import React from "react"
import {strings} from "../../../../localization/Localization";

const HolidayListForUser = (props) => {
  return(
    <div className="table-responsive">
        <table className="table table-responsive table-borderless text-start">
            <thead className={"custom-table-header text-white"}>
            <tr>
                <th>{strings.name}</th>
                <th>{strings.dayDate}</th>
                <th>{strings.month}</th>
                <th>{strings.description}</th>
            </tr>
            </thead>
            <tbody>

            {props.detailsForEmployee.length>0 ?
                 props.detailsForEmployee.map((Val) => {
                return (
                    <tr key={Val.id}>
                        <td scope={"col"}>{Val.name}</td>
                        <td scope={"col"}>{Val.day}</td>
                        <td scope={"col"}>{Val.month}</td>
                        <td scope={"col"}>{Val.description}</td>
                    </tr>
                )}) : <tr className={"justify-content-center text-center "}>
                    <td colSpan={5}>
                        <h6 className={"text-secondary"}><i>{strings.emptyList}</i></h6>
                    </td>
                </tr>
            }
            </tbody>
        </table>
    </div>
  )
}
export default HolidayListForUser;