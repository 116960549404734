import React from "react";
import positionImg from "../../assets/images/position-img.png";
import "./PositionCard.css";
import { strings } from "../../localization/Localization";

const PositionCard = (props) => {
    return (
        <div className={`w-100 card-position p-2 hover-shadow mb-3 h-100 row ${props.isActive ? "" : "inactive-card"}`}>
            <div className={"d-flex align-items-center"}>
                <div className={"col-2 align-items-center d-flex justify-content-center"}>
                    {/*<img src={positionImg} width={"100px"} height={"100px"}/>*/}
                    <i className={"material-icons  p-2  position-icon"}>import_contacts</i>
                </div>
                <div>
                    <h5><b>{props.position.name}</b></h5>
                    <h6>{props.position.code}</h6>
                </div>
                <div className={"ms-auto align-items-center"}>
                    {props.isActive ? (
                        <>
                            <i className={"material-icons position-status-icon-positive"}>gpp_good</i>
                        </>
                    ) : (
                        <>
                            <i className={"material-icons position-status-icon-negative"}>gpp_bad</i>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PositionCard;
