import React from "react";
import {Spinner} from "react-bootstrap";
import "./Spinner.css"

const SpinnerComponent = (props) => {
    return(
        <div hidden={props.spinnerShow}>
        <div id={"spinner-container"}>
        </div>
        <div>
            <Spinner animation="border" role=""
                     style={{position:"absolute", opacity:"100%", left:"50%", top:"50%",width: "3rem", height: "3rem",zIndex:11111 }} variant={'primary'}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
        </div>
    )
}
export default  SpinnerComponent;