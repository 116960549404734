import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsKey } from "react-icons/bs";
import { strings } from "../../../localization/Localization";
import NotMultipleSelectDropDown from "../../../components/NotMultipleSelectDropDown";
import { TbPhone } from "react-icons/tb";
import { GrLocation } from "react-icons/gr";
import Select from "react-select";
import "./UserPersonalData.css";
import ReligionUnitService from "../../../repository/religionUnit/ReligionUnitRepository";
import EthnicityService from "../../../repository/ethnicityRepository/EthnicityRepository";
import RolesRepository from "../../../repository/rolesRepository/RolesRepository";
import swal from "sweetalert";
import UserRepository from "../../../repository/userRepository/UserRepository";
import ApplicationService from "../../../ApplicationService";
import UserEducation from "./UserEducation/UserEducation";
import { useDispatch } from "react-redux";
import { update } from "../../../redux/userSlice";
import { useParams } from "react-router-dom";
import SpinnerComponent from "../../../components/Spinner";

const UserPersonalData = (props) => {
  const [formData, updateFormData] = useState({
    privateEmail: "",
    email: "",
    firstName: "",
    lastName: "",
    parentName: "",
    role: [],
    phoneNumber: "",
    addressName: "",
    addressCity: "",
    addressMunicipality: "",
    addressSettlement: "",
    ethnicityId: "",
    religionId: "",
    hrmsIdNumber: "",
    gender: "",
    secondAddressName: "",
    secondAddressCity: "",
    secondAddressMunicipality: "",
    secondAddressSettlement: "",
    embg: "",
    birthDate: "",
    isForeignCitizen: false,
  });

  const [showSpinner, setShowSpinner] = useState(true);

  const [selectedRoles, setSelectedRoles] = React.useState([]);

  const [religionListForDropDown, setReligionListForDropDown] = useState([]);

  const [ethnicityListForDropDown, setEthnicityListForDropDown] = useState([]);

  const [selectedEthnicity, setSelectedEthnicity] = useState({});

  const [selectedReligion, setSelectedReligion] = useState({});

  const [roleList, setRoleList] = useState([]);

  const [roleListForDropDown, setRoleListForDropDown] = useState([]);

  const [
    defaultSelectedEthnicityForCompare,
    setDefaultSelectedEthnicityForCompare,
  ] = useState({});

  const [
    defaultSelectedReligionForCompare,
    setDefaultSelectedReligionForCompare,
  ] = useState({});

  const [address, setAddress] = useState({
    id: null,
    name: "",
    city: "",
    municipality: "",
    settlement: "",
  });

  const [secondAddress, setSecondAddress] = useState({
    id: null,
    name: "",
    city: "",
    municipality: "",
    settlement: "",
  });

  const param = useParams();

  const dispach = useDispatch();

  useEffect(() => {
    loadAllRoles();
    getAllReligion();
    getAllEthnicity();
  }, []);

  useEffect(() => {
    if (props.selectedUserForEdit?.id) {
      console.log(props.selectedUserForEdit);
      updateFormData({
        privateEmail: props.selectedUserForEdit?.person?.privateEmail,
        email: props.selectedUserForEdit.email,
        firstName: props.selectedUserForEdit.firstName,
        lastName: props.selectedUserForEdit.lastName,
        parentName: props.selectedUserForEdit.parentName,
        role: [],
        phoneNumber: props.selectedUserForEdit?.person?.phoneNumber,
        addressName: props.selectedUserForEdit?.address?.name,
        addressCity: props.selectedUserForEdit?.address?.city,
        addressMunicipality: props.selectedUserForEdit?.address?.municipality,
        addressSettlement: props.selectedUserForEdit?.address?.settlement,
        ethnicityId: "",
        religionId: "",
        hrmsIdNumber: props.selectedUserForEdit?.person?.hrmsIdNumber,
        gender: props.selectedUserForEdit.person?.gender,
        secondAddressName: props.selectedUserForEdit?.secondAddress?.name,
        secondAddressCity: props.selectedUserForEdit?.secondAddress?.city,
        secondAddressMunicipality: props.selectedUserForEdit?.secondAddress?.municipality,
        secondAddressSettlement: props.selectedUserForEdit?.secondAddress?.settlement,
        embg: props.selectedUserForEdit?.person?.embg,
        isForeignCitizen: props.selectedUserForEdit?.person?.isForeignCitizen + "",
        birthDate: props.selectedUserForEdit.birthDate,
      });

      if (props.selectedUserForEdit.person.religion) {
        setSelectedReligion({
          value: props.selectedUserForEdit.person.religion.id,
          label:
            ApplicationService.getActiveLanguage() === "mk"
              ? props.selectedUserForEdit.person.religion.label_mk
              : props.selectedUserForEdit.person.religion.label_en,
        });
      }
      if (props.selectedUserForEdit.person.ethnicity) {
        setSelectedEthnicity({
          value: props.selectedUserForEdit.person.ethnicity.id,
          label:
            ApplicationService.getActiveLanguage() === "mk"
              ? props.selectedUserForEdit.person.ethnicity.label_mk
              : props.selectedUserForEdit.person.ethnicity.label_en,
        });
      }
      if (props.selectedUserForEdit?.address) {
        setAddress({
          id: props.selectedUserForEdit?.address?.id,
          name: props.selectedUserForEdit?.address?.name,
          city: props.selectedUserForEdit?.address?.city,
          municipality: props.selectedUserForEdit?.address?.municipality,
          settlement: props.selectedUserForEdit?.address?.settlement,
        });
      }

      if (props.selectedUserForEdit?.secondAddress) {
        setSecondAddress({
          id: props.selectedUserForEdit?.secondAddress?.id,
          name: props.selectedUserForEdit?.secondAddress?.name,
          city: props.selectedUserForEdit?.secondAddress?.city,
          municipality: props.selectedUserForEdit?.secondAddress?.municipality,
          settlement: props.selectedUserForEdit?.secondAddress?.settlement,
        });
      }
    }
  }, [props.selectedUserForEdit]);

  useEffect(() => {
    if (props.selectedUserForEdit && roleListForDropDown.length > 0) {
      convertSelectedRolesForDropDown(props.selectedUserForEdit?.roles);
    }
  }, [roleListForDropDown, props.selectedUserForEdit]);

  const getAllReligion = () => {
    ReligionUnitService.getAllReligion().then((data) => {
      setReligionListForDropDown(convertListForDropDown(data.data));
    });
  };

  const getAllEthnicity = () => {
    EthnicityService.getAllEthnicity().then((data) => {
      setEthnicityListForDropDown(convertListForDropDown(data.data));
    });
  };

  const loadAllRoles = () => {
    RolesRepository.fetchRoles().then((data) => {
      setRoleList(data.data);
      convertAllRolesForDropDown(data.data);
    });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const isForeignCitizen = formData.isForeignCitizen === "true";

    if (e.target.hasAttribute("required") && value.trim() === "") {
      e.target.setCustomValidity(strings.fieldsRequired);
    } else {
      e.target.setCustomValidity("");
    }

    let updatedFormData = { ...formData };

    if (["firstName", "parentName", "lastName"].includes(e.target.name)) {
      const lettersOnly = value.replace(/[^a-zA-ZаАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯѓЃљЉњЊjJќЌѕЅџЏ\s]+/gm, "");
      e.target.value = lettersOnly;
      updatedFormData = {
        ...updatedFormData,
        [e.target.name]: lettersOnly,
      };
    } else if (e.target.name === "embg") {
      const numericValue = isForeignCitizen
        ? value
        : value.replace(/[^0-9]/g, "");
      const truncatedValue = isForeignCitizen
        ? value
        : numericValue.substring(0, 13);
      e.target.value = truncatedValue;

      if (!isForeignCitizen && truncatedValue.length === 13) {
        let embg = truncatedValue;
        let dayOfBirth = embg.substring(0, 2);
        let monthOfBirth = embg.substring(2, 4);
        let yearOfBirth = embg.substring(4, 7);

        if (yearOfBirth.charAt(0) === "0") {
          yearOfBirth = "2" + yearOfBirth;
        } else {
          yearOfBirth = "1" + yearOfBirth;
        }

        let genderNumber = embg.substring(9, 12);
        let gender = "MALE";
        if (genderNumber >= 500) {
          gender = "FEMALE";
        }

        updatedFormData = {
          ...updatedFormData,
          birthDate: yearOfBirth + "-" + monthOfBirth + "-" + dayOfBirth,
          gender: gender,
        };
      }

      updatedFormData = {
        ...updatedFormData,
        [e.target.name]: truncatedValue,
      };
    } else if (e.target.name === "phoneNumber") {
      const numericValue = value.replace(/[^0-9]/g, "");

      const formattedPhoneNumber = formatPhoneNumber(numericValue);

      updatedFormData = {
        ...updatedFormData,
        [e.target.name]: formattedPhoneNumber,
      };
    } else {
      updatedFormData = {
        ...updatedFormData,
        [e.target.name]: value,
      };
    }

    updateFormData(updatedFormData);
  };

  const handleChangeBirthDate = (e) => {
    updateFormData({
      ...formData,
      birthDate: e.target.value,
    });
  };

  const handleIsForeignCitizenChange = (e) => {
    updateFormData({ ...formData, isForeignCitizen: e.target.value });
  };

  const getSelectedRoleFromDropDown = (e) => {
    setSelectedRoles(e);
  };

  const getSelectedEthnicityFromDropDown = (e) => {
    setSelectedEthnicity(e);
  };

  const getSelectedReligionFromDropDown = (e) => {
    setSelectedReligion(e);
  };
  const formatPhoneNumber = (numericValue) => {
    const digitsOnly = numericValue.replace(/[^0-9]/g, "");

    let formattedPhoneNumber = "+";

    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 5 || i === 8) {
        formattedPhoneNumber += " ";
      }
      formattedPhoneNumber += digitsOnly[i];
    }

    return formattedPhoneNumber;
  };

  const convertListForDropDown = (list) => {
    var convertList = [];
    for (var i = 0; i < list.length; i++) {
      if (list[i].labelMk) {
        convertList[i] = {
          value: list[i].id,
          label:
            ApplicationService.getActiveLanguage() === "mk"
              ? list[i].labelMk
              : list[i].labelEn,
        };
      } else {
        convertList[i] = {
          value: list[i].id,
          label:
            ApplicationService.getActiveLanguage() === "mk"
              ? list[i].label_mk
              : list[i].label_en,
        };
      }
    }
    return convertList;
  };

  const convertAllRolesForDropDown = (data) => {
    var convertRole = [];
    for (var i = 0; i < data.length; i++) {
      convertRole[i] = {
        value: data[i].id,
        label: data[i].label,
      };
    }
    setRoleListForDropDown(convertRole);
  };

  const convertSelectedRolesForDropDown = (roles) => {
    var convertRole = rolesFilter(roles);
    var roleForDropDown = [];
    for (var i = 0; i < convertRole.length; i++) {
      roleForDropDown[i] = {
        value: convertRole[i].id,
        label: convertRole[i].label,
      };
    }
    setSelectedRoles(roleForDropDown);
  };

  const rolesFilter = (roles) => {
    var convertRole = [];
    for (var i = 0; i < roleList.length; i++) {
      for (var j = 0; j < roles.length; j++) {
        if (roles[j] == roleList[i].label) {
          convertRole.push(roleList[i]);
        }
      }
    }
    return convertRole;
  };

  const warrningAlert = () => {
    swal({
      title: strings.required,
      text: strings.fieldsRequired,
      icon: "warning",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const userEditSuccessfulAlert = () => {
    swal(strings.userEditSuccessful, {
      icon: "success",
    });
  };

  const errorAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const updateAddressField = (addressType, fieldName, value) => {
    if (addressType === "address") {
      setAddress((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    } else if (addressType === "secondAddress") {
      setSecondAddress((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }
  };

  const editUser = (
    id,
    privateEmail,
    email,
    firstName,
    lastName,
    parentName,
    roleIds,
    phoneNumber,
    address,
    secondAddress,
    ethnicityId,
    religionId,
    hrmsIdNumber,
    gender,
    embg,
    birthDate,
    isForeignCitizen
  ) => {
    props.setShowSpinner(false);
    UserRepository.editUser(
      id,
      privateEmail,
      email,
      firstName,
      lastName,
      parentName,
      roleIds,
      phoneNumber,
      address,
      secondAddress,
      ethnicityId,
      religionId,
      hrmsIdNumber,
      gender,
      embg,
      birthDate,
      isForeignCitizen
    )
      .then((data) => {
        if (!param.id) {
          dispach(
            update({
              firstName,
              lastName,
              parentName,
              gender,
              email,
              privateEmail,
            })
          );
        }
        props.setShowSpinner(true);
        userEditSuccessfulAlert();
        props.getUserData();
      })
      .catch((e) => {
        props.setShowSpinner(true);
        errorAlert();
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const privateEmail =
      formData.privateEmail !== ""
        ? formData.privateEmail
        : props.selectedUserForEdit?.person?.privateEmail;
    const email =
      formData.email !== "" ? formData.email : props.selectedUserForEdit.email;
    const firstName =
      formData.firstName !== ""
        ? formData.firstName
        : props.selectedUserForEdit.firstName;
    const lastName =
      formData.lastName !== ""
        ? formData.lastName
        : props.selectedUserForEdit.lastName;
    const parentName =
      formData.parentName !== ""
        ? formData.parentName
        : props.selectedUserForEdit.person.parentName;
    let roleIds = selectedRoles.map((e) => e.value);
    const phoneNumber =
      formData.phoneNumber !== ""
        ? formData.phoneNumber
        : props.selectedUserForEdit?.person?.phoneNumber;
    // const address = formData.address !== '' ? formData.address : props.selectedUserForEdit.address?.name;
    const hrmsIdNumber =
      formData.hrmsIdNumber !== ""
        ? formData.hrmsIdNumber
        : props.selectedUserForEdit.hrmsIdNumber;
    const gender =
      formData.gender !== ""
        ? formData.gender
        : props.selectedUserForEdit.gender;
    // const secondAddress = formData.secondAddress !== '' ? formData.secondAddress : props.selectedUserForEdit.secondAddress?.name;
    const embg = formData?.embg;
    const isForeignCitizenValue = formData?.isForeignCitizen;
    const birthDate =
      formData.birthDate !== null
        ? formData.birthDate.split("T")[0]
        : props.selectedUserForEdit.birthDate;

    let ethnicityId = selectedEthnicity.value;
    let religionId = selectedReligion.value;
    if (roleIds.length > 0) {
      if (ethnicityId === defaultSelectedEthnicityForCompare) {
        ethnicityId = null;
      }
      if (religionId === defaultSelectedReligionForCompare) {
        religionId = null;
      }
      editUser(
        props.selectedUserForEdit.id,
        privateEmail,
        email,
        firstName,
        lastName,
        parentName,
        roleIds,
        phoneNumber,
        address,
        secondAddress,
        ethnicityId,
        religionId,
        hrmsIdNumber,
        gender,
        embg,
        birthDate,
        isForeignCitizenValue
      );
    } else {
      warrningAlert();
    }
  };

  return (
    <>
      <form className={"p-4"} onSubmit={onFormSubmit}>
        <Modal.Body>
          <Row className={"mb-2"}>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label
                  className={"d-flex align-items-center text-secondary mb-0"}
                >
                  <div
                    className={
                      "label-icon align-items-end d-flex rotate-key-icon mt-1"
                    }
                  >
                    <BsKey />
                  </div>
                  {strings.hrmsIdNumber}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={formData.hrmsIdNumber || ''}
                  disabled={
                    ApplicationService.hasRole([
                      "ROLE_ADMINISTRATOR",
                      "ROLE_HR",
                    ])
                      ? false
                      : true
                  }
                  onChange={handleChange}
                  className={"user_data_input"}
                  name="hrmsIdNumber"
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3 d-flex flex-column align-items-start">
                <Form.Label className={"text-secondary mb-0"}>
                  {strings.isForeignCitizen}
                </Form.Label>
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center me-3">
                    <input
                      type="radio"
                      name="isForeignCitizen"
                      id="isForeignCitizenCheckbox"
                      value={true}
                      onChange={handleIsForeignCitizenChange}
                      checked={
                        formData.isForeignCitizen == "true" ? true : false
                      }
                    />
                    <label
                      htmlFor="isForeignCitizenCheckbox"
                      className={"ms-2"}
                    >
                      {strings.yes}
                    </label>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="isForeignCitizen"
                      id="isNotForeignCitizenCheckbox"
                      value={false}
                      onChange={handleIsForeignCitizenChange}
                      checked={
                        formData.isForeignCitizen == "false" ? true : false
                      }
                    />
                    <label
                      htmlFor="isNotForeignCitizenCheckbox"
                      className={"ms-2"}
                    >
                      {strings.no}
                    </label>
                  </div>
                </div>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label
                  className={"d-flex align-items-center text-secondary mb-0"}
                >
                  {strings.embg}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={formData.embg || ''}
                  onChange={handleChange}
                  className={"user_data_input input-number-without-buttons"}
                  name="embg"
                />
              </Form.Group>
            </Col>
          </Row>
           <Row className={"mb-2"}>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3 required">
                <Form.Label className={"text-secondary mb-0"}>
                  {strings.name}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="firstName"
                  className={"user_data_input"}
                  value={formData.firstName || ''}
                  onChange={handleChange}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label className={"text-secondary  mb-0"}>
                  {strings.parentName}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={formData.parentName || ''}
                  onChange={handleChange}
                  className={"user_data_input"}
                  name="parentName"
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label className={"text-secondary  mb-0"}>
                  {strings.lastName}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={formData.lastName || ''}
                  className={"user_data_input"}
                  onChange={handleChange}
                  name="lastName"
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>
          </Row> 
           <Row className={"mb-2"}>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label className={"text-secondary mb-0"}>
                  {strings.ethnicityName}
                </Form.Label>
                <NotMultipleSelectDropDown
                  listForDropDown={ethnicityListForDropDown}
                  selectedDefaultValue={selectedEthnicity}
                  className={"user_details_select_input"}
                  handleSelection={getSelectedEthnicityFromDropDown}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label className={"text-secondary mb-0"}>
                  {strings.religion}
                </Form.Label>
                <NotMultipleSelectDropDown
                  listForDropDown={religionListForDropDown}
                  selectedDefaultValue={selectedReligion}
                  className={"user_details_select_input"}
                  handleSelection={getSelectedReligionFromDropDown}
                />
              </Form.Group>
            </Col>
          </Row>
         <Row className={"mb-2"}>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label className={"text-secondary  mb-0"}>
                  {strings.gender}
                  <span className="text-danger">*</span>
                </Form.Label>{" "}
                <br />
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  value="MALE"
                  onChange={handleChange}
                  checked={formData.gender == "MALE" ? true : false}
                  required
                />
                <label htmlFor="male" className={"ms-2 me-3"}>
                  {strings.male}
                </label>
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  value="FEMALE"
                  onChange={handleChange}
                  checked={formData.gender == "FEMALE" ? true : false}
                  required
                />
                <label htmlFor="female" className={"ms-2 me-3"}>
                  {strings.female}
                </label>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label
                  className={"d-flex align-items-center text-secondary mb-0"}
                >
                  {strings.birthDate}
                </Form.Label>
                <Form.Control
                  type="date"
                  value={formData?.birthDate?.split("T").at(0) ?? ''}
                  onChange={handleChangeBirthDate}
                  className={"user_data_input"}
                  name="birthDate"
                />
              </Form.Group>
            </Col>
          </Row>
           <Form.Group className="mb-2">
            <Form.Label className="d-flex align-items-center text-secondary mb-0">
              {strings.privateEmail}
            </Form.Label>
            <Form.Control
              type="email"
              className={"user_data_input"}
              name="privateEmail"
              value={formData.privateEmail || ''}
              onChange={handleChange}
              style={{ width: "50%" }}
              onInvalid={(e) => {
                e.target.setCustomValidity(strings.emailVerificationWarning);
              }}
            />
          </Form.Group>
           <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mt-3 mb-3 required">
                <Form.Label
                  className={"text-secondary d-flex align-items-center mb-0"}
                >
                  <TbPhone />
                  {strings.phoneNumber}{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  className={"user_data_input"}
                  value={formData.phoneNumber || ''}
                  onChange={handleChange}
                  pattern="[0-9+ ]*"
                  placeholder={strings.onlyNumbers}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  <Form.Label className={"text-secondary mb-1"}>
                    {strings.roles}
                  </Form.Label>
                </Form.Label>
                <Select
                  onChange={getSelectedRoleFromDropDown}
                  value={selectedRoles}
                  isMulti
                  name="role"
                  options={roleListForDropDown}
                  isDisabled={
                    ApplicationService.hasRole(["ROLE_ADMINISTRATOR"])
                      ? false
                      : true
                  }
                  placeholder={strings.selectOption}
                  noOptionsMessage={() => strings.noOptions}
                  className="basic-multi-select user_details_select_input"
                  classNamePrefix="select"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className={"mb-2 mt-4"}>
            <Col xs={12} md={6}>
              <div
                className={
                  "p-3 bg-light userprivate_personal_data__address_wrapper"
                }
              >
                <Row>
                  <Col xs={12} md={12}>
                    <Form.Group className="mb-4">
                      <Form.Label
                        className={
                          "text-secondary d-flex align-items-center mb-1"
                        }
                      >
                        <GrLocation className={"text-dark"} />
                        {strings.activeAddress}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={address.name || ''}
                        className={"user_data_input"}
                        onChange={(e) =>
                          updateAddressField(
                            "address",
                            e.target.name,
                            e.target.value
                          )
                        }
                        name="name"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-4">
                      <Form.Label
                        className={
                          "text-secondary d-flex align-items-center mb-1"
                        }
                      >
                        {strings.city}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={address.city || ''}
                        className={"user_data_input"}
                        onChange={(e) =>
                          updateAddressField(
                            "address",
                            e.target.name,
                            e.target.value
                          )
                        }
                        name="city"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-4">
                      <Form.Label
                        className={
                          "text-secondary d-flex align-items-center mb-1"
                        }
                      >
                        {strings.municipality}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={address.municipality || ''}
                        className={"user_data_input"}
                        onChange={(e) =>
                          updateAddressField(
                            "address",
                            e.target.name,
                            e.target.value
                          )
                        }
                        name="municipality"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-4">
                      <Form.Label
                        className={
                          "text-secondary d-flex align-items-center mb-1"
                        }
                      >
                        {strings.settlement}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={address.settlement ?? ''}
                        className={"user_data_input"}
                        onChange={(e) =>
                          updateAddressField(
                            "address",
                            e.target.name,
                            e.target.value
                          )
                        }
                        name="settlement"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div
                className={"p-3 bg-light user_personal_data__address_wrapper"}
              >
                <Row>
                  <Col xs={12} md={12}>
                    <Form.Group className="mb-4">
                      <Form.Label
                        className={
                          "text-secondary d-flex align-items-center mb-1"
                        }
                      >
                        <GrLocation className={"text-dark"} />
                        {strings.secondAddress}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={secondAddress.name || ''}
                        className={"user_data_input"}
                        onChange={(e) =>
                          updateAddressField(
                            "secondAddress",
                            e.target.name,
                            e.target.value
                          )
                        }
                        name="name"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-4">
                      <Form.Label
                        className={
                          "text-secondary d-flex align-items-center mb-1"
                        }
                      >
                        {strings.city}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={secondAddress.city || ''}
                        className={"user_data_input"}
                        onChange={(e) =>
                          updateAddressField(
                            "secondAddress",
                            e.target.name,
                            e.target.value
                          )
                        }
                        name="city"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-4">
                      <Form.Label
                        className={
                          "text-secondary d-flex align-items-center mb-1"
                        }
                      >
                        {strings.municipality}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={secondAddress.municipality || ''}
                        className={"user_data_input"}
                        onChange={(e) =>
                          updateAddressField(
                            "secondAddress",
                            e.target.name,
                            e.target.value
                          )
                        }
                        name="municipality"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-4">
                      <Form.Label
                        className={
                          "text-secondary d-flex align-items-center mb-1"
                        }
                      >
                        {strings.settlement}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={secondAddress.settlement || ''}
                        className={"user_data_input"}
                        onChange={(e) =>
                          updateAddressField(
                            "secondAddress",
                            e.target.name,
                            e.target.value
                          )
                        }
                        name="settlement"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Modal.Footer>
            <button
              className={"btn text-white mt-3 border-radius add-button"}
              type="submit"
            >
              {strings.saveProfile}
            </button>
          </Modal.Footer> 
        </Modal.Body> 
      </form>
      <div
        className={"p-3 mt-4  mb-3"}
        style={{
          border: "1px solid lightgray",
          width: "99.5%",
          borderRadius: "10px",
        }}
      >
        <SpinnerComponent spinnerShow={showSpinner} />
        <UserEducation
          currentTab={props.currentTab}
          setShowSpinner={setShowSpinner}
        />
      </div>
    </>
  );
};
export default UserPersonalData;
