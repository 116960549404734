import React, {useEffect, useState} from "react"
import LeaveRequestAuditActionService from "../../../../repository/LeaveRequestAuditActionRepository/LeaveRequestAuditActionRepository";
import "./AuditActionLeaveRequest.css"
import {strings} from "../../../../localization/Localization";

const AuditActionLeaveRequest = (props) => {

    const [allAuditAction,setAllAuditAction]=useState([]);

    const [isOpenAuditAction, setIsOpenAuditAction] = useState(false);

    useEffect(()=>{
        if(props.leaveRequestId ){
            getAllAuditAction();
        }
    },[props.leaveRequestId]);

    const getAllAuditAction=()=>{
        if(props.leaveRequestId) {
            LeaveRequestAuditActionService.getAllAuditActionForRequest(props.leaveRequestId)
                .then((data) => {
                    setAllAuditAction(data.data);
                })
        }
    };

    const handleOpenAuditActionContainer= () => {
        setIsOpenAuditAction(!isOpenAuditAction);
    };

    return (
        <>
            {!isOpenAuditAction ? (
                    <h6 className={"text-center d-flex ms-4 mt-3"}><a
                        className={"text-blue d-flex align-items-center cursor-pointer "}
                        onClick={handleOpenAuditActionContainer}>{strings.actionHistory}<i
                        className={"material-icons"}>arrow_drop_up</i></a></h6>)
                :
                (
                    <h6 className={"text-center d-flex ms-4 mt-3"}><a
                        className={"text-secondary d-flex align-items-center cursor-pointer "}
                        onClick={handleOpenAuditActionContainer}>{strings.actionHistory}<i
                        className={"material-icons"}>arrow_drop_down</i></a></h6>
                )
            }
            {isOpenAuditAction &&
                <div>
                    {allAuditAction.length !== 0 ?
                        (<div className="audit-action-wrapper">

                            {allAuditAction.map((action) => {
                                return (
                                    <div className="timeline-block timeline-block-right mb-2 w-100" key={action.id}>
                                        <div className="marker"></div>
                                        <div className="timeline-content">
                                            {/*<h3>First Year</h3>*/}
                                            <span className={"w-100"}>{action.dateCreated?.split("T")[0]}
                                                <br/> {action?.dateCreated?.split("T")[1].split(".")[0].split(":")[0]}:{action?.dateCreated?.split("T")[1].split(".")[0].split(":")[1]}</span>
                                            <p>{action.actionDescription}</p>
                                        </div>
                                    </div>
                                )

                            })}


                        </div>)
                        : (

                            <i className={"text-secondary ms-5"}>{strings.actionListEmpty}</i>
                        )
                    }
                </div>
            }

        </>
    )
}
export default AuditActionLeaveRequest;