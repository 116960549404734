import Modal from "react-bootstrap/Modal";
import {strings} from "../../../../localization/Localization";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ApplicationService from "../../../../ApplicationService";
import {Carousel} from "react-bootstrap";
import EventGroupDetails from "../../EventGroup/EventGroupDetails";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import EventProposalService from "../../../../repository/eventsRepository/EventProposalRepository";
import swal from "sweetalert";
import "./AddEditEventProposalModal.css";
import DraggableModalDialog from "../../../../components/DraggableModalDialog/DraggableModalDialog";

const AddEditEventProposalModal = (props) => {

    const param = useParams();

    const history = useHistory();

    const [formData, updateFormData] = useState({
        name: "",
        description: "",
        proposalFrom: "",
    });

    useEffect(() => {
        if (!props.showModal) {
            resetData();
        }
        if (props.selectedEventProposal) {
            if (props.selectedEventProposal?.eventProposalId) {
                updateFormData({
                    name: props.selectedEventProposal?.name,
                    description: props.selectedEventProposal?.description,
                    proposalFrom: props.selectedEventProposal?.user?.firstName + " " + props.selectedEventProposal?.user?.lastName
                })
            }
        }
    }, [props.selectedEventProposal, props.showModal])

    const handleChange = (e) => {
        const {name, value} = e.target;
        if (e.target.hasAttribute("required") && value.trim() === "") {
            e.target.setCustomValidity(strings.fieldsRequired);
          } else {
            e.target.setCustomValidity("");
          }
        updateFormData({
            ...formData,
            [name]: value,
        });
    };

    const resetData = () => {
        updateFormData({
            name: "",
            description: "",
        })
    };

    const eventProposalAddSuccessfulAlert = () => {
        swal(strings.userEditSuccessful, {
            icon: "success",
        });
    };

    const DenyEventProposal = (id) => {
        swal(strings.deleteConfirmation, {
            title: strings.areYouSure,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                denyEventProposal(id);
            }
        });
    }

    const denyEventProposal = (id) => {
        EventProposalService.denyEventProposal(id).then(() => {
            eventProposalAddSuccessfulAlert();
            props.handleClose();
            props.getEventProposals();
            props.getEventProposalsForUser();
        }).catch(() => {
            errorAlert();
        })
    }

    const errorAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const addEventProposal = (name, description) => {
        EventProposalService.addEventProposal(name, description)
            .then(() => {
                props.handleClose();
                eventProposalAddSuccessfulAlert();
                props.getEventProposals();
                props.getEventProposalsForUser();
            }).catch(() => {
            errorAlert();
        })
    }

    const editEventProposal = (eventProposalId, name, description) => {
        EventProposalService.editEventProposal(eventProposalId, name, description)
            .then(() => {
                props.handleClose();
                eventProposalAddSuccessfulAlert();
                props.getEventProposals();
                props.getEventProposalsForUser();
            }).catch(() => {
            errorAlert();
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const name = formData.name !== "" ? formData.name : null;
        const description = formData.description !== "" ? formData.description : null;

        if (props.selectedEventProposal?.eventProposalId) {
            editEventProposal(props.selectedEventProposal?.eventProposalId, name, description);
        } else {
            addEventProposal(name, description);
        }

    }

    return (
        <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={"lg"} centered>
            <Modal.Header closeButton>
                <Modal.Title>{strings.addEventProposal}</Modal.Title>
            </Modal.Header>
            <Form
                onSubmit={onFormSubmit}
            >
                <Modal.Body>
                    <Row className={"mb-2"}>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.proposalName}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type={"text"}
                                    value={formData.name}
                                    onChange={handleChange}
                                    name={"name"}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.description}
                                </Form.Label>
                                <div className={"my-3"}>
                                  <span className={"gray-background py-2 px-3 rounded-3 d-flex align-items-center"}>
                                    <i className={"material-icons me-1 fs-22"}>error_outline</i>
                                      <span className={"fs-15"}>{strings.describeProposal}<span className="text-danger">*</span></span>
                                  </span>
                                </div>

                                <Form.Control
                                    required
                                    as="textarea"
                                    rows={4}
                                    value={formData.description}
                                    onChange={handleChange}
                                    name={"description"}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    {/*{formData.proposalFrom && (*/}
                    {/*    <Row className={"mb-2"}>*/}
                    {/*        <Col>*/}
                    {/*            <Form.Group className="mb-3">*/}
                    {/*                <Form.Label className={"d-flex"}>*/}
                    {/*                    {strings.eventProposalFrom}*/}
                    {/*                </Form.Label>*/}
                    {/*                <p className={"ps-3"}>*/}
                    {/*                    {formData.proposalFrom}*/}
                    {/*                </p>*/}
                    {/*            </Form.Group>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*)}*/}
                </Modal.Body>
                <Modal.Footer>
                    {props.selectedEventProposal ? (
                        <>
                            {ApplicationService.hasRole(["ROLE_ADMINISTRATOR", "ROLE_HR"]) && (
                                <div className={"me-auto"}>
                                    <button type="button" className={"cancel btn text-white m-1"}
                                            onClick={() => DenyEventProposal(props.selectedEventProposal?.eventProposalId)}>
                                        {strings.deny}
                                    </button>
                                    <button className={"save btn text-white m-1"}
                                            onClick={() => history.push("/addEvent", {eventProposal: props.selectedEventProposal})}>
                                        {strings.accept}
                                    </button>
                                </div>
                            )}
                            <div className={"cancel btn text-white"} onClick={() => {
                                props.handleClose();
                                resetData();
                            }}>
                                {strings.cancel}
                            </div>
                            <button type={"submit"} className={"save btn text-white m-1"}>
                                {strings.save}
                            </button>
                        </>
                    ) : (
                        <>
                            <div className={"cancel btn text-white"} onClick={() => {
                                props.handleClose();
                                resetData();
                            }}>
                                {strings.cancel}
                            </div>
                            <button type={"submit"} className={"save btn text-white m-1"}>
                                {strings.save}
                            </button>
                        </>
                    )}
                </Modal.Footer>


            </Form>
        </Modal>
    )

}

export default AddEditEventProposalModal;