import axios from "../../axios/axios";

const BenefitService = {
    getAll: () => {
        return axios.get("/user-benefit/getAll")
    },
    getAllBenefitsForUser: () =>{
      return axios.get("/user-benefit/getAllBenefitNomenclatureEntriesForUser")
    },
    getAllUserBenefitDataForUser: (userId) => {
        return axios.get(`/user-benefit/getAllUserBenefitDataForUser/${userId}`)
    },
    getAllUserBenefitDataForLoggedUser: () => {
        return axios.get("/user-benefit/getAllUserBenefitDataForLoggedUser")
    },
    getAllUserBenefitForUserAndStatusActive: (userId) => {
        return axios.get(`/user-benefit/getAllUserBenefitForUserAndStatusActive/${userId}`);
    },
    getAllUserBenefitForLoggedUserAndStatusActive: () => {
        return axios.get("/user-benefit/getAllUserBenefitForLoggedUserAndStatusActive");
    },
    getUserBenefitById: (userBenefitId) => {
        return axios.get(`/user-benefit/getUserBenefitById/${userBenefitId}`);
    },
    getAllBenefitNomenclatureEntries:() => {
        return axios.get("/user-benefit/getAllBenefitNomenclatureEntries")
    },
    getAllUserBenefitByNomenclatureEntryId:(id) => {
        return axios.get(`/user-benefit/getAllUserBenefitByNomenclatureEntryId/${id}`)
    },
    addUserBenefitData: (userId, benefitNomenclatureEntryId, startDate, endDate, benefitStatus) => {
        return axios.post("/user-benefit/addUserBenefitData", {
            userId,
            benefitNomenclatureEntryId,
            startDate,
            endDate,
            benefitStatus
        })
    },
    addUserBenefitDataByAdmin: (userId, benefitNomenclatureEntryId, startDate, endDate, benefitStatus) => {
        return axios.post("/user-benefit/addUserBenefitDataByAdmin", {
            userId,
            benefitNomenclatureEntryId,
            startDate,
            endDate,
            benefitStatus
        })
    },
    editUserBenefitData: (userBenefitId, userId, benefitNomenclatureEntryId, startDate, endDate, benefitStatus) => {
        return axios.put("/user-benefit/updateUserBenefitData", {
            userBenefitId,
            userId,
            benefitNomenclatureEntryId,
            startDate,
            endDate,
            benefitStatus
        })
    },
    editUserBenefitDataByAdmin: (userBenefitId, userId, benefitNomenclatureEntryId, startDate, endDate, benefitStatus) => {
        return axios.put("/user-benefit/updateUserBenefitDataByAdmin", {
            userBenefitId,
            userId,
            benefitNomenclatureEntryId,
            startDate,
            endDate,
            benefitStatus
        })
    },
    deleteUserBenefitData: (id) => {
        return axios.delete(`/user-benefit/deleteUserBenefitData/${id}`)
    },
    addUserComment: (userBenefitId, comment) => {
        return axios.post("/user-benefit/addComment", {
            userBenefitId,
            comment
        })
    },
}

export default BenefitService;