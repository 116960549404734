import swal from "sweetalert";
import { strings } from "../../../../localization/Localization";
import BenefitService from "../../../../repository/benefitsRepository/BenefitsRepository";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import ApplicationService from "../../../../ApplicationService";
import "./BenefitDetails.css";
import { useHistory } from "react-router-dom";
import AddOrEditUserToBenefitComponent from "../AddOrEditUserToBenefitComponent/AddOrEditUserToBenefitComponent";
import FormatDate from "../../../../components/formatDate/FormatDate";
import CommentModal from "./CommentModal";
import UserService from "../../../../repository/userRepository/UserRepository";
import DraggableModalDialog from "../../../../components/DraggableModalDialog/DraggableModalDialog";

const BenefitDetails = (props) => {
  const [benefitsList, setBenefitsList] = useState([]);
  const [
    selectedNomenclatureEntryForEdit,
    setSelectedNomenclatureEntryForEdit,
  ] = useState({});
  const history = useHistory();
  const [usersList, setUsersList] = useState([]);
  const [selectedUserBenefitDetails, setSelectedUserBenefitDetails] = useState(
    {}
  );
  const [showAddUser, setShowAddUser] = useState(false);
  const [comment, setComment] = useState("");
  const [isCommentModalVisible, setCommentModalVisible] = useState(false);

  const [currentUserDetails, setCurrentUserDetails] = useState(null);
  const [isCurrentUserInList, setIsCurrentUserInList] = useState(false);

  let rowNumber = 1;

  const getAllUserBenefit = (id) => {
    BenefitService.getAllUserBenefitByNomenclatureEntryId(id).then((data) => {
      setBenefitsList(data.data);
    });
  };

  useEffect(() => {
    if (props.showModal) {
      getAllUserBenefit(props.benefit.id);
      fetchUsersNotInBenefit();
      fetchUserDetails();
    }
  }, [props.showModal]);

  const fetchUserDetails = async () => {
    try {
      const userDetails = await UserService.getUserDetails();
      setCurrentUserDetails(userDetails);

      if (userDetails && usersList.length > 0) {
        const currentUserId = userDetails.data.id;
        const userInList = usersList.some(
          (user) => user.value === currentUserId
        );
        setIsCurrentUserInList(userInList);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    if (currentUserDetails) {
      const currentUserId = currentUserDetails.data.id;
      const userInList = usersList.filter(
        (user) => user.value === currentUserId
      );
      setIsCurrentUserInList(userInList.length > 0);
    }
  }, [currentUserDetails, usersList]);

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const errorAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDate.replace(/\//g, ".");
  };

  const redirectToEditBenefit = (nomenclatureEntry) => {
    setSelectedNomenclatureEntryForEdit(nomenclatureEntry);
  };

  const handleCloseAddUserToBenefitComponent = () => {
    setShowAddUser(false);
    setSelectedUserBenefitDetails({});
  };

  const handleAddUserClick = () => {
    setShowAddUser(!showAddUser);
  };

  const ConfirmationDelete = (id) => {
    swal(strings.deleteConfirmation, {
      title: strings.areYouSure,
      icon: "warning",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteUserBenefit(id);
      }
    });
  };

  const deleteUserBenefit = (id) => {
    BenefitService.deleteUserBenefitData(id)
      .then(() => {
        successfulAlert();
        getAllUserBenefit(props.benefit.id);
        props.getBenefitDetailsById();
      })
      .catch(() => {
        errorAlert();
      });
  };

  const handleCommentSubmit = () => {
    BenefitService.addUserComment(props.benefit.id, comment)
      .then(() => {
        successfulAlert();
      })
      .catch(() => {
        errorAlert();
      });
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleCommentClick = (userBenefitDetails) => {
    setCommentModalVisible(true);
    setSelectedUserBenefitDetails(userBenefitDetails);
  };

  const fetchUsersNotInBenefit = () => {
    UserService.getAllUsersNotBelongingToBenefit(props.benefit.id)
      .then((response) => {
        const usersNotInBenefit = response.data;
        const userOptions = usersNotInBenefit.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }));
        setUsersList(userOptions);
      })
      .catch((error) => {
        console.error("Error fetching users not in benefit:", error);
      });
  };

  return (
    <Modal
      show={props.showModal}
      dialogAs={DraggableModalDialog}
      onHide={props.handleClose}
      size={"xl"}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <b>
            {ApplicationService.getActiveLanguage() === "mk"
              ? props.benefit.labelMk
              : props.benefit.labelEn}
          </b>
        </Modal.Title>
        <div>
          {new Date(props.benefit.endDate) >=
          new Date().setHours(0, 0, 0, 0) ? (
            <span className="badge text-bg-success">
              {strings.active.toUpperCase()}
            </span>
          ) : (
            <span className="badge text-bg-danger">
              {strings.inactive.toUpperCase()}
            </span>
          )}
        </div>
      </Modal.Header>
      <div>
        <Modal.Body>
          <div>
            <div className={"d-flex"}>
              {props.matchingValues.map((matchingValue, index) => (
                <span key={index} className="me-4 mt-1 fw-bolder">
                  {ApplicationService.getActiveLanguage() === "mk"
                    ? matchingValue.labelMk
                    : matchingValue.labelEn}
                  :{" "}
                  {matchingValue.labelValue
                    ? FormatDate.formatDate(matchingValue.labelValue)
                    : "/"}
                </span>
              ))}
              {ApplicationService.hasRole([
                "ROLE_ADMINISTRATOR",
                "ROLE_HR",
              ]) && (
                <div className={"ms-auto d-flex"}>
                  <div className={"icon-div mx-2"}>
                    <div
                      onClick={() => {
                        // redirectToEditBenefit();
                        history.push("/nomenclature", {
                          nomenclatureEntry: "Бенефиции",
                          benefitForEdit: props.benefit
                          
                        });
                      }}
                      title={"Edit"}
                      className={"text-dark icon"}
                    >
                      <i className={"edit-icon-circle material-icons "}>
                        create
                      </i>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <p className={"justify-content-center description"}>
              {props.benefit.description}
            </p>
          </div>

          {ApplicationService.hasRole(["ROLE_ADMINISTRATOR", "ROLE_HR"]) ? (
            <></>
          ) : (
            <div>
              {isCurrentUserInList === true ? (
                <div></div>
              ) : (
                <div>
                  <div className="mb-3">
                    <span
                      className={
                        "gray-background py-2 px-3 rounded-3 d-flex align-items-center"
                      }
                    >
                      <i className={"material-icons me-1 fs-22"}>
                        error_outline
                      </i>
                      <span className={"fs-15"}>
                        {strings.leaveCommentHere}:
                      </span>
                    </span>
                  </div>

                  <div className="mb-3">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder={strings.enterYourComment}
                      value={comment}
                      onChange={handleCommentChange}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <div
                      className="btn text-end d-flex align-items-center border-radius add-button test"
                      onClick={handleCommentSubmit}
                    >
                      <div className="align-items-center text-white d-flex text-start">
                        <i className="material-icons add_button_icon">
                          add_box
                        </i>
                      </div>
                      <div className="mx-1 text-white">
                        {strings.addComment}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        {ApplicationService.hasRole(["ROLE_ADMINISTRATOR", "ROLE_HR"]) && (
          <Modal.Footer>
            <div className={"me-auto fs-5"}>
              <i>{strings.benefitUsersList}</i>
            </div>
            {!showAddUser ? (
              <div
                className={
                  "btn text-end d-flex align-items-center border-radius add-button"
                }
                onClick={handleAddUserClick}
              >
                <div
                  className={"align-items-center d-flex text-start text-white"}
                >
                  <i className={"material-icons add_button_icon"}>person_add</i>
                </div>
                <div className={"mx-1 text-white"}>
                  {strings.addUserToBenefit}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className={"container"}>
              {showAddUser && (
                <AddOrEditUserToBenefitComponent
                  benefitList={benefitsList}
                  showModal={showAddUser}
                  handleClose={handleCloseAddUserToBenefitComponent}
                  benefit={props.benefit}
                  matchingValues={props.matchingValues}
                  selectedUserBenefitDetails={selectedUserBenefitDetails}
                  getAllUserBenefit={getAllUserBenefit}
                  getBenefitDetailsById={props.getBenefitDetailsById}
                />
              )}
            </div>

            <div className={"container mb-2"}>
              <div>
                <div className={"row table-responsive"}>
                  {props.benefitDetails.length > 0 ? (
                    <table id="keywords" className={"mb-3"}>
                      <thead>
                        <tr className="userlist">
                          <th scope={"col"}>
                            <b>#</b>
                          </th>
                          <th scope={"col"}></th>
                          <th scope={"col"}>{strings.name}</th>
                          <th scope={"col"}>{strings.lastName}</th>
                          <th scope={"col"}>{strings.started}</th>
                          <th scope={"col"}>{strings.ended}</th>
                          <th scope={"col"}>{strings.status}</th>
                          <th scope={"col"}></th>
                          <th scope={"col"}></th>
                          <th scope={"col"}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.benefitDetails.map(
                          (userBenefitDetails, index) => (
                            <tr key={index}>
                              <td scope={"col"}>{rowNumber++}</td>
                              <td scope={"col"}>
                                <img
                                  className={"img-icon"}
                                  src={
                                    "https://cdn-icons-png.flaticon.com/512/236/236831.png"
                                  }
                                  alt="User Icon"
                                />
                              </td>
                              <td scope={"col"}>
                                {userBenefitDetails.userHelper.firstName}
                              </td>
                              <td scope={"col"}>
                                {userBenefitDetails.userHelper.lastName}
                              </td>
                              <td scope={"col"}>
                                {formatDate(userBenefitDetails.startDate)}
                              </td>
                              <td scope={"col"}>
                                {formatDate(userBenefitDetails.endDate)}
                              </td>
                              <td scope="col">
                                <span
                                  className={
                                    userBenefitDetails.benefitStatus ===
                                    "INACTIVE"
                                      ? "not-accepted-color"
                                      : "accepted-color"
                                  }
                                >
                                  {userBenefitDetails.benefitStatus ===
                                  "INACTIVE"
                                    ? strings.inactive
                                    : strings.active}
                                </span>
                              </td>
                              <td scope={"col"}>
                                <span
                                  className="material-icons"
                                  onClick={() =>
                                    handleCommentClick(userBenefitDetails)
                                  }
                                >
                                  feedback
                                </span>
                              </td>
                              <td scope={"col"}>
                                <div className={"icon-div"}>
                                  <div
                                    onClick={() => {
                                      handleAddUserClick();
                                      setSelectedUserBenefitDetails(
                                        userBenefitDetails
                                      );
                                    }}
                                    className={"text-dark icon"}
                                    title={"Edit"}
                                  >
                                    <i
                                      className={
                                        "edit-icon-circle material-icons"
                                      }
                                    >
                                      create
                                    </i>
                                  </div>
                                </div>
                              </td>
                              <td scope={"col"}>
                                <div className={"icon-div"}>
                                  <div
                                    onClick={() =>
                                      ConfirmationDelete(
                                        userBenefitDetails.userBenefitId
                                      )
                                    }
                                    className={"text-dark icon"}
                                    title={"Delete"}
                                  >
                                    <i
                                      className={
                                        "material-icons delete-icon-circle"
                                      }
                                    >
                                      delete
                                    </i>
                                  </div>
                                </div>
                              </td>
                              <td scope={"col"}>&nbsp;</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <h3 className={"text-center my-5"}>
                      <i className={"text-secondary no-notification-message"}>
                        {strings.noUsersOnBenefit}
                      </i>
                    </h3>
                  )}
                  <CommentModal
                    show={isCommentModalVisible}
                    handleClose={() => setCommentModalVisible(false)}
                    selectedUserBenefitDetails={selectedUserBenefitDetails}
                  />
                </div>
              </div>
            </div>
          </Modal.Footer>
        )}
      </div>
    </Modal>
  );
};

export default BenefitDetails;
