import axios from "../../axios/axios"

const NotificationRepository = {
    fetchAllNotificationForLoggedUser:() => {
        return axios.get(`/notification/getAllForLoggedUser/`)
    },
    fetchAllNotificationForLoggedUserPaginated:(page,size) => {
        return axios.get(`/notification/getAllForLoggedUserPaginated/${page}/${size}`)
    },
    setReadNotification:(id)=>{
        return axios.put(`/notification/updateStatus/${id}`)
    }
}
export default NotificationRepository;