import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import BenefitsService from "../../../repository/benefitsRepository/BenefitsRepository";
import { strings } from "../../../localization/Localization";
import "../../Events/Events.css";
import "../../../pages/UserDetails/UserDetails.css";
import "./Benefits.css";
import BenefitCardComponent from "./BenefitCard/BenefitCard";
import ApplicationService from "../../../ApplicationService";
import BenefitProposalService from "../../../repository/benefitsRepository/BenefitsProposalRepository";
import AddEditBenefitProposalModal from "./BenefitProposal/AddEditBenefitProposal/AddEditBenefitProposalModal";
import { Tabs } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import BenefitProposal from "./BenefitProposal/BenefitProposal";
import NomenclatureService from "../../../repository/nomenclatureRepository/NomenclatureRepository";

const Benefits = (props) => {
  const [benefitsList, setBenefitsList] = useState([]);
  const [benefitNomenclature, setBenefitNomenclature] = useState({});
  const history = useHistory();
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);
  const [showNomenclatureEntryModal, setShowNomenclatureEntryModal] = useState({});
  const [selectedNomenclatureEntryForEdit,setSelectedNomenclatureEntryForEdit,] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [benefitProposals, setBenefitProposals] = useState([]);
  const [showAddEditBenefitProposalModal, setAddEditBenefitProposalModal] =useState(false);
  const [key, setKey] = useState("allBenefits");

  useEffect(() => {
    if (key === "allBenefits") {
      getAllBenefits();
    } else if (key === "allBenefitProposals") {
      getAllBenefitProposals();
    }
  }, [key]);

  useEffect(() => {
    getNomenclatureByName();
  }, []);

  const getAllBenefits = () => {
    BenefitsService.getAllBenefitNomenclatureEntries().then((data) => {
      setBenefitsList(data.data);
      setExpandedDescriptions(new Array(data.data.length).fill(false));
    });
  };

  const getNomenclatureByName = () => {
    NomenclatureService.getNomenclatureByName("Бенефиции").then((data) => {
      setBenefitNomenclature(data.data);
    });
  };

  const getAllBenefitProposals = () => {
    BenefitProposalService.getAllBenefitProposals().then((data) => {
      setBenefitProposals(data.data);
    });
  };

  const handleAddOrEditBenefitProposal = () => {
    setAddEditBenefitProposalModal(true);
  };

  const handleCloseAddOrEditBenefitModal = () => {
    setAddEditBenefitProposalModal(false);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDate.replace(/\//g, ".");
  };

  const toggleDescription = (index) => {
    const updatedExpandedDescriptions = [...expandedDescriptions];
    updatedExpandedDescriptions[index] = !updatedExpandedDescriptions[index];
    setExpandedDescriptions(updatedExpandedDescriptions);
  };

  const redirectToEditBenefit = (nomenclatureEntry) => {
    setSelectedNomenclatureEntryForEdit(nomenclatureEntry);
    setShowNomenclatureEntryModal(true);
  };

  const handleShowAddUserToBenefitModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddUserToBenefitModal = () => {
    setShowAddModal(false);
  };

  const handleTabSelect = (selectedKey) => {
    setKey(selectedKey);
  };

  return (
    <div>
      <AddEditBenefitProposalModal
        showModal={showAddEditBenefitProposalModal}
        handleClose={handleCloseAddOrEditBenefitModal}
        getBenefitProposals={getAllBenefitProposals}
        
      />

      <div className="w-100 d-flex justify-content-between">
        <div>
          <h1>{strings.benefitsList}</h1>
        </div>

        {ApplicationService.hasRole(["ROLE_ADMINISTRATOR", "ROLE_HR"]) ? (
          <div className={"d-flex ms-auto"}>
            <div className="align-items-center d-flex justify-content-between">
              <div
                className={
                  "btn text-end d-flex align-items-center border-radius add-button test"
                }
                onClick={() => {
                  history.push("/nomenclature", {
                    nomenclatureEntry: "Бенефиции",
                  });
                }}
              >
                <div
                  className={"align-items-center text-white d-flex text-start"}
                >
                  <i className={"material-icons add_button_icon"}>add_box</i>
                </div>
                <div className={"mx-1 text-white"}>{strings.addBenefit}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="align-items-center d-flex ms-2">
            <div
              className={
                "btn text-white text-end d-flex align-items-center border-radius px-xs-0  add-button"
              }
              onClick={handleAddOrEditBenefitProposal}
            >
              <div className={"align-items-center d-flex text-start"}>
                <i className={"material-icons add_button_icon"}>add_box</i>
              </div>
              <div className={"mx-1"}>{strings.addBenefitProposal}</div>
            </div>
          </div>
        )}
      </div>

      <div
        className={
          "user_details_box w-100 mb-4 border border-radius border-light border-5 px-4 pt-4"
        }
      >
        <Tabs
          id="controlled-tab-example"
          className={"w-100 user_details_tabs events-tabs"}
          activeKey={key}
          onSelect={handleTabSelect}
        >
          <Tab
            eventKey="allBenefits"
            title={strings.allBenefits}
            className={"h-100 mt-4"}
          >
            <div className={"w-100 h-100 "}>
              <div className="row">
                {benefitsList.length > 0 ? (
                  benefitsList.map((benefit, index) => (
                    <div className="col-12 col-lg-6 col-xl-4 mb-4" key={index}>
                      <BenefitCardComponent
                        benefit={benefit}
                        benefitNomenclature={benefitNomenclature}
                        index={index}
                        expandedDescriptions={expandedDescriptions}
                        toggleDescription={toggleDescription}
                        formatDate={formatDate}
                      />
                    </div>
                  ))
                ) : (
                  <h3 className={"text-center my-5"}>
                    <i className={"text-secondary no-notification-message"}>
                      {strings.noBenefits}
                    </i>
                  </h3>
                )}
              </div>
            </div>
          </Tab>

          <Tab
            eventKey="allBenefitProposals"
            title={strings.allBenefitProposals}
            className={"h-100 w-100"}
          >
            <div className={"w-100 h-100"}>
              <div className={"row table-responsive mt-4"}>
                <table id="keywords" className={"mb-1"}>
                  <thead>
                    <tr className="userlist">
                      <th scope={"col"}>
                        <b>{strings.name}</b>
                      </th>
                      <th scope={"col w-25"}>{strings.description}</th>
                      <th scope={"col"}>{strings.userEventProposal}</th>
                      <th scope={"col "} className={"pe-4"}>
                        {strings.status}
                      </th>
                      <th scope={"col"} className={"pe-1"}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {benefitProposals.length > 0 &&
                      benefitProposals.map((benefitProposal, index) => (
                        <BenefitProposal
                          benefitProposal={benefitProposal}
                          key={benefitProposal.id}
                          activeTab={key}
                          getAllBenefits={getAllBenefits}
                          getAllBenefitProposals={getAllBenefitProposals}
                         
                        />
                      ))}
                  </tbody>
                </table>
                {benefitProposals.length === 0 && (
                  <h3 className={"text-center my-5"}>
                    <i className={"text-secondary no-notification-message"}>
                      {strings.noBenefitProposals}
                    </i>
                  </h3>
                )}
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Benefits;
