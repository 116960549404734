import ApplicationService from '../../../../../ApplicationService';
import React, { useEffect, useState } from 'react';
import NomenclatureService from '../../../../../repository/nomenclatureRepository/NomenclatureRepository';
import { strings } from '../../../../../localization/Localization';
import './UserWorkDayView.css';

const UserWorkDayView = (props) => {
  const [nomenclatureWorkDayList, setNomenclatureWorkDayList] = useState([]);

  useEffect(() => {
    getAllNomenclatureEntriesForWorkDay();
  }, []);

  const getAllNomenclatureEntriesForWorkDay = () => {
    NomenclatureService.getAllNomenclatureEntryByNomenclatureName('Работни денови')
      .then((data) => {
        let nomenclatureConvert = data.data.map((n) => {
          return {
            value: n.id,
            label: ApplicationService.getActiveLanguage() === 'mk' ? n.labelMk : n.labelEn,
            name: n.name
          };
        });
        setNomenclatureWorkDayList(nomenclatureConvert);
      });
  };

  return (
    <div className='pl-3'>
      <i className='pt-1 mb-3 hours-daily d-inline'>{strings.hoursDailyEngagement}</i>
      <div className='w-75 mt-1'>
        <div className='d-flex justify-content-between'>
          {nomenclatureWorkDayList.map((day) => (
            <div key={day.value} className='badge text-bg-secondary day-hours-badge mb-2 me-2'>
              {day.label.substring(0, 3)}:{' '}
              {props.userWorkDays.find(
                (workDay) => workDay.workDayNomenclatureEntryId === day.value
              )?.workHours !== undefined ? props.userWorkDays.find(
                (workDay) => workDay.workDayNomenclatureEntryId === day.value
              )?.workHours : '0'}{' '}
              <i className={'material-icons mi-access-time working-hours-icon'}>
                access_time
              </i>
            </div>
          ))}
        </div>
      </div>
    </div>

  );
};

export default UserWorkDayView;
