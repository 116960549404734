import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import MultiSelectDropdown from "../MultiSelectDropdown";
import ReligionUnitService from "../../repository/religionUnit/ReligionUnitRepository";
import EthnicityService from "../../repository/ethnicityRepository/EthnicityRepository";
import {strings} from "../../localization/Localization";
import OrganisationHierarchyService from "../../repository/hierarchyRepository/organisationHierarchyRepository";
import PositionHierarchyRepository from "../../repository/hierarchyRepository/positionHierarchyRepository";
import "./UserFilter.css"
import RolesRepository from "../../repository/rolesRepository/RolesRepository";

const FilterEvidention = (props) => {

    const [selectedReligion, setSelectedReligion] = useState([]);

    const [selectedEthnicity, setSelectedEthnicity] = useState([]);

    const [religions, setReligions] = useState([]);

    const [ethnicity, setEthnicity] = useState([]);

    const [listEthnicityForDropDown, setListEthnicityForDropDown] = useState([]);

    const [listReligionForDropDown, setListReligionForDropDown] = useState([]);

    const [organisationsList, setOrganisationsList] = useState([]);

    const [positionsList, setPositionsList] = useState([]);

    const [organisationsListForDropDown, setOrganisationsListForDropDown] = useState([]);

    const [positionsListForDropDown, setPositionsListForDropDown] = useState([]);

    const [selectedPositions, setSelectedPositions] = useState([]);

    const [selectedOrganisations, setSelectedOrganisations] = useState([]);

    const [rolesList, setRolesList] = useState([]);

    const [rolesListForDropDown, setRolesListForDropDown] = useState([]);

    const [selectedRoles, setSelectedRoles] = useState([]);

    const [resetAllSelectInput, setResetAllSelectInput] = useState(true);

    const [spinnerForLoadData, setSpinnerForLoadData] = useState(false);


    const [formData, updateFormData] = React.useState({
        firstName: "", lastName: "", parentName: "", email: "", hrmsIdNumber: ""
    });


    useEffect(() => {
        getAllReligion();
        getAllEthnicity();
        getAllOrganisation();
        getAllPosition();
        getAllRoles();
    }, []);

    useEffect(() => {
        if (selectedOrganisations.length > 0) {
            // setSelectedPositions([]);
            getAllPositionsForOrganisation(selectedOrganisations);
        } else {
            setSelectedPositions([]);
            getAllPositionsForOrganisation();
        }
    }, [selectedOrganisations]);

    useEffect(() => {
        setListReligionForDropDown(convertReligionForDropDown(religions))
    }, [religions]);

    useEffect(() => {
        setListEthnicityForDropDown(convertEthnicityForDropDown(ethnicity))
    }, [ethnicity]);

    useEffect(() => {
        setOrganisationsListForDropDown(convertHierarchyForDropDown(organisationsList))
    }, [organisationsList]);

    useEffect(() => {
        setPositionsListForDropDown(convertHierarchyForDropDown(positionsList))
    }, [positionsList]);
    useEffect(() => {
        setRolesListForDropDown(convertRolesForDropDown(rolesList));
    }, [rolesList]);

    const getAllReligion = () => {
        ReligionUnitService.fetchReligionUnit(0, 100)
            .then((data) => {
                setReligions(data.data.content)
            })
    };

    const getAllEthnicity = () => {
        EthnicityService.fetchEthnicities(0, 100)
            .then((data) => {
                setEthnicity(data.data.content)
            })
    };

    const getAllOrganisation = () => {
        OrganisationHierarchyService.getHierarchy()
            .then((data) => {
                setOrganisationsList(data.data);
            })
    };

    const getAllPosition = () => {
        PositionHierarchyRepository.getHierarchy()
            .then((data) => {
                setPositionsList(data.data)
            })
    };

    const getAllRoles = () => {
        RolesRepository.fetchRoles()
            .then((data)=>{
                setRolesList(data.data);
        })
    };

    const getAllPositionsForOrganisation = (organisationId = null) => {
        setSpinnerForLoadData(true);
        if (organisationId !== null) {
            OrganisationHierarchyService.fetchAllPositionForListOfOrganisations(organisationId)
                .then((data) => {
                    setSpinnerForLoadData(false);
                    setPositionsList(data.data);
                    // if(selectedPositions.length>0){
                    //     let tempSelectedPositions=[];
                    //     for(let position in data.data){
                    //         selectedPositions.map((s)=>{
                    //             if(s.value===position.id){
                    //                 tempSelectedPositions.push(position)
                    //             }
                    //         })
                    //     }
                    //     setSelectedPositions(convertHierarchyForDropDown(tempSelectedPositions))
                    // }
                }).catch(() => {
                setSpinnerForLoadData(false);
            })

        } else {
            PositionHierarchyRepository.getHierarchy()
                .then((data) => {
                    setSpinnerForLoadData(false);
                    setPositionsList(data.data)
                }).catch(() => {
                setSpinnerForLoadData(false);
            })
        }
    };

    const handleChange = (e) => {
        setResetAllSelectInput(false);
        updateFormData({
            ...formData, [e.target.name]: e.target.value
        })
    };

    const filterUser = (firstName, lastName, parentName, email, religion, ethnicity, organisationsIds, positionsIds, reset, search, hrmsIdNumber,roles) => {
        props.loadUserFiltred(firstName, lastName, parentName, email, roles, religion, ethnicity, organisationsIds, positionsIds, reset, search, hrmsIdNumber)
    };

    const onFormSubmit = (e, reset = false, search = false) => {
        e.preventDefault();
        search = !reset;
        let firstName = formData.firstName !== "" ? formData.firstName : null;
        let lastName = formData.lastName !== "" ? formData.lastName : null;
        let email = formData.email !== "" ? formData.email : null;
        let parentName = formData.parentName !== "" ? formData.parentName : null;
        let religionIds = selectedReligion.length > 0 ? selectedReligion : [];
        let ethnicityIds = selectedEthnicity.length > 0 ? selectedEthnicity : [];
        let positionsIds = selectedPositions.length > 0 ? selectedPositions : [];
        let organisationsIds = selectedOrganisations.length > 0 ? selectedOrganisations : [];
        let roles=selectedRoles.length > 0 ? selectedRoles : [];
        let hrmsId = formData.hrmsIdNumber !== "" ? formData.hrmsIdNumber : null;
        if (reset) {
            firstName = null;
            lastName = null;
            email = null;
            parentName = null;
            religionIds = [];
            ethnicityIds = [];
            positionsIds = [];
            organisationsIds = [];
            roles=[];
            hrmsId = null;
        }
        filterUser(firstName, lastName, parentName, email, religionIds, ethnicityIds, organisationsIds, positionsIds, reset, search, hrmsId,roles);
    };

    const resetFilterData = (event) => {
        updateFormData({
            firstName: "", lastName: "", parentName: "", email: "", hrmsIdNumber: ""
        })
        setSelectedPositions([]);
        setSelectedEthnicity([]);
        setSelectedOrganisations([]);
        setSelectedReligion([]);
        setResetAllSelectInput(true);
        props.resetFilterData();
        onFormSubmit(event, true);
    };

    const getSelectedReligionFromDropDown = (selectedOptions) => {
        setSelectedReligion(selectedOptions.map(el => el.value));
    };
    const getSelectedEthnicityFromDropDown = (selectedOptions) => {
        setSelectedEthnicity(selectedOptions.map(el => el.value));
    };
    const getSelectedOrganisation = (selectedOptions) => {
        setSelectedOrganisations(selectedOptions.map(el => el.value));
    };
    const getSelectedPosition = (selectedOptions) => {
        setSelectedPositions(selectedOptions.map(el => el.value));
    };
    const getSelectedRoles=(selectedOptions)=>{
        setSelectedRoles(selectedOptions.map(el => el.value));
    };

    const convertEthnicityForDropDown = (data) => {
        var convertDataList = []
        for (var i = 0; i < data.length; i++) {
            convertDataList[i] = {
                value: data[i].id, label: data[i].label_mk
            }
        }
        return convertDataList
    };

    const convertReligionForDropDown = (data) => {
        var convertDataList = []
        for (var i = 0; i < data.length; i++) {
            convertDataList[i] = {
                value: data[i].id, label: data[i].labelMk
            }
        }
        return convertDataList
    };

    const convertHierarchyForDropDown = (data) => {
        var convertDataList = []
        for (var i = 0; i < data.length; i++) {
            convertDataList[i] = {
                value: data[i].id, label: data[i].name
            }
        }
        return convertDataList
    };

    const convertRolesForDropDown = (data) => {
        var convertDataList = []
        for (var i = 0; i < data.length; i++) {
            convertDataList[i] = {
                value: data[i].id, label: data[i].label
            }
        }
        return convertDataList
    };

    return (<>
        <div className="card border-secondary mb-3">
            <div className="card-header justify-content-center">
                <div className="m-2 p-2 text-center justify-content-center">
                    <Form onSubmit={onFormSubmit}>
                        <Row className="d-flex justify-content-center">
                            <Col xs={12} md={4} className="d-flex justify-content-center">
                                <Form.Group className="mb-3" controlId='firstName'>
                                    <Form.Label className="d-flex justify-content-start">{strings.name}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='firstName'
                                        placeholder={strings.filterName}
                                        onChange={handleChange}
                                        value={formData.firstName}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group className="mb-2" controlId='parentName'>
                                    <Form.Label
                                        className="d-flex justify-content-start">{strings.parentName}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='parentName'
                                        placeholder={strings.filterParent}
                                        onChange={handleChange}
                                        value={formData.parentName}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group className="mb-2" controlId='lastName'>
                                    <Form.Label
                                        className="d-flex justify-content-start">{strings.lastName}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='lastName'
                                        placeholder={strings.filterSurname}
                                        onChange={handleChange}
                                        value={formData.lastName}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-4 mt-2 w-75 mx-auto" controlId='hrmsId'>
                                    <Form.Label
                                        className={"justify-content-start text-start align-items-start d-flex"}>{strings.hrmsIdNumber}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='hrmsIdNumber'
                                        placeholder={strings.filterIdentificationNumber}
                                        onChange={handleChange}
                                        value={formData.hrmsIdNumber}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-4 mt-2 w-75 mx-auto" controlId='email'>
                                    <Form.Label
                                        className={"justify-content-start text-start align-items-start d-flex"}>{strings.email}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='email'
                                        placeholder={strings.filterEmail}
                                        onChange={handleChange}
                                        value={formData.email}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-2 text-start w-75 mx-auto " controlId='religion'>
                                    <Form.Label>{strings.religion}</Form.Label>
                                    <MultiSelectDropdown handleSelection={getSelectedReligionFromDropDown}
                                                         listForDropDown={listReligionForDropDown}
                                                         defaultListForDropdown={props.defaultListForDropdown}
                                                         resetData={resetAllSelectInput}
                                                         setResetData={setResetAllSelectInput}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-2 text-start w-75 mx-auto" controlId='ethnicity'>
                                    <Form.Label>{strings.ethnicity}</Form.Label>
                                    <MultiSelectDropdown handleSelection={getSelectedEthnicityFromDropDown}
                                                         listForDropDown={listEthnicityForDropDown}
                                                         defaultListForDropdown={props.defaultListForDropdown}
                                                         resetData={resetAllSelectInput}
                                                         setResetData={setResetAllSelectInput}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className={"my-3"}>
                            <Col xs={12} md={6}>
                                <Form.Group
                                    className={"mb-2 text-start w-75 mx-auto  " + (spinnerForLoadData ? "cursor-spinner" : "")}
                                    controlId='religion'>
                                    <Form.Label>{strings.organisation}</Form.Label>
                                    <MultiSelectDropdown handleSelection={getSelectedOrganisation}
                                                         listForDropDown={organisationsListForDropDown}
                                                         defaultListForDropdown={props.defaultListForDropdown}
                                                         resetData={resetAllSelectInput}
                                                         setResetData={setResetAllSelectInput}
                                                         isDisabled={spinnerForLoadData}/>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group
                                    className={"mb-2 text-start w-75 mx-auto " + (spinnerForLoadData ? "cursor-spinner" : "")}
                                    controlId='ethnicity'
                                    disabled={true}>
                                    <Form.Label>{strings.workPosition}</Form.Label>
                                    <MultiSelectDropdown handleSelection={getSelectedPosition}
                                                         listForDropDown={positionsListForDropDown}
                                                         defaultListForDropdown={selectedPositions}
                                                         resetData={resetAllSelectInput}
                                                         setResetData={setResetAllSelectInput}
                                                         isDisabled={spinnerForLoadData}/>
                                </Form.Group>
                            </Col>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-4 mt-2 w-40 mx-auto" controlId='roles'>
                                        <Form.Label
                                            className={"justify-content-start text-start align-items-start d-flex"}>{strings.roles}</Form.Label>
                                        <MultiSelectDropdown handleSelection={getSelectedRoles}
                                                             listForDropDown={rolesListForDropDown}
                                                             defaultListForDropdown={props.defaultListForDropdown}
                                                             resetData={resetAllSelectInput}
                                                             setResetData={setResetAllSelectInput}
                                                             isDisabled={spinnerForLoadData}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <div className={"mt-4"}>
                                {!props.resetIsLoading ? (<div
                                    className="btn text-white align-bottom align-items-end user-filter__reset_button me-5 user-filter__button_size"
                                    onClick={event => {
                                        resetFilterData(event);
                                    }}>
                                    {strings.reset}
                                </div>) : (<div
                                    className="btn text-white align-bottom align-items-end user-filter__reset_button user-filter__reset_button__disabled me-5 user-filter__button_size"
                                    type="button" disabled={true}>
                                        <span className="spinner-grow spinner-grow-sm me-1" role="status"
                                              aria-hidden="true"></span>
                                    {strings.reset}...
                                </div>)}
                                {!props.searchIsLoading ? (<div
                                    className="btn text-white align-bottom align-items-end add-button user-filter__button_size"
                                    onClick={onFormSubmit}>
                                    {strings.search}
                                </div>) : (<div
                                    className="btn text-white align-bottom align-items-end add-button user-filter_search_button__disabled user-filter__button_size"
                                    type="button" disabled={true}>
                                        <span className="spinner-grow spinner-grow-sm me-1" role="status"
                                              aria-hidden="true"></span>
                                    {strings.search}...
                                </div>)

                                }

                            </div>
                        </Row>
                    </Form>
                </div>
            </div>
        </div>
    </>);
}
export default FilterEvidention;
