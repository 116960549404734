import MainLayout from "../../components/Layout/MainLayout";
import React, {useEffect, useState} from "react";
import UserRepository from "../../repository/userRepository/UserRepository";
import {strings} from "../../localization/Localization";
import {Button, Form} from "react-bootstrap";
import "./MyProfile.css"
import UserAddService from "../../repository/userRepository/UserAddRepository";
import swal from "sweetalert";
import ApplicationService from "../../ApplicationService";
import UserService from "../../repository/userRepository/UserRepository";
import AvatarEditorModal from "../../components/AvatarEditorModal/AvatarEditorModal";

const MyProfile = () => {

    const [userData, setUserData] = useState({});

    const [isOpenChangePasswordForm, setIsOpenChangePasswordForm] = useState(false);


    const [changePasswordForm, setChangePasswordForm] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    })

    const [profileFormData, setProfileFormData] = useState({
        firstName: "",
        parentName: "",
        lastName: "",
        phoneNumber: "",
        address: ""
    })

    const [profileImage, setProfileImage] = useState(null);

    const [showEditorModal, setShowEditorModal] = useState(false);

    const [imageForEditor, setImageForEditor] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const bothFieldsAreFilled = changePasswordForm.newPassword && changePasswordForm.confirmNewPassword;

    const passwordNotMatchException = changePasswordForm.newPassword === changePasswordForm.confirmNewPassword;

    useEffect(() => {
        getDetailsForUser();
        getProfileImageForLoggedUser();
    }, []);

    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);
        }
    }, [isLoading])


    const getDetailsForUser = () => {
        UserRepository.getUserDetails().then(res => {
            setUserData(res.data)
        });
    }

    const getProfileImageForLoggedUser = () => {
        UserService.getImageForLoggedUser().then(async (data) => {
            setProfileImage(data.data.bytes)
        }).catch(() => {
            setProfileImage("https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg");
        })
    };

    const arrayBufferToBase64 = (buffer) => {
        let binary = "";
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };


    const successfulAlert = (text) => {
        swal(text, {
            text: text,
            icon: "success",
        });
    };

    const errorAlert = (text) => {
        swal({
            title: strings.oops,
            text: text,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        })
    };

    const handleClose = () => {
        setIsLoading(true);
        setShowEditorModal(false);
    };

    const handleOpenChangePasswordForm = () => {
        setIsOpenChangePasswordForm(!isOpenChangePasswordForm);
    };

    const handleChangePasswordFormChange = (e) => {
        setChangePasswordForm({
            ...changePasswordForm,
            [e.target.name]: e.target.value
        })
    };

    const handleChangeEditFormData = (e) => {
        setProfileFormData({
            ...profileFormData,
            [e.target.name]: e.target.value
        })
    };

    const OnChangePasswordFormSubmit = (e) => {
        e.preventDefault();
        changePassword();
    };

    const onSaveNewImageCropped = async (img) => {
        setIsLoading(true);
        const formData = new FormData();
        let file = img.toDataURL();
        formData.append("photo", file)
        UserService.changeProfileImageFromByte(file).then((data) => {
            getProfileImageForLoggedUser();
            setIsLoading(false);
            setShowEditorModal(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }

    function b64_to_utf8(str) {
        return btoa(str)
    }


    const changeProfileImageHandler = async (e) => {
        if (e.target.files[0]) {
            const formData = new FormData();
            const file = e.target.files[0];
            formData.append("photo", file)
            setShowEditorModal(true);
            setImageForEditor(file);
        }
    };


    const onSubmitChangeProfile = (e) => {
        e.preventDefault();
        const name = profileFormData.firstName !== "" ? profileFormData.firstName : userData.firstName;
        const lastName = profileFormData.lastName !== "" ? profileFormData.lastName : userData.lastName;
        const parentName = profileFormData.parentName !== "" ? profileFormData.parentName : userData.parentName;
        const phoneNumber = profileFormData.phoneNumber !== "" ? profileFormData.phoneNumber : userData.person.phoneNumber;
        const address = profileFormData.address !== "" ? profileFormData.address : userData.person.address;

        UserService.editUser(userData.id, userData.email, name, lastName, parentName, null, phoneNumber, address)
            .then((data) => {
                getDetailsForUser();
                successfulAlert(strings.userEditSuccessful)
            }).catch(() => {
            errorAlert(strings.somethingWrong)
        })
    }

    const changePassword = () => {
        UserAddService.changePasswordWithoutToken(userData.email, changePasswordForm.oldPassword, changePasswordForm.newPassword, changePasswordForm.confirmNewPassword)
            .then((data) => {
                successfulAlert(strings.passwordChangeSuccessful);
                setChangePasswordForm({
                    oldPassword: "",
                    newPassword: "",
                    confirmNewPassword: ""
                })
            }).catch((e) => {
            let error = "";
            if (e.response.data == "Incorrect password") {
                error = strings.incorrectPassword;
            } else if (e.response.data == "Password do not match exception") {
                error = strings.passwordsConfirmationDoNotMatch;
            } else {
                error = strings.somethingWrong;
            }
            errorAlert(error);
        })
    }

    return (
        <div className={"h-100"}>
            <MainLayout>
                <div className="container rounded bg-white mt-5 mb-5">
                    <div className="row  border-5 border-light border border-radius">
                        <div className="col-md-5 border-right ">
                            <div className={"profile-avatar-wrapper"}>
                                <div className="d-flex flex-column align-items-center text-center p-5 ">

                                    <div className="profile-pic mt-4">
                                        <label className="-label" htmlFor="file">
                                            <label className={"material-icons text-white"}
                                                   style={{fontSize: "18px"}}>add_a_photo</label>
                                            <span>Change Image</span>
                                        </label>
                                        {!isLoading &&
                                            <input id="file" type="file" onChange={changeProfileImageHandler}/>
                                        }

                                        {profileImage === "https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" ?
                                            (
                                                <img id="output" className="rounded-circle" width="200px"
                                                     height={"200px"}
                                                     src={"https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"}
                                                />) : (
                                                <img id="output" className="rounded-circle" width="200px"
                                                     height={"200px"}
                                                     src={(`data:image/*;base64,${profileImage}`)}
                                                />
                                            )

                                        }


                                    </div>
                                    <AvatarEditorModal showModal={showEditorModal} image={imageForEditor}
                                                       onSaveImageCropped={onSaveNewImageCropped}
                                                       setNewPhoto={setImageForEditor}
                                                       handleClose={handleClose}/>


                                    <span className="font-weight-bold mt-2">
                                {userData.firstName ? userData.firstName : ""} {userData.lastName ? userData.lastName : ""}
                            </span><span
                                    className="text-black-50">{userData.email ? userData.email : ""}</span><span> </span>
                                </div>
                            </div>


                            <div className="mt-3 ms-4 text-center">
                                {!isOpenChangePasswordForm ? (
                                        <h6 className={"text-center d-flex justify-content-center"}><a
                                            className={"text-blue d-flex align-items-center cursor-pointer text-center"}
                                            onClick={handleOpenChangePasswordForm}>{strings.changePassword}<i
                                            className={"material-icons"}>arrow_drop_up</i></a></h6>)
                                    :
                                    (
                                        <h6 className={"text-center d-flex justify-content-center"}><a
                                            className={"text-secondary d-flex align-items-center cursor-pointer text-center"}
                                            onClick={handleOpenChangePasswordForm}>{strings.changePassword}<i
                                            className={"material-icons"}>arrow_drop_down</i></a></h6>
                                    )
                                }
                            </div>
                            <div
                                className={"change_password_wrapper w-90 mx-auto border-3 border border-radius border-light p-4 my-3  " + (!isOpenChangePasswordForm ? "expandable" : "slideUp_animation")}>
                                <h4 className={"text-center mb-3"}>{strings.changePassword}</h4>
                                <Form onSubmit={OnChangePasswordFormSubmit}>
                                    <div className=" mb-3"><label
                                        className="labels">{strings.password}<span className="text-danger">*</span></label>
                                        <input type="password"
                                               className="form-control"
                                               required={true}
                                               value={changePasswordForm.oldPassword}
                                               onChange={handleChangePasswordFormChange}
                                               name={"oldPassword"}
                                               placeholder={strings.enterOldPassword}/>
                                    </div>
                                    <div className="mb-3"><label
                                        className="labels">{strings.newPassword}<span className="text-danger">*</span></label><input
                                        type="password" className="form-control"
                                        name={"newPassword"}
                                        value={changePasswordForm.newPassword}
                                        required={true}
                                        onChange={(e) => {
                                            handleChangePasswordFormChange(e);
                                        }}
                                        placeholder={strings.enterNewPassword}
                                    />
                                    </div>
                                    <div className=" mb-3"><label
                                        className="labels">{strings.confirmNewPassword}<span className="text-danger">*</span></label><input
                                        type="password" className="form-control"
                                        name={"confirmNewPassword"}
                                        value={changePasswordForm.confirmNewPassword}
                                        required={true}
                                        onChange={(e) => {
                                            handleChangePasswordFormChange(e);
                                        }}
                                        placeholder={strings.enterNewPassword}/>
                                    </div>

                                    {bothFieldsAreFilled ?
                                        (passwordNotMatchException ?
                                                (
                                                    <div
                                                        className={'d-inline-flex text-center justify-content-center'}>
                                                        <i className="material-icons text-success">check_circle</i>
                                                        &nbsp; {strings.passwordsMatch}
                                                    </div>
                                                ) :
                                                (
                                                    <div
                                                        className={'d-inline-flex text-center justify-content-center'}>
                                                        <i className="material-icons text-danger">report</i>
                                                        &nbsp;{strings.passwordsDoNotMatch}
                                                    </div>
                                                )
                                        ) : null
                                    }
                                    {passwordNotMatchException && bothFieldsAreFilled &&
                                        <div className="mt-1  text-center">
                                            <button className={"btn text-white mx-3 border-radius add-button "}
                                                    type="submit">{strings.savePassword}
                                            </button>
                                        </div>
                                    }
                                    {!bothFieldsAreFilled &&
                                        <div className="mt-1 pt-2 text-center">
                                            <button className={"btn text-white mx-3 border-radius add-button mt-4"}
                                                    type="submit">{strings.savePassword}
                                            </button>
                                        </div>
                                    }
                                </Form>

                            </div>
                        </div>
                        <div className="col-md-5  mt-5">
                            <Form onSubmit={onSubmitChangeProfile}>
                                <div className="my-5 p-4 border-3 border border-light border-radius">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <h4 className="text-right">{strings.profile}</h4>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-4 mb-3"><label
                                            className="labels">{strings.name}</label>
                                            <input type="text"
                                                   className="form-control"
                                                   name={"firstName"}
                                                   defaultValue={userData.firstName ? userData.firstName : ""}
                                                   onChange={handleChangeEditFormData}/>
                                        </div>
                                        <div className="col-md-4 mb-3"><label
                                            className="labels">{strings.fatherName}</label><input
                                            type="text" className="form-control "
                                            defaultValue={userData?.parentName}
                                            name={"parentName"}
                                            onChange={handleChangeEditFormData}
                                            placeholder="father name"/>
                                        </div>
                                        <div className="col-md-4 mb-3"><label
                                            className="labels">{strings.lastName}</label><input
                                            type="text" className="form-control "
                                            name={"lastName"}
                                            defaultValue={userData.lastName ? userData.lastName : ""}
                                            placeholder="surname"
                                            onChange={handleChangeEditFormData}/>
                                        </div>

                                        <div className="col-md-12 my-2"><label
                                            className="labels">{strings.email}</label><input
                                            type="text" className="form-control" placeholder="enter email"
                                            defaultValue={userData.email ? userData.email : ""} disabled/>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 my-2">
                                            <label className="labels">{strings.phoneNumber}</label><input
                                            type="text" className="form-control" placeholder="enter phone number"
                                            name={"phoneNumber"}
                                            defaultValue={userData.phoneNumber ? userData.phoneNumber : ""}
                                            onChange={handleChangeEditFormData}/>
                                        </div>
                                        <div className="col-md-12 my-2">
                                            <label className="labels">{strings.address}</label>
                                            <input type="text"
                                                   className="form-control"
                                                   name={"address"}
                                                   placeholder="enter address"
                                                   onChange={handleChangeEditFormData}
                                                   defaultValue={userData.address ? userData.address : ""}/>
                                        </div>
                                        <div className="col-md-12 my-2">
                                            <label className="labels">{strings.roles}</label>
                                            <input type="text"
                                                   className="form-control"
                                                   placeholder="role"
                                                   defaultValue={userData.roles ? userData.roles : ""}
                                                   disabled/>
                                        </div>

                                        <div className="col-md-12 my-2"><label
                                            className="labels">{strings.position}</label><input
                                            type="text" className="form-control"
                                            defaultValue={userData.position?.name ? userData.position?.name : ""}
                                            disabled/>
                                        </div>
                                        <div className="col-md-12 my-2"><label
                                            className="labels">{strings.organization}</label><input
                                            type="text" className="form-control"
                                            defaultValue={userData.organisation?.name ? userData.organisation?.name : ""}
                                            disabled/>
                                        </div>
                                    </div>
                                    <div className="mt-4 text-center">
                                        <button className={"btn text-white mt-3 mx-3 border-radius add-button"}
                                                type="submit">{strings.saveProfile}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </MainLayout>
        </div>
    )
}

export default MyProfile;