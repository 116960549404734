import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import ApplicationService from "../../ApplicationService";
import Logo from "../../assets/images/logo-inteligenta.png"
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {strings} from "../../localization/Localization";
import AuthenticationRepository from "../../repository/authentication/AuthenticationRepository";
import "./ForgotPassword.css"

const ForgotPassword = () => {

    const [email, setEmail] = useState();

    const [showSuccessResponse, setShowSuccessResponse] = useState(false);

    const [showErrorResponse, setShowErrorResponse] = useState(false);


    const onFormSubmit = (e) => {
        e.preventDefault();
        AuthenticationRepository.forgotPassword(email).then((data) => {
            setShowErrorResponse(false);
            setShowSuccessResponse(true);

        }).catch((err) => {
            if (err.response.data.message === "UserNotFoundException") {
                setShowSuccessResponse(false);
                setShowErrorResponse(true);
            }
        });
    };

    return (
        <div className="back">
            <Form className="form_newpassword" onSubmit={onFormSubmit}>
                <div className="d-flex justify-content-center">
                    <img src={Logo}
                         className={"logo"}/>
                </div>
                <hr/>
                <h3 className={"text-center mb-2 forgotPassword_header"}>{strings.forgotPassword}</h3>
                {showErrorResponse &&
                    <div className={"alert alert-danger mt-4 mb-2"}> {strings.accountNotRegistered}!</div>
                }
                {showSuccessResponse &&
                    <div className={"alert alert-success mt-4 mb-2"}>{strings.passwordChangeMessageSuccessfully}</div>
                }
                <Form.Group className="mb-5" controlId="formBasicPassword">
                    <Form.Label className={"ms-1"}>{strings.email}<span className="text-danger">*</span></Form.Label>
                    <Form.Control type="email" className={"email"} placeholder="Enter email address"
                                  aria-required={"true"}
                                  onChange={(event) => setEmail(event.target.value)}/>
                </Form.Group>
                <div className={"d-flex justify-content-between"}>
                    <Link to={"/login"} className={"cancel btn text-white border-radius px-4"}>{strings.back}</Link>
                    <div className={'d-inline-flex text-center justify-content-center'}>
                        <Button className={"add-button border-radius px-4"} type="submit">
                            {strings.sendRequest}
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
}
export default ForgotPassword;