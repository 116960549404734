const FormatDate = {
    formatDate: (dateString) => {
        if (dateString != null){
            const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
            const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
            return formattedDate.replace(/\//g, '.');
        }
    }
}
export default FormatDate;
