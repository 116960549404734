import ApplicationService from '../../../../../ApplicationService';
import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import NomenclatureService from '../../../../../repository/nomenclatureRepository/NomenclatureRepository';
import { strings } from '../../../../../localization/Localization';
import './UserWorkDay.css';

const UserWorkDay = (props) => {
  const [nomenclatureWorkDayList, setNomenclatureWorkDayList] = useState([]);

  useEffect(() => {
    getAllNomenclatureEntriesForWorkDay();
  }, []);

  const getAllNomenclatureEntriesForWorkDay = () => {
    NomenclatureService.getAllNomenclatureEntryByNomenclatureName('Работни денови')
      .then((data) => {
        let nomenclatureConvert = data.data.map((n) => {
          return {
            value: n.id,
            label: ApplicationService.getActiveLanguage() === 'mk' ? n.labelMk : n.labelEn,
          };
        });
        setNomenclatureWorkDayList(nomenclatureConvert);
      });
  };

  const handleWorkHoursUpdate = (dayId, hours) => {
    props.onWorkHoursUpdate(dayId, hours);
  };

  return (
    <React.Fragment>
      {nomenclatureWorkDayList.map((day) => (
        <Form.Group className='mb-3' key={day.value}>
          <Card className='p-3'>
            <Form.Label className={'d-flex justify-content-center'}>
              {day.label}
            </Form.Label>
            <div className={'d-flex align-item-center'}>
              <i className={'material-icons mi-watch-later align-self-center working-hours-icon'}>
                watch_later
              </i>
              <Form.Control
                type={'text'}
                placeholder={strings.hours}
                inputMode='numeric'
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^\d]|9/g, '');
                }}
                maxLength='1'
                className='working-hours-control text-center'
                value={
                  props.userWorkDays.find(
                    (workDay) => workDay.workDayNomenclatureEntryId === day.value
                  )?.workHours !== undefined ? props.userWorkDays.find(
                    (workDay) => workDay.workDayNomenclatureEntryId === day.value
                  )?.workHours : '0'
                }
                onChange={(e) =>
                  handleWorkHoursUpdate(day.value, e.target.value)
                }
              />
            </div>
          </Card>
        </Form.Group>
      ))}
    </React.Fragment>
  );
};

export default UserWorkDay;
