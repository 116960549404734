import { useState } from "react";
import React, { useEffect } from "react";
import { strings } from "../../../localization/Localization";
import NomenclatureService from "../../../repository/nomenclatureRepository/NomenclatureRepository";
import EquipmentRepository from "../../../repository/equipmentReposituory/EquipmentRepository";
import RequestEquipmentModal from "../EquipmentRequest/RequestEquipmentModal/RequestEquipmentModal";
import MyEquipmentCardComponent from "../MyEquipmentCardComponent/MyEquipmentCardComponent";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EquipmentProposalRepository from "../../../repository/equipmentReposituory/EquipmentProposalRepository";
import "./MyEquipment.css";

const MyEquipment = () => {
  const [equipmentList, setEquipmentList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);
  const [equipmentNomenclature, setEquipmentNomenclature] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [myEquipmentProposals, setMyEquipmentProposals] = useState([]);
  const [key, setKey] = useState("myEquipment");

  useEffect(() => {
    if (key === "myEquipment") {
      getMyEquipment();
    } else if (key === "myEquipmentProposals") {
      getAllEquipmentProposalsForLoggedUser();
    }
  }, [key]);

  useEffect(() => {
    getNomenclatureByname();
  }, []);

  const getMyEquipment = () => {
    EquipmentRepository.getAllEquipmentsForLoggedUser().then((data) => {
      setEquipmentList(data.data);
      setExpandedDescriptions(
        new Array(data.data.description?.length).fill(false)
      );
    });
  };

  const getAllEquipmentProposalsForLoggedUser = () => {
    EquipmentProposalRepository.getAllEquipmentProposalsForLoggedUser().then(
      (data) => {
        setMyEquipmentProposals(data.data);
      }
    );
  };

  const getNomenclatureByname = () => {
    NomenclatureService.getNomenclatureByName("Опрема").then((data) => {
      setEquipmentNomenclature(data.data);
    });
  };

  const handleShow = () => {
    setShowAddModal(true);
    setShowModal(true);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDate.replace(/\//g, ".");
  };

  const showDescription = (index) => {
    const updatedExpandedDescriptions = [...expandedDescriptions];
    updatedExpandedDescriptions[index] = !updatedExpandedDescriptions[index];
    setExpandedDescriptions(updatedExpandedDescriptions);
  };

  const handleTabSelect = (selectedKey) => {
    setKey(selectedKey);
  };

  const handleClose = () => {
    setShowAddModal(false);
  };

  const checkStatus = (status) => {
    switch (status) {
      case "APPROVED":
        return strings.accepted;
      case "REJECTED":
        return strings.notAccepted;
      case "PENDING":
        return strings.pending;
      default:
        return "";
    }
  };

  const getStatusColorClass = (status) => {
    switch (status) {
      case "APPROVED":
        return "accepted-color";
      case "REJECTED":
        return "not-accepted-color";
      case "PENDING":
        return "pending-color";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="w-100 d-flex justify-content-between">
        <div>
          <h1>{strings.myEquipment}</h1>
        </div>

        <div className="align-items-center d-flex justify-content-between">
          <div
            className={
              "btn text-end d-flex align-items-center border-radius add-button test"
            }
            onClick={handleShow} //set  send request modal
          >
            <div className={"align-items-center text-white d-flex text-start"}>
              <i className={"material-icons add_button_icon"}>add_box</i>
            </div>
            <div className={"mx-1 text-white"}>
              {strings.addEquipmentProposal}
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          "user_details_box w-100 mb-4 border border-radius border-light border-5 px-4 pt-4"
        }
      >
      <Tabs
        id="controlled-tab-example"
        className={"w-100 user_details_tabs myEquipment-tabs"}
        activeKey={key}
        onSelect={handleTabSelect}
      >
        <Tab
          eventKey="myEquipment"
          title={strings.myEquipment}
          className={"h-100 "}
        >
          <div className="user_details_box w-100 mb-4 border border-radius border-light border-5 px-4">
            <div className="w-100 mt-3 h-100">
              <div className="row">
                {equipmentList.map((item, index) => (
                  <div className="col-12 col-lg-6 col-xl-4 mb-4" key={index}>
                    <MyEquipmentCardComponent
                      setShowModal={showModal}
                      equipment={item}
                      equipmentNomenclature={equipmentNomenclature}
                      index={index}
                      expandedDescriptions={expandedDescriptions}
                      showDescription={showDescription}
                      formatDate={formatDate}
                    />
                  </div>
                ))}
                <RequestEquipmentModal
                  showModal={showAddModal}
                  handleClose={handleClose}
                  getEquipmentProposalsForUser={
                    getAllEquipmentProposalsForLoggedUser
                  }
                />
              </div>
              {equipmentList.length === 0 && (
                <h3 className={"text-center my-5"}>
                  <i className={"text-secondary no-notification-message"}>
                    {strings.noEquipmentEntries}
                  </i>
                </h3>
              )}
            </div>
          </div>
        </Tab>
        <Tab
          eventKey="myEquipmentProposals"
          title={strings.myEquipmentProposals}
          className={"h-100 w-100 mt-4"}
        >
          <div className={"w-100 h-100"}>
            <div className={"row table-responsive mt-1"}>
              <table id="keywords" className={"mb-1"}>
                <thead>
                  <tr className="userList">
                    <th scope={"col"}>
                      <b>{strings.equipmentProposalName}</b>
                    </th>
                    <th scope={"col"}>
                      <b>{strings.equipmentQuantity}</b>
                    </th>
                    <th scope={"col"}>
                      <b>{strings.equipmentDescription}</b>
                    </th>
                    <th scope={"col"}>
                      <b>{strings.status}</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {myEquipmentProposals.length > 0 &&
                    myEquipmentProposals.map((myEquipmentProposals) => (
                      <tr>
                        <td>
                          {myEquipmentProposals.equipmentNomenclatureEntry.name}
                        </td>
                        <td>{myEquipmentProposals.quantity}</td>
                        <td>{myEquipmentProposals.description}</td>
                        <td>
                          <b
                            className={`pb-2 px-2 rounded-2 ${getStatusColorClass(
                              myEquipmentProposals.status
                            )}`}
                          >
                            {checkStatus(myEquipmentProposals.status)}
                          </b>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {myEquipmentProposals.length === 0 && (
                <h3 className={"text-center my-5"}>
                  <i className={"text-secondary no-notification-message"}>
                    {strings.noActiveEquipmentProposals}
                  </i>
                </h3>
              )}
            </div>
          </div>
        </Tab>
      </Tabs>
      </div>
    </>
  );
};

export default MyEquipment;
