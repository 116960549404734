import "./UserAttendedEvents.css"
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import EventsService from "../../../../repository/eventsRepository/EventsRepository";
import {strings} from "../../../../localization/Localization";
import ApplicationService from "../../../../ApplicationService";

const UserAttendedEvents = (props) => {

    const [isExpanded, setIsExpanded] = useState(false);

    const maxWordsToShow = 10;

    const getStatusColorClass = (status) => {
        switch (status) {
            case "ACTIVE":
                return "accepted-color";
            case "INACTIVE":
                return "not-accepted-color";
            default:
                return "";
        }
    };

    const getStatus = (status) => {
        if (status === 'ACTIVE') {
            return strings.active
        } else if (status === 'INACTIVE') {
            return strings.inactive
        }
    }

    const statusColorClass = getStatusColorClass(props.attendedEvent?.status);
    const descriptionWords = props.attendedEvent?.description.split(' ');
    const shouldShowMoreLink = descriptionWords.length > maxWordsToShow;

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };

    return(
        <tr className="userlist py-auto cursor-pointer" key={props.attendedEvent?.id}>
            <td>
                <b>{props.attendedEvent?.name}</b>
            </td>
            <td className="text_blue_dark w-25">
                <div className={`description-container ${isExpanded ? "expanded" : ""}`}>
                    {descriptionWords.slice(0, maxWordsToShow).join(" ")}
                    {shouldShowMoreLink && !isExpanded && " ... "}
                    {isExpanded || !shouldShowMoreLink ? (
                        descriptionWords.slice(maxWordsToShow).join(" ")
                    ) : null}
                </div>
                {shouldShowMoreLink && (
                    <span className="show-more-link text_blue_dark" onClick={toggleDescription}>
              {isExpanded ? (
                  <i className={"material-icons"}>expand_less</i>
              ) : (
                  <i className={"material-icons"}>expand_more</i>
              )}
            </span>
                )}
            </td>
            <td>
                <b>
                    {ApplicationService.getActiveLanguage() == "mk" ? props.attendedEvent.eventTypeNomenclatureEntry.labelMk
                        : props.attendedEvent.eventTypeNomenclatureEntry.labelEn}
                </b>
            </td>
            <td className={"px-1 text_blue_dark"}>
                <b>
                    {props.attendedEvent.organisation}
                </b>
            </td>
            <td className={`px-1 text_blue_dark`}>
                <b>
                    {props.attendedEvent.trainer}
                </b>
            </td>
            <td>
                <b className={`pb-2 px-2 rounded-2 ${statusColorClass}`}>
                    {getStatus(props.attendedEvent.status)}
                </b>
            </td>
        </tr>
    )

}

export default UserAttendedEvents;