import React, {useState} from "react";
import {strings} from "../../../localization/Localization";
import PositionHierarchyTree from "../PositionHierarchyTree/PositionHierarchyTree";


const PositionHierarchyList = () => {

    return (
        <>
            <div className="container w-100">

                <div className="my-3 mx-3">
                    <h1>{strings.positionHierarchy}</h1>
                </div>

                <div>
                    <PositionHierarchyTree/>
                </div>
            </div>
        </>
    );
}
export default PositionHierarchyList;