import React, {useState} from "react"
import {strings} from "../../localization/Localization";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import LeaveType from "./LeaveTypes/LeaveType";
import LeaveReasons from "./LeaveResons/LeaveReasons";
import "./LeaveTypeAndReasonsList.css";

const LeaveTypeAndReasonsList = () => {

    const [key, setKey] = useState('leaveReasons');


    return(
      <div>
          <h1 className={"mt-2 mb-3"}>{strings.typesOfAbsences}</h1>
          <div className={"pb-2"}>
              <div className={"user_details_box w-100 mb-4 border border-radius border-light border-5 ps-2 pt-4"}>
                  <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      className={"w-100 absence_type_tabs"}
                      onSelect={(k) => setKey(k)}>

                      <Tab eventKey="leaveReasons" title={strings.absenceByReasons} className={"h-100"}>
                          <div className={"w-100 h-100 py-3 px-3 "}>
                            <LeaveReasons tabKey={key}/>
                          </div>

                      </Tab>

                      <Tab eventKey="leaveType" title={strings.leaveType} className={"h-100 w-100"}>
                          <div className={"w-100 h-100 py-3 px-3"}>
                            <LeaveType tabKey={key}/>
                          </div>
                      </Tab>

                  </Tabs>

              </div>
          </div>
      </div>
    );

}
export default LeaveTypeAndReasonsList;