import React, {useEffect, useState} from "react";
import swal from "sweetalert";
import {strings} from "../../../../localization/Localization";
import DocumentService from "../../../../repository/documentRepository/DocumentRepository";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import {useParams} from "react-router-dom";
import UserService from "../../../../repository/userRepository/UserRepository";
import ApplicationService from "../../../../ApplicationService";
import DraggableModalDialog from "../../../../components/DraggableModalDialog/DraggableModalDialog";

const AddPersonalDocumentModal = (props) => {


    const [file, setFile] = useState({});
    const param = useParams();
    const [selectedUserId, setSelectedUserId] = useState();
    const [isActive, setIsActive] = useState(false);
    const [openByAdmin, setOpenByAdmin] = useState(false);

    const [formData, updateFormData] = useState({
        documentNumber: "",
        dateFrom: "",
        dateTo: "",
        personalDocumentType: "",
        issuedBy: "",
    });

    useEffect(() => {
        if (!props.showModal) {
            resetData();
        } else {
            getUserForPersonalDocumentUpload()
            const documentTypeTemp = getDocumentType(props.selectedDocumentForEdit?.personalDocumentType);
            if (props.selectedDocumentForEdit.id) {
                updateFormData({
                    documentNumber: props.selectedDocumentForEdit?.documentNumber,
                    dateFrom: props.selectedDocumentForEdit?.dateFrom.split("T")[0],
                    dateTo: props.selectedDocumentForEdit?.dateTo.split("T")[0],
                    personalDocumentType: documentTypeTemp,
                    issuedBy: props.selectedDocumentForEdit?.issuedBy,
                });
            }
        }

    }, [props.showModal]);

    const getDocumentType = (type) => {
        if (type === 'IDENTITY_CARD') {
            return {value: type, label: strings.identityCard};
        } else if (type === 'PASSPORT') {
            return {value: type, label: strings.passport};
        }
        return null;
    };

    useEffect(() => {
        if (props.showModal) {
            setTimeout(() => {
                document.getElementById("documentType").blur(); // Replace with actual ID
            }, 0);
        }
    }, [props.showModal]);

    const getUserForPersonalDocumentUpload = () => {
        if (param.id) {
            UserService.getUserDetailsById(param.id).then((data) => {
                setSelectedUserId(data.data.id)
                setOpenByAdmin(true);
                setIsActive(data.data.active);
            }).catch(() => {
            })
        } else {
            UserService.getUserDetails().then((data) => {
                setSelectedUserId(data.data.id)
                setOpenByAdmin(false);
                setIsActive(data.data.active);
            }).catch(() => {
            })
        }
    }

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const warningAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const resetData = () => {
        updateFormData({
            documentNumber: "",
            dateFrom: "",
            dateTo: "",
            personalDocumentType: "",
            issuedBy: "",
        })
    };

    const handleChange = (selectedOption, name) => {
        let value;
    
        if (selectedOption && selectedOption.target) {
            value = selectedOption.target.value;
            let isValid = true;
    
            if (selectedOption.target.hasAttribute("required") && value.trim() === "") {
                selectedOption.target.setCustomValidity(strings.fieldsRequired);
                isValid = false;
            } else {
                selectedOption.target.setCustomValidity("");
            }
    
            if (isValid) {
                updateFormData((prevData) => ({
                    ...prevData,
                    [name]: value,
                }));
            }
        } else if (selectedOption) {
            value = selectedOption.value;
    
            updateFormData((prevData) => ({
                ...prevData,
                [name]: { value, label: selectedOption.label },
            }));
        }
    };
    

    const addPersonalDocument = (selectedUserId, documentNumber, personalDocumentType, dateFrom, dateTo, issuedBy) => {
        if (param.id === undefined) {
            DocumentService.addPersonalDocument(selectedUserId, documentNumber, personalDocumentType, dateFrom, dateTo, issuedBy).then((data) => {
                props.handleClose();
                successfulAlert();
                props.getDocumentData();
            }).catch(() => {
                warningAlert();
            })
        } else {
            DocumentService.addPersonalDocumentByAdmin(selectedUserId, documentNumber, personalDocumentType, dateFrom, dateTo, issuedBy).then((data) => {
                props.handleClose();
                successfulAlert();
                props.getDocumentData();
            }).catch(() => {
                warningAlert();
            })
        }
    };

    const editPersonalDocument = (selectedUserId, documentNumber, personalDocumentType, dateFrom, dateTo, issuedBy) => {
        if (param.id === undefined) {
            DocumentService.editPersonalDocument(props.selectedDocumentForEdit.id, selectedUserId, documentNumber, personalDocumentType, dateFrom, dateTo, issuedBy).then((data) => {
                props.handleClose();
                successfulAlert();
                props.getDocumentData();
            }).catch(() => {
                warningAlert();
            })
        } else {
            DocumentService.editPersonalDocumentByAdmin(props.selectedDocumentForEdit.id, selectedUserId, documentNumber, personalDocumentType, dateFrom, dateTo, issuedBy).then((data) => {
                props.handleClose();
                successfulAlert();
                props.getDocumentData();
            }).catch(() => {
                warningAlert();
            })
        }
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        if (props.selectedDocumentForEdit.id) {
            editPersonalDocument(selectedUserId, formData.documentNumber, formData.personalDocumentType.value, formData.dateFrom, formData.dateTo, formData.issuedBy);
        } else {
            addPersonalDocument(selectedUserId, formData.documentNumber, formData.personalDocumentType.value, formData.dateFrom, formData.dateTo, formData.issuedBy);
        }

    };

    return (
        <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={"lg"} centered>
            <Modal.Header closeButton>
                <Modal.Title>{strings.addPersonalDocument}</Modal.Title>
            </Modal.Header>
            <Form
                onSubmit={onFormSubmit}
            >
                <Modal.Body>
                    <Row className={"mb-2"}>
                        <Col xs={12} md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.documentType}<span className="text-danger">*</span>
                                </Form.Label>
                                <Select
                                    type="text"
                                    required
                                    onChange={(e) => handleChange(e, "personalDocumentType")}
                                    value={{
                                        value: formData.personalDocumentType.value,
                                        label: formData.personalDocumentType.label
                                    }}
                                    options={[{
                                        value: "IDENTITY_CARD", label: strings.identityCard
                                    },
                                        {
                                            value: "PASSPORT", label: strings.passport
                                        }]}
                                    name="personalDocumentType"
                                    id={"documentType"}
                                    placeholder={strings.selectOption}
                                    noOptionsMessage={()=>strings.noOptions}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.documentNumber}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type={"text"}
                                    defaultValue={formData.documentNumber}
                                    onChange={(e) => handleChange(e, "documentNumber")}
                                    name={"documentNumber"}
                                    maxLength={8} 
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.issuedBy} ({strings.institution})<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type={"text"}
                                    required
                                    defaultValue={formData.issuedBy}
                                    onChange={(e) => handleChange(e, "issuedBy")}
                                    name={"issuedBy"}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.dateOfIssue}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type={"date"}
                                    required
                                    value={formData.dateFrom}
                                    onChange={(e) => handleChange(e, "dateFrom")}
                                    name={"dateFrom"}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.dateOfExpiry}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type={"date"}
                                    required
                                    value={formData.dateTo}
                                    onChange={(e) => handleChange(e, "dateTo")}
                                    name={"dateTo"}
                                    min={formData.dateFrom}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className={"cancel btn text-white"} onClick={props.handleClose}>
                        {strings.cancel}
                    </div>
                    <button type={"submit"} className={"save btn text-white"}>
                        {strings.save}
                    </button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
export default AddPersonalDocumentModal;