import ApplicationService from "../../../../ApplicationService";
import { strings } from "../../../../localization/Localization";
import React, { useEffect, useState } from "react";
import BenefitDetails from "../BenefitDetails/BenefitDetails";
import "./BenefitCard.css";
import BenefitService from "../../../../repository/benefitsRepository/BenefitsRepository";
import FormatDate from "../../../../components/formatDate/FormatDate";

const BenefitCardComponent = ({
  benefit,
  index,
  expandedDescriptions,
  toggleDescription,
  formatDate,
  benefitNomenclature,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedBenefitDetails, setSelectedBenefitDetails] = useState({});
  const [matchingValues, setMatchingValues] = useState([]);

  useEffect(() => {
    if (benefitNomenclature && benefitNomenclature.metaDataList) {
      findMatching();
    }
  }, [benefitNomenclature]);

  const getBenefitDetailsById = (id) => {
    BenefitService.getAllUserBenefitByNomenclatureEntryId(id).then((data) => {
      setSelectedBenefitDetails(data.data);
    });
  };

  const findMatching = () => {
    const newMatchingValues = benefitNomenclature.metaDataList.map(
      (metaDataEntry) => {
        const matchingEntry = benefit.nomenclatureEntryMetadataList.find(
          (entry) => entry.metadataId === metaDataEntry.id
        );

        return {
          benefitTypeId: matchingEntry?.nomenclatureId || null,
          labelMk: metaDataEntry.labelMk,
          labelEn: metaDataEntry.labelEn,
          labelValue: matchingEntry?.label || null,
        };
      }
    );

    setMatchingValues(newMatchingValues);
  };

  const handleCardClick = (id) => {
    getBenefitDetailsById(id);
    setShowModal(true);
  };

  const handleCloseBenefitModal = () => {
    setSelectedBenefitDetails({});
    setShowModal(false);
  };

  return (
    <div className="col-4 card-width background-card" key={benefit.id}>
      <div className={`card  ${
    expandedDescriptions
      ? expandedDescriptions[index]
        ? "card-expanded"
        : "card-not-expanded"
      : ""
  }`}>
        <div className="card-body d-flex flex-column">
          <div className=" mb-2 d-flex justify-content-between">
            <h5 className="card-title ">
              {ApplicationService.getActiveLanguage() === "mk"
                ? benefit?.labelMk
                : benefit?.labelEn}
            </h5>

            <div className="col-3">
              {benefit.isActive ? (
                <span className="badge text-bg-success ms-3">
                  {strings.Active}
                </span>
              ) : (
                <span className="badge text-bg-danger ">
                  {strings.notActive}
                </span>
              )}
            </div>
          </div>
          <div className="row">
  <div className="col-4">
    <img
      src="https://cdn-icons-png.flaticon.com/128/8644/8644486.png"
      alt="Benefits"
      className="col-4 image-width"
    />
  </div>
  <div className="col-8 d-flex flex-column">
    {matchingValues.map((matchingValue, index) => (
      <span key={index} className="text-secondary me-2">
        <i>
          {ApplicationService.getActiveLanguage() === "mk"
            ? matchingValue.labelMk
            : matchingValue.labelEn}
          :{" "}
          {matchingValue.labelValue
            ? FormatDate.formatDate(matchingValue.labelValue)
            : "/"}
        </i>
      </span>
    ))}
  </div>
</div>

          
        
        <div className="row mb-3 mt-3">
            <div className={"d-flex"}>
  <p
    className={`description-text me-2 ${
      expandedDescriptions[index]
        ? "description-expanded"
        : "description-not-expanded"
    }`}
  >
    {benefit.description}
  </p>
  {benefit.description.split(" ").length > 20 && (
    <button
      className="toggle-description-button justify-content-center"
      onClick={() => toggleDescription(index)}
    >
      {expandedDescriptions[index] ? (
        <i className={"material-icons"}>expand_less</i>
      ) : (
        <i className={"material-icons"}>expand_more</i>
      )}
    </button>
  )}
</div>
</div>
<div className="d-flex mt-auto">
            <a
              className="btn btn-primary text-white add-button details-button"
              onClick={() => {
                handleCardClick(benefit.id);
              }}
            >
              {strings.details}
            </a>
          </div>
          <BenefitDetails
            showModal={showModal}
            benefitDetails={selectedBenefitDetails}
            handleClose={handleCloseBenefitModal}
            benefit={benefit}
            getBenefitDetailsById={() => getBenefitDetailsById(benefit.id)}
            matchingValues={matchingValues}
          />
        </div>
      </div>
     </div>
  );
};

export default BenefitCardComponent;
