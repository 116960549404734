import axios from "../../axios/axios";

const ProjectTechnologyService = {

    getAllProjectTechnologies: () => {
        return axios.get("/project_technology/getAll")
    },

    getAllTechnologiesForProject: (id) => {
        return axios.get(`/project_technology/getAllTechnologiesForProject/${id}`)
    },
    getAllTechnologies: () =>{
        return axios.get("project_technology/getAllNomenclatureEntries")
    },

    // addProjectTechnology: (name, description, dateBeginning, dateEnding, isActive, typeNomenclatureEntryId, groupId) => {
    //     return axios.post("/project_technology/addProjectTechnology", {
    //         name,
    //         description,
    //         dateBeginning,
    //         dateEnding,
    //         isActive,
    //         typeNomenclatureEntryId,
    //         groupId
    //     })
    // },
    //
    // updateProjectTechnology: (name, description, dateBeginning, dateEnding, isActive, typeNomenclatureEntryId, groupId) => {
    //     return axios.post("/project_technology/updateProjectTechnology", {
    //         name,
    //         description,
    //         dateBeginning,
    //         dateEnding,
    //         isActive,
    //         typeNomenclatureEntryId,
    //         groupId
    //     })
    // },
    //
    // deleteProjectTechnology: (id) => {
    //     return axios.post(`/project_technology/deleteProjectTechnology/${id}`)
    // }

}

export default ProjectTechnologyService;
