import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "./Finalized_registration.css"
import {strings} from "../../localization/Localization";
import ApplicationService from "../../ApplicationService";
import Logo from "../../assets/images/logo-inteligenta.png"

const Finalized_registration = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const [success,setSuccess] =useState()

    useEffect(()=>{
       let successParam= queryParams.get("status")
        if(successParam==="true"){
            setSuccess(true)
        }else{
            setSuccess(false)
        }
    },[])

    return (
        <div className="back">
            <div className="form_newpassword ">
                <div className="d-flex justify-content-center">
                    <img src={Logo}  className={"logo"}/>
                </div>
                <hr/>
                {success &&
                    <div  className={'text-success text-center success-info-container'}>{strings.registrationSuccessful}</div>
                }
                {!success &&
                    <div className={"text-danger text-center success-info-container"}>{strings.registrationFailed}</div>
                }
                <br/>

                <div className={'justify-content-center text-center'}>
                    <Link className={'back_link text-light btn signIn-button border-radius text-end'} to="/login">
                        {/*<IoMdArrowBack className={'back-icon text-light'} />*/}
                        {strings.backToLogin}
                    </Link>
                </div>

            </div>
        </div>
    );
}
export default Finalized_registration;
