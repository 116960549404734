import React, {useEffect, useState} from "react"
import ApplicationService from "../../ApplicationService";
import Logo from "../../assets/images/logo-inteligenta.png"
import {strings} from "../../localization/Localization";
import {Link} from "react-router-dom";
import "./FinalizedRegistrationMobile.css";

const FinalizedRegistrationMobile = () => {

    const queryParams = new URLSearchParams(window.location.search);

    const [success,setSuccess] =useState();

    useEffect(()=>{
        let successParam= queryParams.get("status")
        if(successParam==="true"){
            setSuccess(true)
        }else{
            setSuccess(false)
        }
    },[]);

    return (
        <div className="back_FinalizedRegistration_Mobile h-100 w-100 pt-5 px-4">
            <div className={"pt-5"} >
                <div className="d-flex justify-content-center">
                    <img src={Logo}  className={"logo"}/>
                </div>
                <hr className={"mb-4"}/>
                {success &&
                    <div  className={'text-success text-center success-info-container'}>{strings.registrationSuccessful}</div>
                }
                {!success &&
                    <div className={"text-danger text-center success-info-container"}>{strings.registrationFailed}</div>
                }
                <br/>

                <div className={'justify-content-center text-center'}>
                    <Link className={'back_link text-light btn btn-primary text-end'} to="/login">
                        {/*<IoMdArrowBack className={'back-icon text-light'} />*/}
                        {strings.backToLogin}
                    </Link>
                </div>

            </div>
        </div>
    );
}
export default FinalizedRegistrationMobile;