import TimeRecordTerm from "../TimeRecordTerm/TimeRecordTerm";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import React, { useEffect } from "react";
import TimeRecordModal from "../TimeRecordModal/TimeRecordModal";
import TimeTrackingRepository from "../../../repository/timeTrackingRepository/TimeTrackingRepository";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { strings } from "../../../localization/Localization";
import AddButton from "../../../components/addButton/AddButton";
import Select from "react-select";
import TimeTrackingFilter from "../../../components/TimeTrackingFilter/TimeTrackingFilter";

const MyTimeTrackingList = () => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [nextPageOffset, setNextPageOffset] = useState(0);
  const [timeTrackingList, setTimeTrackingList] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedTimeRecordForEdit, setSelectedTimeRecordForEdit] = useState(
    {}
  );
  const [isEdit, setIsEdit] = useState(false);
  const [resetIsLoading, setResetIsLoading] = useState(false);
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterProject, setFilterProject] = React.useState({
    firstName: null,
    lastName: null,
    projectGroup: null,
    projectName: null,
    timeRecordDate: null,
  });
  // const [permissionForUpsert, setPermissionForUpsert] = useState(false);

  useEffect(() => {
    loadTimeRecord();
  }, [size]);

  const handleClose = () => {
    setShowModal(false);
    setIsEdit(false);
    setSelectedTimeRecordForEdit({});
    loadTimeRecord();
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const onClickSetSelectedTimeRecordForEdit = (e) => {
    setSelectedTimeRecordForEdit(e);
    setIsEdit(true);
    handleShow();
  };

  const loadTimeRecord = () => {
    TimeTrackingRepository.fetchAllTimeRecordByUser(page, size).then((res) => {
      if (res.data?.length === 0 && page !== 0) {
        setPage(page - 1);
      } else {
        setTimeTrackingList(res.data);
        setPageCount(res.data.totalPages);
        // setOffset(res.data.pageable.offset);
        // setNextPageOffset(res.data.pageable.offset + size)
      }
      setTimeTrackingList(res.data);
    });
  };

  const loadMyTimeRecordFiltered = (
    firstName,
    lastName,
    projectGroup,
    projectName,
    timeRecordDate,
    resetDate = false,
    searchData = false
  ) => {
    if (resetDate) {
      setResetIsLoading(true);
    } else if (searchData) {
      setSearchIsLoading(true);
    }

    setFilterProject({
      firstName: null,
      lastName: null,
      projectGroup: projectGroup,
      projectName: projectName,
      timeRecordDate: timeRecordDate,
    });

    TimeTrackingRepository.getAllPaginatedByUser({
      firstName: null,
      lastName: null,
      projectGroup: projectGroup?.label,
      projectName: projectName?.label,
      timeRecordDate,
    })

      .then((data) => {
        setPageCount(data.data.totalPages);
        setTimeTrackingList(data.data.content);
        setPage(0);
        if (resetDate) {
          setResetIsLoading(false);
        } else if (searchData) {
          setSearchIsLoading(false);
        }
      })
      .catch(() => {
        setResetIsLoading(false);
        setSearchIsLoading(false);
      });
  };

  const resetFilterData = () => {
    setFilterProject({
      firstName: null,
      lastName: null,
      projectGroup: null,
      projectName: null,
      timeRecordDate: null,
    });
  };

  const getTimeRecordPage = () => {
    return timeTrackingList.map((term) => {
      return (
        <TimeRecordTerm
          key={term.id}
          onClickSetSelectedTimeRecordForEdit={
            onClickSetSelectedTimeRecordForEdit
          }
          term={term}
          editTerm={term}
        />
      );
    });
  };

  const timeRecordTermPage = getTimeRecordPage(offset, nextPageOffset);

  const handlePageClick = (data) => {
    let selected = data.selected;
    setPage(selected);
  };

  const onClickHandle = () => {
    handleShow();
  };

  const selectSize = (e) => {
    setPage(0);
    setSize(e.value);
  };

  return (
    <>
      <div className={"w-100 d-flex justify-content-between"}>
        <div>
          <h1>{strings.myTimeTracking}</h1>
        </div>
        <div className={"d-flex"}>
          <div
            className={
              "align-items-center py-1 d-flex justify-content-end mx-2"
            }
          >
            <div
              className="btn btn-secondary d-flex justify-content-center text-end  align-items-end users_filter_button"
              onClick={handleFilter}
            >
              <i className={"material-icons me-1 users_filter_button_icon"}>
                filter_list
              </i>{" "}
              {strings.filter}
            </div>
          </div>
          <div className={"align-items-center d-flex justify-content-end"}>
            <AddButton onClickHandle={onClickHandle}></AddButton>
          </div>
        </div>
      </div>
      <div className={`${showModal ? "mt-2" : ""}`}>
        {showFilter && (
          <TimeTrackingFilter
            permissionForUpsert={false}
            loadTimeRecord={loadMyTimeRecordFiltered}
            resetFilterData={resetFilterData}
            resetIsLoading={resetIsLoading}
            searchIsLoading={searchIsLoading}
          />
        )}
      </div>
      <div className={"container"}>
        <div>
          <div className={"row table-responsive"}>
            <table id="keywords" className={"mb-1"}>
              <thead>
                <tr>
                  <th scope={"col"}>{strings.projectGroup}</th>
                  <th scope={"col"}>{strings.projectName}</th>
                  <th scope={"col"}>{strings.date}</th>
                  <th scope={"col"}>{strings.duration}</th>
                  <th scope={"col"} className={"text-end "}></th>
                </tr>
              </thead>
              <tbody>{timeTrackingList.length > 0 && timeRecordTermPage}</tbody>
            </table>
            {timeTrackingList.length === 0 && (
              <div className={"justify-content-center text-center w-100"}>
                <div>
                  <h5 className={"text-secondary w-100 "}>
                    <i>{strings.emptyList}</i>
                  </h5>
                </div>
              </div>
            )}
          </div>
          {timeTrackingList.length !== 0 && (
            <div className={"d-flex justify-content-between my-3"}>
              <div className={"mx-auto h-100 align-items-center  d-flex"}>
                <ReactPaginate
                  previousLabel={<AiOutlineArrowLeft />}
                  nextLabel={<AiOutlineArrowRight />}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  forcePage={page}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={
                    "pagination h-100 m-0 justify-content-center"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
              <div className={"d-flex align-items-end"}>
                <Select
                  placeholder={strings.size}
                  onChange={selectSize}
                  options={[
                    { value: 5, label: "5" },
                    { value: 10, label: "10" },
                    { value: 15, label: "15" },
                    { value: 20, label: "20" },
                  ]}
                  defaultValue={{ value: 10, label: "10" }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 40,
                      border: "none",
                    }),
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <TimeRecordModal
        showModal={showModal}
        handleClose={handleClose}
        selectedTimeRecordForEdit={selectedTimeRecordForEdit}
        isEdit={isEdit}
        permissionForUpsert={false}
      />
    </>
  );
};

export default MyTimeTrackingList;
