import React, { useState} from "react"
import {Menu, MenuItem, ProSidebar, SidebarHeader, SubMenu} from "react-pro-sidebar";
import {
    FaBook, FaBookReader, FaCalendarCheck,
    FaClipboardList,
    FaFileExport,
    FaFlag, FaHistory,
    FaRegCalendarAlt,
    FaSitemap,
    FaUserCircle, FaUserTag
} from "react-icons/fa";
import {strings} from "../../localization/Localization";
import {Link} from "react-router-dom";
import ApplicationService from "../../ApplicationService";
import {RiAdminFill} from "react-icons/ri";
import {GiBookPile, GiBookshelf, GiChurch, GiFiles} from "react-icons/gi";
import {FiSettings} from "react-icons/fi";
import {BsBookmarkCheckFill} from "react-icons/bs";
import "./MobileSideBar.css";
import {useSelector} from "react-redux";

const MobileSideBar = (props) => {

    const [activePage, setActivePage] = useState(null);

    const userDetailsRedux=useSelector((state)=> state.user);

    const isActive = (url) => {
        const location = window.location.pathname;
        return location == url;
    };

    function handleActive(event) {
        if (!event.target.classList.value.includes("active")) {
            event.target.classList.toggle("active");
            if (activePage)
                activePage.classList.remove("active");
            setActivePage(event.target);
        }
        props.handleMobileMenu();
    };

  return(
      <>
          <div className={`d-flex flex-row `}
          >
              <div>
                  <ProSidebar name="sidebar"   collapsedWidth={"10vh"}>
                      <Menu iconShape="circle">
                          <SidebarHeader>
                              <div className="justify-content-start align-items-center  text-start  text-wrap user-information-wrapper ms-4 mb-3">
                                  <div className={"h-100  text-center align-items-center pt-2 justify-content-center"}>
                                      <Link to={"/userDetails"}>
                                          {/*<i className={"material-icons sidebar-account-icon"}>account_circle</i>*/}
                                          <img id="output" className="rounded-circle" width="120px" height={"120px"}
                                               src={(`data:image/*;base64,${props.userData.profileImageHelper.bytes}`)}
                                          />
                                      </Link>
                                  </div>
                                  <div className={"mx-1 text-center"}>
                                      <div className={' sidebar-account-text'}> <b>{userDetailsRedux.firstName} {userDetailsRedux.lastName } </b> </div>
                                      <div className={'  sidebar-account-text'}><b>{userDetailsRedux.email}</b></div>
                                  </div>
                              </div>
                          </SidebarHeader>
                          <MenuItem icon={<i className={"material-icons mobile-sidebar-icon"}>home</i>} active={isActive("/home")}><Link onClick={handleActive}
                                                                          to="/home"> {strings.home}</Link></MenuItem>
                          <MenuItem icon={<i className="material-icons mobile-sidebar-icon">account_circle</i>} active={isActive("/userDetails")}><Link onClick={handleActive}
                                                                                                                                                      to="/userDetails"> {strings.profile}</Link></MenuItem>

                          <SubMenu active={true} title={strings.projects} icon={<GiBookPile/>}>
                              {ApplicationService.hasRole(["ROLE_ADMINISTRATOR","ROLE_HR"]) &&
                                  <MenuItem icon={<GiBookshelf/>} id={"projects"}
                                            active={isActive("/projects")}><Link
                                      onClick={handleActive}
                                      to="/projects">{strings.administration}</Link></MenuItem>
                              }

                              <MenuItem icon={<FaBookReader/>} active={isActive("/myProjects")}><Link onClick={handleActive}
                                                                                                      to="/myProjects"> {strings.myProjects}</Link></MenuItem>
                          </SubMenu>

                          {ApplicationService.hasRole(["ROLE_ADMINISTRATOR","ROLE_HR"]) &&
                              <>
                                  <SubMenu title={strings.administration} icon={<RiAdminFill/>}>
                                      <MenuItem icon={<FaUserCircle/>} id={"user"} active={isActive("/user")}><Link
                                          onClick={handleActive}
                                          to="/user">{strings.users}</Link></MenuItem>
                                      <MenuItem icon={<GiChurch/>} id={"religion"} active={isActive("/religion")}><Link
                                          onClick={handleActive}
                                          to="/religion">{strings.religionList}</Link></MenuItem>
                                      <MenuItem icon={<FaFlag/>} id={"ethnicity"} active={isActive("/ethnicity")}><Link
                                          onClick={handleActive}
                                          to="/ethnicity">{strings.ethnicityGroup}</Link></MenuItem>
                                      <MenuItem icon={<FaSitemap/>} id={"organisation"} active={isActive("/organisation")}><Link
                                          onClick={handleActive}
                                          to="/organisation">{strings.organizationalHierarchy}</Link></MenuItem>
                                      <MenuItem icon={<FaSitemap/>} id={"position"} active={isActive("/position")}><Link
                                          onClick={handleActive}
                                          to="/position">{strings.positions}</Link></MenuItem>
                                      {ApplicationService.hasRole(["ROLE_ADMINISTRATOR"]) &&
                                          < MenuItem icon={<FiSettings/>} id={"settings"} active={isActive("/settings")}><Link
                                              onClick={handleActive}
                                              to="/settings">{strings.settings}</Link></MenuItem>
                                      }
                                  </SubMenu>
                                  <MenuItem icon={<FaRegCalendarAlt/>} id={"holidays"} active={isActive("/holidays")}><Link
                                      onClick={handleActive}
                                      to="/holidays"> {strings.holidays}</Link></MenuItem>
                              </>
                          }
                          <SubMenu title={strings.absence} icon={<FaClipboardList/>}>
                              <MenuItem icon={<FaFileExport/>} id={"leaveRequests"} active={isActive("/leaveRequests")}>
                                  <Link onClick={handleActive} to="/leaveRequests"> {strings.myRequest}</Link>
                              </MenuItem>
                              <MenuItem icon={<BsBookmarkCheckFill/>} id={"absence"} active={isActive("/absence")}><Link
                                  onClick={handleActive}
                                  to="/absence"> {strings.allowedAbsence}</Link></MenuItem>
                              {ApplicationService.hasRole(["ROLE_ADMINISTRATOR","ROLE_HR"]) &&
                                  <MenuItem icon={<GiFiles/>} id={"arrivedRequest"} active={isActive("/arrivedRequest")}>
                                      <Link onClick={handleActive}
                                            to="/arrivedRequest"> {strings.deliverRequest}</Link>
                                  </MenuItem>
                              }
                          </SubMenu>
                          {ApplicationService.hasRole(["ROLE_ADMINISTRATOR","ROLE_HR"]) &&
                              <>
                                  <SubMenu title={strings.evidention} icon={<FaBook/>}>
                                      <MenuItem icon={<FaCalendarCheck/>} id={"status"} active={isActive("/status")}><Link
                                          onClick={handleActive}
                                          to="/status">{strings.absenceCalendar}</Link></MenuItem>
                                      <MenuItem icon={<FaUserTag/>} id={"employeelist"} active={isActive("/employeelist")}><Link
                                          onClick={handleActive}
                                          to="/employeelist">{strings.userevidention}</Link></MenuItem>
                                  </SubMenu>
                                  <MenuItem icon={<FaHistory/>} id={"calendarHistory"} active={isActive("/calendarHistory")}><Link
                                      onClick={handleActive}
                                      to="/calendarHistory">{strings.calendarHistory}</Link></MenuItem>
                              </>
                          }

                          <MenuItem icon={<i className="material-icons mobile-sidebar-icon">logout</i>} active={isActive("/logout")}><Link onClick={handleActive}
                                                                          to="/logout"> {strings.logOut}</Link></MenuItem>

                      </Menu>
                  </ProSidebar>
              </div>
          </div>
      </>
  )
}
export default MobileSideBar;
