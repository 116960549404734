import axios from "../../axios/axios";

const EventFilesRepository = {

    getFilesForEvent: (id) => {
        return axios.get(`/eventFiles/getFilesForEvent/${id}`);
    },

    deleteEventFile: (id) => {
        return axios.delete(`/eventFiles/delete/${id}`);
    },

    addEventFile: (newFormData) => {
        return axios.post("/eventFiles/uploadEventFile", newFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

}

export default EventFilesRepository;