import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import { strings } from "../../../../../localization/Localization";
import swal from "sweetalert";
import UserService from "../../../../../repository/userRepository/UserRepository";
import WorkExperienceService from "../../../../../repository/WorkExperienceRepository/WorkExperienceRepository";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../../UserDetails.css";
import applicationService from "../../../../../ApplicationService";
import DraggableModalDialog from "../../../../../components/DraggableModalDialog/DraggableModalDialog";
import NomenclatureService from "../../../../../repository/nomenclatureRepository/NomenclatureRepository";
import Select from 'react-select';

const AddWorkExperienceModal = (props) => {
  const [selectedUserId, setSelectedUserId] = useState();
  const [isActive, setIsActive] = useState(false);
  const [openByAdmin, setOpenByAdmin] = useState(false);
  const param = useParams();
  const [calculatedYears, setCalculatedYears] = useState("");
  const [calculatedMonths, setCalculatedMonths] = useState("");
  const [calculatedDays, setCalculatedDays] = useState("");
  const [nomenclaturePositions, setNomenclaturePositions] = useState([]);

  const [formData, updateFormData] = useState({
    companyName: "",
    dateRegistered: "",
    workPosition: "",
    startDate: "",
    endDate: "",
    workingYears: "",
    workingMonths: "",
    workingDays: "",
    fromOurCompany: "",
  });

  useEffect(() => {
    if (!props.showModal) {
      resetData();
    } else {
      getUserId();
      if (props.selectedWorkExperienceForEdit?.workExperienceId) {
        updateFormData({
          companyName: props.selectedWorkExperienceForEdit?.companyName,
          dateRegistered: props.selectedWorkExperienceForEdit?.dateRegistered,
          workPosition: props.selectedWorkExperienceForEdit?.workPosition,
          startDate: props.selectedWorkExperienceForEdit?.startDate,
          endDate: props.selectedWorkExperienceForEdit?.endDate,
          workingYears: props.selectedWorkExperienceForEdit?.workingYears,
          workingMonths: props.selectedWorkExperienceForEdit?.workingMonths,
          workingDays: props.selectedWorkExperienceForEdit?.workingDays,
          fromOurCompany: props.selectedWorkExperienceForEdit?.fromOurCompany,
        });
      }
    }
  }, [props.showModal, props.selectedWorkExperienceForEdit]);

  useEffect(() => {
    calculateDuration();
  }, [formData.startDate, formData.endDate]);

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const resetData = () => {
    updateFormData({
      companyName: "",
      dateRegistered: "",
      workPosition: "",
      startDate: "",
      endDate: "",
      workingYears: "",
      workingMonths: "",
      workingDays: "",
      fromOurCompany: "",
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue;
  
    if (type === "checkbox") {
      newValue = checked;
    } else if (name === "pd_bank_account") {
      newValue = value.slice(0, 15).replace(/\D/g, "");
    } else if (name === "pd_professional_number") {
      newValue = value.replace(/\D/g, "");
    } else {
      newValue = value;
    }
  
    if (name === "fromOurCompany") {
      updateFormData({
        ...formData,
        [name]: newValue,
        companyName: checked ? "Интелигента" : "",
      });
    } else {
      updateFormData({
        ...formData,
        [name]: newValue,
      });
    }
    e.target.setCustomValidity("");
  };

  const getUserId = () => {
    if (param.id) {
      UserService.getUserDetailsById(param.id).then((data) => {
        setSelectedUserId(data.data);
        setOpenByAdmin(false);
        setIsActive(data.data.active);
      });
    } else {
      UserService.getUserDetails()
        .then((data) => {
          setSelectedUserId(data.data);
          setOpenByAdmin(false);
          setIsActive(data.data.active);
        })
        .catch(() => {});
    }
  };

  const addWorkExperience = (
    selectedUserId,
    companyName,
    dateRegistered,
    workPosition,
    startDate,
    endDate,
    workingYears,
    workingMonths,
    workingDays,
    fromOurCompany
  ) => {
    if (param.id === undefined) {
      WorkExperienceService.addWorkExperienceData(
        selectedUserId.id,
        companyName,
        dateRegistered,
        workPosition,
        startDate,
        endDate,
        workingYears,
        workingMonths,
        workingDays,
        fromOurCompany
      )
        .then(() => {
          props.handleClose();
          successfulAlert();
          props.getWorkExperienceData();
        })
        .catch(() => {
          warningAlert();
        });
    } else {
      WorkExperienceService.addWorkExperienceDataByAdmin(
        selectedUserId.id,
        companyName,
        dateRegistered,
        workPosition,
        startDate,
        endDate,
        workingYears,
        workingMonths,
        workingDays,
        fromOurCompany
      )
        .then(() => {
          props.handleClose();
          successfulAlert();
          props.getWorkExperienceData();
        })
        .catch(() => {
          warningAlert();
        });
    }
  };

  const editWorkExperience = (
    selectedUserId,
    companyName,
    dateRegistered,
    workPosition,
    startDate,
    endDate,
    workingYears,
    workingMonths,
    workingDays,
    fromOurCompany
  ) => {
    if (param.id === undefined) {
      WorkExperienceService.editWorkExperienceData(
        props.selectedWorkExperienceForEdit.workExperienceId,
        selectedUserId.id,
        companyName,
        dateRegistered,
        workPosition,
        startDate,
        endDate,
        workingYears,
        workingMonths,
        workingDays,
        fromOurCompany
      )
        .then(() => {
          props.handleClose();
          successfulAlert();
          props.getWorkExperienceData();
        })
        .catch(() => {
          warningAlert();
        });
    } else {
      WorkExperienceService.editWorkExperienceDataByAdmin(
        props.selectedWorkExperienceForEdit.workExperienceId,
        selectedUserId.id,
        companyName,
        dateRegistered,
        workPosition,
        startDate,
        endDate,
        workingYears,
        workingMonths,
        workingDays,
        fromOurCompany
      )
        .then(() => {
          props.handleClose();
          successfulAlert();
          props.getWorkExperienceData();
        })
        .catch(() => {
          warningAlert();
        });
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    const startDate = new Date(formData.startDate);
    const endDate = formData.endDate ? new Date(formData.endDate) : null;

    if (endDate && endDate < startDate) {
      return;
    }

    if (props.selectedWorkExperienceForEdit?.workExperienceId) {
      editWorkExperience(
        selectedUserId,
        formData.companyName,
        formData.dateRegistered,
        formData.workPosition,
        formData.startDate,
        formData.endDate,
        calculatedYears,
        calculatedMonths,
        calculatedDays,
        formData.fromOurCompany
      );
    } else {
      addWorkExperience(
        selectedUserId,
        formData.companyName,
        formData.dateRegistered,
        formData.workPosition?.value ?? formData.workPosition,
        formData.startDate,
        formData.endDate,
        calculatedYears,
        calculatedMonths,
        calculatedDays,
        formData.fromOurCompany
      );
    }
  };

  const calculateDuration = () => {
    let years, months, days;

    if (formData.startDate != null && formData.endDate != null) {
      const startDate = new Date(formData.startDate);
      const endDate = new Date(formData.endDate);

      setCalculatedYears(
        applicationService.getYearsMonthsDaysDifference(startDate, endDate)
          .years
      );
      setCalculatedMonths(
        applicationService.getYearsMonthsDaysDifference(startDate, endDate)
          .months
      );
      setCalculatedDays(
        applicationService.getYearsMonthsDaysDifference(startDate, endDate).days
      );
    } else {
      const startDate = new Date(formData.startDate);
      const endDate = new Date();

      setCalculatedYears(
        applicationService.getYearsMonthsDaysDifference(startDate, endDate)
          .years
      );
      setCalculatedMonths(
        applicationService.getYearsMonthsDaysDifference(startDate, endDate)
          .months
      );
      setCalculatedDays(
        applicationService.getYearsMonthsDaysDifference(startDate, endDate).days
      );
    }
  };

  useEffect(() => {
    NomenclatureService.getAllNomenclatureEntryByNomenclatureName('Работни позиции')
    .then((response) => {
      let nomenclatureConvert = response.data.map((n) => {
        return {
          value: n.id,
          label: applicationService.getActiveLanguage() == 'mk' ? n.labelMk : n.labelEn
        };
      });
      setNomenclaturePositions(nomenclatureConvert);
    });
  }, []);

  const handleNomenclatureSelectChange = (e) => {
    updateFormData({
      ...formData,
      workPosition: e
    });
  };

  return (
    <Modal
      show={props.showModal}
      dialogAs={DraggableModalDialog}
      onHide={props.handleClose}
      size={"lg"}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.selectedWorkExperienceForEdit.workExperienceId
            ? strings.editWorkExperience
            : strings.addWorkExperience}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <Modal.Body>
          <Row className={"mb-2"}>
            <Col xs={12} md={8}>
              <Form.Group
                className="d-flex"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Group className={"d-flex my-auto mt-1"}>
                  <Form.Label className={"me-3"}>
                    {strings.fromInteligenta}
                  </Form.Label>
                  <div className={"mt-1"}>
                    <label className="switchEnabled">
                      <input
                        type="checkbox"
                        checked={formData.fromOurCompany}
                        name={"fromOurCompany"}
                        onChange={handleChange}
                      />
                      <span className="sliderEnabled round"> </span>
                    </label>
                  </div>
                </Form.Group>
              </Form.Group>
            </Col>
          </Row>
          <Row className={"mb-2"}>
            <Col xs={12} md={12}>
              <Form.Group className="mb-3">
                <Form.Label className={"d-flex"}>
                  {strings.companyName}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type={"text"}
                  value={formData.companyName}
                  required
                  onChange={handleChange}
                  name={"companyName"}
                  disabled={formData.fromOurCompany}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className={"mb-1"}>
            <Col xs={12} md={6}>
              <Form.Label className={"d-flex"}>
                <b>{strings.duration}:</b>
              </Form.Label>
            </Col>
            <Col xs={12} md={6}>
              <Form.Label className={"d-flex"}>
                <b>{strings.total}:</b>
              </Form.Label>
            </Col>
          </Row>
          <Row className={"mb-2"}>
            <Col xs={12} md={3}>
              <Form.Group className="mb-3">
                <Form.Label className={"d-flex"}>
                  {strings.started}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type={"date"}
                  value={formData.startDate}
                  onChange={handleChange}
                  required
                  name={"startDate"}
                  max={formData.endDate}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group className="mb-3">
                <Form.Label className={"d-flex"}>{strings.ended}</Form.Label>
                <Form.Control
                  type={"date"}
                  value={formData.endDate}
                  onChange={handleChange}
                  name={"endDate"}
                  min={formData.startDate}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={2}>
              <Form.Group className="mb-3">
                <Form.Label className={"d-flex"}>{strings.years}</Form.Label>
                <Form.Control
                  type={"text"}
                  value={isNaN(calculatedYears) ? "0" : calculatedYears}
                  onChange={handleChange}
                  name={"workingYears"}
                  readOnly
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={2}>
              <Form.Group className="mb-3">
                <Form.Label className={"d-flex"}>{strings.months}</Form.Label>
                <Form.Control
                  type={"text"}
                  value={isNaN(calculatedMonths) ? "0" : calculatedMonths}
                  onChange={handleChange}
                  name={"workingMonths"}
                  readOnly
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={2}>
              <Form.Group className="mb-3">
                <Form.Label className={"d-flex"}>{strings.days}</Form.Label>
                <Form.Control
                  type={"text"}
                  value={isNaN(calculatedDays) ? "0" : calculatedDays}
                  onChange={handleChange}
                  name={"workingDays"}
                  readOnly
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className={"mb-1"}>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label className={"d-flex"}>
                  {strings.dateRegistered}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type={"date"}
                  value={formData.dateRegistered}
                  onChange={handleChange}
                  required
                  name={"dateRegistered"}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={8}>
  {formData.fromOurCompany ? (
  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
    <Form.Label className={'d-flex'}>
      {strings.workPosition}<span className="text-danger">*</span>
    </Form.Label>
    <Select
      name={'selectedNomenclatureEntry'}
      options={nomenclaturePositions}
      required
      onChange={handleNomenclatureSelectChange}
      placeholder={strings.selectOption}
      noOptionsMessage={()=>strings.noOptions}
      value={formData.workPosition}
      onInvalid={(e) => {
        e.target.setCustomValidity(strings.fieldsRequired);
      }}/>
  </Form.Group>
  ) : (
    <Form.Group className="mb-3">
      <Form.Label className={"d-flex"}>
        {strings.workExperiencePosition}
        <span className="text-danger">*</span>
      </Form.Label>
      <Form.Control
        type={"text"}
        value={formData.workPosition}
        onChange={handleChange}
        required
        name={"workPosition"}
        onInvalid={(e) => {
          e.target.setCustomValidity(strings.fieldsRequired);
        }}
      />
    </Form.Group>
  )}
</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className={"cancel btn text-white"} onClick={props.handleClose}>
            {strings.cancel}
          </div>
          <button type={"submit"} className={"save btn text-white"}>
            {strings.save}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddWorkExperienceModal;
