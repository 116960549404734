import axios from "../../axios/axios";

const ProjectService = {

    getAllProjects: () => {
        return axios.get("/projects/getAll");
    },

    getProjectById: (id) => {
        return axios.get(`/projects/getProjectById/${id}`);
    },

    getAllProjectsForGroupForLoggedUser: (groupId) => {
        return axios.get(`/projects/${groupId}/getAllProjectsForLoggedUser`);
    },

    addProject: (newFormData) => {
        return axios.post("/projects/addProject", newFormData, {
           headers: {
               'Content-Type': 'multipart/form-data'
           }
        })
            .then(response => {
                return response.data;
            });
    },

    editProject: (newFormData) => {
        return axios.put(`/projects/updateProject`, newFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    deleteProject: (id) => {
        return axios.delete(`/projects/deleteProject/${id}`)
    },

    getAllProjectsForGroupId: (id) =>{
        return axios.get(`/projects/getAllProjectsForGroupId/${id}`);
    },

    getAllProjectsForUser:(userId) => {
        return axios.get(`/projects/getAllProjectsForUser/${userId}`)
    },
    getAllProjectsForUserLogged: () => {
        return axios.get(`/projects/userProjects`)
    },
    getAllProjectsForLoggedUser:() => {
        return axios.get("/projects/getAllProjectsForLoggedUser")
    },


}

export default ProjectService;
