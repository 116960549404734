import "./UserEventsData.css";
import UserAttendedEvents from "./UserAttendedEvents/UserAttendedEvents";
import UserEventProposals from "./UserEventProposals/UserEventProposals";
import EventProposalService from "../../../repository/eventsRepository/EventProposalRepository";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {strings} from "../../../localization/Localization";
import EventProposal from "../../Events/EventProposal/EventProposal";
import EventsService from "../../../repository/eventsRepository/EventsRepository";

const UserEventsData = (props) => {

    const param = useParams();

    const [eventProposalsForUser, setEventProposalsForUser] = useState([]);

    const [userAttendedEvents, setUserAttendedEvents] = useState([]);

    useEffect(() => {
        if (props.currentTab === "eventsForUser") {
            getEventProposalsForUser();
            getUserAttendedEvents();
        }
    }, [props.currentTab]);

    const getEventProposalsForUser = () => {
        if (param.id) {
            EventProposalService.getEventProposalsForUserId(param.id)
                .then((data) => {
                    setEventProposalsForUser(data.data);
                })
        } else {
            EventProposalService.getMyEventProposals()
                .then((data) => {
                    setEventProposalsForUser(data.data);
                })
        }
    }

    const getUserAttendedEvents = () => {
        if (param.id) {
            EventsService.getEventsForUserWithId(param.id)
                .then((data) => {
                    setUserAttendedEvents(data.data);
                })
        } else {
            EventsService.getEventsForLoggedInUser()
                .then((data) => {
                    setUserAttendedEvents(data.data);
                })
        }
    }

    return (
        <div className={"p-4"}>
            <div>
                <div>
                    <h2>
                        {param.id ? (
                            strings.eventsAttendedFromUser + props.selectedUser?.firstName + " " + props.selectedUser?.lastName
                        ) : (
                            strings.myAttendedEvents
                        )}
                    </h2>
                </div>
                <div className={"row table-responsive mt-1"}>
                    <table id="keywords" className={"mb-1 custom-table-header"}>
                        <thead>
                        <tr className="userlist">
                            <th scope={"col"}><b>{strings.name}</b></th>
                            <th scope={"col w-25"}>{strings.description}</th>
                            <th scope={"col w-25"}>{strings.eventType}</th>
                            <th scope={"col"}>{strings.organisation}</th>
                            <th scope={"col"}>{strings.trainer}</th>
                            <th scope={"col"}>{strings.status}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {userAttendedEvents.length > 0 && userAttendedEvents.map((attendedEvent, index) => (
                            <UserAttendedEvents key={index}
                                                attendedEvent={attendedEvent}
                                                selectedUser={props.selectedUser}
                                                currentTab={props.currentTab}/>
                        ))}
                        </tbody>
                    </table>
                    {
                        userAttendedEvents.length === 0 &&
                        // <h3 className={"text-center my-5"}>
                        //     <i className={"text-secondary no-notification-message"}>{strings.noEvents}</i>
                        // </h3>
                        <div className={"justify-content-center text-center "}>
                            <h5 className={"text-secondary"}><i>{strings.emptyList}</i></h5>
                        </div>
                    }
                </div>
            </div>
            <br/>


            <div>
                <div>
                    <h2>
                        {param.id ? (
                            strings.userEventProposals + props.selectedUser?.firstName + " " + props.selectedUser?.lastName
                        ) : (
                            strings.myEventProposals
                        )}
                    </h2>
                </div>
                <div className={"row table-responsive mt-1"}>
                    <table id="keywords" className={"mb-1 custom-table-header"}>
                        <thead>
                        <tr className="userlist">
                            <th scope={"col"}><b>{strings.name}</b></th>
                            <th scope={"col w-25"}>{strings.description}</th>
                            <th scope={"col"}>{strings.userEventProposal}</th>
                            <th scope={"col "} className={"pe-4"}>{strings.status}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {eventProposalsForUser.length > 0 && eventProposalsForUser.map((eventProposal, index) => (
                            <UserEventProposals key={index}
                                                eventProposal={eventProposal}
                                                selectedUser={props.selectedUser}
                                                currentTab={props.currentTab}
                            />
                        ))}
                        </tbody>
                    </table>
                    {
                        eventProposalsForUser.length === 0 &&
                        // <h3 className={"text-center my-5"}>
                        //     <i className={"text-secondary no-notification-message"}>{strings.noEventProposals}</i>
                        // </h3>
                        <div className={"justify-content-center text-center "}>
                            <h5 className={"text-secondary"}><i>{strings.emptyList}</i></h5>
                        </div>
                    }
                </div>
            </div>
        </div>
    )

}

export default UserEventsData;