import axios from "../../axios/axios";

const EquipmentRepository = {
    getAllEquipments: () => {
        return axios.get("/equipment-possession/getAllEquipmentNomenclatureEntries")
    },
    getAllEquipmentsForLoggedUser: () => {
        return axios.get("/equipment-possession/get-all-logged-user")
    },
    getAllEquipmentsForUser: (userId) => {
        return axios.get(`/equipment-possession/get-all-user/${userId}`)
    },
    getEquipmentPossessionById: (id) => {
        return axios.get(`/equipment-possession/get-by-nomenclatureEntryId/${id}`)
    },
    getAllEquipmentPossessionsForEquipment: (id) => {
        return axios.get(`/equipment-possession/get-all-equipment-possessions-for-equipment/${id}`)
    },
    addEquipmentPossession: (quantity, model, serialNumber, description, manufacturer, dateAcquired, userId, equipmentNomenclatureEntryId) => {
        return axios.post("/equipment-possession/add", {
            quantity,
            model,
            serialNumber,
            description,
            manufacturer,
            userId,
            dateAcquired,
            equipmentNomenclatureEntryId
        })
    },

    editEquipmentPossession: (id,quantity, model, serialNumber, description, manufacturer,selectedUserId, dateAcquired, equipmentNomenclatureEntryId) => {
        return axios.put("/equipment-possession/update",{
            id,
            quantity,
            model,
            serialNumber,
            description,
            manufacturer,
            selectedUserId,
            dateAcquired,
            equipmentNomenclatureEntryId
        })
    },
    
    deleteUserEquipmentData: (id) => {
        return axios.delete(`/equipment-possession/delete/${id}`)
    },
    
};

export default EquipmentRepository;