import React from "react";
import DropDown from "./Dropdown";
import {useEffect, useState} from "react";
import UserRepository from "../repository/userRepository/UserRepository";
import {strings} from "../localization/Localization";
import Notifications from "./Notifications/Notifications";
import {Link} from "react-router-dom";
import Logo from "../assets/images/logo-inteligenta.png"
import {useDispatch, useSelector} from "react-redux";
import {update} from "../redux/userSlice";
import  femaleAvatar  from "../assets/images/female_avatar.png"

const Navigationbar = () => {

    const [userData, setUserData] = useState({});

    const userDetailsRedux=useSelector((state)=> state.user);

    const dispach=useDispatch();

    useEffect(() => {
        UserRepository.getUserDetails().then(res => {
            const firstName=res.data.firstName;
            const lastName=res.data.lastName;
            const parentName=res.data.parentName;
            const gender=res.data.person.gender;
            const email=res.data.email;
            dispach(update({firstName,lastName,parentName,gender,email}))
            setUserData(res.data)
        })
    }, []);

    const setLanguageToEnglish = () => {
        strings.setLanguage('en');
        localStorage.setItem("activeLanguage", "en")
        window.location.reload();
    }

    const setLanguageToMacedonian = () => {
        strings.setLanguage('mk');
        localStorage.setItem("activeLanguage", "mk")
        window.location.reload();
    }

    return (
        <div className="navigation container-fluid  p-1">
            <div className="row g-0 align-items-center h-100">
                <div className="col-4 justify-content-end text-start mt-1  ps-2">
                    <div className={"w-100 h-100 navigationbar_logo_wrapper"}>
                        <Link to="/home">
                            <img className="img-fluid "
                                 src={Logo}/>
                        </Link>
                    </div>
                </div>
                <div className="col-8 justify-content-end align-items-center  text-end d-flex">
                    <div className="justify-content-end align-items-center information text-start d-flex text-wrap">
                        <Link to={"/userDetails"}
                            className={"h-100 w-100 text-center align-items-center  justify-content-end d-flex mx-1"}>
                            <img className="user_list_image image_border_radius_50 me-3    "
                                 src={userData.profileImageHelper ? (`data:image/*;base64,${userData.profileImageHelper.bytes}`) :
                                     ( userDetailsRedux.gender === "MALE" ? "https://bootdey.com/img/Content/avatar/avatar7.png": femaleAvatar)} alt="..."/>
                        </Link>
                        <div className={"mx-1"}>
                            <div
                                className={'text-dark'}>{userDetailsRedux.firstName ? userDetailsRedux.firstName : ""} {userDetailsRedux.lastName ? userDetailsRedux.lastName : ""} </div>
                            <div className={'text-dark'}>{userData.email ? userData.email : ""}</div>
                        </div>
                    </div>
                    <div className={"d-flex align-items-start left-space"}>
                        <div onClick={setLanguageToMacedonian} className="btn btn-sm  m-sm-1 text-white language-btn"
                        >MK
                        </div>
                        <div onClick={setLanguageToEnglish} className="btn btn-sm  m-sm-1 text-white language-btn"
                        >EN
                        </div>
                    </div>
                    <div className={"d-flex align-items-end text-start "}>
                        <div className="d-flex align-items-end justify-content-center text-end h-100 mt-auto">
                            <Notifications/>
                        </div>
                        <div className=" d-flex align-items-end justify-content-center text-end h-100">
                            <DropDown/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Navigationbar;
