import {useParams} from "react-router-dom";
import "./EventGroupDetails.css"
import swal from "sweetalert";
import {strings} from "../../../localization/Localization";
import EventGroupService from "../../../repository/eventsRepository/EventGroupRepository";
import React, {useEffect, useState} from "react";
import AddOrEditEventGroup from "./AddOrEditEventGroupModal/AddOrEditEventGroup";
import ApplicationService from "../../../ApplicationService";
import UserEventGroupService from "../../../repository/eventsRepository/UserEventGroupRepository";
import UserInEventGroupTerm from "../UserInEventGroup/UserInEventGroupTerm";
import FormatDate from "../../../components/formatDate/FormatDate";

const EventGroupDetails = (props) => {

    const param = useParams();

    const [selectedGroupForEdit, setSelectedGroupForEdit] = useState({});

    const [showAddModal, setShowAddModal] = useState(false);

    const [usersForEventGroup, setUsersForEventGroup] = useState([]);

    const [isClicked, setIsClicked] = useState(false);

    const [permissionForUpsert, setPermissionForUpsert] = useState(ApplicationService.hasRole(["ROLE_HR", "ADMINISTRATOR"]));

    useEffect(() => {
        getAllUsersForEventGroup();
        if (ApplicationService.hasRole(["ROLE_HR", "ROLE_ADMINISTRATOR"])) {
            setPermissionForUpsert(true);
        }
    }, []);

    const getAllUsersForEventGroup = () => {
        UserEventGroupService.getAllUsersForEventGroup(props.eventGroup?.eventGroupId)
            .then((data) => {
                setUsersForEventGroup(data.data);
            }).catch(() => {
            errorAlert();
        })
    }

    const handleShowAddEventGroupModal = () => {
        setShowAddModal(true);
    };

    const handleCloseAddEventGroupModal = () => {
        setSelectedGroupForEdit({});
        setShowAddModal(false);
    };

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const errorAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const ConfirmationAttendGroup = (id) => {
        swal(strings.attendConfirmation, {
            title: strings.areYouSure,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                attendEventGroup(id);
            }
        });
    };

    const ConfirmationCancelAttendanceGroup = (id) => {
        swal(strings.attendCancelConfirmation, {
            title: strings.areYouSure,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                cancelAttendanceForGroup(id);
            }
        });
    }

    const ConfirmationDelete = (id) => {
        swal(strings.deleteConfirmation, {
            title: strings.areYouSure,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteEventGroup(id);
            }
        });
    };

    const deleteEventGroup = (id) => {
        EventGroupService.deleteEventGroup(id)
            .then(() => {
                successfulAlert();
                props.getAllEventGroups();
                props.getEventDetails();
            })
            .catch(() => {
                errorAlert();
            })
    };

    const attendEventGroup = (id) => {
        UserEventGroupService.userAttendsEventGroup(id)
            .then(() => {
                setIsClicked(isClicked);
                props.getAllUserEventGroupsInEventForLoggedInUser();
                props.getAllGroupsForEvent();
                getAllUsersForEventGroup()
                successfulAlert();
            })
            .catch(() => {
                errorAlert();
            })
    }

    const cancelAttendanceForGroup = (id) => {
        UserEventGroupService.userCancelsAttendanceForEventGroup(id)
            .then(() => {
                successfulAlert();
                setIsClicked(isClicked);
                props.getAllUserEventGroupsInEventForLoggedInUser();
                props.getAllGroupsForEvent();
                getAllUsersForEventGroup();
            })
            .catch(() => {
                errorAlert();
            })
    }

    return (
        <div
            key={props.eventGroup.eventGroupId}
            className={`border mb-3 border-1 border-radius p-3 border-light ${
                props.eventGroup.eventGroupId ===
                (props.allEventGroupsForEventForLoggedInUser?.[0]?.eventGroupId || null)
                    ? 'eventGroupColor clicked'
                    : 'bg-light'
            }`}
        >
            <AddOrEditEventGroup showModal={showAddModal}
                                 handleClose={handleCloseAddEventGroupModal}
                                 selectedEventGroupForEdit={selectedGroupForEdit}/>
            <div className={"d-flex"}>
                <div>
                    <div className={"d-flex align-items-center mb-2"}>
                        <div className={"d-flex flex-row"}>
                            <i className={"me-1 material-icons"}>schedule</i> {FormatDate.formatDate(props.eventGroup.startDate)} - {FormatDate.formatDate(props.eventGroup.endDate)}
                        </div>
                    </div>

                    <div className={"d-flex flex-row"}>
                        <div className={"d-flex"}>
                            <div className={"d-flex flex-column"}>
                                <i className={"text-secondary"}>{strings.trainer}:</i>
                                <i className={"text-secondary"}>{strings.location}: </i>
                                <i className={"text-secondary"}>{strings.status}: </i>
                                <i className={"text-secondary"}>{strings.capacity}: </i>
                            </div>
                            <div className={"d-flex flex-column ms-2"}>
                                <b className={"text_blue_dark"}>{props.eventGroup.trainer}</b>
                                <b className={"text_blue_dark"}>{props.eventGroup.location}</b>
                                <b className={"text_blue_dark"}>{props.eventGroup.status}</b>
                                <b className={"text_blue_dark"}>{props.eventGroup.capacity}</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"d-flex flex-column ms-auto"}>
                    <div>
                        {param.id &&
                            <div className={"ms-auto d-flex"}>
                                <div className={'icon-div mx-2'}>
                                    <div
                                        onClick={() => {
                                            setSelectedGroupForEdit(props.eventGroup);
                                            handleShowAddEventGroupModal();
                                        }}
                                        title={'Edit'}
                                        className={'text-dark icon'}>
                                        <i className={"edit-icon-circle material-icons "}>
                                            create
                                        </i>
                                    </div>
                                </div>
                                <div className={'icon-div'}>
                                    <a title={'Delete'}>
                                        <div
                                            onClick={() => ConfirmationDelete(props.eventGroup.eventGroupId)}
                                            title={'Delete'}
                                            className={'text-dark icon'}>
                                            <i className={"material-icons delete-icon-circle"}>delete</i>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={"d-flex ms-auto"}>
                        {!param.id && (
                            <div className={"ms-auto d-flex"}>
                                {usersForEventGroup.length > 0 ? (
                                    <div className={"d-flex flex-column"}>
                                        <div>
                                            {strings.vacancies} {props.eventGroup.capacity - usersForEventGroup.length}
                                        </div>
                                        <div className={"ms-auto"}>
                                            {strings.usersAttending} {usersForEventGroup.length}
                                        </div>
                                    </div>
                                ) : (
                                    strings.emptyEventGroup
                                )}
                            </div>
                        )}

                    </div>
                    <div className={"align-self-start ms-auto mt-auto cursor-pointer"}>
                        {!param.id && props.eventGroup.status === 'active' &&
                            <div>
                                {props.allEventGroupsForEventForLoggedInUser[0]?.eventGroupId === props.eventGroup.eventGroupId ? (
                                    <div className={'ms-auto mt-auto cursor-pointer'}>
                                        <a>
                                            <div className={"cancel btn text-white border-radius"}
                                                 onClick={() => {
                                                     ConfirmationCancelAttendanceGroup(props.eventGroup.eventGroupId);
                                                 }}
                                            >
                                                {strings.cancel}
                                            </div>
                                        </a>
                                    </div>) : (
                                    <>
                                        {props.allEventGroupsForEventForLoggedInUser.length === 0 && props.eventGroup.capacity !== usersForEventGroup.length &&
                                            <div>
                                                <a>
                                                    <div
                                                        className={"btn text-white text-end d-flex align-items-center border-radius px-xs-0  add-button"}
                                                        onClick={() => ConfirmationAttendGroup(props.eventGroup.eventGroupId)}>
                                                        <i className={"text-white"}>{strings.attend}</i>
                                                    </div>
                                                </a>
                                            </div>
                                        }
                                    </>)

                                }
                            </div>
                            // (
                            //     <div className={"align-self-start ms-auto mt-auto"}>
                            //         This group is not active
                            //     </div>
                            // )
                        }
                    </div>
                </div>
            </div>
            {param.id && (
                <div className={"mt-3"}>
                    <h4>{strings.listOfParticipants}: ({strings.total}: {usersForEventGroup.length})</h4>
                    <div className={"row table-responsive"}>
                        <table id="keywords" className={"mb-1"}>
                            <thead className={""}>
                            <tr>
                                <th scope={"col"}>{strings.name}</th>
                                <th scope={"col"}>{strings.lastName}</th>
                                <th scope={"col"}>{strings.phoneNumber}</th>
                                <th scope={"col"} className={'text-end '}>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {usersForEventGroup.length > 0 &&
                                usersForEventGroup.map((user, index) => (
                                    <UserInEventGroupTerm user={user}
                                                          key={index}
                                                          permissionForUpsert={permissionForUpsert}
                                                          eventGroup={props.eventGroup}
                                                          usersForEventGroup={getAllUsersForEventGroup}/>
                                ))
                            }
                            </tbody>
                        </table>
                        {usersForEventGroup.length === 0 &&
                            <div className={"justify-content-center text-center w-100"}>
                                <div>
                                    <h5 className={"text-secondary w-100 "}>
                                        <i>{strings.emptyList}</i></h5>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )}

        </div>
    )
}

export default EventGroupDetails;