import React, {useState} from "react"
import {Link} from "react-router-dom";
import Logo from "../../assets/images/logo-inteligenta.png"
import Notifications from "../Notifications/Notifications";
import DropDown from "../Dropdown";
import {FiMenu} from "react-icons/fi";
import "./MobileNavigationBar.css"


const MobileNavigationBar = (props) => {


    return (
        <div className="mobile-navigation container-fluid ps-1 pt-3">
            <div className="row align-items-center">
                <div className="col-8 justify-content-end text-start h-100 ">
                    <Link to="/home"><img
                    className="img-fluid px-2 h-100 "  src={Logo}/></Link>
                </div>
                <div className="col-4 justify-content-end align-items-center  d-flex">

                    <div className={"d-flex align-items-center text-end "}>
                        <div className={"d-flex "}>
                            <div className="d-flex justify-content-end">
                                <Notifications/>
                            </div>
                            <div className="d-flex justify-content-end ps-3">
                                <DropDown/>
                            </div>
                        </div>
                        <div className={"text-dark me-2"} onClick={props.handleMobileMenu}>
                            <FiMenu size={50}/>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );

}
export default MobileNavigationBar