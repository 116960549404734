import React, {useState} from "react"
import {Link, useHistory} from "react-router-dom";
import UserAddService from "../../repository/userRepository/UserAddRepository";
import Form from "react-bootstrap/Form";
import ApplicationService from "../../ApplicationService";
import Logo from "../../assets/images/logo-inteligenta.png"
import Button from "react-bootstrap/Button";
import "./ChangePasswordMobile.css";

const ChangePasswordMobile = () => {

    const history = useHistory();

    const [newPassword, setNewPassword] = useState("");

    const [repeatNewPassword, setRepeatNewPassword] = useState("");

    const bothFieldsAreFilled = newPassword && repeatNewPassword;

    const passwordsMatch = newPassword === repeatNewPassword;

    const queryParams = new URLSearchParams(window.location.search)

    const TOKEN = queryParams.get("token")

    const changePassword = () => {
        if (newPassword === repeatNewPassword && newPassword != '' && repeatNewPassword != '') {
            const form = new FormData();
            form.append('newPassword', newPassword);
            form.append('repeatNewPassword', repeatNewPassword);
            UserAddService.changePassword(newPassword, repeatNewPassword, TOKEN)
                .then((data) => {
                    history.push("/finalized_registration?status=true")
                }).catch(() => {
                history.push("/finalized_registration?status=false")
            })

        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <div className="back_change_password_mobile h-100 w-100 pt-5 px-4">
            <Form className="" onSubmit={changePassword}>
                <div className="d-flex justify-content-center">
                    <img src={Logo} className={"logo"}/>
                </div>
                <hr className={"mb-5"}/>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password<span className="text-danger">*</span></Form.Label>
                    <Form.Control type="password" placeholder="Enter new password" aria-required={"true"}
                                  onChange={(event) => setNewPassword(event.target.value)}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicRepeatPassword">
                    <Form.Label>Repeat Password<span className="text-danger">*</span></Form.Label>
                    <Form.Control type="password" placeholder="Repeat new password" aria-required={"true"}
                                  onChange={(event) => setRepeatNewPassword(event.target.value)}/>
                </Form.Group>
                <div className={'d-inline-flex text-center justify-content-center'}>
                    {passwordsMatch && bothFieldsAreFilled ?
                        (
                            <Link to={" "}> <Button variant="primary" type="submit" onClick={(event) => {
                                changePassword()
                            }}>
                                Submit
                            </Button>
                            </Link>

                        ) : null
                    }
                    {bothFieldsAreFilled ?
                        (passwordsMatch ?
                                (
                                    <div className={'d-inline-flex mx-3 my-2 text-center justify-content-center'}>
                                        <i className="material-icons mx-1">check_circle</i>
                                        Passwords match
                                    </div>
                                ) :
                                (
                                    <div className={'d-sm-flex'}>
                                        <i className="material-icons ">report</i>
                                        Passwords do not match
                                    </div>
                                )
                        ) : null
                    }
                </div>
            </Form>
        </div>
    );
}
export default ChangePasswordMobile;