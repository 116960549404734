import React, {useEffect, useState} from "react";
import {Calendar, dateFnsLocalizer} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import {strings} from "../../../localization/Localization";
import Modal from "react-bootstrap/Modal";
import './CalendarList.css'
import {enUS, mk} from "date-fns/locale";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import HolidayService from "../../../repository/holidayRepository/holidayRepository";
import {Link, useHistory} from "react-router-dom";
import StyleForCalendarEvents from "../../../components/StyleForCalendarEvents";
import {FaCalendarPlus} from "react-icons/fa";
import ColorLegend from "../../../components/ColorLegend/ColorLegend";
import ColorLegendWrapper from "../ColorLegendWrapper/ColorLegendWrapper";
import ApplicationService from "../../../ApplicationService";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const locales = {
    'mk': mk,
    'en-US': enUS

};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const calendarList = () => {

    const customviews = ["week", "month"];

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [newEvent, setNewEvent] = useState([{title: "", start: "", end: "", type: ""}]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [allEvents, setAllEvents] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showModal, setShowModal] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectedDate, setSelectedDate] = useState(new Date());

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [holidaysForSelectedDay, setHolidaysForSelectedDay] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [holidaysForSelectedMonth, setHolidaysForSelectedMonth] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [defaultDate, setDefaultDate] = useState();

    const queryParams = new URLSearchParams(window.location.search);

    const urlYear = queryParams.get("year")

    const urlMonth = queryParams.get("month")

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const history = useHistory()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isLoading, setIsLoading] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
    }, [allEvents])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        setIsLoading(true)
        var date = null;
        if (urlYear !== null && urlMonth !== null) {
            date = new Date(parseInt(urlYear), parseInt(urlMonth) + 1, 0,)
        } else {
            date = new Date()
        }
        setDefaultDate(date)
        setIsLoading(false)
        getHolidaysOnSelectedMonth(date.getMonth(), date.getFullYear())
    }, [])

    const handleClose = () => {
        setShowModal(false);
    };

    const handleShow = () => {
        setShowModal(true);
    };

    const getActiveLanguage = () => {
        const activeLanguage = localStorage.getItem('activeLanguage')
        if (activeLanguage === 'mk') {
            return "mk"
        } else {
            return "en-US"
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        getHolidaysOnSelectedDay()
    }, [selectedDate])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        let newEvents = [];
        for (let holiday of holidaysForSelectedMonth) {
            newEvents.push({
                title: holiday.name,
                start: new Date(holiday.year, holiday.month, holiday.day),
                end: new Date(holiday.year, holiday.month, holiday.day + 1),
                type: holiday?.holidayType?.name
            })
        }
        setAllEvents(newEvents)
    }, [holidaysForSelectedMonth])

    const getHolidaysOnSelectedDay = () => {
        if (selectedDate.start) {
            HolidayService.getHolidayBySelectedDay(selectedDate.start.getDate(), selectedDate.start.getMonth() + 1, selectedDate.start.getFullYear())
                .then((data) => {
                    setHolidaysForSelectedDay(data.data.content)
                })
        }
    }

    const getHolidaysOnSelectedMonth = (month, year) => {

        HolidayService.getHolidayBySelectedMonth(month + 1, year)
            .then((data) => {
                setHolidaysForSelectedMonth(data.data.content)
            })

    }

    const getStringOfSelectedDateAndData = () => {
        if (showModal) {
            let start = selectedDate.start;
            let monthNumber = start.getMonth();
            let day = start.getDay();
            let date = start.getDate();
            let year = start.getFullYear();
            const monthString = [strings.january, strings.february, strings.march, strings.april, strings.may, strings.june, strings.july, strings.august, strings.september, strings.october, strings.november, strings.december];
            const weekday = [strings.sunday, strings.monday, strings.tuesday, strings.wednesday, strings.thursday, strings.friday, strings.saturday];
            return weekday[day] + ", " + date + " " + monthString[monthNumber] + " " + year;
        }
    }

    const redirectToHolidayDetails = (id) => {
        history.push("/holiday/" + id)
    }

    const getEventForCurrentMonth = (date) => {
        getHolidaysOnSelectedMonth(date.getMonth(), date.getFullYear())
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        return {
            style: StyleForCalendarEvents.getStyleForType(event.type)
        }
    }

    return (
        <>
            <div className={"container mt-2 pb-4 h-100"}>
                <div className="w-100 d-flex justify-content-between">
                    <h1>{strings.calendarOfHolidays}</h1>
                    {ApplicationService.hasRole(["ROLE_HR", "ROLE_ADMINISTRATOR"]) &&
                        <Link to="/holiday">
                            <div
                                className={`btn border-radius text-white align-items-center save d-flex add-holiday-link my-2 m-0 p-2  ${ApplicationService.getActiveLanguage() == "mk" ? "px-3 " : "px-3 me-1"}`}>
                                <div className={"pb-1 m-0 align-items-center d-flex text-center"}>
                                    <FaCalendarPlus size={18}/>
                                </div>
                                <div className={"mx-1"}>
                                    {strings.addHoliday}
                                </div>
                            </div>
                        </Link>
                    }
                </div>
                {!isLoading && defaultDate &&
                    <Calendar
                        messages={{
                            previous: ('<'),
                            next: ('>'),
                            today: ('•'),
                            week: strings.week,
                            month: strings.month
                        }}
                        views={customviews}
                        defaultDate={defaultDate}
                        localizer={localizer}
                        culture={getActiveLanguage()}
                        selectable
                        onSelectSlot={(slotInfo) => {
                            setSelectedDate(slotInfo)
                            handleShow();
                        }}
                        onSelectEvent={(data) => {
                            setSelectedDate(data)
                            handleShow();
                        }}
                        onNavigate={(date, view, action) => {
                            if (action == "DATE") {
                                setSelectedDate({start: date})
                                handleShow();
                            } else {
                                getEventForCurrentMonth(date)
                            }
                        }}
                        events={allEvents}
                        eventPropGetter={(eventStyleGetter)}
                        startAccessor="start"
                        endAccessor="end"
                        style={{height: "100%"}}/>
                }
                <Modal
                    show={showModal}
                    dialogAs={DraggableModalDialog}
                    onHide={handleClose}
                    keyboard={false}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{getStringOfSelectedDateAndData()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {holidaysForSelectedDay.length > 0 && ApplicationService.hasRole(["ROLE_HR", "ROLE_ADMINISTRATOR"]) &&
                            <>
                                <div className={"text-end"}>
                                    <Link to={{pathname: "/holiday", params: selectedDate.start}}
                                          className={"btn btn-sm add-button text-white justify-content-end"}>
                                        {strings.addHoliday}
                                    </Link>
                                </div>
                                <hr/>
                            </>
                        }

                        {
                            holidaysForSelectedDay.length ? (
                                holidaysForSelectedDay.map((holiday) => {
                                    var classForColor = "border-national";
                                    if (holiday.ethnicity) {
                                        classForColor = "border-ethnicity";
                                    } else if (holiday.religion) {
                                        classForColor = "border-religion"
                                    } else if (holiday.holidayType.name === "RELIGIOUS AND NATIONAL") {
                                        classForColor = "border-religion-and-national"
                                    }
                                    return (
                                        <div key={holiday.id}>

                                            <div className={'body-modal p-2 row ' + classForColor}
                                                 onClick={event => {
                                                     redirectToHolidayDetails(holiday.id)
                                                 }}>
                                                <div className={'col-8'}>
                                                    <h6>{holiday.name}</h6>
                                                    <p>{holiday.description}</p>
                                                </div>
                                                <div className={'col-4'}>
                                                    <p className={"holiday-type"}>{holiday.holidayType.label_mk}</p>
                                                    {
                                                        holiday.ethnicity ? (
                                                            <p>{holiday.ethnicity.label_mk}</p>
                                                        ) : null
                                                    }
                                                    {
                                                        holiday.religion ? (
                                                            <p>{holiday.religion.label_mk}</p>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                            <hr/>
                                        </div>
                                    );
                                })
                            ) : (
                                <>
                                    <h6 className={'text-center text-secondary'}>
                                        <i>  {strings.noHolidaysForSelectedDate} </i>
                                    </h6>
                                    {ApplicationService.hasRole(["ROLE_HR", "ROLE_ADMINISTRATOR"]) &&
                                        <div className={"text-center mt-3 "}>
                                            <Link to={{pathname: "/holiday", params: selectedDate.start}}
                                                  className={"btn btn-sm add-button text-white justify-content-end"}>{strings.addHoliday}
                                            </Link>
                                        </div>
                                    }
                                </>
                            )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <div className={"btn text-white cancel"} onClick={handleClose}>
                            {strings.cancel}
                        </div>
                    </Modal.Footer>
                </Modal>
                <div className={"d-flex w-100 mt-3 mb-5"}>
                    <ColorLegendWrapper/>
                </div>
            </div>
        </>
    );
}
export default calendarList;