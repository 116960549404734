import axios from "../../axios/axios";

const HolidayService ={
    addHoliday: (name, description, day, month, year,holidayTypeId,ethnicityId,religionId,recurring) =>{
        return axios.post("/holiday/add",{
            name,
            description,
            day,
            month,
            year,
            holidayTypeId,
            ethnicityId,
            religionId,
            recurring
        });
    },
    getHolidayById: (id)=>{
        return axios.get(`/holiday/get/${id}`)

    },
    editHoliday:(id,name, description, day, month, year,holidayTypeId,ethnicityId,religionId,recurring) =>{
        return axios.put("/holiday/update",{
            id,
            name,
            description,
            day,
            month,
            year,
            holidayTypeId,
            ethnicityId,
            religionId,
            recurring
        });
    },
    deleteHoliday:(id)=>{
        return axios.delete(`/holiday/delete/${id}`)
    },
    getHolidayBySelectedDay: (day,month,year)=>{
        return axios.post(`/holiday/getAllFiltered/0/100`,{
                day,
                month,
                year
        })
    },
    getHolidayBySelectedMonth: (month,year)=>{
        return axios.post(`/holiday/getAllFiltered/0/100`,{
            month,
            year
        })
    },
    getHolidayBySelectedYear:(year)=>{
        return axios.post('/holiday/getHolidaysByYear',{
            year
        })
    },
    getHolidaysType: ()=>{
        return axios.get('/holidaytype/getAllHolidayTypes')
    },
    addUsersHolidays:(holidayId,userIds)=>{
        return axios.post("/userholiday/addUserHolidays",{
            holidayId,
            userIds,
        })
    },
    deleteUserForHoliday:(holidayId,userIds)=>{
        return axios.post(`/userholiday/delete`,{
            holidayId,
            userIds
        })
    }
}
export default HolidayService;