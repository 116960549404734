import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {strings} from "../../../localization/Localization";
import Form from "react-bootstrap/Form";
import ApplicationService from "../../../ApplicationService";
import ProjectTechnologies from "../../AllProjects/ProjectTechnologies/ProjectTechnologies";
import ProjectFile from "../../AllProjects/ProjectFile/ProjectFile"
import ProjectTechnologyService from "../../../repository/projectTechnologyRepository/projectTechnologyRepository";
import ProjectFileService from "../../../repository/projectFileRepository/projectFileRepository";
import swal from "sweetalert";
import DocumentService from "../../../repository/documentRepository/DocumentRepository";
import projectUserRepository from "../../../repository/projectsRepository/projectUserRepository";
import CustomTable from "../../../components/customTable/CustomTableComponent";
import FormatDate from "../../../components/formatDate/FormatDate";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const MyProjectsDetails = (props) => {
    const [projectTechnologies, setProjectTechnologies] = useState([]);
    const [projectUserList, setProjectUserList] = useState([]);
    const [projectFiles, setProjectFiles] = useState([]);

    const columns = [
        {key: 'userName', header: 'Name'},
        {key: 'userEmail', header: 'Email'},
        {key: "roles", header: "Roles", render: roles => roles.map(role => role.name).join(", ")},
        {key: "technologies", header: "Technologies", render: tech => tech.map(t => t.name).join(", ")},
        {key: "dateStarted", header: 'Date Joined', render: date => FormatDate.formatDate(date)},
        {key: "dateEnded", header: 'Date Left', render: date => FormatDate.formatDate(date)}
    ];

    useEffect(() => {
        getAllProjectTechnologies();
        getAllFiles();

        if (props.showModal) {
            getAllProjectUsers();
        } else {
            setProjectUserList([]);
        }
    }, [props.showModal])

    const getAllProjectTechnologies = () => {
        ProjectTechnologyService.getAllTechnologiesForProject(props.projectDetails.id)
            .then((response) => {
                setProjectTechnologies(response.data);
            }).catch((error) => {
            console.error("Error fetching project technologies:", error);
        })
    }

    const getAllProjectUsers = () => {
        projectUserRepository.getProjectUserByProjectId(props.projectDetails.id)
            .then((response) => {
                setProjectUserList(response.data);
            })
            .catch((error) => {
                console.error("Error fetching projects:", error);
            });
    };

    const getAllFiles = () => {
        ProjectFileService.getAllFilesForProject(props.projectDetails.id)
            .then((response) => {
                setProjectFiles(response.data);
            }).catch((error) => {
            console.error("Error fetching project files:", error);
        })
    }

    return (
        <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={"lg"} centered>
            <Modal.Header>
                <Modal.Title><b> {props.projectDetails.name}</b> - {ApplicationService.getActiveLanguage() === "mk" ?
                    props.projectDetails.typeNomenclatureEntry?.labelMk : props.projectDetails.typeNomenclatureEntry?.labelEn}
                </Modal.Title>
                <div>
                    {props.projectDetails.isActive ? (
                        <span className="badge text-bg-success ms-3">{strings.Active}</span>
                    ) : (
                        <span className="badge text-bg-danger">{strings.notActive}</span>
                    )}
                </div>
            </Modal.Header>
            <Form /* onSubmit={onFormSubmit} */ >
                <Modal.Body>
                    <div>
                        <div className={"d-flex"}>
                            <p className={"me-4"}>
                                <i>{strings.dateStarted}:</i> {FormatDate.formatDate(props.projectDetails.dateBeginning)}
                            </p>
                            <p><i>{strings.dateEnded}:</i> {FormatDate.formatDate(props.projectDetails.dateEnding)} </p>
                        </div>

                        <p>{props.projectDetails.description}</p>

                        <div className={"d-flex"}><i>{strings.technologies}:</i>
                            {projectTechnologies.map((projectTechnology) => (
                                <ProjectTechnologies technology={projectTechnology}/>
                            ))}
                        </div>

                        <div>
                            <h4>{strings.employeesOnProject}</h4>
                            <CustomTable data={projectUserList} columns={columns}/>
                        </div>

                        <h4>{strings.files}:</h4>
                        <div>
                            <ProjectFile files={projectFiles}/>
                        </div>

                    </div>
                </Modal.Body>
            </Form>
        </Modal>
    )
}
export default MyProjectsDetails;
