import axios from "../../axios/axios";

const ExportService = {
    getAbsenceByDateFile: (month, year) => {
        return axios.post("/export", {
            month,
            year
        })
    },
    getEmployeeFile:()=> {
        return axios.post("/exportUser",{})
    }
}
export default ExportService;