import React, { useEffect, useState } from "react";
import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import { strings } from "../../../../localization/Localization";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NomenclatureService from "../../../../repository/nomenclatureRepository/NomenclatureRepository";
import swal from "sweetalert";
import ApplicationService from "../../../../ApplicationService";
import InputLimit from "../../../../components/InputLimit/InputLimit";
import { useLocation } from "react-router-dom";
import benefitsProposalRepository from "../../../../repository/benefitsRepository/BenefitsProposalRepository";
import DraggableModalDialog from "../../../../components/DraggableModalDialog/DraggableModalDialog";

const NomenclatureEntryModal = (props) => {
  const [dataTypeValue, setDataTypeValue] = useState("");

  const [nameInputValue, setNameInputValue] = useState(
    props.selectedEthnicityForEdit?.name || ""
  );

  const location = useLocation();

  const benefitProposal = location.state
    ? location.state.benefitProposal
    : null;

  const equipmentEdit = location.state ? location.state.equipmentForEdit : null;
  const benefitEdit = location.state ? location.state.benefitForEdit : null;

  const [formData, updateFormData] = useState({
    name: "",
    labelMk: "",
    labelEn: "",
    labelSq: "",
    description: "",
    value: "",
    nomenclatureEntryMetadataListForAddOrEdit: [],
  });

  const convertEntryMetadata = (data) => {
    return data.map((item) => {
      return {
        [item.metadataType + "_" + item.metadataId]: item.label,
      };
    });
  };

  useEffect(() => {
    if (props.showModal) {
      if (props.selectedNoemnclatureEntryForEdit.id) {
        updateFormData({
          name: props.selectedNoemnclatureEntryForEdit?.name,
          labelMk: props.selectedNoemnclatureEntryForEdit?.labelMk,
          labelEn: props.selectedNoemnclatureEntryForEdit?.labelEn,
          labelSq: props.selectedNoemnclatureEntryForEdit?.labelSq,
          description: props.selectedNoemnclatureEntryForEdit?.description,
          value: props.selectedNoemnclatureEntryForEdit?.value,
          nomenclatureEntryMetadataListForAddOrEdit: convertEntryMetadata(
            props.selectedNoemnclatureEntryForEdit
              ?.nomenclatureEntryMetadataList
          ),
        });
      }
    } else {
      resetFormData();
    }
  }, [props.showModal]);

  useEffect(() => {
    if (benefitProposal != null) {
      if (benefitProposal.benefitProposalId != null) {
        updateFormData({
          name: "",
          labelMk: benefitProposal.name.toString(),
          labelEn: "",
          labelSq: "",
          description: benefitProposal.description.toString(),
          value: benefitProposal.name.toString(),
          nomenclatureEntryMetadataListForAddOrEdit: [],
        });
      }
    }
  }, [benefitProposal]);

  useEffect(() => {
    if (equipmentEdit != null) {
      if (equipmentEdit.id != null) {
        updateFormData({
          name: equipmentEdit.name,
          labelMk: equipmentEdit.labelMk,
          labelEn: equipmentEdit.labelEn,
          labelSq: equipmentEdit.labelSq,
          description: equipmentEdit.description,
          value: equipmentEdit.name.toString(),
          nomenclatureEntryMetadataListForAddOrEdit: [],
        });
      }
    }
  }, [equipmentEdit]);

  useEffect(() => {
    if (benefitEdit != null) {
      if (benefitEdit.id != null) {
        
        updateFormData({
          name: benefitEdit.name,
          labelMk: benefitEdit.labelMk,
          labelEn: benefitEdit.labelEn,
          labelSq: benefitEdit.labelSq,
          description: benefitEdit.description,
          value: benefitEdit.name.toString(),
          nomenclatureEntryMetadataListForAddOrEdit: [],
        });
      }
    }
  }, [benefitEdit]);

  const resetFormData = () => {
    updateFormData({
      name: "",
      labelMk: "",
      labelEn: "",
      labelSq: "",
      description: "",
      value: "",
      nomenclatureEntryMetadataListForAddOrEdit: [],
    });
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const errorAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (e.target.hasAttribute("required") && value.trim() === "") {
      e.target.setCustomValidity(strings.fieldsRequired);
    } else {
      e.target.setCustomValidity("");
    }

    if (name.startsWith("metadata_")) {
      const [prefix, dynamicName, suffix] = name.split("_");
      const fullName = dynamicName + "_" + suffix;
      const newValue = type === "checkbox" ? checked : value;
      const existingIndex =
        formData.nomenclatureEntryMetadataListForAddOrEdit.findIndex((item) =>
          item.hasOwnProperty(fullName)
        );

      const updatedMetaDataList =
        existingIndex !== -1
          ? formData.nomenclatureEntryMetadataListForAddOrEdit.map((item) => {
              if (item.hasOwnProperty(fullName)) {
                return { ...item, [fullName]: newValue };
              } else {
                return item;
              }
            })
          : [
              ...formData.nomenclatureEntryMetadataListForAddOrEdit,
              { [fullName]: newValue },
            ];

      updateFormData({
        ...formData,
        nomenclatureEntryMetadataListForAddOrEdit: updatedMetaDataList,
      });
    } else {
      if (name === "name") {
        const pattern = /^[A-Z0-9!@#$%^&*()_+{}\[\]:;"'<>,.?~]+$/;
        if (value === "" || pattern.test(value)) {
          updateFormData({
            ...formData,
            [name]: value,
          });
        }
      } else {
        updateFormData({
          ...formData,
          [name]: type === "checkbox" ? checked : value,
        });
      }
    }
  };

  const addNomenclatureEntry = () => {
    NomenclatureService.addNomenclatureEntry(
      formData.name,
      formData.labelMk,
      formData.labelEn,
      formData.labelSq,
      formData.description,
      formData.value,
      props.selectedNomenclature.id,
      formData.nomenclatureEntryMetadataListForAddOrEdit
    )
      .then((data) => {
        props.handleClose();
        successfulAlert();
        props.getNomenclatureEntryData(props.selectedNomenclature.id);
      })
      .catch(() => {
        errorAlert();
      });
  };

  const editNomenclatureEntry = () => {
    NomenclatureService.editNomenclatureEntry(
      formData.name,
      formData.labelMk,
      formData.labelEn,
      formData.labelSq,
      formData.description,
      formData.value,
      props.selectedNoemnclatureEntryForEdit.id,
      formData.nomenclatureEntryMetadataListForAddOrEdit
    )
      .then((data) => {
        props.handleClose();
        successfulAlert();
        props.getNomenclatureEntryData(props.selectedNomenclature.id);
      })
      .catch(() => {
        errorAlert();
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (props.selectedNoemnclatureEntryForEdit.id) {
      editNomenclatureEntry();
    } else {
      addNomenclatureEntry();
      if (benefitProposal.benefitProposalId != null) {
        benefitsProposalRepository
          .acceptBenefitProposal(benefitProposal.benefitProposalId)
          .then();
      }
    }
  };

  const checkDataType = (dataType) => {
    if (dataType === "boolean") {
      return "checkbox";
    } else {
      return dataType;
    }
  };

  return (
    <>
      <Modal
        show={props.showModal}
        dialogAs={DraggableModalDialog}
        onHide={props.handleClose}
        size={"lg"}
        centered
      >
        <ModalHeader closeButton>
          <ModalTitle>
            <b>
              {" "}
              {props.selectedNoemnclatureEntryForEdit.id
                ? strings.editNomenclatureEntry
                : strings.addNomenclatureEntry}{" "}
            </b>
          </ModalTitle>
        </ModalHeader>
        <Form onSubmit={onFormSubmit}>
          <ModalBody>
            <Form.Group className="mb-3" controlId="hierarchyName">
              <Form.Group>
                <Form.Label>
                  {strings.code}
                  <span className="text-danger">*</span>
                </Form.Label>
                {InputLimit.codeLimitMessage()}
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  autoFocus
                  value={formData?.name}
                  name="name"
                  required
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
              <h5 className={"my-3"}>{strings.marks}</h5>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {strings.labelMk}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={handleChange}
                      value={formData?.labelMk}
                      required
                      name="labelMk"
                      onInvalid={(e) => {
                        e.target.setCustomValidity(strings.fieldsRequired);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{strings.labelSq}</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={handleChange}
                      value={formData?.labelSq}
                      name="labelSq"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{strings.labelEn}</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData?.labelEn}
                      onChange={handleChange}
                      requred
                      name="labelEn"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label>{strings.value}</Form.Label>
                <Form.Control
                  type="text"
                  value={formData?.value}
                  onChange={handleChange}
                  name="value"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{strings.description}</Form.Label>
                <textarea
                  name="description"
                  className="form-control"
                  onChange={handleChange}
                  value={formData?.description}
                  id="exampleFormControlTextarea1"
                  rows="2"
                />
              </Form.Group>
              <Row>
                {props.selectedNomenclature?.metaDataList.length !== 0 &&
                  props.selectedNomenclature?.metaDataList.map(
                    (element, index) => {
                      const keyToFind = `${element.dataType}_${element.id}`;
                      let foundValue = "";

                      for (const obj of formData.nomenclatureEntryMetadataListForAddOrEdit) {
                        if (keyToFind in obj) {
                          foundValue = obj[keyToFind];
                          break;
                        }
                      }

                      return (
                        <Col xs={12} md={6} key={element.id}>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              {ApplicationService.getActiveLanguage() === "mk"
                                ? element.labelMk
                                : element.labelEn}
                            </Form.Label>
                            {element.dataType === "boolean" ? (
                              <label className="switchEnabled me-2 mb-0">
                                <Form.Control
                                  type={checkDataType(element.dataType)}
                                  name={`metadata_${element.dataType}_${element.id}`}
                                  value={foundValue}
                                  checked={foundValue}
                                  onChange={(e) => handleChange(e)}
                                />
                                <span className="sliderEnabled round"> </span>
                              </label>
                            ) : (
                              <Form.Control
                                type={checkDataType(element.dataType)}
                                name={`metadata_${element.dataType}_${element.id}`}
                                value={foundValue}
                                checked={
                                  element.dataType === "boolean"
                                    ? foundValue
                                    : undefined
                                }
                                onChange={(e) => handleChange(e)}
                              />
                            )}
                          </Form.Group>
                        </Col>
                      );
                    }
                  )}
              </Row>
            </Form.Group>
          </ModalBody>
          <Modal.Footer>
            <div
              className={"cancel btn text-white"}
              onClick={props.handleClose}
            >
              {strings.cancel}
            </div>
            <button type={"submit"} className={"save btn text-white"}>
              {strings.save}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default NomenclatureEntryModal;
