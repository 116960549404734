import React, {useEffect} from "react";
import "./UserListInModalStyle.css"
import {strings} from "../localization/Localization";
import femaleAvatar from "../assets/images/female_avatar.png"

const UserListInModal = (props) => {


    return (
        <>
            {props.users.length > 0 ?
                (
                    props.users.map((user) => {
                        return (
                            <>
                                <div className="card b-1 hover-shadow mb-20" key={user.id}>
                                    <div className="media card-body">
                                        <div className="media-left col-2 pr-12 h-100">


                                            <img className=" avatar-xl image_border_radius mt-2 ml-2 my-auto d-flex"
                                                 src={user.profileImageHelper ? (`data:image/*;base64,${user.profileImageHelper.bytes}`) :
                                                     (user.person?.gender === "MALE" ? "https://bootdey.com/img/Content/avatar/avatar7.png" : femaleAvatar)}
                                                 alt="..."/>
                                        </div>
                                        <div className="media-body col-4">
                                            <div className="mb-2">
                                                <span className="fs-20 pr-16">{user.firstName} {user.lastName}</span>
                                            </div>

                                            <div className="mb-2">
                                                <small className="fs-16 fw-300 ls-1">{user.position?.name} </small>
                                            </div>
                                        </div>
                                        <div className="media-right  d-none d-md-block col-5 ">
                                            <div className="text-fade d-flex text-wrapper-word-break">
                                                <div className={"align-items-center mx-1"}>
                                                    <i className="material-icons pr-1 ">
                                                        email
                                                    </i>
                                                </div>
                                                <div>
                                                    {user.email != "" ? user.email : <i>{strings.noInfo}</i>}
                                                </div>
                                            </div>
                                            <div className="text-fade d-flex">
                                                <div className={"align-items-center mx-1"}>
                                                    <i className="material-icons pr-1 ">
                                                        location_on
                                                    </i>
                                                </div>
                                                <div>
                                                    {(user.address?.name != "" && user.address?.name !== null) ? user.address?.name :
                                                        <i>{strings.noInfo}</i>}
                                                </div>
                                            </div>
                                            <div className="text-fade d-flex">
                                                <div className={"align-items-center mx-1"}>
                                                    <i className="material-icons pr-1 ">
                                                        phone_in_talk
                                                    </i>
                                                </div>
                                                <div>
                                                    {(user.phoneNumber != null && user.phoneNumber != "") ? user.phoneNumber :
                                                        <i>{strings.noInfo}</i>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })
                )
                :
                (
                    <h5 className={'text-center text-secondary'}>
                        <i>  {strings.noAbsenceForSelectedDate} </i></h5>
                )
            }
        </>
    )
}
export default UserListInModal;