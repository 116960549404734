import React, {useEffect, useState} from "react"
import {strings} from "../../../localization/Localization";
import {HiDownload, HiOutlineDocumentText} from "react-icons/hi";
import DocumentService from "../../../repository/documentRepository/DocumentRepository";
import swal from "sweetalert";
import {useParams} from "react-router-dom";
import {FaPassport, FaRegIdCard} from "react-icons/fa";
import AddButton from "../../../components/addButton/AddButton";
import AddPersonalDocumentModal from "./AddPersonalDocumentModal/AddPersonalDocumentModal";
import FormatDate from "../../../components/formatDate/FormatDate";


const PersonalDocuments = (props) => {

    const [allDocuments, setAllDocuments] = useState([]);

    const [showAddModal, setShowAddModal] = useState(false);

    const [selectedDocumentForEdit, setSelectedDocumentForEdit] = useState({});

    const param = useParams();

    const getDocumentType = (type) => {
        if (type === 'IDENTITY_CARD') {
            return strings.identityCard;
        } else if (type === 'PASSPORT') {
            return strings.passport;
        }
        return null;
    };

    useEffect(() => {
        if (props.currentTab === "personalDocuments") {
            getAllPersonalDocuments();
        }
    }, [props.currentTab]);

    const getAllPersonalDocuments = () => {
        if (param.id) {
            DocumentService.getAllPersonalDocumentByUserId(param.id)
                .then((data) => {
                    setAllDocuments(data.data)
                })
        } else {
            DocumentService.getAllPersonalDocumentForLoggedUser()
                .then((data) => {
                    setAllDocuments(data.data)
                })
        }
    };

    const handleCloseAddDocumentModal = () => {
        setSelectedDocumentForEdit({});
        setShowAddModal(false);
    };

    const handleShowAddDocumentModal = () => {
        setShowAddModal(true);
    };

    const ConfirmationDelete = (id) => {
        swal(strings.deleteConfirmation, {
            title: strings.areYouSure,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteDocument(id);
            }
        });
    };

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const warningAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const deleteDocument = (id) => {
        DocumentService.deletePersonalDocument(id)
            .then(() => {
                successfulAlert();
                getAllPersonalDocuments();
            }).catch(() => {
            warningAlert();
        });
    };



    return (
        <div className={"p-4"}>
            <AddPersonalDocumentModal showModal={showAddModal} handleClose={handleCloseAddDocumentModal}
                                      getDocumentData={getAllPersonalDocuments}
                                      selectedDocumentForEdit={selectedDocumentForEdit}/>
            <div className={"d-flex mb-2 justify-content-end"}>
                <AddButton onClickHandle={handleShowAddDocumentModal}/>
            </div>
            <div className="table-responsive">
                {allDocuments.length > 0 ? (
                        <table className="table user-table table-responsive table-borderless text-start">
                            <thead className={"custom-table-header text-white"}>
                            <tr className={"user-table-header-row"}>
                                <th scope="col">{strings.documentNumber}</th>
                                <th scope="col">{strings.issuedBy}</th>
                                <th scope="col">{strings.dateOfIssue}</th>
                                <th>{strings.dateOfExpiry}</th>
                                <th scope="col">{strings.documentType}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>


                            {allDocuments.map((data) => {
                                return (
                                    <tr className={"user-table-body-row"} key={data.id}>

                                        <td>
                                            {
                                                data?.personalDocumentType == "Pasosh" && <FaPassport
                                                    className={"user_personal_data__document_icon me-3 text-blue "}/>
                                            }
                                            {
                                                data?.personalDocumentType == "LicnaKarta" && <FaRegIdCard
                                                    className={"user_personal_data__document_icon me-3 text-blue "}/>
                                            }
                                            {data?.documentNumber}
                                        </td>
                                        <td>{data?.issuedBy}</td>
                                        <td>{FormatDate.formatDate(data?.dateFrom.split("T")[0])}</td>
                                        <td>{FormatDate.formatDate(data?.dateTo.split("T")[0])}</td>
                                        <td>{getDocumentType(data?.personalDocumentType)}</td>
                                        <td>
                                            <div className={"d-flex justify-content-end"}>
                                                <div className={"d-flex ms-auto align-items-center"}>
                                                    <div className={'icon-div mx-2'}>
                                                        <div
                                                            onClick={() => {
                                                                setSelectedDocumentForEdit(data);
                                                                handleShowAddDocumentModal();
                                                            }}
                                                            title={'Edit'}
                                                            className={'text-dark icon'}>
                                                            <i className={"edit-icon-circle material-icons "}>
                                                                create
                                                            </i>
                                                        </div>
                                                    </div>
                                                    <div className={'icon-div cursor-pointer me-2'}>
                                                        <a title={'Delete'}>
                                                            <div
                                                                onClick={() => ConfirmationDelete(data?.id)}
                                                                title={'Delete'}
                                                                className={'text-dark '}>
                                                                <i className={"material-icons delete-icon-circle"}
                                                                   style={{fontSize: "23px"}}>delete</i>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    ) :
                    (
                        <div className={"justify-content-center text-center "}>
                                <h5 className={"text-secondary"}><i>{strings.emptyList}</i></h5>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
export default PersonalDocuments;
