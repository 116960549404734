import axios from "../../axios/axios";

const OrganizationalUnitRepository = {
    getAllOrganisation:()=>{
        return axios.get("/organisation/getAll");
    },
    fetchOrganizationUnit: (page, size) => {
        return axios.get(`/organizationalunit/getAllOrganizationalUnits/${page}/${size}`);
    },
    addOrganizationalUnit: (name, labelMk, labelSq, labelEn) => {
        return axios.post("/organizationalunit/add", {
            name,
            labelMk,
            labelSq,
            labelEn
        })
    },
    deleteOrganizationalUnit: (id) => {
        return axios.delete(`/organizationalunit/delete/${id}`);
    },
    editOrganizationalUnit: (id, name, labelEn, labelMk, labelSq) => {
        return axios.put(`/organizationalunit/update`, {
            id,
            name,
            labelMk,
            labelSq,
            labelEn
        })
    },
    getOrganizationalUnitById: (id) => {
        return axios.get(`/organizationalunit/get/${id}`)
    }
}
export default OrganizationalUnitRepository;