import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import {
  FaRegCalendarAlt,
  FaList,
  FaFlag,
  FaUserCircle,
  FaClipboardList,
  FaCalendarCheck,
  FaBook,
  FaUserTag,
  FaHistory,
  FaHome,
  FaSitemap,
  FaFileExport,
  FaBookReader,
  FaLayerGroup,
  FaRegListAlt,
  FaEnvelopeOpenText,
} from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import {
  BsBookmarkCheckFill,
  BsCalendarEventFill,
  BsFillPersonFill,
  BsSpeedometer,
  BsClockHistory,
  BsGraphUp,
  BsGearWide,
  BsInfoLg,
  BsBagDashFill,
} from "react-icons/bs";
import { GiBookPile, GiChurch, GiFiles } from "react-icons/gi";
import {
  MdAccountTree,
  MdOutlineFormatListBulleted,
  MdOutlinePersonAddAlt,
} from "react-icons/md";
import React, { useEffect, useState } from "react";
import { RiAdminFill, RiGroup2Fill } from "react-icons/ri";
import { strings } from "../localization/Localization";
import "./Layout/MainLayout.css";
import { Link } from "react-router-dom";
import ApplicationService from "../ApplicationService";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);

  const [activePage, setActivePage] = useState(null);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [hovered, setHovered] = useState(false);

  function getWindowSize() {
    const { innerWidth } = window;
    return { innerWidth };
  }

  function handleActive(event) {
    if (!event.target.classList.value.includes("active")) {
      event.target.classList.toggle("active");
      if (activePage) {
        activePage.classList.remove("active");
      }
      setActivePage(event.target);
    }
    ApplicationService.clearAllInLocalStorageExceptTokenAndLanguageAndIsOpen();
  }

  useEffect(() => {
    const data = window.localStorage.getItem("isOpen");
    if (data === null) {
      if (windowSize.innerWidth < 700) {
        window.localStorage.setItem("isOpen", false);
        setIsOpen(true);
      } else {
        window.localStorage.setItem("isOpen", true);
        setIsOpen(false);
      }
    } else {
      setIsOpen(data === true ? true : false);
    }
  }, []);

  function OnSideBarOpen() {
    setIsOpen(!isOpen);
    window.localStorage.removeItem("isOpen");
    window.localStorage.setItem("isOpen", JSON.stringify(isOpen));
  }

  const scrollClass = hovered ? "" : "hide-scroll";

  const handleHover = () => setHovered((prevState) => !prevState);

  const isActive = (url) => {
    const location = window.location.pathname;
    const active = location === url;
    return active;
  };

  return (
    <>
      <div
        className={`d-flex flex-row  ${scrollClass}`}
        onMouseOver={handleHover}
        onMouseOut={handleHover}
      >
        <div>
          <ProSidebar
            name="sidebar"
            collapsed={isOpen}
            collapsedWidth={windowSize.innerWidth > 990 ? "10vh" : "8vh"}
          >
            <Menu iconShape="circle">
              <SidebarHeader>
                <MenuItem
                  icon={<FaList />}
                  onClick={OnSideBarOpen}
                  active={false}>
                  <i>{strings.minimizeThisMenu}</i>
                </MenuItem>
              </SidebarHeader>
              <MenuItem icon={<FaHome />} active={isActive("/home")}>
                <Link onClick={handleActive} to="/home">
                  {" "}
                  {strings.home}
                </Link>
              </MenuItem>
              <MenuItem
                icon={<BsFillPersonFill />}
                active={isActive("/userDetails")}>
                <Link onClick={handleActive} to="/userDetails">
                  {" "}
                  {strings.profile}
                </Link>
              </MenuItem>
              {ApplicationService.hasRole(["ROLE_ADMINISTRATOR"]) && (
                <SubMenu
                  icon={<FaSitemap />}
                  title={strings.organizationalStructure}
                  id={"organizationalstrucuters"}
                  active={isActive("/organizationalstrucuters")}>
                  <MenuItem
                    icon={<FaSitemap />}
                    id={"organisationList"}
                    active={isActive("/organisationList")}>
                    <Link onClick={handleActive} to="/organisationList">
                      {strings.organisationList}
                    </Link>
                  </MenuItem>
                  <MenuItem
                    icon={<FaSitemap />}
                    id={"organisation"}
                    active={isActive("/organisation")}>
                    <Link onClick={handleActive} to="/organisation">
                      {strings.organisationHierarchy}
                    </Link>
                  </MenuItem>
                  <MenuItem
                    icon={<FaSitemap />}
                    id={"position"}
                    active={isActive("/position")}>
                    <Link onClick={handleActive} to="/position">
                      {strings.positionsHierarchy}
                    </Link>
                  </MenuItem>
                </SubMenu>
              )}
              {ApplicationService.hasRole(["ROLE_ADMINISTRATOR"]) && (
                <SubMenu
                  icon={<MdOutlinePersonAddAlt />}
                  title={strings.personal}
                  id={"organizationalstrucuters"}
                  active={isActive("/organizationalstrucuters")}>
                  <MenuItem
                    icon={<GiChurch />}
                    id={"religion"}
                    active={isActive("/religion")}>
                    <Link onClick={handleActive} to="/religion">
                      {strings.religionList}
                    </Link>
                  </MenuItem>
                  <MenuItem
                    icon={<FaFlag />}
                    id={"ethnicity"}
                    active={isActive("/ethnicity")}>
                    <Link onClick={handleActive} to="/ethnicity">
                      {strings.ethnicityGroup}
                    </Link>
                  </MenuItem>
                  {ApplicationService.hasRole([
                    "ROLE_ADMINISTRATOR",
                    "ROLE_HR",
                  ]) && (
                    <MenuItem
                      icon={<FaEnvelopeOpenText />}
                      id={"feedback"}
                      active={isActive("/feedback")}>
                      <Link onClick={handleActive} to="/feedback">
                        {strings.feedback}
                      </Link>
                    </MenuItem>
                  )}
                  {ApplicationService.hasRole([
                    "ROLE_ADMINISTRATOR",
                    "ROLE_HR",
                  ]) && (
                    <MenuItem
                      icon={<FaUserCircle />}
                      id={"user"}
                      active={isActive("/user")}>
                      <Link onClick={handleActive} to="/user">
                        {strings.listOfUsers}
                      </Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              <SubMenu
                active={true}
                title={strings.equipment}
                icon={<BsBagDashFill />}>
                {ApplicationService.hasRole([
                  "ROLE_ADMINISTRATOR",
                  "ROLE_HR",
                ]) && (
                  <MenuItem
                    icon={<BsInfoLg />}
                    id={"allEquipment"}
                    active={isActive("/allEquipment")}>
                    <Link onClick={handleActive} to="/allEquipment">
                      {strings.equipment}
                    </Link>
                  </MenuItem>
                )}
                <MenuItem
                  icon={<BsGearWide />}
                  active={isActive("/myEquipment")}>
                  <Link onClick={handleActive} to="/myEquipment">
                    {" "}
                    {strings.myEquipment}
                  </Link>
                </MenuItem>
              </SubMenu>
              <SubMenu title={strings.absence} icon={<FaClipboardList />}>
                {ApplicationService.hasRole([
                  "ROLE_ADMINISTRATOR",
                  "ROLE_HR",
                ]) && (
                  <MenuItem
                    icon={<MdOutlineFormatListBulleted />}
                    id={"absenceType"}
                    active={isActive("/absenceType")}>
                    <Link onClick={handleActive} to="/absenceType">
                      {strings.typesOfAbsences}
                    </Link>
                  </MenuItem>
                )}
                {ApplicationService.hasRole([
                  "ROLE_ADMINISTRATOR",
                  "ROLE_HR",
                ]) && (
                  <MenuItem
                    icon={<FaRegCalendarAlt />}
                    id={"holidays"}
                    active={isActive("/holidays")}>
                    <Link onClick={handleActive} to="/holidays">
                      {" "}
                      {strings.holidays}
                    </Link>
                  </MenuItem>
                )}
                {ApplicationService.hasRole([
                  "ROLE_ADMINISTRATOR",
                  "ROLE_HR",
                ]) && (
                  <MenuItem
                    icon={<FaCalendarCheck />}
                    id={"status"}
                    active={isActive("/status")}>
                    <Link onClick={handleActive} to="/status">
                      {strings.absenceCalendar}
                    </Link>
                  </MenuItem>
                )}
                <MenuItem
                  icon={<FaUserTag />}
                  id={"employeelist"}
                  active={isActive("/employeelist")}>
                  <Link onClick={handleActive} to="/employeelist">
                    {strings.userAbsenceEvidention}
                  </Link>
                </MenuItem>
                <MenuItem
                  icon={<FaFileExport />}
                  id={"leaveRequests"}
                  active={isActive("/leaveRequests")}>
                  <Link onClick={handleActive} to="/leaveRequests">
                    {" "}
                    {strings.myRequest}
                  </Link>
                </MenuItem>
                <MenuItem
                  icon={<BsBookmarkCheckFill />}
                  id={"absence"}
                  active={isActive("/absence")}>
                  <Link onClick={handleActive} to="/absence">
                    {" "}
                    {strings.allowedAbsence}
                  </Link>
                </MenuItem>
                {ApplicationService.hasRole([
                  "ROLE_ADMINISTRATOR",
                  "ROLE_HR",
                ]) && (
                  <MenuItem
                    icon={<GiFiles />}
                    id={"arrivedRequest"}
                    active={isActive("/arrivedRequest")}>
                    <Link onClick={handleActive} to="/arrivedRequest">
                      {" "}
                      {strings.deliverRequest}
                    </Link>
                  </MenuItem>
                )}
              </SubMenu>
              <MenuItem
                icon={<BsCalendarEventFill />}
                active={isActive("/events")}>
                <Link onClick={handleActive} to="/events">
                  {strings.events}
                </Link>
              </MenuItem>
              <SubMenu
                active={true}
                title={strings.benefits}
                icon={<FaRegListAlt />}>
                {ApplicationService.hasRole([
                  "ROLE_ADMINISTRATOR",
                  "ROLE_HR",
                ]) && (
                  <MenuItem
                    icon={<BsInfoLg />}
                    id={"/benefits"}
                    active={isActive("/benefits")}>
                    <Link onClick={handleActive} to="/benefits">
                      {strings.benefits}
                    </Link>
                  </MenuItem>
                )}
                <MenuItem
                  icon={<BsGearWide />}
                  active={isActive("/myBenefits")}>
                  <Link onClick={handleActive} to="/myBenefits">
                    {" "}
                    {strings.myBenefits}
                  </Link>
                </MenuItem>
              </SubMenu>
              <SubMenu
                active={true}
                title={strings.projects}
                icon={<FaLayerGroup />}>
                {ApplicationService.hasRole([
                  "ROLE_ADMINISTRATOR",
                  "ROLE_HR",
                ]) && (
                  <MenuItem
                    icon={<RiGroup2Fill />}
                    id={"group"}
                    active={isActive("/group")}>
                    <Link onClick={handleActive} to="/group">
                      {strings.projectGroup}
                    </Link>
                  </MenuItem>
                )}
                {ApplicationService.hasRole([
                  "ROLE_ADMINISTRATOR",
                  "ROLE_HR",
                ]) && (
                  <MenuItem
                    icon={<GiBookPile />}
                    id={"projects"}
                    active={isActive("/projects")}>
                    <Link onClick={handleActive} to="/projects">
                      {strings.projects}
                    </Link>
                  </MenuItem>
                )}
                <MenuItem
                  icon={<FaBookReader />}
                  active={isActive("/myProjects")}>
                  <Link onClick={handleActive} to="/myProjects">
                    {" "}
                    {strings.myProjects}
                  </Link>
                </MenuItem>
              </SubMenu>
              <SubMenu
                active={true}
                title={strings.timeTracking}
                icon={<BsClockHistory />}>
                {ApplicationService.hasRole([
                  "ROLE_ADMINISTRATOR",
                  "ROLE_HR",
                ]) && (
                  <MenuItem
                    icon={<BsGraphUp />}
                    id={"allTrackings"}
                    active={isActive("/allTrackings")}>
                    <Link onClick={handleActive} to="/allTrackings">
                      {strings.timeTrackings}
                    </Link>
                  </MenuItem>
                )}
                <MenuItem
                  icon={<BsSpeedometer />}
                  active={isActive("/myTimeTracking")}>
                  <Link onClick={handleActive} to="/myTimeTracking">
                    {" "}
                    {strings.myTimeTracking}
                  </Link>
                </MenuItem>
              </SubMenu>
              {ApplicationService.hasRole([
                "ROLE_ADMINISTRATOR",
                "ROLE_HR",
              ]) && (
                <SubMenu
                  active={true}
                  title={strings.administration}
                  icon={<RiAdminFill />}>
                  <MenuItem
                    icon={<MdAccountTree />}
                    id={"nomenclature"}
                    active={isActive("/nomenclature")}>
                    <Link onClick={handleActive} to="/nomenclature">
                      {strings.nomenclature}
                    </Link>
                  </MenuItem>
                  {ApplicationService.hasRole(["ROLE_ADMINISTRATOR"]) && (
                    <MenuItem
                      icon={<FiSettings />}
                      id={"settings"}
                      active={isActive("/settings")}>
                      <Link onClick={handleActive} to="/settings">
                        {strings.settings}
                      </Link>
                    </MenuItem>
                  )}
                  {ApplicationService.hasRole(["ROLE_ADMINISTRATOR"]) && (
                    <MenuItem
                      icon={<FaHistory />}
                      id={"calendarHistory"}
                      active={isActive("/calendarHistory")}>
                      <Link onClick={handleActive} to="/calendarHistory">
                        {strings.calendarHistory}
                      </Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
            </Menu>
          </ProSidebar>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
