
const StyleForCalendarEvents = {

    getStyleForType: (type) => {
        var backgroundColor = '';
        if (type === "NATIONAL") {// zolta violetova siva /sina
            backgroundColor="#FFCCB6"
        } else if (type === "RELIGIOUS") {
            backgroundColor="#CBAACD"
        } else if (type === "ETHNIC") {
            backgroundColor="#CEE9BE"
        } else {
            backgroundColor="#8FCACA"
        }

        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '10px',
            opacity: 1,
            color: 'black',
            border: '0px',
            display: 'block'
        };
        return style
    }
}
export default StyleForCalendarEvents


