import axios from "../../axios/axios";

const EventGroupService =  {

    getEventGroupById: (eventGroupId) => {
        return axios.get(`/eventGroup/get/${eventGroupId}`);
    },

    getAllEventGroups: () => {
        return axios.get("/eventGroup/getAllEventGroups")
    },

    getAllGroupsForEvent: (eventId) => {
        return axios.get(`/eventGroup/getAllEventGroupsForEvent/${eventId}`)
    },

    deleteEventGroup: (eventId) => {
        return axios.delete(`/eventGroup/delete/${eventId}`);
    },

    addEventGroup: (eventId, startDate, endDate, capacity, status, trainer, location) => {
        return axios.post("/eventGroup/add", {
            eventId,
            startDate,
            endDate,
            capacity,
            status,
            trainer,
            location
        })
    },

    editEventGroup: (eventGroupId, eventId, startDate, endDate, capacity, status, trainer, location) => {
        return axios.put("/eventGroup/update", {
            eventGroupId,
            eventId,
            startDate,
            endDate,
            capacity,
            status,
            trainer,
            location
        })
    }

}

export default EventGroupService;