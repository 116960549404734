import Modal from "react-bootstrap/Modal";
import {strings} from "../../../../localization/Localization";
import React, {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import NomenclatureService from "../../../../repository/nomenclatureRepository/NomenclatureRepository";
import ApplicationService from "../../../../ApplicationService";
import EventsService from "../../../../repository/eventsRepository/EventsRepository";
import swal from "sweetalert";
import DraggableModalDialog from "../../../../components/DraggableModalDialog/DraggableModalDialog";
import {useHistory} from "react-router-dom";

const AcceptEventProposalModal = (props) => {

    const history = useHistory();

    const [nomenclatureEventsList, setNomenclatureEventsList] = useState([]);

    const [statusList, setStatusList] = useState([
        {value: "ACTIVE", label: strings.activeEvent},
        {value: "INACTIVE", label: strings.inactiveEvent}
    ]);

    const [currencies, setCurrencies] = useState([
        {value: "DOLLARS", label: strings.dollars},
        {value: "EUROS", label: strings.euros},
        {value: "DENARS", label: strings.denars},
        {value: "POUNDS", label: strings.pounds}
    ]);

    const [selectedCurrency, setSelectedCurrency] = useState(null);

    const [selectedStatus, setSelectedStatus] = useState(null);

    const [formData, updateFormData] = useState({
        name: "",
        description: "",
        eventType: undefined,
        status: "",
        dateCreated: "",
        dateModified: "",
        certificate: "",
        organisation: "",
        cost: "",
        currency: "",
        trainer: "",
    });

    useEffect(() => {
        if (!props.showModal) {
            resetData();
        }
        if (props.eventProposalForAccepting) {
            if (props.eventProposalForAccepting?.eventProposalId) {
                updateFormData({
                    name: props.eventProposalForAccepting?.name,
                    description: props.eventProposalForAccepting?.description
                })
            }
        }
    }, [props.eventProposalForAccepting, props.showModal])

    useEffect(() => {
        if (nomenclatureEventsList.length === 0) {
            getAllNomenclatureEntriesForEvents();
        }
    }, [formData])

    const resetData = () => {
        updateFormData({
            eventType: undefined,
            status: "",
            dateCreated: "",
            dateModified: "",
            certificate: "",
            organisation: "",
            cost: "",
            currency: "",
            trainer: "",
        })
    };

    const getAllNomenclatureEntriesForEvents = () => {
        NomenclatureService.getAllNomenclatureEntryByNomenclatureName("Обуки")
            .then((data) => {
                let nomenclatureConvert = data.data.map((n) => {
                    return {
                        value: n.id,
                        label: ApplicationService.getActiveLanguage() == "mk" ? n.labelMk : n.labelEn
                    }
                })
                setNomenclatureEventsList(nomenclatureConvert);
            });
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        if (e.target.hasAttribute("required") && value.trim() === "") {
            e.target.setCustomValidity(strings.fieldsRequired);
          } else {
            e.target.setCustomValidity("");
          }
        updateFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCurrencyChange = (selectedOption) => {
        setSelectedCurrency(selectedOption);
        updateFormData({
            ...formData,
            currency: selectedOption?.value,
        });
    };

    const handleStatusChange = (selectedOption) => {
        setSelectedStatus(selectedOption);
        updateFormData({
            ...formData,
            status: selectedOption?.value,
        });
    }

    const handleEventsChange = (selectedOption) => {
        updateFormData({
            ...formData,
            eventType: selectedOption
        });
    };

    const proposalAcceptedSuccessfully = () => {
        swal(strings.userEditSuccessful, {
            icon: "success",
        });
    };

    const errorAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const acceptEventFromProposal = (name, description, eventTypeId, status, certificate, organisation, cost, currency, trainer) => {
        EventsService.acceptEventFromProposal(props.eventProposalForAccepting.eventProposalId, name, description, eventTypeId, status, certificate, organisation, cost, currency, trainer)
            .then(() => {
                proposalAcceptedSuccessfully();
                history.push('/events')
            }).catch(() => {
            errorAlert()
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const name = formData.name !== "" ? formData.name : null;
        const eventTypeId = formData.eventType !== "" ? formData.eventType.value : null;
        const description = formData.description !== "" ? formData.description : null;
        const status = formData.status !== "" ? formData.status : null;
        const organisation = formData.organisation !== "" ? formData.organisation : null;
        const certificate = formData.certificate !== "" ? formData.certificate : null;
        const cost = formData.cost !== "" ? formData.cost : null;
        const currency = formData.currency !== "" ? formData.currency : null;
        const trainer = formData.trainer !== "" ? formData.trainer : null;

        acceptEventFromProposal(name, description, eventTypeId, status, certificate, organisation, cost, currency, trainer)

    }

    return (
        <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={"lg"} centered>
            <Modal.Header closeButton>Accept Event Proposal</Modal.Header>
            <Form onSubmit={onFormSubmit}>
                <Modal.Body>
                    <Row className={"mb-2"}>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.name}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type={"text"}
                                    value={formData.name}
                                    onChange={handleChange}
                                    name={"name"}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.description}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    as="textarea"
                                    rows={4}
                                    value={formData.description}
                                    onChange={handleChange}
                                    name={"description"}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.organisation}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type={"text"}
                                    value={formData.organisation}
                                    onChange={handleChange}
                                    name={"organisation"}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.trainer}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type={"text"}
                                    value={formData.trainer}
                                    onChange={handleChange}
                                    name={"trainer"}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.certificate}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type={"text"}
                                    value={formData.certificate}
                                    onChange={handleChange}
                                    name={"certificate"}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.cost}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type={"number"}
                                    value={formData.cost}
                                    onChange={handleChange}
                                    name={"cost"}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.currency}<span className="text-danger">*</span>
                                </Form.Label>
                                <Select
                                    required
                                    options={currencies}
                                    className={`basic-multi-select user_details_select_input`}
                                    classNamePrefix="select"
                                    name={"currency"}
                                    value={selectedCurrency}
                                    placeholder={strings.selectOption}
                                    noOptionsMessage={()=>strings.noOptions}
                                    onChange={handleCurrencyChange}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-3"}>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.status}<span className="text-danger">*</span>
                                </Form.Label>
                                <Select
                                    required
                                    options={statusList}
                                    className={`basic-multi-select user_details_select_input`}
                                    classNamePrefix="select"
                                    name={"status"}
                                    value={statusList[1]}
                                    isDisabled={true}
                                    onChange={handleStatusChange}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.eventType}
                                </Form.Label>
                                <Select
                                    name="nomenclatureEventType"
                                    options={nomenclatureEventsList}
                                    className={`basic-multi-select user_details_select_input`}
                                    classNamePrefix="select"
                                    onChange={handleEventsChange}
                                    placeholder={strings.selectOption}
                                    noOptionsMessage={()=>strings.noOptions}
                                    value={formData.eventType}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className={"cancel btn text-white"} onClick={() => {
                        props.handleClose();
                        // resetData();
                    }}>
                        {strings.cancel}
                    </div>
                    <button type={"submit"} className={"save btn text-white"}>
                        {strings.save}
                    </button>
                </Modal.Footer>
            </Form>
        </Modal>
    )

}

export default AcceptEventProposalModal;