import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {strings} from "../../../../localization/Localization";
import DraggableModalDialog from '../../../../components/DraggableModalDialog/DraggableModalDialog';

const CommentModal = (props) => {
    const { show, handleClose, selectedUserBenefitDetails } = props;

    return (
        <Modal show={show} dialogAs={DraggableModalDialog} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{strings.userComment}: {selectedUserBenefitDetails.userHelper?.firstName} {selectedUserBenefitDetails.userHelper?.lastName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedUserBenefitDetails && selectedUserBenefitDetails.comment ? (
                    <p>{selectedUserBenefitDetails.comment}</p>
                ) : (
                    <p>{strings.userCommentNotProvided}</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button className={"cancel btn text-white"} onClick={handleClose}>Close</button>
            </Modal.Footer>
        </Modal>
    );
}

export default CommentModal;