import React, {useEffect, useState} from "react";
import './MainLayout.css'
import 'react-pro-sidebar/dist/css/styles.css';
import Navigationbar from "../Navigationbar";
import Sidebar from "../Sidebar";
import '../Table.css'
import MobileNavigationBar from "../MobileNavigaionBar/MobileNavigationBar";
import MobileSideBar from "../MobileSideBar/MobileSideBar";
import UserRepository from "../../repository/userRepository/UserRepository";
import {update} from "../../redux/userSlice";
import {useDispatch} from "react-redux";

const MainLayout = ({children}) => {

    const prosidebar = document.getElementsByName("sidebar")

    const windowSize = window.innerWidth;

    const [collapsed, setCollapsed] = useState(false);

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const [userData, setUserData] = useState({});

    const dispach = useDispatch();

    useEffect(() => {
        UserRepository.getUserDetails().then(res => {
            setUserData(res.data);
            const firstName = res.data.firstName;
            const lastName = res.data.lastName;
            const parentName = res.data.parentName;
            const gender = res.data.person.gender;
            const email = res.data.email;
            dispach(update({firstName, lastName, parentName, gender, email}))
        })
    }, []);


    const handleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    function OnMenuClick() {
        setCollapsed(!collapsed);
    };

    return (
        <div className={'mainDiv h-100'}>
            {
                windowSize > 567 ?
                    (<Navigationbar/>)
                    : (
                        <MobileNavigationBar handleMobileMenu={handleMobileMenu}/>
                    )
            }

            <div className={'flex d-inline-flex w-100 centerWrapper'}>
                {windowSize > 567 ?
                    (<Sidebar userData={userData}/>) : (mobileMenuOpen &&
                        <MobileSideBar userData={userData} handleMobileMenu={handleMobileMenu}/>)
                }
                <div className={"w-100 scrollWrapper"}>
                    <div
                        className={`w-100 px-sm-3 px-lg-3  h-100 container  pt-2 pb-4 ${mobileMenuOpen ? "inShadow" : ""}`}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MainLayout;
