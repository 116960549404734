import "./OrganisationList.css";
import {strings} from "../../localization/Localization";
import OrganizationalHierarchyTree
    from "../OrganizationalHierarchy/OrganizationalHierarchyTree/OrganizationalHierarchyTree";
import React, {useEffect, useState} from "react";
import OrganisationHierarchyService from "../../repository/hierarchyRepository/organisationHierarchyRepository";
import NomenclatureEntryList from "../Nomencalture/NomenclatureEntryList/NomenclatureEntryList";
import {BiEdit, BiTime, BiTimeFive} from "react-icons/bi";
import {MdOutlineAddBox, MdOutlineDescription} from "react-icons/md";
import {FaLaptopCode} from "react-icons/fa";
import PositionListCard from "../../components/PositionListCard/PositionListCard";
import FormatDate from "../../components/formatDate/FormatDate";
import ApplicationService from "../../ApplicationService";
import OrganizationalHierarchyAddModal
    from "../OrganizationalHierarchy/OrganizationalHierarchyAddModal/OrganizationalHierarchyAddModal";
import OrganizationalHierarchyEditModal
    from "../OrganizationalHierarchy/OrganizationalHierarchyEditModal/OrganizationalHierarchyEditModal";
import OrganisationAddOrEditModal from "./OrganisationAddOrEditModal/OrganisationAddOrEditModal";
import swal from "sweetalert";
import AddButton from "../../components/addButton/AddButton";
import AddPositionInOrganisationModal from "./AddPositionInOrganisationModal/AddPositionInOrganisationModal";

const OrganisationList = () => {

    const [organisationList, setOrganisationList] = useState([]);

    const [selectedOrganisation, setSelectedOrganisation] = useState({});

    const [selectedOrganisationForEdit, setSelectedOrganisationForEdit] = useState({});

    const [isEdit, setIsEdit] = useState(false);

    const [activeLanguage, setActiveLanguage] = useState();

    const [positionsForOrganisation, setPositionForOrganisation] = useState([]);

    const [organisationListCheck, setOrganisationListCheck] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const [showPositionModal, setShowPositionModal] = useState(false);

    useEffect(() => {
        getAllOrganisations();
        setActiveLanguage(ApplicationService.getActiveLanguage());
    }, []);

    useEffect(() => {
        if (selectedOrganisation) {
            getAllPositionsForOrganisation(selectedOrganisation.id);
        }
    }, [selectedOrganisation])


    const getAllOrganisations = () => {
        OrganisationHierarchyService.getHierarchy()
            .then((data) => {
                setOrganisationList(data.data);
                if (data.data.length > 0) {
                    setOrganisationListCheck(true);
                } else {
                    setOrganisationListCheck(false);
                }
            })
    }

    const getAllPositionsForOrganisation = (id) => {
        OrganisationHierarchyService.fetchAllPositionForOrganisation(id)
            .then((data) => {
                setPositionForOrganisation(data.data)
            })
    }

    const handleSelectedOrganisation = (organisation) => {
        setSelectedOrganisation(organisation);
    }


    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleShowPositionModal = () => {
        setShowPositionModal(true);
    };

    const handleClosePositionModal = () => {
        setShowPositionModal(false);
    };

    let deleteOrganisation = (id) => {
        OrganisationHierarchyService.deleteOrganisation(id)
            .then((data) => {
                getAllOrganisations();
                setSelectedOrganisation(null);
                setSelectedOrganisationForEdit(null);
            })
    }

    const ConfirmationDelete = (id) => {
        swal(strings.deleteConfirmation, {
            title: strings.areYouSure,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteOrganisation(id);
            }
        });
    }

    const isActiveOrganisation = (organisation) => {
        const currentDate = new Date();

        if (organisation.activeFrom && new Date(organisation.activeFrom) <= currentDate) {
            if (organisation.activeTo && new Date(organisation.activeTo) >= currentDate) {
                return true;
            }
            if (organisation.activeTo === null) {
                return true;
            }
        }
        return false;
    };
    
    return (
        <>
            <div className="my-3 mx-3">
                <h1>{strings.organisationList}</h1>
            </div>
            <div>
                <div className={"row"}>
                    <div className={"col-sm-4 px-0"}>
                        <div className={"mx-2 mt-2 organisation-section h-100 overflow-auto"}>
                            {organisationListCheck === true &&
                              <div className={" mt-3 justify-content-end me-3 d-flex mb-1"}>
                                <div
                                  className={"btn text-blue  btn-light text-end d-flex align-items-center border-radius px-xs-0 "}
                                  onClick={() => {
                                      handleShowModal();
                                      setIsEdit(false)
                                  }}
                                >
                                    <div className={"align-items-center d-flex text-start"}>
                                        <i className={"material-icons add_button_icon"}>add_box</i>
                                    </div>
                                    <div className={"mx-1"}>
                                        {strings.add}
                                    </div>
                                </div>
                            </div>}

                            <ul className={"ps-0 pt-1"}>
                                {organisationList.map((organisation) => {
                                    const isActive = isActiveOrganisation(organisation);
                                    return (
                                        <li
                                            className={`d-flex text-center align-items-center d-flex justify-content-between btn btn-light mb-2 mt-1 w-90 mx-auto ${isActive ? "" : "inactive-organisation"} ${organisation.id === (selectedOrganisation ? selectedOrganisation.id : null) ? "active" : ""}`}
                                            key={organisation.id}
                                            onClick={() => handleSelectedOrganisation(organisation)}>
                                            <div className={"w-100 text-start"}>
                                                {organisation.name}
                                            </div>
                                            <div className={"ms-auto d-flex align-items-center status-icon"}>
                                                {isActive ? (
                                                    <>
                                                        <i className={"material-icons organisation-status-icon-positive"}>gpp_good</i>
                                                    </>
                                                ) : (
                                                    <>
                                                        <i className={"material-icons organisation-status-icon-negative"}>gpp_bad</i>
                                                    </>
                                                )}
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className={"col-sm-8"}>
                        <div className="mx-2 mt-2 organisation-section overflow-auto">
                            {selectedOrganisation && selectedOrganisation.id ? (
                                    <div>
                                        <div className="p-2 mx-2">
                                            <div className="row">
                                                <div className={"col-sm"}>
                                                    <div className={"mx-2"}>
                                                        <h2 className={"text-break"}>{selectedOrganisation ? selectedOrganisation.name : null}</h2>
                                                    </div>
                                                    <div className={"mx-2"}>
                                                        {selectedOrganisation.shortName &&
                                                            <h5>{strings.shortName}: {selectedOrganisation.shortName}</h5>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"d-flex justify-content-center my-1 col-sm"}>
                                            <div>
                                                <div
                                                    onClick={() => {
                                                        handleShowModal();
                                                        setSelectedOrganisationForEdit(selectedOrganisation);
                                                        setIsEdit(true)
                                                    }}

                                                    className={"btn text-white d-flex align-items-center button-hierarchy  mx-1 border-radius edit-button px-lg-4"}>
                                                    <div className={"align-items-center d-flex text-start"}>
                                                        <i className={"material-icons "}>app_registration</i>
                                                    </div>
                                                    <div className={"d-flex align-items-end mx-1"}>
                                                        {strings.edit}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <button onClick={() => {
                                                    ConfirmationDelete(selectedOrganisation ? selectedOrganisation.id : null)
                                                }}
                                                        className={"btn text-white d-flex align-items-center button-hierarchy  mx-1 border-radius rmv-button px-lg-4"}>
                                                    <div className={"align-items-center d-flex text-start"}>
                                                        <i className={"material-icons "}>delete_forever</i>
                                                    </div>
                                                    <div className={"d-flex align-items-end"}>
                                                        {strings.delete}
                                                    </div>

                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="container">
                                                <div className="row">
                                                    <div
                                                        className={`col text-center ${activeLanguage == "mk" ? "ms-4 ps-3" : "ms-5 ps-2"} mb-3 mt-4`}>
                                                        <BiTime/> {strings.activeFrom}: {selectedOrganisation.activeFrom ? FormatDate.formatDate(selectedOrganisation.activeFrom) :
                                                        <i>{strings.noInfo}</i>}
                                                    </div>
                                                    <div
                                                        className={`col text-start mb-3 mt-4 ${activeLanguage == "en" ? "ps-0" : ""}`}>
                                                        <BiTimeFive/> {strings.activeTo}: {selectedOrganisation.activeTo ? FormatDate.formatDate(selectedOrganisation.activeTo) :
                                                        <i>{strings.noInfo}</i>}
                                                    </div>
                                                    <div className="w-100"></div>
                                                    <div
                                                        className={`col text-center ${activeLanguage == "mk" ? "ms-4" : "ms-4 ps-3 "}  mb-5  me-1`}>
                                                        <MdOutlineAddBox/> {strings.created}: {selectedOrganisation?.dateCreated ? FormatDate.formatDate(selectedOrganisation?.dateCreated) :
                                                        <i>{strings.noInfo}</i>}
                                                    </div>
                                                    <div className="col text-start mb-5 ms-1">
                                                        <BiEdit/> {strings.modified}: {selectedOrganisation.dateModified ? FormatDate.formatDate(selectedOrganisation?.dateModified) :
                                                        <i>{strings.noInfo}</i>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-2 mb-3 mx-5 hierarchyelements">
                                                <div>
                                                    <MdOutlineDescription/> <span
                                                    className="fw-bold ms-0">{strings.description}</span>
                                                </div>

                                                <div className="p-1 mx-2 ms-1 mt-2">
                                                    {selectedOrganisation.description ? selectedOrganisation.description :
                                                        <i>{strings.noInfo}</i>}
                                                </div>
                                            </div>

                                            <div className="p-2 mt-2 mx-5 hierarchyelements">
                                                <FaLaptopCode/> <span className="fw-bold ms-0">{strings.code}</span>

                                                <div className="p-1 mx-2 mt-2 ms-1">
                                                    {selectedOrganisation.code ? selectedOrganisation.code :
                                                        <i>{strings.noInfo}</i>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"w-100 d-flex justify-content-between mt-3"}>
                                            <div>
                                                <h3 className={"mb-2 ms-4"}>{strings.positionForOrganisation}</h3>
                                            </div>
                                            <div className={"align-items-center d-flex me-3"}>
                                                <AddButton
                                                    onClickHandle={handleShowPositionModal}
                                                ></AddButton>
                                            </div>
                                        </div>
                                        <div className="hoverPointer">
                                        <PositionListCard positionList={positionsForOrganisation} />
                                        </div>
                                    </div>)
                                :
                                (organisationListCheck === false ?
                                        (
                                            <>
                                                <div className={"mt-4 d-flex justify-content-center text-secondary"}>
                                                    <h4>
                                                        <i>{strings.noOrganisationsToSelectFrom}</i></h4></div>
                                                <div className={"d-flex w-100 justify-content-center mt-2"}>
                                                    <button
                                                        onClick={handleShowModal}
                                                        className={"btn text-white d-flex align-items-center mx-3 border-radius add-button"}>
                                                        <div className={"align-items-center d-flex text-start"}>
                                                            <i className={"material-icons "}>add_box</i>
                                                        </div>
                                                        <div className={"d-flex align-items-end mx-1"}>
                                                            {strings.addOrganisation}
                                                        </div>
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <div className={"d-flex mt-4 my-auto w-100 h-100 text-center"}>
                                                <h5 className={"w-100 text-center "}><i
                                                    className={"text-secondary"}>{strings.selectOrganisation}</i></h5>
                                            </div>
                                        )
                                )

                            }
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <OrganisationAddOrEditModal showModal={showModal}
                                            handleClose={handleCloseModal}
                                            getAllOrganisations={getAllOrganisations}
                                            selectedOrganisationForEdit={selectedOrganisationForEdit}
                                            isEdit={isEdit}
                />
                <AddPositionInOrganisationModal showModal={showPositionModal}
                                                handleClose={handleClosePositionModal}
                                                organisation={selectedOrganisation}
                                                getAllPositionsForOrganisation={getAllPositionsForOrganisation}
                />
            </div>
        </>

    );
}

export default OrganisationList;