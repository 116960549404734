import axios from "../../axios/axios";
import benefitProposal from "../../pages/Benefits/Benefits/BenefitProposal/BenefitProposal";

const BenefitProposalService = {

    getAllBenefitProposals: () =>{
        return axios.get("/benefitProposal/getAll")
},

    getBenefitProposalById: (benefitProposalId) =>  {
        return axios.get(`/benefitProposal/get/${benefitProposalId}`)
    },

    getMyBenefitProposals: () => {
        return axios.get("/benefitProposal/getBenefitProposalsForLoggedInUser")
    },

    getBenefitProposalForUserId: (id) => {
        return axios.get(`/benefitProposal/${id}/getBenefitProposals`)
    },

    getPendingBenefitProposals: () => {
        return axios.get("/benefitProposals/getPendingBenefitProposals")
    },

    addBenefitProposal: (name, description) => {
        return axios.post("/benefitProposal/add", {
            name,
            description
        })
    },

    editBenefitProposal: (benefitProposalId, name, description) =>{
        return axios.put("/benefitProposal/update",{
            benefitProposalId,
            name,
            description
        })
    },

    deleteBenefitProposal: (benefitProposalId) => {
        return axios.delete(`/benefitProposal/delete/${benefitProposalId}`)
    },

    dennyBenefitProposal: (benefitProposalId) => {
        return axios.post(`/benefitProposal/deny/${benefitProposalId}`)
    },

    acceptBenefitProposal: (benefitProposalId) =>{
        return axios.post(`/benefitProposal/accept/${benefitProposalId}`)
    }
}

export default  BenefitProposalService;