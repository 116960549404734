import React, {useState} from "react";
import {Form, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {strings} from "../../localization/Localization";
import ExportService from "../../repository/exportRepository/exportRepository";
import swal from "sweetalert";
import {useHistory} from "react-router-dom";
import DraggableModalDialog from "../../components/DraggableModalDialog/DraggableModalDialog";

const DownloadFileModal = (props) => {

    const [formData, updateFormData] = React.useState({
        month: "",
        year: ""
    });

    let history = useHistory();

    const [fileName,setFileName]=useState("");

    const FileSaver = require("file-saver");

    const successfulAlert = () => {
        swal(strings.fileDownloaded, {
            icon: "success",
        });
    };

    const warningAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel,strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            } else {
            }
        });
    };

    const handleChange = (e) => {
        const value = e.target.value;
        if (e.target.hasAttribute("required") && value.trim() === "") {
          e.target.setCustomValidity(strings.fieldsRequired);
        } else {
          e.target.setCustomValidity("");
        }
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    };

    const downloadFile = (month,year) => {
        ExportService.getAbsenceByDateFile(month,year)
            .then((data)=>{
                setFileName(data.data)
                fetch(process.env.REACT_APP_HOST_ENV + `/export/download/${data.data}`,
                    {
                        origin: 'same-origin',
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token')
                        }
                    }
                ).then(res => res.blob())
                    .then(file => FileSaver.saveAs(file, data.data + '.xlsx'))
            }).catch(()=>{
                warningAlert();
        })
    }

    const onFormSubmit = () => {
        const month=formData.month;
        const year=formData.year;
        downloadFile(month,year);
    }

    return (
        <Modal show={props.showDownloadModal} dialogAs={DraggableModalDialog} onHide={props.handleCloseDownloadModal} centered>
            <ModalHeader>
                <ModalTitle><b>{strings.enterDate}</b></ModalTitle>
            </ModalHeader>
            <Form onSubmit={onFormSubmit}>
                <ModalBody>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3 required" controlId="exampleForm.ControlInput1">
                                <Form.Label>{strings.month}<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    autoFocus
                                    name='month'
                                    onChange={handleChange}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{strings.year}<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    autoFocus
                                    onChange={handleChange}
                                    name="year"
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <div className={"cancel btn text-white"}
                            onClick={() => props.handleCloseDownloadModal()}>{strings.close}</div>
                    <div className={"save btn text-white"} onClick={()=> onFormSubmit()}>{strings.download}</div>
                    {/*<a download href={process.env.REACT_APP_HOST_ENV+"/export/download/"+fileName} onClick={()=> onFormSubmit()}>asd</a>*/}

                </ModalFooter>
            </Form>
        </Modal>

    );

}
export default DownloadFileModal;