import {strings} from "../../../localization/Localization";
import React, {Fragment, useState} from "react";
import "../EventProposal/EventProposal.css";
import femaleAvatar from "../../../assets/images/female_avatar.png";
import swal from "sweetalert";
import EventProposalService from "../../../repository/eventsRepository/EventProposalRepository";
import AddEditEventProposalModal from "./AddEditEventProposal/AddEditEventProposalModal";
import AcceptEventProposalModal from "./AcceptEventProposalModal/AcceptEventProposalModal";
import ApplicationService from "../../../ApplicationService";

const EventProposal = (props) => {

    const [isExpanded, setIsExpanded] = useState(false);

    const [selectedEventProposalForEdit, setSelectedEventProposalForEdit] = useState({})

    const [showAddModal, setShowAddModal] = useState(false);

    const [showAcceptModal, setShowAcceptModal] = useState(false);

    const [selectedProposalForAccept, setSelectedProposalForAccept] = useState({});

    const maxWordsToShow = 10;

    const getEventProposalForRefresh = () => {
        EventProposalService.getEventProposalById(props.eventProposal?.eventProposalId)
            .then((data) => {

            })
    }

    const checkStatus = (status) => {
        switch (status) {
            case "ACCEPTED":
                return strings.accepted;
            case "NOT ACCEPTED":
                return strings.notAccepted;
            case "PENDING":
                return strings.pending;
            default:
                return "";
        }
    }

    const getStatusColorClass = (status) => {
        switch (status) {
            case "ACCEPTED":
                return "accepted-color";
            case "NOT ACCEPTED":
                return "not-accepted-color";
            case "PENDING":
                return "pending-color";
            default:
                return "";
        }
    };

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const errorAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const deleteEventProposal = (id) => {
        EventProposalService.deleteEventProposal(id)
            .then(() => {
                successfulAlert();
                props.getAllEventProposals();
                props.getAllEventProposalsForUser();
            })
            .catch(() => {
                errorAlert();
            })
    }

    const ConfirmationDeleteEventProposal = (id) => {
        swal(strings.deleteConfirmation, {
            title: strings.areYouSure,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteEventProposal(id);
            }
        });
    }


    const handleShowEditEventProposalModal = () => {
        setShowAddModal(true);
    };

    const handleCloseAddProposalModal = () => {
        setSelectedEventProposalForEdit({});
        setShowAddModal(false);
    };

    const statusColorClass = getStatusColorClass(props.eventProposal?.status);
    const descriptionWords = props.eventProposal?.description.split(' ');
    const shouldShowMoreLink = descriptionWords.length > maxWordsToShow;

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };

    const handleProposalClick = () => {
        setSelectedProposalForAccept(props.eventProposal);
        setShowAcceptModal(true);
    }

    const handleCloseAcceptProposalModal = () => {
        setSelectedProposalForAccept({});
        setShowAcceptModal(false);
    };

    return (
        <>
            <AddEditEventProposalModal
                showModal={showAddModal}
                handleClose={handleCloseAddProposalModal}
                selectedEventProposal={selectedEventProposalForEdit}
                getEventProposals={props.getAllEventProposals}
                getEventProposalForRefresh={getEventProposalForRefresh}
                getEventProposalsForUser={props.getAllEventProposalsForUser}
            />
            <AcceptEventProposalModal
                showModal={showAcceptModal}
                handleClose={handleCloseAcceptProposalModal}
                eventProposalForAccepting={selectedProposalForAccept}
            />
            <tr className="userlist py-auto cursor-pointer" key={props.eventProposal?.id}>
                <td>
                    <b>{props.eventProposal?.name}</b>
                </td>
                <td className="text_blue_dark w-25">
                    <div className={`description-container ${isExpanded ? "expanded" : ""}`}>
                        {descriptionWords.slice(0, maxWordsToShow).join(" ")}
                        {shouldShowMoreLink && !isExpanded && " ... "}
                        {isExpanded || !shouldShowMoreLink ? (
                            descriptionWords.slice(maxWordsToShow).join(" ")
                        ) : null}
                    </div>
                    {shouldShowMoreLink && (
                        <span className="show-more-link text_blue_dark" onClick={toggleDescription}>
              {isExpanded ? (
                  <i className={"material-icons"}>expand_less</i>
              ) : (
                  <i className={"material-icons"}>expand_more</i>
              )}
            </span>
                    )}
                </td>
                <td className={"px-1 text_blue_dark"}>
                    <b>
                        {props.eventProposal.user.firstName} {props.eventProposal.user.lastName}
                    </b>
                </td>
                <td className={`px-1 text_blue_dark`}>
                    <b className={`pb-2 px-2 rounded-2 ${statusColorClass}`}>
                        {checkStatus(props.eventProposal?.status)}
                    </b>
                </td>
                <td className={"h-100"}>
                    <div className={"d-inline-flex pe-2 ms-auto"}>
                        {/*{ApplicationService.hasRole(["ROLE_HR", "ROLE_ADMINISTRATOR"]) &&*/}
                        {/*    props.eventProposal?.status !== "ACCEPTED" &&  (*/}
                        {/*        <div className="icon-div ms-3">*/}
                        {/*            <a title="Delete" type="Button" onClick={handleProposalClick}>*/}
                        {/*                <i className="material-icons delete-icon-circle">add</i>*/}
                        {/*            </a>*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {props.eventProposal?.status === "PENDING" && (
                            <>
                                <div className={"icon-div"}>
                                    <a
                                        onClick={() => {
                                            setSelectedEventProposalForEdit(props.eventProposal);
                                            handleShowEditEventProposalModal();
                                        }}
                                        title={"Edit"}
                                        className={"text-dark icon"}
                                    >
                                        <i className={"edit-icon-circle material-icons "}>create</i>
                                    </a>
                                </div>
                                <div className={"icon-div"}>
                                    <a
                                        title={"Delete"}
                                        type={"Button"}
                                        onClick={() => ConfirmationDeleteEventProposal(props.eventProposal.eventProposalId)}
                                    >
                                        <i className={"material-icons delete-icon-circle"}>delete</i>
                                    </a>
                                </div>
                            </>
                        )}
                    </div>
                </td>
            </tr>
        </>
    );
}

export default EventProposal;
