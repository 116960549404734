import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import BenefitService from "../../../repository/benefitsRepository/BenefitsRepository";
import {strings} from "../../../localization/Localization";
import UserBenefits from "./UserBenefitsList/UserBenefits";
import Form from "react-bootstrap/Form";
import ApplicationService from "../../../ApplicationService";

const UserBenefitData = (props) => {

    const param = useParams();

    const [userBenefits, setUserBenefits] = useState([]);

    const [checkButton, setCheckButton] = useState(false);

    useEffect(() => {
        if (props.currentTab === "benefitsForUser") {
            if (checkButton === true) {
                getBenefitsForUser();
            } else {
                getBenefitsForUserWithActiveBenefitStatus()
            }
        }
    }, [props.currentTab, checkButton])

    const handleChange = (e) => {
        setCheckButton(e.target.checked)
    }

    const getBenefitsForUser = () => {
        if (param.id) {
            BenefitService.getAllUserBenefitDataForUser(param.id)
                .then((data) => {
                    setUserBenefits(data.data);
                })
        } else {
            BenefitService.getAllUserBenefitDataForLoggedUser()
                .then((data) => {
                    setUserBenefits(data.data);
                })
        }
    }

    const getBenefitsForUserWithActiveBenefitStatus = () => {
        if (param.id) {
            BenefitService.getAllUserBenefitForUserAndStatusActive(param.id)
                .then((data) => {
                    setUserBenefits(data.data)
                })
        } else {
            BenefitService.getAllUserBenefitForLoggedUserAndStatusActive()
                .then((data) => {
                    setUserBenefits(data.data)
                })
        }
    }

    return (
        <div className={"p-4"}>
            <div className={"d-flex"}>
                <h2>
                    {param.id ? (
                        strings.userBenefits + props.selectedUser?.firstName + " " + props.selectedUser?.lastName
                    ) : (
                        strings.myBenefits
                    )}
                </h2>
                <div className={"d-flex ms-auto align-items-center"}>
                    <div className={"me-3 text-secondary"}>
                        {strings.showInactive}
                    </div>
                    <div className={"mt-1"}>
                        <label className="switchEnabled">
                            <input type="checkbox" name={"pd_key"}
                                // value={formData.pd_key}
                                   checked={checkButton}
                                   onChange={handleChange}
                            />
                            <span className="sliderEnabled round"> </span>
                        </label>
                    </div>
                </div>
            </div>
            <div className={"row table-responsive mt-1"}>
                <table id="keywords" className={"mb-1 custom-table-header"}>
                    <thead>
                    <tr className={"userlist"}>
                        <th scope={"col"}>{strings.name}</th>
                        <th scope={"col"}>{strings.startDate}</th>
                        <th scope={"col"}>{strings.endDate}</th>
                        <th scope={"col"}>{strings.status}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userBenefits.length > 0 && userBenefits.map((userBenefit, index) => (
                        <UserBenefits
                            key={index}
                            userBenefit={userBenefit}
                            selectedUser={props.selectedUser}
                            currentTab={props.currentTab}
                        />
                    ))}
                    </tbody>
                </table>
                {
                    userBenefits.length === 0 &&
                    // <h3 className={"text-center my-5"}>
                    //     <i className={"text-secondary no-notification-message"}>{strings.noBenefits}</i>
                    // </h3>
                    <div className={"justify-content-center text-center "}>
                        <h5 className={"text-secondary"}><i>{strings.emptyList}</i></h5>
                    </div>
                }
            </div>
        </div>
    )

}

export default UserBenefitData;