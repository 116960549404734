import axios from "../../axios/axios";

const PositionHierarchyService = {
    getHierarchy: () => {
        return axios.get("/position/getAll")
    },
    editHierarchy: (id, hrmsStPositionsHId, activeFrom, activeTo, code, name, shortName, description,dateCreated,dateModified,parentId,organistaionId) => {
        return axios.put('/position/update', {
            id,
            hrmsStPositionsHId,
            activeFrom,
            activeTo,
            code,
            name,
            shortName,
            description,
            dateCreated,
            dateModified,
            parentId,
            organistaionId
        })
    },
    addHierarchy: (hrmsStPositionsHId, activeFrom, activeTo, code, name, shortName, description,dateCreated,dateModified,parentId,organistaionId) => {
        return axios.post('/position/add', {
            hrmsStPositionsHId,
            activeFrom,
            activeTo,
            code,
            name,
            shortName,
            description,
            dateCreated,
            dateModified,
            parentId,
            organistaionId
        })
    },
    deleteHierarchy: (id) => {
        return axios.delete(`/position/delete/${id}`);
    },
    getAllUserForPosition:(id,data)=>{
        return axios.post(`/user/getAllByPosition/${id}`,data)
    },
    getAllUsersForPositionPaginated:(id,page,size,data)=>{
        return axios.post(`/user/getAllByPositionPaginated/${id}/${page}/${size}`,data)
    },
    getAllOtherUserByPositionPaginated:(id,page,size,data)=>{
        return axios.post(`/user/getOtherUserByPositionPaginated/${id}/${page}/${size}`, data)
    },
    getAllOtherUserForPosition:(id,data)=>{
      return axios.post(`/user/getOtherUserByPosition/${id}`,data)
    },
    addUserInPosition:(id,users)=>{
        return axios.post(`/user/addUserToPosition/${id}`,users)

    },
    deleteUserFromPosition:(id,userIds)=>{
        return axios.post(`/user/removeUserFromPosition/${id}`,userIds   )
    }
}
export default PositionHierarchyService;