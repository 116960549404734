import React, { useEffect, useState } from 'react';
import { strings } from '../../../../localization/Localization';
import './UserEducation.css';
import { useParams } from 'react-router-dom';
import UserEducationService from '../../../../repository/UserEducationRepository/UserEducationRepository';
import AddButton from '../../../../components/addButton/AddButton';
import UserEducationModal from './UserEducationModal/UserEducationModal';
import swal from 'sweetalert';
import GreyCard from '../../../../components/GreyCard/GreyCard';
import FormatDate from '../../../../components/formatDate/FormatDate';

const UserEducation = (props) => {

  const [allEducationForUser, setAllEducationForUser] = useState([]);

  const [showUserEducationModal, setShowUserEducationModal] = useState(false);

  const [selectedEducationForEdit, setSelectedEducationForEdit] = useState({});

  const param = useParams();

  useEffect(() => {
    if (props.currentTab === 'personalData') {
      getEducationForUser();
    }
  }, [props.currentTab]);

  const ConfirmationDelete = (id) => {
    swal(strings.deleteConfirmation, {
      title: strings.areYouSure,
      icon: 'warning',
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteUserEducation(id);
      }
    });
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: 'success',
    });
  };

  const errorAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: 'error',
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const handleShowUserEducationModal = () => {
    setShowUserEducationModal(true);
  };

  const handleCloseUserEducationModal = () => {
    setShowUserEducationModal(false);
    setSelectedEducationForEdit({});
  };

  const getEducationForUser = () => {
    if (param.id) {
      UserEducationService.getEducationForUserById(param.id).then((data) => {
        setAllEducationForUser(data.data);
      });
    } else {
      UserEducationService.getEducationForLoggedUser().then((data) => {
        setAllEducationForUser(data.data);
      });
    }
  };

  const deleteUserEducation = (id) => {
    UserEducationService.deleteUserEducation(id)
      .then(() => {
        successfulAlert();
        getEducationForUser();
      })
      .catch(() => {
        errorAlert();
      });
  };

  return (
    <div>
      <div className={'d-flex align-items-center border-radius  mb-4'}>
        <h4 className={'me-3 text-secondary'}> {strings.education}</h4>
        <hr className={'me-3'}/>
        <AddButton onClickHandle={handleShowUserEducationModal}/>
      </div>
      {
        allEducationForUser.map((education) => {
          return (
            <GreyCard key={education.id}>
              <div className={'d-flex align-items-center mb-2'}>
                <i
                  className={'me-1 material-icons'}>school</i> {FormatDate.formatDate(education.startDate.split('T')[0])} - {education.endDate != null ? FormatDate.formatDate(education?.endDate?.split('T')[0]) : strings.present}
                <div className={'ms-auto d-flex me-3'}>
                  <div className={'icon-div mx-2'}>
                    <div
                      onClick={() => {
                        setSelectedEducationForEdit(education);
                        handleShowUserEducationModal();
                      }}
                      title={'Edit'}
                      className={'text-dark icon'}>
                      <i className={'edit-icon-circle material-icons '}>
                        create
                      </i>
                    </div>
                  </div>
                  <div className={'icon-div'}>
                    <a title={'Delete'}>
                      <div
                        onClick={() => ConfirmationDelete(education.id)}
                        title={'Delete'}
                        className={'text-dark icon'}>
                        <i className={'material-icons delete-icon-circle'}>delete</i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <b className={'text_blue_dark'}>{education.institution}</b> <br/>
              <i className={'text-secondary'}>{education.area}</i>
            </GreyCard>
          );
        })
      }
      <UserEducationModal showModal={showUserEducationModal} handleClose={handleCloseUserEducationModal}
                          selectedEducationForEdit={selectedEducationForEdit} param={param}
                          getAllEducationForUser={getEducationForUser} setShowSpinner={props.setShowSpinner}/>
    </div>
  );
};
export default UserEducation;