import {strings} from "../../localization/Localization";
import React from "react";

const InputLimit = {

    codeLimitMessage: () => {
        return (
            <div className={"my-3"}>
        <span className={"gray-background py-2 px-3 rounded-3 d-flex align-items-center"}>
          <i className={"material-icons me-1 fs-22"}>error_outline</i>
          <span className={"fs-15"}>{strings.codeLimitMessage}</span>
        </span>
            </div>
        );
    },
};

export default InputLimit;