import axios from '../../axios/axios';

const FeedbackRepository = {

  getAllFeedback: (page, size) => {
    return axios.get('/feedback/list-all-paginated', {
      params: {
        page,
        size
      }
    });
  },

  addFeedback: (title, type, dateCreated, description) => {
    return axios.post('feedback/addFeedback', {
      title,
      type,
      dateCreated,
      description,

    });
  },

  deleteFeedback: (id) => {
    return axios.delete(`/feedback/delete/${id}`);
  },

  setPriority: (id) => {
    return axios.get(`/feedback/setPriority/${id}`);
  },
};
export default FeedbackRepository;