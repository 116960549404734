import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { strings } from '../../../../localization/Localization';
import Select from 'react-select';
import AddButton from '../../../../components/addButton/AddButton';
import EventGroupDetails from '../../EventGroup/EventGroupDetails';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EventsService from '../../../../repository/eventsRepository/EventsRepository';
import ApplicationService from '../../../../ApplicationService';
import EventGroupService from '../../../../repository/eventsRepository/EventGroupRepository';
import NomenclatureService from '../../../../repository/nomenclatureRepository/NomenclatureRepository';
import swal from 'sweetalert';
import AddOrEditEventGroup from '../../EventGroup/AddOrEditEventGroupModal/AddOrEditEventGroup';
import EventProposalService from '../../../../repository/eventsRepository/EventProposalRepository';
import SpinnerComponent from '../../../../components/Spinner';

const AddOrEditEvent = (props) => {

  const history = useHistory();

  const [selectedEvent, setSelectedEvent] = useState();

  const param = useParams();

  const [collapseOpen, setCollapseOpen] = useState(false);

  const [showEventGroupModal, setShowEventGroupModal] = useState(false);

  const [eventProposals, setEventProposals] = useState([]);

  const [pendingEventProposals, setPendingEventProposals] = useState([]);

  const [selectedEventProposals, setSelectedEventProposals] = useState([]);

  const [selectedEventGroupForEdit, setSelectedEventGroupForEdit] = useState({});

  const [nomenclatureEventsList, setNomenclatureEventsList] = useState([]);

  const [nomenclatureSkillsEntries, setNomenclatureSkillsEntries] = useState([]);

  const [statusList, setStatusList] = useState([
    {
      value: 'ACTIVE',
      label: strings.activeEvent
    },
    {
      value: 'INACTIVE',
      label: strings.inactiveEvent
    }
  ]);

  const [currencies, setCurrencies] = useState([
    {
      value: 'DOLLARS',
      label: strings.dollars
    },
    {
      value: 'EUROS',
      label: strings.euros
    },
    {
      value: 'DENARS',
      label: strings.denars
    },
    {
      value: 'POUNDS',
      label: strings.pounds
    }
  ]);

  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState(null);

  const [eventGroupsForSelectedEvent, setEventGroupsForSelectedEvent] = useState([]);

  const [eventGroupForEdit, setEventGroupForEdit] = useState({});

  const [showModal, setShowModal] = useState(false);

  const [dragActive, setDragActive] = React.useState(false);

  const [file, setFile] = useState({});

  const location = useLocation();

  const eventProposal = location.state ? location.state.eventProposal : null;

  const inputRef = useRef(null);

  const [showSpinner, setShowSpinner] = useState(true);

  const [formData, updateFormData] = useState({
    name: '',
    description: '',
    eventType: undefined,
    status: '',
    dateCreated: '',
    dateModified: '',
    certificate: '',
    organisation: '',
    cost: '',
    currency: '',
    trainer: '',
    eventProposals: undefined,
    file_description: '',
    eventSkill: undefined,
  });

  const handleCancel = () => {
    history.goBack();
  };

  const getEventDetails = () => {
    if (param.id) {
      EventsService.getEventById(param.id).then((data) => {
        setSelectedEvent(data.data);
      })
    }
  };

  const getEventProposals = () => {
    EventProposalService.getAllEventProposals()
      .then((data) => {
        let eventProposalsTemp = data.data.map((n) => ({
          value: n.eventProposalId,
          label: n.name + ' from user ' + n.user?.firstName + ' ' + n.user?.lastName
        }));
        setEventProposals(eventProposalsTemp);
      });
  };

  const getPendingEventProposals = () => {
    EventProposalService.getPendingEventProposals()
      .then((data) => {
        let eventProposalsTemp = data.data.map((n) => ({
          value: n.eventProposalId,
          label: n.name + ' from user ' + n.user?.firstName + ' ' + n.user?.lastName
        }));
        setPendingEventProposals(eventProposalsTemp);
      });
  };

  const eventProposalsConverter = (data) => {
    if (Array.isArray(data) && data.length > 1) {
      return data.map((n) => {
        return {
          value: n.eventProposalId,
          label: n.name + ' from user ' + (n.user?.firstName || '') + ' ' + (n.user?.lastName || '')
        };
      });
    } else if (Array.isArray(data) && data.length === 1) {
      return data.map((n) => {
        return {
          value: n.eventProposalId,
          label: n.name + ' from user ' + (n.user?.firstName || '') + ' ' + (n.user?.lastName || '')
        };
      });
    } else if (data && data.eventProposalId) {
      return [
        {
          value: data.eventProposalId,
          label: data.name + ' from user ' + (data.user?.firstName || '') + ' ' + (data.user?.lastName || '')
        }
      ];
    }
  };

  const eventSkillConverter = (data) => {
    if (data != null) {
      return data.map((n) => {
        return {
          value: n.skillsNomenclatureEntryHelper?.id,
          label: ApplicationService.getActiveLanguage() === 'mk' ? n.skillsNomenclatureEntryHelper?.labelMk : n.skillsNomenclatureEntryHelper?.labelEn
        };
      });
    }
  };

  useEffect(() => {
    getEventDetails();
    getEventProposals();
    getPendingEventProposals();
  }, []);

  useEffect(() => {
    if (!param.id) {
      resetData();
      if (eventProposal) {
        let eventProposalTemp = eventProposalsConverter(eventProposal);
        updateFormData({
          name: eventProposal.name,
          description: eventProposal.description,
          eventProposals: eventProposalTemp
        });
      }
    } else {
      if (selectedEvent) {
        getAllGroupsForEvent();
        const currencyType = getCurrencyType(selectedEvent?.currency);
        const statusTemp = getStatus(selectedEvent?.status);
        setSelectedCurrency(currencyType);
        setSelectedStatus(statusTemp);
        let eventTypeTemp = null;
        if (selectedEvent?.eventTypeNomenclatureEntry != null) {
          eventTypeTemp = {
            value: selectedEvent?.eventTypeNomenclatureEntry?.id,
            label: ApplicationService.getActiveLanguage() === 'mk' ? selectedEvent?.eventTypeNomenclatureEntry?.labelMk : selectedEvent?.eventTypeNomenclatureEntry?.labelEn
          };
        }

        if (selectedEvent?.eventId) {
          let eventProposalTemp = eventProposalsConverter(selectedEvent.eventProposalHelper);
          let eventSkillTemp = eventSkillConverter(selectedEvent?.eventSkillsHelper);
          updateFormData({
            name: selectedEvent?.name,
            description: selectedEvent?.description,
            status: statusTemp.value,
            eventType: eventTypeTemp,
            certificate: selectedEvent?.certificate,
            organisation: selectedEvent?.organisation,
            cost: selectedEvent?.cost,
            currency: currencyType.value,
            trainer: selectedEvent?.trainer,
            eventProposals: eventProposalTemp,
            eventSkill: eventSkillTemp
          });
        }
      }
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (nomenclatureEventsList.length === 0) {
      getAllNomenclatureEntriesForEvents();
    }
    if (nomenclatureSkillsEntries.length === 0) {
      getAllSkills();
    }
  }, [formData]);

  const getCurrencyType = (type) => {
    if (type === 'DOLLARS') {
      return {
        value: type,
        label: strings.dollars
      };
    } else if (type === 'EUROS') {
      return {
        value: type,
        label: strings.euros
      };
    } else if (type === 'DENARS') {
      return {
        value: type,
        label: strings.denars
      };
    } else if (type === 'POUNDS') {
      return {
        value: type,
        label: strings.pounds
      };
    }
    return null;
  };

  const getStatus = (status) => {
    if (status === 'ACTIVE') {
      return {
        value: status,
        label: strings.activeEvent
      };
    } else if (status === 'INACTIVE') {
      return {
        value: status,
        label: strings.inactiveEvent
      };
    }
  };

  const getAllGroupsForEvent = () => {
    EventGroupService.getAllGroupsForEvent(selectedEvent?.eventId)
      .then((data) => {
        setEventGroupsForSelectedEvent(data.data);
      });
  };

  const resetData = () => {
    updateFormData({
      name: '',
      description: '',
      eventType: undefined,
      status: '',
      dateCreated: '',
      dateModified: '',
      certificate: '',
      organisation: '',
      cost: '',
      currency: '',
      trainer: '',
      eventProposals: undefined,
      file_description: '',
      eventSkill: undefined,
    });
  };

  const getAllNomenclatureEntriesForEvents = () => {
    NomenclatureService.getAllNomenclatureEntryByNomenclatureName('Обуки')
      .then((data) => {
        let nomenclatureConvert = data.data.map((n) => {
          return {
            value: n.id,
            label: ApplicationService.getActiveLanguage() == 'mk' ? n.labelMk : n.labelEn
          };
        });
        setNomenclatureEventsList(nomenclatureConvert);
      });
  };

  const getAllSkills = () => {
    NomenclatureService.getAllNomenclatureEntryByNomenclatureName('Вештини')
      .then((data) => {
        let nomenclatureConvert = data.data.map((n) => {
          return {
            value: n.id,
            label: ApplicationService.getActiveLanguage() == 'mk' ? n.labelMk : n.labelEn
          };
        });
        setNomenclatureSkillsEntries(nomenclatureConvert);
      });
  };

  const handleEventsChange = (selectedOption) => {
    updateFormData({
      ...formData,
      eventType: selectedOption
    });
  };

  const handleSkillsChange = (selectedOption) => {
    updateFormData({
      ...formData,
      eventSkill: selectedOption
    });
  };

  const handleChange = (e) => {
    const {
      name,
      value
    } = e.target;

    if (e.target.hasAttribute("required") && value.trim() === "") {
      e.target.setCustomValidity(strings.fieldsRequired);
    } else {
      e.target.setCustomValidity("");
    }
    updateFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption);
    updateFormData({
      ...formData,
      currency: selectedOption?.value,
    });
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
    updateFormData({
      ...formData,
      status: selectedOption?.value,
    });
  };

  const handleEventProposalsChange = (selectedOption) => {
    setSelectedEventProposals(selectedOption);
    updateFormData({
      ...formData,
      eventProposals: selectedOption
    });
  };

  const handleCollapseToggle = () => {
    setCollapseOpen(!collapseOpen);
  };

  const eventAddSuccessfulAlert = () => {
    swal(strings.userEditSuccessful, {
      icon: 'success',
    });
  };

  const errorAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: 'error',
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const handleShowEventGroupModal = () => {
    setShowEventGroupModal(true);
  };

  const handleCloseEventGroupModal = () => {
    setShowEventGroupModal(false);
    setSelectedEventGroupForEdit({});
  };

  const onClickSetEventGroupForEdit = (e) => {
    setEventGroupForEdit(e);
    handleShow();
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleChangeFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const addEvent = (newFormData) => {
    setShowSpinner(false);
    EventsService.addEvent(newFormData)
      .then(() => {
        setShowSpinner(true);
        eventAddSuccessfulAlert();
        getEventDetails();
        history.push('/events');
      }).catch(() => {
      setShowSpinner(true);
      errorAlert();
    });
  };

  const editEvent = (newFormData) => {
    setShowSpinner(false);
    EventsService.editEvent(newFormData)
      .then(() => {
        setShowSpinner(true);
        eventAddSuccessfulAlert();
        getEventDetails();
        history.push('/events');
      }).catch(() => {
      setShowSpinner(true);
      errorAlert();
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const newFormData = new FormData();

    // Add form fields to FormData
    newFormData.append('name', formData.name || '');
    newFormData.append('eventTypeId', formData.eventType ? formData.eventType.value : '');
    newFormData.append('description', formData.description || '');
    newFormData.append('status', formData.status || '');
    newFormData.append('organisation', formData.organisation || '');
    newFormData.append('certificate', formData.certificate || '');
    newFormData.append('cost', formData.cost || '');
    newFormData.append('currency', formData.currency || '');
    newFormData.append('trainer', formData.trainer || '');

    if (file) {
      newFormData.append('file', file);
      newFormData.append('file_description', formData.file_description || '');
    }

    const eventProposalsIds = formData.eventProposals ? formData.eventProposals.map((e) => e.value) : [];
    newFormData.append('eventProposalsIds', JSON.stringify(eventProposalsIds));

    const eventSkillsId = formData.eventSkill ? formData.eventSkill.map((e) => e.value) : [];
    newFormData.append('eventSkillsId', JSON.stringify(eventSkillsId));

    if (selectedEvent?.eventId) {
      newFormData.append('eventId', selectedEvent?.eventId);
      editEvent(newFormData);
    } else {
      addEvent(newFormData);
    }
  };

  return (
    <div className={'pb-4'}>
      <div className={'user_details_box w-100 mb-4 border border-radius border-light border-5 px-4 pt-4'}>
        <Form onSubmit={onFormSubmit}>
          {param.id ? (
            <h1 className={'text-center'}>{strings.editTheEvent}: <b>{formData.name}</b></h1>
          ) : (
            <h1 className={'text-center'}>{strings.addEvent}</h1>
          )}
          <hr/>
          <Row className={'mb-2'}>
            <Col xs={12} md={6}>
              <Form.Group className='mb-3'>
                <Form.Label className={'d-flex'}>
                  {strings.name}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  type={'text'}
                  value={formData.name}
                  onChange={handleChange}
                  name={'name'}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}/>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className='mb-3'>
                <Form.Label className={'d-flex'}>
                  {strings.eventType}
                </Form.Label>
                <Select
                  name='nomenclatureEventType'
                  options={nomenclatureEventsList}
                  className={`basic-multi-select user_details_select_input`}
                  classNamePrefix='select'
                  onChange={handleEventsChange}
                  placeholder={strings.selectOption}
                  noOptionsMessage={() => strings.noOptions}
                  value={formData.eventType}/>
              </Form.Group>
            </Col>
          </Row>
          <Row className={'mb-2'}>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label className={'d-flex'}>
                  {strings.description}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  as='textarea'
                  rows={4}
                  value={formData.description}
                  onChange={handleChange}
                  name={'description'}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}/>
              </Form.Group>
            </Col>
          </Row>
          <Row className={'mb-2'}>
            <Col xs={12} md={6}>
              <Form.Group className='mb-3'>
                <Form.Label className={'d-flex'}>
                  {strings.organisation}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  type={'text'}
                  value={formData.organisation}
                  onChange={handleChange}
                  name={'organisation'}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}/>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className='mb-3'>
                <Form.Label className={'d-flex'}>
                  {strings.trainer}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  type={'text'}
                  value={formData.trainer}
                  onChange={handleChange}
                  name={'trainer'}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}/>
              </Form.Group>
            </Col>
          </Row>
          <Row className={'mb-2'}>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label className={'d-flex'}>
                  {strings.certificate}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  type={'text'}
                  value={formData.certificate}
                  onChange={handleChange}
                  name={'certificate'}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}/>
              </Form.Group>
            </Col>
          </Row>
          <Row className={'mb-2'}>
            <Col xs={12} md={6}>
              <Form.Group className='mb-3'>
                <Form.Label className={'d-flex'}>
                  {strings.cost}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  type={'number'}
                  value={formData.cost}
                  onChange={handleChange}
                  name={'cost'}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}/>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className='mb-3'>
                <Form.Label className={'d-flex'}>
                  {strings.currency}<span className="text-danger">*</span>
                </Form.Label>
                <Select
                  required
                  options={currencies}
                  className={`basic-multi-select user_details_select_input`}
                  classNamePrefix='select'
                  name={'currency'}
                  value={selectedCurrency}
                  placeholder={strings.selectOption}
                  noOptionsMessage={() => strings.noOptions}
                  onChange={handleCurrencyChange}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}/>
              </Form.Group>
            </Col>
          </Row>
          <Row className={'mb-3'}>
            <Col xs={12} md={6}>
              <Form.Group className='mb-3'>
                <Form.Label className={'d-flex'}>
                  {strings.status}<span className="text-danger">*</span>
                </Form.Label>
                <Select
                  required
                  options={statusList}
                  className={`basic-multi-select user_details_select_input`}
                  classNamePrefix='select'
                  name={'status'}
                  value={param.id ? selectedStatus : statusList[1]}
                  isDisabled={!param.id}
                  onChange={handleStatusChange}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}/>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className='mb-3'>
                <Form.Label className={'d-flex'}>
                  {strings.eventProposals}
                </Form.Label>
                <Select
                  options={pendingEventProposals}
                  isMulti
                  className={`basic-multi-select user_details_select_input`}
                  classNamePrefix='select'
                  name={'eventProposals'}
                  value={formData.eventProposals}
                  placeholder={strings.selectOption}
                  noOptionsMessage={() => strings.noOptions}
                  onChange={handleEventProposalsChange}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className='mb-3'>
                <Form.Label className={'d-flex'}>
                  {strings.eventSkills}
                </Form.Label>
                <Select
                  name='eventSkill'
                  options={nomenclatureSkillsEntries}
                  isMulti
                  className={`basic-multi-select user_details_select_input`}
                  classNamePrefix='select'
                  onChange={handleSkillsChange}
                  placeholder={strings.selectOption}
                  noOptionsMessage={()=>strings.noOptions}
                  value={formData.eventSkill}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <div className={'d-flex align-items-center border-radius mb-4'}>
              <h4 className={'me-3 text-secondary'}>{strings.files}</h4>
              <hr className={'me-3'}/>
              <div
                className={`btn text-white text-end d-flex align-items-center border-radius px-xs-0  add-button ${
                  collapseOpen ? 'active' : ''
                }`}
                onClick={handleCollapseToggle}
                aria-expanded={collapseOpen}>
                <div className={'align-items-center d-flex text-start'}>
                  <i className={'material-icons add_button_icon'}>add_box</i>
                </div>
                <div className={'mx-1'}>
                  {strings.add}
                </div>
              </div>
            </div>
          </Row>
          <div className={`collapse ${collapseOpen ? 'show' : ''}`} id='collapseExample'>
            <div className='add-file-background p-2 card card-body mb-4'>
              <Row>
                <div className={`collapse ${collapseOpen ? 'show' : ''}`} id='collapseExample'>
                  <Col xs={12} md={12}>
                    <Form.Group className='mb-2' controlId='exampleForm.ControlInput1'>
                      <Form.Label className={'d-flex mb-0'}>
                        {strings.description}
                      </Form.Label>
                      <textarea className={'w-100 form-control free-text'} onChange={handleChange}
                                name={'file_description'}/>
                    </Form.Group>
                  </Col>
                </div>
              </Row>
              <Row>
                <div className={`collapse ${collapseOpen ? 'show' : ''}`} id='collapseExample'>
                  <Col xs={12} md={12}>
                    <Form.Group className='mb-2' controlId='exampleForm.ControlInput1'>
                      <Form.Label className={'d-flex'}>
                        {strings.selectDocument}
                      </Form.Label>
                      <div id='form-file-upload w-75' onDragEnter={handleDrag}>
                        <input ref={inputRef} type='file' id='input-file-upload'
                               onChange={handleChangeFile}/>
                        <label id='label-file-upload' htmlFor='input-file-upload'
                               className={dragActive ? 'drag-active' : ''}>
                          <div className={'text-center pb-4 pt-4'}>
                            <p>{strings.dragAndDropYourFileHereOr}</p>
                            <div
                              className='upload-button mx-auto d-flex align-items-center justify-content-center text-center '>
                              <span className={'me-2'}>{strings.uploadFile}</span> <i
                              className={'material-icons'}>cloud_upload</i>
                            </div>
                            <div>{file.name}</div>
                          </div>
                        </label>
                        {dragActive && <div id='drag-file-element' onDragEnter={handleDrag}
                                            onDragLeave={handleDrag} onDragOver={handleDrag}
                                            onDrop={handleDrop}></div>}
                      </div>
                    </Form.Group>
                  </Col>
                </div>
              </Row>
            </div>
          </div>
          {param.id &&
            <>
              <Row>
                <div>
                  <div className={'d-flex align-items-center border-radius  mb-4'}>

                    <h4 className={'me-3 text-secondary'}> {strings.groups}</h4>
                    <hr className={'me-3'}/>
                    <AddButton
                      onClickHandle={handleShowEventGroupModal}
                      handleClose={handleCloseEventGroupModal}/>
                  </div>
                  {eventGroupsForSelectedEvent.map((eventGroup, index) => (
                    <EventGroupDetails
                      eventGroup={eventGroup}
                      key={index}
                      getAllEventGroups={getAllGroupsForEvent}
                      getEventDetails={getEventDetails}
                      onClickSetEventGroupForEdit={onClickSetEventGroupForEdit}/>
                  ))}
                </div>
              </Row>
            </>
          }
          <div className={'d-flex justify-content-end mb-3'}>
            <div className={'cancel btn text-white m-1'} onClick={handleCancel}>
              {strings.cancel}
            </div>
            <div>
              <button type={'submit'} className={'save btn text-white m-1'}>
                {strings.save}
              </button>
            </div>
          </div>
        </Form>
      </div>
      <SpinnerComponent spinnerShow={showSpinner}/>
      <AddOrEditEventGroup
        showModal={showEventGroupModal}
        handleClose={handleCloseEventGroupModal}
        event={selectedEvent}
        getEventDetails={getEventDetails}
        getAllEventGroups={getAllGroupsForEvent}/>
    </div>
  );
};

export default AddOrEditEvent;