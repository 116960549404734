import {strings} from '../../../localization/Localization';
import React, {useEffect, useState} from 'react';
import ProjectTechnologyService from '../../../repository/projectTechnologyRepository/projectTechnologyRepository';
import MyProjectsDetails from '../MyProjectModal/MyProjectDetailsModal';
import ProjectTechnologies from '../../AllProjects/ProjectTechnologies/ProjectTechnologies';

import './MyProjectsTerm.css';
import FormatDate from '../../../components/formatDate/FormatDate';
import ApplicationService from "../../../ApplicationService";

const MyProjectTerm = (props) => {
    const [showProjectDetailsModal, setShowProjectDetailsModal] = useState(false);
    const [selectedProjectDetails, setSelectedProjectDetails] = useState({});
    const [projectTechnologies, setProjectTechnologies] = useState([]);

    useEffect(() => {
        getAllProjectTechnologies();
    }, []);

    const getAllProjectTechnologies = () => {
        ProjectTechnologyService.getAllTechnologiesForProject(props.project.id)
            .then((response) => {
                setProjectTechnologies(response.data);
            }).catch((error) => {
            console.error('Error fetching project technologies:', error);
        });
    };

    const handleShowProjectDetailsModal = () => {
        setShowProjectDetailsModal(true);
    };

    const handleCloseProjectDetailsModal = () => {
        setShowProjectDetailsModal(false);
        setSelectedProjectDetails({});
    };

    return (
        <div className='col-4 card-width background-card' key={props.project.id}>
            <div className='card'>
                <div className='card-body'>
                    <div className='d-flex mb-2'>
                        <div className={"d-flex flex-column"}>
                            <h5 className='card-title fw-bold'>
                                {props.project.name}
                            </h5>
                            <div className={"fs-6"}>{ApplicationService.getActiveLanguage() === "mk" ?
                                props.project.typeNomenclatureEntry?.labelMk : props.project.typeNomenclatureEntry?.labelEn}</div>
                        </div>
                        <div className='ms-auto'>
                            {props.project.isActive ? (
                                <span className='badge text-bg-success ms-3'>{strings.Active}</span>
                            ) : (
                                <span className='badge text-bg-danger'>{strings.notActive}</span>
                            )}
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <img src='https://cdn-icons-png.flaticon.com/128/8644/8644515.png'
                             alt='Project'
                             className='col-3 image-width'/>
                        <p className='card-text col-9 small'>{props.project.description}</p>
                    </div>
                    <div className={"d-flex"}>
                        <div>
                            <div>
                                <p className={'mb-0'}>
                                    <i>{strings.dateStarted}:</i> {FormatDate.formatDate(props.project.dateBeginning)}
                                </p>
                                <p className={'mb-2'}>
                                    <i>{strings.dateEnded}:</i> {FormatDate.formatDate(props.project.dateEnding)} </p>
                            </div>
                            <div className={'d-flex'}><i>{strings.technologies}:</i>
                                {projectTechnologies.map((projectTechnology) => (
                                    <ProjectTechnologies technology={projectTechnology} key={projectTechnology.id}/>
                                ))}
                            </div>
                        </div>
                        <div className={"mt-auto ms-auto"}>
                            <a
                                className='btn btn-primary text-white text-end align-items-center px-2 py-1 add-button details-button'
                                onClick={() => {
                                    setSelectedProjectDetails(props.project);
                                    handleShowProjectDetailsModal();
                                }}>
                                {strings.details}
                            </a>
                        </div>
                    </div>
                    <MyProjectsDetails projectDetails={selectedProjectDetails}
                                       showModal={showProjectDetailsModal}
                                       handleClose={handleCloseProjectDetailsModal}/>
                </div>
            </div>
        </div>
    );

};

export default MyProjectTerm;
