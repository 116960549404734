import axios from "../../axios/axios";

const TimeTrackingRepository = {
  getAllTimeRecord: () => {
    return axios.get("/time_tracking/listAll");
  },

  addTimeRecord: (
    firstName,
    lastName,
    projectId,
    date,
    hours,
    minutes,
    isOvertime,
    description
  ) => {
    return axios.post("time_tracking/addTimeRecord", {
      firstName,
      lastName,
      projectId,
      date,
      hours,
      minutes,
      isOvertime,
      description,
    });
  },

  fetchTimeRecord: (id) => {
    return axios.get(`/time_tracking/getById/${id}`);
  },

  fetchAllTimeRecordByUser: () => {
    return axios.get(`/time_tracking/listAllLoggedUser`, {
      firstName: null,
      lastName: null,
      projectGroup: null,
      projectName: null,
      timeRecordDate: null,
    });
  },

  editTimeRecord: (
    id,
    userId,
    projectId,
    date,
    hours,
    minutes,
    isOvertime,
    description,
    
  ) => {
    return axios.post(`/time_tracking/updateTimeRecord/`, {
      id,
      userId,
      projectId,
      date,
      hours,
      minutes,
      isOvertime,
      description,
      
    });
  },

  deleteTimeRecord: (id) => {
    return axios.delete(`/time_tracking/delete/${id}`);
  },

  getAllPaginated: (
    data = {
      firstName: null,
      lastName: null,
      projectGroup: null,
      projectName: null,
      timeRecordDate: null,
    }
  ) => {
    return axios.post(`/time_tracking/getAllTimeRecordsPaginated/0/10`, data);
  },

  getAllPaginatedByUser: (
    data = {
      firstName: null,
      lastName: null,
      projectGroup: null,
      projectName: null,
      timeRecordDate: null,
    }
  ) => {
    return axios.post(`/time_tracking/getAllTimeRecordsPaginatedForUser/0/10`,data);
  },
};

export default TimeTrackingRepository;
