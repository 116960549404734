export const mk_strings = {
    signIn: "Најава",
    groupName: "Име на група",
    projectName: "Име на проект",
    selectProjectName: "Избери име на проект.",
    projectIsActive: "Активен",
    projectIsInactive: "Неактивен",
    myProjects: "Мои Проекти",
    projectStatus: "Статус",
    email: "Е-пошта",
    password: "Лозинка",
    embg: "ЕМБГ",
    profile: "Профил",
    logOut: "Одјава",
    name: "Име",
    lastName: "Презиме",
    fatherName: "Татково име",
    phoneNumber: "Телефонски број",
    professionalPhoneNumber: "Службен мобилен број",
    privateEmail: "Приватна е-пошта",
    address: "Адреса",
    roles: "Улога/и",
    calendar: "Календар",
    week: "Седмица",
    month: "Месец",
    addevent: "Додади настан",
    dateselected: "Селектиран датум",
    firstName: "Име",
    UserList: "Листа на корисници",
    addNewUser: "Додади нов корисник",
    editUser: "Промени корисник",
    close: "Затвори",
    cancel: "Откажи",
    save: "Зачувај",
    back: "Вратете се назад",
    register: "Регистрација",
    forgotPassword: "Заборавена лозинка?",
    resetPassword: "Промени лозинка",
    saveProfile: "Зачувај Профил",
    ethnicityList: "Етнички припадности",
    ethnicityGroup: "Етнички групи",
    ethnicityName: "Eтничка припадност",
    labelMk: "Македонски",
    labelEn: "Англиски",
    labelSq: "Албански",
    addNewEthnicity: "Додади етничка припадност",
    editEthnicity: "Измени етничка припадност",
    registrationFailed: "Регистрацијата е неуспешна, обидете се повторно",
    registrationSuccessful: "Регистрацијата е успешна, кликнете овде за да бидете пренасочени назад на страницата за најавa",
    backToLogin: "Назад до страната за логирање",
    accessRestrictedText: "Страницата на која се обидувате да пристапите има ограничен пристап.",
    referToSystemAdministrator: "Ве молиме обратете се на вашиот системски администратор",
    wrongCredentials: "Погрешно корисничко име или лозинка!",
    organizationalUnit: "Сектори",
    religionList: "Религии",
    addNewReligion: "Додадете нова религија",
    editReligion: "Изменете религија",
    minimizeThisMenu: "Минимизирајте мени",
    administration: "Администрација",
    users: "Корисници",
    religion: "Религија",
    ethnicity: "Етничка припадност",
    organisation: "Организации",
    leaveRequest: "Барања за отсуство",
    dateFrom: "Датум од",
    dateTo: "Датум до",
    dateOfIssue: "Датум на издавање",
    dateOfExpiry: "Важи до",
    issuedByHelper: "Издадено од",
    totalDays: "Вкупно денови...",
    correctedWorkDays: "Корегирани работни денови",
    leaveType: "Тип на отсуство",
    leaveRequestStatus: "Статус",
    requestALeave: "Пополни барање за отсуство",
    description: "Опис",
    day: "Внеси број на денови",
    sendRequest: "Испрати барање",
    january: "Јануари",
    february: "Февруари",
    march: "Март",
    april: "Април",
    may: "Мај",
    june: "Јуни",
    july: "Јули",
    august: "Август",
    september: "Септември",
    october: "Октомври",
    november: "Ноември",
    december: "Декември",
    sunday: "Недела",
    monday: "Понеделник",
    tuesday: "Вторник",
    wednesday: "Среда",
    thursday: "Четврток",
    friday: "Петок",
    saturday: "Сабота",
    addHoliday: "Додади Празник",
    editHoliday: "Измени Празник",
    delete: "Избриши",
    holiday: "Празник",
    holidays: "Празници",
    calendarOfHolidays: "Календар на празници",
    evidention: "Евиденција",
    userevidention: "Список на вработени",
    absenceCalendar: "Календар на отсуства",
    noHolidaysForSelectedDate: "Нема празници за избраниот датум",
    statuscalendar: "Календар со статуси",
    EmployeeList: "Список на вработени",
    remainingDays: "Преостанати денови",
    forYear: "Година",
    allowedDays: "Дозволени денови",
    successfulReligion: "Религијата успешно е додадена!",
    somethingWrong: "Настана проблем при извршување на посакуваната акција!",
    fileMightAlreadyExist: " Ве молиме проверете дали веќе постои прикачен документ со истото име.",
    oops: "Грешка...",
    fileIsSafe: "Нема никаква промена!",
    deleteConfirmation: "Дали навистина сакате да избришете?",
    deleteConfirmationNoQuestionMark: "Дали навистина сакате да го избришете корисникот:",
    areYouSure: "Дали сте сигурни?",
    deletedForever: "Ќе биде избришана засекогаш.",
    filter: "Филтер",
    noAbsenceForSelectedDate: "Нема отсутни за избраниот датум",
    absence: "Отсуства",
    type: "Тип",
    nationalType: "Национален тип",
    religionType: "Религиски тип",
    ethnicityType: "Етнички тип",
    home: "Почетна страна",
    calendarHistory: "Историја на календари",
    weAreSorry: "Недозволен пристап...",
    userForHoliday: "Корисници за избраниот празник",
    otherUser: "Останати корисници",
    noUserForSelectedHoliday: "Нема корисници за избраниот празник",
    noOtherUser: "Нема останати корисници",
    parentName: "Име на родител",
    yes: "Да",
    no: "Не",
    holidayRecurring: "Празникот се повторува секоја година",
    recentActivity: "Неодамнешни новости",
    enterDescription: "Внеси Опис...",
    tasks: "Задачи",
    organizationalHierarchy: "Организации",
    hierarchy: "Хиерархија",
    selectedHierarchy: "Селектирана хиерархија",
    selectedItemHierarchy: "Информација за селектираната хиерархија",
    enterValidateDate: "Внесете валиден датум",
    reasonsForLeaveRequest: "Причина за отсуство",
    add: "Додади",
    edit: "Уреди",
    copyCalendar: "Копирај календар",
    editLeaveRequest: "Измени барање за отсуство",
    approveRequest: "Прифати барање",
    returnRequest: "Врати на корекција",
    declineRequest: "Одбиј барање",
    responseDescription: "Опис на одговорот",
    myRequest: "Мои барања",
    deliverRequest: "Доставени барања",
    downloadFile: "Преземи фајл",
    enterDate: "Внесете датум",
    year: "Година",
    download: "Преземи",
    saveChange: "Зачувај промени",
    responseOnRequest: "Одговори на барање",
    responseIsSent: "Одговорот е пратен",
    fileDownloaded: "Фајлот е симнат",
    shortName: "Скратено име",
    activeFrom: "Активен од",
    activeTo: "Активен до",
    unknown: "Непознато",
    created: "Креирано",
    modified: "Последна промена",
    code: "Код",
    hierarchyName: "Име на хиерархијата",
    hierarchyDescription: "Опис на хиерархијата",
    hierarchyCode: "Код за хиерархијата",
    hierarchyShortName: "Скратено име за хиерархијата",
    addNewHierarchy: "Додади нова хиерархија",
    editHierarchy: "Уреди хиерархија",
    leaveRequests: "Барања за отсуство",
    newRequests: "Нови барања",
    otherRequests: "Останати барања",
    size: "Големина",
    search: "Пребарај",
    remove: "Отстрани",
    workingDays: "Работни денови",
    positionHierarchy: "Хиерархија на позиции",
    noMemberInPosition: "Нема припадници во позицијата",
    emptyList: "Листата е празна",
    membersOfThePosition: "Припадници во позицијата",
    addMembers: "Додади припадници",
    allowedAbsence: "Дозволени отсуства",
    usedDays: "Искористени денови",
    allowedAbsenceReview: "Преглед на дозволени отсуства",
    vacation: "Годишен одмор",
    absenceByReasons: "Отсуства според причина",
    hrmsIdNumber: "Идентификациски број",
    organisationHierarchy: "Хиерархија на организации",
    organisationList: "Организации",
    organisations: "Организации",
    selectOrganisation: "Селектирај организација",
    noOrganisationsToSelectFrom: "Нема организација за селекција. Додади почетна организација.",
    fromYear: "Година од",
    toYear: "Година до",
    editMember: "Измени припадници",
    requestsAwaitingResponse: "Барања кои што чекаат одговор",
    currentYear: "Тековна година",
    remainingYears: "Преостанати години",
    addInitialHierarchy: "Додади почетна хиерархија",
    selectHierarchy: "Селектирај хиерархија",
    usersToAddToPosition: "Припадници за додавање во позиција",
    usersToRemoveFromPosition: "Припадници за отстранување од позиција",
    editMemberInHierarchy: "Измени припадници во хиерархија",
    confirmRemoveUserFromHierarchy: "Дали навистина сакате да отстраните ",
    confirmRemoveUserFromHierarchyMemberNumber: "припадници од хиерархијата?",
    confirmAddUserToHierarchy: "Дали навистина сакате да додадете ",
    confirmAddUserToHierarchyMemberNumber: "припадници во хиерархијата?",
    theMailFurtherUsedForLoggingIntoTheSystem: "Мејлот понатаму ќе се користи за најава во системот!",
    nationalHoliday: "Национален Празник",
    ethnicityHoliday: "Етнички празник",
    religionHoliday: "Религиски празник",
    religionAndNationalHoliday: "Религиски и национален празник",
    removeMembers: "Отстрани припадници",
    confirmAddUserToHoliday: "Дали навистина сакате да додадете ",
    confirmAddUserToHolidayMemberNumber: " припадници на празникот?",
    confirmRemoveUserFromHoliday: "Дали навистина сакате да отстраните ",
    confirmRemoveUserFromHolidayMemberNumber: "припадници од празникот?",
    absencesByHoliday: "Отсуства според празник",
    notifications: "Нотификации",
    position: "Позиција",
    positions: "Позиции",
    organization: "Организација",
    absencesByDayOff: "Отсуства според слободен ден",
    viewRemainingAbsence: "Преглед на преостанати отсуства",
    emptyHierarchyList: "Листата на хиерархии е празна",
    usersToAddToHoliday: "Припадници за додавање во празник",
    usersToRemoveFromHoliday: "Припадници за отстранување од празник",
    settings: "Поставки",
    enableHierarchyEditing: "Овозможете уредување на хиерархија",
    doYouReallyWantToDelete: "Дали навистина сакате да го избришете празникот:",
    thisHolidayCannotBeRestored: "Овој празник нема да може да се врати во системот.",
    thisUserCannotBeRestored: "Овој корисник нема да може да се врати во системот.",
    holidayIsDeleted: "Празникот е избришан!",
    actionSuccessful: "Вашата акција е успешна.",
    doYouWantToSelectADeputy: "Дали сакате да изберете заменик?",
    selectDeputy: "Изберете заменик",
    all: "Сите",
    vacationn: "Одмор",
    userIsDeleted: "Корисникот е избришан!",
    welcomeToSystemTitle: "Добредојдовте на системот за управување со отсуствата на Интелигента",
    ethnicityDeleted: "Етничката припадност е избришана.",
    fieldsRequired: "Ве молиме пополнете ги сите задолжителни полиња.",
    required: "Задолжително!",
    userAddSuccessful: "Корисникот е успешно додаден",
    religionDeleted: "Религијата е избришана.",
    enableSync: "Овозможете синхронизација",
    accountNotRegistered: "Корисничката сметка не е регистрирана",
    passwordChangeMessageSuccessfully: "Успешно е испратена порака за промена на лозинката до вашата е-пошта",
    send: "Испрати",
    enterApprovalStatusRequired: "Ве молиме внесете статус и опис на одобрување.",
    emptyNotificationList: "Листата на известувања е празна",
    AbsenceByHoliday: "Отсуства според празник",
    workPosition: "Работно место",
    noInfo: "Нема информации",
    duplicateLeaveRequestException: "Веќе имате поднесено барање за отсуство во тој период!",
    noAvailableDays: "Немате преостанати денови!",
    noAvailableDaysForThisYear: "Нема достапни денови за оваа година!",
    noEnoughAvailableDays: "Немате доволно денови на располагање!",
    holidaysSuccessfullyCreated: "Празникот е успешно креиран. Продолжете кон додавање на корисници за креираниот празник.",
    holidaysSuccessfullyEdited: "Празникот е успешно изменет. Продолжете кон додавање на корисници за креираниот празник.",
    reset: "Пребриши",
    positionForOrganisation: "Позиции за дадената организација",
    noPositionForSelectedOrganisation: "Нема позиции за дадената организација",
    ok: "Во ред",
    selected: "Селектирани",
    userEditSuccessful: "Промената е успешно направена",
    alreadyHaveSickLeaveDuring: "Веќе имате боледување во тој период",
    userAlreadyHaveSickLeaveDuring: "Корисникот веќе има поднесено барање во тој период",
    defaultDescriptionForHavingSickLeaveDuring: "Вашето барање не може да биде прифатено поради тоа што имате боледување во тој период.",
    changePassword: "Промени лозинка",
    newPassword: "Нова лозинка",
    oldPassword: "Стара лозинка",
    confirmNewPassword: "Потврди нова лозинка",
    enterNewPassword: "Внесете нова лозинка...",
    enterOldPassword: "Внесете стара лозинка...",
    enterConfirmNewPassword: "Потврди нова лозинка",
    savePassword: "Зачувај",
    passwordsMatch: "Лозинките се совпаѓаат",
    passwordsDoNotMatch: "Лозинката не се совпаѓа",
    passwordsConfirmationDoNotMatch: "Потврдата на вашата лозинка не се совпаѓа со вашата лозинка!",
    passwordChangeSuccessful: "Лозинката е успешно променета!",
    incorrectPassword: "Вашата лозинка е невалидна",
    noInfoForSelectedLeaveType: "Нема податоци за избраниот тип на отсуство",
    blockUnblockUser: "Блокирај/Одблокирај корисник",
    contact: "Контакт",
    parent: "Родител",
    personalData: "Лични податоци",
    professionalData: "Професионални податоци",
    gender: "Пол",
    male: "Машки",
    female: "Женски",
    other: "Останато",
    activeAddress: "Активна адреса",
    secondAddress: "Втора адреса",
    city: "Град",
    municipality: "Општина",
    settlement: "Населба",
    typesOfAbsences: "Типови на отсуства",
    editLeaveType: "Уреди тип на отсуство",
    baseDays: "Основни денови",
    editLeaveReasons: "Уреди причини за отуства",
    active: "Активна",
    thisLeaveReasons: "Оваа причина за отсуство",
    alreadyExistRequestForThisReasons: "Веќе постои барање за отсуство за оваа причина.",
    alreadyExistRequestForThisType: "Веќе постои барање за отсуство од овој тип.",
    alreadyExistLeaveReasonForThisType: "Постои отсуство според причина од овој тип.",
    addLeaveType: "Додади тип на отсуство",
    addLeaveReasons: "Додади причина за отсуство",
    addOrganisation: "Додади организација",
    addNewOrganisationFromParent: "Додади нова организација во рамките на ",
    parentOrganisation: "Родител организација",
    editOrganisation: "Уреди организација",
    organisationName: "Име на организација",
    organisationShortName: "Скратено име на организација",
    organisationDescription: "Опис на организација",
    organisationCode: "Код на организација",
    addPosition: "Додади позиција",
    addPositionFromParent: "Додади позиција во рамките на ",
    parentPosition: "Родител позиција",
    editPosition: "Уреди позиција",
    positionName: "Име на позиција",
    positionShortName: "Скратено име на позиција",
    positionDescription: "Опис на позиција",
    positionCode: "Код на позиција",
    birthDate: "Датум на раѓање",
    date: "Датум",
    dayDate: "Ден",
    organizationApplies: "Организација на која што припаѓа",
    addDocument: "Додади документ",
    documentType: "Тип на документот",
    documentLanguage: "Јазик на документот",
    selectDocument: "Одбери документ",
    documentDate: "Датум на документот",
    chooseFile: "Избери документ",
    document: "Документ",
    certificate: "Сертификат",
    certificates: "Сертификати",
    personalDocuments: "Лични документи",
    documentNumber: "Број на документ",
    addPersonalDocument: "Додади личен документ",
    issuedBy: "Издаден од",
    nomenclature: "Номенклатури",
    selectNomenclature: "Селектирај номенклатура",
    value: "Вредност",
    addNomenclatureEntry: "Додади запис за номенклатура",
    editNomenclatureEntry: "Уреди запис за номенклатура",
    addNomenclature: "Додади ноемнклатура",
    editNomenclature: "Уреди номенклатура",
    nomenclatureName: "Име на номенклатура",
    uploadFile: "Прикачете документ",
    dragAndDropYourFileHereOr: "Повлечете и пуштете ја вашата датотека овде или",
    education: "Образование",
    institution: "Институција",
    direction: "Насока",
    acknowledged: "Признато",
    addEducation: "Додади образование",
    editEducation: "Уреди образование",
    startDate: "Датум на почнување",
    endDate: "Датум на завршување",
    educationLevel: "Ниво на образование",
    youHaveNoDaysLeftDueToPendingRequests: "Немате преостанати денови поради поднесени барања кои што чекаат одговор",
    present: "Активно",
    identityCard: "Лична карта",
    passport: "Пасош",
    actionListEmpty: "Листата на акции е празна",
    actionHistory: "Историја на акции за барањето",
    dateCreated: "Датум на креирање",
    leaveReason: "Причина за отсуство",
    area: "Област",
    bank: "Банка",
    bankAccount: "Број на сметка",
    bankAccountInfo: "Податоци за банкарска сметка",
    bankFieldRequired: "Пополнете го полето за банка",
    key: "Клуч од канцеларија",
    accountNumber: "Број на сметка",
    office: "Канцеларија",
    employmentData:"Работодавен статус",
    addUserEmployment:"Додади работодавен статус",
    editUserEmployment: "Промени работодавен статус",
    engagementType:"Тип на ангажман",
    contractType:"Тип на договор",
    employmentType:"Тип на вработување",
    internal:"Внатрешни",
    external:"Надворешни",
    intern:"Практикант",
    definite:"Определено",
    indefinite:"Неопределено",
    fullTime:"Полно работно време",
    partTime:"Скратено работно време",
    hours:"Часа",
    hoursDailyEngagement:"Часови на дневен ангажман",
    noOptionsCurrentlyPresent:"Во моментов нема достапни опции за системските поставки",
    notNotificationsPresent:"Нема моментални известувања",
    events: "Обуки",
    allEvents: "Сите обуки",
    eventsList: "Листа на обуки",
    capacity: "Капацитет",
    activeEvents: "Активни обуки",
    inactiveEvents: "Неактивни обуки",
    inactiveEventMessage: "Неактивна",
    addEvent: "Додади обука",
    eventType: "Тип на обука",
    status: "Статус",
    cost: "Износ",
    currency: "Валута",
    trainer: "Обучувач",
    dollars: "долари",
    euros: "евра",
    pounds: "фунти",
    denars: "денари",
    activeEvent: "Активен",
    inactiveEvent: "Неактивен",
    groups: "Термини",
    eventDetails: "Детали за обуката",
    noGroupsAvailable: "Нема термини за оваа обука",
    editTheEvent: "Промени ја обуката",
    location: "Локација",
    addEventGroup: "Додади термин за обука",
    attend: "Пријави се",
    attendConfirmation: "Дали сакате да се пријавите во овој термин?",
    attendCancelConfirmation: "Дали сакате да откажете присуство?",
    emptyEventGroup: "Нема пријавени учесници",
    usersAttending: "Пријавени: ",
    vacancies: "Слободни места: ",
    listOfParticipants: "Листа на учесници",
    statusPlaceholder: "Вредноста се заснова на крајниот датум",
    noEvents: "Нема обуки",
    noInactiveEvents: "Нема неактивни обуки",
    noActiveEvents: "Нема активни обуки",
    proposalName: "Предлог име",
    eventProposals: "Предлози за обуки",
    noEventProposals: "Нема предлози за обуки",
    describeProposal: "Опишете ја вашата идеја за обука. Можете да препорачате обучувач, платформа, времетраење итн...",
    userEventProposal: "Од вработен: ",
    accepted: "Прифатено",
    notAccepted: "Одбиено",
    pending: "Се процесира",
    addEventProposal: "Додади предлог за обука",
    myEventProposals: "Мои предлози за обука",
    noMyEventProposals: "Нема предлог обуки од Вас",
    acceptEventProposal: "Прифаќање на предлог за обука",
    eventProposalFrom: "Предложено од: ",
    accept: "Прифати",
    deny: "Одбиј",
    attendedEvents: "Обуки",
    userEventProposals: "Предлози за обука од ",
    eventsAttendedFromUser: "Обуки на кои присуствува/л корисникот ",
    myAttendedEvents: "Обуки на кои присуствувам/присуствував",
    projects: "Проекти",
    allProjectsList: "Листа од сите проекти",
    notActive: "неактивен",
    Active: "активен",
    dontHaveProjects: "Немате додадено проекти",
    dateStarted: "Започнато на",
    dateEnded: "Завршено на",
    details: "Детали",
    technologies: "Технологии",
    EmptyProjectsList: "Листата од проекти е празна",
    projectDetails: "Детали за проектот",
    files: "Фајлови",
    dateModified: "Последна промена",
    isPrivate: "Приватно",
    fileType: "Тип на фајл",
    workExperience:"Работно искуство",
    dateRegistered:"Датум на заверување",
    workExperiencePosition:"Работна позиција",
    duration:"Времетраење",
    durationIn:"Времетраење во",
    years:"Години",
    months:"Месеци",
    fromOurCompany:"Од нашата компанија",
    inOurCompany: "во нашата компанија",
    addWorkExperience:"Додади работно искуство",
    editWorkExperience:"Уреди работно искуство",
    companyName:"Име на компанија",
    total:"Вкупно",
    fileName: "Име на фајл",
    projectType: "Тип на проект",
    dateBeginning: "Датум на почеток",
    dateEnding: "Датум на завршување",
    projectGroup: "Група на проекти",
    projectTechnology: "Технологии за проект",
    addProject: "Додади проект",
    editProject: "Измени проект",
    isActive: "Активен",
    fromInteligenta:"Од Интелигента",
    selectDate:"Одберете датум",
    totalExperienceUntil:"Вкупно работно искуство до",
    days:"Денови",
    overallTotalExperienceUntil:"Севкупно работно искуство до",
    inteligentaTotalExperienceUntil:"Работно искуство во Интелигента до",
    started:"Започнување",
    ended:"Завршување",
    calculateExperiece:"Пресметај работно искуство",
    employeesOnProject: "Вработени на проектот  ",
    myProjectsList: "Мои Проекти",
    groupList: "Листа на групи",
    projectsInGroup: "Проекти во групата",
    numberOfProjectsInGroup: "Број на проекти",
    addGroup: "Додади група",
    editGroup: "Измени група",
    filterName: "Филтрирај по име ...",
    filterParent: "Филтрирај по име на родител ...",
    filterSurname: "Филтрирај по презиме ...",
    filterIdentificationNumber: "Филтрирај по идентификациски број ...",
    filterEmail: "Пример: example@gmail.com",
    noFilesInEvent: "Нема документи за оваа обука",
    userProjects: "Проекти за ",
    benefits: "Бенефиции",
    benefitsList: "Листа на бенефиции",
    readMore:"Прочитај повеќе",
    readLess:"Прочитај помалку",
    inactive:"Неактивна",
    editUserBenefit: "Уреди бенефиција",
    addUserBenefit: "Додади бенефиција",
    benefitDetails: "Детали за бенефицијата",
    benefitUsersList: "Листа на корисници на бенефицијата",
    chooseUser:"Одбери корисник",
    benefit:"Бенефиција",
    addUserToBenefit:"Додади корисник на бенефиција",
    myBenefits: "Мои бенефиции",
    userBenefits: "Бенефиции на ",
    noBenefits: "Нема записи за бенефиции",
    eventSkills: "Вештини кои се стекнуваат со обуката",
    closeAddUserToBenefit:"Затвори ја секцијата за додавање",
    pleaseEnterLeaveType:'Ве молиме внесете тип на отсуство!',
    codeLimitMessage: "Кодот може да содржи само големи букви, бројки и симболи и не треба да содржи празни места.",
    listOfLeaveTypeIsEmptyForAddLeaveReasons:'Листата со типови на отсуства е празена. За да додадете причини за отсуство, ве молиме додајте тип на отсуство',
    timeTracking: "Активност",
    myTimeTracking: "Мои активности",
    allUsersTimeTracking: "Сите активности",
    enterTime: "Внесете време",
    Hours: "Часови",
    Minutes: "Минути",
    enterHours: "Внесете часови",
    enterMinutes: "Внесете минути",
    addTimeRecord: "Додадете активност",
    editTimeRecord: "Променете активност",
    deleteConfirmationTimeRecord: "Дали навистина сакате да ја избришете селектираната активност",
    timeRecordDeleted: "Активноста е избришана",
    addEmployee:"Додади вработен",
    addEmployeeToProject: "Додади вработен на проект",
    selectEmployee: "Одбери вработен",
    employee: "Вработен",
    noEmployeesOnProject: "Нема вработени на проектот",
    projectCreatedSuccessfully: "Проектот е успешно креиран",
    projectAddedInfo: "Ако сакате да продолжите со додавање на вработени на проектот продолжете со ажурирање на проектот",
    showInactive: "Прикажи ги и неактивните",
    isOvertime: "Прекувремено",
    feedback: "Пофалби/Жалби",
    title: "Наслов",
    addFeedback: "Додади пофалба/жалба",
    deleteConfirmationFeedback: "Дали навистина сакате да го избришете записот",
    feedbackDeleted: "Податокот е избришан",
    feedbackType: "Тип на порака",
    feedbackPraise: "Пофалба",
    feedbackComplain: "Жалба",
    feedbackProposal: "Предлог",
    feedbackOther: "Останато",
    marks: "Ознаки",
    equipment: "Опрема",
    myEquipment: "Моја Опрема",
    allEquipment: "Цела Опрема",
    addEquipment: "Додади Опрема",
    addBenefit: "Додади Бенифиција",
    addBenefitProposal: "Додади Предлог Бенифиција",
    allBenefits: "Сите Бенефиции",
    myBenefitList: "Листа на мои бенефиции",
    noBenefitProposals: "Нема активни прелози за бенефиции",
    myBenefitProposals: "Мои Предлози за бенифиција",
    selectPlaceholder: "Селектирај...",
    allBenefitProposals: "Сите предлози за бенифиција",
    explainBenefitProposal: "Опишете ја вашата идеја за бенефиција. Можете да препорачате паркинг место, спортска картичка итн...",
    userComment: "Коментар од корисникот",
    userCommentNotProvided: "Корисникот сеуште нема оставено коментар за бенифицијата.",
    leaveCommentHere: "Оставете го вашиот коментар во врска со бенифицијата тука",
    noUsersOnBenefit: "Ниеден вработен ja нема оваа бенефиција",
    addComment: "Додади коментар",
    enterYourComment: "Внесете го вашиот коментар тука",
    addUserToEquipment: "Додади корисник на опрема",
    addEquipmentProposal: "Побарај опрема",
    equipmentProposalName: "Име на опрема",
    equipmentDescription: "Опис на опремата",
    equipmentProposals: "Барања за опрема",
    noActiveEquipmentProposals: "Нема активни барања за опрема",
    equipmentQuantity: "Количина",
    selectOption: "Селектирај...",
    noOptions: "Нема опции...",
    dateAcquired: "Датум на стекнување",
    quantity: " Количина",
    setQuantity: "Одберете количина",
    myEquipmentProposals: "Мои барања за опрема",
    noEquipmentEntries:"Не постојат записи за опрема",
    equipmentProposalDetails: "Детали за поднесено барање за опрема",
    acceptEquipmentConfirmation: "Дали навистина сакате да го прифатите ова барање за опрема?",
    denyEquipmentConfirmation: "Дали навистина сакате да го одбиете ова барање за опрема?",
    organizationalStructure: " Организациски Структури",
    positionsHierarchy: "Хиерархија на позиции",
    personal: "Персонал",
    listOfUsers: "Список на вработени",
    userAbsenceEvidention: "Евиденција на отсуства",
    timeTrackings: "Активности",
    allRequiredFieldsNotFilled:"Сите задолжителни полиња не се пополнети",
    projectFiles: "Фајлови за проектот",
    editEmployeesOnProject: "Измени ги вработените на проектот",
    onlyNumbers: "Внесете само броеви",
    emailVerificationWarning: "Ве молиме внесете валиден емаил. Пр. example@example.com",
    role: "Улога",
    dateJoined: "Започнал",
    dateLeft: "Завршил",
    groupOfProjects: "Група проекти",
    isForeignCitizen: "Странски државјанин",
    
};