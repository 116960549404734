import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EthnicityRepository from "../../../repository/ethnicityRepository/EthnicityRepository";
import { strings } from "../../../localization/Localization";
import InputLimit from "../../../components/InputLimit/InputLimit";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const EthnicityModal = (props) => {
  const history = useHistory();

  const [formData, updateFormData] = React.useState({
    id: "",
    name: "",
    label_MK: "",
    label_SQ: "",
    label_EN: "",
  });

  useEffect(() => {
    resetFormData();
  }, [props.showModal]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (e.target.hasAttribute("required") && value.trim() === "") {
      e.target.setCustomValidity(strings.fieldsRequired);
    } else {
      e.target.setCustomValidity("");
    }

    if (name === "name") {
      const pattern = /^[A-Z0-9!@#$%^&*()_+{}\[\]:;"'<>,.?~]+$/;
      if (value === "" || pattern.test(value)) {
        updateFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      updateFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    if (props.showModal) {
      if (props.selectedEthnicityForEdit.id) {
        updateFormData({
          id: props.selectedEthnicityForEdit?.id,
          name: props.selectedEthnicityForEdit.name,
          label_MK: props.selectedEthnicityForEdit.label_mk,
          label_EN: props.selectedEthnicityForEdit.label_en,
          label_SQ: props.selectedEthnicityForEdit.label_sq,
        });
      }
    }
  }, [props.showModal]);

  const resetFormData = () => {
    updateFormData({
      id: "",
      name: "",
      label_MK: "",
      label_SQ: "",
      label_EN: "",
    });
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const addEthnicity = (name, label_mk, label_sq, label_en) => {
    EthnicityRepository.addEthnicity(name, label_mk, label_sq, label_en)
      .then((data) => {
        successfulAlert();
        props.handleClose();
      })
      .catch(() => {
        warningAlert();
      });
  };

  const editEthnicity = (id, name, label_mk, label_sq, label_en) => {
    EthnicityRepository.editEthnicity(id, name, label_mk, label_sq, label_en)
      .then(() => {
        successfulAlert();
        props.handleClose();
      })
      .catch(() => {
        warningAlert();
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const id =
      formData.id !== "" ? formData.id : props.selectedEthnicityForEdit.id;
    const name =
      formData.name !== ""
        ? formData.name
        : props.selectedEthnicityForEdit.name;
    const label_mk =
      formData.label_MK !== ""
        ? formData.label_MK
        : props.selectedEthnicityForEdit.label_mk;
    const label_sq =
      formData.label_SQ !== ""
        ? formData.label_SQ
        : props.selectedEthnicityForEdit.label_sq;
    const label_en =
      formData.label_EN !== ""
        ? formData.label_EN
        : props.selectedEthnicityForEdit.label_en;
    if (!props.isEdit) {
      addEthnicity(name, label_mk, label_sq, label_en);
    } else {
      editEthnicity(id, name, label_mk, label_sq, label_en);
    }
  };

  return (
    <Modal
      show={props.showModal}
      dialogAs={DraggableModalDialog}
      onHide={props.handleClose}
      size={"lg"}
      centered
    >
      <Modal.Header closeButton>
        {!props.isEdit && <Modal.Title>{strings.addNewEthnicity}</Modal.Title>}
        {props.isEdit && <Modal.Title>{strings.editEthnicity}</Modal.Title>}
      </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <h5>{strings.code}<span className="text-danger">*</span></h5>
            {InputLimit.codeLimitMessage()}
            <Form.Control
              type="text"
              autoFocus
              value={formData?.name}
              required
              onChange={handleChange}
              name="name"
              onInvalid={(e) => {
                e.target.setCustomValidity(strings.fieldsRequired);
              }}/>
          </Form.Group>
          <h5 className={"my-4"}>{strings.marks}</h5>
          <Row className={"px-2"}>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3 required" controlId="label_mk">
                <Form.Label> {strings.labelMk}<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={formData?.label_MK}
                  name="label_MK"
                  onChange={handleChange}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="label_sq">
                <Form.Label>{strings.labelSq}</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  value={formData?.label_SQ}
                  name="label_SQ"
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="label_en">
                <Form.Label>{strings.labelEn}<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={formData?.label_EN}
                  onChange={handleChange}
                  name="label_EN"
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className={"cancel btn text-white"} onClick={props.handleClose}>
            {strings.cancel}
          </div>
          <button type={"submit"} className={"save btn text-white"}>
            {strings.save}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EthnicityModal;
