import React, {useEffect, useState} from "react";
import "./HomeDesign.css";
import {strings} from "../localization/Localization";
import NotificationRepository from "../repository/notificationRepository/NotificationRepository";
import {Link} from "react-router-dom";
import ApplicationService from "../ApplicationService";
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";
import ReactPaginate from "react-paginate";

const HomeNotificationComponent = () => {

    const [notifications, setNotifications] = useState([]);

    const [page, setPage] = useState(0);

    const [size, setSize] = useState(10);

    const [pageCount, setPageCount] = useState();

    useEffect(() => {
        getAllNotificationForUser(page, size);
    }, []);

    useEffect(() => {
        getAllNotificationForUser(page, size);
    }, [page]);

    const getAllNotificationForUser = (page, size) => {
        NotificationRepository.fetchAllNotificationForLoggedUserPaginated(page, size)
            .then((data) => {
                setPageCount(data.data.totalPages)
                setNotifications(data.data.content)
            })
    };

    const handlePageClick = (data) => {
        let selected = data.selected;
        setPage(selected);
    }

    const dateFormat = (notification) => {
        const dateCreated = notification?.dateCreated.slice(0, 10)
        return dateCreated;
    };

    const getColorClass = (notification) => {
        if (notification.name === 'Approved') {
            return 'approved_notification';
        } else if (notification.name === 'New') {
            return 'new_notification';
        } else if (notification.name === 'Declined') {
            return 'declined_notification';
        } else if (notification.name === 'Returned for correction') {
            return 'returned_notification';
        } else if (notification.name === 'Corrected') {
            return 'corrected_notification';// ili ova ili text-warning corrected_notification
        } else if (notification.name === 'Between approvals') {
            return 'between_notification';
        }
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-9 w-100">
                        <div className="box shadow-sm border rounded bg-white mb-2">
                            <div
                                className="box-title d-flex border-bottom title_text home_notification_header pt-2 pb-2 mx-2 justify-content-center">
                                <div className={"d-flex align-items-center  text-center"}>
                                    <h3 className={"mb-0"}> {strings.recentActivity}</h3>
                                </div>
                            </div>
                            <div className="box-body p-0 overflow-auto borderbox">
                                {
                                    notifications.length > 0 ?
                                        (notifications.map((notification) => {

                                            return (
                                                (notification.name === "New" || notification.name === "Corrected") ?
                                                    (<Link key={notification.id} to={{
                                                        pathname: "/arrivedRequest",
                                                        state: {"LeaveRequest": notification.leaveReqeustId}
                                                    }}
                                                           className={"d-flex align-items-center content osahan-post-header border-bottom prefs notification"}>
                                                        <div className={"w-100"}>
                                                            <div
                                                                className="font-weight-bold mr-3 p-2 d-flex w-100">
                                                                <div
                                                                    className={`me-2 pt-1 ${getColorClass(notification)}`}>
                                                                    <i
                                                                        className={"material-icons-round"}>mail</i>
                                                                </div>
                                                                <div
                                                                    className={`d-flex justify-content-between pe-10 w-75 pt-1 ${getColorClass(notification)}`}>
                                                                    {
                                                                        ApplicationService.getActiveLanguage() == "mk" ?
                                                                            (
                                                                                <b>{notification.label_mk}</b>
                                                                            ) : (
                                                                                <b>{notification.label_en}</b>
                                                                            )
                                                                    }
                                                                    <br/>

                                                                </div>
                                                                <div
                                                                    className={"d-flex justify-content-end w-100 home_text me-2"}>
                                                                    <div>
                                                                        <b>{dateFormat(notification)}</b>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div
                                                                className={"ps-4 ms-3 w-75 pb-3 home_text"}>{notification.title}</div>
                                                            <span className="actionbtn">
                                    </span>
                                                        </div>
                                                    </Link>)
                                                    : (<Link key={notification.id} to={{
                                                        pathname: `/leaveRequests`,
                                                        state: {"LeaveRequest": notification.leaveReqeustId}
                                                    }}
                                                             className="align-items-center osahan-post-header d-flex border-bottom w-100 prefs">
                                                        <div className={"w-100"}>
                                                            <div
                                                                className="font-weight-bold mr-3 p-2 d-flex w-100">
                                                                <div className={`me-2 pt-1 ${getColorClass(notification)}`}>
                                                                    <i
                                                                        className={"material-icons-round"}>mail</i></div>
                                                                <div
                                                                    className={`d-flex justify-content-between pe-10 w-75 pt-1 ${getColorClass(notification)}`}>
                                                                    {
                                                                        ApplicationService.getActiveLanguage() == "mk" ?
                                                                            (
                                                                                <b>{notification.label_mk}</b>
                                                                            ) : (
                                                                                <b>{notification.label_en}</b>
                                                                            )
                                                                    }
                                                                    <br/>

                                                                </div>
                                                                <div
                                                                    className={"d-flex justify-content-end w-100 home_text me-2"}>
                                                                    <div>
                                                                        <b>{dateFormat(notification)}</b>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div
                                                                className={"ps-4 ms-3 w-75 pb-3 home_text"}>{notification.title}</div>

                                                            <span className="actionbtn">
                                    </span>
                                                        </div>
                                                    </Link>)
                                            )
                                        }))
                                        : (
                                            <div className={"text-center  py-3"}>
                                                <h4 className={"text-secondary"}><i>{strings.emptyNotificationList}</i></h4>
                                            </div>

                                        )
                                }
                            </div>
                            {
                                notifications.length > 0 &&
                                <ReactPaginate previousLabel={<AiOutlineArrowLeft/>}
                                               nextLabel={<AiOutlineArrowRight/>}
                                               breakLabel={"..."}
                                               pageCount={pageCount}
                                               marginPagesDisplayed={2}
                                               pageRangeDisplayed={5}
                                               selectedPageRel={page}
                                               forcePage={page}
                                               onPageChange={handlePageClick}
                                               breakClassName={"page-item"}
                                               breakLinkClassName={"page-link"}
                                               containerClassName={"pagination h-100 m-0 justify-content-center"}
                                               pageClassName={"page-item"}
                                               pageLinkClassName={"page-link"}
                                               previousClassName={"page-item"}
                                               previousLinkClassName={"page-link"}
                                               nextClassName={"page-item"}
                                               nextLinkClassName={"page-link"}
                                               activeClassName={"active"}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default HomeNotificationComponent;