import ReactPaginate from "react-paginate";
import React, {useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/collapse.js";
import BootstrapTable from 'react-bootstrap-table-next';
import DeliveredRequestModal from "./DeliveredRequestModal/DeliveredRequestModal"
import LeaveRequestRepository from "../../../repository/leaveRequestRepository/LeaveRequestRepository";
import '../MyLeaveRequest/LeaveRequestList/LeaveRequest.css';
import {useHistory, useLocation} from "react-router-dom";
import {
    AiOutlineArrowLeft,
    AiOutlineArrowRight,
    AiOutlineExpand,
    AiOutlineExpandAlt,
    AiOutlineShrink
} from "react-icons/ai";
import {strings} from "../../../localization/Localization";
import swal from "sweetalert";

const DeliveredRequestList = (props) => {

    const [showModal, setShowModal] = useState(false)

    const [allLeaves, setAllLeaves] = useState([]);

    const [newLeaves, setNewLeaves] = useState([]);

    const [otherLeaves, setOtherLeaves] = useState([]);

    const [pageCountForNewRequest, setPageCountForNewRequest] = useState();

    const [pageCountForOtherRequest, setPageCountForOtherRequest] = useState();

    const [pageForNewRequest, setPageForNewRequest] = useState(0);

    const [sizeForNewRequest, setSizeForNewRequest] = useState(5);

    const [pageForOtherRequest, setPageForOtherRequest] = useState(0);

    const [sizeForOtherRequest, setSizeForOtherRequest] = useState(5);

    // const [offset, setOffset] = useState(0);

    //const [nextPageOffset, setNextPageOffset] = useState(0);

    const [selectedLeaveRequestForEdit, setSelectedLeaveRequestForEdit] = useState({})

    const history = useHistory();

    const location = useLocation()

    useEffect(() => {
        if (location.state) {
            getLeaveRequestById(location.state.LeaveRequest);
        }
    }, [location.state])

    useEffect(() => {
        getAllNewLeaveRequest();
    }, [pageForNewRequest]);

    useEffect(() => {
        getAllOtherLeaveRequest();
    }, [pageForOtherRequest]);


    const handleShow = () => {
        getAllOtherLeaveRequest();
        getAllNewLeaveRequest();
        setShowModal(true);
    };

    const handleClose = () => {
        setSelectedLeaveRequestForEdit({});
        setShowModal(false);
    }

    const getAllLeaveRequests = () => {
        getAllNewLeaveRequest();
        getAllOtherLeaveRequest();
    }

    const getLeaveRequestById = (id) => {
        LeaveRequestRepository.getLeaveRequestById(id)
            .then((data) => {
                setSelectedLeaveRequestForEdit(
                    data.data
                )
            })
        handleShow();
    }

    const getAllNewLeaveRequest = () => {
        LeaveRequestRepository.getAllNewLeaveRequestsPaginated(pageForNewRequest, sizeForNewRequest).then((data) => {
            if(data.data.content.length ===0 && pageForNewRequest!==0){
                setPageForNewRequest(pageForNewRequest-1);
            }else{
            setPageCountForNewRequest(data.data.totalPages)
            setNewLeaves(data.data.content);
            }
        })
    };

    const getAllOtherLeaveRequest = () => {
        LeaveRequestRepository.getAllOtherLeaveRequestsPaginated(pageForOtherRequest, sizeForOtherRequest).then((data) => {
            setPageCountForOtherRequest(data.data.totalPages)
            var otherRequest = data.data.content.filter(r => r.leaveRequestStatus.name !== "NEW");
            setOtherLeaves(otherRequest);
        })
    };

    const handlePageClickForNewRequest = (data) => {
        let selected = data.selected;
        setPageForNewRequest(selected);
    };

    const handlePageClickForOtherRequest = (data) => {
        let selected = data.selected;
        setPageForOtherRequest(selected);
    };

    const formatDate = (date) => {
        if (date == null) {
            return '';
        }
        var d = new Date(date);
        var dateString = d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear()
        return dateString;
    };

    const deleteConfirmAlert = (id) => {
        swal({
            title: "Are you sure?",
            text: "Do you really want to delete " + ". \n  This leave request cannot be restored.",
            icon: "warning",
            buttons: [strings.cancel,strings.ok],
        }).then((willSuccess) => {
            if (willSuccess) {
                deleteLeaveRequest(id);
            } else {
            }
        });
    };

    const deleteLeaveRequest = (id) => {
        LeaveRequestRepository.deleteLeaveRequest(id)
            .then((data) => {
                swal("Leave Request is deleted!", {
                    icon: "success",
                });
                getAllNewLeaveRequest();
                getAllOtherLeaveRequest();
            });
    }

    const columns = [
        {
            dataField: 'userHelper.firstName',
            text: strings.name,
            sort: true,

        },
        {
            dataField: 'userHelper.parentName',
            text: strings.parentName,
            sort: true,

        },
        {
            dataField: 'userHelper.lastName',
            text: strings.lastName,
            sort: true,

        },
        {
            dataField: 'dateFrom',
            text: strings.dateFrom,
            sort: true,
            formatter: (cell, row) => {
                return <span>{formatDate(cell)}</span>
            }
        },
        {
            dataField: 'dateTo',
            text: strings.dateTo,
            sort: true,
            formatter: (cell, row) => {
                return <span>{formatDate(cell)}</span>
            }
        },
        {
            dataField: 'totalDays',
            text: strings.totalDays,
            sort: true,
        },
        {
            dataField: 'correctedWorkDays',
            text: strings.workingDays,
            sort: true
        },
        {
            dataField: 'leaveReason.labelMk',
            text: strings.reasonsForLeaveRequest,
            sort: true
        },
        {
            dataField: 'leaveRequestStatus.label_mk',
            text: strings.leaveRequestStatus,
            sort: true,
            formatter: (cell, row) => {
                if (cell == "Ново") {
                    return <span className={"new_notification"}>{cell}</span>
                } else if (cell == "Одбиено") {
                    return <span className={"declined_notification"}>{cell}</span>
                } else if (cell == "Одобрено") {
                    return <span className={"approved_notification"}>{cell}</span>
                } else if (cell == "Вратено за корекција") {
                    return <span className={"returned_notification"}>{cell}</span>
                } else if (cell == "Корегирано") {
                    return <span className={"corrected_notification"}>{cell}</span>
                }
            }
        },
        {
            formatter: (cell, row) => {
                // if (row.leaveRequestStatus.name === "RETURNED FOR CORRECTION" || row.leaveRequestStatus.name === "DECLINED") {
                    return <div className={'icon-div mx-3'}>
                        <a
                            onClick={() => {
                                setSelectedLeaveRequestForEdit(row);
                                handleShow();
                            }}
                            title={'Edit'}
                            className={'text-dark icon'}>
                            <i className={"edit-icon-circle material-icons "}>
                                create
                            </i>
                        </a>
                    </div>
                // }
            }
        }
    ];

    const expandRow = {
        className: 'expanding-foo',
        onlyOneExpanding: true,
        renderer: row => (
            <div className={"d-flex"}>
                <div className={"block-description w-75"}>
                    <div className={"text-start"}>
                        <p style={{margin: "1px", marginLeft: "25px", fontFamily: "Cambria"}}>
                            <b>{strings.leaveType}: </b> {row.leaveType.label_mk}</p>
                    </div>
                    <div className={"text-start"}>
                        <p style={{margin: "1px", marginLeft: "25px", fontFamily: "Cambria"}}>
                            <b>{strings.reasonsForLeaveRequest}: </b> {row.leaveReason.labelMk}</p>
                    </div>
                    <div className={"text-start"}>
                        <p style={{margin: "1px", marginLeft: "25px", fontFamily: "Cambria"}}>
                            <b>{strings.description}: </b>{row.description}</p>
                    </div>
                </div>
                <div className={"w-25 d-flex mx-5 text-end justify-content-end align-items-center"}>
                </div>

            </div>
        ),
        showExpandColumn: true,
        expandHeaderColumnRenderer: ({isAnyExpands}) => {
            if (isAnyExpands) {
                return <b><AiOutlineShrink/></b>;
            }
            return <b><AiOutlineExpand/></b>;
        },
        expandColumnRenderer: ({expanded}) => {
            if (expanded) {
                return (
                    <b><AiOutlineShrink/></b>
                );
            }
            return (
                <b><AiOutlineExpandAlt/></b>
            );
        }
    };

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    function rowStyleFormat(row, rowIdx) {
        return {backgroundColor: rowIdx % 2 === 0 ? 'red' : 'blue'};
    }

    return (
        <>
            <div className={"container pb-5"}>
                <div className={"container"}>
                    <h2>{strings.requestsAwaitingResponse}</h2>
                    {
                        allLeaves &&
                        <BootstrapTable
                            keyField='id'
                            data={newLeaves}
                            columns={columns}
                            expandRow={expandRow}
                            bordered={true}
                            defaultSorted={defaultSorted}
                            hover={true}
                            trStyle={rowStyleFormat}
                            headerClasses={"headerStyle"}
                            wrapperClasses={"text-center "}
                            bodyClasses={"bodyStyle"}
                        />
                    }
                    {
                        newLeaves.length === 0 &&
                        <div className={"justify-content-center text-center w-100"}>
                            <div>
                                <h5 className={"text-secondary w-100 "}>
                                    <i>{strings.emptyList}</i></h5>
                            </div>
                        </div>
                    }{
                    newLeaves.length !== 0 &&

                    <ReactPaginate
                        previousLabel={<AiOutlineArrowLeft/>}
                        nextLabel={<AiOutlineArrowRight/>}
                        breakLabel="..."
                        pageCount={pageCountForNewRequest}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        forcePage={pageForNewRequest}
                        onPageChange={handlePageClickForNewRequest}
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination m-0 justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                    />
                }
                </div>
            </div>
            <DeliveredRequestModal showModal={showModal}
                                   handleClose={handleClose}
                                   getAllLeaveRequests={getAllLeaveRequests}
                                   getAllOtherLeaveRequest={getAllOtherLeaveRequest}
                                   getAllNewLeaveRequest={getAllNewLeaveRequest}
                                   selectedLeaveRequestForEdit={selectedLeaveRequestForEdit}
            />
            <hr className={"mt-5"}/>
            <div className={"container my-5 pb-3"}>
                <div className={"container"}>
                    <h2>{strings.otherRequests}</h2>
                    {
                        otherLeaves &&
                        <BootstrapTable
                            keyField='id'
                            data={otherLeaves}
                            columns={columns}
                            expandRow={expandRow}
                            bordered={true}
                            defaultSorted={defaultSorted}
                            hover={true}
                            trStyle={rowStyleFormat}
                            headerClasses={"headerStyle"}
                            wrapperClasses={"text-center "}
                            bodyClasses={"bodyStyle"}
                        />
                    }
                    {otherLeaves.length === 0 &&
                        <div className={"justify-content-center text-center w-100"}>
                            <div>
                                <h5 className={"text-secondary w-100 "}>
                                    <i>{strings.emptyList}</i></h5>
                            </div>
                        </div>

                    }
                    {otherLeaves.length !== 0 &&
                        <ReactPaginate
                            previousLabel={<AiOutlineArrowLeft/>}
                            nextLabel={<AiOutlineArrowRight/>}
                            breakLabel="..."
                            pageCount={pageCountForOtherRequest}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            forcePage={pageForOtherRequest}
                            onPageChange={handlePageClickForOtherRequest}
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination m-0 justify-content-center"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            activeClassName="active"
                        />
                    }
                </div>
            </div>
        </>
    )
}
export default DeliveredRequestList;