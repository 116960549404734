import React, { useEffect, useState } from "react";
import { strings } from "../../../localization/Localization";
import LeaveReasonService from "../../../repository/leaveReasonRepository/LeaveReasonRepository";
import LeaveReasonsModal from "./LeaveReasonsModal/LeaveReasonsModal";
import AddButton from "../../../components/addButton/AddButton";
import LeaveTypeRepository from "../../../repository/leaveTypeRepository/LeaveTypeRepository";
import swal from "sweetalert";
import FormatDate from "../../../components/formatDate/FormatDate";
import ApplicationService from "../../../ApplicationService";

const LeaveReasons = (props) => {
  const [leaveReasonsList, setLeaveReasonsList] = useState([]);

  const [selectedLeaveReasonsForEdit, setSelectedLeaveReasonsForEdit] =
    useState({});

  const [showLeaveReasonsModal, setShowLeaveReasonsModal] = useState(false);

  const [leaveTypeList, setLeaveTypeList] = useState([]);

  useEffect(() => {
    if (props.tabKey === "leaveReasons") {
      getLeaveReasons();
      getLeaveTypes();
    }
  }, [props.tabKey]);

  const getLeaveReasons = () => {
    LeaveReasonService.getAllLeaveReasons().then((data) => {
      setLeaveReasonsList(data.data);
    });
  };

  const getLeaveTypes = () => {
    LeaveTypeRepository.getAllLeaveType().then((data) => {
      setLeaveTypeList(data.data);
    });
  };

  const handleClose = () => {
    setShowLeaveReasonsModal(false);
    setSelectedLeaveReasonsForEdit({});
  };

  const handleShow = () => {
    setShowLeaveReasonsModal(true);
  };

  const onCLickLeaveReasons = (leaveReasons) => {
    setSelectedLeaveReasonsForEdit(leaveReasons);
    handleShow();
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const errorAlert = () => {
    swal({
      title: strings.oops,
      text: strings.alreadyExistRequestForThisReasons,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const confirmRemoveAlert = (id) => {
    swal({
      title: strings.areYouSure,
      text: strings.thisLeaveReasons + " " + strings.deletedForever,
      icon: "warning",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteLeaveReasons(id);
      } else {
      }
    });
  };

  const deleteLeaveReasons = (id) => {
    LeaveReasonService.deleteLeaveReason(id)
      .then(() => {
        successfulAlert();
        getLeaveReasons();
      })
      .catch((data) => {
        if (
          data.response.data ==
          "You already have a leave request for this reasons"
        ) {
          errorAlert();
        } else {
          warningAlert();
        }
      });
  };

  const displayLeaveTypeLabel = (leaveTypeId) => {
    let leaveType = leaveTypeList.find((el) => el.id == leaveTypeId);
    let leaveTypeDisplayLabel = leaveType?.labelMk;
    if (ApplicationService.getActiveLanguage() === "en")
      leaveTypeDisplayLabel = leaveType?.labelEn;
    return leaveTypeDisplayLabel;
  };

  return (
    <div className="table-responsive">
      {leaveTypeList.length !== 0 ? (
        <>
          <div className="justify-content-end w-100 d-flex mb-1">
            <AddButton onClickHandle={handleShow} />
          </div>
          <table className="table user-table table-responsive table-borderless text-start">
            <thead className="custom-table-header text-white no_text_space">
              <tr className="user-table-header-row text-center">
                <th scope="col">{strings.code}</th>
                <th scope="col">{strings.labelMk}</th>
                <th scope="col">{strings.labelSq}</th>
                <th scope="col">{strings.labelEn}</th>
                <th scope="col">{strings.allowedDays}</th>
                <th scope="col">{strings.startDate}</th>
                <th scope="col">{strings.endDate}</th>
                <th scope="col">{strings.leaveType}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {leaveReasonsList.length > 0 ? (
                leaveReasonsList.map((leaveReason) => (
                  <tr
                    className="user-table-body-row cursor-pointer text-center align-middle"
                    key={leaveReason?.id}
                  >
                    <td>{leaveReason?.name}</td>
                    <td>{leaveReason?.labelMk}</td>
                    <td>{leaveReason?.labelSq}</td>
                    <td>{leaveReason?.labelEn}</td>
                    <td>{leaveReason?.baseDays}</td>
                    <td>{FormatDate.formatDate(leaveReason?.dateFrom)}</td>
                    <td>{FormatDate.formatDate(leaveReason?.dateTo)}</td>
                    <td>{displayLeaveTypeLabel(leaveReason?.leaveType)}</td>
                    <td>
                      <div className="d-flex justify-content-end">
                        <div className="d-flex ms-auto align-items-center">
                          <div className="icon-div mx-2">
                            <div
                              onClick={() => onCLickLeaveReasons(leaveReason)}
                              title="Edit"
                              className="text-dark icon"
                            >
                              <i className="edit-icon-circle material-icons">
                                create
                              </i>
                            </div>
                          </div>
                          <div className="icon-div cursor-pointer me-2">
                            <a
                              title="Delete"
                              type="Button"
                              onClick={() => confirmRemoveAlert(leaveReason.id)}
                            >
                              <i
                                className="material-icons delete-icon-circle"
                                style={{ fontSize: "23px" }}
                              >
                                delete
                              </i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="justify-content-center text-center">
                  <td colSpan={9}>
                    <h6 className="text-secondary">
                      <i>{strings.emptyList}</i>
                    </h6>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <LeaveReasonsModal
            showModal={showLeaveReasonsModal}
            onHandleClose={handleClose}
            selectedLeaveReasonsForEdit={selectedLeaveReasonsForEdit}
            getLeaveReasons={getLeaveReasons}
          />
        </>
      ) : (
        <div className="d-flex text-center justify-content-center text-secondary">
          <i>{strings.listOfLeaveTypeIsEmptyForAddLeaveReasons}</i>
        </div>
      )}
    </div>
  );
};
export default LeaveReasons;
