import axios from "../../axios/axios";

const MyProjectService = {
    getAllProjectsForUser:() =>{
        return axios.get("/projects/userProjects")
    },

    getPaginatedProjects: (page, size) => {
        return axios.get(`/projects/paginated?page=${page}&size=${size}`);
    },

    getAllForLoggedUserPaginated: (page, size) => {
        return axios.post(`/projects/getAllForLoggedUserPaginated/${page}/${size}`);
    },
    addProject: (name, description, dateBeginning, dateEnding,isActive,type,group) =>{
        return axios.post("/projects/addProject",{
            name,
            description,
            dateBeginning,
            dateEnding,
            isActive,
            type,
            group
        });
},
    deleteProject:(id) =>{
        return axios.delete(`/projects/deleteProject/${id}`)
    },
    getProjectByUserId:(userId) =>{
        return axios.get(`/projects/user/${userId}`)
    },
    fetchProjectsFiltered: (page, size, data = {
        projectName: null,
        technologies: [],
        isActive: null,
        dateStarted: null,
        dateEnded: null
    }) => {return axios.post(`/projects/getAllFiltered/${page}/${size}`, data)},
    fetchMyProjectsFiltered: (page, size ,data = {
        projectName: null,
        technologies: [],
        isActive: null,
        dateStarted: null,
        dateEnded: null
    })  => {return axios.post(`/projects/getAllFilteredForUser/${page}/${size}`, data)}
}
export default MyProjectService;
