import React from "react";
import { Modal } from "react-bootstrap";
import ApplicationService from "../../../ApplicationService";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const MyEquipmentCardDetailsModal = (props) => {
  return (
    <Modal show={props.showModal}  dialogAs={DraggableModalDialog} onHide={props.handleClose} size={"xl"}>
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          <b>
            {ApplicationService.getActiveLanguage() === "mk"
              ? props.equipmentDetails.equipmentNomenclatureEntry?.labelMk
              : props.equipmentDetails.equipmentNomenclatureEntry?.labelEn}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {props.equipmentDetails.equipmentNomenclatureEntry?.description}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default MyEquipmentCardDetailsModal;
