import React, {useEffect, useState} from "react";
import {Form, FormControl, Modal, ModalBody, ModalHeader, ModalTitle} from "react-bootstrap";
import {strings} from "../../../localization/Localization";
import Button from "react-bootstrap/Button";
import PositionHierarchyService from "../../../repository/hierarchyRepository/positionHierarchyRepository";
import OrganizationalUnitRepository from "../../../repository/organizationUnitRepository/OrganizationalUnitRepository";
import Select from "react-select";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const OrganizationalHierarchyAddModal = (props) => {

    const [formData, updateFormData] = React.useState({
        hrmsStPositionsHId: "",
        activeFrom: "",
        activeTo: "",
        code: "",
        name: "",
        shortName: "",
        description: "",
        dateCreated: "",
        dateModified: "",
        parentId: ""
    });

    const [organisationForDropDown, setOrganisationForDropDown] = useState({});

    const [selectedOrganisation, setSelectedOrganisation] = useState({});

    useEffect(() => {
        if (props.showModal) {
            getAllOrganisations();
        } else {
            setSelectedOrganisation({});
        }
    }, [props.showModal]);

    const getAllOrganisations = () => {
        OrganizationalUnitRepository.getAllOrganisation()
            .then((data) => {
                setOrganisationForDropDown(convertOrganisationForDropdown(data.data));
            })
    };

    const convertOrganisationForDropdown = (organisation) => {
        return organisation.map((e) => {
            return {label: e.name, value: e.id}
        })
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        const hrmsStPositionsHId = formData.hrmsStPositionsHId !== "" ? formData.hrmsStPositionsHId : null;
        const activeFrom = formData.activeFrom !== "" ? formData.activeFrom : "2022-01-01";
        const activeTo = formData.activeTo !== "" ? formData.activeTo : "2050-01-01";
        const code = formData.code !== "" ? formData.code : null;
        const name = formData.name !== "" ? formData.name : formData.name;
        const description = formData.description !== "" ? formData.description : null;
        const shortName = formData.shortName !== "" ? formData.shortName : null;
        const organisation = selectedOrganisation?.value;
        const dateCreated = "";
        const dateModified = "";
        addHierarchy(hrmsStPositionsHId, activeFrom, activeTo, code, name, shortName, description, dateCreated, dateModified, props.selectedNode?.id, organisation)
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (e.target.hasAttribute("required") && value.trim() === "") {
          e.target.setCustomValidity(strings.fieldsRequired);
        } else {
          e.target.setCustomValidity("");
        }
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    };

    const handleSelectedOrganisationChange = (e) => {
        setSelectedOrganisation(e);
    };

    const addHierarchy = (hrmsStPositionsHId, activeFrom, activeTo, code, name, shortName, description, dateCreated, dateModified, parentId, organisation) => {
        PositionHierarchyService.addHierarchy(hrmsStPositionsHId, activeFrom, activeTo, code, name, shortName, description, dateCreated, dateModified, parentId, organisation)
            .then((data) => {
                props.loadHierarchy(false);
                props.handleClose();
            });
    };

    return (
        <>
            <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={"lg"} centered>
                <ModalHeader closeButton>
                    <ModalTitle>
                        <b>{strings.addPosition}</b>
                    </ModalTitle>
                </ModalHeader>
                <Form onSubmit={onFormSubmit}>
                    <ModalBody>
                        <Form.Group className="mb-3" controlId="hierarchyName">
                            {props.selectedNode != null && props.selectedNode.parent !== 0 &&
                                <Form.Group>
                                    <Form.Label>{strings.parentPosition}: <b>{props.selectedNode.data.name} - {props.selectedNode.data.code}</b></Form.Label>
                                </Form.Group>
                            }
                            <Form.Group className="mb-3">
                                <Form.Label>{strings.positionName}<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleChange}
                                    autoFocus
                                    name="name"
                                    required
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{strings.positionShortName}</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleChange}
                                    name="shortName"/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{strings.organizationApplies}</Form.Label>
                                <Select
                                    type="text"
                                    onChange={handleSelectedOrganisationChange}
                                    value={selectedOrganisation}
                                    options={organisationForDropDown}
                                    placeholder={strings.selectOption}
                                    noOptionsMessage={()=>strings.noOptions}
                                    name="shortName"/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{strings.positionDescription}</Form.Label>
                                <textarea name="description" className="form-control"
                                          onChange={handleChange}
                                          id="exampleFormControlTextarea1" rows="2"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{strings.positionCode}</Form.Label>
                                <FormControl type="text" name="code"
                                             className="form-control"
                                             onChange={handleChange}
                                             id="exampleFormControlTextarea1"/>
                            </Form.Group>
                        </Form.Group>
                    </ModalBody>
                    <Modal.Footer>
                        <div className={"cancel btn text-white"} onClick={props.handleClose}>
                            {strings.cancel}
                        </div>
                        <button type={"submit"} className={"save btn text-white"}>
                            {strings.save}
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
export default OrganizationalHierarchyAddModal;