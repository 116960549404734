import axios from "../../axios/axios";

const LeaveRequestRepository = {
    getLeaveRequestById:(id)=>{
        return axios.get(`/leaverequest/get/${id}`)
    },
    getAllLeaveRequests: () => {
        return axios.get(`/leaverequest/getAll`);
    },
    getAllLeaveRequestsPaginated: (page, size) => {
        return axios.get(`/leaverequest/getAllPaginated/${page}/${size}`)
    },
    getAllLeaveRequestsForLoggedUser: (page, size) => {
        return axios.get(`/leaverequest/getAllPaginatedForLoggedUser/${page}/${size}`)
    },
    getAllNewLeaveRequestsPaginated: (page, size) => {
        return axios.get(`/leaverequest/getAllPaginatedNew/${page}/${size}`)
    },
    getAllOtherLeaveRequestsPaginated: (page, size) => {
        return axios.get(`/leaverequest/getAllPaginatedNotNew/${page}/${size}`)
    },
    addLeaveRequest: (dateFrom, dateTo, totalDays, workDays, description, leaveTypeId, leaveReasonId,deputyId) => {
        return axios.post(`/leaverequest/addLeaveRequestByLoggedUser`, {
            dateFrom,
            dateTo,
            totalDays,
            workDays,
            description,
            leaveTypeId,
            leaveReasonId,
            deputyId
        })
    },
    getAllAbsencesByLoggedUser: () => {
        return axios.get(`/userabsence/getAllByLoggedUser`);
    },
    getAllAbsence: (date) => {
        return axios.post("/user/getAllByLeaveRequest", {
            date,
        })
    },
    getCalculateTotalLeaveDays: (dateFrom, dateTo) => {
        return axios.post("/leaverequest/getWorkDaysForLoggedUser", {
            dateFrom,
            dateTo
        })
    },
    getCalculateTotalLeaveDate: (dateFrom, days) => {
        return axios.post("/leaverequest/getDate", {
            dateFrom,
            days
        })
    },
    deleteLeaveRequest: (id) => {
        return axios.delete(`/leaverequest/delete/${id}`);
    },
    editLeaveRequest: (id, dateFrom, dateTo, totalDays, workDays, description, leaveTypeId, leaveReasonId,deputyId) => {
        return axios.put('leaverequest/update', {
            id,
            description,
            dateFrom,
            dateTo,
            totalDays,
            leaveRequestStatusId: "1",
            leaveReasonId,
            leaveTypeId,
            deputyId
        })
    },
    getAbsenceByVacation:(date)=>{
        return axios.post("/user/getAllByLeaveRequestDate",{
            date
        })
    },
    sentResponseForRequest:(responsibleUserId,leaveRequestId,responseText,afterLeaveRequestStatusId)=>{
        return axios.post("/leaverequestapproval/addLeaveRequestApproval",{
            responsibleUserId,
            leaveRequestId,
            afterLeaveRequestStatusId,
            responseText
        })
    },
    getAllLeaveRequestStatus:()=>{
        return axios.get("/leaverequeststatus/getAll")
    }
}
export default LeaveRequestRepository;