import React, {useEffect, useState} from "react"
import Modal from "react-bootstrap/Modal";
import {strings} from "../../../../localization/Localization";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LeaveTypeRepository from "../../../../repository/leaveTypeRepository/LeaveTypeRepository";
import swal from "sweetalert";
import InputLimit from "../../../../components/InputLimit/InputLimit";
import DraggableModalDialog from "../../../../components/DraggableModalDialog/DraggableModalDialog";

const LeaveTypeModal = (props) => {

    const [formData, updateFormData] = useState({
        name: "",
        label_mk: "",
        label_sq: "",
        label_en: ""
    });

    const [isActive, setIsActive] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;

        if (e.target.hasAttribute("required") && value.trim() === "") {
            e.target.setCustomValidity(strings.fieldsRequired);
          } else {
            e.target.setCustomValidity("");
          }

        if (name === 'name') {
            const pattern = /^[A-Z0-9!@#$%^&*()_+{}\[\]:;"'<>,.?~]+$/;
            if (value === '' || pattern.test(value)) {
                updateFormData({
                    ...formData,
                    [name]: value
                });
            }
        } else {
            updateFormData({
                ...formData,
                [name]: value
            });
        }
    };

    useEffect(() => {
        if (props.showModal) {
            if (props.selectedLeaveTypeForEdit.id) {
                updateFormData({
                    name: props.selectedLeaveTypeForEdit.name,
                    label_mk: props.selectedLeaveTypeForEdit.labelMk,
                    label_sq: props.selectedLeaveTypeForEdit.labelSq,
                    label_en: props.selectedLeaveTypeForEdit.labelEn
                })
            }
            if (props.selectedLeaveTypeForEdit.active) {
                setIsActive(true)
            } else {
                setIsActive(false);
            }
        } else {
            updateFormData(
                {
                    name: "",
                    label_mk: "",
                    label_sq: "",
                    label_en: ""
                }
            );
        }
    }, [props.showModal]);

    const handleActiveType = () => {
        LeaveTypeRepository.changeActiveLeaveType(props.selectedLeaveTypeForEdit.id)
            .then(() => {
                setIsActive(!isActive);
                props.getLeaveTypes();
            })
    };

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const warningAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const errorAlert = () => {
        swal({
            title: strings.oops,
            text: strings.alreadyExistRequestForThisType,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const addLeaveType = (data = {id: "", name: "", labelMk: "", labelEn: "", labelSq: ""}) => {
        LeaveTypeRepository.addLeaveType(data)
            .then(() => {
                props.getLeaveTypes();
                props.onHandleClose();
                successfulAlert();
            }).catch(() => {
            warningAlert();
        })
    };

    const editLeaveType = (data = {id: "", name: "", labelMk: "", labelEn: "", labelSq: ""}) => {
        LeaveTypeRepository.updateLeaveType(data)
            .then(() => {
                props.getLeaveTypes();
                props.onHandleClose();
                successfulAlert();
            }).catch(() => {
            warningAlert();
        })
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        const id = props.selectedLeaveTypeForEdit.id;
        const name = formData.name;
        const label_mk = formData.label_mk;
        const label_sq = formData.label_sq;
        const label_en = formData.label_en;
        if (props.selectedLeaveTypeForEdit.id) {
            editLeaveType({id, name, labelMk: label_mk, labelEn: label_en, labelSq: label_sq});
        } else {
            addLeaveType({id, name, labelMk: label_mk, labelEn: label_en, labelSq: label_sq});
        }
    };

    return (
        <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.onHandleClose} size={"lg"} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.selectedLeaveTypeForEdit.id ? (
                        <div>{strings.editLeaveType}</div>
                    ) : (
                        <div>{strings.addLeaveType}</div>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onFormSubmit}>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>{strings.code}<span className="text-danger">*</span></Form.Label>
                        {InputLimit.codeLimitMessage()}
                        <Form.Control
                            type="text"
                            autoFocus
                            required
                            onChange={handleChange}
                            disabled={props.selectedLeaveTypeForEdit.id && !props.selectedLeaveTypeForEdit?.editable}
                            value={formData.name}
                            name="name"
                        />
                    </Form.Group>
                    <h5 className={"my-4"}>{strings.marks}</h5>
                    <Row className={"px-2"}>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3 required" controlId="label_mk">
                                <Form.Label> {strings.labelMk}<span className="text-danger">*</span> </Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    autoFocus
                                    disabled={props.selectedLeaveTypeForEdit.id && !props.selectedLeaveTypeForEdit?.editable}
                                    value={formData.label_mk}
                                    name='label_mk'
                                    onChange={handleChange}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3" controlId="label_sq">
                                <Form.Label>{strings.labelSq}</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={props.selectedLeaveTypeForEdit.id && !props.selectedLeaveTypeForEdit?.editable}
                                    onChange={handleChange}
                                    autoFocus
                                    value={formData.label_sq}
                                    name="label_sq"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3" controlId="label_en">
                                <Form.Label>{strings.labelEn}</Form.Label>
                                <Form.Control
                                    type="text"
                                    autoFocus
                                    disabled={props.selectedLeaveTypeForEdit.id && !props.selectedLeaveTypeForEdit?.editable}
                                    value={formData.label_en}
                                    onChange={handleChange}
                                    name="label_en"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {props.selectedLeaveTypeForEdit.id &&
                        <Row style={{borderTop: "1px solid #ced4da"}} className={"mt-5"}>
                            <Col xs={12} md={8} className={"mt-4"}>
                                <Form.Group className="mb-1 ms-1   d-flex "
                                            controlId="exampleForm.ControlInput1">

                                    <Form.Group className={"d-flex my-auto mt-1"}>
                                        <Form.Label className={"me-3"}>
                                            {strings.active}
                                        </Form.Label>
                                        <div className={"mt-1"}>
                                            <label className="switchEnabled">
                                                <input type="checkbox"
                                                       disabled={props.selectedLeaveTypeForEdit.id && !props.selectedLeaveTypeForEdit?.editable}
                                                       checked={isActive} onChange={handleActiveType}/>
                                                <span className="sliderEnabled round"></span>
                                            </label>
                                        </div>
                                    </Form.Group>
                                </Form.Group>
                            </Col>
                        </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className={"cancel btn text-white"} onClick={props.onHandleClose}>
                        {strings.cancel}
                    </div>
                    <button type={"submit"} disabled={props.selectedLeaveTypeForEdit?.editable}
                            className={"save btn text-white"}>
                        {strings.save}
                    </button>
                </Modal.Footer>
            </Form>
        </Modal>

    )
}
export default LeaveTypeModal;