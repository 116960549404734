import {useEffect, useState} from "react";
import swal from "sweetalert";
import LeaveRequestRepository from "../../../../repository/leaveRequestRepository/LeaveRequestRepository";
import {Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Row} from "react-bootstrap";
import Select from "react-select";
import LeaveTypeRepository from "../../../../repository/leaveTypeRepository/LeaveTypeRepository";
import {strings} from "../../../../localization/Localization";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import './DeliveredRequestModal.css'
import LeaveReasonService from "../../../../repository/leaveReasonRepository/LeaveReasonRepository";
import 'material-icons/iconfont/material-icons.css';
import SpinnerComponent from "../../../../components/Spinner"
import ApplicationService from "../../../../ApplicationService";
import DeliveredRequestStatusManager from "../DeliveredRequestStatusMenager/DeliveredRequestStatusManager";
import UserListInModal from "../../../../components/UserListInModal";
import AuditActionLeaveRequest from "../AuditActionLeaveRequest/AuditActionLeaveRequest";
import DraggableModalDialog from "../../../../components/DraggableModalDialog/DraggableModalDialog";


const DeliveredRequestModal = (props) => {

    const [formData, setFormData] = useState({
        dateFrom: "",
        dateTo: "",
        totalDays: null,
        workDays: null,
        description: "",
        leaveType: "",
        leaveReasons: "",
    });

    const [leaveType, setLeaveType] = useState([]);

    const [selectedLeaveType, setSelectedLeaveType] = useState({});

    const [spinnerShow, setShowSpinner] = useState(true)

    const [leaveReasons, setLeaveReasons] = useState([]);

    const [selectedLeaveReasons, setSelectedLeaveReasons] = useState({});

    const [dateValid, setDateValid] = useState(true);

    const [absenceDetails, setAbsenceDetails] = useState([]);

    const [absenceDetailsByReasons, setAbsenceDetailsByReasons] = useState([]);

    const [absenceDetailsByReasonsForCurrentYear, setAbsenceDetailsByReasonsForCurrentYear] = useState([]);

    const [allLeaveRequestStatus, setAllLeaveRequestStatus] = useState([]);

    const [selectedStatusAndDescriptionForResponse, setSelectedStatusAndDescriptionForResponse] = useState({
        description: "",
        responseStatus: ""
    });

    const [userAlreadyHaveSickLeaveRequest,setUserAlreadyHaveSickLeaveRequest]=useState(false);


    useEffect(() => {
        if (props.showModal) {
            setUserAlreadyHaveSickLeaveRequest(false);
            getAllLeaveType();
            getLeaveStatus();
        } else {
            setUserAlreadyHaveSickLeaveRequest(false);
            setSelectedLeaveType({});
            setSelectedLeaveReasons({});
            setAbsenceDetailsByReasons([]);
            setSelectedStatusAndDescriptionForResponse({description: "", responseStatus: ""});
            resetFormDate();
        }
    }, [props.showModal]);

    useEffect(() => {
        if (props.selectedLeaveRequestForEdit.id) {
            setSelectedLeaveReasons({
                value: props.selectedLeaveRequestForEdit.leaveReason.id,
                label: props.selectedLeaveRequestForEdit.leaveReason.name
            });
            setSelectedLeaveType({
                value: props.selectedLeaveRequestForEdit.leaveType.id,
                label: props.selectedLeaveRequestForEdit.leaveType.name
            })
        }
    }, [props.selectedLeaveRequestForEdit]);

    useEffect(() => {
        calculateTotalLeaveDays()
    }, [formData.dateFrom, formData.dateTo]);

    useEffect(() => {
        if (props.showModal) {
            getAllAbsencesByLoggedUser();
        }
    }, []);

    useEffect(() => {
        if (selectedLeaveReasons.value) {
            LeaveReasonService.getAllowDayAndRemainingDayForReasonsByUserId(selectedLeaveReasons.value, props.selectedLeaveRequestForEdit.userHelper.id)
                .then((data) => {
                    setAbsenceDetailsByReasons(data.data);
                })
        }
    }, [selectedLeaveReasons])

    useEffect(() => {
        if (selectedLeaveType.value || props.selectedLeaveRequestForEdit.id) {
            if (selectedLeaveType.value) {
                if (formData.leaveType !== "") {
                    setSelectedLeaveReasons({});
                }
                getLeaveReasons(selectedLeaveType.value);
            } else if (props.selectedLeaveRequestForEdit.id) {
                getLeaveReasons(props.selectedLeaveRequestForEdit.leaveType.id);
            }
        }
    }, [selectedLeaveType, props.selectedLeaveRequestForEdit]);

    useEffect(() => {
        let year = new Date().getFullYear();
        if (absenceDetailsByReasons.filter((r) => r.forYear === year).length === 0) {
            year = year - 1;
        }

        setAbsenceDetailsByReasonsForCurrentYear(absenceDetailsByReasons.filter((r) => r.forYear === year))
    }, [absenceDetailsByReasons])

    const resetFormDate = () => {
        setFormData({
            dateFrom: "",
            dateTo: "",
            totalDays: null,
            workDays: null,
            description: "",
            leaveType: "",
            leaveReasons: "",
        })
        // setResponseDescription(null)
    };

    const calculateTotalLeaveDays = () => {
        if (!formData.dateTo && !props.selectedLeaveRequestForEdit.id) {
            return;
        }
        setShowSpinner(false)
        let date1 = null;
        if (props.selectedLeaveRequestForEdit.id && formData.dateFrom === "") {
            date1 = new Date(props.selectedLeaveRequestForEdit.dateFrom);
        } else if (formData.dateFrom !== "") {
            date1 = new Date(formData.dateFrom);
        } else {
            date1 = new Date();
        }
        let date2 = null;
        if (props.selectedLeaveRequestForEdit.id && formData.dateTo === "") {
            date2 = new Date(props.selectedLeaveRequestForEdit.dateTo);
        } else {
            date2 = new Date(formData.dateTo.getFullYear(), formData.dateTo.getMonth(), formData.dateTo.getDate() + 1);
        }

        LeaveRequestRepository.getCalculateTotalLeaveDays(date1, date2)
            .then((data) => {
                setFormData({
                    ...formData,
                    totalDays: data.data
                })
            }).catch(() => {
            setFormData({
                ...formData,
                totalDays: ""
            })
        })
        setShowSpinner(true)
    };

    const handleChange = (e) => {
        const value = e.target.value;
        if (e.target.hasAttribute("required") && value.trim() === "") {
          e.target.setCustomValidity(strings.fieldsRequired);
        } else {
          e.target.setCustomValidity("");
        }
        
        setFormData({
            ...formData,
            [e.target.name]: e.target.value

        })
    };

    const handleDateFromChange = (date) => {
        if (formData.dateTo != "") {
            checkDateValues(date, formData.dateTo);
        }
        setFormData({
            ...formData,
            dateFrom: date
        })
    };

    const handleDateToChange = (date) => {
        if (formData.dateFrom !== "") {
            checkDateValues(formData.dateFrom, date);
        }
        setFormData({
            ...formData,
            dateTo: date
        })
    };

    const checkDateValues = (from, to) => {
        let dateFrom = new Date(from);
        let dateTo = new Date(to);
        if (dateFrom > dateTo) {
            setDateValid(false)
        } else {
            setDateValid(true)
        }
    };

    const selectLeaveType = (e) => {
        setFormData({
            ...formData,
            leaveType: leaveType.find(el => el.id === e.value)
        })
        setSelectedLeaveType({value: e.value, label: e.label});
    };

    const selectLeaveReasons = (e) => {
        setFormData({
            ...formData,
            leaveReasons: leaveReasons.find(el => el.id === e.value)
        })
        setSelectedLeaveReasons({value: e.value, label: e.label});
    };

    const successfulAlert = () => {
        swal(strings.responseIsSent, {
            icon: "success",
        });
    };

    const errorAlert = (errorText) => {
        swal({
            title: strings.oops,
            text: errorText,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
        });
    };

    const warrningAlert = () => {
        swal({
            title: strings.required,
            text: strings.enterApprovalStatusRequired,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };


    const getAllAbsencesByLoggedUser = () => {
        LeaveRequestRepository.getAllAbsencesByLoggedUser().then((days) => {
            setAbsenceDetails(days.data);
        })
    };

    const getAllLeaveType = () => {
        LeaveTypeRepository.getAllLeaveType().then((data) => {
            setLeaveType(data.data)
            // setSelectedLeaveType({value: data.data[0].id, label: data.data[0].name});
        })
    };

    const getLeaveReasons = (id) => {
        LeaveReasonService.getAllLeaveReasonsByLeaveTypeId(id)
            .then((data) => {
                setLeaveReasons(data.data)
            })
    }

    const getLeaveStatus = () => {
        LeaveRequestRepository.getAllLeaveRequestStatus()
            .then((data) => {
                setAllLeaveRequestStatus(data.data);
            })
    };

    const onFormSubmit = (e) => {
        setShowSpinner(false);
        let statusId = allLeaveRequestStatus.filter((r) => r.name === selectedStatusAndDescriptionForResponse.responseStatus);
        if (selectedStatusAndDescriptionForResponse.responseStatus === "") {
            setShowSpinner(true)
            warrningAlert();
        } else {
            if ((selectedStatusAndDescriptionForResponse.responseStatus === "RETURNED FOR CORRECTION" || selectedStatusAndDescriptionForResponse.responseStatus === "DECLINED") && selectedStatusAndDescriptionForResponse.description === "") {
                setShowSpinner(true)
                warrningAlert();
            } else {
                statusId = statusId[0].id;
                LeaveRequestRepository.sentResponseForRequest(props.selectedLeaveRequestForEdit.userHelper.id, props.selectedLeaveRequestForEdit.id, selectedStatusAndDescriptionForResponse.description, statusId)
                    .then((data) => {
                        setShowSpinner(true)
                        successfulAlert();
                        props.getAllOtherLeaveRequest();
                        props.getAllNewLeaveRequest();
                        props.handleClose();
                    })
                    .catch((error) => {
                        let errorText = "";
                        if (error.response.data == "DuplicateLeaveRequestException") {
                            errorText = strings.duplicateLeaveRequestException;
                        } else if (error.response.data == "No available days") {
                            errorText = strings.noAvailableDays
                        } else if (error.response.data == "No available days for this year") {
                            errorText = strings.noAvailableDaysForThisYear
                        } else if (error.response.data == "Already absent") {
                            errorText = strings.alreadyHaveSickLeaveDuring;
                        } else if (error.response.data == "User already absent") {
                            setUserAlreadyHaveSickLeaveRequest(true);
                            errorText = strings.userAlreadyHaveSickLeaveDuring;
                        } else {
                            errorText = strings.somethingWrong;
                        }
                        setShowSpinner(true)
                        errorAlert(errorText);
                    })
            }
        }

    };

    const columns = [
        {
            dataField: 'forYear',
            text: strings.forYear,
        },
        {
            dataField: 'allowedDays',
            text: strings.allowedDays,
        },
        {
            dataField: 'remainingDays',
            text: strings.remainingDays
        }
    ];

    const getClassStyleForStatus = (status) => {
        if (status == "NEW") {
            return "new_notification";
        } else if (status == "DECLINED") {
            return " declined_notification";
        } else if (status == "APPROVED") {
            return "approved_notification";
        } else if (status == "RETURNED FOR CORRECTION") {
            return "returned_notification";
        } else if (status == "CORRECTED") {
            return "corrected_notification";
        }
    }

    const getDateForFormDateCalendar = () => {
        if (props.selectedLeaveRequestForEdit.id && formData.dateFrom === "") {
            return new Date(props.selectedLeaveRequestForEdit.dateFrom);
        } else if (formData.dateFrom !== "") {
            return formData.dateFrom
        } else {
            return new Date();
        }
    }

    const getDateForDateToCalendar = () => {
        if (props.selectedLeaveRequestForEdit.id && formData.dateTo === "") {
            return new Date(props.selectedLeaveRequestForEdit.dateTo);
        } else if (formData.dateTo !== "") {
            return formData.dateTo
        } else {
            return "";
        }
    }

    return (
        <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={"lg"} centered>
            <ModalHeader closeButton>
                {props.selectedLeaveRequestForEdit.id ? (
                    <ModalTitle>
                        <div className={"d-flex "}>
                            <div>{strings.editLeaveRequest}</div>
                            <div
                                className={"status-position-end d-flex text-center align-items-center " + getClassStyleForStatus(props.selectedLeaveRequestForEdit.leaveRequestStatus.name)}>{ApplicationService.getActiveLanguage() === "mk" ? (props.selectedLeaveRequestForEdit.leaveRequestStatus.label_mk) : (props.selectedLeaveRequestForEdit.leaveRequestStatus.label_en)}</div>
                        </div>
                    </ModalTitle>
                ) : (
                    <ModalTitle><b>{strings.requestALeave}</b></ModalTitle>
                )
                }
            </ModalHeader>
            <Form>
                <ModalBody>
                    <Form.Group className="row mb-3" controlId="date">
                        <DeliveredRequestStatusManager
                            setSelectedStatusAndDescriptionForResponse={setSelectedStatusAndDescriptionForResponse}
                            selectedStatusAndDescriptionForResponse={selectedStatusAndDescriptionForResponse}
                            userAlreadyHaveSickLeaveRequest={userAlreadyHaveSickLeaveRequest}/>
                        {props.selectedLeaveRequestForEdit.id &&
                            <Form.Group className={"mt-3"}>
                                <UserListInModal users={[props.selectedLeaveRequestForEdit.userHelper]}></UserListInModal>
                            </Form.Group>
                        }

                        <Form.Group className="col-6">
                            <Form.Label>{strings.dateFrom}</Form.Label> <br/>
                            <DatePicker
                                className="react-datepicker w-100"
                                disabled={true}
                                selected={
                                    getDateForFormDateCalendar()
                                }
                                onChange={(date) => handleDateFromChange(date)}
                                dateFormat="dd.MM.yyyy"
                            />
                        </Form.Group>
                        <Form.Group className="col-6">
                            <Form.Label>{strings.dateTo}</Form.Label> <br/>
                            <DatePicker
                                className="react-datepicker w-100"
                                disabled={true}
                                selected={getDateForDateToCalendar()}
                                onChange={(date) => handleDateToChange(date)}
                                dateFormat="dd.MM.yyyy"
                            />
                        </Form.Group>
                    </Form.Group>
                    {dateValid ? null :
                        <div className="alert alert-danger row-cols-6 mt-2"
                             role="alert">{strings.enterValidateDate}</div>}
                    <Form.Group className={"row"}>
                        <Form.Group className="col-6 mb-3" controlId="workDays">
                            <Form.Label>{strings.workingDays}</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder={strings.day}
                                disabled={true}
                                autoFocus
                                value={props.selectedLeaveRequestForEdit.correctedWorkDays}
                                // defaultValue={props.selectedLeaveRequestForEdit.correctedWorkDays}
                                readOnly={true}
                                onChange={handleChange}
                                name="workDays"
                                // value={formData.workDays}
                                className="mb-3"/>

                        </Form.Group>
                        <Form.Group className="col-6 mb-3" controlId="workDays">
                            <Form.Label>{strings.totalDays}</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder={strings.day}
                                disabled={true}
                                autoFocus
                                value={props.selectedLeaveRequestForEdit.totalDays}
                                // defaultValue={props.selectedLeaveRequestForEdit.totalDays}
                                readOnly={true}
                                onChange={handleChange}
                                name="totalDays"
                                // value={formData.totalDays}
                                className="mb-3"/>

                        </Form.Group>
                    </Form.Group>
                    <Form.Group className="mb-3 justifyContent" controlId="description">
                        <Form.Label>{strings.description}<span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={strings.enterDescription}
                            disabled={true}
                            defaultValue={props.selectedLeaveRequestForEdit.description}
                            autoFocus
                            required
                            onChange={handleChange}
                            name="description"
                            onInvalid={(e) => {
                                e.target.setCustomValidity(strings.fieldsRequired);
                              }}
                        />
                    </Form.Group>
                    <Row>
                        <Form.Group className="col-5 mb-3">
                            <Form.Label>{strings.doYouWantToSelectADeputy}</Form.Label>
                            <div className={"d-flex ms-2"}>
                                <div className={"me-2"}>
                                    <input type="radio" value="yes" name="deputy"
                                           defaultChecked={props.selectedLeaveRequestForEdit.deputy != null ? true : false}
                                           disabled={true}
                                        // onChange={handleDeputySelect}
                                    /> {strings.yes}
                                </div>
                                <div>
                                    <input type="radio"
                                        // onChange={handleDeputySelect}
                                           defaultChecked={props.selectedLeaveRequestForEdit.deputy != null ? false : true}
                                           value="no"
                                           disabled={true}
                                           name="deputy"/> {strings.no}
                                </div>
                            </div>
                        </Form.Group>
                        {props.selectedLeaveRequestForEdit.deputy &&
                            <Form.Group className="col-7 mb-3">
                                <Form.Label>{strings.selectDeputy}<span className="text-danger">*</span></Form.Label>
                                <Select required
                                        name="deputies"
                                        options={
                                            {
                                                value: props.selectedLeaveRequestForEdit.deputy.id,
                                                label: props.selectedLeaveRequestForEdit.deputy.firstName + " " + props.selectedLeaveRequestForEdit.deputy.lastName
                                            }
                                        }
                                        isDisabled={true}
                                    // onChange={selectedDeputyChange}
                                        value={{
                                            value: props.selectedLeaveRequestForEdit.deputy.id,
                                            label: props.selectedLeaveRequestForEdit.deputy.firstName + " " + props.selectedLeaveRequestForEdit.deputy.lastName
                                        }
                                        }
                                        onInvalid={(e) => {
                                            e.target.setCustomValidity(strings.fieldsRequired);
                                          }}
                                />
                            </Form.Group>
                        }
                    </Row>
                    <Form.Label>{strings.leaveType}<span className="text-danger">*</span></Form.Label>
                    {
                        selectedLeaveType && leaveType.length > 0 &&
                        <Select required
                                name="leaveType"
                                isDisabled={true}
                                options={
                                    leaveType?.map((item) => (
                                        {value: item.id, label: item.labelMk}
                                    ))}
                                onChange={selectLeaveType}
                                value={selectedLeaveType}
                                onInvalid={(e) => {
                                    e.target.setCustomValidity(strings.fieldsRequired);
                                  }}
                        />
                    }
                    {(props.selectedLeaveRequestForEdit.id || selectedLeaveType.value && leaveReasons.length > 0) &&
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="mt-3 justifyContent" controlId="reasons">
                                    <Form.Label>{strings.reasonsForLeaveRequest}<span className="text-danger">*</span></Form.Label>
                                    <Select required
                                            name="leaveReasons"
                                            isDisabled={true}
                                            options={
                                                leaveReasons?.map((item) => (
                                                    {value: item.id, label: item.name}
                                                ))}
                                            onChange={selectLeaveReasons}
                                            value={selectedLeaveReasons}
                                            onInvalid={(e) => {
                                                e.target.setCustomValidity(strings.fieldsRequired);
                                              }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mt-4 d-flex text-align-end justify-content-end"
                                            controlId="dayLeft">
                                    <Form.Group className={"d-flex justify-content-end align-items-end"}>
                                        <Form.Group className={"text-center"}>
                                            <Form.Label
                                                className={"justify-content-center d-flex"}>{strings.allowedDays}:<span className="text-danger">*</span></Form.Label>
                                            <Form.Control className={"w-50 h-100 mx-auto"}
                                                          type="text"
                                                          autoFocus
                                                          disabled={true}
                                                          required
                                                          value={absenceDetailsByReasonsForCurrentYear[0]?.allowedDays}
                                                          readOnly={true}
                                                          onChange={handleChange}
                                                          name="description"
                                                          onInvalid={(e) => {
                                                            e.target.setCustomValidity(strings.fieldsRequired);
                                                          }}
                                            />
                                        </Form.Group>
                                        <Form.Group className={"text-center "}>
                                            <Form.Label
                                                className={"justify-content-center d-flex"}>{strings.remainingDays}:<span className="text-danger">*</span></Form.Label>
                                            <Form.Group className={"d-inline-flex justify-content-center"}>
                                                <Form.Control className={"w-50 h-100"}
                                                              type="text"
                                                              autoFocus
                                                              disabled={true}
                                                              required
                                                              value={absenceDetailsByReasonsForCurrentYear[0]?.remainingDays}
                                                              readOnly={true}
                                                              onChange={handleChange}
                                                              name="description"
                                                              onInvalid={(e) => {
                                                                e.target.setCustomValidity(strings.fieldsRequired);
                                                              }}
                                                />
                                                {/*<div className={'d-inline-flex mx-3 my-2 text-center justify-content-center'}>*/}
                                                {absenceDetailsByReasonsForCurrentYear[0]?.remainingDays >= props.selectedLeaveRequestForEdit.correctedWorkDays ?
                                                    (
                                                        <i className="material-icons mx-1 align-items-center d-flex text-success">check_circle</i>
                                                    ) : (
                                                        <i className="material-icons mx-1 align-items-center d-flex text-danger ">cancel</i>
                                                    )
                                                }
                                                {/*Имате преостанато денови.*/}
                                                {/*</div>*/}
                                            </Form.Group>
                                        </Form.Group>
                                        {/*<Form.Group>*/}
                                        {/*    <Form.Label className={"justify-content-center d-flex"}>Преостанато денови:</Form.Label>*/}
                                        {/*    <Form.Control className={"w-25 h-100"}*/}
                                        {/*                  type="text"*/}
                                        {/*                  autoFocus*/}
                                        {/*                  required*/}
                                        {/*                  onChange={handleChange}*/}
                                        {/*                  name="description"*/}
                                        {/*    />*/}
                                        {/*</Form.Group>*/}

                                    </Form.Group>
                                </Form.Group>
                            </Col>
                        </Row>
                    }
                    <h2 className="mt-5 mb-5 remaining-days-header"><span>{strings.remainingDays}</span></h2>
                    <BootstrapTable
                        keyField='id'
                        data={absenceDetailsByReasons}
                        columns={columns}
                        bordered={true}
                        hover={false}
                        headerClasses={"headerStyleModal remainingDaysTable"}
                        wrapperClasses={"text-center "}
                        bodyClasses={"bodyStyleModal"}/>
                    <AuditActionLeaveRequest leaveRequestId={props.selectedLeaveRequestForEdit.id} showModal={props.showModal}/>
                </ModalBody>
                <ModalFooter>
                    <div type={"button"} className={"save btn text-white"} onClick={onFormSubmit}>
                        {strings.responseOnRequest}
                    </div>
                </ModalFooter>
            </Form>
            <SpinnerComponent spinnerShow={spinnerShow}/>
        </Modal>
    )
}
export default DeliveredRequestModal;