import React, {useEffect, useState} from "react"
import {strings} from "../../../localization/Localization";
import LeaveTypeRepository from "../../../repository/leaveTypeRepository/LeaveTypeRepository";
import LeaveTypeModal from "./LeaveTypeModal/LeaveTypeModal";
import AddButton from "../../../components/addButton/AddButton";
import swal from "sweetalert";

const LeaveType = (props) => {

    const [leaveTypeList, setLeaveTypeList] = useState([]);

    const [selectedLeaveTypeForEdit, setSelectedLeaveTypeForEdit] = useState({});

    const [showLeaveTypeModal, setShowLeaveTypeModal] = useState(false);

    useEffect(() => {
        if (props.tabKey === "leaveType") {
            getLeaveTypes();
        }
    }, [props.tabKey]);

    const getLeaveTypes = () => {
        LeaveTypeRepository.getAllLeaveType()
            .then((data) => {
                setLeaveTypeList(data.data);
            })
    };

    const handleClose = () => {
        setShowLeaveTypeModal(false);
        setSelectedLeaveTypeForEdit({});
    };

    const handleShow = () => {
        setShowLeaveTypeModal(true);
    };

    const onCLickLeaveType = (leaveType) => {
        setSelectedLeaveTypeForEdit(leaveType);
        handleShow();
    };

    const confirmRemoveAlert = (id) => {
        swal({
            title: strings.areYouSure,
            text: strings.thisLeaveReasons + " " + strings.deletedForever,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteLeaveType(id);
            } else {

            }
        });
    };

    const deleteLeaveType = (id) => {
        LeaveTypeRepository.deleteLeaveType(id)
            .then(() => {
                successfulAlert();
                getLeaveTypes();
            }).catch((data) => {
            if (data.response.data === "You already have a leave request for this type") {
                errorAlert(1);
            } else if (data.response.data === "You cannot delete this leave type because there are leave reasons connected to it.") {
                errorAlert(2)
            } else {
                warningAlert();
            }

        });
    };

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const warningAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const errorAlert = (errorType) => {
        let title = strings.oops;
        let text = strings.somethingWrong;

        switch (errorType) {
            case 1:
                text = strings.alreadyExistRequestForThisType;
                break;
            case 2:
                text = strings.alreadyExistLeaveReasonForThisType;
                break;
            default:
                break;
        }

        swal({
            title: title,
            text: text,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };


    return (
        <div className="table-responsive">
            <div className={"justify-content-end w-100 d-flex mb-1"}>
                <AddButton onClickHandle={handleShow}></AddButton>
            </div>
            <table className="table user-table table-responsive table-borderless text-start">
                <thead className={"custom-table-header"}>
                <tr className={"user-table-header-row text-center"}>
                    <th scope="col">{strings.code}</th>
                    <th scope="col">{strings.labelMk}</th>
                    <th scope="col">{strings.labelSq}</th>
                    <th scope="col">{strings.labelEn}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>

                {leaveTypeList.length > 0 ?
                    (leaveTypeList.map((leaveType) => {
                            return (
                                <tr className={"user-table-body-row cursor-pointer text-center align-middle"} key={leaveType?.id}>
                                    <td>{leaveType?.name}</td>
                                    <td>{leaveType?.labelMk}</td>                                    
                                    <td>{leaveType?.labelSq}</td>
                                    <td>{leaveType?.labelEn}</td>
                                    <td>
                                        <div className={"d-flex justify-content-end"}>
                                            <div className={"d-flex ms-auto align-items-center"}>
                                                <div className={'icon-div mx-2'}>
                                                    <div
                                                        onClick={() => onCLickLeaveType(leaveType)}
                                                        title={'Edit'}
                                                        className={'text-dark icon'}>
                                                        <i className={"edit-icon-circle material-icons "}>
                                                            create
                                                        </i>
                                                    </div>
                                                </div>
                                                <div className={'icon-div cursor-pointer me-2'}>
                                                    <a title={'Delete'} type={'Button'} onClick={() => confirmRemoveAlert(leaveType.id)}>
                                                        <i className={"material-icons delete-icon-circle"}
                                                           style={{fontSize: "23px"}}>delete</i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })

                    ) :
                    (
                        <tr className={"justify-content-center text-center "}>
                            <td colSpan={5}>
                                <h6 className={"text-secondary"}><i>{strings.emptyList}</i></h6>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            <LeaveTypeModal showModal={showLeaveTypeModal} onHandleClose={handleClose}
                            selectedLeaveTypeForEdit={selectedLeaveTypeForEdit} getLeaveTypes={getLeaveTypes}/>
        </div>
    )
}
export default LeaveType;