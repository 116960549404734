import React from "react";
import "./ProjectTechnologies.css"

const ProjectTechnologies = (props) => {
    return (
        <span className="mx-1" key={props.technology.id}>
            {props.technology.technology.value === "backend" ? (
                <span className="badge rounded-pill  backend-badge">{props.technology.technology.name}</span>
            ) : props.technology.technology.value === "frontend" ? (
                <span className="badge rounded-pill frontend-badge">{props.technology.technology.name}</span>
            ) : (
                <span className="badge rounded-pill other-badge">{props.technology.technology.name}</span>
            )}
        </span>
    );
}

export default ProjectTechnologies;
