import { useState, useEffect } from "react";
import { strings } from "../../../localization/Localization";
import { useHistory } from "react-router-dom";
import EquipmentCardComponent from "../EquipmentCardComponent/EquipmentCardComponent";
import NomenclatureService from "../../../repository/nomenclatureRepository/NomenclatureRepository";
import ApplicationService from "../../../ApplicationService";
import EquipmentRepository from "../../../repository/equipmentReposituory/EquipmentRepository";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EquipmentProposalRepository from "../../../repository/equipmentReposituory/EquipmentProposalRepository";
import "./AllEquipment.css";
import RequestEquipmentModal from "../EquipmentRequest/RequestEquipmentModal/RequestEquipmentModal";
import swal from "sweetalert";

const AllEquipment = () => {
  const [equipmentList, setEquipmentList] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);
  const [equipmentNomenclature, setEquipmentNomenclature] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [equipmentProposals, setEquipmentProposals] = useState([]);
  const [selectedUserEquipmentDetails, setSelectedUserEquipmentDetails] =
    useState({});
  const history = useHistory();
  const [key, setKey] = useState("allEquipment");

  useEffect(() => {
    if (key === 'allEquipment') {
      getAllEquipment();
    } else if (key === 'equipmentProposals') {
      getAllEquipmentProposals();
      getNomenclatureByName();
    }
  }, [key]);

  const getAllEquipment = () => {
    EquipmentRepository.getAllEquipments().then((data) => {
      setEquipmentList(data.data);
      setExpandedDescriptions(new Array(data.data.length).fill(false));
    });
  };

  const getAllEquipmentProposals = () => {
    EquipmentProposalRepository.getAllEquipmentProposals().then((data) => {
      setEquipmentProposals(data.data);
    });
  };

  const handleTabSelect = (selectedKey) => {
    setKey(selectedKey);
  };

  const getNomenclatureByName = () => {
    NomenclatureService.getNomenclatureByName('Опрема').then((data) => {
      setEquipmentNomenclature(data.data);
    });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDate.replace(/\//g, ".");
  };

  const showDescription = (index) => {
    const updatedExpandedDescriptions = [...expandedDescriptions];
    updatedExpandedDescriptions[index] = !updatedExpandedDescriptions[index];
    setExpandedDescriptions(updatedExpandedDescriptions);
  };

  const redirectToEditEquipment = (nomenclatureEntry) => {
    history.push("/edit-equipment", { nomenclatureEntry });
  };

  const handleShowAddUserToEquipmentModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddUserToEquipmentModal = () => {
    setShowAddModal(false);
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const errorAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const deleteEquipmentProposal = (id) => {
    EquipmentProposalRepository.deleteEquipmentProposal(id)
      .then(() => {
        successfulAlert();
        getAllEquipmentProposals();
      })
      .catch(() => {
        errorAlert();
      });
  };

  const ConfirmationDeleteProposal = (id) => {
    swal(strings.deleteConfirmation, {
      title: strings.areYouSure,
      icon: "warning",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteEquipmentProposal(id);
      }
    });
  };

  const handleAddRequestModal = (proposal = null) => {
    setSelectedUserEquipmentDetails(proposal);
    setShowAddModal(true);
  };

  const checkStatus = (status) => {
    switch (status) {
      case "APPROVED":
        return strings.accepted;
      case "REJECTED":
        return strings.notAccepted;
      case "PENDING":
        return strings.pending;
      default:
        return "";
    }
  };

  const getStatusColorClass = (status) => {
    switch (status) {
      case "APPROVED":
        return "accepted-color";
      case "REJECTED":
        return "not-accepted-color";
      case "PENDING":
        return "pending-color";
      default:
        return "";
    }
  };

  return (
    <>   
      <RequestEquipmentModal
        showModal={showAddModal}
        handleClose={handleCloseAddUserToEquipmentModal}
        getEquipmentProposals={getAllEquipmentProposals}
        selectedEquipmentProposalForEdit={selectedUserEquipmentDetails}
        fromAllEquipment={true}
      />
      <div className="w-100 d-flex justify-content-between">
        <div>
          <h1>{strings.administration}</h1>
        </div>
        {ApplicationService.hasRole(['ROLE_ADMINISTRATOR', 'ROLE_HR']) && (
          <div className='align-items-center d-flex justify-content-between'>
            <div
              className={
                'btn text-end d-flex align-items-center border-radius add-button test'
              }
              onClick={() => {
                history.push('/nomenclature', { nomenclatureEntry: 'Опрема' });
              }}>
              <div className={'align-items-center text-white d-flex text-start'}>
                <i className={'material-icons add_button_icon'}>add_box</i>
              </div>
              <div className={'mx-1 text-white'}>{strings.addEquipment}</div>
            </div>
          </div>
        )}
      </div>
      <div
        className={
          "user_details_box w-100 mb-4 border border-radius border-light border-5 px-4 pt-4"
        }
      >
        <Tabs
          id="controlled-tab-example"
          className={"w-100 user_details_tabs equipment-tabs"}
          activeKey={key}
          onSelect={handleTabSelect}
        >
          <Tab
            eventKey="allEquipment"
            title={strings.allEquipment}
            className={"h-100 mt-4"}
          >
    
              <div className="w-100 h-100">
                <div className="row">
                  {equipmentList.map((item, index) => (
                    <div className="col-12 col-lg-6 col-xl-4 mb-4" key={index}>
                      <EquipmentCardComponent
                        equipment={item}
                        equipmentNomenclature={equipmentNomenclature}
                        index={index}
                        expandedDescriptions={expandedDescriptions}
                        showDescription={showDescription}
                        formatDate={formatDate}
                      />
                    </div>
                  ))}
                </div>
                {equipmentList.length === 0 && (
                  <h3 className={"text-center my-5"}>
                    <i className={"text-secondary no-notification-message"}>
                      {strings.noEquipmentEntries}
                    </i>
                  </h3>
                )}
              </div>
          </Tab>
          <Tab
            eventKey="equipmentProposals"
            title={strings.equipmentProposals}
            className={"h-100 w-100"}
          >
            <div className={"w-100 h-100"}>
              <div className={"row table-responsive mt-4"}>
                <table id="keywords" className={"mb-1"}>
                  <thead>
                    <tr className="userList">
                      <th scope={"col"}>{strings.firstName}</th>
                      <th scope={"col"}>{strings.lastName}</th>
                      <th scope={"col"}>{strings.equipmentProposalName}</th>
                      <th scope={"col"}>{strings.equipmentQuantity}</th>
                      <th scope={"col"}>{strings.description}</th>
                      <th scope={"col "} className={"pe-4"}>
                        {strings.status}
                      </th>
                      <th scope={"col"}></th>
                      <th scope={"col"}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {equipmentProposals.length > 0 &&
                      equipmentProposals.map((proposal) => (
                        <tr>
                          <td>{proposal.user.firstName}</td>
                          <td>{proposal.user.lastName}</td>
                          <td>{proposal.equipmentNomenclatureEntry.name}</td>
                          <td>{proposal.quantity}</td>
                          <td>{proposal.description}</td>
                          <td>
                            <b
                              className={`pb-2 px-2 rounded-2 ${getStatusColorClass(
                                proposal.status
                              )}`}
                            >
                              {checkStatus(proposal.status)}
                            </b>
                          </td>
                          <td scope={"col"}>
                            <div className={"icon-div"}>
                              <div
                                onClick={() => {
                                  handleAddRequestModal(proposal);
                                }}
                                className={"text-dark icon"}
                                title={"Edit"}
                              >
                                <i
                                  className={"edit-icon-circle material-icons"}
                                >
                                  create
                                </i>
                              </div>
                            </div>
                          </td>
                          <td scope={"col"}>
                            <div className={"icon-div"}>
                              <div
                                onClick={() =>
                                  ConfirmationDeleteProposal(
                                    proposal.id
                                  )
                                }
                                className={"text-dark icon"}
                                title={"Delete"}
                              >
                                <i
                                  className={
                                    "material-icons delete-icon-circle"
                                  }
                                >
                                  delete
                                </i>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {equipmentProposals.length === 0 && (
                  <h3 className={"text-center my-5"}>
                    <i className={"text-secondary no-notification-message"}>
                      {strings.noActiveEquipmentProposals}
                    </i>
                  </h3>
                )}
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
     
      </>
  );
                }
export default AllEquipment;
