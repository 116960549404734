import React, {useEffect, useState} from "react";
import {strings} from "../../../../localization/Localization";
import EventGroupService from "../../../../repository/eventsRepository/EventGroupRepository";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DraggableModalDialog from "../../../../components/DraggableModalDialog/DraggableModalDialog";

const AddOrEditEventGroup = (props) => {

    const today = new Date();

    const formattedToday = today.toISOString().split('T')[0];

    const [formData, updateFormData] = useState({
        startDate: "",
        endDate: "",
        capacity: "",
        status: "",
        trainer: "",
        location: "",
    });

    useEffect(() => {

    }, [formData])

    useEffect(() => {
        if (!props.showModal) {
            resetData();
        } else {
            if (props.selectedEventGroupForEdit?.eventGroupId) {
                updateFormData({
                    startDate: props.selectedEventGroupForEdit.startDate,
                    endDate: props.selectedEventGroupForEdit.endDate,
                    capacity: props.selectedEventGroupForEdit.capacity,
                    status: props.selectedEventGroupForEdit.status,
                    trainer: props.selectedEventGroupForEdit.trainer,
                    location: props.selectedEventGroupForEdit.location,
                })
            }
        }

    }, [props.showModal]);

    const resetData = () => {
        updateFormData({
            startDate: "",
            endDate: "",
            capacity: "",
            status: "",
            trainer: "",
        })
    };


    const handleChange = (e) => {
        const {name, value} = e.target;

        let newValue = value;

        if (e.target.hasAttribute("required") && value.trim() === "") {
            e.target.setCustomValidity(strings.fieldsRequired);
          } else {
            e.target.setCustomValidity("");
          }

        if (name === 'capacity') {
            newValue = parseInt(value);
        }

        if (name === 'endDate') {
            const currentDate = new Date().toISOString().split("T")[0];
            newValue = value;
            const status = value > currentDate ? 'active' : 'inactive';
            updateFormData({
                ...formData,
                [name]: newValue,
                status: status,
            });
        } else {
            updateFormData({
                ...formData,
                [name]: newValue,
            });
        }
    };
    const eventGroupAddSuccessfulAlert = () => {
        swal(strings.userEditSuccessful, {
            icon: "success",
        });
    };

    const errorAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };


    const addEventGroup = (startDate, endDate, capacity, status, trainer, location) => {
        EventGroupService.addEventGroup(props.event?.eventId, startDate, endDate, capacity, status, trainer, location)
            .then(() => {
                props.handleClose();
                eventGroupAddSuccessfulAlert();
                props.getEventDetails();
            }).catch(() => {
            errorAlert()

        })
    }

    const editEventGroup = (startDate, endDate, capacity, status, trainer, location) => {
        EventGroupService.editEventGroup(props.selectedEventGroupForEdit.eventGroupId, props.selectedEventGroupForEdit.eventId, startDate, endDate, capacity, status, trainer, location)
            .then(() => {
                props.handleClose();
                eventGroupAddSuccessfulAlert();
                props.getEventDetails();
            }).catch(() => {
            errorAlert()
        })
    }


    const onFormSubmit = (e) => {
        e.preventDefault();
        const startDate = formData.startDate !== "" ? formData.startDate : null;
        const endDate = formData.endDate !== "" ? formData.endDate : null;
        const capacity = formData.capacity !== "" ? formData.capacity : null;
        const status = formData.status !== "" ? formData.status : null;
        const trainer = formData.trainer !== "" ? formData.trainer : null;
        const location = formData.location !== "" ? formData.location : null;

        if (props.selectedEventGroupForEdit?.eventGroupId) {
            editEventGroup(startDate, endDate, capacity, status, trainer, location);
        } else {
            addEventGroup(startDate, endDate, capacity, status, trainer, location);
        }
    }

    return (
        <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={"lg"} centered>
            <Modal.Header closeButton>
                <Modal.Title>{strings.addEventGroup}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={onFormSubmit}>
                <Modal.Body>
                    <Row className={"mb-2"}>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.capacity}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type={"number"}
                                    value={formData.capacity}
                                    onChange={handleChange}
                                    name={"capacity"}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.status}
                                </Form.Label>
                                <Form.Control
                                    disabled
                                    placeholder={strings.statusPlaceholder}
                                    type={"text"}
                                    value={formData.status}
                                    onChange={handleChange}
                                    name={"status"}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.trainer}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type={"text"}
                                    value={formData.trainer}
                                    onChange={handleChange}
                                    name={"trainer"}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.location}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type={"text"}
                                    value={formData.location}
                                    onChange={handleChange}
                                    name={"location"}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.startDate}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type={"date"}
                                    required
                                    value={formData.startDate}
                                    onChange={handleChange}
                                    name={"startDate"}
                                    min={formattedToday}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.endDate}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type={"date"}
                                    required
                                    value={formData.endDate}
                                    onChange={handleChange}
                                    name={"endDate"}
                                    min={formData.startDate}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className={"cancel btn text-white"} onClick={props.handleClose}>
                        {strings.cancel}
                    </div>
                    <button type={"submit"} className={"save btn text-white"}>
                        {strings.save}
                    </button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddOrEditEventGroup;