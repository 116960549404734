import React, {useEffect} from "react";
import {useState} from "react";
import "./DeiveredRequestStatusManager.css"
import {strings} from "../../../../localization/Localization";

const DeliveredRequestStatusManager = (props) => {

    const [isActive, setIsActive] = useState({
        decline: false,
        approve: false,
        returnForCorrection: false
    })

    useEffect(()=>{
        if(props.userAlreadyHaveSickLeaveRequest){
            props.setSelectedStatusAndDescriptionForResponse({
                ...props.selectedStatusAndDescriptionForResponse,
                description: strings.defaultDescriptionForHavingSickLeaveDuring
            });

        }
    },[props.userAlreadyHaveSickLeaveRequest])

    const handleDescriptionChange = (e) => {
        props.setSelectedStatusAndDescriptionForResponse({
            ...props.selectedStatusAndDescriptionForResponse,
            description: e.target.value
        });
    };

    const setActiveStatus = (statusClicked) => {
        if (statusClicked === "approve") {
            setIsActive({
                decline: false,
                approve: true,
                returnForCorrection: false
            })
            props.setSelectedStatusAndDescriptionForResponse({
                ...props.selectedStatusAndDescriptionForResponse,
                responseStatus: "APPROVED"
            });
        } else if (statusClicked === "returnForCorrection") {
            setIsActive({
                decline: false,
                approve: false,
                returnForCorrection: true
            })
            props.setSelectedStatusAndDescriptionForResponse({
                ...props.selectedStatusAndDescriptionForResponse,
                responseStatus: "RETURNED FOR CORRECTION"
            });
        } else if (statusClicked === "decline") {
            setIsActive({
                decline: true,
                approve: false,
                returnForCorrection: false
            })
            props.setSelectedStatusAndDescriptionForResponse({
                ...props.selectedStatusAndDescriptionForResponse,
                responseStatus: "DECLINED"
            });
        }
    };

    const handleLeaveRequestResponse = (statusClicked) => {
        if (statusClicked === "approve") {
        } else if (statusClicked === "returnForCorrection") {
        } else if (statusClicked === "decline") {
        }
    };

    return (
        <>
            <div className={"d-flex justify-content-around mx-auto my-2 w-75 "}>
                <div><a disabled={true}
                        className={("btn button-approve " + ((isActive.approve ? "active-button active-button-approve" : "")) + (props.userAlreadyHaveSickLeaveRequest ? "  button-approve-disabled " : ""))}
                        onClick={() => {
                            if (!props.userAlreadyHaveSickLeaveRequest) {
                                setActiveStatus("approve");
                                handleLeaveRequestResponse("approve")
                            }
                        }}>{strings.approveRequest}</a></div>
                <div><a
                    className={"btn button-return " + (isActive.returnForCorrection ? "active-button active-button-return" : "")}
                    onClick={() => {
                        setActiveStatus("returnForCorrection");
                        handleLeaveRequestResponse("returnForCorrection")
                    }}>{strings.returnRequest}</a></div>
                <div><a
                    className={"btn button-decline " + (isActive.decline ? "active-button active-button-decline" : "")}
                    onClick={() => {
                        setActiveStatus("decline");
                        handleLeaveRequestResponse("decline")
                    }}>{strings.declineRequest}</a></div>

            </div>
            {(isActive.returnForCorrection || isActive.decline) &&
                <>
                    <h4 className={"text-center"}>{strings.responseDescription}<span className="text-danger">*</span></h4>
                    <div className={"w-100 text-center mb-4 align-items-center d-flex justify-content-center"}>
                    <textarea className={"w-75 form-control text-center free-text"}
                              onChange={handleDescriptionChange} required={true}
                              defaultValue={props.userAlreadyHaveSickLeaveRequest ? strings.defaultDescriptionForHavingSickLeaveDuring : ""}></textarea>
                    </div>
                </>
            }
        </>
    );
}
export default DeliveredRequestStatusManager