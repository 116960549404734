import React from "react";
import {strings} from "../../../../../localization/Localization";
import "./AddRemoveUserFromHoliday.css"

const AddRemoveUserFromHoliday = (props) => {

    const removeUserFromList = (userId) => {
        if (document.getElementById(userId) != null) {
            document.getElementById(userId).checked = false;
        }
        props.setSelectedList(props.removeUserFormList(props.users, userId));
    }

    return (
        <div className={"mx-auto holiday__users__list__add__remove"}>
            <h5 className={"my-2"}>{props.listTitle}</h5>
            <div className={"h-75"}>
                <div
                    className={"w-100  h-100 bg-light p-3 border-1 border-radius responsive-y-wrapper max-height-user-table  min-height-user-table"}>
                    {props.users.length > 0 ?
                        (props.users.map((user) => {
                                return (
                                    <div key={user.id} scope={"row"} className={"d-flex"}>
                                        <div scope="col" className={"ms-2"}>
                                            <div className={"text-danger material-icons cursor-pointer"}
                                                 title={"remove"}
                                                 onClick={() => removeUserFromList(user.id)}>clear
                                            </div>
                                        </div>
                                        <div className={"ms-2"}>{user.firstName} {user.lastName}</div>
                                    </div>
                                )
                            })

                        ) :
                        (
                            <div className={"justify-content-center w-100 text-center"}>
                                <div>
                                    <h6 className={"text-secondary"}>
                                        <i>{strings.emptyList}</i></h6>
                                </div>
                            </div>
                        )
                    }

                </div>
                {props.users.length > 0 &&
                    <div className={"d-flex  justify-content-between"}>
                        <div className={"my-2"}>
                            {strings.selected}: {props.users.length}
                        </div>
                        <div className={"text-end my-1"}>
                            <div className={"btn btn-sm text-white save mb-4"} onClick={() => {
                                props.confirmAlert();
                            }}>{props.buttonTitle}
                            </div>
                        </div>
                    </div>
                }
            </div>

        </div>
    );

}
export default AddRemoveUserFromHoliday;