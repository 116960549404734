import React, {useEffect, useState} from "react"
import {Form, Modal, ModalBody, ModalHeader, ModalTitle} from "react-bootstrap";
import {strings} from "../../../localization/Localization";
import swal from "sweetalert";
import NomenclatureService from "../../../repository/nomenclatureRepository/NomenclatureRepository";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const NomenclatureModal = (props) => {

    const [formData, updateFormData] = useState({
        name: "",
    });

    useEffect(() => {
        if (props.noemnclatureForEdit.id) {
            updateFormData({name: props.noemnclatureForEdit.name})
        } else {
            resetFormData();
        }
    }, [props.showModal])

    const resetFormData = () => {
        updateFormData({
            name: "",
        });
    };

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const errorAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const handleChange = (e) => {
        const value = e.target.value;
        if (e.target.hasAttribute("required") && value.trim() === "") {
          e.target.setCustomValidity(strings.fieldsRequired);
        } else {
          e.target.setCustomValidity("");
        }
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    };

    const addNomenclature = (name) => {
        NomenclatureService.addNomenclature(name)
            .then(() => {
                props.getAllNoemnclature();
                props.handleClose();
                successfulAlert();
            }).catch(() => {
            errorAlert();
        });
    };

    const editNomenclature = (name,id) => {
        NomenclatureService.editNomenclature(name,id)
            .then((data) => {
                props.getAllNoemnclature();
                props.handleClose();
                successfulAlert();
            }).catch(() => {
            errorAlert();
        });

    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        if (props.noemnclatureForEdit.id) {
            editNomenclature(formData.name, props.noemnclatureForEdit.id);
        } else {
            addNomenclature(formData.name);
        }
    };

    return (
        <>
            <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose}   dialogClassName="nomenclature-modal" size={"sm"} centered>
                <ModalHeader closeButton>

                    <ModalTitle><b> {props.noemnclatureForEdit.id ? strings.editNomenclature : strings.addNomenclature} </b></ModalTitle>
                </ModalHeader>
                <Form onSubmit={onFormSubmit}>
                    <ModalBody>
                        <Form.Group className="mb-3" controlId="nomenclatureName">
                            <Form.Label>{strings.name}<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                onChange={handleChange}
                                autoFocus
                                value={formData?.name}
                                name="name"
                                required
                                onInvalid={(e) => {
                                    e.target.setCustomValidity(strings.fieldsRequired);
                                  }}
                                />
                        </Form.Group>
                    </ModalBody>
                    <Modal.Footer>
                        <div className={"cancel btn text-white"} onClick={props.handleClose}>
                            {strings.cancel}
                        </div>
                        <button type={"submit"} className={"save btn text-white"}>
                            {strings.save}
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
export default NomenclatureModal;