import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { strings } from '../../../../../localization/Localization';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import swal from 'sweetalert';
import NomenclatureService from '../../../../../repository/nomenclatureRepository/NomenclatureRepository';
import ApplicationService from '../../../../../ApplicationService';
import UserEducationService from '../../../../../repository/UserEducationRepository/UserEducationRepository';
import DraggableModalDialog from '../../../../../components/DraggableModalDialog/DraggableModalDialog';

const UserEducationModal = (props) => {

  const [formData, updateFormData] = useState({
    institution: '',
    area: '',
    acknowledged: '',
    startDate: '',
    endDate: '',
    selectedNomenclatureEntry: ''
  });

  const [nomenclatureList, setNomenclatureList] = useState([]);
  const [minEndDate, setMinEndDate] = useState('');

  useEffect(() => {
    if (!props.showModal) {
      resetData();
    } else {
      getAllNomenclature();
      if (props.selectedEducationForEdit.id) {
        updateFormData({
            institution: props.selectedEducationForEdit.institution,
            area: props.selectedEducationForEdit.area,
            acknowledged: props.selectedEducationForEdit.isAcknowledged,
            startDate: props.selectedEducationForEdit.startDate.split('T')[0],
            endDate: props.selectedEducationForEdit?.endDate?.split('T')[0],
            selectedNomenclatureEntry:
              {
                value: props.selectedEducationForEdit?.nomenclatureEntryHelper?.id,
                label: ApplicationService.getActiveLanguage() == 'mk' ? props.selectedEducationForEdit?.nomenclatureEntryHelper?.labelMk : props.selectedEducationForEdit?.nomenclatureEntryHelper?.labelEn
              }
          }
        );
      }
    }
  }, [props.showModal]);

  const handleChange = (e) => {
    const value = e.target.value;
    if (e.target.hasAttribute("required") && value.trim() === "") {
      e.target.setCustomValidity(strings.fieldsRequired);
    } else {
      e.target.setCustomValidity("");
    }
    if (e.target.name !== 'acknowledged') {
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
      if (e.target.name === 'startDate') {
        setMinEndDate(e.target.value);
      }
    } else {
      updateFormData({
        ...formData,
        'acknowledged': e.target.checked
      });
    }

  };

  const handleNomenclatureSelectChange = (e) => {
    updateFormData({
      ...formData,
      selectedNomenclatureEntry: e
    });
  };

  const resetData = () => {
    updateFormData({
      education: '',
      institution: '',
      area: '',
      acknowledged: '',
      startDate: '',
      endDate: '',
      selectedNomenclatureEntry: ''
    });
  };

  const getAllNomenclature = () => {
    NomenclatureService.getAllNomenclatureEntryByNomenclatureName('Образование')
      .then((data) => {
        let nomenclatureConvert = data.data.map((n) => {
          return {
            value: n.id,
            label: ApplicationService.getActiveLanguage() == 'mk' ? n.labelMk : n.labelEn
          };
        });
        setNomenclatureList(nomenclatureConvert);
      });
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: 'success',
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: 'error',
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const addUserEducation = (institution, area, startDate, endDate, acknowledged, selectedNomenclatureEntryId) => {
    props.setShowSpinner(false);
    if (props.param.id) {
      UserEducationService.addUserEducationByAdministrator(institution, area, startDate, endDate, acknowledged, selectedNomenclatureEntryId, props.param.id)
        .then(() => {
          props.setShowSpinner(true);
          successfulAlert();
          props.getAllEducationForUser();
          props.handleClose();
        }).catch(() => {
        props.setShowSpinner(true);
        warningAlert();
      });
    } else {
      UserEducationService.addUserEducation(institution, area, startDate, endDate, acknowledged, selectedNomenclatureEntryId)
        .then(() => {
          props.setShowSpinner(true);
          successfulAlert();
          props.getAllEducationForUser();
          props.handleClose();
        }).catch(() => {
        props.setShowSpinner(true);
        warningAlert();
      });
    }
  };

  const editUserEducation = (institution, area, startDate, endDate, acknowledged, selectedNomenclatureEntryId, selectedEducationForEditId) => {
    props.setShowSpinner(false);
    UserEducationService.editUserEducation(institution, area, startDate, endDate, acknowledged, selectedNomenclatureEntryId, selectedEducationForEditId)
      .then(() => {
        props.setShowSpinner(true);
        successfulAlert();
        props.getAllEducationForUser();
        props.handleClose();
      }).catch(() => {
      props.setShowSpinner(true);
      warningAlert();
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (props.selectedEducationForEdit.id) {
      editUserEducation(formData.institution, formData.area, formData.startDate, formData.endDate, formData.acknowledged, formData.selectedNomenclatureEntry?.value, props.selectedEducationForEdit.id);
    } else {
      addUserEducation(formData.institution, formData.area, formData.startDate, formData.endDate, formData.acknowledged, formData.selectedNomenclatureEntry?.value);
    }
    e.stopPropagation();
  };

  return (
    <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={'lg'} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.selectedEducationForEdit.id ? strings.editEducation : strings.addEducation}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={onFormSubmit}>
        <Modal.Body>
          <Row className={'mb-2'}>
            <Col xs={12} md={8}>
              <Form.Group className='d-flex '
                          controlId='exampleForm.ControlInput1'>
                <Form.Group className={'d-flex my-auto mt-1'}>
                  <Form.Label className={'me-3'}>
                    {strings.acknowledged}
                  </Form.Label>
                  <div className={'mt-1'}>
                    <label className='switchEnabled'>
                      <input type='checkbox' checked={formData.acknowledged} name={'acknowledged'}
                             onChange={handleChange}/>
                      <span className='sliderEnabled round'> </span>
                    </label>
                  </div>
                </Form.Group>
              </Form.Group>
            </Col>
          </Row>
          <Row className={'mb-2'}>
            <Col xs={12} md={6}>
              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                <Form.Label className={'d-flex'}>
                  {strings.institution}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type={'text'}
                  name={'institution'}
                  onChange={handleChange}
                  value={formData.institution}
                  required
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}/>
                  
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                <Form.Label className={'d-flex'}>
                  {strings.area}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type={'text'}
                  name={'area'}
                  value={formData.area}
                  required
                  onChange={handleChange}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}/>
              </Form.Group>
            </Col>
          </Row>
          <Row className={'mb-2'}>
            <Col xs={12} md={6}>
              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                <Form.Label className={'d-flex'}>
                  {strings.startDate}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type={'date'}
                  name={'startDate'}
                  onChange={handleChange}
                  value={formData.startDate}
                  required
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}/>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                <Form.Label className={'d-flex'}>
                  {strings.endDate}
                </Form.Label>
                <Form.Control
                  type={'date'}
                  name={'endDate'}
                  onChange={handleChange}
                  value={formData.endDate}
                  min={minEndDate}/>
              </Form.Group>
            </Col>
          </Row>
          <Row className={'mb-2'}>
            <Col xs={12} md={6}>
              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                <Form.Label className={'d-flex'}>
                  {strings.educationLevel}<span className="text-danger">*</span>
                </Form.Label>
                <Select
                  name={'selectedNomenclatureEntry'}
                  options={nomenclatureList}
                  required
                  onChange={handleNomenclatureSelectChange}
                  placeholder={strings.selectOption}
                  noOptionsMessage={()=>strings.noOptions}
                  value={formData.selectedNomenclatureEntry}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}/>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className={'cancel btn text-white'} onClick={props.handleClose}>
            {strings.cancel}
          </div>
          <button type={'submit'} className={'save btn text-white'}>
            {strings.save}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default UserEducationModal;