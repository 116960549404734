import Modal from "react-bootstrap/Modal";
import { strings } from "../../../../../localization/Localization";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ApplicationService from "../../../../../ApplicationService";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import BenefitProposalService from "../../../../../repository/benefitsRepository/BenefitsProposalRepository";
import DraggableModalDialog from "../../../../../components/DraggableModalDialog/DraggableModalDialog";

const AddEditBenefitProposalModal = (props) => {
  const history = useHistory();
  const [formData, updateFormData] = useState({
    name: "",
    description: "",
    proposalFrom: "",
  });

  useEffect(() => {
    if (!props.showModal) {
      resetData();
    }
    if (props.selectedBenefitProposal) {
      if (props.selectedBenefitProposal?.benefitProposalId) {
        updateFormData({
          name: props.selectedBenefitProposal?.name,
          description: props.selectedBenefitProposal?.description,
          proposalFrom:
            props.selectedBenefitProposal?.user?.firstName +
            " " +
            props.selectedBenefitProposal?.user?.lastName,
        });
      }
    }
  }, [props.selectedBenefitProposal, props.showModal]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetData = () => {
    updateFormData({
      name: "",
      description: "",
    });
  };

  const benefitProposalAddSuccessfulAlert = () => {
    swal(strings.userEditSuccessful, {
      icon: "success",
    });
  };

  const DenyBenefitProposal = (id) => {
    swal(strings.deleteConfirmation, {
      title: strings.areYouSure,
      icon: "warning",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        denyBenefitProposal(id);
      }
    });
  };

  const denyBenefitProposal = (id) => {
    BenefitProposalService.dennyBenefitProposal(id)
      .then(() => {
        benefitProposalAddSuccessfulAlert();
        props.handleClose();
        props.getBenefitProposals();
        props.getMyBenefitProposals();
      })
      .catch(() => {
        errorAlert();
      });
  };

  const errorAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const addBenefitProposal = (name, description) => {
    BenefitProposalService.addBenefitProposal(name, description).then(
      (response) => {
        if (response.data === "Benefit Proposal added successfully") {
          props.handleClose();
          benefitProposalAddSuccessfulAlert();
          props.getBenefitProposals();
          props.getMyBenefitProposals();
        } else {
          errorAlert();
        }
      }
    );
  };

  const editBenefitProposal = (benefitProposalId, name, description) => {
    BenefitProposalService.editBenefitProposal(
      benefitProposalId,
      name,
      description
    )
      .then(() => {
        props.handleClose();
        props.getBenefitProposals();
        props.getMyBenefitProposals();
      })
      .catch(() => {});
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const name = formData.name !== "" ? formData.name : null;
    const description =
      formData.description !== "" ? formData.description : null;

    if (props.selectedBenefitProposal?.benefitProposalId) {
      editBenefitProposal(
        props.selectedBenefitProposal?.benefitProposalId,
        name,
        description
      );
    } else {
      addBenefitProposal(name, description);
    }
  };

  return (
    <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size="gl" centered>
      <Modal.Header closeButton>
        <Modal.Title>{strings.addBenefitProposal}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <Modal.Body>
          <Row className={"mb-2"}>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className={"d-flex"}>
                  {strings.proposalName}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  type={"text"}
                  value={formData.name}
                  onChange={handleChange}
                  name={"name"}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className={"mb-2"}>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className={"d-flex"}>
                  {strings.description}
                </Form.Label>
                <div className={"my-3"}>
                  <span
                    className={
                      "gray-background py-2 px-3 rounded-3 d-flex align-items-center"
                    }
                  >
                    <i className={"material-icons me-1 fs-22"}>error_outline</i>
                    <span className={"fs-15"}>
                      {strings.explainBenefitProposal}<span className="text-danger">*</span>
                    </span>
                  </span>
                </div>
                <Form.Control
                  required
                  as="textarea"
                  rows={4}
                  value={formData.description}
                  onChange={handleChange}
                  name={"description"}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {props.selectedBenefitProposal ? (
            <>
              {ApplicationService.hasRole([
                "ROLE_ADMINISTRATOR",
                "ROLE_HR",
              ]) && (
                <div className={"me-auto"}>
                  <button
                    type="button"
                    className={"cancel btn text-white m-1"}
                    onClick={() =>
                      DenyBenefitProposal(
                        props.selectedBenefitProposal?.benefitProposalId
                      )
                    }
                  >
                    {strings.deny}
                  </button>
                  <button
                    className={"save btn text-white m-1"}
                    onClick={() => {
                      history.push("/nomenclature", {
                        nomenclatureEntry: "Бенефиции",
                        benefitProposal: props.selectedBenefitProposal,
                      });
                    }}
                  >
                    {strings.accept}
                  </button>
                </div>
              )}
              <div
                className={"cancel btn text-white"}
                onClick={() => {
                  props.handleClose();
                  resetData();
                }}
              >
                {strings.cancel}
              </div>
            </>
          ) : (
            <>
              <div
                className={"cancel btn text-white"}
                onClick={() => {
                  props.handleClose();
                  resetData();
                }}
              >
                {strings.cancel}
              </div>
              <button type={"submit"} className={"save btn text-white m-1"}>
                {strings.save}
              </button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEditBenefitProposalModal;
