import React from "react";
import {Form, FormControl, Modal, ModalBody, ModalHeader, ModalTitle} from "react-bootstrap";
import {strings} from "../../../localization/Localization";
import Button from "react-bootstrap/Button";
import {useHistory} from "react-router-dom";
import OrganisationHierarchyService from "../../../repository/hierarchyRepository/organisationHierarchyRepository";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const OrganizationalHierarchyEditModal = (props) => {

    const history = useHistory();

    const [formData, updateFormData] = React.useState({
        hrmsStPositionsHId: "",
        activeFrom: "",
        activeTo: "",
        code: "",
        name: "",
        shortName: "",
        description: "",
        dateCreated: "",
        dateModified: "",
        parentId: ""
    });

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    };

    const editHierarchy = (id, hrmsStPositionsHId, activeFrom, activeTo, code, name, shortName, description, dateCreated, dateModified, childrenOrganisations) => {
        OrganisationHierarchyService.editHierarchy(id, hrmsStPositionsHId, activeFrom, activeTo, code, name, shortName, description, dateCreated, dateModified, childrenOrganisations)
            .then((data) => {
                props.loadHierarchy(true);
                props.handleClose();
            });
    };

    const onFormSubmit = (e) => {
        e.preventDefault();

        const hrmsStPositionsHId = formData.hrmsStPositionsHId !== "" ? formData.emhrmsStPositionsHIdail : props.selectedNode.emhrmsStPositionsHIdail;
        const activeFrom = formData.activeFrom !== "" ? formData.activeFrom : props.selectedNode.activeFrom;
        const activeTo = formData.activeTo !== "" ? formData.activeTo : props.selectedNode.activeTo;
        const code = formData.code !== "" ? formData.code : props.selectedNode.data.code;
        const name = formData.name !== "" ? formData.name : props.selectedNode.text;
        const description = formData.description !== "" ? formData.description : props.selectedNode.data.description;
        const shortName = formData.shortName !== "" ? formData.shortName : props.selectedNode.data.shortName;
        const dateCreated = "";
        const dateModified = "";

        if (props.selectedNode.id)
            editHierarchy(props.selectedNode.id, hrmsStPositionsHId, activeFrom, activeTo, code, name, shortName, description, dateCreated, dateModified, props.selectedNode.data.parentId);
    };

    return (
        <>
            <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={"lg"} centered>
                <ModalHeader closeButton>
                    <ModalTitle><b>{strings.editOrganisation}</b></ModalTitle>
                </ModalHeader>
                <Form onSubmit={onFormSubmit}>
                    <ModalBody>
                        <Form.Group className="mb-3" controlId="hierarchyName">
                           <Row>
                               <Col xs={12} md={12} className={"mb-2"}>
                                   <Form.Group>
                                       <Form.Label> {strings.organisationName}</Form.Label>
                                       <Form.Control
                                           disabled
                                           type="text"
                                           defaultValue={props.selectedNode?.text}
                                           onChange={handleChange}
                                           autoFocus
                                           name="name"/>
                                   </Form.Group>
                               </Col>

                               <Col xs={12} md={12} className={"mb-2"}>
                                   <Form.Group>
                                       <Form.Label>{strings.organisationShortName}</Form.Label>
                                       <Form.Control
                                           disabled
                                           type="text"
                                           defaultValue={props.selectedNode?.data?.shortName}
                                           onChange={handleChange}
                                           name="shortName"/>
                                   </Form.Group>
                               </Col>
                               <Col xs={12} md={12} className={"mb-2"}>
                                   <Form.Group>
                                       <Form.Label>{strings.organisationDescription}</Form.Label>
                                       <textarea
                                           disabled
                                           name="description" className="form-control"
                                           defaultValue={props.selectedNode?.data?.description}
                                           onChange={handleChange}
                                           id="exampleFormControlTextarea1" rows="2"/>
                                   </Form.Group>
                               </Col>
                               <Col xs={12} md={12} className={"mb-2"}>
                                   <Form.Group>
                                       <Form.Label>{strings.organisationCode}</Form.Label>
                                       <textarea
                                           disabled
                                           name="code" className="form-control"
                                           defaultValue={props.selectedNode?.data?.code}
                                           onChange={handleChange}
                                           id="exampleFormControlTextarea1" rows="2"/>
                                   </Form.Group>
                               </Col>
                               <Col xs={12} md={6} className={"mb-2"}>
                                   <Form.Group>
                                       <Form.Label>{strings.activeFrom}</Form.Label>
                                       <FormControl type="date"
                                                    name="activeFrom"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    defaultValue={props.selectedNode?.data?.activeFrom}
                                                    id="exampleFormControlTextarea1"/>
                                   </Form.Group>
                               </Col>
                               <Col xs={12} md={6} className={"mb-2"}>
                                   <Form.Group>
                                       <Form.Label>{strings.activeTo}</Form.Label>
                                       <FormControl type="date"
                                                    name="activeTo"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    defaultValue={props.selectedNode?.data?.activeTo}
                                                    id="exampleFormControlTextarea1"/>
                                   </Form.Group>
                               </Col>
                           </Row>
                        </Form.Group>
                    </ModalBody>
                    <Modal.Footer>
                        <div className={"cancel btn text-white"} onClick={props.handleClose}>
                            {strings.cancel}
                        </div>
                        <div type={"submit"} className={"save btn text-white"} onClick={onFormSubmit}>
                            {strings.save}
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
export default OrganizationalHierarchyEditModal;