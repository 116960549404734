import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import MultiSelectDropdown from "../MultiSelectDropdown";
import {strings} from "../../localization/Localization";
import TechnologyService from "../../repository/projectTechnologyRepository/projectTechnologyRepository";
import "./ProjectFilter.css";

const FilterProjects = (props) => {
    const [technologies, setTechnologies] = useState([]);

    const [selectedTechnology, setSelectedTechnology] = useState([]);

    const [listTechnologiesForDropDown, setListTechnologiesForDropDown] = useState([]);

    const [resetAllSelectInput, setResetAllSelectInput] = useState(true);

    const [formData, updateFormData] = React.useState({
        projectName: "",
        dateBeginning: "",
        dateEnding: "",
        isActive: ""
    })

    useEffect(() => {
        getAllProjectTechnologies();
    }, [])

    useEffect(() => {
        if (technologies.length > 0) {
            setListTechnologiesForDropDown(convertTechnologyForDropDown(technologies));
        }
    }, [technologies]);


    const getAllProjectTechnologies = () => {
        TechnologyService.getAllTechnologies()
            .then((data) => {
                setTechnologies(data.data);
            })
            .catch((error) => {
                console.error("Error fetching technologies:", error);
            });
    };

    const handleChange = (e) => {
        setResetAllSelectInput(false);
        updateFormData({
            ...formData, [e.target.name]: e.target.value
        })
    };

    const filterProjects = (projectName, dateStarted, dateEnded, isActive, projectTechnologies) => {
        props.loadProjectFiltered(projectName, dateStarted, dateEnded, isActive, projectTechnologies)
    };

    const onFormSubmit = (e, reset = false, search = false) => {
        e.preventDefault();
        search = !reset;
        let projectName = formData.projectName !== "" ? formData.projectName  : null;
        let dateBeginning = formData.dateBeginning !== "" ? formData.dateBeginning : null;
        let dateEnded = formData.dateEnding !== "" ? formData.dateEnding : null;
        let isActive = formData.isActive !== "" ? formData.isActive : null;
        let projectTechnologies = selectedTechnology.length > 0 ? selectedTechnology : [];
        if (reset) {
            projectName = null;
            dateBeginning = null;
            dateEnded = null;
            isActive = null;
            projectTechnologies = [];
        }
        filterProjects(projectName, dateBeginning, dateEnded, isActive, projectTechnologies);
    };

    const resetFilterData = (event) => {
        updateFormData({
            projectName: "", dateBeginning: "", dateEnding: "", isActive: ""
        })
        setSelectedTechnology([]);
        setResetAllSelectInput(true);
        props.resetFilterData();
        onFormSubmit(event, true);
    };

    const getSelectedTechnologiesFromDropDown = (selectedOptions) => {
        setSelectedTechnology(selectedOptions.map(el => el.value));
    }

    const convertTechnologyForDropDown = (data) => {
        return data.map((item) => ({
            value: item.id.toString(),
            label: item.name
        }));
    };

    return (
        <>
            <div className="card border-secondary mb-3">
                <div className="card-header justify-content-center">
                    <div className="m-2 p-2 text-center justify-content-center">
                        <Form onSubmit={onFormSubmit}>
                            <Row className="d-flex justify-content-center">
                                <Col xs={12} md={4} className="d-flex justify-content-center">
                                    <Form.Group className="mb-3" controlId='projectName'>
                                        <Form.Label className="d-flex justify-content-start">{strings.projectName}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name='projectName'
                                            placeholder="Filter project name..."
                                            onChange={handleChange}
                                            value={formData.projectName}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Form.Group className="mb-2" controlId='dateBeginning'>
                                        <Form.Label className="d-flex justify-content-start">{strings.dateFrom}</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name='dateBeginning'
                                            placeholder="Filter beginning date..."
                                            onChange={handleChange}
                                            value={formData.dateBeginning}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Form.Group className="mb-2" controlId='dateEnding'>
                                        <Form.Label className="d-flex justify-content-start">{strings.dateTo}</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name='dateEnding'
                                            placeholder="Filter ending date..."
                                            onChange={handleChange}
                                            value={formData.dateEnding}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-4 mt-2 w-75 mx-auto" controlId='isActive'>
                                        <Form.Label className={"justify-content-start text-start align-items-start d-flex"}>{strings.projectStatus}</Form.Label>
                                        <Form.Select
                                            name='isActive'
                                            onChange={handleChange}
                                            value={formData.isActive}
                                        >
                                            <option value="">All</option>
                                            <option value="true">Active</option>
                                            <option value="false">Not Active</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-4 mt-2 w-75 mx-auto" controlId='technology'>
                                        <Form.Label className={"justify-content-start text-start align-items-start d-flex"}>{strings.technologies}</Form.Label>
                                        <MultiSelectDropdown handleSelection={getSelectedTechnologiesFromDropDown}
                                                             listForDropDown={listTechnologiesForDropDown}
                                                             defaultListForDropdown={selectedTechnology}
                                                             resetData={resetAllSelectInput}
                                                             setResetData={setResetAllSelectInput}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className={"mt-4"}>
                                {!props.resetIsLoading ? (
                                    <div className="btn text-white align-bottom align-items-end user-filter__reset_button me-5 user-filter__button_size" onClick={event => resetFilterData(event)}>
                                        {strings.reset}
                                    </div>
                                ) : (
                                    <div className="btn text-white align-bottom align-items-end user-filter__reset_button user-filter__reset_button__disabled me-5 user-filter__button_size" type="button" disabled={true}>
                                        <span className="spinner-grow spinner-grow-sm me-1" role="status" aria-hidden="true"></span>
                                        {strings.reset}...
                                    </div>
                                )}
                                {!props.searchIsLoading ? (
                                    <div className="btn text-white align-bottom align-items-end add-button user-filter__button_size" onClick={onFormSubmit}>
                                        {strings.search}
                                    </div>
                                ) : (
                                    <div className="btn text-white align-bottom align-items-end add-button user-filter_search_button__disabled user-filter__button_size" type="button" disabled={true}>
                                        <span className="spinner-grow spinner-grow-sm me-1" role="status" aria-hidden="true"></span>
                                        {strings.search}...
                                    </div>
                                )}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterProjects;