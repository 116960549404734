import "./UserBenefits.css"
import {strings} from "../../../../localization/Localization";
import ApplicationService from "../../../../ApplicationService";
import FormatDate from "../../../../components/formatDate/FormatDate";

const UserBenefits = (props) => {

    const getStatusColorClass = (status) => {
        switch (status) {
            case "ACTIVE":
                return "accepted-color";
            case "INACTIVE":
                return "not-accepted-color";
            default:
                return "";
        }
    };

    const getStatus = (status) => {
        if (status === 'ACTIVE') {
            return strings.active
        } else if (status === 'INACTIVE') {
            return strings.inactive
        }
    }

    const statusColorClass = getStatusColorClass(props.userBenefit?.benefitStatus);


    return (
        <tr className="userlist py-auto cursor-pointer" key={props.userBenefit?.userBenefitId}>
            <td>
                <b>
                    {ApplicationService.getActiveLanguage() === "mk" ?
                        props.userBenefit.benefitNomenclatureEntryHelper?.labelMk :
                        props.userBenefit.benefitNomenclatureEntryHelper?.labelEn}
                </b>
            </td>
            <td>
                {FormatDate.formatDate(props.userBenefit.startDate)}
            </td>
            <td>
                {FormatDate.formatDate(props.userBenefit.endDate)}
            </td>
            <td>
                <b className={`pb-2 px-2 rounded-2 ${statusColorClass}`}>{getStatus(props.userBenefit.benefitStatus)}</b>
            </td>
        </tr>
    )

}

export default UserBenefits;