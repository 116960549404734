import axios from "../../axios/axios";

const EventsService = {
    getAllEvents: () => {
        return axios.get("/event/getAll")
    },

    getAllActiveEvents: () => {
        return axios.get("/event/getAllActive")
    },

    getAllInactiveEvents: () => {
        return axios.get("/event/getAllInactive")
    },

    getEventById: (id) => {
        return axios.get(`/event/get/${id}`);
    },

    getEventsForUserWithId: (id) => {
        return axios.get(`/userEvent/${id}/events`);
    },

    getEventsForLoggedInUser: () => {
      return axios.get("/userEvent/loggedInUser/events")
    },

    getAllCurrencies: () => {
        return axios.get("/event/getAllCurrencies")
    },

    deleteEvent: (id) => {
        return axios.delete(`/event/delete/${id}`)
    },

    addEvent: (newFormData) => {
        return axios.post("/event/add", newFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    editEvent: (newFormData) => {
        return axios.post("/event/update", newFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    acceptEventFromProposal: (eventProposalId, name, description, eventTypeId, status, certificate, organisation, cost, currency, trainer) => {
        return axios.post("/event/acceptEventFromProposal", {
            eventProposalId,
            name,
            description,
            eventTypeId,
            status,
            certificate,
            organisation,
            cost,
            currency,
            trainer
        })
    },
}

export default EventsService;