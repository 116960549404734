import React, { useEffect, useState } from 'react';
import ProjectService from '../../repository/projectRepository/projectRepository';
import { strings } from '../../localization/Localization';
import ProjectTerm from './ProjectTerm/ProjectTerm';
import AddButton from '../../components/addButton/AddButton';
import { useHistory } from 'react-router-dom';
import MyProjectService from '../../repository/projectsRepository/projectsRepository';
import ProjectFilter from '../../components/projectFilter/ProjectFilter';
import ReactPaginate from 'react-paginate';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import Select from 'react-select';

const AllProjects = () => {
  const [projectList, setProjectList] = useState([]);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(3);
  const [pageCount, setPageCount] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [showModal, setShow] = useState(false);
  const [filterProject, setFilterProject] = React.useState({
    projectName: null,
    technologies: [],
    isActive: null,
    dateBeginning: null,
    dateEnding: null
  });
  const [resetIsLoading, setResetIsLoading] = useState(false);
  const [searchIsLoading, setSearchIsLoading] = useState(false);

  useEffect(() => {
    const pageNumber = localStorage.getItem('allProjectListPage');
    const pageSizeNumber = localStorage.getItem('allProjectListSize');
    if (pageNumber) {
      setPage(pageNumber);
    }
    if (pageSizeNumber) {
      setSize(pageSizeNumber);
    }
  }, []);

  useEffect(() => {
    loadProjectsFiltered();
  }, [page, size]);

  const getAllProjects = () => {
    ProjectService.getAllProjects()
      .then((response) => {
        setProjectList(response.data);
      })
      .catch((error) => {
        console.error('Error fetching projects:', error);
      });
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handlePageClick = (data) => {
    let selected = data.selected;
    setPage(selected);
    handleLocalStoragePageNumber(data.selected);
  };

  const handleLocalStoragePageNumber = (pageNumber) => {
    localStorage.setItem('allProjectListPage', pageNumber);
  };

  const handleLocalStoragePageSizeNumber = (pageSizeNumber) => {
    localStorage.setItem('allProjectListSize', pageSizeNumber);
  };

  const loadProjectsFiltered = () => {
    MyProjectService.fetchProjectsFiltered(page, size, filterProject)
      .then((data) => {
        setPageCount(data.data.totalPages);
        setProjectList(data.data.content);
      });
  };

  const loadProjectAndPageFiltered = (projectName, dateBeginning, dateEnding, isActive, technologies, resetDate = false, searchData = false) => {
    if (resetDate) {
      setResetIsLoading(true);
    } else if (searchData) {
      setSearchIsLoading(true);
    }
    setFilterProject({
      projectName: projectName,
      technologies: technologies,
      isActive: isActive,
      dateBeginning: dateBeginning,
      dateEnding: dateEnding
    });
    MyProjectService.fetchProjectsFiltered(0, size, {
      projectName,
      technologies,
      isActive,
      dateBeginning,
      dateEnding
    })
      .then((data) => {
        setPageCount(data.data.totalPages);
        setProjectList(data.data.content);
        setPage(0);
        handleLocalStoragePageNumber(0);
        if (resetDate) {
          setResetIsLoading(false);
        } else if (searchData) {
          setSearchIsLoading(false);
        }
      }).catch(() => {
      setResetIsLoading(false);
      setSearchIsLoading(false);
    });
  };

  const resetFilterData = () => {
    setFilterProject({
      projectName: null,
      technologies: [],
      isActive: null,
      dateBeginning: null,
      dateEnding: null
    });
  };

  const selectSize = (e) => {
    setPage(0);
    handleLocalStoragePageNumber(0);
    setSize(e.value);
    handleLocalStoragePageSizeNumber(e.value);
  };

  const redirectToAddProject = () => {
    history.push('/addProject');
  };

  return (
    <div>
      <div className={'d-flex mb-2'}>
        <h3 className=''>{strings.allProjectsList}</h3>
        <div className={'ms-auto'}>
          <AddButton onClickHandle={redirectToAddProject}/>
        </div>
        <div className={'align-items-center d-flex justify-content-end mx-2'}>
          <div
            className='btn btn-secondary d-flex justify-content-center text-end  align-items-end users_filter_button'
            onClick={handleFilter}><i
            className={'material-icons me-1 users_filter_button_icon'}>filter_list</i> {strings.filter}
          </div>
        </div>
      </div>
      <div className={`${showModal ? 'mt-2' : ''}`}>
        {showFilter && <ProjectFilter loadProjectFiltered={loadProjectAndPageFiltered} resetFilterData={resetFilterData}
                                      resetIsLoading={resetIsLoading} searchIsLoading={searchIsLoading}/>}
      </div>
      <div className={'user_details_box w-100 mb-4 border border-radius border-light border-5 px-4 pt-4'}>
        <div className={'w-100 h-100'}>
          {projectList.length === 0 ? (
            <div className='text-center py-3'>
              <h4 className='text-secondary'>
                <i>{strings.EmptyProjectsList}</i>
              </h4>
            </div>
          ) : (
            <div className='row'>
              {projectList.map((project) => (
                <ProjectTerm project={project} getAllProjects={getAllProjects} key={project.id}/>
              ))}
            </div>
          )}
        </div>
        <div className={'text-center'}>
          <ReactPaginate
            previousLabel={<AiOutlineArrowLeft/>}
            nextLabel={<AiOutlineArrowRight/>}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            selectedPageRel={page}
            forcePage={parseInt(page)} // Set the initial current page
            onPageChange={handlePageClick} // Use the "selected.selected" value to get the selected page
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            containerClassName={'pagination h-100 m-0 justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            activeClassName={'active'}/>
        </div>
        <div className={'d-flex align-items-end justify-content-end'}>
          <div>
            <Select
              placeholder={strings.size}
              onChange={selectSize}
              defaultValue={{
                value: size,
                label: size
              }}
              options={[
                {
                  value: 3,
                  label: '3'
                },
                {
                  value: 6,
                  label: '6'
                },
                {
                  value: 9,
                  label: '9'
                },
                {
                  value: 15,
                  label: '15'
                }
              ]}
              styles={{
                control: base => ({
                  ...base,
                  height: 40,
                  border: 'none'
                })
              }}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProjects;
