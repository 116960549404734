import axios from '../../axios/axios';

const EquipmentProposalRepository = {

  getAllEquipmentProposals: () => {
    return axios.get('/equipment-request/get-all');
  },

    getAllEquipmentProposalsForLoggedUser: () => {
        return axios.get("/equipment-request/get-all-logged-user")
    },

    addEquipmentProposal: (equipmentNomenclatureEntry, quantity, description) => {
        return axios.post("/equipment-request/add", {
            equipmentNomenclatureEntryId: equipmentNomenclatureEntry,
            quantity,
            description

        })
    },

    editEquipmentProposal: (id, equipmentNomenclatureEntryId, equipmentNomenclatureEntry,  quantity, description, status) => {
        return axios.put("/equipment-request/update", {
            id,
            equipmentNomenclatureEntryId,
            equipmentNomenclatureEntry,
            quantity,
            description,
            status
        })
    },

    deleteEquipmentProposal: (equipmentProposalId) => {
        return axios.delete(`/equipment-request/delete/${equipmentProposalId}`);
    },

    denyEquipmentProposal: (id) => {
        return axios.post(`/equipment-request/deny-equipment-request/${id}`)
    },

    acceptEquipmentProposal: (id) => {
        return axios.put(`/equipment-request/approve/${id}`)
    }



};

export default EquipmentProposalRepository;

