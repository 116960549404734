import axios from "../../axios/axios";

const UserProfessionalDataService = {

    getUserProfessionalData: () => {
        return axios.get("/professional-data/getUserProfessionalDataDetails");
    },
    getUserProfessionalDataById: (id) => {
        return axios.get(`/professional-data/getUserProfessionalDataDetails/${id}`)
    },
    editUser: (userId, professionalNumber, privateEmail, officeId, key, accountNumber, bankId, dateFrom, dateTo) => {
        return axios.post('/professional-data/updateProfessionalData', {
            userId,
            professionalNumber,
            privateEmail,
            officeId,
            key,
            accountNumber,
            bankId,
            dateFrom,
            dateTo,
        })
    },
    editUserByAdmin: (userId, professionalNumber, privateEmail, officeId, key, accountNumber, bankId, dateFrom, dateTo) => {
        return axios.post('/professional-data/updateProfessionalDataByAdmin', {
            userId,
            professionalNumber,
            privateEmail,
            officeId,
            key,
            accountNumber,
            bankId,
            dateFrom,
            dateTo,
        })
    },
}

export default UserProfessionalDataService;