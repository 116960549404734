import React, { useState } from "react";
import "./FeedbackTermPage.css";
import FeedbackRepository from "../../../repository/feedbackRepository/FeedbackRepository";
import FormatDate from "../../../components/formatDate/FormatDate";
import FeedbackModal from "../FeedbackModal/FeedbackModal";
import { strings } from "../../../localization/Localization";

const FeedbackTermPage = ({ term, getAllFeedback }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const maxCharactersToShow = 30;
  const shouldShowMoreLink =
    term?.description && term?.description.length > maxCharactersToShow;
  const truncatedDescription = term?.description.slice(0, maxCharactersToShow);
  const isTruncated = term?.description.length > maxCharactersToShow;
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const toggleDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case "PRAISE":
        return strings.feedbackPraise;
      case "COMPLAIN":
        return strings.feedbackComplain;
      case "PROPOSAL":
        return strings.feedbackProposal;
      case "OTHER":
        return strings.feedbackOther;
      default:
        return type;
    }
  };

  const togglePriority = (event) => {
    event.stopPropagation();
    FeedbackRepository.setPriority(term.id)
      .then(() => {
        getAllFeedback();
      })
      .catch((error) => {
        console.error("Error toggling priority:", error);
      });
  };

  const handleShow = () => {
    setShowModal(true);
    setIsEdit(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setIsEdit(false);
  };

  return (
    <>
      <tr key={term?.id} onClick={handleShow} className="feedback-row">
        <td>{getTypeLabel(term?.type)}</td>
        <td>{term?.title}</td>
        <td>{FormatDate.formatDate(term?.dateCreated)}</td>
        <td className="text_blue_dark w-25">
          <div
            className={`description-container ${
              isDescriptionExpanded ? "expanded" : ""
            }`}
          >
            {isTruncated ? (
              <>
                {truncatedDescription}...
                <br />
                {shouldShowMoreLink && !isDescriptionExpanded && (
                  <span
                    className="show-more-link text_blue_dark"
                    onClick={toggleDescription}
                  ></span>
                )}
              </>
            ) : (
              term?.description
            )}
          </div>
        </td>
        <td className={"h-100 pe-2"}>
          <div
            className={
              "d-inline-flex justify-content-end w-100 align-items-end"
            }
          >
            <div className={"icon-div me-2"}>
              <div title={"Flag"} type={"Button"}>
                <span
                  className={`material-icons ${
                    term.setPriority ? " flag-active text-danger " : " flag "
                  }`}
                  onClick={(event) => togglePriority(event)}
                >
                  flag
                </span>
              </div>
            </div>
          </div>
        </td>
      </tr>
      {showModal && (
        <FeedbackModal
          showModal={showModal}
          handleClose={handleClose}
          feedbackDetails={term}
          isEdit={isEdit}
        />
      )}
    </>
  );
};
export default FeedbackTermPage;
