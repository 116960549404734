import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import UserService from "../../repository/userRepository/UserRepository";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { strings } from "../../localization/Localization";
import "./UserDetails.css";
import UserPersonalData from "./UserPersonalData/UserPersonalData";
import AvatarEditorModal from "../../components/AvatarEditorModal/AvatarEditorModal";
import { MdArrowBack } from "react-icons/md";
import UserChangePasswordByAdminModal from "./UserChangePasswordModal/UserChangePasswordByAdminModal";
import UserChangePasswordByLoggedUserModal from "./UserChangePasswordModal/UserChangePasswordByLoggedUserModal";
import UserProfessionalData from "./UserProfessionalData/UserProfessionalData";
import ApplicationService from "../../ApplicationService";
import PersonalDocuments from "./PersonalDocuments/PersonalDocuments";
import { useSelector } from "react-redux";
import femaleAvatar from "../../assets/images/female_avatar.png";
import UserEventsData from "./UserEventsData/UserEventsData";
import UserProjects from "./UserProjects/UserProjects";
import UserBenefitData from "./UserBenefitData/UserBenefitData";
import SpinnerComponent from "../../components/Spinner";

const UserDetails = () => {
  const [selectedUser, setSelectedUser] = useState();

  const [isActive, setIsActive] = useState(false);

  const [profileImage, setProfileImage] = useState();

  const [showEditorModal, setShowEditorModal] = useState(false);

  const [imageForEditor, setImageForEditor] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const [key, setKey] = useState("personalData");

  const [openByAdmin, setOpenByAdmin] = useState(false);

  const [showSpinner, setShowSpinner] = useState(true);

  const userDetailsRedux = useSelector((state) => state.user);

  const inputRef = useRef(null);

  const param = useParams();

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    if (param.id) {
      UserService.getUserDetailsById(param.id)
        .then((data) => {
          setSelectedUser(data.data);
          setOpenByAdmin(true);
          if (data.data.profileImageHelper !== null) {
            setProfileImage(data.data.profileImageHelper.bytes);
          } else {
            setProfileImage(
              "https://bootdey.com/img/Content/avatar/avatar7.png"
            );
          }
          setIsActive(data.data.active);
        })
        .catch(() => {});
    } else {
      UserService.getUserDetails()
        .then((data) => {
          setSelectedUser(data.data);
          setOpenByAdmin(false);
          if (data.data.profileImageHelper !== null) {
            setProfileImage(data.data.profileImageHelper.bytes);
          } else {
            setProfileImage(
              "https://bootdey.com/img/Content/avatar/avatar7.png"
            );
          }
          setIsActive(data.data.active);
        })
        .catch(() => {});
    }
  };

  const resetInputValue = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const enabledUserHandleChange = () => {
    UserService.changeUserActive(param.id).then((data) => {
      setIsActive(!isActive);
    });
  };

  const getProfileImageForLoggedUser = () => {
    UserService.getImageForLoggedUser()
      .then(async (data) => {
        if (data.data.bytes != "") {
          setProfileImage(data.data.bytes);
        } else {
          setProfileImage("https://bootdey.com/img/Content/avatar/avatar7.png");
        }
      })
      .catch(() => {
        setProfileImage("https://bootdey.com/img/Content/avatar/avatar7.png");
      });
  };

  const handleClose = () => {
    setIsLoading(true);
    setShowEditorModal(false);
    setImageForEditor(null);
    resetInputValue();
  };
  const handleShowChangePasswordModal = (e) => {
    setShowChangePasswordModal(true);
  };

  const handleCloseChangePasswordUserModal = () => {
    setShowChangePasswordModal(false);
  };

  const changeProfileImageHandler = async (e) => {
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append("photo", file);
    setShowEditorModal(true);
    setImageForEditor(file);
  };

  const checkGender = (gender) => {
    if (gender === "MALE") {
      return strings.male;
    }
    if (gender === "FEMALE") {
      return strings.female;
    }
  };

  const onSaveNewImageCropped = async (img) => {
    setIsLoading(true);
    const formData = new FormData();
    let file = img.toDataURL();
    formData.append("photo", file);
    if (param.id) {
      UserService.changeProfileImageFromByteFromAdministrator(file, param.id)
        .then((data) => {
          getProfileImageForLoggedUser();
          setIsLoading(false);
          setShowEditorModal(false);
          window.location.reload();
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      UserService.changeProfileImageFromByteForLoggedUser(file)
        .then((data) => {
          getProfileImageForLoggedUser();
          setIsLoading(false);
          setShowEditorModal(false);
          window.location.reload();
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <div className="d-inline-block w-100 h-100">
        <div
          className={
            "user_details_box w-100 pb-4 border border-radius border-light border-5 mb-2 ps-4"
          }
        >
          <div className={"w-100 d-flex justify-content-between mt-3 pe-4"}>
            <Link to="/user" className={"d-flex align-items-end"}>
              <div className={"p-0 gray_color d-flex align-items-center"}>
                <MdArrowBack size={"30px"} />
              </div>
            </Link>
            {param.id &&
              selectedUser &&
              !(
                (selectedUser?.roles.includes("Администратор") &&
                  ApplicationService.hasRole(["ROLE_ADMINISTRATOR"])) ||
                ((selectedUser?.roles.includes("Човечки ресурси") ||
                  selectedUser?.roles.includes("Администратор")) &&
                  ApplicationService.hasRole(["ROLE_HR"]))
              ) && (
                <div>
                  <label className={"mx-2"}>{strings.blockUnblockUser}</label>
                  <label className="switchEnabled">
                    <input
                      type="checkbox"
                      checked={!isActive}
                      onChange={enabledUserHandleChange}
                    />
                    <span className="sliderEnabled round"></span>
                  </label>
                </div>
              )}
          </div>
          <div className={"row"}>
            <div
              className={"col-12 col-lg-2 justify-content-center d-inline-flex"}
            >
              <div className="profile-pic mt-4">
                <label className="-label" htmlFor="file">
                  <label
                    className={"material-icons text-white"}
                    style={{ fontSize: "18px" }}
                  >
                    add_a_photo
                  </label>
                  <span>Change Image</span>
                </label>
                <input
                  ref={inputRef}
                  id="file"
                  type="file"
                  onChange={changeProfileImageHandler}
                />

                {profileImage !==
                "https://bootdey.com/img/Content/avatar/avatar7.png" ? (
                  <img
                    id="output"
                    className="rounded-circle"
                    width="150px"
                    height={"150px"}
                    src={`data:image/*;base64,${profileImage}`}
                  />
                ) : selectedUser.person.gender === "MALE" ? (
                  <img
                    id="output"
                    className="rounded-circle"
                    width="150px"
                    height={"150px"}
                    src={profileImage}
                  />
                ) : (
                  <img
                    id="output"
                    className="rounded-circle"
                    width="150px"
                    height={"150px"}
                    src={femaleAvatar}
                  />
                )}
              </div>
            </div>

            <div
              className={
                "align-items-center pt-3 col-12 col-lg-4 user_details__name"
              }
            >
              <div className={"user_details__name_wrapper mx-auto mx-lg-0"}>
                <h5 className={"text_blue_dark"}>
                  <b>
                    {selectedUser?.firstName} {selectedUser?.parentName}{" "}
                    {selectedUser?.lastName}
                  </b>
                </h5>
                <h6 className={"text-center"}>
                  <b>{selectedUser?.position?.name}</b>
                </h6>
              </div>
            </div>
            <div className={"pt-3 col-12 col-lg-6"}>
              <div className={"d-flex mb-1"}>
                <label className={"user_details__label"}>
                  {strings.phoneNumber}:
                </label>
                <div className={"d-flex align-items-end gray_color"}>
                  <i>
                    {selectedUser?.phoneNumber
                      ? selectedUser?.phoneNumber
                      : strings.noInfo}
                  </i>
                </div>
              </div>
              <div className={"d-flex mb-1"}>
                <label className={"user_details__label"}>
                  {strings.email}:
                </label>
                <div className={"d-flex align-items-end gray_color"}>
                  <i>
                    {selectedUser?.email ? selectedUser?.email : strings.noInfo}
                  </i>
                </div>
              </div>
              <div className={"d-flex mb-1"}>
                <label className={"user_details__label"}>
                  {strings.address}:
                </label>
                <div className={"d-flex align-items-end gray_color"}>
                  <i>
                    {selectedUser?.person?.address?.name
                      ? selectedUser?.person?.address?.name
                      : strings.noInfo}
                  </i>
                </div>
              </div>
              <div className={"d-flex mb-1"}>
                <label className={"user_details__label"}>
                  {strings.gender}:
                </label>
                <div className={"d-flex align-items-end gray_color"}>
                  <i>{checkGender(selectedUser?.person?.gender)}</i>
                </div>
              </div>
              {(!openByAdmin ||
                ApplicationService.hasRole(["ROLE_ADMINISTRATOR"])) && (
                <div className={"d-flex justify-content-end"}>
                  <h6
                    className={"text-end justify-content-end d-flex mt-3 me-4"}
                  >
                    <a
                      className={
                        "text-blue d-flex align-items-start cursor-pointer text-center"
                      }
                      onClick={handleShowChangePasswordModal}
                    >
                      {strings.changePassword}
                      <i className={"material-icons"}>arrow_right</i>
                    </a>
                  </h6>
                </div>
              )}
            </div>
          </div>
          <AvatarEditorModal
            showModal={showEditorModal}
            image={imageForEditor}
            onSaveImageCropped={onSaveNewImageCropped}
            setNewPhoto={setImageForEditor}
            handleClose={handleClose}
          />
          {param.id ? (
            <UserChangePasswordByAdminModal
              showModal={showChangePasswordModal}
              handleClose={handleCloseChangePasswordUserModal}
              selectedUserForChangePassword={selectedUser}
            />
          ) : (
            <UserChangePasswordByLoggedUserModal
              showModal={showChangePasswordModal}
              handleClose={handleCloseChangePasswordUserModal}
              selectedUserForChangePassword={selectedUser}
            />
          )}
        </div>
        <div className={"pb-2"}>
          <div
            className={
              "user_details_box w-100 mb-4 border border-radius border-light border-5 ps-2 pt-3"
            }
          >
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              className={"w-100 user_details_tabs"}
              onSelect={(k) => setKey(k)}
            >
              <Tab
                eventKey="personalData"
                title={strings.personalData}
                className={"h-100"}
              >
                <div className={"w-100 h-100 "}>
                  <UserPersonalData
                    selectedUserForEdit={selectedUser}
                    onShowChangePasswordModal={handleShowChangePasswordModal}
                    isAdmin={openByAdmin}
                    getUserData={getUserData}
                    setShowSpinner={setShowSpinner}
                    currentTab={key}
                  />
                </div>
              </Tab>

              <Tab
                eventKey="professionalData"
                title={strings.professionalData}
                className={"h-100 w-100"}
              >
                <div className={"w-100 h-100"}>
                  <UserProfessionalData
                    selectedUser={selectedUser}
                    setShowSpinner={setShowSpinner}
                    currentTab={key}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="personalDocuments"
                title={strings.personalDocuments}
                className={"h-100 w-100"}
              >
                <div className={"w-100 h-100"}>
                  <PersonalDocuments
                    selectedUser={selectedUser}
                    currentTab={key}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="personalProjects"
                title={strings.projects}
                className={"h-100 w-100"}
              >
                <div className={"w-100 h-100"}>
                  <UserProjects selectedUser={selectedUser} currentTab={key} />
                </div>
              </Tab>
              <Tab
                eventKey="eventsForUser"
                title={strings.attendedEvents}
                className={"h-100 w-100 "}
              >
                <div className={"w-100 h-100"}>
                  <UserEventsData
                    selectedUser={selectedUser}
                    currentTab={key}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="benefitsForUser"
                title={strings.benefits}
                className={"h-100 w-100 "}
              >
                <div className={"w-100 h-100"}>
                  <UserBenefitData
                    selectedUser={selectedUser}
                    currentTab={key}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <SpinnerComponent spinnerShow={showSpinner} />
    </>
  );
};
export default UserDetails;
