import React from "react";
import swal from "sweetalert";
import {strings} from "../../../localization/Localization";
import UserEventGroupService from "../../../repository/eventsRepository/UserEventGroupRepository";

const UserInEventGroupTerm = (props) => {

    const deleteUserFromEventGroup = (userId, eventGroupId) => {
        UserEventGroupService.deleteUserEventGroupWithUserAndEventGroupId(userId, eventGroupId).then(() => {
            successfulAlert();
            props.usersForEventGroup();
        }).catch(() => {
            errorAlert();
        })

    }

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const errorAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const ConfirmationDelete = (userId, eventGroupId) => {
        swal(strings.deleteConfirmation, {
            title: strings.areYouSure,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteUserFromEventGroup(userId, eventGroupId);
            }
        });
    };

    return (
        <tr key={props.user?.id}>
            <td>{props.user?.firstName}</td>
            <td>{props.user?.lastName}</td>
            <td>{props.user?.phoneNumber}</td>
            <td className={'h-100 pe-2'}>
                {props.permissionForUpsert &&
                    <div className={"d-inline-flex justify-content-end w-100 align-items-end"}>
                        <div className={'icon-div'}>
                            <div title={'Delete'} type={'Button'}
                                 onClick={() => {
                                     ConfirmationDelete(props.user?.id, props.eventGroup.eventGroupId);
                                 }}
                            >
                                <i className={"material-icons delete-icon-circle"}>delete</i>

                            </div>
                        </div>
                    </div>
                }
            </td>
        </tr>
    )
}

export default UserInEventGroupTerm;