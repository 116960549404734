import React, {useEffect, useRef, useState} from "react"
import {Modal} from "react-bootstrap";
import ReactAvatarEditor from 'react-avatar-editor'
import {strings} from "../../localization/Localization";
import {HiPhotograph} from "react-icons/hi";
import "./AvatarEditorModal.css"
import DraggableModalDialog from "../DraggableModalDialog/DraggableModalDialog";

const AvatarEditorModal = (props) => {

    const editor = useRef(null);

    const [state, setState] = useState({
        image: props.image,
        allowZoomOut: false,
        position: {x: 0.5, y: 0.5},
        scale: 1.2,
        rotate: 0,
        borderRadius: 500,
        preview: null,
        width: 500,
        height: 500

    });

    function getWindowSize() {
        const {innerWidth} = window;
        return {innerWidth};
    }


    const handleNewImage = e => {
        props.setNewPhoto(e.target.files[0]);
    };

    const handleScale = e => {
        const scale = parseFloat(e.target.value)
        setState({...state, scale})
    };

    const handlePositionChange = position => {
        setState({...state, position})
    };

    const onCrop = () => {
        const canvas = editor.current.getImage()

        // If you want the image resized to the canvas size (also a HTMLCanvasElement)
        const canvasScaled = editor.current.getImageScaledToCanvas()

        props.onSaveImageCropped(canvasScaled);
    }

    return (
        <Modal show={props.showModal} dialogAs={DraggableModalDialog} size={"lg"} onHide={props.handleClose} centered>

            <Modal.Header closeButton>
                <Modal.Title>Edit profile photo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"text-center"}>
                    <ReactAvatarEditor
                        scale={parseFloat(state.scale)}
                        ref={editor}
                        width={state.width}
                        height={state.height}
                        position={state.position}
                        onPositionChange={handlePositionChange}
                        rotate={parseFloat(state.rotate)}
                        borderRadius={state.width / (100 / state.borderRadius)}
                        image={props.image}
                        // ref={(ref) => setEditorRef(ref)}
                        style={{
                            width: "75%",
                            height: "75%"
                        }}
                        className="editor-canvas"
                    />
                </div>
                <div className={"d-flex w-70 mx-auto justify-content-between align-items-end mx-auto"}>
                    <div className={"d-flex me-3"}>
                        {/*<input  name="newImage" type="file" onChange={handleNewImage}/>*/}
                        <input type="file" id="upload-button" style={{display: 'none'}}
                               onChange={handleNewImage}/>

                        <label htmlFor={'upload-button'}>
                            <div className={"file-input add-button"}>
                                <HiPhotograph style={{marginRight: 10}}/> Upload File
                            </div>
                        </label>
                    </div>
                    <div className={"d-flex align-items-center"}>
                        <label className={"mx-2"}>Zoom:</label>
                        <input
                            name="scale"
                            type="range"
                            onChange={handleScale}
                            min={state.allowZoomOut ? '0.1' : '1'}
                            max="2"
                            step="0.01"
                            defaultValue="1"
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className={"cancel btn text-white"} onClick={props.handleClose}>
                    {strings.cancel}
                </div>
                <button type={"submit"} className={"save btn text-white"} onClick={onCrop}>
                    {strings.save}
                </button>

            </Modal.Footer>

        </Modal>

    )
}
export default AvatarEditorModal;