import axios from "../../axios/axios";

const UserEducationService = {
    getEducationForLoggedUser: () => {
        return axios.get("/user-education/getAllForLoggedUser")
    },
    getEducationForUserById: (userId) => {
        return axios.get(`/user-education/getAllForUser/${userId}`)
    },
    addUserEducation: (institution, area, startDate, endDate, isAcknowledged, nomenclatureEntryId) => {
        return axios.post("/user-education/add", {
            institution,
            area,
            startDate,
            endDate,
            isAcknowledged,
            nomenclatureEntryId
        })
    },
    addUserEducationByAdministrator:(institution, area, startDate, endDate, isAcknowledged, nomenclatureEntryId,userId) => {
        return axios.post("/user-education/addByAdministrator", {
            institution,
            area,
            startDate,
            endDate,
            isAcknowledged,
            nomenclatureEntryId,
            userId
        })
    },
    editUserEducation: (institution, area, startDate, endDate, isAcknowledged, nomenclatureEntryId,id) => {
        return axios.post("/user-education/update", {
            institution,
            area,
            startDate,
            endDate,
            isAcknowledged,
            nomenclatureEntryId,
            id
        })
    },
    deleteUserEducation:(id)=>{
        return axios.delete(`/user-education/delete/${id}`)
    }
}
export default UserEducationService;
