import React, {useEffect, useState} from "react";
import "./Settings.css";
import {FiSettings} from "react-icons/fi"
import {strings} from "../../localization/Localization";
import SettingsRepository from "../../repository/settingsRepository/SettingsRepository";

const Settings = () => {

   //  const [hierarchyEditable, setHierarchyEditable] = useState();
   //
   //  useEffect(()=>{
   //      getIsEditableHierarchy();
   //  },[])
   //
   //  const getIsEditableHierarchy = () => {
   //      SettingsRepository.getEditableHierarchy()
   //          .then((data)=>{
   //              setHierarchyEditable(data.data.value == 1 ? true: false)
   //          })
   //  };
   //
   // const changeIsEnableHierarchy=()=>{
   //          SettingsRepository.changeEditableHierarchy()
   //             .then((data)=>{
   //              setHierarchyEditable(data.data.value == 1 ? true: false)
   //          });
   // };

    return (
        <div className={"container pb-3"}>
            <div className={"container settings__container h-50"}>
                <div className={"mt-4 d-flex"}>
                    {/*<div className={"w-50 my-4"}>*/}
                    {/*    <div className={"d-flex ms-3"}>*/}
                    {/*        <div className={"d-flex align-items-center settings__title_icon"}>*/}
                    {/*            <FiSettings/>*/}
                    {/*        </div>*/}
                    {/*        <h1 className={"ms-2"}>{strings.settings}</h1>*/}
                    {/*    </div>*/}
                    {/*    <div className={"my-4 ms-3"}>*/}
                    {/*        <div className={"d-flex "}>*/}
                    {/*            <h5>*/}
                    {/*                {strings.enableSync}*/}
                    {/*            </h5>*/}
                    {/*            <div className={"ms-3 d-flex align-items-center"}>*/}
                    {/*                <label className="switch">*/}
                    {/*                    <input type="checkbox" checked={hierarchyEditable} onClick={changeIsEnableHierarchy}/>*/}
                    {/*                    <span className="slider round"></span>*/}
                    {/*                </label>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={"w-50 h-75  d-flex align-items-center mt-auto mb-auto"}>
                        <div className={"mx-auto w-75 h-75 my-auto bg_image_wrapper d-flex"}>
                            <div className={"bg_image mb-3"}>

                            </div>
                        </div>
                    </div>

                    <div className={"d-flex mt-4 my-auto mt-auto mb-auto w-100 h-100 text-center"}>
                        <h2 className={"w-100 text-center "}><i
                            className={"text-secondary"}>{strings.noOptionsCurrentlyPresent}</i></h2>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Settings