import React, { useEffect, useState } from 'react';
import { Form, FormControl, Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap';
import { strings } from '../../../localization/Localization';
import Button from 'react-bootstrap/Button';
import OrganisationHierarchyService from '../../../repository/hierarchyRepository/organisationHierarchyRepository';
import Select from 'react-select';
import DraggableModalDialog from '../../../components/DraggableModalDialog/DraggableModalDialog';

const OrganizationalHierarchyAddModal = (props) => {

  const [selectedOrganisation, setSelectedOrganisation] = useState(null);

  const [formData, updateFormData] = React.useState({
    child_sh_organisation_id: '',
    parent_sh_organisation_id: '',
  });

  useEffect(() => {
    if (props.showModal) {
      if (props.selectedNode) {
        updateFormData({
          ...formData,
          parent_sh_organisation_id: props.selectedNode.id
        });
      } else {
        updateFormData({
          child_sh_organisation_id: '',
          parent_sh_organisation_id: '',
        });
      }
    } else {
      updateFormData({
        child_sh_organisation_id: '',
        parent_sh_organisation_id: '',
      });
      handleClose();
    }
  }, [props.showModal, props.selectedNode]);

  const handleChange = (selectedOption) => {
    setSelectedOrganisation(selectedOption);
    updateFormData({
      ...formData,
      child_sh_organisation_id: selectedOption ? selectedOption.value : null,
    });
  };

  const handleClose = () => {
    setSelectedOrganisation(null);
    props.handleClose();
  };

  const addOrganisationInHierarchy = (organisationId, parentOrganisationId) => {
    OrganisationHierarchyService.addOrganisationInHierarchy(organisationId, parentOrganisationId)
      .then((data) => {
        props.loadHierarchy(false);
        props.handleClose();
        props.getAllOrganisationsNotInHierarchy();
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const organisationId = formData.child_sh_organisation_id ? formData.child_sh_organisation_id : null;
    const parentOrganisationId = formData.parent_sh_organisation_id ? formData.parent_sh_organisation_id : null;

    addOrganisationInHierarchy(organisationId, parentOrganisationId);
  };

  return (
    <>
      <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={'lg'} centered>
        <ModalHeader closeButton>
          <ModalTitle>
            <b>{strings.addOrganisation}</b>
          </ModalTitle>
        </ModalHeader>
        <Form onSubmit={onFormSubmit}>
          <ModalBody>
            <Form.Group className='mb-3' controlId='hierarchyName'>
              {props.selectedNode != null &&
                <Form.Group>
                  <Form.Label>{strings.parentOrganisation}: <b>{props.selectedNode.data.name} - {props.selectedNode.data.code}</b></Form.Label>
                </Form.Group>
              }

              <Form.Group>
                <Form.Label>{strings.selectOrganisation}<span className="text-danger">*</span></Form.Label>
                <Select
                  required
                  options={props.organisationsNotInHierarchy}
                  className={`border border-1 border-radius p-2 border-light rounded-4 bg-light`}
                  classNamePrefix='select'
                  name={'organisationId'}
                  value={selectedOrganisation}
                  placeholder={strings.selectOption}
                  onChange={handleChange}
              
                />
              </Form.Group>
              {/*<Form.Group>*/}
              {/*    <Form.Label>{strings.organisationName}</Form.Label>*/}
              {/*    <Form.Control*/}
              {/*        type="text"*/}
              {/*        onChange={handleChange}*/}
              {/*        autoFocus*/}
              {/*        name="name"*/}
              {/*        required/>*/}
              {/*</Form.Group>*/}
              {/*<Form.Group>*/}
              {/*    <Form.Label>{strings.organisationShortName}</Form.Label>*/}
              {/*    <Form.Control*/}
              {/*        type="text"*/}
              {/*        onChange={handleChange}*/}
              {/*        name="shortName"/>*/}
              {/*</Form.Group>*/}
              {/*<Form.Group>*/}
              {/*    <Form.Label>{strings.organisationDescription}</Form.Label>*/}
              {/*    <textarea name="description" className="form-control"*/}
              {/*              onChange={handleChange}*/}
              {/*              id="exampleFormControlTextarea1" rows="2"/>*/}
              {/*</Form.Group>*/}
              {/*<Form.Group>*/}
              {/*    <Form.Label>{strings.organisationCode}</Form.Label>*/}
              {/*    <FormControl type="text"*/}
              {/*                 name="code"*/}
              {/*                 className="form-control"*/}
              {/*                 onChange={handleChange}*/}
              {/*                 id="exampleFormControlTextarea1"/>*/}
              {/*</Form.Group>*/}
            </Form.Group>
          </ModalBody>
          <Modal.Footer>
            <div className={'cancel btn text-white'} onClick={props.handleClose}>
              {strings.cancel}
            </div>
            <button type={'submit'} className={'save btn text-white'}>
              {strings.save}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default OrganizationalHierarchyAddModal;