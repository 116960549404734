import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { strings } from "../../../localization/Localization";
import { Modal } from "react-bootstrap";
import AddUserToEquipmentCard from "../AddUserToEquipmentCard/AddUserToEquipmentCard";
import EquipmentRepository from "../../../repository/equipmentReposituory/EquipmentRepository";
import ApplicationService from "../../../ApplicationService";
import { useHistory } from "react-router-dom";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const EquipmentCardDetailsModal = (props) => {
  const [
    equipmentPossessionsForEquipment,
    setEquipmentPossessionsForEquipment,
  ] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [
    selectedNomenclatureEntryForEdit,
    setSelectedNomenclatureEntryForEdit,
  ] = useState({});
  const history = useHistory();
  const [selectedUserEquipmentDetails, setSelectedUserEquipmentDetails] =
    useState({});
  const [showAddUser, setShowAddUser] = useState(false);
  let rowNumber = 1;

  const getAllEquipmentPossessionsForEquipment = () => {
    EquipmentRepository.getAllEquipmentPossessionsForEquipment(
      props.equipmentDetails?.id
    ).then((data) => {
      setEquipmentPossessionsForEquipment(data.data);
    });
  };

  useEffect(() => {
    if (props.showModal) {
      getAllEquipmentPossessionsForEquipment();
    }
  }, [props.showModal]);

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const errorAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDate.replace(/\//g, ".");
  };

  const redirectToEditEquipment = (equipmentDetails) => {
    setSelectedNomenclatureEntryForEdit(equipmentDetails);
  };

  const handleCloseAddUserToEquipmentComponent = () => {
    setShowAddUser(false);
    setSelectedUserEquipmentDetails({});
  };

  const handleAddUserClick = () => {
    setShowAddUser(!showAddUser);
  };

  const ConfirmationDelete = (id) => {
    swal(strings.deleteConfirmation, {
      title: strings.areYouSure,
      icon: "warning",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteUserBenefit(id);
      }
    });
  };

  const deleteUserBenefit = (id) => {
    EquipmentRepository.deleteUserEquipmentData(id)
      .then(() => {
        getAllEquipmentPossessionsForEquipment();
        successfulAlert();
      })
      .catch(() => {
        errorAlert();
      });
  };

  return (
    <Modal
      show={props.showModal}
      dialogAs={DraggableModalDialog}
      onHide={props.handleClose}
      size={"xl"}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <b>
            {ApplicationService.getActiveLanguage() === "mk"
              ? props.equipmentDetails.labelMk
              : props.equipmentDetails.labelEn}
          </b>
        </Modal.Title>
        <div>
          {equipmentPossessionsForEquipment.length > 0 ? (
            <span className="badge text-bg-success">
              {strings.active.toUpperCase()}
            </span>
          ) : (
            <span className="badge text-bg-danger">
              {strings.inactive.toUpperCase()}
            </span>
          )}
        </div>
      </Modal.Header>
      <div>
        <Modal.Body>
          <div>
            <div className={"d-flex"}>
              {ApplicationService.hasRole([
                "ROLE_ADMINISTRATOR",
                "ROLE_HR",
              ]) && (
                <div className={"ms-auto d-flex"}>
                  <div className={"icon-div mx-2"}>
                    <div
                      onClick={() => {
                        history.push("/nomenclature", {
                          nomenclatureEntry: "Опрема",
                          equipmentForEdit: props.equipmentDetails,
                        });
                      }}
                      title={"Edit"}
                      className={"text-dark icon"}
                    >
                      <i className={"edit-icon-circle material-icons "}>
                        create
                      </i>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <p className={"justify-content-center description"}>
              {props.equipmentDetails.description}
            </p>
          </div>
        </Modal.Body>

        {ApplicationService.hasRole(["ROLE_ADMINISTRATOR", "ROLE_HR"]) && (
          <Modal.Footer>
            <div className={"me-auto fs-5"}>
              <i>{strings.equipmentUserList}</i>
            </div>
            {!showAddUser ? (
              <div
                className={
                  "btn text-end d-flex align-items-center border-radius add-button"
                }
                onClick={handleAddUserClick}
              >
                <div
                  className={"align-items-center d-flex text-start text-white"}
                >
                  <i className={"material-icons add_button_icon"}>person_add</i>
                </div>
                <div className={"mx-1 text-white"}>
                  {strings.addUserToEquipment}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className={"container"}>
              {showAddUser && (
                <AddUserToEquipmentCard
                  equipmentList={equipmentList}
                  handleClose={handleCloseAddUserToEquipmentComponent}
                  selectedUserEquipmentDetails={selectedUserEquipmentDetails}
                  equipment={props.equipmentDetails}
                  getAllUserEquipment={getAllEquipmentPossessionsForEquipment}
                  getEquipmentDetailsById={props.getEquipmentDetailsById}
                />
              )}
            </div>

            <div className={"container mb-2"}>
              <div>
                <div className={"row table-responsive"}>
                  <table id="keywords" className={"mb-3"}>
                    <thead>
                      <tr className="userlist">
                        <th scope={"col"}>
                          <b>#</b>
                        </th>
                        <th scope={"col"}></th>
                        <th scope={"col"}>{strings.name}</th>
                        <th scope={"col"}>{strings.lastName}</th>
                        <th scope={"col"}>{strings.quantity}</th>
                        <th scope={"col"}>{strings.dateAcquired}</th>
                        <th scope={"col"}></th>
                        <th scope={"col"}></th>
                        <th scope={"col"}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {equipmentPossessionsForEquipment.length > 0 ? (
                        equipmentPossessionsForEquipment.map(
                          (userEquipmentDetails, index) => (
                            <tr key={index}>
                              {<td scope={"col"}>{rowNumber++}</td>}
                              <td scope={"col"}>
                                <img
                                  className={"img-icon"}
                                  src={
                                    "https://cdn-icons-png.flaticon.com/512/236/236831.png"
                                  }
                                />
                              </td>
                              <td scope={"col"}>
                                {userEquipmentDetails.user?.person?.firstName}
                              </td>
                              <td scope={"col"}>
                                {userEquipmentDetails.user?.person?.lastName}
                              </td>
                              <td scope={"col"}>
                                {userEquipmentDetails.quantity}
                              </td>
                              <td scope={"col"}>
                                {formatDate(userEquipmentDetails.dateAcquired)}
                              </td>
                              {/* <td scope={"col"}>{formatDate(userEquipmentDetails.endDate)}</td> */}
                              <td scope="col"></td>
                              <td scope={"col"}>
                                <div className={"icon-div"}>
                                  <div
                                    onClick={() => {
                                      handleAddUserClick();
                                      setSelectedUserEquipmentDetails(
                                        userEquipmentDetails
                                      );
                                    }}
                                    className={"text-dark icon"}
                                    title={"Edit"}
                                  >
                                    <i
                                      className={
                                        "edit-icon-circle material-icons"
                                      }
                                    >
                                      create
                                    </i>
                                  </div>
                                </div>
                              </td>
                              <td scope={"col"}>
                                <div className={"icon-div"}>
                                  <div
                                    onClick={() =>
                                      ConfirmationDelete(
                                        userEquipmentDetails.id
                                      )
                                    }
                                    className={"text-dark icon"}
                                    title={"Delete"}
                                  >
                                    <i
                                      className={
                                        "material-icons delete-icon-circle"
                                      }
                                    >
                                      delete
                                    </i>
                                  </div>
                                </div>
                              </td>
                              <td scope={"col"}>&nbsp;</td>
                            </tr>
                          )
                        )
                      ) : (
                        <div>
                          <p>No users here</p>
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Footer>
        )}
      </div>
    </Modal>
  );
};
export default EquipmentCardDetailsModal;
