import Modal from "react-bootstrap/Modal";
import { strings } from "../../../localization/Localization";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EventGroupDetails from "../EventGroup/EventGroupDetails";
import React, { useEffect, useRef, useState } from "react";
import ApplicationService from "../../../ApplicationService";
import EventGroupService from "../../../repository/eventsRepository/EventGroupRepository";
import { useHistory, useParams } from "react-router-dom";
import swal from "sweetalert";
import EventsService from "../../../repository/eventsRepository/EventsRepository";
import { Carousel } from "react-bootstrap";
import "./EventDetails.css";
import UserEventGroupService from "../../../repository/eventsRepository/UserEventGroupRepository";
import DocumentService from "../../../repository/documentRepository/DocumentRepository";
import EventFilesRepository from "../../../repository/eventsRepository/EventFilesRepository";
import { HiDownload, HiOutlineDocumentText } from "react-icons/hi";
import EventSkillsService from "../../../repository/eventsRepository/EventSkillsRepository";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const EventDetails = (props) => {
  const FileSaver = require("file-saver");

  const [eventGroupsForSelectedEvent, setEventGroupsForSelectedEvent] =
    useState([]);

  const history = useHistory();

  const [
    allEventGroupsForEventForLoggedInUser,
    setAllEventGroupsForEventForLoggedInUser,
  ] = useState([]);

  const [filesList, setFilesList] = useState([]);

  const [collapseOpen, setCollapseOpen] = useState(false);

  const [dragActive, setDragActive] = React.useState(false);

  const [file, setFile] = useState({});

  const inputRef = useRef(null);

  const [skillsForEvent, setSelectedSkillsForEvent] = useState({});

  const [formData, updateFormData] = useState({
    file_description: "",
  });

  const resetData = () => {
    updateFormData({
      file_description: "",
    });
  };

  const handleCollapseToggle = () => {
    if (collapseOpen === false) {
      resetData();
      setFile({});
    }
    setCollapseOpen(!collapseOpen);
  };

  useEffect(() => {
    if (props.showModal) {
      getAllGroupsForEvent();
      getAllUserEventGroupsInEventForLoggedInUser();
      getAllFilesForEvent();
      // getEventSkillsForEvent();
    }
  }, [props.showModal]);

  const redirectToEditEvent = () => {
    history.push("/event/" + props.selectedEventForEdit?.eventId);
  };

  const getAllGroupsForEvent = () => {
    EventGroupService.getAllGroupsForEvent(
      props.selectedEventForEdit?.eventId
    ).then((data) => {
      setEventGroupsForSelectedEvent(data.data);
    });
  };

  const getAllFilesForEvent = () => {
    EventFilesRepository.getFilesForEvent(
      props.selectedEventForEdit?.eventId
    ).then((data) => {
      setFilesList(data.data);
    });
  };

  const getAllUserEventGroupsInEventForLoggedInUser = () => {
    UserEventGroupService.getAllUserEventGroupsInEventForLoggedInUser(
      props.selectedEventForEdit?.eventId
    ).then((data) => {
      setAllEventGroupsForEventForLoggedInUser(data.data);
    });
  };

  const ConfirmationDelete = (id) => {
    swal(strings.deleteConfirmation, {
      title: strings.areYouSure,
      icon: "warning",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteEvent(id);
      }
    });
  };

  const ConfirmationDeleteEventFile = (id) => {
    swal(strings.deleteConfirmation, {
      title: strings.areYouSure,
      icon: "warning",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteEventFile(id);
      }
    });
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const deleteEvent = (id) => {
    EventsService.deleteEvent(id)
      .then(() => {
        successfulAlert();
        props.getAllEvents();
        props.handleClose();
      })
      .catch(() => {
        warningAlert();
      });
  };

  const deleteEventFile = (id) => {
    EventFilesRepository.deleteEventFile(id)
      .then(() => {
        successfulAlert();
        getAllFilesForEvent();
      })
      .catch(() => {
        warningAlert();
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleChangeFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const downloadEventFile = (id, fileName) => {
    fetch(
      process.env.REACT_APP_HOST_ENV + `/eventFiles/downloadEventFile/${id}`,
      {
        method: "post",
        origin: "same-origin",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth_token"),
        },
      }
    )
      .then((res) => res.blob())
      .then((file) => FileSaver.saveAs(file, fileName));
  };

  const checkCurrency = (currency) => {
    switch (currency) {
      case "DOLLARS":
        return strings.dollars;
      case "EUROS":
        return strings.euros;
      case "DENARS":
        return strings.denars;
      case "POUNDS":
        return strings.pounds;
      default:
        return "";
    }
  };

  const checkStatus = (status) => {
    switch (status) {
      case "ACTIVE":
        return strings.activeEvent;
      case "INACTIVE":
        return strings.inactiveEvent;
      default:
        return "";
    }
  };

  const addEventFile = (newFormData) => {
    EventFilesRepository.addEventFile(newFormData)
      .then(() => {
        handleCollapseToggle();
        successfulAlert();
        getAllFilesForEvent();
      })
      .catch(() => {
        warningAlert();
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const newFormData = new FormData();

    if (file) {
      newFormData.append("file", file);
      newFormData.append("file_description", formData.file_description || "");
    }

    if (props.selectedEventForEdit?.eventId) {
      newFormData.append("eventId", props.selectedEventForEdit.eventId);
    }

    addEventFile(newFormData);
  };
  return (
    <Modal
      show={props.showModal}
      dialogAs={DraggableModalDialog}
      onHide={props.handleClose}
      size={"lg"}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <b>{props.selectedEventForEdit.name}</b> -{" "}
            {ApplicationService.getActiveLanguage() == "mk"
              ? props.selectedEventForEdit?.eventTypeNomenclatureEntry?.labelMk
              : props.selectedEventForEdit?.eventTypeNomenclatureEntry?.labelEn}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className={"mb-2"}>
          <Col xs={12} md={7}>
            <p>{props.selectedEventForEdit?.description}</p>
          </Col>
          <Col xs={12} md={5}>
            <div
              className={
                "d-flex bg-event-info border-secondary border-radius p-3 justify-content-between"
              }
            >
              <div className={"d-flex flex-column me-4"}>
                <p className={"text-secondary mb-0 me-2"}>
                  <i>{strings.organisation}:</i>
                </p>
                <p className={"text-secondary mb-0 me-2"}>
                  <i>{strings.certificate}:</i>
                </p>
                <p className={"text-secondary mb-0 me-2"}>
                  <i>{strings.trainer}:</i>
                </p>
                {ApplicationService.hasRole([
                  "ROLE_ADMINISTRATOR",
                  "ROLE_HR",
                ]) && (
                  <p className={"text-secondary mb-0 me-2"}>
                    <i>{strings.cost}:</i>
                  </p>
                )}
                <p className={"text-secondary mb-0 me-2"}>
                  <i>{strings.status}:</i>
                </p>
              </div>
              <div className={"d-flex flex-column"}>
                <b>{props.selectedEventForEdit?.organisation}</b>
                <b>{props.selectedEventForEdit?.certificate}</b>
                <b>{props.selectedEventForEdit?.trainer}</b>
                {ApplicationService.hasRole([
                  "ROLE_ADMINISTRATOR",
                  "ROLE_HR",
                ]) && (
                  <b>
                    {props.selectedEventForEdit?.cost}{" "}
                    {checkCurrency(props.selectedEventForEdit?.currency)}
                  </b>
                )}
                <b>{checkStatus(props.selectedEventForEdit?.status)}</b>
              </div>
            </div>
          </Col>
        </Row>
        <Row className={"mb-2"}>
          <Col></Col>
        </Row>
        <Row>
          <Col xs={12} md={7} className="d-flex flex-column">
            {props.selectedEventForEdit?.eventSkillsHelper &&
              props.selectedEventForEdit.eventSkillsHelper.length > 0 && (
                <div
                  className={
                    "bg-event-info border-secondary border-radius p-3 flex-grow-1"
                  }
                >
                  <p className={"text-secondary mb-0 me-2"}>
                    <i>{strings.eventSkills}:</i>
                  </p>
                  <p>
                    {props.selectedEventForEdit.eventSkillsHelper.map(
                      (eventSkill, index) => (
                        <li>
                          <b key={index}>
                            {ApplicationService.getActiveLanguage() === "mk"
                              ? eventSkill?.skillsNomenclatureEntryHelper
                                  ?.labelMk
                              : eventSkill?.skillsNomenclatureEntryHelper
                                  ?.labelEn}
                            <br />
                          </b>
                        </li>
                      )
                    )}
                  </p>
                </div>
              )}
          </Col>
          <Col xs={12} md={5} className="d-flex flex-column">
            {props.event.eventProposalHelper.length > 0 && (
              <div
                className={
                  "bg-event-info border-secondary border-radius p-3 flex-grow-1"
                }
              >
                <p className={"text-secondary mb-0 me-2"}>
                  <i>{strings.eventProposalFrom}</i>
                </p>
                {props.event.eventProposalHelper
                  .reduce((uniqueUsers, eventProposal) => {
                    const userId = eventProposal.user?.id;
                    if (
                      userId &&
                      !uniqueUsers.some((user) => user.id === userId)
                    ) {
                      uniqueUsers.push(eventProposal.user);
                    }
                    return uniqueUsers;
                  }, [])
                  .map((user, index) => (
                    <li>
                      <b key={index}>
                        {user.firstName} {user.lastName}
                        <br />
                      </b>
                    </li>
                  ))}
                <br />
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <div className={"d-flex align-items-center border-radius my-4"}>
            <h4 className={"me-3 text-secondary"}>{strings.files}</h4>
            <hr className={"me-3"} />
            <div
              className={`btn text-white text-end d-flex align-items-center border-radius px-xs-0  add-button ${
                collapseOpen ? "active" : ""
              }`}
              onClick={handleCollapseToggle}
              aria-expanded={collapseOpen}
            >
              <div className={"align-items-center d-flex text-start"}>
                <i className={"material-icons add_button_icon"}>add_box</i>
              </div>
              <div className={"mx-1"}>{strings.add}</div>
            </div>
          </div>
        </Row>

        <Form onSubmit={onFormSubmit}>
          <div
            className={`collapse ${collapseOpen ? "show" : ""}`}
            id="collapseExample"
          >
            <div className="add-file-background p-2 card card-body mb-4">
              <Row>
                <Col xs={12} md={6}>
                  <div
                    className={`collapse ${collapseOpen ? "show" : ""}`}
                    id="collapseExample"
                  >
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className={"d-flex mb-2"}>
                        {strings.description}
                      </Form.Label>
                      <textarea
                        className={"w-100 form-control free-text"}
                        onChange={handleChange}
                        rows={6}
                        value={formData.file_description}
                        name={"file_description"}
                      />
                    </Form.Group>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div
                    className={`collapse ${collapseOpen ? "show" : ""}`}
                    id="collapseExample"
                  >
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className={"d-flex"}>
                        {strings.selectDocument}
                      </Form.Label>
                      <div id="form-file-upload w-75" onDragEnter={handleDrag}>
                        <input
                          ref={inputRef}
                          type="file"
                          id="input-file-upload"
                          onChange={handleChangeFile}
                        />
                        <label
                          id="label-file-upload"
                          htmlFor="input-file-upload"
                          className={dragActive ? "drag-active" : ""}
                        >
                          <div className={"text-center pb-4 pt-4"}>
                            <p>{strings.dragAndDropYourFileHereOr}</p>
                            <div className="upload-button mx-auto d-flex align-items-center justify-content-center text-center ">
                              <span className={"me-2"}>
                                {strings.uploadFile}
                              </span>{" "}
                              <i className={"material-icons"}>cloud_upload</i>
                            </div>
                            <div>{file.name}</div>
                          </div>
                        </label>
                        {dragActive && (
                          <div
                            id="drag-file-element"
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                          ></div>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                </Col>
              </Row>

              <div className={"ms-auto"}>
                <div
                  className={"cancel btn text-white"}
                  onClick={() => {
                    handleCollapseToggle();
                    resetData();
                  }}
                >
                  {strings.cancel}
                </div>
                <button type={"submit"} className={"save btn text-white m-1"}>
                  {strings.save}
                </button>
              </div>
            </div>
          </div>
        </Form>

        <Row>
          <Col>
            {/*<h5 className="fw-bolder mt-3">{strings.files}</h5>*/}
            <table className="table user-table table-responsive table-borderless text-start">
              <thead className="custom-table-header text-white">
                <tr className="user-table-header-row">
                  <th scope="col">{strings.name}</th>
                  <th scope="col">{strings.description}</th>
                  <th scope="col">{strings.type}</th>
                  <th scope="col">{strings.date}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filesList.length > 0 ? (
                  filesList.map((data) => (
                    <tr className="user-table-body-row" key={data.id}>
                      <td>
                        <HiOutlineDocumentText className="user_personal_data__document_icon me-3 text-blue" />{" "}
                        {data?.name}
                      </td>
                      <td>{data?.description}</td>
                      <td>{data?.fileType}</td>
                      <td>{data?.dateCreated.split("T")[0]}</td>
                      <td>
                        <div className="d-flex justify-content-end align-items-center">
                          {ApplicationService.hasRole([
                            "ROLE_ADMINISTRATOR",
                            "ROLE_HR",
                          ]) && (
                            <div className="icon-div cursor-pointer me-2">
                              <a title="Delete">
                                <div
                                  onClick={() =>
                                    ConfirmationDeleteEventFile(data?.id)
                                  }
                                  title="Delete"
                                  className="text-dark"
                                >
                                  <i
                                    className="material-icons delete-icon-circle"
                                    style={{ fontSize: "23px" }}
                                  >
                                    delete
                                  </i>
                                </div>
                              </a>
                            </div>
                          )}
                          <HiDownload
                            className="text-primary cursor-pointer user_personal_data__document_download__icon"
                            onClick={() =>
                              downloadEventFile(data?.id, data?.name)
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center text-secondary">
                      <i>{strings.noFilesInEvent}</i>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Col>
        </Row>

        <Row>
          <div>
            <div className={"d-flex align-items-center border-radius  mb-4"}>
              <h4 className={"me-3 text-secondary"}>{strings.groups}</h4>
              <hr className={"me-3"} />
            </div>
            {eventGroupsForSelectedEvent.length === 0 ? (
              <h4 className="text-secondary text-center">
                {strings.noGroupsAvailable}
              </h4>
            ) : (
              <Carousel
                data-bs-theme="dark"
                nextIcon={
                  <i className={"material-icons text-blue fs-1"}>arrow_right</i>
                }
                prevIcon={
                  <i className={"material-icons text-blue fs-1"}>arrow_left</i>
                }
              >
                {eventGroupsForSelectedEvent.map((eventGroup, index) => (
                  <Carousel.Item key={index}>
                    <div
                      className={
                        "d-flex w-100 justify-content-center event-group-details-carousel"
                      }
                    >
                      <div className={"w-80"}>
                        <EventGroupDetails
                          eventGroup={eventGroup}
                          key={index}
                          getAllGroupsForEvent={getAllGroupsForEvent}
                          allEventGroupsForEventForLoggedInUser={
                            allEventGroupsForEventForLoggedInUser
                          }
                          getAllUserEventGroupsInEventForLoggedInUser={
                            getAllUserEventGroupsInEventForLoggedInUser
                          }
                        />
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
          </div>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {ApplicationService.hasRole(["ROLE_ADMINISTRATOR", "ROLE_HR"]) && (
          <div className={"ms-auto d-flex"}>
            <div className={"icon-div mx-2"}>
              <div
                onClick={redirectToEditEvent}
                title={"Edit"}
                className={"text-dark icon"}
              >
                <i className={"edit-icon-circle material-icons "}>create</i>
              </div>
            </div>
            <div className={"icon-div"}>
              <a title={"Delete"}>
                <div
                  onClick={() =>
                    ConfirmationDelete(props.selectedEventForEdit?.eventId)
                  }
                  title={"Delete"}
                  className={"text-dark icon"}
                >
                  <i className={"material-icons delete-icon-circle"}>delete</i>
                </div>
              </a>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EventDetails;
