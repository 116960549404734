import React from 'react';
import ReactPaginate from 'react-paginate';
import { useEffect, useState } from 'react';
import UserTerm from '../UserTerm/UserTerm';
import UserService from '../../../repository/userRepository/UserRepository';
import '../../../components/Table.css';
import AddUserModal from '../User_modal/AddUserModal';
import RolesRepository from '../../../repository/rolesRepository/RolesRepository';
import SpinnerComponent from '../../../components/Spinner';
import decode from 'jwt-decode';
import { strings } from '../../../localization/Localization';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import AddButton from '../../../components/addButton/AddButton';
import Select from 'react-select';
import UserFilter from '../../../components/UserFilter/UserFilter';
import '../../../components/UserFilter/UserFilter.css';

const UserList = (props) => {

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [userList, setUserList] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [showModal, setShow] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [listForDropDown, setListForDropDown] = useState([]);
  const [selectedUserForEdit, setSelectedUserForEdit] = useState({});
  const [listOfDefaultSelectedRolesForDropDown, setListOfDefaultSelectedRolesForDropDown] = useState([]);
  const [spinnerShow, setShowSpinner] = useState(true);
  const [decodeToken, setDecodeToken] = useState({});
  const [isOpen, setIsOpen] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filterUser, setFilterUser] = React.useState({
    firstName: null,
    lastName: null,
    parentName: null,
    email: null,
    hrmsIdNumber: null,
    roleIds: [],
    religion: [],
    ethnicity: [],
    organisation: [],
    position: []
  });

  const [resetIsLoading, setResetIsLoading] = useState(false);

  const [searchIsLoading, setSearchIsLoading] = useState(false);

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const [selectedUserForChangePassword, setSelectedUserForChangePassword] = useState({});

  useEffect(() => {
    const pageNumber = localStorage.getItem('userPage');
    const pageSizeNumber = localStorage.getItem('userSizePage');
    if (pageNumber) {
      setPage(pageNumber);
    }
    if (pageSizeNumber) {
      setSize(pageSizeNumber);
    }
  }, []);

  useEffect(() => {
    loadUserAndPage();
    const token = localStorage.getItem('auth_token');
    setDecodeToken(decode(token));
  }, [page, size]);

  useEffect(() => {
    if (selectedUserForEdit.id) {
      convertSelectedRolesForDropDown(selectedUserForEdit.roles);
    }
  }, [selectedUserForEdit, allRoles]);

  const onClickSetSelectedUserForEdit = (e) => {
    setSelectedUserForEdit(e);
    handleShow();
  };

  const onClickSetSelectedUserForChangePassword = (e) => {
    setSelectedUserForChangePassword(e);
    setShowChangePasswordModal(!showChangePasswordModal);
  };

  const setDefaultSelectedUser = () => {
    setSelectedUserForEdit({});
    setListOfDefaultSelectedRolesForDropDown([]);
  };

  useEffect(() => {
    convertAllRolesForDropDown();
  }, [allRoles]);

  const loadAllRoles = () => {
    RolesRepository.fetchRoles()
      .then((data) => {
        setAllRoles(data.data);
      });
  };

  const handleClose = () => {
    setSelectedUserForEdit({});
    setShow(false);
  };

  const handleCloseChangePasswordUserModal = () => {
    setSelectedUserForChangePassword({});
    setShowChangePasswordModal(false);
  };

  const handleShow = () => {
    loadAllRoles();
    setShow(true);
  };

  const handleSpinnerClose = () => {
    setShowSpinner(true);
  };

  const handleSpinnerShow = () => {
    setShowSpinner(false);
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleLocalStoragePageNumber = (pageNumber) => {
    localStorage.setItem('userPage', pageNumber);
  };

  const handleLocalStoragePageSizeNumber = (pageSizeNumber) => {
    localStorage.setItem('userSizePage', pageSizeNumber);
  };

  const loadUserAndPage = () => {
    handleSpinnerShow();
    UserService.fetchUsersIgnoreIsActive(page, size, filterUser)
      .then((data) => {
        handleSpinnerClose();
        setPageCount(data.data.totalPages);
        setUserList(data.data.content);
      }).catch(()=>   handleSpinnerClose());
  };

  const convertAllRolesForDropDown = () => {
    var convertRole = [];
    for (var i = 0; i < allRoles.length; i++) {
      convertRole[i] = {
        value: allRoles[i].id,
        label: allRoles[i].label
      };
    }
    setListForDropDown(convertRole);
  };

  const convertSelectedRolesForDropDown = (roles) => {
    var convertRole = rolesFilter(roles);
    var roleForDropDown = [];
    for (var i = 0; i < convertRole.length; i++) {
      roleForDropDown[i] = {
        value: convertRole[i].id,
        label: convertRole[i].label
      };
    }
    setListOfDefaultSelectedRolesForDropDown(roleForDropDown);
  };

  const rolesFilter = (roles) => {
    var convertRole = [];
    for (var i = 0; i < allRoles.length; i++) {
      for (var j = 0; j < roles.length; j++) {
        if (roles[j] == allRoles[i].label) {
          convertRole.push(allRoles[i]);
        }
      }
    }
    return convertRole;
  };

  const deleteUser = (id) => {
    UserService.deleteUser(id)
      .then(() => {
        loadUserAndPage();
      }).catch(() => {
    });
  };

  const loadUserAndPageFiltered = (firstName, lastName, parentName, email, roleIds, religion, ethnicity, organisationsIds, positionsIds, resetDate = false, searchData = false, hrmsIdNumber) => {// proveri dali e okej
    if (resetDate) {
      setResetIsLoading(true);
    } else if (searchData) {
      setSearchIsLoading(true);
    }

    setFilterUser({
      firstName: firstName,
      lastName: lastName,
      parentName: parentName,
      hrmsIdNumber: hrmsIdNumber,
      email: email,
      roleIds: roleIds,
      religion: religion,
      ethnicity: ethnicity,
      organisation: organisationsIds,
      position: positionsIds
    });
    handleSpinnerShow();
    UserService.fetchUsersIgnoreIsActive(page, size, {
      firstName,
      lastName,
      parentName,
      hrmsIdNumber,
      email,
      roleIds,
      religion,
      ethnicity,
      organisation: organisationsIds,
      position: positionsIds
    })
      .then((data) => {
        handleSpinnerClose();
        setPageCount(data.data.totalPages);
        setUserList(data.data.content);
        setPage(0);
        handleLocalStoragePageNumber(0);
        if (resetDate) {
          setResetIsLoading(false);
        } else if (searchData) {
          setSearchIsLoading(false);
        }
      }).catch(() => {
        handleSpinnerClose();
      setResetIsLoading(false);
      setSearchIsLoading(false);
    });
  };

  const resetFilterData = () => {
    setFilterUser({
      firstName: null,
      lastName: null,
      parentName: null,
      email: null,
      hrmsIdNumber: null,
      roles: [],
      religion: [],
      ethnicity: [],
      organisation: [],
      position: []
    });
  };

  const getUserPage = () => {
    return userList.map((term) => {
      return (
        <UserTerm
          key={term.id}
          onClickSetSelectedUserForEdit={onClickSetSelectedUserForEdit}
          term={term}
          deleteUser={deleteUser}
          onClickSetSelectedUserForChangePassword={onClickSetSelectedUserForChangePassword}
        />
      );
    });
  };

  const userTermPage = getUserPage();

  const handlePageClick = (data) => {
    let selected = data.selected;
    setPage(selected);
    handleLocalStoragePageNumber(data.selected);
  };

  const onClickHandle = () => {
    handleShow();
    setDefaultSelectedUser();
  };

  const selectSize = (e) => {
    setPage(0);
    setSize(e.value);
    handleLocalStoragePageNumber(0);
    handleLocalStoragePageSizeNumber(e.value);
  };

  return (
    <div>
      <div className={'w-100 d-flex justify-content-between align-items-center'}>
        <div className={'d-flex align-items-end mt-auto'}>
          <h1 className={'d-flex align-items-end mt-auto'}>{strings.UserList}</h1>
        </div>

        <div className={'d-flex'}>
          <div className={'align-items-center py-1 d-flex justify-content-end mx-2'}>
            <div
              className='btn btn-secondary d-flex justify-content-center text-end  align-items-end users_filter_button'
              onClick={handleFilter}><i
              className={'material-icons me-1 users_filter_button_icon'}>filter_list</i> {strings.filter}
            </div>
          </div>
          <div className={'align-items-center d-flex justify-content-end'}>
            <AddButton onClickHandle={onClickHandle}></AddButton>
          </div>
        </div>
      </div>
      <div className={`${showModal ? 'mt-2' : ''}`}>
        {showFilter && <UserFilter loadUserFiltred={loadUserAndPageFiltered} resetFilterData={resetFilterData}
                                   resetIsLoading={resetIsLoading} searchIsLoading={searchIsLoading}/>}
      </div>
      <SpinnerComponent spinnerShow={spinnerShow}/>
      <div className={'container'}>
        <div>
          <div className={'row table-responsive'}>
            <table id='keywords' className={'mb-1'}>
              <thead>
              <tr className='userlist'>
                <th scope={'col'}><b>{strings.hrmsIdNumber}</b></th>
                <th scope={'col'}></th>
                <th scope={'col'}>{strings.name}</th>
                <th scope={'col '} className={'pe-4'}>{strings.parent}</th>
                <th scope={'col'}>{strings.lastName}</th>
                <th scope={'col'}>{strings.contact}</th>
                <th scope={'col'}>{strings.roles}</th>
                <th scope={'col'}>{strings.organization}</th>
                <th scope={'col'}>{strings.position}</th>
                <th scope={'col'} className={'pe-1'}>

                </th>
              </tr>
              </thead>
              <tbody>
              {userList.length > 0 &&
                userTermPage
              }
              </tbody>
            </table>
            {
              userList.length === 0 &&
              <div className={'justify-content-center text-center w-100'}>
                <div>
                  <h5 className={'text-secondary w-100 '}>
                    <i>{strings.emptyList}</i></h5>
                </div>
              </div>
            }
          </div>
          {
            userList.length !== 0 &&
            <div className={'d-flex justify-content-between my-3'}>
              <div className={'mx-auto h-100 align-items-center  d-flex'}>
                <ReactPaginate previousLabel={<AiOutlineArrowLeft/>}
                               nextLabel={<AiOutlineArrowRight/>}
                               breakLabel={'...'}
                               pageCount={pageCount}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               forcePage={parseInt(page)}
                               onPageChange={handlePageClick}
                               breakClassName={'page-item'}
                               breakLinkClassName={'page-link'}
                               containerClassName={'pagination h-100 m-0 justify-content-center'}
                               pageClassName={'page-item'}
                               pageLinkClassName={'page-link'}
                               previousClassName={'page-item'}
                               previousLinkClassName={'page-link'}
                               nextClassName={'page-item'}
                               nextLinkClassName={'page-link'}
                               activeClassName={'active'}/>
              </div>
              <div className={'d-flex align-items-end'}>
                <div>
                  <Select
                    placeholder={strings.size}
                    onChange={selectSize}
                    defaultValue={{
                      value: size,
                      label: size
                    }}
                    options={[
                      {
                        value: 5,
                        label: '5'
                      },
                      {
                        value: 10,
                        label: '10'
                      },
                      {
                        value: 15,
                        label: '15'
                      },
                      {
                        value: 20,
                        label: '20'
                      }
                    ]}
                    styles={{
                      control: base => ({
                        ...base,
                        height: 40,
                        border: 'none'
                      })
                    }}
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <AddUserModal showModal={showModal}
                    handleClose={handleClose}
                    listForDropDown={listForDropDown}
                    defaultListForDropdown={listOfDefaultSelectedRolesForDropDown}
                    selectedUserForEdit={selectedUserForEdit}
                    handleSpinnerShow={handleSpinnerShow}
                    handleSpinnerClose={handleSpinnerClose}
                    loadUserAndPage={loadUserAndPage}/>
    </div>

  );
};
export default UserList;