import axios from "../../axios/axios";

const ProjectGroupService = {
    getAllGroups: () => {
        return axios.get("/group/getAll")
    },

    getGroupById: (id) => {
        return axios.get(`/group/getGroupById/${id}`)
    },

    deleteGroup: (id) => {
        return axios.delete(`/group/deleteGroup/${id}`)
    },

    addGroup: (name, description) => {
        return axios.post("/group/addGroup", {
            name,
            description
        })
    },

    editGroup: (id, name, description) => {
        return axios.put(`/group/editGroup`, {
            id,
            name,
            description
        })
    },
}
export default ProjectGroupService;
