import axios from "../../axios/axios";

const UserEmploymentService = {
    getAll: () => {
        return axios.get("")
    },
    getUserEmploymentData: (userId) => {
        return axios.get(`/user-employment/getUserEmploymentData/${userId}`)
    },
    getLoggedUserEmploymentData: () => {
        return axios.get(`/user-employment/getLoggedUserEmploymentData`)
    },
    addUserEmploymentData: (userId, engagementId, contractId, employmentId, startDate, endDate, workDaysData) => {
        return axios.post("/user-employment/addUserEmploymentData", {
            userId,
            engagementId,
            contractId,
            employmentId,
            startDate,
            endDate,
            workDaysData
        })
    },
    addUserEmploymentDataByAdmin: (userId, engagementId, contractId, employmentId, startDate, endDate, workDaysData) => {
        return axios.post("/user-employment/addUserEmploymentDataByAdmin", {
            userId,
            engagementId,
            contractId,
            employmentId,
            startDate,
            endDate,
            workDaysData
        })
    },
    editUserEmploymentData: (userEmploymentId, userId, engagementId, contractId, employmentId, startDate, endDate, workDaysData) => {
        return axios.post("/user-employment/updateUserEmploymentData", {
            userEmploymentId,
            userId,
            engagementId,
            contractId,
            employmentId,
            startDate,
            endDate,
            workDaysData
        })
    },
    editUserEmploymentDataByAdmin: (userEmploymentId, userId, engagementId, contractId, employmentId, startDate, endDate, workDaysData) => {
        return axios.post("/user-employment/updateUserEmploymentDataByAdmin", {
            userEmploymentId,
            userId,
            engagementId,
            contractId,
            employmentId,
            startDate,
            endDate,
            workDaysData
        })
    },
    deleteUserEmploymentData: (id) => {
        return axios.delete(`/user-employment/deleteUserEmploymentData/${id}`)
    }

}

export default UserEmploymentService;