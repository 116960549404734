import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { strings } from "../../../../localization/Localization";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./AddDocumentModal.css";
import Select from "react-select";
import userRepository from "../../../../repository/userRepository/UserRepository";
import DocumentService from "../../../../repository/documentRepository/DocumentRepository";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import UserService from "../../../../repository/userRepository/UserRepository";
import DraggableModalDialog from "../../../../components/DraggableModalDialog/DraggableModalDialog";

const AddDocumentModal = (props) => {
  const [selectedUserId, setSelectedUserId] = useState();
  const param = useParams();
  const [file, setFile] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [openByAdmin, setOpenByAdmin] = useState(false);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [formData, updateFormData] = useState({
    language: "",
    description: "",
    documentType: "",
    documentDate: "",
  });

  const inputRef = React.useRef(null);

  const [dragActive, setDragActive] = React.useState(false);

  useEffect(() => {
    if (!props.showModal) {
      resetData();
    } else {
      getUserForFilesUpload();
      if (props.initialDocumentType != null) {
        updateFormData({
          ...formData,
          documentType: props.initialDocumentType,
          documentDate: getTodayDate(),
        });
      }
    }
  }, [props.showModal]);

  const getUserForFilesUpload = () => {
    if (param.id) {
      UserService.getUserDetailsById(param.id)
        .then((data) => {
          setSelectedUserId(data.data.id);
          setOpenByAdmin(true);
          setIsActive(data.data.active);
        })
        .catch(() => {});
    } else {
      UserService.getUserDetails()
        .then((data) => {
          setSelectedUserId(data.data.id);
          setOpenByAdmin(false);
          setIsActive(data.data.active);
        })
        .catch(() => {});
    }
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong + strings.fileMightAlreadyExist,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const resetData = () => {
    updateFormData({
      language: "",
      description: "",
      documentType: "",
      documentDate: "",
    });
    setFile({});
  };

  const handleChange = (value, name) => {
    if (name === "documentDate") {
      updateFormData({
        ...formData,
        [name]: value,
      });
    } else if (name === "description" || name === "language") {
      updateFormData({
        ...formData,
        [name]: value,
      });
    } else {
      const selectedOption = value ? { value, label: value } : null;
      if (
        !selectedOption &&
        inputRef.current &&
        inputRef.current.hasAttribute("required")
      ) {
        inputRef.current.setCustomValidity(strings.fieldsRequired);
      } else {
        inputRef.current.setCustomValidity("");
      }
  
      updateFormData({
        ...formData,
        [name]: selectedOption ? selectedOption.value : null,
      });
    }
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleChangeFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleUpload = (
    selectedUserId,
    documentType,
    documentDate,
    language,
    description
  ) => {
    const formData = new FormData();
    formData.append("selectedUserId", selectedUserId);
    formData.append("file", file);
    formData.append("documentType", documentType);
    formData.append("documentDate", documentDate);
    formData.append("language", language);
    formData.append("description", description);

    DocumentService.uploadDocument(formData)
      .then((data) => {
        props.handleClose();
        successfulAlert();
        props.getDocumentData();
      })
      .catch(() => {
        warningAlert();
      });
  };

  const handleUploadByAdmin = (
    selectedUserId,
    documentType,
    documentDate,
    language,
    description
  ) => {
    const formData = new FormData();
    formData.append("selectedUserId", selectedUserId);
    formData.append("file", file);
    formData.append("documentType", documentType);
    formData.append("documentDate", documentDate);
    formData.append("language", language);
    formData.append("description", description);

    DocumentService.uploadDocumentByAdmin(formData)
      .then((data) => {
        props.handleClose();
        successfulAlert();
        props.getDocumentData();
      })
      .catch(() => {
        warningAlert();
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (param.id === undefined) {
      handleUpload(
        selectedUserId,
        formData.documentType,
        formData.documentDate,
        formData.language,
        formData.description
      );
    } else {
      handleUploadByAdmin(
        selectedUserId,
        formData.documentType,
        formData.documentDate,
        formData.language,
        formData.description
      );
    }
  };

  return (
    <Modal
      show={props.showModal}
      dialogAs={DraggableModalDialog}
      onHide={props.handleClose}
      size={"lg"}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{strings.addDocument}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <Modal.Body>
          <Row className={"mb-2"}>
            <Col xs={12} md={6}>
              <Row>
                <Col xs={12} md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className={"d-flex"}>
                      {strings.documentType}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Select
                                            type="text"
                                            required
                                            autoFocus
                                            onChange={(e) => handleChange(e, "documentType")}
                                            value={formData.documentType == "BIOGRAPHY" ? {
                                                    value: formData.documentType,
                                                    label: strings.certificate
                                                }
                                                : {value: formData.documentType, label: formData.documentType}}
                                            options={[{
                                                value: "CV", label: "CV"
                                            },
                                                {
                                                    value: "BIOGRAPHY", label: "Сертификат"
                                                }]}
                                            placeholder={strings.selectOption}
                                            noOptionsMessage={()=>strings.noOptions}
                                            name="documentType"
                                            onInvalid={(e) => {
                                                e.target.setCustomValidity(strings.fieldsRequired);
                                              }}
                                              isDisabled={true}
                                        />

                  </Form.Group>
                </Col>
                <Col xs={12} md={12}>
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className={"d-flex"}>
                      {strings.documentLanguage}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Select
                      type="text"
                      required
                      autoFocus
                      onChange={(selectedOption) =>
                        handleChange(selectedOption.value, "language")
                      }
                      options={[
                        {
                          value: "MK",
                          label: "MK",
                        },
                        {
                          value: "EN",
                          label: "EN",
                        },
                      ]}
                      placeholder={strings.selectOption}
                      noOptionsMessage={() => strings.noOptions}
                      name="language"
                      ref={inputRef}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className={"d-flex"}>
                      {strings.documentDate}
                    </Form.Label>
                    <Form.Control
                      type={"date"}
                      value={formData.documentDate}
                      onChange={(e) =>
        handleChange(e.target.value, "documentDate")
      }
                      name={"documentDate"}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className={"d-flex"}>
                  {strings.description}
                </Form.Label>
                <textarea
                  className={"w-100 form-control free-text"}
                  onChange={(e) => handleChange(e.target.value, "description")}
                  rows={8}
                  name={"documentDate"}
                />
              </Form.Group>
            </Col>
          </Row>
          {/*<Row className={"mb-2"}>*/}

          {/*</Row>*/}
          <Row>
            <Col xs={12} md={12}>
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className={"d-flex"}>
                  {strings.selectDocument}
                </Form.Label>
                <div id="form-file-upload w-75" onDragEnter={handleDrag}>
                  <input
                    ref={inputRef}
                    type="file"
                    id="input-file-upload"
                    onChange={handleChangeFile}
                  />
                  <label
                    id="label-file-upload"
                    htmlFor="input-file-upload"
                    className={dragActive ? "drag-active" : ""}
                  >
                    <div className={"text-center pb-4 pt-4"}>
                      <p>{strings.dragAndDropYourFileHereOr}</p>
                      <div className="upload-button mx-auto d-flex align-items-center justify-content-center text-center ">
                        <span className={"me-2"}>{strings.uploadFile}</span>{" "}
                        <i className={"material-icons"}>cloud_upload</i>
                      </div>

                      <div>{file.name}</div>
                    </div>
                  </label>
                  {dragActive && (
                    <div
                      id="drag-file-element"
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    ></div>
                  )}
                </div>

                {/*<span className={"mx-2"}>{file?.name}</span>*/}
                {/*<input type="file" name="file" id="chooseDocument" className={"add_documents_input"}*/}
                {/*       onChange={handleFile}/>*/}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className={"cancel btn text-white"} onClick={props.handleClose}>
            {strings.cancel}
          </div>
          <button type={"submit"} className={"save btn text-white"}>
            {strings.save}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default AddDocumentModal;
