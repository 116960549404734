import React, { useEffect, useState } from "react";
import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import { strings } from "../../../localization/Localization";
import { useHistory } from "react-router-dom";
import PositionHierarchyService from "../../../repository/hierarchyRepository/positionHierarchyRepository";
import Select from "react-select";
import OrganizationalUnitRepository from "../../../repository/organizationUnitRepository/OrganizationalUnitRepository";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const PositionHierarchyEditModal = (props) => {
  const history = useHistory();

  const [formData, updateFormData] = React.useState({
    hrmsStPositionsHId: "",
    activeFrom: "",
    activeTo: "",
    code: "",
    name: "",
    shortName: "",
    description: "",
    dateCreated: "",
    dateModified: "",
    parentId: "",
  });

  const [organisationForDropDown, setOrganisationForDropDown] = useState({});

  const [selectedOrganisation, setSelectedOrganisation] = useState({});
  const [userPositionList, setUserPositionList] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);

  useEffect(() => {
    if (props.showModal) {
      getAllOrganisations();
      fetchUserPositions();
    } else {
      setSelectedOrganisation({});
      setUserPositionList([]);
    }
  }, [props.showModal]);

  const fetchUserPositions = (
    data = {
      firstName: null,
      lastName: null,
      parentName: null,
      email: null,
      hrmsIdNumber: null,
      roleIds: [],
      religion: [],
      ethnicity: [],
      organisation: [],
      position: [],
    },
  ) => {
    PositionHierarchyService.getAllUsersForPositionPaginated(
      props.selectedNode.id,
      page,
      size,
      data
    ).then((data) => {
      setUserPositionList(data.data.content);
    });
  };

  const getAllOrganisations = () => {
    OrganizationalUnitRepository.getAllOrganisation().then((data) => {
      setOrganisationForDropDown(convertOrganisationForDropdown(data.data));
      let filteredSelectedOrganisation = data.data.filter(
        (e) => e.id === props.selectedNode?.data?.organistaionId ? props.selectedNode.data.organistaionId : props.selectedNode.id
      );
      if (filteredSelectedOrganisation.length > 0) {
        setSelectedOrganisation({
          value: filteredSelectedOrganisation[0].id,
          label: filteredSelectedOrganisation[0].name,
        });
      }
    });
  };

  const convertOrganisationForDropdown = (organisation) => {
    return organisation.map((e) => {
      return {
        label: e.name,
        value: e.id,
      };
    });
  };

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectedOrganisationChange = (e) => {
    setSelectedOrganisation(e);
  };

  const editHierarchy = (
    id,
    hrmsStPositionsHId,
    activeFrom,
    activeTo,
    code,
    name,
    shortName,
    description,
    dateCreated,
    dateModified,
    parentId,
    organisation
  ) => {
    PositionHierarchyService.editHierarchy(
      id,
      hrmsStPositionsHId,
      activeFrom,
      activeTo,
      code,
      name,
      shortName,
      description,
      dateCreated,
      dateModified,
      parentId,
      organisation
    ).then((data) => {
      props.handleClose();
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    
    const hrmsStPositionsHId =
      formData.hrmsStPositionsHId !== ""
        ? formData.hrmsStPositionsHId
        : props.selectedNode.hrmsStPositionsHId;
    const activeFrom =
      formData.activeFrom !== ""
        ? formData.activeFrom
        : props.selectedNode.activeFrom;
    const activeTo =
      formData.activeTo !== ""
        ? formData.activeTo
        : props.selectedNode.activeTo;
    const code =
      formData.code !== "" ? formData.code : props.selectedNode?.data?.code ?? props.selectedNode.code;
    const name = formData.name !== "" ? formData.name : props.selectedNode.text;
    const description =
      formData.description !== ""
        ? formData.description
        : props.selectedNode?.data?.description ?? props.selectedNode.description;
    const shortName =
      formData.shortName !== ""
        ? formData.shortName
        : props.selectedNode?.data?.shortName ?? props.selectedNode.shortName;
    const organisation = selectedOrganisation.value;
    const dateCreated = "";
    const dateModified = "";

    if (props.selectedNode.id) {
      editHierarchy(
        props.selectedNode.id,
        hrmsStPositionsHId,
        activeFrom,
        activeTo,
        code,
        name,
        shortName,
        description,
        dateCreated,
        dateModified,
        props.selectedNode.parentId,
        organisation
      );
    }
  };
  
  return (
    <>
      <Modal
        show={props.showModal}
        dialogAs={DraggableModalDialog}
        onHide={props.handleClose}
        size={"lg"}
        centered
      >
        <ModalHeader closeButton>
          <ModalTitle>
            <b>{strings.editPosition}</b>
          </ModalTitle>
        </ModalHeader>
        <Form onSubmit={onFormSubmit}>
          <ModalBody>
            <Form.Group className="mb-3" controlId="hierarchyName">
              <Form.Label>{strings.positionName}</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.selectedNode?.text ?? props.selectedNode?.name}
                onChange={handleChange}
                autoFocus
                name="name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{strings.positionShortName}</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.selectedNode?.data?.shortName ?? props.selectedNode?.shortName}
                onChange={handleChange}
                name="shortName"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{strings.organizationApplies}</Form.Label>
              <Select
                type="text"
                onChange={handleSelectedOrganisationChange}
                value={selectedOrganisation}
                options={organisationForDropDown}
                placeholder={strings.selectOption}
                noOptionsMessage={() => strings.noOptions}
                name="shortName"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{strings.positionDescription}</Form.Label>
              <textarea
                name="description"
                className="form-control"
                defaultValue={props.selectedNode?.data?.description ?? props.selectedNode?.description}
                onChange={handleChange}
                id="exampleFormControlTextarea1"
                rows="2"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{strings.positionCode}</Form.Label>
              <textarea
                name="code"
                className="form-control"
                defaultValue={props.selectedNode?.data?.code ?? props.selectedNode?.code}
                onChange={handleChange}
                id="exampleFormControlTextarea1"
                rows="2"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{strings.membersOfThePosition}</Form.Label>
              <table className="table table-responsive table-borderless text-start">
                <thead className={"custom-table-header text-dark"}>
                  <tr>
                    <th scope="col" width="5%"></th>
                    <th scope="col" width="30%">{strings.name}</th>
                    <th scope="col" width="30%">{strings.lastName}</th>
                    <th scope="col" width="30%">{strings.email}</th>
                  </tr>
                </thead>
                <tbody>
                  {userPositionList.length > 0 ? (
                    userPositionList.map((user) => {
                      return (
                        <tr key={user.id}>
                          <td scope={"row"}></td>
                          <td>{user.firstName}</td>
                          <td>{user.lastName}</td>
                          <td>{user.email}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className={"justify-content-center text-center"}>
                      <td colSpan={5}>
                        <h6 className={"text-secondary"}>
                          <i>{strings.noMemberInPosition}</i>
                        </h6>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Form.Group>
          </ModalBody>
          <Modal.Footer>
            <div
              className={"cancel btn text-white"}
              onClick={props.handleClose}
            >
              {strings.cancel}
            </div>
            <div
              type={"submit"}
              className={"save btn text-white"}
              onClick={onFormSubmit}
            >
              {strings.save}
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default PositionHierarchyEditModal;
