import {strings} from "../../../../localization/Localization";
import React, {useEffect, useState} from "react";
import AddButton from "../../../../components/addButton/AddButton";
import UserEmploymentService from "../../../../repository/UserEmploymentRepository/UserEmploymentRepository";
import {useParams} from "react-router-dom";
import swal from "sweetalert";
import AddUserEmploymentModal from "./UserEmploymentModal/UserEmploymentModal";
import ApplicationService from "../../../../ApplicationService";
import Row from "react-bootstrap/Row";
import UserWorkDayView from "./UserWorkDayView/UserWorkDayView";
import GreyCard from "../../../../components/GreyCard/GreyCard";
import FormatDate from "../../../../components/formatDate/FormatDate";
import SpinnerComponent from "../../../../components/Spinner";

const EmploymentData = (props) => {

    const [allEmploymentForUser, setAllEmploymentForUser] = useState([]);

    const [showAddModal, setShowAddModal] = useState(false);

    const [selectedEmploymentForEdit, setSelectedEmploymentForEdit] = useState({});
    const [loading, setLoading] = useState(false);

    const param = useParams();

    useEffect(() => {
        if (props.currentTab === "professionalData") {
            getEmploymentForUser();
        }
    }, [props.currentTab]);

    useEffect(()=>{
        if(!showAddModal){
            setSelectedEmploymentForEdit({});
        }
    },[showAddModal])

    const ConfirmationDelete = (id) => {
        swal(strings.deleteConfirmation, {
            title: strings.areYouSure,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteUserEmployment(id);
            }
        });
    };

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const errorAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const handleCloseUserAddEmploymentModal = () => {
        setSelectedEmploymentForEdit({});
        setShowAddModal(false);
    };

    const handleShowUserEmploymentModal = () => {
        setLoading(true);
        setShowAddModal(true);
        setLoading(false);
     
    };

    const getEmploymentForUser = () => {
        if (param.id) {
            UserEmploymentService.getUserEmploymentData(param.id).then((data) => {
                setAllEmploymentForUser(data.data);
                setLoading(false);
            })
        } else {
            UserEmploymentService.getLoggedUserEmploymentData().then((data) => {
                setAllEmploymentForUser(data.data);
                setLoading(false);
            })
        }
    };

    const deleteUserEmployment = (id) => {
        UserEmploymentService.deleteUserEmploymentData(id)
            .then(() => {
                successfulAlert();
                getEmploymentForUser();
            })
            .catch(() => {
                errorAlert();
            })
    };

    const formatEmploymentDateRange = (startDate, endDate) => {
        const formattedStartDate =  startDate ? FormatDate.formatDate(startDate) :'';
        const formattedEndDate = endDate ? FormatDate.formatDate(endDate) : strings.present;
        return `${formattedStartDate} - ${formattedEndDate}`;
    };

    return (
        <div className={"p-3 mt-4 me-2 mb-5"} style={{border: "1px solid lightgray", borderRadius: "10px"}}>
                <div className={"d-flex align-items-center border-radius  mb-4"}>
                    <h4 className={"me-3 text-secondary"} style={{whiteSpace: "nowrap"}}>{strings.employmentData}</h4>
                    <hr className={"me-3"}/>
                    <AddUserEmploymentModal showModal={showAddModal}
                                            handleClose={handleCloseUserAddEmploymentModal}
                                            getEmploymentData={getEmploymentForUser}
                                            selectedEmploymentForEdit={selectedEmploymentForEdit}
                    />
                    {ApplicationService.hasRole(["ROLE_ADMINISTRATOR", "ROLE_HR"]) &&
                  <div className={"me-2"}>
                    {loading && <SpinnerComponent />}
                  <AddButton onClickHandle={handleShowUserEmploymentModal}/>
                </div>
                    }
                </div>

            {allEmploymentForUser.length === 0 ? (
                <div className={"justify-content-center text-center "}>
                    <h5 className={"text-secondary"}><i>{strings.emptyList}</i></h5>
                </div>
            ) : (
                allEmploymentForUser.map((employment) => (
                    <GreyCard key={employment.employmentId}>
                        <div className={"d-flex align-items-center mb-2"}>
                            <i className={"me-1 material-icons"}>work</i> {formatEmploymentDateRange(employment.startDate, employment.endDate)}
                            {ApplicationService.hasRole(["ROLE_ADMINISTRATOR", "ROLE_HR"]) && (
                                <div className={"ms-auto d-flex me-3"}>
                                    <div className={'icon-div mx-3'}>
                                        <div
                                            onClick={() => {
                                                setSelectedEmploymentForEdit(employment);
                                                handleShowUserEmploymentModal();
                                            }}
                                            title={'Edit'}
                                            className={'text-dark icon'}>
                                            <i className={"edit-icon-circle material-icons "}>create</i>
                                        </div>
                                    </div>
                                    <div className={'icon-div'}>
                                        <a title={'Delete'}>
                                            <div
                                                onClick={() => ConfirmationDelete(employment.userEmploymentId)}
                                                title={'Delete'}
                                                className={'text-dark icon'}>
                                                <i className={"material-icons delete-icon-circle"}>delete</i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={"d-flex"}>
                            <div className={"d-flex flex-column"}>
                                <i className="text-secondary mb-1">{strings.engagementType}:</i>
                                <i className="text-secondary mb-1">{strings.contractType}:</i>
                                <i className="text-secondary mb-1">{strings.employmentType}:&nbsp;</i>
                            </div>
                            <div className={"d-flex flex-column"}>
                                <b className="text_blue_dark mb-1">
                                    {ApplicationService.getActiveLanguage() === "mk"
                                        ? employment?.engagementNomenclatureEntryHelper?.labelMk
                                        : employment?.engagementNomenclatureEntryHelper?.labelEn}
                                </b>
                                <b className="text_blue_dark mb-1">
                                    {ApplicationService.getActiveLanguage() === "mk"
                                        ? employment?.contractNomenclatureEntryHelper?.labelMk
                                        : employment?.contractNomenclatureEntryHelper?.labelEn}
                                </b>
                                <b className="text_blue_dark mb-1">
                                    {ApplicationService.getActiveLanguage() === "mk"
                                        ? employment?.employmentNomenclatureEntryHelper?.labelMk
                                        : employment?.employmentNomenclatureEntryHelper?.labelEn}
                                </b>
                            </div>
                        </div>
                        {employment.employmentNomenclatureEntryHelper.name === "Скратено работно време" ? (
                            <React.Fragment>
                                <Row xs={8} md={5}>
                                    <UserWorkDayView
                                        userWorkDays={employment.workDaysData}
                                        onWorkHoursUpdate={(dayId, hours) => {}}
                                    />
                                </Row>
                            </React.Fragment>
                        ) : null}
                    </GreyCard>
                )))
            }
        </div>
    )
}
export default EmploymentData;