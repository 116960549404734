import { strings } from "../../../../localization/Localization";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import UserService from "../../../../repository/userRepository/UserRepository";
import ApplicationService from "../../../../ApplicationService";
import BenefitService from "../../../../repository/benefitsRepository/BenefitsRepository";
import "./AddOrEditUserToBenefitComponent.css";
import swal from "sweetalert";

const AddOrEditUserToBenefitComponent = (props) => {
  const [selectedBenefit, setSelectedBenefit] = useState(
    props.benefit
      ? {
          value: props.benefit.id,
          label:
            ApplicationService.getActiveLanguage() === "mk"
              ? props.benefit.labelMk
              : props.benefit.labelEn,
        }
      : null
  );

  const defaultStartDate = props.matchingValues.filter(
    (value) => value.labelEn === "Start date"
  )[0].labelValue;
  const defaultEndData = props.matchingValues.filter(
    (value) => value.labelEn === "End date"
  )[0].labelValue;
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndData);
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [status, setStatus] = useState("");

  useEffect(() => {
    fetchUsersNotInBenefit();
  }, [selectedBenefit]);

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const fetchUsersNotInBenefit = () => {
    UserService.getAllUsersNotBelongingToBenefit(props.benefit.id)
      .then((response) => {
        const usersNotInBenefit = response.data;
        const userOptions = usersNotInBenefit.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }));
        setUsersList(userOptions);
      })
      .catch((error) => {
        console.error("Error fetching users not in benefit:", error);
      });
  };

  const [formData, updateFormData] = useState({
    userId: "",
    benefitNomenclatureEntryId: "",
    startDate: "",
    endDate: "",
    status: "",
  });

  const resetData = () => {
    updateFormData({
      userId: "",
      benefitNomenclatureEntryId: "",
      startDate: null,
      endDate: null,
      status: "",
    });
  };

  const handleSelectedUsersChange = (e) => {
    setSelectedUsers(e);
  };

  useEffect(() => {
    if (!props.showModal) {
      resetData();
    } else {
      if (props.selectedUserBenefitDetails) {
        const userLabel = `${
          props.selectedUserBenefitDetails?.userHelper?.firstName || ""
        } ${props.selectedUserBenefitDetails?.userHelper?.lastName || ""}`;
        setSelectedUsers({
          value: props.selectedUserBenefitDetails?.userId,
          label: userLabel,
        });
        updateFormData({
          userId: props.selectedUserBenefitDetails?.userId,
          benefitNomenclatureEntryId:
            props.selectedUserBenefitDetails?.benefitNomenclatureEntryId,
          startDate: props.selectedUserBenefitDetails?.startDate,
          endDate: props.selectedUserBenefitDetails?.endDate,
          status: props.selectedUserBenefitDetails?.benefitStatus,
        });
      }
    }
  }, [props.showModal, props.selectedUserBenefitDetails]);

  const onEndDateChange = (e) => {
    const selectedEndDate = e.target.value;

    const today = new Date();
    const selectedEnd = new Date(selectedEndDate);
    {
      today > selectedEnd ? setStatus("INACTIVE") : setStatus("ACTIVE");
    }

    setEndDate(selectedEndDate);
  };

  const addUserToBenefit = (
    selectedUserId,
    benefitNomenclatureEntryId,
    startDate,
    endDate,
    status
  ) => {
    BenefitService.addUserBenefitDataByAdmin(
      selectedUserId,
      benefitNomenclatureEntryId,
      startDate,
      endDate,
      status
    )
      .then(() => {
        successfulAlert();
        props.getAllUserBenefit(benefitNomenclatureEntryId);
        props.getBenefitDetailsById();
        props.handleClose();
      })
      .catch(() => {
        warningAlert();
      });
  };

  const editUserToBenefit = (
    userBenefitId,
    selectedUserId,
    benefitNomenclatureEntryId,
    startDate,
    endDate,
    status
  ) => {
    BenefitService.editUserBenefitDataByAdmin(
      userBenefitId,
      selectedUserId,
      benefitNomenclatureEntryId,
      startDate,
      endDate,
      status
    )
      .then(() => {
        props.getBenefitDetailsById();
        props.handleClose();
        successfulAlert();
      })
      .catch(() => {
        warningAlert();
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (props.selectedUserBenefitDetails?.userBenefitId) {
      editUserToBenefit(
        props.selectedUserBenefitDetails.userBenefitId,
        props.selectedUserBenefitDetails.userHelper?.id,
        props.benefit.id,
        startDate,
        endDate,
        status
      );
    } else {
      addUserToBenefit(
        selectedUsers.value,
        props.benefit.id,
        startDate,
        endDate,
        status
      );
    }
  };

  return (
    <div
      className={`border border-1 border-radius p-2 border-light rounded-4 bg-light w-100 mb-3 ${
        props.showModal ? "show" : "hide"
      }`}
    >
      <div>
        <Form onSubmit={onFormSubmit}>
          <div>
            <div className="row mb-2">
              <div className="col-md-3">
                <Form.Label className={"d-flex"}>
                  {strings.chooseUser}
                </Form.Label>
                <Select
                  onChange={handleSelectedUsersChange}
                  value={selectedUsers}
                  options={usersList}
                  placeholder={strings.selectOption}
                  noOptionsMessage={() => strings.noOptions}
                  isClearable
                  isDisabled={
                    props.selectedUserBenefitDetails.userBenefitId != null
                  }
                />
              </div>
              <div className="col-md-3">
                <Form.Label className={"d-flex"}>{strings.status}</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    status === "ACTIVE" ? strings.active : strings.inactive
                  }
                  disabled
                />
              </div>
              <div className="col-md-3">
                <Form.Group className="mb-3">
                  <Form.Label>
                    {strings.startDate}<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                    max={endDate}
                    min={props.benefit.startDate}
                    onInvalid={(e) => {
                      e.target.setCustomValidity(strings.fieldsRequired);
                    }}
                  />
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group className="mb-3">
                  <Form.Label>{strings.endDate}<span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={onEndDateChange}
                    required
                    min={startDate}
                    max={props.benefit.endDate}
                    onInvalid={(e) => {
                      e.target.setCustomValidity(strings.fieldsRequired);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <div
              className="cancel btn text-white me-2"
              onClick={props.handleClose}
            >
              {strings.cancel}
            </div>
            <button type={"submit"} className="save btn text-white">
              {strings.save}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddOrEditUserToBenefitComponent;
