import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReligionUnitRepository from "../../../repository/religionUnit/ReligionUnitRepository";
import { strings } from "../../../localization/Localization";
import InputLimit from "../../../components/InputLimit/InputLimit";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const ReligionModal = (props) => {
  const history = useHistory();

  const [formData, updateFormData] = React.useState({
    id: "",
    name: "",
    label_mk: "",
    label_sq: "",
    label_en: "",
  });

  useEffect(() => {
    resetFormData();
  }, [props.showModal]);

  const addReligion = (name, label_mk, label_sq, label_en) => {
    ReligionUnitRepository.addReligion(name, label_mk, label_sq, label_en)
      .then((data) => {
        props.handleClose();
        successfulAlert();
        history.push("/religion");
      })
      .catch(() => {
        warningAlert();
      });
  };

  const editReligion = (id, name, label_mk, label_sq, label_en) => {
    ReligionUnitRepository.editReligion(id, name, label_mk, label_sq, label_en)
      .then((data) => {
        props.handleClose();
        successfulAlert();
        history.push("/religion");
      })
      .catch((e) => {
        warningAlert();
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (e.target.hasAttribute("required") && value.trim() === "") {
      e.target.setCustomValidity(strings.fieldsRequired);
    } else {
      e.target.setCustomValidity("");
    }

    if (name === "name") {
      const pattern = /^[A-Z0-9!@#$%^&*()_+{}\[\]:;"'<>,.?~]+$/;
      if (value === "" || pattern.test(value)) {
        updateFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      updateFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const resetFormData = () => {
    updateFormData({
      id: "",
      name: "",
      label_mk: "",
      label_sq: "",
      label_en: "",
    });
  };

  const successfulAlert = () => {
    swal(strings.successfulReligion, {
      icon: "success",
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      } else {
        swal(strings.fileIsSafe);
      }
    });
  };

  useEffect(() => {
    if (props.showModal) {
      if (props.selectedReligionForEdit.id) {
        updateFormData({
          id: props.selectedReligionForEdit.id,
          name: props.selectedReligionForEdit.name,
          label_mk: props.selectedReligionForEdit.labelMk,
          label_sq: props.selectedReligionForEdit.labelSq,
          label_en: props.selectedReligionForEdit.labelEn,
        });
      }
    }
  }, [props.showModal]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    document.getElementsByClassName("modal-content").disabled = true;
    const id =
      formData.id !== "" ? formData.id : props.selectedReligionForEdit.id;
    const name =
      formData.name !== "" ? formData.name : props.selectedReligionForEdit.name;
    const label_mk =
      formData.label_mk !== ""
        ? formData.label_mk
        : props.selectedReligionForEdit.labelMk;
    const label_sq =
      formData.label_sq !== ""
        ? formData.label_sq
        : props.selectedReligionForEdit.labelSq;
    const label_en =
      formData.label_en !== ""
        ? formData.label_en
        : props.selectedReligionForEdit.labelEn;
    if (props.selectedReligionForEdit.id) {
      editReligion(
        props.selectedReligionForEdit.id,
        name,
        label_mk,
        label_sq,
        label_en
      );
    } else {
      addReligion(name, label_mk, label_sq, label_en);
    }
  };

  return (
    <Modal
      show={props.showModal}
      dialogAs={DraggableModalDialog}
      onHide={props.handleClose}
      size={"lg"}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.selectedReligionForEdit.id
            ? strings.editReligion
            : strings.addNewReligion}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <h5>
              {strings.code}
              <span className="text-danger">*</span>
            </h5>
            {InputLimit.codeLimitMessage()}
            <Form.Control
              type="text"
              required
              autoFocus
              value={formData?.name}
              onChange={handleChange}
              name="name"
              onInvalid={(e) => {
                e.target.setCustomValidity(strings.fieldsRequired);
              }}
            />
            <h5 className={"my-4"}>{strings.marks}</h5>
            <Row className={"px-2"}>
              <Col xs={12} md={4}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {strings.labelMk}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={formData?.label_mk}
                    onChange={handleChange}
                    name="label_mk"
                    onInvalid={(e) => {
                      e.target.setCustomValidity(strings.fieldsRequired);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group
                  className="mb-3 required"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{strings.labelSq}</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData?.label_sq}
                    onChange={handleChange}
                    name="label_sq"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {strings.labelEn}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={formData?.label_en}
                    onChange={handleChange}
                    name="label_en"
                    required
                    onInvalid={(e) => {
                      e.target.setCustomValidity(strings.fieldsRequired);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <div className={"cancel btn text-white"} onClick={props.handleClose}>
            {strings.cancel}
          </div>
          <button type={"submit"} className={"save btn text-white"}>
            {strings.save}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default ReligionModal;
