import React, { useEffect, useState } from "react";
import { strings } from "../../../localization/Localization";
import NomenclatureService from "../../../repository/nomenclatureRepository/NomenclatureRepository";
import "./NomenclatureList.css"
import NomenclatureEntryList from "../NomenclatureEntryList/NomenclatureEntryList";
import NomenclatureModal from "../NomenclatureModal/NomenclatureModal";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";

const NomenclatureList = () => {

    const [nomenclatureList, setNomenclatureList] = useState([]);

    const [nomenclatureForEdit, setNomenclatureForEdit] = useState({});

    const [selectedNomenclature, setSelectedNomenclature] = useState({});

    const [showANomenclatureModal, setShowNomenclatureModal] = useState(false);

    const location = useLocation();

    const localNomenclatureEntry = location.state ? location.state : null;

    useEffect(() => {
        getAllNomenclature();
    }, []);

    useEffect(() => {
        if (selectedNomenclature.id) {
            reselectSelectedNomenclature(selectedNomenclature.id);
        }
        if (localNomenclatureEntry !== null && localNomenclatureEntry.nomenclatureEntry === "Бенефиции") {
            const nomenclatureToOpen = nomenclatureList.find(
                (nomenclature) => nomenclature.name === "Бенефиции"
            );
            if (nomenclatureToOpen) {
                setNomenclatureForEdit(localNomenclatureEntry);
                setSelectedNomenclature(nomenclatureToOpen);
            }
        } else if (localNomenclatureEntry !== null && localNomenclatureEntry.nomenclatureEntry === "Опрема") {
            const nomenclatureToOpen = nomenclatureList.find(
                (nomenclature) => nomenclature.name === "Опрема"
            );
            if (nomenclatureToOpen) {
                setNomenclatureForEdit(localNomenclatureEntry);
                setSelectedNomenclature(nomenclatureToOpen);
            }
        }
    }, [nomenclatureList]);

    const getAllNomenclature = () => {
        NomenclatureService.getAllNomenclature()
            .then((data) => {
                setNomenclatureList(data.data)
            })
    };

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const errorAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };


    const ConfirmationDelete = (id) => {
        swal(strings.deleteConfirmation, {
            title: strings.areYouSure,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteNomenclature(id);
            }
        });
    };

    const deleteNomenclature = (id) => {
        NomenclatureService.deleteNomenclature(id)
            .then(() => {
                getAllNomenclature();
                successfulAlert();
                setSelectedNomenclature({});
            }).catch(() => {
                errorAlert();
            })

    }

    const handleSelectedNomenclature = (nomenclature) => {
        setSelectedNomenclature(nomenclature);
    };

    const handleShowNomenclatureModal = () => {
        setShowNomenclatureModal(true);
    };

    const handleCloseNomenclatureModal = () => {
        setNomenclatureForEdit({});
        setShowNomenclatureModal(false);
    };

    const reselectSelectedNomenclature = (id) => {
        let filteredNomenclature = nomenclatureList.filter((n) => n.id === id);
        if (filteredNomenclature.length > 0) {
            setSelectedNomenclature(filteredNomenclature[0]);
        }
    };

    return (
        <div>
            <div className={"row"}>
                <div className="col-sm-4 px-0 ">
                    <NomenclatureModal noemnclatureForEdit={nomenclatureForEdit} showModal={showANomenclatureModal}
                        handleClose={handleCloseNomenclatureModal}
                        getAllNoemnclature={getAllNomenclature}
                        nomenclatureEntry={localNomenclatureEntry} />
                    <div className="mx-2 mt-2 nomenclature-selection h-100 overflow-auto">
                        <div className={" mt-3 justify-content-end me-3 d-flex mb-1"}>
                            <div
                                className={"btn text-blue  btn-light text-end d-flex align-items-center border-radius px-xs-0 "}
                                onClick={handleShowNomenclatureModal}>
                                <div className={"align-items-center d-flex text-start"}>
                                    <i className={"material-icons add_button_icon"}>add_box</i>
                                </div>
                                <div className={"mx-1"}>
                                    {strings.add}
                                </div>
                            </div>
                        </div>
                        <ul className={"ps-0 pt-1"}>


                            {nomenclatureList.map((nomenclature) => {

                                return (
                                    <li className={`text-center align-items-center d-flex justify-content-between btn btn-light mb-2 mt-1 w-90 mx-auto ${nomenclature.id === selectedNomenclature.id ? "active" : ""}`}
                                        key={nomenclature.id}
                                        onClick={() => handleSelectedNomenclature(nomenclature)}>
                                        <div className={"w-100 text-start"}>
                                            {nomenclature.name}
                                        </div>


                                        <div className={"d-flex ms-auto w-100 justify-content-end align-items-center "}>
                                            <div className={'icon-div mx-2'}>
                                                <div
                                                    onClick={() => {
                                                        setNomenclatureForEdit(nomenclature);
                                                        handleShowNomenclatureModal();
                                                    }}
                                                    title={'Edit'}
                                                    className={'text-dark d-flex align-items-center icon'}>
                                                    <i className={"nomenclature-edit-icons material-icons "}>
                                                        create
                                                    </i>
                                                </div>
                                            </div>
                                            <div className={'icon-div'}>
                                                <a title={'Delete'}>
                                                    <div
                                                        onClick={() => ConfirmationDelete(nomenclature.id)}
                                                        title={'Delete'}
                                                        className={'text-dark icon d-flex align-items-center'}>
                                                        <i className={"nomenclature-delete-icons material-icons"}>delete</i>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="mx-2 mt-2 nomenclature-selection overflow-auto">
                        {selectedNomenclature.id ? (
                            <div className={"container"}>
                                <div className={"ms-2 mt-2"}>
                                    <h2 className="text-break">{selectedNomenclature.name}</h2>
                                </div>
                                <div>
                                    <NomenclatureEntryList selectedNomenclature={selectedNomenclature} />
                                </div>
                            </div>
                        ) : (
                            <div className={"d-flex mt-4 my-auto w-100 h-100 text-center"}>
                                <h5 className={"w-100 text-center "}><i
                                    className={"text-secondary"}>{strings.selectNomenclature}</i></h5>
                            </div>
                        )

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NomenclatureList;