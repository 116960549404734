import axios from "../../axios/axios";

const DocumentService = {
    uploadDocument: (file) => {
        return axios.post(`/documents/uploadDocument`, file)
        return axios({
            method: "post",
            url: '/rest/user/registerNewUser',
            data: file,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        })
    },

    uploadDocumentByAdmin: (file) => {
        return axios.post(`/documents/uploadDocumentByAdmin`, file)
        return axios({
            method: "post",
            url: '/rest/user/registerNewUser',
            data: file,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        })
    },

    getAllDataOfDocumentForLoggedUserByType: (documentType) => {
        return axios.get(`/documents/getAllByType/${documentType}`);
    },
    getAllDataOfDocumentsByUserIdByType: (userId, documentType) => {
        return axios.get(`/documents/getAllForUserByType/${userId}`, {params: {documentType}});
    },
    deleteDocumentByNameForLoggedUser: (name) => {
        return axios.delete("/documents/deleteDocument", {params: {name}});
    },
    deleteDocumentByNameAndUserId: (name, id) => {
        return axios.delete(`/documents/deleteDocument/${id}`, {params: {name}});
    },
    getAllPersonalDocumentByUserId: (userId) => {
        return axios.get(`/personal-document/getAllForUser/${userId}`);
    },
    getAllPersonalDocumentForLoggedUser: () => {
        return axios.get("/personal-document/getAllForLoggedUser");
    },
    deletePersonalDocument: (id) => {
        return axios.delete(`/personal-document/delete/${id}`);
    },
    addPersonalDocument: (userId, documentNumber, personalDocumentType, dateFrom, dateTo, issuedBy) => {
        return axios.post("/personal-document/add", {
            userId,
            documentNumber,
            personalDocumentType,
            dateFrom,
            dateTo,
            issuedBy
        })
    },
    addPersonalDocumentByAdmin: (userId, documentNumber, personalDocumentType, dateFrom, dateTo, issuedBy) => {
        return axios.post("/personal-document/addByAdmin", {
            userId,
            documentNumber,
            personalDocumentType,
            dateFrom,
            dateTo,
            issuedBy
        })
    },
    editPersonalDocument: (id, userId, documentNumber, personalDocumentType, dateFrom, dateTo, issuedBy) => {
        return axios.post("/personal-document/update", {
            id,
            userId,
            documentNumber,
            personalDocumentType,
            dateFrom,
            dateTo,
            issuedBy
        })
    },
    editPersonalDocumentByAdmin: (id, userId, documentNumber, personalDocumentType, dateFrom, dateTo, issuedBy) => {
        return axios.post("/personal-document/updateByAdmin", {
            id,
            userId,
            documentNumber,
            personalDocumentType,
            dateFrom,
            dateTo,
            issuedBy
        })
    }
}
export default DocumentService;