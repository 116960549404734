import React, { useState, useEffect } from "react";
import { strings } from "../../../localization/Localization";
import swal from "sweetalert";
import Select from "react-select";
import { Form } from "react-bootstrap";
import UserService from "../../../repository/userRepository/UserRepository";
import ApplicationService from "../../../ApplicationService";
import EquipmentRepository from "../../../repository/equipmentReposituory/EquipmentRepository";

const AddUserToEquipmentCard = (props) => {
  const [selectedEquipment, setSelectedEquipment] = useState(
    props.equipment
      ? {
          value: props.equipment.id,
          label:
            ApplicationService.getActiveLanguage() === "mk"
              ? props.equipment.labelMk
              : props.equipment.labelEn,
        }
      : null
  );

  const [dateAcquired, setDateAcquired] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState("");
  const [quantity, setQuantity] = useState("");
  const [model, setModel] = useState(null);
  const [serialNumber, setSerialNumber] = useState(null);
  const [description, setDescription] = useState(null);
  const [manufacturer, setManufacturer] = useState(null);

  useEffect(() => {
    getAllUsers();
  }, [selectedEquipment]);

  const getAllUsers = () => {
    if (props.selectedUserEquipmentDetails?.user) {
      setUsersList([
        {
          value: props.selectedUserEquipmentDetails.user.id,
          label:
            props.selectedUserEquipmentDetails.user.firstName +
            " " +
            props.selectedUserEquipmentDetails.user.lastName,
        },
      ]);
      handleSelectedUsersChange({
        value: props.selectedUserEquipmentDetails.user.id,
        label:
          props.selectedUserEquipmentDetails.user.firstName +
          " " +
          props.selectedUserEquipmentDetails.user.lastName,
      });
      setQuantity(props.selectedUserEquipmentDetails.quantity);
      setDateAcquired(props.selectedUserEquipmentDetails.dateAcquired);
    } else {
      UserService.findAllThatDoNotPossessTheEquipment(props.equipment?.id).then(
        (data) => {
          let userConverter = data.data.map((uc) => {
            return {
              value: uc.id,
              label: uc.person.firstName + " " + uc.person.lastName,
            };
          });
          setUsersList(userConverter);
        }
      );
    }
  };

  const handleSelectedUsersChange = (e) => {
    setSelectedUsers(e);
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const addUserToEquipment = (
    quantity,
    model,
    serialNumber,
    description,
    manufacturer,
    dateAcquired,
    userId,
    equipmentNomenclatureEntryId
  ) => {
    EquipmentRepository.addEquipmentPossession(
      quantity,
      model,
      serialNumber,
      description,
      manufacturer,
      dateAcquired,
      userId,
      equipmentNomenclatureEntryId
    )
      .then(() => {
        props.getAllUserEquipment();
        props.handleClose();
        successfulAlert();
      })
      .catch(() => {
        warningAlert();
      });
  };

  const editUserToEquipment = (
    id,
    quantity,
    model,
    serialNumber,
    description,
    manufacturer,
    selectedUserId,
    dateAcquired,
    equipmentNomenclatureEntryId
  ) => {
    EquipmentRepository.editEquipmentPossession(
      id,
      quantity,
      model,
      serialNumber,
      description,
      manufacturer,
      selectedUserId,
      dateAcquired,
      equipmentNomenclatureEntryId
    )
      .then(() => {
        props.getAllUserEquipment();
        props.handleClose();
        successfulAlert();
      })

      .catch(() => {
        warningAlert();
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (props.selectedUserEquipmentDetails?.userId) {
      editUserToEquipment(
        props.selectedUserEquipmentDetails?.id,
        quantity,
        model,
        serialNumber,
        description,
        manufacturer,
        selectedUsers?.value,
        dateAcquired,
        props.equipment?.id
      );
    } else {
      addUserToEquipment(
        quantity,
        model,
        serialNumber,
        description,
        manufacturer,
        dateAcquired,
        selectedUsers?.value,
        props.equipment?.id
      );
    }
  };

  return (
    <div
      className={`border border-1 border-radius p-2 border-light rounded-4 bg-light w-100 mb-3`}
    >
      <div>
        <Form onSubmit={onFormSubmit}>
          <div>
            <div className="row mb-2">
              <div className="col-md-4">
                <Form.Label className={"d-flex"}>
                  {strings.chooseUser}
                </Form.Label>
                <Select
                  onChange={handleSelectedUsersChange}
                  value={selectedUsers}
                  options={usersList}
                  placeholder={strings.selectOption}
                  isClearable
                  noOptionsMessage={({ inputValue }) =>
                    !inputValue ? strings.noOptions : "No results found"
                  }
                  isDisabled={props.selectedUserEquipmentDetails?.user}
                />
              </div>
              <div className="col-md-4">
                <Form.Group className="mb-3">
                  <Form.Label>{strings.quantity}</Form.Label>
                  <Form.Control
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    placeholder={strings.setQuantity}
                    min={0}
                  />
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group className="mb-3">
                  <Form.Label>{strings.dateAcquired}<span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    type="date"
                    value={dateAcquired}
                    onChange={(e) => setDateAcquired(e.target.value)}
                    required
                    onInvalid={(e) => {
                      e.target.setCustomValidity(strings.fieldsRequired);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div
              className="cancel btn text-white me-2"
              onClick={props.handleClose}
            >
              {strings.cancel}
            </div>
            <button type={"submit"} className="save btn text-white">
              {strings.save}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddUserToEquipmentCard;
