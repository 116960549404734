import axios from "../../axios/axios";

const OrganisationHierarchyService = {
    getHierarchy: () => {
        return axios.get("/organisation/getAll")
    },
    getAllOrganisationHierarchies: () => {
        return axios.get("/organisationHierarchy/getAll");
    },
    getAllOrganisationsNotInHierarchy: () => {
        return axios.get("/organisation/getAllNotInHierarchy");
    },
    addOrganisationInHierarchy: (child_sh_organisation_id, parent_sh_organisation_id) => {
        return axios.post('/organisationHierarchy/addOrganisationInHierarchy', {
            child_sh_organisation_id,
            parent_sh_organisation_id
        })
    },
    editHierarchy: (id, hrmsStOrganisationsHId, activeFrom, activeTo, code, name, shortName, description, dateCreated, dateModified, parentOrganisationId) => {
        return axios.put('/organisation/update', {
            id,
            hrmsStOrganisationsHId,
            activeFrom,
            activeTo,
            code,
            name,
            shortName,
            description,
            dateCreated,
            dateModified,
            parentOrganisationId
        })
    },
    addHierarchy: (hrmsStOrganisationsHId, activeFrom, activeTo, code, name, shortName, description, dateCreated, dateModified,parentOrganisationId) => {
        return axios.post("/organisation/add", {
            hrmsStOrganisationsHId,
            activeFrom,
            activeTo,
            code,
            name,
            shortName,
            description,
            dateCreated,
            dateModified,
            parentOrganisationId
        })
    },
    deleteOrganisation: (id) => {
        return axios.delete(`/organisation/delete/${id}`)
    },
    deleteOrganisationFromHierarchy: (id) => {
        return axios.delete(`/organisationHierarchy/delete/${id}`)
    },
    getAllUsersByOrganisation: (id, data) => {
        return axios.post(`/user/getAllByOrganisation/${id}`, data)
    },
    getAllOtherUsersFromOtherOrganisations: (id, data) => {
        return axios.post(`/user/getAllUsersFromOtherOrganisations/${id}`, data)
    },
    addUserInOrganisation: (id, data) => {
        return axios.post(`/user/addUserToOrganisation/${id}`, data)
    },
    deleteUserFromOrganisation: (id, data) => {
        return axios.post(`/user/removeUserFromOrganisation/${id}`, data)
    },
    fetchAllPositionForOrganisation:(id)=>{
        return axios.get(`/position/getAllForOrganisation/${id}`)
    },
    fetchAllPositionForListOfOrganisations:(organisationsId)=>{
        return axios.post(`/position/getAllForListOfOrganisations`,organisationsId)
    }

}
export default OrganisationHierarchyService;