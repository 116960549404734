import {strings} from "../../localization/Localization";
import React, {useEffect, useState} from "react";
import AddButton from "../../components/addButton/AddButton";
import ApplicationService from "../../ApplicationService";
import EventsService from "../../repository/eventsRepository/EventsRepository";
import "./Events.css";
import EventCard from "./EventCard/EventCard";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {useHistory} from "react-router-dom";
import EventProposalService from "../../repository/eventsRepository/EventProposalRepository";
import EventProposal from "./EventProposal/EventProposal";
import AddEditEventProposalModal from "./EventProposal/AddEditEventProposal/AddEditEventProposalModal";

const Events = () => {

    const [eventsList, setEventsList] = useState([]);

    const [activeEventsList, setActiveEventsList] = useState([]);

    const [inactiveEventsList, setInactiveEventsList] = useState([]);

    const [eventProposals, setEventProposals] = useState([]);

    const [myEventProposals, setMyEventProposals] = useState([]);

    const [key, setKey] = useState('allEvents');

    const [permissionForUpsert, setPermissionForUpsert] = useState(false);

    const [showAddModal, setShowAddModal] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (key === "allEvents") {
            getAllEvents();
        } else if (key === "activeEvents") {
            getAllActiveEvents();
        } else if (key === "inactiveEvents") {
            getAllInactiveEvents();
        } else if (key === "eventProposals") {
            getAllEventProposals();
        } else if (key === "myEventProposals") {
            getMyEventProposals();
        }
        if (ApplicationService.hasRole(["ROLE_HR", "ROLE_ADMINISTRATOR"])) {
            setPermissionForUpsert(true);
        }
    }, [key]);

    const getAllEvents = () => {
        EventsService.getAllEvents()
            .then((data) => {
                setEventsList(data.data)
            })
    }

    const getAllActiveEvents = () => {
        EventsService.getAllActiveEvents()
            .then((data) => {
                setActiveEventsList(data.data)
            })
    }

    const getAllInactiveEvents = () => {
        EventsService.getAllInactiveEvents()
            .then((data) => {
                setInactiveEventsList(data.data)
            })
    }

    const getAllEventProposals = () => {
        EventProposalService.getAllEventProposals()
            .then((data) => {
                setEventProposals(data.data);
            })
    }

    const getMyEventProposals = () => {
        EventProposalService.getMyEventProposals()
            .then((data) => {
                setMyEventProposals(data.data);
            })
    }

    const handleTabSelect = (selectedKey) => {
        setKey(selectedKey);
    }

    const redirectToAddEvent = () => {
        history.push("/addEvent")
    };

    const handleAddEventProposal = () => {
        setShowAddModal(true);
    }

    const handleCloseAddProposalModal = () => {
        setShowAddModal(false);
    }

    return (
        <div className={"pb-4"}>
            <AddEditEventProposalModal showModal={showAddModal}
                                       handleClose={handleCloseAddProposalModal}
                                       getEventProposals={getAllEventProposals}
                                       getEventProposalsForUser={getMyEventProposals}
            />
            <div className="w-100 d-flex">
                <div>
                    <h1>{strings.eventsList}</h1>
                </div>
                <div className={"d-flex ms-auto"}>
                    <div className="align-items-center d-flex ms-2">
                        {permissionForUpsert && ApplicationService.hasRole(["ROLE_ADMINISTRATOR", "ROLE_HR"]) && (
                            <div
                                className={"btn text-white text-end d-flex align-items-center border-radius px-xs-0  add-button"}
                                onClick={redirectToAddEvent}
                            >
                                <div className={"align-items-center d-flex text-start"}>
                                    <i className={"material-icons add_button_icon"}>add_box</i>
                                </div>
                                <div className={"mx-1"}>
                                    {strings.addEvent}
                                </div>
                            </div>
                        )}
                    </div>
                    {!ApplicationService.hasRole(["ROLE_ADMINISTRATOR"]) && (
                        <div className="align-items-center d-flex ms-2">
                            <div
                                className={"btn text-white text-end d-flex align-items-center border-radius px-xs-0  add-button"}
                                onClick={handleAddEventProposal}
                            >
                                <div className={"align-items-center d-flex text-start"}>
                                    <i className={"material-icons add_button_icon"}>add_box</i>
                                </div>
                                <div className={"mx-1"}>
                                    {strings.addEventProposal}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className={"user_details_box w-100 mb-4 border border-radius border-light border-5 px-4 pt-4"}>
                <Tabs
                    id="controlled-tab-example"
                    className={"w-100 user_details_tabs events-tabs"}
                    activeKey={key}
                    onSelect={handleTabSelect}>

                    <Tab eventKey="allEvents" title={strings.allEvents} className={"h-100"}>
                        <div className={"w-100 h-100 "}>
                            <div className="row equal-height-row">
                                {eventsList.length > 0 ? (eventsList.map((event, index) => (
                                    <EventCard event={event}
                                               key={event.eventId}
                                               activeTab={key}
                                               getAllEvents={getAllEvents}/>
                                ))) : (
                                    <h3 className={"text-center my-5"}>
                                        <i className={"text-secondary no-notification-message"}>{strings.noEvents}</i>
                                    </h3>
                                )}
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey="activeEvents" title={strings.activeEvents} className={"h-100"}>
                        <div className={"w-100 h-100 "}>
                            <div className="row equal-height-row">
                                {activeEventsList.length > 0 ? (activeEventsList.map((event, index) => (
                                    <EventCard event={event}
                                               key={event.eventId}
                                               activeTab={key}
                                               getAllEvents={getAllEvents}/>
                                ))) : (
                                    <h3 className={"text-center my-5"}>
                                        <i className={"text-secondary no-notification-message"}>{strings.noActiveEvents}</i>
                                    </h3>
                                )}
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey="inactiveEvents" title={strings.inactiveEvents} className={"h-100 w-100"}>
                        <div className={"w-100 h-100"}>
                            <div className="row equal-height-row">
                                {inactiveEventsList.length > 0 ? (inactiveEventsList.map((event, index) => (
                                    <EventCard event={event}
                                               key={event.eventId}
                                               activeTab={key}
                                               getAllEvents={getAllEvents}/>
                                ))) : (
                                    <h3 className={"text-center my-5"}>
                                        <i className={"text-secondary no-notification-message"}>{strings.noInactiveEvents}</i>
                                    </h3>
                                )}
                            </div>
                        </div>
                    </Tab>
                    {ApplicationService.hasRole(["ROLE_HR", "ROLE_ADMINISTRATOR"]) &&
                        <Tab eventKey="eventProposals" title={strings.eventProposals} className={"h-100 w-100"}>
                            <div className={"w-100 h-100"}>
                                <div className={"row table-responsive mt-1"}>
                                    <table id="keywords" className={"mb-1"}>
                                        <thead>
                                        <tr className="userlist">
                                            <th scope={"col"}><b>{strings.name}</b></th>
                                            <th scope={"col w-25"}>{strings.description}</th>
                                            <th scope={"col"}>{strings.userEventProposal}</th>
                                            <th scope={"col "} className={"pe-4"}>{strings.status}</th>
                                            <th scope={"col"} className={"pe-1"}>

                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {eventProposals.length > 0 && eventProposals.map((eventProposal, index) => (
                                            <EventProposal eventProposal={eventProposal}
                                                           key={eventProposal.id}
                                                           activeTab={key}
                                                           getAllEvents={getAllEvents}
                                                           getAllEventProposals={getAllEventProposals}
                                                           getAllEventProposalsForUser={getMyEventProposals}
                                            />
                                        ))}
                                        </tbody>
                                    </table>
                                    {
                                        eventProposals.length === 0 &&
                                        <h3 className={"text-center my-5"}>
                                            <i className={"text-secondary no-notification-message"}>{strings.noEventProposals}</i>
                                        </h3>
                                    }
                                </div>
                            </div>
                        </Tab>
                    }
                    {!ApplicationService.hasRole(["ROLE_HR", "ROLE_ADMINISTRATOR"]) && (
                        <Tab eventKey="myEventProposals" title={strings.myEventProposals} className={"h-100 w-100"}>
                        <div className={"w-100 h-100"}>
                            <div className={"row table-responsive mt-1"}>
                                <table id="keywords" className={"mb-1"}>
                                    <thead>
                                    <tr className="userlist">
                                        <th scope={"col"}><b>{strings.name}</b></th>
                                        <th scope={"col w-25"}>{strings.description}</th>
                                        <th scope={"col"}>{strings.userEventProposal}</th>
                                        <th scope={"col "} className={"pe-4"}>{strings.status}</th>
                                        <th scope={"col"} className={"pe-1"}>

                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {myEventProposals.length > 0 && myEventProposals.map((eventProposal, index) => (
                                        <EventProposal eventProposal={eventProposal}
                                                       key={eventProposal.id}
                                                       activeTab={key}
                                                       getAllEvents={getAllEvents}
                                                       getAllEventProposals={getAllEventProposals}
                                                       getAllEventProposalsForUser={getMyEventProposals}
                                        />
                                    ))}
                                    </tbody>
                                </table>
                                {
                                    myEventProposals.length === 0 &&
                                    <h3 className={"text-center my-5"}>
                                        <i className={"text-secondary no-notification-message"}>{strings.noMyEventProposals}</i>
                                    </h3>
                                }
                            </div>
                        </div>
                    </Tab>
                    )}

                </Tabs>
            </div>
        </div>
    );


}

export default Events;