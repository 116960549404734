import axios from "../../axios/axios";

const LeaveTypeRepository = {
    getAllLeaveType: () => {
        return axios.get(`/leavetype/getAll`);
    },
    addLeaveType: (data)=>{
        return axios.post('/leavetype/add',data)
    },
    updateLeaveType:(data)=>{
        return axios.post('/leavetype/update',data)
    },
    changeActiveLeaveType:(id)=>{
        return axios.post(`/leavetype/changeActive/${id}`)
    },
    deleteLeaveType:(id)=>{
        return axios.delete(`/leavetype/delete/${id}`)
    }

}
export default LeaveTypeRepository;