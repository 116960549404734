import React, {useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import {Form, FormControl, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import {strings} from "../../../localization/Localization";
import Select from "react-select";
import formatDate from "../../../components/formatDate/FormatDate";
import PositionHierarchyService from "../../../repository/hierarchyRepository/positionHierarchyRepository";
import OrganisationHierarchyService from "../../../repository/hierarchyRepository/organisationHierarchyRepository";
import "./AddPositionInOrganisationModal.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const AddPositionInOrganisationModal = (props) => {


    const [formData, updateFormData] = React.useState({
        hrmsStPositionsHId: "",
        activeFrom: "",
        activeTo: "",
        code: "",
        name: "",
        shortName: "",
        description: "",
        dateCreated: "",
        dateModified: "",
        parentId: ""
    });

    useEffect(() => {
        if (!props.showModal) {
            resetData();
        }
    }, [props.showModal])

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (e.target.hasAttribute("required") && value.trim() === "") {
          e.target.setCustomValidity(strings.fieldsRequired);
        } else {
          e.target.setCustomValidity("");
        }
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    };

    const resetData = () => {
        updateFormData({
            hrmsStPositionsHId: "",
            activeFrom: "",
            activeTo: "",
            code: "",
            name: "",
            shortName: "",
            description: "",
            dateCreated: "",
            dateModified: "",
            parentId: ""
        })
    };


    const addHierarchy = (hrmsStPositionsHId, activeFrom, activeTo, code, name, shortName, description, dateCreated, dateModified, parentId, organisationId) => {
        PositionHierarchyService.addHierarchy(hrmsStPositionsHId, activeFrom, activeTo, code, name, shortName, description, dateCreated, dateModified, parentId, organisationId)
            .then((data) => {
                props.getAllPositionsForOrganisation(organisationId);
                props.handleClose();
                resetData();
            });
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        const hrmsStPositionsHId = formData.hrmsStPositionsHId !== "" ? formData.hrmsStPositionsHId : null;
        const activeFrom = formData.activeFrom !== "" ? formData.activeFrom : null;
        const activeTo = formData.activeTo !== "" ? formData.activeTo : null;
        const code = formData.code !== "" ? formData.code : null;
        const name = formData.name !== "" ? formData.name : formData.name;
        const description = formData.description !== "" ? formData.description : null;
        const shortName = formData.shortName !== "" ? formData.shortName : null;
        const organisationId = props.organisation?.id;
        const dateCreated = "";
        const dateModified = "";
        addHierarchy(hrmsStPositionsHId, activeFrom, activeTo, code, name, shortName, description, dateCreated, dateModified, props.selectedNode?.id, organisationId)
    };


    return (
        <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={"lg"} centered>
            <ModalHeader closeButton>
                <ModalTitle>
                    <b>{strings.addPosition}</b>
                </ModalTitle>
            </ModalHeader>
            <Form onSubmit={onFormSubmit}>
                <ModalBody>
                    <Form.Group className="mb-3" controlId="positionName">
                        <Row>
                            <Col xs={12} md={12} className={"mb-2"}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{strings.positionName}<span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleChange}
                                        value={formData.name}
                                        autoFocus
                                        name="name"
                                        required
                                        onInvalid={(e) => {
                                            e.target.setCustomValidity(strings.fieldsRequired);
                                          }}/>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} className={"mb-2"}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{strings.positionShortName}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleChange}
                                        value={formData.shortName}
                                        name="shortName"/>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} className={"mb-2"}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{strings.positionDescription}</Form.Label>
                                    <textarea name="description" className="form-control"
                                              onChange={handleChange}
                                              value={formData.description}
                                              id="exampleFormControlTextarea1" rows="2"/>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} className={"mb-2"}>
                                <Form.Group>
                                    <Form.Label>{strings.positionCode}</Form.Label>
                                    <FormControl type="text" name="code"
                                                 className="form-control"
                                                 onChange={handleChange}
                                                 value={formData.code}
                                                 id="exampleFormControlTextarea1"/>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{strings.activeFrom}</Form.Label>
                                    <FormControl type="date" name="activeFrom" className="form-control"
                                                 onChange={handleChange}
                                                 value={formData.activeFrom}
                                                 id="exampleFormControlTextarea1" rows="2"/>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group>
                                    <Form.Label>{strings.activeTo}</Form.Label>
                                    <FormControl type="date" name="activeTo"
                                                 className="form-control"
                                                 onChange={handleChange}
                                                 value={formData.activeTo}
                                                 id="exampleFormControlTextarea1"/>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form.Group>
                </ModalBody>
                <ModalFooter>
                    <div className={"cancel btn text-white"} onClick={props.handleClose}>
                        {strings.cancel}
                    </div>
                    <button type={"submit"} className={"save btn text-white"}>
                        {strings.save}
                    </button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default AddPositionInOrganisationModal;