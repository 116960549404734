import React, { useEffect, useState } from 'react';
import { strings } from '../../localization/Localization';
import AddButton from '../../components/addButton/AddButton';
import ProjectGroupService from '../../repository/projectGroupRepository/projectGroupRepository';
import './ProjectGroup.css';
import AddGroup from './AddGroup/AddGroup';
import swal from 'sweetalert';

const ProjectGroup = () => {

  const [groupList, setGroupList] = useState([]);
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [selectedGroupForEdit, setSelectedGroupForEdit] = useState({});

  useEffect(() => {
    getAllGroups();
  }, []);

  const getAllGroups = () => {
    ProjectGroupService.getAllGroups()
      .then((response) => {
        setGroupList(response.data);
      })
      .catch((error) => {
        console.error('Error fetching projects:', error);
      });
  };

  const handleShowAddGroupModal = () => {
    setShowAddGroupModal(true);
  };

  const handleCloseAddGroupModal = () => {
    setShowAddGroupModal(false);
    setSelectedGroupForEdit({});
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: 'success',
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: 'error',
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const ConfirmationDelete = (id) => {
    swal(strings.deleteConfirmation, {
      title: strings.areYouSure,
      icon: 'warning',
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteGroup(id);
      }
    });
  };

  const deleteGroup = (id) => {
    ProjectGroupService.deleteGroup(id)
      .then(() => {
        successfulAlert();
        getAllGroups();
        handleCloseAddGroupModal();
      }).catch(() => {
      warningAlert();
    });
  };

  return (
    <div>
      <div className={'d-flex mb-2'}>
        <h3 className=''>{strings.groupList}</h3>
        <div className={'ms-auto'}>
          <AddButton onClickHandle={handleShowAddGroupModal}/>
        </div>
      </div>
      <div className={'table-responsive scrollWrapper'}>
        {groupList.length > 0 ? (
            <table className='table user-table table-borderless text-start w-100'>
              <thead className={'custom-table-header text-white'}>
              <tr className={'user-table-header-row'}>
                <th scope='col'>{strings.name}</th>
                <th scope='col' className={'w-50'}>{strings.description}</th>
                <th scope='col'>{strings.groupOfProjects}</th>
                <th scope={'col'}>{strings.numberOfProjectsInGroup}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              {groupList.map((group) => {
                return (
                  <tr className={'user-table-body-row'} key={group.id}>
                    <td>{group.name}</td>
                    <td className={'w-50'}>{group.description}</td>
                    <td>
                      {group.projects.map((projectForGroup, index) => (
                        <React.Fragment key={projectForGroup.name}>
                          {projectForGroup.name}
                          {index !== group.projects.length - 1 && ', '}
                        </React.Fragment>
                      ))}
                    </td>
                    <td>{group.projects.length}</td>
                    <td>
                      <div className={'d-flex justify-content-end align-items-center'}>
                        <div className={'icon-div mx-2'}>
                          <div
                            onClick={() => {
                              setSelectedGroupForEdit(group);
                              handleShowAddGroupModal();
                            }}
                            title={'Edit'}
                            className={'text-dark icon'}>
                            <i className={'edit-icon-circle material-icons '}>create</i>
                          </div>
                        </div>
                        {group.projects.length === 0 ? (
                          <div className={'icon-div cursor-pointer me-3'}>
                            <a title={'Delete'}>
                              <div
                                onClick={() => ConfirmationDelete(group?.id)}
                                title={'Delete'}
                                className={'text-dark '}>
                                <i className={'material-icons delete-icon-circle'} style={{ fontSize: '23px' }}>delete</i>
                              </div>
                            </a>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
              }
              </tbody>
            </table>

          ) :
          (
            <div className={'justify-content-center text-center '}>
              <h5 className={'text-secondary'}><i>{strings.emptyList}</i></h5>
            </div>
          )
        }
      </div>
      <AddGroup
        showModal={showAddGroupModal}
        handleClose={handleCloseAddGroupModal}
        selectedGroupForEdit={selectedGroupForEdit}
        getAllGroups={getAllGroups}/>
    </div>
  );
};
export default ProjectGroup;
