import { strings } from "../../../../localization/Localization";
import React, { useState } from "react";
import "../BenefitProposal/BenefitsProposal.css";
import swal from "sweetalert";
import BenefitProposalService from "../../../../repository/benefitsRepository/BenefitsProposalRepository";
import AddEditBenefitProposalModal from "./AddEditBenefitProposal/AddEditBenefitProposalModal";

const BenefitProposal = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedBenefitProposalForEdit, setBenefitProposalForEdit] = useState(
    props.benefitProposal
  );
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [selectedProposalForAccept, setSelectedProposalForAccept] = useState(
    {}
  );

  const maxWordsToShow = 10;

  const getBenefitProposalForRefresh = () => {
    BenefitProposalService.getBenefitProposalById(
      props.benefitProposal?.benefitProposalId
    ).then((data) => {});
  };

  const checkStatus = (status) => {
    switch (status) {
      case "ACCEPTED":
        return strings.accepted;
      case "DENIED":
        return strings.notAccepted;
      case "PENDING":
        return strings.pending;
      default:
        return "";
    }
  };

  const getStatusColorClass = (status) => {
    switch (status) {
      case "ACCEPTED":
        return "accepted-color";
      case "DENIED":
        return "not-accepted-color";
      case "PENDING":
        return "pending-color";
      default:
        return "";
    }
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const errorAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const deleteBenefitProposal = (id) => {
    BenefitProposalService.deleteBenefitProposal(id).then((response) => {
      if (response.data === "Benefit Proposal deleted successfully") {
        successfulAlert();
        props.getAllBenefitProposals();
        props.getAllBenefitProposalsForUser();
      } else {
        errorAlert();
      }
    });
  };

  const ConfirmationDeleteBenefitProposal = (id) => {
    swal(strings.deleteConfirmation, {
      title: strings.areYouSure,
      icon: "warning",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteBenefitProposal(id);
      }
    });
  };

  const handleShowEditBenefitProposalModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddProposalModal = () => {
    setBenefitProposalForEdit({});
    setShowAddModal(false);
  };

  const statusColorClass = getStatusColorClass(
    props.benefitProposal?.benefitProposalStatus
  );
  const descriptionWords = props.benefitProposal?.description?.split(" ");
  const shouldShowMoreLink = descriptionWords?.length > maxWordsToShow;

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const handleProposalClick = () => {
    setSelectedProposalForAccept(props.benefitProposal);
    setShowAcceptModal(true);
  };

  const handleCloseAcceptProposalModal = () => {
    setSelectedProposalForAccept({});
    setShowAcceptModal(false);
  };
  return (
    <>
      <AddEditBenefitProposalModal
        showModal={showAddModal}
        handleClose={handleCloseAddProposalModal}
        selectedBenefitProposal={selectedBenefitProposalForEdit}
        getBenefitProposals={props.getAllBenefitProposals}
        getBenefitProposalsForUser={props.getAllBenefitProposalsForUser}
      />
      <tr
        className="userlist py-auto cursor-pointer"
        key={props.benefitProposal?.id}
      >
        <td>
          <b>{props.benefitProposal?.name}</b>
        </td>
        <td className="text_blue_dark w-25">
          <div
            className={`description-container ${isExpanded ? "expanded" : ""}`}
          >
            {descriptionWords?.slice(0, maxWordsToShow).join(" ")}
            {shouldShowMoreLink && !isExpanded && " ... "}
            {isExpanded || !shouldShowMoreLink
              ? descriptionWords?.slice(maxWordsToShow).join(" ")
              : null}
          </div>
          {shouldShowMoreLink && (
            <span
              className="show-more-link text_blue_dark"
              onClick={toggleDescription}
            >
              {isExpanded ? (
                <i className={"material-icons"}>expand_less</i>
              ) : (
                <i className={"material-icons"}>expand_more</i>
              )}
            </span>
          )}
        </td>
        <td className={"px-1 text_blue_dark"}>
          <b>
            {props.benefitProposal.user.firstName}{" "}
            {props.benefitProposal.user.lastName}
          </b>
        </td>
        <td className={`px-1 text_blue_dark`}>
          <b className={`pb-2 px-2 rounded-2 ${statusColorClass}`}>
            {checkStatus(props.benefitProposal?.benefitProposalStatus)}
          </b>
        </td>
        <td className={"h-100"}>
          <div className={"d-inline-flex pe-2 ms-auto"}>
            {props.benefitProposal?.benefitProposalStatus === "PENDING" && (
              <>
                <div className={"icon-div"}>
                  <a
                    onClick={() => {
                      setBenefitProposalForEdit(props.benefitProposal);
                      handleShowEditBenefitProposalModal();
                    }}
                    title={"Edit"}
                    className={"text-dark icon"}
                  >
                    <i className={"edit-icon-circle material-icons "}>create</i>
                  </a>
                </div>
                <div className={"icon-div"}>
                  <a
                    title={"Delete"}
                    type={"Button"}
                    onClick={() =>
                      ConfirmationDeleteBenefitProposal(
                        props.benefitProposal.benefitProposalId
                      )
                    }>
                    <i className={"material-icons delete-icon-circle"}>
                      delete
                    </i>
                  </a>
                </div>
              </>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default BenefitProposal;
