import React, {useEffect, useState} from "react";
import "./ProjectTechnologies.css"
import ProjectTechnologyService from "../../../repository/projectTechnologyRepository/projectTechnologyRepository";

const ProjectTechnologies = (props) => {

    const [projectTechnologies, setProjectTechnologies] = useState([]);

    useEffect(() => {
        getAllProjectTechnologies();
    }, [])

    const getAllProjectTechnologies = () => {
        ProjectTechnologyService.getAllTechnologiesForProject(props.project.id)
            .then((response) => {
                setProjectTechnologies(response.data);
            }).catch((error) => {
            console.error("Error fetching project technologies:", error);
        })
    }

    return (
        <div>
            {projectTechnologies.length > 0 && projectTechnologies.map((technology) => (
                <span className="mx-1" key={technology.id}>
                {technology.technology.value === "backend" ? (
                    <span className="badge rounded-pill backend-badge">{technology.technology.name}</span>
                ) : technology.technology.value === "frontend" ? (
                    <span className="badge rounded-pill frontend-badge">{technology.technology.name}</span>
                ) : (
                    <span className="badge rounded-pill other-badge">{technology.technology.name}</span>
                )}
            </span>
            ))}
        </div>
    );
}

export default ProjectTechnologies;
