import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {strings} from "../../../../localization/Localization";
import WorkExperienceService from "../../../../repository/WorkExperienceRepository/WorkExperienceRepository";
import swal from "sweetalert";
import AddButton from "../../../../components/addButton/AddButton";
import './WorkExperienceForm.css';
import ApplicationService from "../../../../ApplicationService";
import AddWorkExperienceModal from "./WorkExperienceModal/WorkExperienceModal";
import applicationService from "../../../../ApplicationService";
import {Form} from "react-bootstrap";
import TotalExperienceUntilSelectedDate from "./TotalExperienceUntilSelectedDate/TotalExperienceUntilSelectedDate";
import GreyCard from "../../../../components/GreyCard/GreyCard";
import FormatDate from "../../../../components/formatDate/FormatDate";


const WorkExperienceForm = (props) => {

    const [allWorkExperienceForUser, setAllWorkExperienceForUser] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [selectedWorkExperienceForEdit, setSelectedWorkExperienceForEdit] = useState({});
    const param = useParams();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [totalYearsAndMonthsDaysSelected, setTotalYearsAndMonthsDaysSelected] = useState({
        years: 0,
        months: 0,
        days: 0
    });
    const [totalYearsAndMonthsDaysSelectedFiltered, setTotalYearsAndMonthsDaysSelectedFiltered] = useState({
        years: 0,
        months: 0,
        days: 0
    });


    useEffect(() => {
        if (props.currentTab === "professionalData") {
            getWorkExperienceForUser();
        }
    }, [props.currentTab]);

    const ConfirmationDelete = (id) => {
        swal(strings.deleteConfirmation, {
            title: strings.areYouSure,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteWorkExperience(id);
            }
        });
    };

    const deleteWorkExperience = (id) => {
        WorkExperienceService.deleteWorkExperienceData(id)
            .then(() => {
                successfulAlert();
                getWorkExperienceForUser();
            })
            .catch(() => {
                errorAlert();
            })
    };

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const errorAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const handleCloseUserAddWorkExperienceModal = () => {
        setSelectedWorkExperienceForEdit({});
        setShowAddModal(false);
    }

    const handleShowUserWorkExperienceModal = () => {
        setShowAddModal(true);
    }

    const getWorkExperienceForUser = () => {
        if (param.id) {
            WorkExperienceService.getAllWorkExperienceDataForUser(param.id).then((data) => {
                setAllWorkExperienceForUser(data.data);
            })
        } else {
            WorkExperienceService.getAllWorkExperienceDataForLoggedUser().then((data) => {
                setAllWorkExperienceForUser(data.data);
            })
        }
    }

    const formatWorkExperienceDateRange = (startDate, endDate) => {
        const formattedStartDate = FormatDate.formatDate(startDate);
        const formattedEndDate = endDate !== null ? FormatDate.formatDate(endDate) : strings.present;

        return `${formattedStartDate} - ${formattedEndDate}`;
    };

    const calculateYears = (startDate, endDate) => {
        return applicationService.getYearsMonthsDaysDifference(startDate, endDate !== null ? endDate : new Date()).years;
    };

    const calculateMonths = (startDate, endDate) => {
        return applicationService.getYearsMonthsDaysDifference(startDate, endDate !== null ? endDate : new Date()).months;
    };

    const calculateDays = (startDate, endDate) => {
        return applicationService.getYearsMonthsDaysDifference(startDate, endDate !== null ? endDate : new Date()).days;
    };


    const calculateTotalYearsMonthsDays = (workExperiences) => {
        const total = {years: 0, months: 0, days: 0};

        workExperiences.forEach((workExperience) => {
            const {
                years,
                months,
                days
            } = applicationService.getYearsMonthsDaysDifference(workExperience.startDate, workExperience.endDate);
            total.years += years;
            total.months += months;
            total.days += days;
        });

        if (total.days > 30) {
            total.months += Math.floor(total.days / 30);
            total.days = total.days % 30;
        }

        if (total.months > 12) {
            total.years += Math.floor(total.months / 12);
            total.months = total.months % 12;
        }

        return total;
    };

    const totalYearsMonthsDays = calculateTotalYearsMonthsDays(
        allWorkExperienceForUser
    );

    const filteredWorkExperiences = allWorkExperienceForUser.filter(
        (workExperience) => workExperience.companyName === "Интелигента"
    );

    const totalYearsMonthsDaysFiltered = calculateTotalYearsMonthsDays(
        filteredWorkExperiences
    );

    const handleCalculatorClick = () => {
        setShowDatePicker(!showDatePicker);
    };

    const handleCalculateClick = () => {
        if (selectedDate) {
            const totalExperience = calculateTotalYearsAndMonthsUntilSelectedDate(allWorkExperienceForUser, selectedDate);
            setTotalYearsAndMonthsDaysSelected(totalExperience);

            const totalExperienceFiltered = calculateTotalYearsAndMonthsUntilSelectedDate(filteredWorkExperiences, selectedDate);
            setTotalYearsAndMonthsDaysSelectedFiltered(totalExperienceFiltered);
        }
    }

    const calculateTotalYearsAndMonthsUntilSelectedDate = (workExperiences, selectedEndDate) => {
        const total = {years: 0, months: 0, days: 0};

        workExperiences.forEach((workExperience) => {
            const startDate = new Date(workExperience.startDate);
            const endDate = workExperience.endDate
                ? new Date(Math.min(new Date(workExperience.endDate), new Date(selectedEndDate)))
                : new Date(selectedEndDate);

            // eslint-disable-next-line no-unused-expressions
            endDate < startDate
                ? (total.years = 0, total.months = 0, total.days = 0)
                : (() => {
                    const {years, months, days} = applicationService.getYearsMonthsDaysDifference(startDate, endDate);
                    total.years += years;
                    total.months += months;
                    total.days += days;
                })();
        });

        if (total.days > 30) {
            total.months += Math.floor(total.days / 30);
            total.days = total.days % 30;
        }

        if (total.months > 12) {
            total.years += Math.floor(total.months / 12);
            total.months = total.months % 12;
        }

        return total;
    };


    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };


    return (
        <div>
            <div className={"p-3 mt-4 me-2 mb-5"} style={{border: "1px solid lightgray", borderRadius: "10px"}}>

                <div className={"d-flex align-items-center border-radius  mb-4"}>
                    <h4 className={"me-3 text-secondary"}
                        style={{whiteSpace: "nowrap"}}>{strings.workExperience}</h4>
                    <hr className={"me-3"}/>
                    {ApplicationService.hasRole(["ROLE_ADMINISTRATOR", "ROLE_HR"]) &&
                        <>
                            <AddWorkExperienceModal showModal={showAddModal}
                                                    handleClose={handleCloseUserAddWorkExperienceModal}
                                                    getWorkExperienceData={getWorkExperienceForUser}
                                                    selectedWorkExperienceForEdit={selectedWorkExperienceForEdit}
                            />
                            <div className={"me-2"}>
                                <AddButton
                                    onClickHandle={handleShowUserWorkExperienceModal}
                                />
                            </div>
                        </>
                    }
                </div>


                <div className={"d-flex mb-2 me-2 flex-wrap"}>
                    <div className="badge text-bg-secondary day-hours-badge mb-2 me-2 fs-6 total-experience">
                        {strings.total}: {strings.years}: {totalYearsMonthsDays.years} &nbsp;
                        {strings.months}: {totalYearsMonthsDays.months}&nbsp;
                        {strings.days}: {totalYearsMonthsDays.days}
                    </div>
                    <div className="badge text-bg-secondary day-hours-badge mb-2 me-2 fs-6 total-experience">
                        {strings.total} {strings.inOurCompany}:&nbsp;
                        {strings.years}: {totalYearsMonthsDaysFiltered.years}&nbsp;
                        {strings.months}: {totalYearsMonthsDaysFiltered.months}&nbsp;
                        {strings.days}: {totalYearsMonthsDaysFiltered.days}
                    </div>
                    <div className={"my-auto calculator-icon d-flex justify-content-center"}
                         onClick={handleCalculatorClick}>
                        <i className={"material-icons fs-2 mb-2 me-2 total-experience-calculator"}>calculate</i>
                    </div>
                </div>

                {showDatePicker &&
                    <div
                        className={`border border-1 border-radius p-2 border-light rounded-4 bg-light w-100 mb-3 date-picker-container show`}>
                        <div
                            className={"d-flex inline-block align-items-center total-experience-text mb-3 mt-2 fs-6"}>
                            <p className="mb-0 mt-1 select-end-date">{strings.selectDate}: &nbsp;</p>
                            <Form.Control
                                className={"date-picker-selected-date"}
                                type="date"
                                value={selectedDate}
                                onChange={handleDateChange}
                                on
                            />
                            <button type={"submit"}
                                    className={"search-experience btn fs-9 d-flex py-1 align-items-center"}
                                    onClick={handleCalculateClick}>
                                {strings.calculateExperiece}
                                <i className={"material-icons fs-4 pr-5 show-calculations"}>arrow_drop_down</i>
                            </button>
                        </div>

                        <div className={"d-flex"}>
                            <TotalExperienceUntilSelectedDate
                                label={strings.overallTotalExperienceUntil}
                                years={totalYearsAndMonthsDaysSelected.years}
                                months={totalYearsAndMonthsDaysSelected.months}
                                days={totalYearsAndMonthsDaysSelected.days}
                                selectedDate={FormatDate.formatDate(selectedDate)}
                            />

                            <TotalExperienceUntilSelectedDate
                                label={strings.inteligentaTotalExperienceUntil}
                                years={totalYearsAndMonthsDaysSelectedFiltered.years}
                                months={totalYearsAndMonthsDaysSelectedFiltered.months}
                                days={totalYearsAndMonthsDaysSelectedFiltered.days}
                                selectedDate={FormatDate.formatDate(selectedDate)}
                            />
                        </div>
                    </div>
                }
                {allWorkExperienceForUser.map((workExperience) => {
                        return (
                            <GreyCard key={workExperience.workExperienceId}>
                                <div className={"d-flex align-items-center mb-2"}>
                                    <i className={"me-1 material-icons"}>corporate_fare</i> {workExperience.companyName}
                                    {ApplicationService.hasRole(["ROLE_ADMINISTRATOR", "ROLE_HR"]) &&
                                        <div className={"ms-auto d-flex me-3"}>
                                            <div className={'icon-div mx-3'}>
                                                <div
                                                    onClick={() => {
                                                        setSelectedWorkExperienceForEdit(workExperience);
                                                        handleShowUserWorkExperienceModal();
                                                    }}
                                                    title={'Edit'}
                                                    className={'text-dark icon'}>
                                                    <i className={"edit-icon-circle material-icons "}>
                                                        create
                                                    </i>
                                                </div>
                                            </div>
                                            <div className={'icon-div'}>
                                                <a title={'Delete'}>
                                                    <div
                                                        onClick={() => ConfirmationDelete(workExperience.workExperienceId)}
                                                        title={'Delete'}
                                                        className={'text-dark icon'}>
                                                        <i className={"material-icons delete-icon-circle"}>
                                                            delete
                                                        </i>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className={"d-flex"}>
                                    <div className={"d-flex flex-column"}>
                                        <i className="text-secondary mb-2">
                                            {strings.duration}:
                                        </i>
                                        <i className="text-secondary mb-2">
                                            {strings.durationIn}:
                                        </i>
                                        <i className="text-secondary mb-2">
                                            {strings.dateRegistered}: &nbsp;
                                        </i>
                                        <i className="text-secondary mb-2">
                                            {strings.workExperiencePosition}:&nbsp;
                                        </i>
                                        <i className={"text-secondary"}>
                                            {strings.fromOurCompany}:&nbsp;
                                        </i>
                                    </div>
                                    <div className={"d-flex flex-column"}>
                                        <b className="text_blue_dark mb-2">
                                            {formatWorkExperienceDateRange(workExperience.startDate, workExperience.endDate)}
                                        </b>
                                        <div className="d-flex justify-content-between">
                                            <b className="text_blue_dark duration-in-years-months-days mb-2 me-2">
                                                {strings.years} : {calculateYears(workExperience.startDate, workExperience.endDate)}
                                            </b>

                                            <b className="text_blue_dark duration-in-years-months-days mb-2 me-2">
                                                {strings.months} : {calculateMonths(workExperience.startDate, workExperience.endDate)}
                                            </b>
                                            <b className="text_blue_dark duration-in-years-months-days mb-2 me-2">
                                                {strings.days} : {calculateDays(workExperience.startDate, workExperience.endDate)}
                                            </b>
                                        </div>
                                        <b className="text_blue_dark mb-2">
                                            {FormatDate.formatDate(workExperience.dateRegistered)}
                                        </b>
                                        <b className="text_blue_dark mb-2">
                                            {workExperience.workPosition}
                                        </b>
                                        <b className="text_blue_dark mb-1">
                                            {workExperience.durationIn}
                                        </b>

                                        <b>
                                            {workExperience.fromOurCompany ? (
                                                <i className={"material-icons text-success from-our-company"}>check_circle_outline</i>
                                            ) : (
                                                <i className={"material-icons text-danger from-our-company"}>highlight_off</i>
                                            )}
                                        </b>
                                    </div>
                                </div>
                            </GreyCard>
                        )
                    })
                }
            </div>
        </div>
    )

}
export default WorkExperienceForm;