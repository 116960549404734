import {createSlice} from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        firstName: "",
        lastName: "",
        parentName: "",
        gender: "",
        email: ""
    },
    reducers: {
        update: (state, action) => {
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.parentName = action.payload.parentName;
            state.gender = action.payload.gender;
            state.email= action.payload.email
        },
        remove: (state) => (
            state = {}
        )
    }
})
export const {update, remove} = userSlice.actions;
export default userSlice.reducer;