import React, {useEffect, useState} from "react"
import DocumentService from "../../../../repository/documentRepository/DocumentRepository";
import AddDocumentModal from "../AddDocumentModal/AddDocumentModal";
import AddButton from "../../../../components/addButton/AddButton";
import {strings} from "../../../../localization/Localization";
import {HiDownload, HiOutlineDocumentText} from "react-icons/hi";
import {useParams} from "react-router-dom";
import swal from "sweetalert";
import FormatDate from "../../../../components/formatDate/FormatDate";

const CertificateDocuments = (props) => {

    const [allCertificates, setAllCertificates] = useState([]);

    const [showAddModal, setShowAddModal] = useState(false);

    const param = useParams();

    const FileSaver = require("file-saver");

    useEffect(() => {
        if (props.currentTab === "certificate") {
            getAllCertificates();
        }
    }, [props.currentTab]);

    const handleCloseAddDocumentModal = () => {
        setShowAddModal(false);
    };

    const handleShowAddDocumentModal = () => {
        setShowAddModal(true);
    };

    const ConfirmationDelete = (name) => {
        swal(strings.deleteConfirmation, {
            title: strings.areYouSure,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteCertificateDocument(name);
            }
        });
    };

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const warningAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const getAllCertificates = () => {
        if (param.id) {
            DocumentService.getAllDataOfDocumentsByUserIdByType(param.id, "BIOGRAPHY")
                .then((data) => {
                    setAllCertificates(data.data)
                })
        } else {
            DocumentService.getAllDataOfDocumentForLoggedUserByType("BIOGRAPHY")
                .then((data) => {
                    setAllCertificates(data.data);
                })
        }
    };

    const downloadCertificateDocuments = (fileName, id) => {
        fetch(process.env.REACT_APP_HOST_ENV + `/documents/downloadDocument/${id}`,
            {
                method: "post",
                origin: 'same-origin',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('auth_token')

                },

            }
        ).then(res => res.blob())
            .then(file => FileSaver.saveAs(file, fileName))
    };

    const deleteCertificateDocument = (name) => {
        if (param.id) {
            DocumentService.deleteDocumentByNameAndUserId(name, param.id)
                .then(() => {
                    successfulAlert();
                    getAllCertificates();
                }).catch(() => {
                warningAlert();
            })
        } else {
            DocumentService.deleteDocumentByNameForLoggedUser(name)
                .then(() => {
                    successfulAlert();
                    getAllCertificates();
                }).catch(() => {
                warningAlert();
            })
        }
    };

    return (
        <div className={"p-4"}>
            <div className="table-responsive">
                <AddDocumentModal showModal={showAddModal} handleClose={handleCloseAddDocumentModal}
                                  getDocumentData={getAllCertificates} initialDocumentType={"BIOGRAPHY"}/>
                <div className={"d-flex mb-2 justify-content-end"}>
                    <AddButton onClickHandle={handleShowAddDocumentModal}/>
                </div>
                {allCertificates.length > 0 ? (
                        <table className="table user-table table-responsive table-borderless">
                            <thead className={"custom-table-header "}>
                            <tr className={"userlist text-center"}>
                                <th scope="col"></th>
                                <th scope="col">{strings.name}</th>
                                <th scope="col">{strings.description}</th>
                                <th scope="col">{strings.documentLanguage}</th>
                                <th scope="col">{strings.documentDate}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {allCertificates.map((data) => {
                                return (
                                    <tr className={"userlist pt-4 text-center align-middle"} key={data.id}>
                                        <td><HiOutlineDocumentText
                                            className={"user_personal_data__document_icon me-3 text-blue "}/>
                                        </td>
                                        <td>{data?.name}</td>
                                        <td className={"w-25"}>{data?.description}</td>
                                        <td>{data?.language}</td>
                                        <td>{FormatDate.formatDate(data?.date.split("T")[0])}</td>
                                        <td>
                                            <div className={"d-flex justify-content-end align-items-center"}>
                                                <HiDownload
                                                    className={"text-primary cursor-pointer user_personal_data__document_download__icon"}
                                                    onClick={() => downloadCertificateDocuments(data.name, data.id)}/>
                                                <div className={'icon-div cursor-pointer me-2'}>
                                                    <a title={'Delete'}>
                                                        <div
                                                            onClick={() => ConfirmationDelete(data?.name)}
                                                            title={'Delete'}
                                                            className={'text-dark '}>
                                                            <i className={"material-icons delete-icon-circle"}
                                                               style={{fontSize: "23px"}}>delete</i>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                            </tbody>
                        </table>
                    ) :
                    (
                        <div className={"justify-content-center text-center "}>
                            <h5 className={"text-secondary"}><i>{strings.emptyList}</i></h5>
                        </div>
                    )
                }
            </div>


        </div>
    )
}
export default CertificateDocuments;