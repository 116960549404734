import "./UserEventProposals.css"
import React, {useState} from "react";
import {strings} from "../../../../localization/Localization";

const UserEventProposals = (props) => {

    const [isExpanded, setIsExpanded] = useState(false);

    const maxWordsToShow = 10;

    const getStatusColorClass = (status) => {
        switch (status) {
            case "ACCEPTED":
                return "accepted-color";
            case "NOT ACCEPTED":
                return "not-accepted-color";
            case "PENDING":
                return "pending-color";
            default:
                return "";
        }
    };

    const checkStatus = (status) => {
        switch (status) {
            case "ACCEPTED":
                return strings.accepted;
            case "NOT ACCEPTED":
                return strings.notAccepted;
            case "PENDING":
                return strings.pending;
            default:
                return "";
        }
    }

    const statusColorClass = getStatusColorClass(props.eventProposal?.status);
    const descriptionWords = props.eventProposal?.description.split(' ');
    const shouldShowMoreLink = descriptionWords.length > maxWordsToShow;

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };



    return (
        <tr className="userlist py-auto cursor-pointer" key={props.eventProposal?.id}>
            <td>
                <b>{props.eventProposal?.name}</b>
            </td>
            <td className="text_blue_dark w-25">
                <div className={`description-container ${isExpanded ? "expanded" : ""}`}>
                    {descriptionWords.slice(0, maxWordsToShow).join(" ")}
                    {shouldShowMoreLink && !isExpanded && " ... "}
                    {isExpanded || !shouldShowMoreLink ? (
                        descriptionWords.slice(maxWordsToShow).join(" ")
                    ) : null}
                </div>
                {shouldShowMoreLink && (
                    <span className="show-more-link text_blue_dark" onClick={toggleDescription}>
              {isExpanded ? (
                  <i className={"material-icons"}>expand_less</i>
              ) : (
                  <i className={"material-icons"}>expand_more</i>
              )}
            </span>
                )}
            </td>
            <td className={"px-1 text_blue_dark"}>
                <b>
                    {props.eventProposal.user.firstName} {props.eventProposal.user.lastName}
                </b>
            </td>
            <td className={`px-1 text_blue_dark`}>
                <b className={`pb-2 px-2 rounded-2 ${statusColorClass}`}>
                    {checkStatus(props.eventProposal?.status)}
                </b>
            </td>
        </tr>
    )

}

export default UserEventProposals;