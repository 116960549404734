import axios from "../../axios/axios"

const NomenclatureService = {
    getAllNomenclature: () => {
        return axios.get("/nomenclature/getAll")
    },
    getAllNomenclatureEntry: (id) => {
        return axios.get(`/nomenclature-entry/getAll/${id}`)
    },
    getNomenclatureByName: (name) => {
        return axios.get(`/nomenclature/findByName?name=` + name);
    },
    getAllNomenclatureEntryByNomenclatureName: (name) => {
        return axios.get(`/nomenclature-entry/getAllByNomenclatureName?name=` + name)
    },
    addNomenclature: (name) => {
        return axios.post("/nomenclature/add", {name})
    },
    editNomenclature: (name, id) => {
        return axios.post("/nomenclature/update", {name, id})
    },
    deleteNomenclature: (id) => {
        return axios.delete(`/nomenclature/delete/${id}`)
    },
    addNomenclatureEntry: (name, labelMk, labelEn, labelSq, description, value, nomenclatureId, nomenclatureEntryMetadataListForAddOrEdit) => {
        return axios.post("/nomenclature-entry/add", {
            name,
            labelMk,
            labelEn,
            labelSq,
            description,
            value,
            nomenclatureId,
            nomenclatureEntryMetadataListForAddOrEdit
        })
    },
    editNomenclatureEntry: (name, labelMk, labelEn, labelSq, description, value, id, nomenclatureEntryMetadataListForAddOrEdit) => {
        return axios.post("/nomenclature-entry/update", {
            name,
            labelMk,
            labelEn,
            labelSq,
            description,
            value,
            id,
            nomenclatureEntryMetadataListForAddOrEdit
        })
    },
    deleteNomenclatureEntry: (id) => {
        return axios.delete(`/nomenclature-entry/delete/${id}`)
    },
    getDataTypeByMetadataId: (id) => {
        return axios.get(`/dataType/getDataTypeByMetadataId/${id}`);
    }
}
export default NomenclatureService;