import React, {useEffect, useState} from "react";
import Select from "react-select";


const NotMultipleSelectDropDown = (props) => {

  const [options,setOptions]=useState([])

    useEffect(()=>{
        setOptions(props.listForDropDown)
    },[props.listForDropDown])

    const getValue=(selectedValues)=>{
        props.handleSelection(selectedValues)
    }

    return(
        <div>
            <Select
                onChange={getValue}
                value={props.selectedDefaultValue}
                name="role"
                options={options}
                className={`basic-multi-select ${props.className}`}
                classNamePrefix="select"
                isDisabled={props.disable}
            />
        </div>
    )


}
export  default NotMultipleSelectDropDown;