import React, { useEffect, useState } from 'react';
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TimeTrackingRepository from "../../../repository/timeTrackingRepository/TimeTrackingRepository";
import { strings } from "../../../localization/Localization";
import Select from "react-select";
import ProjectService from "../../../repository/projectRepository/projectRepository";
import ProjectGroupService from "../../../repository/projectGroupRepository/projectGroupRepository";
import ApplicationService from "../../../ApplicationService";
import UserRepository from "../../../repository/userRepository/UserRepository";
import GroupService from '../../../repository/groupRepository/GroupService';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DraggableModalDialog from '../../../components/DraggableModalDialog/DraggableModalDialog';

const TimeRecordModal = (props) => {

  const { showModal, handleClose, selectedTimeRecordForEdit, isEdit } = props;

  const [projectsNames, setProjectsNames] = React.useState([]);

  const [projectsNotConverted, setProjectsNotConverted] = useState([]);

  const [userGroups, setUserGroups] = useState([]);

  const [allGroups, setAllGroups] = React.useState([]);

  const [isValid, setIsValid] = useState(false);

  const [permissionForUpsert, setPermissionForUpsert] = React.useState(
    ApplicationService.hasRole(["ROLE_HR", "ADMINISTRATOR"])
  );

  useEffect(() => {
    ProjectService.getAllProjectsForLoggedUser().then((data) => {
    });
  }, []);

  useEffect(() => {
    if (ApplicationService.hasRole(["ROLE_HR", "ROLE_ADMINISTRATOR"])) {
      setPermissionForUpsert(true);
    }
  }, []);

  const [formData, updateFormData] = React.useState({
    id: "",
    userId: "",
    firstName: "",
    lastName: "",
    groupId: "",
    projectId: "",
    date: "",
    hours: "",
    minutes: "",
    isOvertime: false,
    description: "",
  });

  useEffect(() => {
    if (showModal) {
      getAllProjectsForLoggedUser();
      getAllProjectGroupsForLoggedUser();
      //   getAllGroups();
      if (selectedTimeRecordForEdit?.id) {
        updateFormData({
          id: selectedTimeRecordForEdit.id,
          userId: selectedTimeRecordForEdit.userId,
          firstName: selectedTimeRecordForEdit.user.firstName,
          lastName: selectedTimeRecordForEdit.user.lastName,
          projectId: {
            value: selectedTimeRecordForEdit.project.id,
            label: selectedTimeRecordForEdit.project.name,
          },
          groupId: {
            value: selectedTimeRecordForEdit.project.group?.id,
            label: selectedTimeRecordForEdit.project.group?.name,
          },
          date: selectedTimeRecordForEdit.date,
          hours: selectedTimeRecordForEdit.hours,
          minutes: selectedTimeRecordForEdit.minutes,
          isOvertime: selectedTimeRecordForEdit?.isOvertime,
          description: selectedTimeRecordForEdit.description,
        });
      }
    } else {
      resetFormData();
    }
  }, [showModal]);

  const getAllProjectsForLoggedUser = () => {
    ProjectService.getAllProjectsForUserLogged().then((data) => {
      setProjectsNotConverted(data.data);
      let namesAndIdsConverter = data.data.map((n) => {
        return {
          value: n.id,
          label: n.name,
        };
      });
      setProjectsNames(namesAndIdsConverter);
    });
  };

  const getAllProjectGroupsForLoggedUser = () => {
    GroupService.getProjectGroupsForLoggedUser().then((data) => {
      let converter = data.data.map((n) => {
        return {
          value: n.id,
          label: n.name,
        };
      });
      converter.unshift({
        value: 0,
        label: strings.all
      });
      setUserGroups(converter);
    })
  }

  const getAllProjectsForGroupForLoggedUser = (groupId) => {
    ProjectService.getAllProjectsForGroupForLoggedUser(groupId).then((data) => {
      let converter = data.data.map((n) => {
        return {
          value: n.id,
          label: n.name,
        };
      });
      setProjectsNames(converter);
    })
  }

  const handleChange = (e) => {
    const value = e.target.value;
    if (e.target.hasAttribute("required") && value.trim() === "") {
      e.target.setCustomValidity(strings.fieldsRequired);
    } else {
      e.target.setCustomValidity("");
    }
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleCheckboxChange = (e) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === "checkbox" ? checked : value;

    updateFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleProjectNameChange = (selectedOption) => {
    const selectedProjectId = selectedOption.value;
    const selectedProject = projectsNotConverted.find((project) => project.id === selectedProjectId);
    const groupId = selectedProject.group ? selectedProject.group.id : null;
    setIsValid(true);
    updateFormData({
      ...formData,
      projectId: selectedOption,
      groupId: groupId ? { value: groupId, label: selectedProject.group.name } : null,
    });
  };


  const handleGroupChange = (selectedOption) => {
    getAllProjectsForGroupForLoggedUser(selectedOption.value);
    setIsValid(false);
    updateFormData({
      ...formData,
      groupId: selectedOption,
      projectId: "",
    })
  }

  const resetFormData = () => {
    updateFormData({
      id: "",
      userId: "",
      firstName: "",
      lastName: "",
      projectId: "",
      date: "",
      hours: "",
      minutes: "",
      isOvertime: false,
      description: "",
    });
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {strings.selectProjectName}
    </Tooltip>
  );

  const addTimeRecord = (
    firstName,
    lastName,
    projectId,
    date,
    hours,
    minutes,
    isOvertime,
    description
  ) => {
    TimeTrackingRepository.addTimeRecord(
      firstName,
      lastName,
      projectId,
      date,
      hours,
      minutes,
      isOvertime,
      description
    )

      .then((data) => {
        successfulAlert();
        handleClose();
      })
      .catch(() => {
        warningAlert();
      });
  };

  const editTimeRecord = (
    id,
    userId,
    projectId,
    date,
    hours,
    minutes,
    isOvertime,
    description
  ) => {
    TimeTrackingRepository.editTimeRecord(
      id,
      userId,
      projectId,
      date,
      hours,
      minutes,
      isOvertime,
      description
    )
      .then(() => {
        successfulAlert();
        handleClose();
      })
      .catch(() => {
        warningAlert();
      });
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    const firstName =
      formData.firstName !== ""
        ? formData.firstName
        : selectedTimeRecordForEdit.firstName;
    const lastName =
      formData.lastName !== ""
        ? formData.lastName
        : selectedTimeRecordForEdit.lastName;
    // const typeNomenclatureEntryId = formData.typeNomenclatureEntryId.value !== "" ? formData.typeNomenclatureEntryId.value : selectedTimeRecordForEdit.typeNomenclatureEntryId.id;
    const projectId =
      formData.projectId.value !== ""
        ? formData.projectId.value
        : selectedTimeRecordForEdit.projectId.id;
    const date =
      formData.date !== "" ? formData.date : selectedTimeRecordForEdit.date;
    const hours =
      formData.hours !== ""
        ? parseInt(formData.hours)
        : selectedTimeRecordForEdit.hours;
    const minutes =
      formData.minutes !== ""
        ? parseInt(formData.minutes)
        : selectedTimeRecordForEdit.minutes;
    // const isOvertime = formData.isOvertime !== "" ? formData.isOvertime : null;
    const isOvertime = formData.isOvertime;
    const description =
      formData.description !== ""
        ? formData.description
        : selectedTimeRecordForEdit.description;

    if (!isEdit) {
      addTimeRecord(
        firstName,
        lastName,
        projectId,
        date,
        hours,
        minutes,
        isOvertime,
        description
      );
    } else {
      const id =
        formData.id !== "" ? formData.id : selectedTimeRecordForEdit.id;
      const userId = selectedTimeRecordForEdit.user.id;
      editTimeRecord(
        id,
        userId,
        projectId,
        date,
        hours,
        minutes,
        isOvertime,
        description
      );
    }
  };

  return (
    <Modal show={showModal} dialogAs={DraggableModalDialog} onHide={handleClose} size={"lg"} centered>
      <Modal.Header closeButton>
        {!isEdit && <Modal.Title>{strings.addTimeRecord}</Modal.Title>}
        {isEdit && <Modal.Title>{strings.editTimeRecord}</Modal.Title>}
      </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <Modal.Body>
          {props.permissionForUpsert && isEdit && (
            <Row>
              <>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3 required" controlId="firstName">
                    <Form.Label> {strings.firstName}</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      placeholder={strings.firstName}
                      autoFocus
                      value={formData.firstName}
                      name="firstName"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3 required" controlId="lastName">
                    <Form.Label> {strings.lastName}</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      placeholder={strings.lastName}
                      autoFocus
                      value={formData.lastName}
                      name="lastName"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </>
            </Row>
          )}
          <Row>
            <Col xs={12} md={5}>
              <Form.Group className="mb-3 required" controlId="projectName">
                <Form.Label> {strings.groupName}<span className="text-danger">*</span> </Form.Label>
                <Select
                  options={userGroups}
                  onChange={handleGroupChange}
                  name="groupName"
                  value={formData.groupId}
                  required
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                ></Select>
              </Form.Group>
            </Col>
            <Col xs={12} md={5}>
              <Form.Group className="mb-3 required" controlId="projectName">
                <Form.Label> {strings.projectName}<span className="text-danger">*</span> </Form.Label>
                <Select
                  options={projectsNames}
                  onChange={handleProjectNameChange}
                  name="projectName"
                  value={formData.projectId}
                  required
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                ></Select>
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={2}
              className={"d-flex justify-content-center align-items-center"}
            >
              <Form.Group className="mb-3">
                <Form.Label className={"d-flex me-1 "}>
                  {strings.isOvertime}
                </Form.Label>
                <label className="switchEnabled me-2 mb-0">
                  <input
                    type="checkbox"
                    name={"isOvertime"}
                    value={formData.isOvertime}
                    checked={formData.isOvertime}
                    disabled={false}
                    onChange={handleCheckboxChange}
                  />
                  <span className="sliderEnabled round"> </span>
                </label>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={4}>
              <Form.Label>
                <div
                  className={
                    "label-icon align-items-end d-flex rotate-key-icon "
                  }
                ></div>
                {strings.date}<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                required
                type={"date"}
                value={formData.date?.split("T").at(0)}
                onChange={handleChange}
                className={"user_professional_data_input"}
                name={"date"}
                onInvalid={(e) => {
                  e.target.setCustomValidity(strings.fieldsRequired);
                }}
              ></Form.Control>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3 required" controlId="hours">
                <Form.Label> {strings.Hours}<span className="text-danger">*</span> </Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder={strings.enterHours}
                  autoFocus
                  min="0"
                  max="24"
                  value={formData.hours}
                  name="hours"
                  onChange={handleChange}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group className="mb-3 required" controlId="minutes">
                <Form.Label> {strings.Minutes}<span className="text-danger">*</span> </Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder={strings.enterMinutes}
                  autoFocus
                  min="0"
                  max="59"
                  value={formData.minutes}
                  name="minutes"
                  onChange={handleChange}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3 required" controlId="description">
                <Form.Label> {strings.description} </Form.Label>
                <Form.Control
                  // required
                  as="textarea"
                  rows={4}
                  autoFocus
                  value={formData.description}
                  name="description"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <div className={"d-flex ms-auto"}>
            <div className={"cancel btn text-white me-2"} onClick={props.handleClose}>
              {strings.cancel}
            </div>
            {!isValid ? (
              <OverlayTrigger
                placement='right'
                delay={{show: 250, hide: 400
                }} overlay={renderTooltip}>
                <div className={'ms-auto'}>
                  <button disabled={!isValid} type={'submit'} className={'save btn text-white'}>
                    {strings.save}
                  </button>
                </div>
              </OverlayTrigger>
            ) : (
              <div className={'ms-auto'}>
                <button disabled={!isValid} type={'submit'} className={'save btn text-white'}>
                  {strings.save}
                </button>
              </div>
            )}

          </div>


        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TimeRecordModal;
