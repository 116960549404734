import React, {Fragment} from "react";

import "../User_modal/AddUserModal.css"
import swal from "sweetalert";
import {strings} from "../../../localization/Localization";
import {Link, useHistory} from "react-router-dom";
import  femaleAvatar  from "../../../assets/images/female_avatar.png"

const UserTerm = (props) => {

    const history = useHistory();

    const redirectToUserDetails=()=> {
            history.push("/userDetails/"+props.term.id)
    };



    const deleteConfirmAlert = () => {
        swal({
            title: strings.areYouSure,
            text: strings.deleteConfirmationNoQuestionMark + "\n" + props.term.email + "?" + "\n " + strings.thisUserCannotBeRestored,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
        }).then((willSuccess) => {
            if (willSuccess) {
                props.deleteUser(props.term.id)
                swal(strings.userIsDeleted, {
                    icon: "success",
                });
            } else {
            }
        });
    };

    return (
        <tr className="userlist py-auto cursor-pointer" key={props.term.id} onClick={redirectToUserDetails}>
                <td><b>{props.term?.person?.hrmsIdNumber}</b></td>
                <td className={"me-3"}>
                    <div className={"w-100 h-100"}>
                        <img className="user_list_image image_border_radius_50 me-3    "
                             src={props.term.profileImageHelper ? (`data:image/*;base64,${props.term.profileImageHelper.bytes}`) :
                                 (props.term.person.gender === "MALE" ? "https://bootdey.com/img/Content/avatar/avatar7.png" : femaleAvatar)} alt="..."/>
                    </div>
                </td>
                <td className={"text_blue_dark"}><b>{props.term.firstName}</b></td>
                <td className={"px-1 text_blue_dark"}><b>{props.term.person?.parentName}</b></td>
                <td className={"ps-1 text_blue_dark"}><b>{props.term.lastName}</b></td>
                <td className={"mx-2 px-3"}>
                    <div>{props.term.phoneNumber}</div>
                    <div className={"gray_color"}>{props.term.email}</div>
                </td>
                <td>
                    {props.term.roles.map((e) => {
                        return (

                            <Fragment key={e}>

                                {e + " "} <br/>
                            </Fragment>
                        )
                    })}
                </td>
                <td className={"px-2"}>{props.term?.organisation?.name}</td>
                <td className={"px-2"}>{props.term?.position?.name}</td>
                <td className={'h-100'}>
                    <div className={"d-inline-flex pe-2"}>
                        {/*<div className={'icon-div ms-3'}>*/}
                        {/*    <a*/}
                        {/*        onClick={() => props.onClickSetSelectedUserForEdit(props.term)}*/}
                        {/*        title={'Edit'}*/}
                        {/*        className={'text-dark icon'}>*/}
                        {/*        <i className={"edit-icon-circle material-icons "}>*/}
                        {/*            create*/}
                        {/*        </i>*/}
                        {/*    </a>*/}
                        {/*/!*</div>*!/*/}
                        {/*<div className={'icon-div mx-2'}>*/}
                        {/*    <a*/}
                        {/*        onClick={() => props.onClickSetSelectedUserForChangePassword(props.term)}*/}
                        {/*        title={'Edit'}*/}
                        {/*        className={'text-dark icon'}>*/}
                        {/*        <i className={"edit-icon-circle material-icons gray_color"}>*/}
                        {/*            lock*/}
                        {/*        </i>*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                        {/*<div className={'icon-div'}>*/}
                        {/*    <a title={'Delete'} type={'Button'}*/}
                        {/*       onClick={deleteConfirmAlert}>*/}
                        {/*        <i className={"material-icons delete-icon-circle"}>delete</i>*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </div>
                </td>
        </tr>
    )
}
export default UserTerm;