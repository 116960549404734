import React from "react";
import '../../../components/Table.css'
import  femaleAvatar  from "../../../assets/images/female_avatar.png"
import ApplicationService from "../../../ApplicationService";


const EmployeeTerm = (props) => {
    return (
        <tr className="trhover" onClick={ApplicationService.hasRole(['ROLE_ADMINISTRATOR', 'ROLE_HR']) ? () => props.onClickSetSelectedEmployeeForEdit(props.term) : null}>
                <td className={"ps-3 pe-1"}><b>{props.term.hrmsIdNumber}</b></td>
                <td className={"ps-3 me-3"}>
                    <div className={"w-100 h-100"}>
                        <img className="user_list_image image_border_radius_50 me-3    "
                             src={props.term.profileImageHelper ? (`data:image/*;base64,${props.term.profileImageHelper.bytes}`) :
                                 ( props.term?.person?.gender === "MALE" ? "https://bootdey.com/img/Content/avatar/avatar7.png":femaleAvatar)} alt="..."/>
                    </div>
                </td>
                <td className={"ps-3 pe-1"}>{props.term.firstName}</td>
                <td className={"px-3"}>{props.term.person?.parentName}</td>
                <td className={"mx-3"}>{props.term.lastName}</td>
                <td className={"mx-2 px-4"}>
                    <div>{props.term.phoneNumber}</div>
                    <div className={"gray_color"}>{props.term.email}</div>
                </td>
                <td className={"px-2"}>{props.term.roles.join(", ")}</td>
                <td className={"px-2"}>{props.term.organisation?.name}</td>
                <td className={"pe-2"}>{props.term.position?.name}</td>
                <td className="h-100">
                    <div className="d-inline-flex pe-2"></div>
                </td>
            </tr>
    )
}
export default EmployeeTerm;