import React from "react";
import swal from "sweetalert";
import { strings } from "../../../localization/Localization";
import FormatDate from "../../../components/formatDate/FormatDate";

const TimeRecordTerm = ({
  term,
  deleteTimeRecord,
  permissionForUpsert,
  onClickSetSelectedTimeRecordForEdit,
  isAllTerms,
}) => {
  const deleteConfirmAlert = () => {
    swal({
      title: strings.areYouSure,
      text:
        strings.deleteConfirmationTimeRecord + "?\n" + strings.deletedForever,
      icon: "warning",
      buttons: [strings.cancel, strings.ok],
    }).then((willSuccess) => {
      if (willSuccess) {
        deleteTimeRecord(term.id);
        swal(strings.timeRecordDeleted, {
          icon: "success",
        });
      } else {
      }
    });
  };

  return (
    <tr key={term?.id}>
      {isAllTerms && (
        <>
          <td>{term?.user?.firstName}</td>
          <td>{term?.user?.lastName}</td>
        </>
      )}
      <td>{term?.project?.group?.name}</td>
      <td>{term?.project?.name}</td>
      <td>{FormatDate.formatDate(term?.date)}</td>
      <td>{term?.hours + "h " + term?.minutes + "m"}</td>
      <td className={"h-100 pe-2"}>
        <div
          className={"d-inline-flex justify-content-end w-100 align-items-end"}
        >
          <div className={"icon-div mx-3"}>
            <div
              onClick={() => onClickSetSelectedTimeRecordForEdit(term)}
              title={"Edit"}
              className={"text-dark icon"}
            >
              <i className={"edit-icon-circle material-icons "}>create</i>
            </div>
          </div>
          <div className={"icon-div"}>
            <div title={"Delete"} type={"Button"} onClick={deleteConfirmAlert}>
              <i className={"material-icons delete-icon-circle"}>delete</i>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
export default TimeRecordTerm;
