import axios from "../../axios/axios";

const ProjectUserService ={
    getProjectUserByProjectId:(projectId) =>{
        return axios.get(`projectUser/ListUserDetailsOnProject/${projectId}`)
    },
    addProjectUsers: (projectId, userIds) => {
        return axios.post(`projectUser/addUsersToProject`, {
            projectId: projectId,
            userIds: userIds,
        });
    },
    removeProjectUsers: (projectId, userIds) => {
        return axios.delete(`projectUser/deleteUsersFromProject`, {
            data: {
                projectId: projectId,
                userIds: userIds,
            },
        });
    },
    getPaginatedUsersByProjectId: (projectId, page, size) => {
        return axios.get(`projectUser/findAllUsersOnProjectPaginated/${projectId}/${page}/${size}`);
    },
    addUserToProject: (formData) => {
        return axios.post(`projectUser/addUserToProject`, formData);
    }
}
export default  ProjectUserService;