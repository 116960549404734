import { useState } from "react";
import React, { useEffect } from "react";
import AddButton from "../../../components/addButton/AddButton";
import { strings } from "../../../localization/Localization";
import Select from "react-select";
import FeedbackTermPage from "../FeedbackTermPage/FeedbackTermPage";
import FeedbackRepository from "../../../repository/feedbackRepository/FeedbackRepository";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import FeedbackModal from "../FeedbackModal/FeedbackModal";

const FeedbackList = () => {
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [isEdit, setIsEdit] = useState(false);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [nextPageOffset, setNextPageOffset] = useState(0);
  const [feedbackList, setFeedbackList] = useState([]);

  useEffect(() => {
    getAllFeedback();
  }, [size]);

  const handleShow = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setIsEdit(false);
    getAllFeedback();
  };

  const handlePageClick = (data) => {
    let selected = data.selected;
    setPage(selected);
  };

  const selectSize = (e) => {
    setPage(0);
    setSize(e.value);
  };

  const getAllFeedback = () => {
    FeedbackRepository.getAllFeedback(page, size).then((res) => {
      setFeedbackList(res.data.content);
      setPageCount(res.data.totalPages);
    });
  };

  const getFeedbackTermpage = () => {
    return feedbackList.map((term) => {
      return (
        <FeedbackTermPage
          isAllTerms={true}
          key={term.id}
          term={term}
          editTerm={term}
          getAllFeedback={getAllFeedback}
        />
      );
    });
  };

  const feedbackTermPage = getFeedbackTermpage(offset, nextPageOffset);

  return (
    <>
      <div className={"w-100 d-flex justify-content-between"}>
        <div>
          <h1>{strings.feedback}</h1>
        </div>
        <div className={"d-flex"}>
          <div className={"align-items-center d-flex justify-content-end"}>
            <AddButton onClickHandle={handleShow} />
          </div>
        </div>
      </div>
      <div className={"container"}>
        <div>
          <div className={"row table-responsive"}>
            <table id="keywords" className={"mb-1"}>
              <thead>
                <tr>
                  <th scope={"col"}>{strings.feedbackType}</th>
                  <th scope={"col"}>{strings.title}</th>
                  <th scope={"col"}>{strings.dateCreated}</th>
                  <th scope={"col"}>{strings.description}</th>
                  <th scope={"col"}></th>
                </tr>
              </thead>
              <tbody>{feedbackList.length > 0 && feedbackTermPage}</tbody>
            </table>
            {feedbackList.length === 0 && (
              <div className={"justify-content-center text-center w-100"}>
                <div>
                  <h5 className={"text-secondary w-100 "}>
                    <i>{strings.emptyList}</i>
                  </h5>
                </div>
              </div>
            )}
          </div>
          {feedbackList.length !== 0 && (
            <div className={"d-flex justify-content-between my-3"}>
              <div className={"mx-auto h-100 align-items-center  d-flex"}>
                <ReactPaginate
                  previousLabel={<AiOutlineArrowLeft />}
                  nextLabel={<AiOutlineArrowRight />}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  forcePage={page}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={
                    "pagination h-100 m-0 justify-content-center"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
              <div className={"d-flex align-items-end"}>
                <Select
                  placeholder={strings.size}
                  onChange={selectSize}
                  options={[
                    { value: 5, label: "5" },
                    { value: 10, label: "10" },
                    { value: 15, label: "15" },
                    { value: 20, label: "20" },
                  ]}
                  defaultValue={{ value: 10, label: "10" }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 40,
                      border: "none",
                    }),
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <FeedbackModal
        showModal={showModal}
        handleClose={handleClose}
        isEdit={isEdit}
      />
    </>
  );
};
export default FeedbackList;
