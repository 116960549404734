import React, {useEffect, useState} from "react";
import {strings} from "../../../localization/Localization";
import ProjectTechnologiesForUser from "../../AllProjects/ProjectTechnologies/ProjectTechnologiesForUserPage";
import ProjectService from "../../../repository/projectRepository/projectRepository";
import MyProjectService from "../../../repository/projectsRepository/projectsRepository";
import ProjectsDetails from "../../AllProjects/ProjectDetailsModal/ProjectsDetails";

const UserProjects = (props) => {

    const [projectList, setProjectList] = useState([]);
    const [selectedProjectDetails, setSelectedProjectDetails] = useState({});
    const [selectedProjectForEdit, setSelectedProjectForEdit] = useState({});
    const [showProjectDetailsModal, setShowProjectDetailsModal] = useState(false);


    useEffect(() => {
        getProjectsForUser();
    }, []);

    const getProjectsForUser = () => {
        MyProjectService.getAllProjectsForUser()
            .then((data) => {
                setProjectList(data.data)
            }).catch((error) => {
            console.error("Error fetching projects:", error);
        });
    }

    const handleShowProjectDetailsModal = () => {
        setShowProjectDetailsModal(true);
    };

    const handleCloseProjectDetailsModal = () => {
        setShowProjectDetailsModal(false);
        setSelectedProjectDetails({});
        setSelectedProjectForEdit({});
    };

    return (
        <div>
          <div className={"table-responsive scrollWrapper mt-4"}>
            {projectList.length > 0 ? (
              <table className="table user-table table-borderless text-start w-100">
                <thead className={"custom-table-header text-white"}>
                  <tr className={"user-table-header-row"}>
                    <th scope="col">{strings.name}</th>
                    <th scope={"col"}>{strings.projectType}</th>
                    <th scope="col">{strings.projectTechnology}</th>
                    <th scope={"col"}>{strings.projectGroup}</th>
                    <th scope={"col"}>{strings.active}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {projectList.map((project) => (
                    <tr className={"user-table-body-row"} key={project?.id}>
                      <td>{project?.name}</td>
                      <td>{project.typeNomenclatureEntry?.name}</td>
                      <td>
                        <div className={"d-flex"}>
                          <ProjectTechnologiesForUser project={project} />
                        </div>
                      </td>
                      <td>{project?.group?.name}</td>
                      <td>
                        <div style={{ display: "flex", alignItems: "center", margin: "5px" }}>
                          {project.isActive ? (
                            <span className="badge bg-success">{strings.Active}</span>
                          ) : (
                            <span className="badge bg-danger">{strings.notActive}</span>
                          )}
                        </div>
                      </td>
                      <td className={'cursor-pointer'}>
                        <a
                          className={"btn text-white text-end align-items-center px-xs-0 py-0 add-button details-button"}
                          onClick={() => {
                            setSelectedProjectDetails(project);
                            setSelectedProjectForEdit(project);
                            handleShowProjectDetailsModal();
                          }}
                        >
                          {strings.details}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className={"justify-content-center text-center "}>
                <h5 className={"text-secondary"}><i>{strings.emptyList}</i></h5>
              </div>
            )}
            <ProjectsDetails
              projectDetails={selectedProjectDetails}
              showModal={showProjectDetailsModal}
              selectedProjectForEdit={selectedProjectForEdit}
              handleClose={handleCloseProjectDetailsModal}
              // getAllProjects={props.getAllProjects}
            />
          </div>
        </div>
      );
            };      
export default UserProjects;
