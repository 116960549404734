import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { strings } from "../../../../localization/Localization";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import swal from "sweetalert";
import EquipmentProposalRepository from "../../../../repository/equipmentReposituory/EquipmentProposalRepository";
import EquipmentRepository from "../../../../repository/equipmentReposituory/EquipmentRepository";
import Select from "react-select";
import ApplicationService from "../../../../ApplicationService";
import { useHistory } from "react-router-dom";
import DraggableModalDialog from "../../../../components/DraggableModalDialog/DraggableModalDialog";

const RequestEquipmentModal = (props) => {
  const [allEquipment, setAllEquipment] = useState([]);
  const history = useHistory();
  const [formData, updateFormData] = useState({
    equipmentNomenclatureEntry: "",
    quantity: "",
    description: "",
  });

  useEffect(() => {
    if (!props.showModal) {
      getAllEquipment();
      resetData();
    }

    if (props.selectedEquipmentProposalForEdit?.equipmentNomenclatureEntryId) {
      const { equipmentNomenclatureEntry, quantity, description } =
        props.selectedEquipmentProposalForEdit;

      updateFormData({
        equipmentNomenclatureEntry,
        quantity,
        description,
      });
    }
  }, [props.selectedEquipmentProposalForEdit, props.showModal]);

  const getAllEquipment = () => {
    EquipmentRepository.getAllEquipments()
      .then((data) => {
        const equipmentConverter = data.data.map((gp) => ({
          value: gp.id,
          label: gp.name,
        }));
        setAllEquipment(equipmentConverter);
      })
      .catch(() => {
        errorAlert();
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (e.target.hasAttribute("required") && value.trim() === "") {
      e.target.setCustomValidity(strings.fieldsRequired);
    } else {
      e.target.setCustomValidity("");
    }
    updateFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetData = () => {
    updateFormData({
      equipmentNomenclatureEntry: "",
      quantity: "",
      description: "",
    });
  };

  const handleEquipmentDropdownChange = (selectedOption) => {
    updateFormData({
      ...formData,
      equipmentNomenclatureEntry: selectedOption,
    });
  };

  const equipmentProposalAddSuccessfulAlert = () => {
    swal(strings.userEditSuccessful, {
      icon: "success",
    });
  };

  const DenyEquipmentProposal = (id) => {
    swal(strings.denyEquipmentConfirmation, {
      title: strings.areYouSure,
      icon: "warning",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        denyEquipmentProposal(id);
      }
    });
  };
  const AcceptEquipmentProposal = (id) => {
    swal(strings.acceptEquipmentConfirmation, {
      title: strings.areYouSure,
      icon: "warning",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptEquipmentProposal(id);
      }
    });
  };

  const acceptEquipmentProposal = (id) => {
    EquipmentProposalRepository.acceptEquipmentProposal(id)
      .then(() => {
        equipmentProposalAddSuccessfulAlert();
        props.handleClose();
        props.getEquipmentProposals();
        history.push("/allEquipment");
      })
      .catch(() => {
        errorAlert();
      });
  };

  const denyEquipmentProposal = (id) => {
    EquipmentProposalRepository.denyEquipmentProposal(id)
      .then(() => {
        equipmentProposalAddSuccessfulAlert();
        props.handleClose();
        props.getEquipmentProposals();
      })
      .catch(() => {
        errorAlert();
      });
  };

  const errorAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    });
  };

  const addEquipmentProposal = (
    equipmentNomenclatureEntry,
    quantity,
    description
  ) => {
    EquipmentProposalRepository.addEquipmentProposal(
      equipmentNomenclatureEntry,
      quantity,
      description
    )
      .then(() => {
        equipmentProposalAddSuccessfulAlert();
        props.handleClose();
        props.getEquipmentProposalsForUser();
      })
      .catch(() => {
        errorAlert();
      });
  };

  const editEquipmentProposal = (
    id,
    equipmentNomenclatureEntry,
    equipmentNomenclatureEntryId,
    quantity,
    description,
    status
  ) => {
    EquipmentProposalRepository.editEquipmentProposal(
      id,
      equipmentNomenclatureEntry,
      equipmentNomenclatureEntryId,
      quantity,
      description,
      status
    )
      .then(() => {
        equipmentProposalAddSuccessfulAlert();
        props.handleClose();
        props.getEquipmentProposals();
      })
      .catch(() => {
        errorAlert();
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const equipmentNomenclatureEntry =
      formData.equipmentNomenclatureEntry !== ""
        ? formData.equipmentNomenclatureEntry
        : null;
    const quantity = formData.quantity !== "" ? formData.quantity : null;
    const description =
      formData.description !== "" ? formData.description : null;

    if (props.selectedEquipmentProposalForEdit?.equipmentNomenclatureEntryId) {
      editEquipmentProposal(
        props.selectedEquipmentProposalForEdit?.id,
        props.selectedEquipmentProposalForEdit?.equipmentNomenclatureEntryId,
        equipmentNomenclatureEntry,
        quantity,
        description,
        props.selectedEquipmentProposalForEdit?.status
      );
    } else {
      addEquipmentProposal(
        equipmentNomenclatureEntry.value,
        quantity,
        description
      );
    }
  };

  return (
    <Modal
      show={props.showModal}
      dialogAs={DraggableModalDialog}
      onHide={props.handleClose}
      size={"lg"}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.selectedEquipmentProposalForEdit
            ? strings.equipmentProposalDetails
            : strings.addEquipmentProposal}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <Modal.Body>
          <Row className={"mb-2"}>
            <Col xs={12} md={9}>
              <Form.Group className={"mb-3"}>
                <Form.Label className={"d-flex"}>
                  {strings.equipmentProposalName}
                </Form.Label>
                {allEquipment.length > 0 ? (
                  <Select
                    name="equipmentNomenclatureEntry"
                    options={allEquipment}
                    className={`basic-multi-select user_details_select_input`}
                    classNamePrefix="select"
                    onChange={handleEquipmentDropdownChange}
                    placeholder={strings.selectOption}
                    noOptionsMessage={() => strings.noOptions}
                    value={
                      props.selectedEquipmentProposalForEdit
                        ? {
                            value: formData.equipmentNomenclatureEntry.id,
                            label: formData.equipmentNomenclatureEntry.name,
                          }
                        : {
                            value: formData.equipmentNomenclatureEntry.value,
                            label: formData.equipmentNomenclatureEntry.label
                          }
                    }
                    isDisabled={props.fromAllEquipment}
                  />
                ) : (
                  <Select noOptionsMessage={() => strings.noOptions} />
                )}
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group className={"mb-3"}>
                <Form.Label className={"d-flex"}>
                  {strings.equipmentQuantity}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type={"number"}
                  value={formData.quantity}
                  required
                  min={0}
                  onChange={handleChange}
                  name={"quantity"}
                  disabled={props.fromAllEquipment}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className={"mb-2"}>
            <Col>
              <Form.Group className={"mb-3"}>
                <Form.Label className={"d-flex"}>
                  {strings.equipmentDescription}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={4}
                  value={formData.description}
                  onChange={handleChange}
                  name={"description"}
                  disabled={props.fromAllEquipment}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {props.selectedEquipmentProposalForEdit ? (
            <>
              {ApplicationService.hasRole([
                "ROLE_ADMINISTRATOR",
                "ROLE_HR",
              ]) && (
                <div className={"d-flex justify-content-end"}>
                  <button
                    type="button"
                    className={"cancel btn text-white m-1"}
                    onClick={() =>
                      DenyEquipmentProposal(
                        props.selectedEquipmentProposalForEdit.id
                      )
                    }
                  >
                    {strings.deny}
                  </button>
                  <button
                    className={"save btn text-white m-1"}
                    onClick={(e) => {
                      e.preventDefault();
                      AcceptEquipmentProposal(
                        props.selectedEquipmentProposalForEdit.id
                      );
                    }}
                  >
                    {strings.accept}
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              <div
                className={"cancel btn text-white"}
                onClick={() => {
                  props.handleClose();
                  resetData();
                }}
              >
                {strings.cancel}
              </div>
              <button type={"submit"} className={"save btn text-white m-1"}>
                {strings.save}
              </button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default RequestEquipmentModal;
