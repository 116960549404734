import React, { useEffect, useState } from 'react';
import './UserProfessionalData.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { strings } from '../../../localization/Localization';
import CVDocuments from './CVDocuments/CVDocuments';
import CertificateDocuments from './CertificateDocuments/CertificateDocuments';
import ProfessionalDataForm from './ProfessionalDataInfo/ProfessionalDataForm';
import EmploymentData from './EmploymentData/EmploymentData';
import WorkExperienceForm from './WorkExperienceData/WorkExperienceForm';

const UserProfessionalData = (props) => {

  const [key, setKey] = useState('CV');

  return (
    <div className='UserProfessionalData pb-4'>
      <ProfessionalDataForm selectedUserProfessionalDataToEdit={props.selectedUser}
                            setShowSpinner={props.setShowSpinner}
                            currentTab={props.currentTab}/>
      <EmploymentData currentTab={props.currentTab}/>
      <WorkExperienceForm currentTab={props.currentTab}/>
      <div className={'pt-2'}>
        <Tabs
          id='controlled-tab-example'
          activeKey={key}
          className={'w-100 user_details_tabs'}
          onSelect={(k) => setKey(k)}>
          <Tab eventKey='CV' title={strings.cv} className={'h-100'}>
            <div className={'w-100 h-100'}>
              <CVDocuments currentTab={key}/>
            </div>
          </Tab>
          <Tab eventKey='certificate' title={strings.certificates} className={'h-100 w-100'}>
            <div className={'w-100 h-100'}>
              <CertificateDocuments currentTab={key}/>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>);
};
export default UserProfessionalData;