import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { strings } from "../../../localization/Localization";
import FeedbackRepository from "../../../repository/feedbackRepository/FeedbackRepository";
import Select from "react-select";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const FeedbackModal = (props) => {
  const { showModal, handleClose, isEdit } = props;
  const [formData, updateFormData] = useState({
    title: "",
    type: "",
    dateCreated: "",
    description: "",
  });

  useEffect(() => {
    resetFormData();

    if (showModal && props.feedbackDetails) {
      updateFormData({
        title: props.feedbackDetails?.title || "",
        type:
          {
            value: props.feedbackDetails.type,
            label: getFeedbackTypeLabel(props.feedbackDetails?.type),
          } || "",
        dateCreated: props.feedbackDetails?.dateCreated || "",
        description: props.feedbackDetails?.description || "",
      });
    }
  }, [showModal, props.feedbackDetails]);

  const getFeedbackTypeLabel = (type) => {
    switch (type) {
      case "PRAISE":
        return strings.feedbackPraise;
      case "COMPLAIN":
        return strings.feedbackComplain;
      case "PROPOSAL":
        return strings.feedbackProposal;
      case "OTHER":
        return strings.feedbackOther;
      default:
        return "";
    }
  };

  const resetFormData = () => {
    updateFormData({
      title: "",
      type: "",
      dateCreated: "",
      description: "",
    });
  };

  const handleChange = (e) => {

    const value = e.target.value;
    if (e.target.hasAttribute("required") && value.trim() === "") {
      e.target.setCustomValidity(strings.fieldsRequired);
    } else {
      e.target.setCustomValidity("");
    }
    
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    
  };

  const handleFeedbackType = (selectedOption) => {
    updateFormData({
      ...formData,
      type: selectedOption,
    });
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const addFeedback = (title, type, dateCreated, description) => {
    FeedbackRepository.addFeedback(title, type, dateCreated, description)
      .then((data) => {
        successfulAlert();
        handleClose();
      })
      .catch(() => {
        warningAlert();
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const title = formData?.title;
    const type = formData?.type.value;
    const dateCreated = formData?.dateCreated;
    const description = formData?.description;
    if (!isEdit) {
      addFeedback(title, type, dateCreated, description);
    }
  };

  return (
    <Modal show={showModal} dialogAs={DraggableModalDialog} onHide={handleClose} size={"lg"} centered>
      <Modal.Header closeButton>
        {!isEdit && <Modal.Title>{strings.addFeedback}</Modal.Title>}
      </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3 required" controlId="feedbackType" isRequired>
                <Form.Label>{strings.feedbackType}<span className="text-danger">*</span></Form.Label>
                <Select
                  name="feedbackType"
                  options={[
                    {
                      value: "PRAISE",
                      label: strings.feedbackPraise,
                    },
                    {
                      value: "COMPLAIN",
                      label: strings.feedbackComplain,
                    },
                    {
                      value: "PROPOSAL",
                      label: strings.feedbackProposal,
                    },
                    {
                      value: "OTHER",
                      label: strings.feedbackOther,
                    },
                  ]}
                  className={`basic-multi-select`}
                  classNamePrefix="select"
                  onChange={handleFeedbackType}
                  placeholder={strings.selectOption}
                  noOptionsMessage={() => strings.noOptions}
                  value={formData.type}
                  isDisabled={isEdit}
                  required
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                  />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>
                <div
                  className={
                    "label-icon align-items-end d-flex rotate-key-icon "
                  }
                  disabled={isEdit}
                ></div>
                {strings.date}<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                required
                type={"date"}
                value={formData.dateCreated}
                onChange={handleChange}
                className={"user_professional_data_input"}
                name={"dateCreated"}
                disabled={isEdit}
                onInvalid={(e) => {
                  e.target.setCustomValidity(strings.fieldsRequired);
                }}
              ></Form.Control>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="mb-3 required" controlId="title">
                <Form.Label> {strings.title}<span className="text-danger">*</span> </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={strings.title}
                  autoFocus
                  value={formData.title}
                  name="title"
                  onChange={handleChange}
                  disabled={isEdit}
                  required
                  onInvalid={(e) => {
                    e.target.setCustomValidity(strings.fieldsRequired);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 required" controlId="description">
                <Form.Label> {strings.description}<span className="text-danger">*</span> </Form.Label>
                {isEdit ? (
                  <p>{formData.description}</p>
                ) : (
                  <Form.Control
                    as="textarea"
                    rows={4}
                    autoFocus
                    value={formData.description}
                    name="description"
                    onChange={handleChange}
                    disabled={isEdit}
                    required  
                    onInvalid={(e) => {
                      e.target.setCustomValidity(strings.fieldsRequired);
                    }}
                  />
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {!isEdit && (
            <>
              <div
                className={"cancel btn text-white"}
                onClick={props.handleClose}
              >
                {strings.cancel}
              </div>
              <button type={"submit"} className={"save btn text-white"}>
                {strings.save}
              </button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default FeedbackModal;
