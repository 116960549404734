import React, {useState} from "react";
import {strings} from "../../../localization/Localization";
import {Form, Modal} from "react-bootstrap";
import UserAddService from "../../../repository/userRepository/UserAddRepository";
import swal from "sweetalert";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const UserChangePasswordByLoggedUserModal = (props) => {

    const [changePasswordForm, setChangePasswordForm] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    });

    const bothFieldsAreFilled = changePasswordForm.newPassword && changePasswordForm.confirmNewPassword;

    const passwordNotMatchException = changePasswordForm.newPassword === changePasswordForm.confirmNewPassword;

    const successfulAlert = (text) => {
        swal(text, {
            text: text,
            icon: "success",
        });
    };

    const errorAlert = (text) => {
        swal({
            title: strings.oops,
            text: text,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        })
    };

    const handleChangePasswordFormChange = (e) => {
        setChangePasswordForm({
            ...changePasswordForm,
            [e.target.name]: e.target.value.trim()
        })
    };

    const OnChangePasswordFormSubmit = (e) => {
        e.preventDefault();
        changePassword();
    };

    const changePassword = () => {
        UserAddService.changePasswordWithoutToken(props.selectedUserForChangePassword.email, changePasswordForm.oldPassword, changePasswordForm.newPassword, changePasswordForm.confirmNewPassword)
            .then((data) => {
                successfulAlert(strings.passwordChangeSuccessful);
                setChangePasswordForm({
                    oldPassword: "",
                    newPassword: "",
                    confirmNewPassword: ""
                });
                props.handleClose();
            }).catch((e) => {
            let error = "";
            if (e.response.data == "Incorrect password") {
                error = strings.incorrectPassword;
            } else if (e.response.data == "Password do not match exception") {
                error = strings.passwordsConfirmationDoNotMatch;
            } else {
                error = strings.somethingWrong;
            }
            errorAlert(error);
        })
    }


    return(
        <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={"lg"} centered>
            <Modal.Header closeButton>
                <Modal.Title>{strings.changePassword}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={OnChangePasswordFormSubmit}>
                <Modal.Body>
                <div className=" mb-3"><label
                    className="labels">{strings.password}<span className="text-danger">*</span></label>
                    <input type="password"
                           className="form-control"
                           required={true}
                           value={changePasswordForm.oldPassword}
                           onChange={handleChangePasswordFormChange}
                           name={"oldPassword"}
                           placeholder={strings.enterOldPassword}/>
                </div>
                <div className="mb-3"><label
                    className="labels">{strings.newPassword}<span className="text-danger">*</span></label><input
                    type="password" className="form-control"
                    name={"newPassword"}
                    value={changePasswordForm.newPassword}
                    required={true}
                    onChange={(e) => {
                        handleChangePasswordFormChange(e);
                    }}
                    placeholder={strings.enterNewPassword}
                />
                </div>
                <div className=" mb-3"><label
                    className="labels">{strings.confirmNewPassword}<span className="text-danger">*</span></label><input
                    type="password" className="form-control"
                    name={"confirmNewPassword"}
                    value={changePasswordForm.confirmNewPassword}
                    required={true}
                    onChange={(e) => {
                        handleChangePasswordFormChange(e);
                    }}
                    placeholder={strings.enterNewPassword}/>
                </div>

                {bothFieldsAreFilled ?
                    (passwordNotMatchException ?
                            (
                                <div
                                    className={'d-inline-flex text-center justify-content-center'}>
                                    <i className="material-icons text-success">check_circle</i>
                                    &nbsp; {strings.passwordsMatch}
                                </div>
                            ) :
                            (
                                <div
                                    className={'d-inline-flex text-center justify-content-center'}>
                                    <i className="material-icons text-danger">report</i>
                                    &nbsp;{strings.passwordsDoNotMatch}
                                </div>
                            )
                    ) : null
                }

                </Modal.Body>
                <Modal.Footer>
                    {passwordNotMatchException && bothFieldsAreFilled &&
                        <div className="mt-1  text-center">
                            <button className={"save btn text-white "}
                                    type="submit">{strings.savePassword}
                            </button>
                        </div>
                    }
                    {!bothFieldsAreFilled &&
                        <div className="mt-1 pt-2 text-center">
                            <button className={"save btn text-white"}
                                    type="submit">{strings.savePassword}
                            </button>
                        </div>
                    }
                </Modal.Footer>
            </Form>

        </Modal>
    );
}
export default UserChangePasswordByLoggedUserModal;