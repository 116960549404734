import axios from "../../axios/axios";

const UserEventGroupService = {

    getAllUserEventGroupsInEventForLoggedInUser: (eventId) => {
        return axios.get(`/userEvent/getAllForLoggedInUser/${eventId}`);
    },

    getAllUsersForEventGroup: (eventGroupId) => {
        return axios.get(`/userEvent/getUsersForEventGroup/${eventGroupId}`);
    },

    userAttendsEventGroup: (eventGroupId) => {
        return axios.post("/userEvent/attend", {
            eventGroupId,
        })
    },

    userCancelsAttendanceForEventGroup: (eventGroupId) => {
        return axios.delete(`/userEvent/cancelAttendanceForLoggedInUser/${eventGroupId}`);
    },

    deleteUserEventGroupWithUserAndEventGroupId: (userId, eventGroupId) => {
        return axios.delete(`/userEvent/deleteUserEventGroupByUserAndEventGroupIds/${userId}/${eventGroupId}`);
    }

}

export default UserEventGroupService;