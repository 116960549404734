import axios from "../../axios/axios";

const UserService = {

    findAllUsers:() =>{
      return axios.get("/user/findAllUsers")
    },
    getImageForLoggedUser:()=>{
        return axios.get("/user/getProfilePhoto")
    },
    changeProfileImage: (img) => {
        return axios.post("/user/updatePhoto",img)
    },
    changeProfileImageFromByteForLoggedUser: (byte) => {
        return axios.post(`/user/updatePhotoFromByteForLoggeduser/`, {byte})
    },
    changeProfileImageFromByteFromAdministrator: (byte,id) => {
        return axios.post(`/user/updatePhotoFromByteByAdministrator/${id}`, {byte})
    },
    fetchUsers: (page, size, data = {
        "firstName": null,
        lastName: null,
        parentName: null,
        email: null,
        hrmsIdNumber: null,
        roleIds: [],
        religion: [],
        ethnicity: [],
        organisation: [],
        position: []
    }) => {
        return axios.post(`/user/getAllFiltered/${page}/${size}`, data)
    },
    fetchUsersIgnoreIsActive: (page, size, data = {
        "firstName": null,
        lastName: null,
        parentName: null,
        email: null,
        hrmsIdNumber: null,
        roleIds: [],
        religion: [],
        ethnicity: [],
        organisation: [],
        position: []
    }) => {
        return axios.post(`/user/getAllFilteredActive/${page}/${size}`, data)
    },
    getUserDetails: () => {
        return axios.get(`/user/getUserDetails`)
    },
    getUserDetailsById: (id) => {
        return axios.get(`/user/getUserDetails/${id}`)
    },
    editUser: (id, privateEmail, email, firstName, lastName, parentName, roleIds, phoneNumber, address, secondAddress, ethnicityId, religionId, hrmsIdNumber, gender, embg, birthDate,isForeignCitizen) => {
        return axios.put('/user/update', {
            id,
            privateEmail,
            email,
            "enabled": true,
            firstName,
            lastName,
            phoneNumber,
            address,
            secondAddress,
            "headUser": null,
            "person": null,
            parentName,
            "employment": null,
            roleIds,
            ethnicityId,
            religionId,
            hrmsIdNumber,
            gender,
            embg,
            birthDate,
            isForeignCitizen
        })
    },
    deleteUser(id) {
        return axios.delete(`/user/delete/${id}`)
    },
    getAllUserForHoliday: (firstName, lastName, parentName, email, hrmsIdNumber, roles, religion, ethnicity, id, organisationsIds, positionsIds, page, size) => {
        return axios.post(`/user/getAllByHoliday/${id}/${page}/${size}`, {
            email,
            firstName,
            lastName,
            parentName,
            hrmsIdNumber,
            roleIds: roles,
            ethnicity,
            religion,
            organisation: organisationsIds,
            position: positionsIds
        })
    },
    getAllUserWithoutHoliday: (firstName, lastName, parentName, email, hrmsIdNumber, roles, religion, ethnicity, id, organisationsIds, positionsIds, page, size) => {
        return axios.post(`/user/getAllWithoutHoliday/${id}/${page}/${size}`, {
            email,
            firstName,
            lastName,
            parentName,
            hrmsIdNumber,
            roleIds: roles,
            ethnicity,
            religion,
            organisation: organisationsIds,
            position: positionsIds
        })
    },
    getAllUserForHolidayFiltered: (filterDateForHolidayUserList, id) => {
        return axios.post(`/user/getAllByHolidayUsersForHolidayFiltered/${id}`, {
            "email": filterDateForHolidayUserList.email,
            "firstName": filterDateForHolidayUserList.firstName,
            "lastName": filterDateForHolidayUserList.lastName,
            "parentName": filterDateForHolidayUserList.parentName,
            "hrmsIdNumber": filterDateForHolidayUserList.hrmsIdNumber,
            roleIds: filterDateForHolidayUserList.roles,
            "ethnicity": filterDateForHolidayUserList.ethnicityId,
            "religion": filterDateForHolidayUserList.religionIds,
            organisation: filterDateForHolidayUserList.organisationsIds,
            position: filterDateForHolidayUserList.positionsIds
        })
    },
    getAllUserWithoutHolidayFiltered: (filterDateForOtherUserList, id) => {
        return axios.post(`/user/getAllByHolidayUsersWithOutHoliday/${id}`, {
            "email": filterDateForOtherUserList.email,
            "firstName": filterDateForOtherUserList.firstName,
            "lastName": filterDateForOtherUserList.lastName,
            "parentName": filterDateForOtherUserList.parentName,
            "hrmsIdNumber": filterDateForOtherUserList.hrmsIdNumber,
            roleIds: filterDateForOtherUserList.roles,
            "ethnicity": filterDateForOtherUserList.ethnicityId,
            "religion": filterDateForOtherUserList.religionIds,
            organisation: filterDateForOtherUserList.organisationsIds,
            position: filterDateForOtherUserList.positionsIds
        })
    },
    changeUserActive:(id)=>{
        return axios.post(`/user/enableUser/${id}`)
    },
    getAllUsersNotBelongingToBenefit:(benefitId) => {
        return axios.get(`/user/getAllUsersNotBelongingToBenefit/${benefitId}`)
    },
    findAllThatDoNotPossessTheEquipment: (equipmentNomenclatureId) => {
        return axios.get(`/user/findAllThatDoNotPossessTheEquipment/${equipmentNomenclatureId}`)
    }
}
export default UserService;