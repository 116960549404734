import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {strings} from '../../../../localization/Localization';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LeaveTypeRepository from '../../../../repository/leaveTypeRepository/LeaveTypeRepository';
import swal from 'sweetalert';
import Select from 'react-select';
import LeaveReasonService from '../../../../repository/leaveReasonRepository/LeaveReasonRepository';
import ApplicationService from '../../../../ApplicationService';
import InputLimit from "../../../../components/InputLimit/InputLimit";
import DraggableModalDialog from '../../../../components/DraggableModalDialog/DraggableModalDialog';

const LeaveReasonsModal = (props) => {

    const [formData, updateFormData] = useState({
        name: '',
        label_mk: '',
        label_sq: '',
        label_en: '',
        baseDays: '',
        startDate: '',
        endDate: ''
    });

    const [leaveTypeForDropDown, setLeaveTypeForDropDown] = useState([]);

    const [selectedLeaveType, setSelectedLeaveType] = useState({});

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (props.showModal) {
            getLeaveType();
            if (props.selectedLeaveReasonsForEdit.id) {
                updateFormData({
                    name: props.selectedLeaveReasonsForEdit.name,
                    label_mk: props.selectedLeaveReasonsForEdit.labelMk,
                    label_sq: props.selectedLeaveReasonsForEdit.labelSq,
                    label_en: props.selectedLeaveReasonsForEdit.labelEn,
                    baseDays: props.selectedLeaveReasonsForEdit.baseDays,
                    startDate: props.selectedLeaveReasonsForEdit.dateFrom,
                    endDate: props.selectedLeaveReasonsForEdit.dateTo,
                });
            }
            if (props.selectedLeaveReasonsForEdit.active) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        } else {
            updateFormData({
                name: '',
                label_mk: '',
                label_sq: '',
                label_en: '',
                baseDays: '',
                startDate: '',
                endDate: ''
            });
            setSelectedLeaveType({});
        }
    }, [props.showModal]);

    const getLeaveType = () => {
        LeaveTypeRepository.getAllLeaveType()
            .then((data) => {
                setLeaveTypeForDropDown(data.data?.map((item) => ({
                    value: item.id,
                    label: item.labelMk
                })));
                if (props.selectedLeaveReasonsForEdit.id) {
                    const leaveType = data.data.filter((e) => e.id == props.selectedLeaveReasonsForEdit.leaveType)[0];
                    setSelectedLeaveType({
                        value: leaveType.id,
                        label: ApplicationService.getActiveLanguage() == 'mk' ? leaveType.labelMk : leaveType.labelEn
                    });
                }
            });
    };


    const handleChange = (e) => {
        const {name, value} = e.target;

        if (e.target.hasAttribute("required") && value.trim() === "") {
            e.target.setCustomValidity(strings.fieldsRequired);
          } else {
            e.target.setCustomValidity("");
          }

        if (name === 'name') {
            const pattern = /^[A-Z0-9!@#$%^&*()_+{}\[\]:;"'<>,.?~]+$/;
            if (value === '' || pattern.test(value)) {
                updateFormData({
                    ...formData,
                    [name]: value
                });
            }
        } else {
            updateFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSelectedLeaveType = (e) => {
        setSelectedLeaveType(e);
    };

    const handleActiveReasons = () => {
        LeaveReasonService.changeActiveLeaveReasons(props.selectedLeaveReasonsForEdit.id)
            .then(() => {
                setIsActive(!isActive);
                props.getLeaveReasons();
            });
    };

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: 'success',
        });
    };

    const warningAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: 'error',
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const leaveTypeRequiredAlert = () => {
        swal({
            title: strings.oops,
            text: strings.pleaseEnterLeaveType,
            icon: 'error',
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };


    const addLeaveReasons = (data = {
        id: '',
        name: '',
        labelMk: '',
        labelEn: '',
        labelSq: '',
        leaveType: '',
        dateFrom: '',
        dateTo: ''
    }) => {
        LeaveReasonService.addLeaveReasons(data)
            .then(() => {
                props.getLeaveReasons();
                props.onHandleClose();
                successfulAlert();
            }).catch(() => {
            warningAlert();
        });
    };

    const editLeaveReasons = (data = {
        id: '',
        name: '',
        labelMk: '',
        labelEn: '',
        labelSq: '',
        leaveType: ''
    }) => {
        LeaveReasonService.editLeaveReasons(data)
            .then(() => {
                props.getLeaveReasons();
                props.onHandleClose();
                successfulAlert();
            }).catch(() => {
            warningAlert();
        });
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        if (!selectedLeaveType.value) {
            leaveTypeRequiredAlert();
            return;
        }
        const id = props.selectedLeaveReasonsForEdit.id;
        const name = formData.name;
        const label_mk = formData.label_mk;
        const label_sq = formData.label_sq;
        const label_en = formData.label_en;
        const baseDays = formData.baseDays;
        const leaveType = selectedLeaveType.value;
        const startDate = formData.startDate;
        const endDate = formData.endDate;
        if (props.selectedLeaveReasonsForEdit.id) {
            editLeaveReasons({
                id,
                name,
                labelMk: label_mk,
                labelEn: label_en,
                labelSq: label_sq,
                baseDays: baseDays,
                leaveType: leaveType,
                dateFrom: startDate,
                dateTo: endDate
            });
        } else {
            addLeaveReasons({
                id,
                name,
                labelMk: label_mk,
                labelEn: label_en,
                labelSq: label_sq,
                baseDays: baseDays,
                leaveType: leaveType,
                dateFrom: startDate,
                dateTo: endDate
            });
        }
    };

    return (<Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.onHandleClose} size={'lg'} centered>
        <Modal.Header closeButton>
            <Modal.Title>
                {props.selectedLeaveReasonsForEdit.id ? (
                    <div>{strings.editLeaveReasons}</div>
                ) : (
                    <div>{strings.addLeaveReasons}</div>
                )}
            </Modal.Title>
        </Modal.Header>
        <Form onSubmit={onFormSubmit}>
            <Modal.Body>
                <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>{strings.code}<span className="text-danger">*</span></Form.Label>
                    {InputLimit.codeLimitMessage()}
                    <Form.Control
                        type='text'
                        autoFocus
                        required
                        disabled={props.selectedLeaveReasonsForEdit.id && !props.selectedLeaveReasonsForEdit?.editable}
                        onChange={handleChange}
                        value={formData.name}
                        name='name'
                        onInvalid={(e) => {
                            e.target.setCustomValidity(strings.fieldsRequired);
                          }}/>
                </Form.Group>
                <h5 className={"my-4"}>{strings.marks}</h5>
                <Row className={"px-2"}>
                    <Col xs={12} md={4}>
                        <Form.Group className='mb-3 required' controlId='label_mk'>
                            <Form.Label> {strings.labelMk}<span className="text-danger">*</span> </Form.Label>
                            <Form.Control
                                required
                                type='text'
                                autoFocus
                                disabled={props.selectedLeaveReasonsForEdit.id && !props.selectedLeaveReasonsForEdit?.editable}
                                value={formData.label_mk}
                                name='label_mk'
                                onChange={handleChange}
                                onInvalid={(e) => {
                                    e.target.setCustomValidity(strings.fieldsRequired);
                                  }}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group className='mb-3' controlId='label_sq'>
                            <Form.Label>{strings.labelSq}</Form.Label>
                            <Form.Control
                                type='text'
                                onChange={handleChange}
                                disabled={props.selectedLeaveReasonsForEdit.id && !props.selectedLeaveReasonsForEdit?.editable}
                                autoFocus
                                value={formData.label_sq}
                                name='label_sq'/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group className='mb-3' controlId='label_en'>
                            <Form.Label>{strings.labelEn}</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                value={formData.label_en}
                                disabled={props.selectedLeaveReasonsForEdit.id && !props.selectedLeaveReasonsForEdit?.editable}
                                onChange={handleChange}
                                name='label_en'/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                            <Form.Label>
                                {strings.leaveType}
                            </Form.Label>
                            <Select
                                onChange={handleSelectedLeaveType}
                                value={selectedLeaveType}
                                name='leaveType'
                                isDisabled={props.selectedLeaveReasonsForEdit.id && !props.selectedLeaveReasonsForEdit?.editable}
                                options={leaveTypeForDropDown}
                                placeholder={strings.selectOption}
                                noOptionsMessage={()=>strings.noOptions}
                                className='basic-multi-select'
                                classNamePrefix='select'/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                            <Form.Label>
                                {strings.allowedDays}<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                onChange={handleChange}
                                type={'number'}
                                required
                                value={formData.baseDays}
                                name='baseDays'
                                disabled={props.selectedLeaveReasonsForEdit.id && !props.selectedLeaveReasonsForEdit?.editable}
                                options={leaveTypeForDropDown}
                                className='basic-multi-select'
                                classNamePrefix='select'
                                onInvalid={(e) => {
                                    e.target.setCustomValidity(strings.fieldsRequired);
                                  }}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput2'>
                            <Form.Label>
                                {strings.startDate}<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                onChange={handleChange}
                                type={'date'}
                                required
                                disabled={props.selectedLeaveReasonsForEdit.id && !props.selectedLeaveReasonsForEdit?.editable}
                                value={formData.startDate}
                                name='startDate'
                                onInvalid={(e) => {
                                    e.target.setCustomValidity(strings.fieldsRequired);
                                  }}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput3'>
                            <Form.Label>
                                {strings.endDate}<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                onChange={handleChange}
                                type={'date'}
                                required
                                disabled={props.selectedLeaveReasonsForEdit.id && !props.selectedLeaveReasonsForEdit?.editable}
                                value={formData.endDate}
                                name='endDate'
                                onInvalid={(e) => {
                                    e.target.setCustomValidity(strings.fieldsRequired);
                                  }}/>
                        </Form.Group>
                    </Col>
                </Row>
                {props.selectedLeaveReasonsForEdit.id &&
                    <Row style={{borderTop: '1px solid #ced4da'}} className={'mt-5'}>
                        <Col xs={12} md={8} className={'mt-4'}>
                            <Form.Group className='mb-1 ms-1   d-flex '
                                        controlId='exampleForm.ControlInput1'>
                                <Form.Group className={'d-flex my-auto mt-1'}>
                                    <Form.Label className={'me-3'}>
                                        {strings.active}
                                    </Form.Label>
                                    <div className={'mt-1'}>
                                        <label className='switchEnabled'>
                                            <input type='checkbox' checked={isActive}
                                                   disabled={props.selectedLeaveReasonsForEdit.id && !props.selectedLeaveReasonsForEdit?.editable}
                                                   onChange={handleActiveReasons}/>
                                            <span className='sliderEnabled round'> </span>
                                        </label>
                                    </div>
                                </Form.Group>
                            </Form.Group>
                        </Col>
                    </Row>}
            </Modal.Body>
            <Modal.Footer>
                <div className={'cancel btn text-white'} onClick={props.onHandleClose}>
                    {strings.cancel}
                </div>
                <button type={'submit'}
                        disabled={props.selectedLeaveReasonsForEdit.id && !props.selectedLeaveReasonsForEdit?.editable}
                        className={'save btn text-white'}>
                    {strings.save}
                </button>
            </Modal.Footer>
        </Form>
    </Modal>);
};
export default LeaveReasonsModal;