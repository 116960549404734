import {useHistory, useParams} from 'react-router-dom';
import React, {useRef,useEffect, useState} from 'react';
import ProjectService from '../../../repository/projectRepository/projectRepository';
import Form from 'react-bootstrap/Form';
import {strings} from '../../../localization/Localization';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import ApplicationService from '../../../ApplicationService';
import swal from 'sweetalert';
import NomenclatureService from '../../../repository/nomenclatureRepository/NomenclatureRepository';
import ProjectGroupService from '../../../repository/projectGroupRepository/projectGroupRepository';
import AddFile from '../ProjectFile/AddFile';
import projectUserRepository from '../../../repository/projectsRepository/projectUserRepository';
import CustomTable from '../../../components/customTable/CustomTableComponent';
import ReactPaginate from 'react-paginate';
import {AiOutlineArrowLeft, AiOutlineArrowRight} from 'react-icons/ai';
import AddUserToProjectModal from '../AddUserToProjctModal/AddUserToProjectModal';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const AddOrEditProject = (props) => {

    const history = useHistory();
    const param = useParams();
    const [selectedProject, setSelectedProject] = useState({});
    const [nomenclatureProjectsList, setNomenclatureProjectsList] = useState([]);
    const [groupForSelectedProject, setGroupForSelectedProject] = React.useState([]);
    const [allGroups, setAllGroups] = useState([]);
    const [technologyListForDropDown, setTechnologyListForDropDown] = useState([]);
    const [selectedTechnologies, setSelectedTechnologies] = React.useState([]);
    const [file, setFile] = useState({});
    const [collapseOpen, setCollapseOpen] = useState(false);
    const inputRef = React.useRef(null);
    const [dragActive, setDragActive] = React.useState(false);
    const [projectUserList, setProjectUserList] = useState([]);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [userToDelete, setUserToDelete] = useState(null);
    const location = useLocation();
    const word = location.state ? location.state.word : null;

    const [formData, updateFormData] = useState({
        name: '',
        description: '',
        dateBeginning: '',
        dateEnding: '',
        isActive: false,
        typeNomenclatureList: undefined,
        technologyNomenclatureList: '',
        projectGroup: undefined,
        technologyIds: [],
        file_name: '',
        file_description: '',
        filePath: '',
        isPrivate: false,
        project: undefined,
        projectTechnologies: undefined,
        projectUsers: [],
    });

    const [userList, setUserList] = useState([]);
    const employeesSectionRef = useRef(null);
   

    const addUserToUserList = (user) => {
        setUserList([...userList, user]);
    };

    const [showAddUserToProjectModal, setAddUserToProjectModal] = useState(false);

    const handleShowAddUserToProjectModal = () => {
        setAddUserToProjectModal(true);
    };

    const handleCloseAddUserToProjectModal = () => {
        setAddUserToProjectModal(false);
        getAllProjectUsers();
    };

    const handleCancel = () => {
        history.goBack();
    };

    useEffect(() => {
        if (employeesSectionRef.current) {
            console.log(employeesSectionRef)
            employeesSectionRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [param.id]); 

    const getAllProjectUsers = () => {
        projectUserRepository.getProjectUserByProjectId(param.id)
            .then((response) => {
                setProjectUserList(response.data);
            })
            .catch((error) => {
                console.error('Error fetching project users by project id:', error);
            });
    };

    const getProjectDetails = () => {
        ProjectService.getProjectById(param.id).then((data) => {
            setSelectedProject(data.data);
        })
    };

    const getAllGroups = () => {
        ProjectGroupService.getAllGroups()
            .then((data) => {
                let groupsConverter = data.data.map((gp) => {
                    return {
                        value: gp.id,
                        label: gp.name,
                    };
                });
                setAllGroups(groupsConverter);
            });
    };

    useEffect(() => {
        if (param.id) {
            getProjectDetails();
            getAllProjectUsers();
        }
        getAllProjectTechnologies();
        getAllGroups();
    }, []);

    useEffect(() => {
        if (!param.id) {
            resetData();
        } else {
            if (selectedProject) {
                let projectTypeTemp = null;
                let projectGroupTemp = null;
                let projectTechnologiesTemp = projectTechnologiesConverter(selectedProject?.technologies);
                if (selectedProject?.typeNomenclatureEntry != null) {
                    projectTypeTemp = {
                        value: selectedProject?.typeNomenclatureEntry?.id,
                        label: ApplicationService.getActiveLanguage() === 'mk' ? selectedProject?.typeNomenclatureEntry?.labelMk : selectedProject?.typeNomenclatureEntry?.labelEn
                    };
                }
                if (selectedProject?.group != null) {
                    projectGroupTemp = {
                        value: selectedProject?.group?.id,
                        label: selectedProject?.group.name,
                    };
                }
                if (selectedProject?.id) {
                    updateFormData({
                        name: selectedProject?.name,
                        description: selectedProject?.description,
                        dateBeginning: selectedProject?.dateBeginning.split('T').at(0),
                        dateEnding: selectedProject?.dateEnding.split('T').at(0),
                        isActive: selectedProject?.isActive,
                        typeNomenclatureList: projectTypeTemp,
                        projectGroup: projectGroupTemp,
                        projectTechnologies: projectTechnologiesTemp,
                    });
                }
            }
        }
    }, [selectedProject]);

    const projectTechnologiesConverter = (data) => {
        if (data) {
            return data.map((n) => {
                return {
                    value: n.technology?.id,
                    label: ApplicationService.getActiveLanguage() === 'mk' ? n?.technology?.labelMk : n?.technology?.labelEn
                };
            });
        }
    };

    useEffect(() => {
        if (nomenclatureProjectsList.length === 0) {
            getAllNomenclatureEntriesForProjects();
        }
    }, [formData]);

    const getGroupForProject = () => {
        ProjectGroupService.getGroupById(selectedProject?.groupId)
            .then((data) => {
                setGroupForSelectedProject(data.data);
            });
    };

    const resetData = () => {
        updateFormData({
            name: '',
            description: '',
            dateBeginning: '',
            dateEnding: '',
            isActive: false,
            typeNomenclatureList: undefined,
            technologyNomenclatureList: '',
            projectGroup: undefined,
            technologyIds: [],
            fr_name: '',
            fr_description: '',
            filePath: '',
            isPrivate: false,
            project: undefined,
        });
        setFile({});
    };

    const getAllNomenclatureEntriesForProjects = () => {
        NomenclatureService.getAllNomenclatureEntryByNomenclatureName('Тип на проект')
            .then((data) => {
                let nomenclatureConvert = data.data.map((n) => {
                    return {
                        value: n.id,
                        label: ApplicationService.getActiveLanguage() === 'mk' ? n.labelMk : n.labelEn
                    };
                });
                setNomenclatureProjectsList(nomenclatureConvert);
            });
    };

    const handleProjectsChange = (selectedOption) => {
        updateFormData({
            ...formData,
            typeNomenclatureList: selectedOption
        });
    };

    const handleGroupChange = (selectedOption) => {
        updateFormData({
            ...formData,
            projectGroup: selectedOption
        });
    };

    const handleChange = (e) => {
        const {
            name,
            value,
            type,
            checked
        } = e.target;
        
        if (e.target.hasAttribute("required") && value.trim() === "") {
            e.target.setCustomValidity(strings.fieldsRequired);
          } else {
            e.target.setCustomValidity("");
          }

        const newValue = type === 'checkbox' ? checked : value;

        updateFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : newValue,
        });
    };

    const projectEditSuccessfulAlert = () => {
        swal(strings.userEditSuccessful, {
            icon: 'success',
        });

    };

    const projectAddSuccessfulAlert = (projectId) => {
        swal({
            title: strings.projectCreatedSuccessfully,
            text: strings.projectAddedInfo,
            icon: 'success',
            buttons: [strings.cancel, strings.editProject],
        }).then((willSuccess) => {
            if (willSuccess) {
                history.push(`/projects/${projectId}`);
            } else {
                history.push('/projects');
            }
        });
    };

    const errorAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: 'error',
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const addProject = (newFormData) => {
        ProjectService.addProject(newFormData)
            .then((projectId) => {
                projectAddSuccessfulAlert(projectId);
            })
            .catch(() => {
                errorAlert();
            });
    };

    const editProject = (newFormData) => {
        ProjectService.editProject(newFormData)
            .then(() => {
                projectEditSuccessfulAlert();
                getProjectDetails();
                history.push('/projects');
            }).catch(() => {
            errorAlert();
        });
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        const name = formData.name !== '' ? formData.name : null;
        const description = formData.description !== '' ? formData.description : null;
        const dateBeginning = formData.dateBeginning !== '' ? formData.dateBeginning : null;
        const dateEnding = formData.dateEnding !== '' ? formData.dateEnding : null;
        const isActive = formData.isActive !== '' ? formData.isActive : null;
        const typeNomenclatureEntryId = formData.typeNomenclatureList !== '' ? formData.typeNomenclatureList.value : null;
        const groupId = formData.projectGroup && formData.projectGroup.value !== '' ? formData.projectGroup.value : null;
        let technologyIds = selectedTechnologies ? selectedTechnologies.map((e) => e.value) : [];
        const newFormData = new FormData();
        newFormData.append('name', name);
        newFormData.append('description', description);
        newFormData.append('dateBeginning', dateBeginning);
        newFormData.append('dateEnding', dateEnding);
        newFormData.append('isActive', isActive);
        newFormData.append('typeNomenclatureEntryId', typeNomenclatureEntryId);
        newFormData.append('groupId', groupId);
        newFormData.append('technologyIds', JSON.stringify(technologyIds));
        newFormData.append('file', file);
        newFormData.append('file_name', formData.file_name);
        newFormData.append('file_description', formData.file_description);
        newFormData.append('isPrivate', formData.isPrivate);
        if (selectedProject?.id) {
            newFormData.append('id', selectedProject?.id);
            editProject(newFormData);
        } else {
            addProject(newFormData);
        }
    };

    const getAllProjectTechnologies = () => {
        NomenclatureService.getAllNomenclatureEntryByNomenclatureName('Технологии')
            .then((response) => {
                let nomenclatureConvert = response.data.map((n) => {
                    return {
                        value: n.id,
                        label: ApplicationService.getActiveLanguage() === 'mk' ? n.labelMk : n.labelEn
                    };
                });
                setTechnologyListForDropDown(nomenclatureConvert);
            }).catch((error) => {
            console.error('Error fetching project technologies:', error);
        });
    };

    const getSelectedTechnologyFromDropDown = (e) => {
        setSelectedTechnologies(e);
        updateFormData({
            ...formData,
            projectTechnologies: e
        });
    };

    const handlePageChange = (selected) => {
        setCurrentPage(selected.selected + 1);
    };

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedUsers = projectUserList.slice(startIndex, endIndex);
    const displayUsersList = userList.slice(startIndex, endIndex);

    const columns = [
        {
            key: 'userName',
            header: strings.name
        },
        {
            key: 'userEmail',
            header: strings.email
        },
        {
            key: 'roles',
            header: strings.roles,
            render: roles => roles.map(role => role.name).join(', ')
        },
        {
            key: 'technologies',
            header: strings.technologies,
            render: tech => tech.map(t => t.name).join(', ')
        },
        {
            key: 'id',
            header: strings.delete,
            render: (userId) => (
                <div className={'icon-div'}>
                    <a title={'Delete'}>
                        <div
                            onClick={() => handleDeleteClick(userId)}
                            title={'Delete'}
                            className={'text-dark icon'}>
                            <i className={'material-icons delete-icon-circle'}>delete</i>
                        </div>
                    </a>
                </div>
            )
        }
    ];

    const toggleUserSelection = (userId) => {
        if (selectedUserIds.includes(userId)) {
            setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
        } else {
            setSelectedUserIds([...selectedUserIds, userId]);
        }
    };

    const handleDeleteClick = (userId) => {
        if (userId !== null) {
            const projectId = selectedProject?.id;
            projectUserRepository.removeProjectUsers(projectId, [userId])
                .then(() => {
                    const updatedProjectUserList = projectUserList.filter((user) => user.id !== userId);
                    setProjectUserList(updatedProjectUserList);
                    setUserToDelete(null);
                })
                .catch((error) => {
                    console.error('Error deleting user from project:', error);
                });
        }
    };

    const handleCollapseToggle = () => {
        setCollapseOpen(!collapseOpen);
    };

    const handleChangeFile = (e) => {
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setFile(e.dataTransfer.files[0]);
        }
    };

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleCancelCollapse = () => {
        setCollapseOpen(false);
        props.handleClose();
    };

    return (
        <div className={'pb-4'}>
            <div className={'user_details_box w-100 mb-3 border border-radius border-light border-5 px-4 pt-4'}>
                <Form onSubmit={onFormSubmit}>
                    {param.id ? (
                        <h1 className={'text-center'}>{strings.editProject}: <b>{formData.name}</b></h1>
                    ) : (
                        <h1 className={'text-center'}>{strings.addProject}</h1>
                    )}
                    <hr/>
                    <Row className={'mb-2'}>
                        <Col xs={12} md={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label className={'d-flex text-secondary'}>
                                    {strings.name}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type={'text'}
                                    value={formData.name}
                                    onChange={handleChange}
                                    name={'name'}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={5}>
                            <Form.Group className='mb-3'>
                                <Form.Label className={'text-secondary d-flex'}>
                                    {strings.projectGroup}
                                </Form.Label>
                                <Select
                                    name='projectGroup'
                                    options={allGroups}
                                    className={`border border-1 border-radius border-light rounded-4 bg-light`}
                                    classNamePrefix='select'
                                    onChange={handleGroupChange}
                                    placeholder={strings.selectOption}
                                    noOptionsMessage={()=>strings.noOptions}
                                    value={formData.projectGroup}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={1} className={'d-flex justify-content-center align-items-center'}>
                            <Form.Group className='mb-3'>
                                <Form.Label className={'d-flex me-1 text-secondary'}>{strings.isActive}</Form.Label>
                                <label className='switchEnabled me-2 mb-0'>
                                    <input type='checkbox' name={'isActive'}
                                           value={formData.isActive}
                                           checked={formData.isActive}
                                           disabled={false}
                                           onChange={handleChange}/>
                                    <span className='sliderEnabled round'> </span>
                                </label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={'mb-2'}>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label className={'d-flex text-secondary'}>
                                    {strings.description}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    as='textarea'
                                    rows={4}
                                    value={formData.description}
                                    onChange={handleChange}
                                    name={'description'}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={'mb-2'}>
                        <Col xs={12} md={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label className={'d-flex align-items-center mb-0 text-secondary'}>
                                    <div className={'label-icon align-items-end d-flex rotate-key-icon mt-1'}>
                                    </div>
                                    {strings.dateBeginning}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type={'date'}
                                    value={formData.dateBeginning}
                                    onChange={handleChange}
                                    className={'user_professional_data_input'}
                                    name={'dateBeginning'}
                                    max={formData.dateEnding}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label className={'d-flex align-items-center mb-0 text-secondary'}>
                                    <div className={'label-icon align-items-end d-flex rotate-key-icon mt-1'}>
                                    </div>
                                    {strings.dateEnding}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type={'date'}
                                    value={formData.dateEnding ? formData.dateEnding : ''}
                                    onChange={handleChange}
                                    className={'user_professional_data_input'}
                                    name={'dateEnding'}
                                    min={formData.dateBeginning}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label className={'text-secondary mb-1'}>
                                    {strings.projectTechnology}
                                </Form.Label>
                                <Select
                                    onChange={getSelectedTechnologyFromDropDown}
                                    value={formData.projectTechnologies}
                                    isMulti
                                    name='technology'
                                    options={technologyListForDropDown}
                                    placeholder={strings.selectOption}
                                    noOptionsMessage={()=>strings.noOptions}
                                    className='border border-1 border-radius border-light rounded-4 bg-light'
                                    classNamePrefix='select'
                                    />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label className={'d-flex text-secondary mb-1'}>
                                    {strings.projectType}
                                </Form.Label>
                                <Select
                                    name='nomenclatureEventType'
                                    options={nomenclatureProjectsList}
                                    className={`border border-1 border-radius border-light rounded-4 bg-light`}
                                    classNamePrefix='select'
                                    onChange={handleProjectsChange}
                                    placeholder={strings.selectOption}
                                    noOptionsMessage={()=>strings.noOptions}
                                    value={formData.typeNomenclatureList}
                                   />
                            </Form.Group>

                        </Col>
                    </Row>
                    {param.id ? (
                        <>
                            <Row className={'mb-2 mt-3'}>
                                <Col>
                                    <div className={'mb-2'}>
                                        <div
                                            className='d-flex justify-content-between align-items-center text-secondary'>
                                            <div className='d-flex align-items-center'>
                                                <div style={{whiteSpace: 'nowrap'}}>                     
                                                {word !== "edit" && (
                                                <h4 ref={employeesSectionRef} className={'me-3 text-secondary'} id="employeesSection">
                                                    {strings.employeesOnProject}
                                                 </h4>
                                                )}
                                                </div>
                                            </div>
                                            <hr className={'me-3'}/>
                                            <div
                                                className={`btn text-white text-end p-2 d-flex align-items-center border-radius add-button`}
                                                onClick={() => {
                                                    setAddUserToProjectModal(true);
                                                }}>
                                                <div className={'align-items-center d-flex text-start'}>
                                                    <i className={'material-icons add_button_icon'}>add_box</i>
                                                </div>
                                                <div className={'mx-1'}>
                                                    {strings.add}
                                                </div>
                                            </div>
                                        </div>
                                        <br></br>
                                        {displayedUsers.length === 0 ? (
                                            <h5 className={'text-secondary text-center'}>{strings.noEmployeesOnProject}</h5>
                                        ) : (
                                            <>
                                                <CustomTable data={displayedUsers} columns={columns}/>
                                                <div className='pagination-container d-flex justify-content-center'>
                                                    <ReactPaginate
                                                        previousLabel={<AiOutlineArrowLeft/>}
                                                        nextLabel={<AiOutlineArrowRight/>}
                                                        breakLabel={'...'}
                                                        pageCount={Math.ceil(projectUserList.length / pageSize)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageChange}
                                                        containerClassName={'pagination justify-content-center'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link'}
                                                        activeClassName={'active'}/>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </>) : <></>}
                    <Row xs={12} md={12}>
                        <Form.Group>
                            <div>
                                <div className={'d-flex align-items-center border-radius mb-4'}>
                                    <h4 className={'me-3 text-secondary'}>{strings.files}</h4>
                                    <hr className={'me-3'}/>
                                    <div
                                        className={`btn text-white text-end p-2 d-flex align-items-center border-radius add-button ${
                                            collapseOpen ? 'active' : ''
                                        }`}
                                        onClick={handleCollapseToggle}
                                        aria-expanded={collapseOpen}>
                                        <div className={'align-items-center d-flex text-start'}>
                                            <i className={'material-icons add_button_icon'}>add_box</i>
                                        </div>
                                        <div className={'mx-1'}>
                                            {strings.add}
                                        </div>
                                    </div>
                                </div>
                                <div className={`collapse ${collapseOpen ? 'show' : ''}`} id='collapseExample'>
                                    <div className='add-file-background p-2 card card-body mb-4'>
                                        <Form onSubmit={onFormSubmit} className={'p-2'}>
                                            <div className={'row mb-2'}>
                                            </div>
                                            <div className={'row'}>
                                                <div className={'col-10'}>
                                                    <Form.Group className='mb-2' controlId='exampleForm.ControlInput1'>
                                                        <Form.Label className={'d-flex mb-0'}>
                                                            {strings.description}
                                                        </Form.Label>
                                                        <textarea className={'w-100 form-control free-text'}
                                                                  onChange={handleChange}
                                                                  name={'file_description'}/>
                                                    </Form.Group>
                                                </div>
                                                <div className={'col-2'}>
                                                    <Form.Group className='mb-2' controlId='exampleForm.ControlInput1'>
                                                        <Form.Label
                                                            className={'d-flex'}>{strings.isPrivate}</Form.Label>
                                                        <label className='switchEnabled me-2'>
                                                            <input type='checkbox' name={'isPrivate'}
                                                                   value={formData.isPrivate}
                                                                   checked={formData.isPrivate}
                                                                   disabled={false}
                                                                   onChange={handleChange}/>
                                                            <span className='sliderEnabled round'> </span>
                                                        </label>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className={'row'}>
                                                <div className={'col-12'}>
                                                    <Form.Group className='' controlId='exampleForm.ControlInput1'>
                                                        <Form.Label className={'d-flex mb-0'}>
                                                            {strings.selectDocument}
                                                        </Form.Label>
                                                        <div id='form-file-upload w-75' onDragEnter={handleDrag}>
                                                            <input ref={inputRef} type='file' id='input-file-upload'
                                                                   onChange={handleChangeFile}/>
                                                            <label id='label-file-upload' htmlFor='input-file-upload'
                                                                   className={dragActive ? 'drag-active' : ''}>
                                                                <div className={'text-center pb-4 pt-4'}>
                                                                    <p>{strings.dragAndDropYourFileHereOr}</p>
                                                                    <div
                                                                        className='upload-button mx-auto d-flex align-items-center justify-content-center text-center '><span
                                                                        className={'me-2'}>{strings.uploadFile}</span>
                                                                        <i className={'material-icons'}>cloud_upload</i>
                                                                    </div>
                                                                    <div>{file.name}</div>
                                                                </div>
                                                            </label>
                                                            {dragActive &&
                                                                <div id='drag-file-element' onDragEnter={handleDrag}
                                                                     onDragLeave={handleDrag}
                                                                     onDragOver={handleDrag} onDrop={handleDrop}>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className={'row mb-2'}>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <hr></hr>
                        <Col xs={12} md={2} className={'ms-auto'}>
                            <div className={'d-flex justify-content-end mb-3'}>
                                <div className={'cancel btn text-white m-1'} onClick={handleCancel}>
                                    {strings.cancel}
                                </div>
                                <div>
                                    <button type={'submit'} className={'save btn text-white m-1'}>
                                        {strings.save}
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
                <AddUserToProjectModal showModal={showAddUserToProjectModal}
                                       addUserToUserList={addUserToUserList}
                                       handleClose={handleCloseAddUserToProjectModal} slectedProjectId={param}/>
            </div>
        </div>
    );
};

export default AddOrEditProject;
