import axios from "../../axios/axios";

const EventProposalService = {

    getAllEventProposals: () => {
        return axios.get("/eventProposal/getAll");
    },

    getEventProposalById: (eventProposalId) => {
        return axios.get(`/eventProposal/get/${eventProposalId}`)
    },

    getMyEventProposals: () => {
        return axios.get("/eventProposal/getEventProposalsForLoggedInUser")
    },

    getEventProposalsForUserId: (id) => {
        return axios.get(`/eventProposal/${id}/getEventProposals`)
    },

    getPendingEventProposals: () => {
        return axios.get("/eventProposal/getPendingEventProposals");
    },

    addEventProposal: (name, description) => {
        return axios.post("/eventProposal/add", {
            name,
            description
        })
    },

    editEventProposal: (eventProposalId, name, description) => {
        return axios.put("/eventProposal/update", {
            eventProposalId,
            name,
            description
        })
    },

    deleteEventProposal: (eventProposalId) => {
        return axios.delete(`/eventProposal/delete/${eventProposalId}`);
    },

    denyEventProposal: (eventProposalId) => {
        return axios.post(`/eventProposal/deny/${eventProposalId}`)
    }

}

export default EventProposalService;