import React, { useRef, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { strings } from "../../../localization/Localization";
import Form from "react-bootstrap/Form";
import ApplicationService from "../../../ApplicationService";
import ProjectTechnologies from "../ProjectTechnologies/ProjectTechnologies";
import ProjectTechnologyService from "../../../repository/projectTechnologyRepository/projectTechnologyRepository";
import ProjectFileService from "../../../repository/projectFileRepository/projectFileRepository";
import ProjectFile from "../ProjectFile/ProjectFile";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import ProjectService from "../../../repository/projectRepository/projectRepository";
import projectUserRepository from "../../../repository/projectsRepository/projectUserRepository";
import CustomTable from "../../../components/customTable/CustomTableComponent";
import FormatDate from "../../../components/formatDate/FormatDate";
import AddButton from "../../../components/addButton/AddButton";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const ProjectsDetails = (props) => {
  const [projectTechnologies, setProjectTechnologies] = useState([]);
  const [projectUserList, setProjectUserList] = useState([]);
  const [projectFiles, setProjectFiles] = useState([]);
  const history = useHistory();
  const employeesOnProjectRef = useRef(null);

  const redirectToEditProject = () => {
    history.push("/projects/" + props.selectedProjectForEdit?.id, {
      word: "edit",
    });
  };
  const redirectToAddEmployee = () => {
    const projectId = props.selectedProjectForEdit?.id;
    history.push(`/projects/${projectId}#employeesSection`, { word: "add" });
  };

  const columns = [
    {
      key: "userName",
      header: strings.name || "Name",
    },
    {
      key: "userEmail",
      header: strings.email || "Email",
    },
    {
      key: "roles",
      header: strings.role || "Roles",
      render: (roles) => roles.map((role) => role.name).join(", "),
    },
    {
      key: "technologies",
      header: strings.technologies || "Technologies",
      render: (tech) => tech.map((t) => t.name).join(", "),
    },
    {
      key: "dateStarted",
      header: strings.dateJoined || "Date Joined",
      render: (date) => FormatDate.formatDate(date),
      minWidth: '120px'
    },
    {
      key: "dateEnded",
      header: strings.dateLeft || "Date Left",
      render: (date) => FormatDate.formatDate(date),
    },
  ];

  useEffect(() => {
    if (props.showModal) {
      getAllProjectUsers();
      getAllProjectTechnologies();
      getAllFiles();
    } else {
      setProjectUserList([]);
    }
  }, [props.showModal]);

  useEffect(() => {
    // Scroll to the target element when the component mounts
    if (employeesOnProjectRef.current) {
      employeesOnProjectRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const getAllProjectTechnologies = () => {
    ProjectTechnologyService.getAllTechnologiesForProject(
      props.projectDetails.id
    )
      .then((response) => {
        setProjectTechnologies(response.data);
      })
      .catch((error) => {
        console.error("Error fetching project technologies:", error);
      });
  };

  const getAllProjectUsers = () => {
    projectUserRepository
      .getProjectUserByProjectId(props.projectDetails.id)
      .then((response) => {
        setProjectUserList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  };

  const getAllFiles = () => {
    ProjectFileService.getAllFilesForProject(props.projectDetails.id)
      .then((response) => {
        setProjectFiles(response.data);
      })
      .catch((error) => {
        console.error("Error fetching project files:", error);
      });
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const ConfirmationDelete = (id) => {
    swal(strings.deleteConfirmation, {
      title: strings.areYouSure,
      icon: "warning",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteProject(id);
      }
    });
  };

  const deleteProject = (id) => {
    ProjectService.deleteProject(id)
      .then(() => {
        successfulAlert();
        props.getAllProjects();
        props.handleClose();
      })
      .catch(() => {
        warningAlert();
      });
  };

  return (
    <Modal
      show={props.showModal}
      dialogAs={DraggableModalDialog}
      onHide={props.handleClose}
      size={"lg"}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <div>
            <b> {props.projectDetails.name}</b> -{" "}
            {ApplicationService.getActiveLanguage() === "mk"
              ? props.projectDetails.typeNomenclatureEntry?.labelMk
              : props.projectDetails.typeNomenclatureEntry?.labelEn}
          </div>
          <div className={"mb-0 small"}>
            Project group: <i>{props.projectDetails.group?.name}</i>
          </div>
        </Modal.Title>
        <div>
          {props.projectDetails.isActive ? (
            <span className="badge text-bg-success ms-3">{strings.Active}</span>
          ) : (
            <span className="badge text-bg-danger">{strings.notActive}</span>
          )}
        </div>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <div>
            <div className={"d-flex"}>
              <div className={"d-flex"}>
                <p className={"me-4"}>
                  <i>{strings.dateStarted}:</i>{" "}
                  {FormatDate.formatDate(props.projectDetails.dateBeginning)}
                </p>
                <p>
                  <i>{strings.dateEnded}:</i>{" "}
                  {FormatDate.formatDate(props.projectDetails.dateEnding)}
                </p>
              </div>
              <div className={"ms-auto"}>
                {ApplicationService.hasRole([
                  "ROLE_ADMINISTRATOR",
                  "ROLE_HR",
                ]) && (
                  <div className={"icon-div mx-2"}>
                    <div
                      onClick={redirectToEditProject}
                      title={"Edit"}
                      className={"text-dark icon"}
                    >
                      <i className={"edit-icon-circle material-icons "}>
                        create
                      </i>
                    </div>
                  </div>
                )}
              </div>
              <div className={"icon-div"}>
                <a title={"Delete"}>
                  <div
                    onClick={() =>
                      ConfirmationDelete(props.selectedProjectForEdit?.id)
                    }
                    title={"Delete"}
                    className={"text-dark icon"}
                  >
                    <i className={"material-icons delete-icon-circle"}>
                      delete
                    </i>
                  </div>
                </a>
              </div>
            </div>
            <p>{props.projectDetails.description}</p>
            <div className={"d-flex"}>
              {projectTechnologies.map((projectTechnology) => (
                <ProjectTechnologies technology={projectTechnology} />
              ))}
            </div>
            <div className="mt-2" style={{ overflowX: "auto" }}>
              <div className={"d-flex mb-2"}>
                <h5>
                  <i>{strings.employeesOnProject}:</i>
                </h5>
                
                  <div className={"ms-auto"}>
                    <Link to="#" onClick={redirectToAddEmployee}>
                      <div
                        className={
                          "btn text-white text-end d-flex align-items-center border-radius px-xs-0  add-button"
                        }
                        onClick={redirectToAddEmployee}
                      >
                        <div className={"align-items-center d-flex text-start"}>
                          <i className={"material-icons add_button_icon"}>
                            add_box
                          </i>
                        </div>
                        <div className={"mx-1"}>
                          {strings.addEmployeeToProject}
                        </div>
                      </div>
                    </Link>
                 
                </div>
              </div>
              {projectUserList.length === 0 ? (
                <h5 className={"text-secondary text-center"}>
                  {" "}
                  {strings.noEmployeesOnProject}
                </h5>
              ) : (
                <>
                  <CustomTable data={projectUserList} columns={columns} />
                </>
              )}
            </div>

            <div className={"mt-2"}>
              <h5>
                <i>{strings.projectFiles}:</i>
              </h5>
              <ProjectFile files={projectFiles} getAllFiles={getAllFiles} />
            </div>
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
};
export default ProjectsDetails;
