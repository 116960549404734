import React, {useEffect, useState} from "react"
import {CgBell} from "react-icons/cg";
import {RiMailOpenLine, RiMailSendLine} from "react-icons/ri"
import './Notifications.css';
import NotificationRepository from "../../repository/notificationRepository/NotificationRepository";
import {Link} from "react-router-dom";
import {strings} from "../../localization/Localization";
import ApplicationService from "../../ApplicationService";


const Notifications = () => {

    const [hovered, setHovered] = useState(false);

    const [allNotification, setAllNotification] = useState([]);

    const [activeNotificationNumber, setActiveNotificationNumber] = useState();

    const scrollClass = hovered ? 'hover-animation' : '';

    const handleHover = () => setHovered((prevState) => !prevState)


    useEffect(() => {
        const interval = setInterval(() => {
            getAllNotificationForUser();
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        getAllNotificationForUser();
    }, []);

    const getAllNotificationForUser = () => {
        NotificationRepository.fetchAllNotificationForLoggedUser()
            .then((data) => {
                setActiveNotificationNumber(data.data.filter((n) => n.active === true).length)
                setAllNotification(data.data)
            })
    };

    const setPassive = (id) => {
        NotificationRepository.setReadNotification(id)
            .then(() => {
                NotificationRepository.fetchAllNotificationForLoggedUser()
                    .then((data) => {
                        setActiveNotificationNumber(data.data.filter((n) => n.active === true).length)
                        setAllNotification(data.data)
                    })
            })
    }

    return (
        <div className="dropdown text-end w-50 h-50 ps-3">
            <a className={`btn d-flex  justify-content-center  btn-secondary icon-button`}
               href="src/components/Notifications/Notifications#"
               role="button" id="dropdownMenuLink"
               data-bs-toggle="dropdown" aria-expanded="false"
               onMouseOver={handleHover}
               onMouseOut={handleHover}
            >
                <i className={"text-dark d-flex align-items-end notification-icon"}>
                    <CgBell size={32}/>
                </i>

                {activeNotificationNumber !== 0 &&
                    <div
                        className={`badge ${scrollClass}  icon-button__badge notification-number d-flex`}>{activeNotificationNumber}
                    </div>
                }

            </a>

            <ul className={`dropdown-menu dropdownlistz drop_down_list`} aria-labelledby="dropdownMenuLink">
                <div className={"d-flex notification_title my-1"}>
                    <h5 className={"ms-2  d-flex align-items-center"}><i
                        className={"material-icons me-2"}>notifications_none</i><b>{strings.notifications}</b></h5>
                </div>
                <hr className={"d-flex my-0 text-dark text-bg-dark"}/>
                {/*<h5 className={"ms-3"}>News</h5>*/}
                {/*<hr className={"my-0"}/>*/}
                {allNotification.map((n) => {
                    return (

                        <li className={"border-bottom-1 d-flex align-items-center notification_list_element "}
                            onClick={() => {
                                setPassive(n.id)
                            }}
                            key={n.id}>
                            {(n.name === "New" || n.name === "Corrected") ?
                                (<Link to={{pathname: "/arrivedRequest", state: {"LeaveRequest": n.leaveReqeustId}}}>
                                    <div className={"w-100 ps-4 d-flex align-items-center "}>
                                        {n.active ?
                                            (<div className={"flex text-dark"}><RiMailSendLine/></div>)
                                            : (<div className={"text-secondary d-flex"}><RiMailOpenLine/></div>)
                                        }
                                        <>
                                            {ApplicationService.getActiveLanguage() == "mk" ?
                                                (
                                                    n.active ? (
                                                        <div className={"text-dark"}><b>{n.label_mk}</b></div>) : (
                                                        <div className={"text-secondary"}>{n.label_mk}</div>)
                                                )
                                                : (
                                                    n.active ? (
                                                        <div className={"text-dark"}><b>{n.label_en}</b></div>) : (
                                                        <div className={"text-secondary"}>{n.label_en}</div>)
                                                )
                                            }
                                        </>
                                    </div>
                                </Link>)
                                :


                                (<Link to={{pathname: `/leaveRequests`, state: {"LeaveRequest": n.leaveReqeustId}}} >
                                    <div className={"w-100  ps-4 d-flex align-items-center "}>
                                        {n.active ?
                                            (< div className={"flex text-dark"}><RiMailSendLine/></div>)
                                            : (<div className={"text-secondary d-flex"}><RiMailOpenLine/></div>)
                                        }
                                        <>
                                            {ApplicationService.getActiveLanguage() == "mk" ?
                                                (
                                                    n.active ? (
                                                        <div className={"text-dark"}><b>{n.label_mk}</b></div>) : (
                                                        <div className={"text-secondary"}>{n.label_mk}</div>)
                                                )
                                                : (
                                                    n.active ? (
                                                        <div className={"text-dark"}><b>{n.label_en}</b></div>) : (
                                                        <div className={"text-secondary"}>{n.label_en}</div>)
                                                )
                                            }
                                        </>
                                    </div>
                                </Link>)
                            }


                        </li>
                    )
                })
                }

                {/*{allNotification.map((n) => {*/}
                {/*    return (*/}
                {/*        (n.name !== "New" && n.name !== "Corrected") &&*/}
                {/*        <li className={"border-bottom-1 d-flex align-items-center notification_list_element "}*/}
                {/*            onClick={() => {*/}
                {/*                setPassive(n.id)*/}
                {/*            }}*/}
                {/*            key={n.id}>*/}
                {/*         */}
                {/*        </li>*/}
                {/*    )*/}
                {/*})*/}
                {/*}*/}
                <h4 className={"w-100 text-center mt-3"}>
                    <i className={"text-secondary no-notification-message"}>{strings.notNotificationsPresent}</i>
                </h4>
            </ul>
        </div>
    )
        ;
}

export default Notifications;
