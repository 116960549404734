import axios from "../../axios/axios";

const ProjectFileService = {
    getAllFiles: () => {
        return axios.get("/file/getAll")
    },

    getAllFilesForProject: (id) => {
        return axios.get(`/file/getAllFilesForProject/${id}`)
    },

    addFile: (file) => {
        return axios.post(`/file/addFile/`)
        return axios({
            method: "post",
            url: 'rest/file/addFile',
            data: file,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        })
    },

    getAllDataOfFile: (id) =>{
        return axios.get(`/file/getAllDataOfFile/${id}`)
    },

    deleteFile: (id) => {
        return axios.delete(`/file/deleteFile/${id}`);
    },
}
export default ProjectFileService;
