export const en_strings = {
    signIn: "Sign In",
    groupName: "Group Name",
    projectName: "Project Name",
    selectProjectName: "Select a project name.",
    projectIsActive: "Active",
    projectIsInactive: "Inactive",
    myProjects: "My Projects",
    projectStatus: "Project status",
    email: "E-mail",
    password: "Password",
    embg: "EMBG",
    profile: "Profile",
    logOut: "Log out",
    name: "Name",
    lastName: "Surname",
    fatherName: "Father name",
    phoneNumber: "Phone Number",
    professionalPhoneNumber: "Official Phone Number",
    privateEmail: "Private e-mail",
    address: "Address",
    roles: "Role/s",
    addevent: "Add Event",
    week: "Week",
    month: "Month",
    calendar: "Calendar",
    dateselected: "Selected Date",
    UserList: "User list",
    addNewUser: "Add new user",
    editUser: "Edit user",
    close: "Close",
    cancel:"Cancel",
    save: "Save",
    back: "Go Back",
    register: "Register",
    forgotPassword: "Forgot password?",
    resetPassword: "Reset password",
    saveProfile: "Save Profile",
    ethnicityList: "Ethnicity List",
    ethnicityName: "Ethnicity",
    labelMk:"Macedonian",
    labelEn:"English",
    labelSq:"Albanian",
    addNewEthnicity:"Add New Ethnicity",
    editEthnicity:"Edit Ethnicity",
    registrationFailed:"Registration failed, try again",
    registrationSuccessful:"Registration successful, click here to be redirected back to the Login page",
    backToLogin:"Back to login",
    accessRestrictedText:"The page you're trying to access has restricted access.",
    referToSystemAdministrator:" Please refer to your system administrator",
    wrongCredentials:"Wrong E-Mail or Password!",
    organizationalUnit:"Organizational unit",
    religionList:"Religion list",
    addNewReligion:"Add new religion",
    editReligion:"Edit religion",
    minimizeThisMenu:"Minimize This Menu",
    administration:"Administration",
    users:"Users",
    religion:"Religion",
    ethnicity:"Ethnicity",
    ethnicityGroup: "Ethnicity Group",
    organisation :"Organisation",
    leaveRequest:"Leave request",
    dateFrom: "Date From",
    dateTo: "Date To",
    dateOfIssue: "Date of issue",
    dateOfExpiry: "Date of expiry",
    issuedByHelper: "Issued by",
    totalDays: "Total Days",
    correctedWorkDays: "Corrected Work Days",
    leaveType: "Leave Type",
    leaveRequestStatus: "Status",
    requestALeave: "File a Leave Request",
    description: "Description",
    day: "Enter number of days...",
    sendRequest: "Send request",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "october",
    november: "november",
    december: "december",
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    addHoliday:"Add Holiday",
    editHoliday:"Edit Holiday",
    delete:"Delete",
    holiday:"Holiday",
    holidays:"Holidays",
    calendarOfHolidays:"Calendar of holidays",
    evidention:"Evidention",
    statuscalendar:"Status Calendar",
    userevidention:"Evidention of employees",
    EmployeeList: "Employee Evidention",
    filter: "Filter",
    absenceCalendar:"Absence calendar",
    noHolidaysForSelectedDate: "There are no holidays for the selected date",
    home: "Home",
    successfulReligion: "The religion was succesfully added!",
    somethingWrong: "A problem occurred while performing the desired action!",
    fileMightAlreadyExist: "Please provide a unique file name for every user file.",
    oops: "Error...",
    fileIsSafe: "The file is safe!",
    deleteConfirmation: "Do you really want to delete it?",
    deleteConfirmationNoQuestionMark: "Are you sure you want to delete the user:",
    areYouSure: "Are you sure?",
    deletedForever: "Will be deleted forever.",
    absence:"Absence",
    type:"Type",
    nationalType: "National type",
    religionType: "Religious type",
    ethnicityType: "Ethnic type",
    remainingDays: "Remaining days",
    forYear: "Year",
    allowedDays: "Allowed Days",
    calendarHistory: "Calendar History",
    weAreSorry:"We are Sorry...",
    userForHoliday:"User for this holiday",
    otherUser:"Other Users",
    noUserForSelectedHoliday:"No user for selected holiday",
    noOtherUser:"No other user",
    parentName:"Parent name",
    recentActivity: "Recent activity",
    enterDescription: "Enter Description...",
    tasks:"Tasks",
    organizationalHierarchy: "Organizations",
    hierarchy:"Hierarchy",
    selectedHierarchy:"Selected Hierarchy",
    selectedItemHierarchy:"Selected Item Information From Hierarchy",
    yes:"Yes",
    no:"No",
    holidayRecurring:"The holiday recurs every year",
    enterValidateDate:"Enter Validate date",
    reasonsForLeaveRequest:"Reasons for leave request",
    add: "Add",
    edit: "Edit",
    copyCalendar:"Copy calendar",
    editLeaveRequest:"Edit leave request",
    approveRequest:"Approve request",
    returnRequest:"Return for correction",
    declineRequest: "Decline request",
    responseDescription:"Response description",
    myRequest:"My request",
    deliverRequest:"Delivered request",
    downloadFile:"Download file",
    enterDate:"Enter date",
    year:"Year",
    download:"Download",
    saveChange:"Save change",
    responseOnRequest:"Respond to request",
    responseIsSent:"Response is sent",
    fileDownloaded:"File downloaded",
    shortName: "Short Name",
    activeFrom:"Active From",
    activeTo:"Active To",
    unknown:"Unknown",
    created:"Created",
    modified:"Modified",
    code:"Code",
    hierarchyName:"Hierarchy Name",
    hierarchyDescription:"Hierarchy Description",
    hierarchyCode:"Hierarchy Code",
    hierarchyShortName:"Hierarchy Short Name",
    addNewHierarchy: "Add New Hierarchy",
    editHierarchy:"Edit Hierarchy",
    leaveRequests:"Leave requests",
    newRequests:"New requests",
    otherRequests:"Other requests",
    size:"size",
    search:"Search",
    remove:"Remove",
    workingDays:"Working days",
    positionHierarchy:"Hierarchy of positions",
    noMemberInPosition:"There are no members in the position",
    emptyList:"The list is empty",
    membersOfThePosition:"Members of the positions",
    addMembers:"Add members",
    allowedAbsence:"Allowed absence",
    usedDays:"Used days",
    allowedAbsenceReview:"Allowed absence review",
    vacation:"Vacation",
    absenceByReasons:"Absence by reasons",
    hrmsIdNumber: "Identification number",
    organisationHierarchy:"Organizational Hierarchy",
    organisationList: "Organisation list",
    organisations: "Organisations",
    selectOrganisation: "Select organisation",
    noOrganisationsToSelectFrom: "There are no organisations to select from. Add initial organisation.",
    fromYear: "From year",
    toYear: "To year",
    editMember: "Edit members",
    requestsAwaitingResponse:"Requests awaiting response",
    currentYear:"Current year",
    remainingYears:"Remaining years",
    addInitialHierarchy:"Add initial hierarchy",
    selectHierarchy:"Select hierarchy",
    usersToAddToPosition:"Members to add to position",
    usersToRemoveFromPosition:"Members to remove from position",
    editMemberInHierarchy:"Edit member in hierarchy",
    confirmRemoveUserFromHierarchy:"Do you really want to remove ",
    confirmRemoveUserFromHierarchyMemberNumber:"member from the hierarchy?",
    confirmAddUserToHierarchy:"Do you really want to add ",
    confirmAddUserToHierarchyMemberNumber:"member to the hierarchy?",
    theMailFurtherUsedForLoggingIntoTheSystem:"the e-mail is further used for logging into the system!",
    nationalHoliday:"National holiday",
    ethnicityHoliday:"Ethnicity holiday",
    religionHoliday:"Religion holiday",
    religionAndNationalHoliday:"Religion and national holiday",
    removeMembers:"Remove members",
    confirmRemoveUserFromHoliday:"Do you really want to remove  ",
    confirmRemoveUserFromHolidayMemberNumber:"member from the holiday?",
    confirmAddUserToHoliday:"Do you really want to add ",
    confirmAddUserToHolidayMemberNumber:"member to the holiday?",
    absencesByHoliday:"Absences by holiday",
    notifications:"Notifications",
    position:"Position",
    positions:"Positions",
    organization:"Organisation",
    absencesByDayOff:"Absences by day off",
    viewRemainingAbsence:"View remaining absence",
    emptyHierarchyList:"The list of hierarchy is empty",
    usersToAddToHoliday:"Members for add to holiday",
    usersToRemoveFromHoliday:"Members for delete from holiday",
    settings:"Settings",
    enableHierarchyEditing:"Enable hierarchy editing",
    doYouReallyWantToDelete:"Are you sure you want to delete the holiday:",
    thisHolidayCannotBeRestored:"This holiday will not be able to be returned to the system.",
    holidayIsDeleted:"Holiday  is deleted!",
    actionSuccessful:"Your action is successful",
    doYouWantToSelectADeputy:"Do you want to select a deputy",
    selectDeputy:"Select a deputy",
    noAbsenceForSelectedDate:"No absence for selected date",
    all:"All",
    vacationn:"Vacation",
    thisUserCannotBeRestored:"This user will not be able to return to the system.",
    userIsDeleted:"User is deleted!",
    welcomeToSystemTitle:"Welcome to the Inteligenta Absence Management System",
    ethnicityDeleted:"Ethnicity is deleted.",
    fieldsRequired:"Please fill in all required fields.",
    required:"Required!",
    userAddSuccessful:"User added successfully",
    religionDeleted:"Religion is deleted.",
    enableSync:"Enable sync",
    accountNotRegistered:"That account is not registered",
    passwordChangeMessageSuccessfully:"A password change message has been successfully sent to your email.",
    send:"Send",
    enterApprovalStatusRequired:"Please enter an approval status and description.",
    emptyNotificationList:"The notification list is empty",
    AbsenceByHoliday:"Absence by holiday",
    workPosition:"Work place",
    noInfo:"No information",
    duplicateLeaveRequestException:"You already have that period leave request!",
    noAvailableDays:"No available days!",
    noAvailableDaysForThisYear:"No available days for this year!",
    noEnoughAvailableDays:"You don't have enough days available!",
    holidaysSuccessfullyCreated:"The holiday has been successfully created. Continue to add users for the created holiday.",
    holidaysSuccessfullyEdited:"Holiday modified successfully. Continue to add users for the created holiday.",
    reset:"Reset",
    positionForOrganisation:"Positions for the selected organization",
    noPositionForSelectedOrganisation:"There are no positions for the selected organization",
    ok:"OK",
    selected:"Selected",
    userEditSuccessful:"The change is successful. ",
    alreadyHaveSickLeaveDuring:"You already have sick leave during that period",
    userAlreadyHaveSickLeaveDuring:"User already have sick leave during that period",
    defaultDescriptionForHavingSickLeaveDuring:"Your request cannot be accepted because you are on sick leave during that period.",
    changePassword:"Change password",
    newPassword:"New Password",
    oldPassword:"Old Password",
    confirmNewPassword:"Confirm new password",
    enterNewPassword:"Enter new password...",
    enterOldPassword:"Enter old password...",
    savePassword:"Save password",
    passwordsMatch:"Passwords match",
    passwordsDoNotMatch:"Password do not match",
    passwordsConfirmationDoNotMatch:"Your password confirmation did not match your password!",
    passwordChangeSuccessful:"Password change successful!",
    incorrectPassword:"Your password is incorrect!",
    noInfoForSelectedLeaveType:"There is no data for selected leave type",
    blockUnblockUser:"Block/Unblock user",
    contact:"Contact",
    parent:"Parent",
    personalData:"Personal data",
    professionalData:"Professional data",
    gender:"Gender",
    male: "Male",
    female: "Female",
    activeAddress:"Active address",
    secondAddress:"Second address",
    city: "City",
    municipality: "Municipality",
    settlement: "Settlement",
    typesOfAbsences:"Types of absences",
    editLeaveType:"Edit leave type",
    baseDays:"Base Days",
    editLeaveReasons:"Edit leave reasons",
    active:"Active",
    thisLeaveReasons:"This leave reasons",
    alreadyExistRequestForThisReasons:"There is already a leave request for this reason",
    alreadyExistRequestForThisType:"There is already a leave request for this type",
    alreadyExistLeaveReasonForThisType: "There is a leave reason for this type.",
    addLeaveType:"Add leave type",
    addLeaveReasons:"Add leave reasons",
    addOrganisation:"Add new organisation",
    addNewOrganisationFromParent: "Add new organisation from the parent ",
    editOrganisation:"Edit organisation",
    organisationName:"Organisation name",
    organisationShortName:"Organisation short name",
    organisationDescription:"Organisation description",
    organisationCode:"Organisation code",
    parentOrganisation: "Parent organisation",
    addPosition:"Add position",
    addPositionFromParent: "Add position from the parent ",
    parentPosition: "Parent position",
    editPosition:"Edit position",
    positionName:"Position name",
    positionShortName:"Position short name",
    positionDescription:"Position description",
    positionCode:"Position code",
    birthDate:"Birth date",
    date:"Date",
    dayDate:"Day",
    organizationApplies:"Organization to which it applies",
    addDocument:"Add document",
    documentType:"Document type",
    documentLanguage:"Document language",
    selectDocument:"Select document",
    documentDate:"Document date",
    chooseFile:"Choose file",
    cv:"CV",
    document:"File",
    certificate:"Certificate",
    certificates:"Certificates",
    personalDocuments:"Personal documents",
    documentNumber:"Document number",
    addPersonalDocument:"Add personal document",
    issuedBy:"Issued by",
    nomenclature:"Nomenclature",
    selectNomenclature:"Select nomenclature",
    value:"Value",
    addNomenclatureEntry:"Add nomenclature entry",
    editNomenclatureEntry:"Edit nomenclature entry",
    addNomenclature:"Add nomenclature",
    editNomenclature:"Edit nomenclature",
    nomenclatureName:"Nomenclature name",
    uploadFile:"Upload a file",
    dragAndDropYourFileHereOr:"Drag and drop your file here or",
    education: "Education",
    institution:"Institution",
    direction:"Direction",
    acknowledged:"Acknowledged",
    addEducation:"Add education",
    editEducation:"Edit education",
    startDate:"Start date",
    endDate:"End date",
    educationLevel:"Level of education",
    youHaveNoDaysLeftDueToPendingRequests:"You have no days left due to pending requests",
    present:"Present",
    identityCard:"Identity card",
    passport:"Passport",
    dateCreated:"Date created",
    actionListEmpty:"The action list is empty",
    actionHistory:"Action history for the request",
    leaveReason:"Leave reason",
    area:"Area",
    bank: "Bank",
    bankAccount: "Account number",
    bankAccountInfo: "Bank account information",
    bankFieldRequired: "Please fill the Bank field",
    key: "Office key",
    accountNumber: "Account number",
    office: "Office",
    employmentData:"Employment status",
    addUserEmployment:"Add employment status",
    editUserEmployment: "Edit employment status",
    engagementType:"Engagement type",
    contractType:"Contract type",
    employmentType:"Employment Type",
    internal:"Internal",
    external:"External",
    intern:"Intern",
    definite:"Definite",
    indefinite:"Indefinite",
    fullTime:"Full time",
    partTime:"Part time",
    hours:"Hours",
    hoursDailyEngagement:"Hours of daily engagement",
    noOptionsCurrentlyPresent:"No options currently present",
    notNotificationsPresent:"No notifications present",
    events: "Events",
    allEvents: "All events",
    eventsList: "Events list",
    capacity: "Capacity",
    activeEvents: "Active events",
    inactiveEvents: "Inactive events",
    inactiveEventMessage: "Inactive",
    addEvent: "Add event",
    eventType: "Event type",
    status: "Status",
    cost: "Cost",
    currency: "Currency",
    trainer: "Trainer",
    dollars: "dollars",
    euros: "euros",
    pounds: "pounds",
    denars: "denars",
    activeEvent: "Active",
    inactiveEvent: "Inactive",
    groups: "Groups",
    eventDetails: "Event details",
    noGroupsAvailable: "No event groups available",
    editTheEvent: "Edit the event",
    workExperience:"Work experience",
    dateRegistered:"Certification",
    workExperiencePosition:"Work position",
    duration:"Duration",
    durationIn:"Duration in",
    years:"Years",
    months:"Months",
    fromOurCompany:"From our company",
    inOurCompany: "in our company",
    addWorkExperience:"Add work experience",
    editWorkExperience:"Edit work experience",
    companyName:"Company name",
    total:"Total",
    projects: "Projects",
    allProjectsList: "All projects List",
    notActive: "not active",
    Active: "active",
    dontHaveProjects: "You don't have any projects assigned",
    dateStarted: "Date started",
    dateEnded: "Date ended",
    details: "Details",
    technologies: "Technologies",
    EmptyProjectsList: "Empty projects list",
    projectDetails: "Project details",
    files: "Files",
    dateModified: "Date modified",
    isPrivate: "Is private",
    fileType: "File type",
    fromInteligenta:"From Inteligenta",
    selectDate:"Select date",
    totalExperienceUntil:"Total experience until",
    days:"Days",
    overallTotalExperienceUntil:"Overall total experience until",
    inteligentaTotalExperienceUntil:"Total experience in Inteligenta until",
    started:"Started",
    ended:"Ended",
    calculateExperiece:"Calculate working experience",
    employeesOnProject: "Employees on project",
    fileName: "File name",
    projectType: "Project type",
    dateBeginning: "Date beginning",
    dateEnding: "Date ending",
    projectGroup: "Project group",
    projectTechnology: "Project technologies",
    addProject: "Add project",
    editProject: "Edit project",
    isActive: "Active",
    location: "Location",
    addEventGroup: "Add event group",
    attend: "Attend",
    attendConfirmation: "Do you want to attend in this group?",
    attendCancelConfirmation: "Do you want to cancel attendance?",
    emptyEventGroup: "No participants for now",
    usersAttending: "Attending: ",
    vacancies: "Vacancies: ",
    listOfParticipants: "List of participants",
    statusPlaceholder: "Status value is based on the end date",
    noEvents: "There are no events",
    noInactiveEvents: "There are no inactive events",
    noActiveEvents: "There are no active events",
    proposalName: "Proposal name",
    eventProposals: "Event proposals",
    noEventProposals: "No event proposals",
    describeProposal: "Please describe shortly your training idea. You can recommend trainers, platforms, working hours etc...",
    userEventProposal: "From user: ",
    accepted: "Accepted",
    notAccepted: "Not accepted",
    pending: "Pending",
    addEventProposal: "Add event proposal",
    myEventProposals: "My event proposals",
    noMyEventProposals: "There are no event proposals from",
    acceptEventProposal: "Accept event proposal",
    eventProposalFrom: "Event proposal from: ",
    accept: "Accept",
    deny: "Deny",
    attendedEvents: "Attended events",
    userEventProposals: "Event proposals from ",
    eventsAttendedFromUser: "Events attended from ",
    myAttendedEvents: "My attended events",
    noFilesInEvent: "There are no files for this event",
    groupList: "Group list",
    projectsInGroup: "Projects in group",
    numberOfProjectsInGroup: "Number of projects",
    addGroup: "Add group",
    editGroup: "Edit group",
    filterName: "Filter by name ...",
    filterParent: "Filter by parent name ...",
    filterSurname: "Filter by surname ...",
    filterIdentificationNumber: "Filter by identification number ...",
    filterEmail: "Exampe@gmail.com",
    userProjects: "Projects for ",
    myProjectsList: "My Projects",
    benefits:"Benefits",
    benefitsList:"Benefits list",
    readMore:"Read more",
    readLess:"Read less",
    inactive:"Inactive",
    editUserBenefit: "Edit benefit",
    addUserBenefit: "Add benefit",
    benefitDetails: "Benefit details",
    benefitUsersList: "List of users of the benefit",
    chooseUser:"Choose user",
    benefit:"Benefit",
    addUserToBenefit:"Add user to benefit",
    myBenefits: "My benefits",
    userBenefits: "Benefits for ",
    noBenefits: "There are no benefits",
    eventSkills: "Skills acquired through training",
    closeAddUserToBenefit:"Close the add section",
    pleaseEnterLeaveType:'Please enter a leave type!',
    codeLimitMessage: "The code must consist exclusively of uppercase letters, numbers, and symbols, with no spaces permitted.",
    listOfLeaveTypeIsEmptyForAddLeaveReasons:'The leave type list is empty. To add leave reasons, please add an leave type',
    timeTracking: "Time Tracking",
    myTimeTracking: "My Activity",
    allUsersTimeTracking: "All Users Activity",
    enterTime: " Enter Time",
    Hours: "Hours",
    Minutes: "Minutes",
    enterHours: "Enter Hours",
    enterMinutes: "Enter Minutes",
    addTimeRecord: "Add Time Record",
    editTimeRecord: "Edit Time Record",
    deleteConfirmationTimeRecord: "Do you really want to delete this time record",
    timeRecordDeleted: "Time record deleted",
    addEmployee:"Аdd Employee",
    addEmployeeToProject: "Аdd еmployee to project",
    selectEmployee: "Select an employee",
    employee: "Employee",
    noEmployeesOnProject: "No employees on project",
    projectCreatedSuccessfully: " The project was created successfully",
    showInactive: "Show inactive as well",
    projectAddedInfo: "If you want to continue to add employees on the project continue to the edit page",
    isOvertime: "Overtime",
    feedback: "Feedback",
    title: "Title",
    addFeedback: "Add Feedback",
    deleteConfirmationFeedback:"Do you really want to delete feedback",
    feedbackDeleted: "Feedback deleted",
    feedbackType: " Feedback Type ",
    feedbackPraise: "Praise",
    feedbackComplain: "Complain",
    feedbackProposal: "Proposal",
    equipment: "Equipment",
    myEquipment: "My Equipment",
    allEquipment: "All Equipment",
    feedbackOther: "Other",
    addEquipment: "Add Equipment",
    marks: "Marks",
    addBenefit: "Add Benefit",
    addBenefitProposal: "Add Benefit Proposal",
    allBenefits: "All Benefits",
    noBenefitProposals: "No Active Proposals For Benefits",
    myBenefitList: "My Benefits List",
    myBenefitProposals: "My Benefit Proposals",
    explainBenefitProposal: "Please describe shortly your benefit idea. You can recommend parking spot, sport card etc...",
    userComment: "User Comment",
    userCommentNotProvided: "User has not provided a comment yet",
    leaveCommentHere: "Please leave your comment here",
    leaveComment: "Leave your comment here:",
    noUsersOnBenefit: "No employee has this benefit",
    allBenefitProposals: "All Benefit Proposals",
    selectPlaceholder: "Select...",
    addUserToEquipment: "Add User To Equipment",
    addEquipmentProposal: "Request Equipment",
    equipmentProposalName: "Equipment Name",
    equipmentDescription: "Equipment Description",
    equipmentProposals: "Equipment Proposals",
    noActiveEquipmentProposals: "No Active Equipment Proposals",
    equipmentQuantity: "Quantity",
    selectOption: "Select...",
    noOptions: "No options...",
    dateAcquired: " Date Acquired",
    quantuty: "Quantuty",
    setQuantity: "Set Quantity",
    myEquipmentProposals: "My Equipment Proposals",
    addComment: "Add Comment",
    enterYourComment: "Enter your comment",
    noEquipmentEntries: "There are no equipment entries yet",
    equipmentProposalDetails: "Equipment Proposal Details",
    acceptEquipmentConfirmation: "Do you really want to accept this equipment proposal?",
    denyEquipmentConfirmation: "Do you really want to deny this equipment proposal?",
    organizationalStructure: " Organizational Structure",
    positionsHierarchy: "Positions Hierarchy",
    personal: "Personal",
    listOfUsers: "List of Users",
    userAbsenceEvidention: "User Absence Evidention",
    timeTrackings: "Activities",
    allRequiredFieldsNotFilled: "All Required Fields Are Not Filled",
    projectFiles: "Project Files",
    editEmployeesOnProject: "Edit Employees On Project",
    onlyNumbers: "Please enter only numbers",
    emailVerificationWarning: "Please enter valid mail. Ex. example@example.com",
    role: "Role",
    dateJoined: "Date Joined",
    dateLeft: "Date Left",
    groupOfProjects: "Group of projects",
    isForeignCitizen: "Foreign Citizen",
    };