import React from "react"
import HomeNotificationComponent from "../../components/HomeNotificationComponent";
import {strings} from "../../localization/Localization";
import "./Home.css"

const Home = () => {
    return (
        <>

            <div className={"d-flex align-items-end justify-content-center pb-4 mt-4"}>
                <div className={"header_wrapper justify-content-center d-flex"}>
                    <div className={" d-flex me-2"}></div>
                    <div className={"pt-1 d-flex align-items-center ms-2 me-3"}>
                        <h2 className={"home_header"}>{strings.welcomeToSystemTitle}</h2>
                    </div>
                </div>

            </div>
            <div className={"h-100 mt-3"}>
                {/*<div className="container mt-2">*/}
                {/*    <h1>{strings.home}</h1>*/}
                {/*</div>*/}

                <div>
                    <HomeNotificationComponent/>
                    {/*<HomeComponent2/>*/}
                </div>

            </div>
        </>
    );
}
export default Home;