import React, {useEffect, useState} from "react";
import swal from "sweetalert";
import {strings} from "../../../../../localization/Localization";
import UserEmploymentService from "../../../../../repository/UserEmploymentRepository/UserEmploymentRepository";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UserService from "../../../../../repository/userRepository/UserRepository"
import {useParams} from "react-router-dom";
import Select from "react-select";
import NomenclatureService from "../../../../../repository/nomenclatureRepository/NomenclatureRepository";
import ApplicationService from "../../../../../ApplicationService";
import '../EmploymentData.css'
import DraggableModalDialog from "../../../../../components/DraggableModalDialog/DraggableModalDialog";
import UserWorkDay from "../UserWorkDay/UserWorkDay";
import SpinnerComponent from "../../../../../components/Spinner";


const AddUserEmploymentModal = (props) => {

    const [selectedUserId, setSelectedUserId] = useState();
    const [isActive, setIsActive] = useState(false);
    const [openByAdmin, setOpenByAdmin] = useState(false);
    const [selectedUserEmploymentData, setSelectedUserEmploymentData] = useState();
    const param = useParams();
    const [nomenclatureEngagementList, setNomenclatureEngagementList] = useState([]);
    const [nomenclatureContractList, setNomenclatureContractList] = useState([]);
    const [nomenclatureEmploymentList, setNomenclatureEmploymentList] = useState([]);
    const [selectedEngagement, setSelectedEngagement] = useState([]);
    const [selectedContract, setSelectedContract] = useState([]);
    const [selectedEmployment, setSelectedEmployment] = useState([]);
    const [userWorkDays, setUserWorkDays] = useState([]);
    const [loading, setLoading] = useState(false);

    const [formData, updateFormData] = useState({
        engagementNomenclatureEntry: "",
        contractNomenclatureEntry: "",
        employmentNomenclatureEntry: "",
        startDate: "",
        endDate: "",
        workDaysData: ""
    });

    useEffect(() => {
        getAllNomenclatureEntriesForEngagement();
        getAllNomenclatureEntriesForEmployment();
        getAllNomenclatureEntriesForContract();
        if (!props.showModal) {
            resetData();
        } else {
            getUserId()
            if (props.selectedEmploymentForEdit?.userEmploymentId) {
                updateFormData({
                    engagementNomenclatureEntry: {
                        value: props.selectedEmploymentForEdit?.engagementNomenclatureEntryHelper?.id,
                        label: ApplicationService.getActiveLanguage() === "mk"
                            ? props.selectedEmploymentForEdit?.engagementNomenclatureEntryHelper?.labelMk
                            : props.selectedEmploymentForEdit?.engagementNomenclatureEntryHelper?.labelEn
                    },
                    contractNomenclatureEntry: {
                        value: props.selectedEmploymentForEdit?.contractNomenclatureEntryHelper?.id,
                        label: ApplicationService.getActiveLanguage() === "mk"
                            ? props.selectedEmploymentForEdit?.contractNomenclatureEntryHelper?.labelMk
                            : props.selectedEmploymentForEdit?.contractNomenclatureEntryHelper?.labelEn
                    },
                    employmentNomenclatureEntry: {
                        value: props.selectedEmploymentForEdit?.employmentNomenclatureEntryHelper?.id,
                        label: ApplicationService.getActiveLanguage() === "mk"
                            ? props.selectedEmploymentForEdit?.employmentNomenclatureEntryHelper?.labelMk
                            : props.selectedEmploymentForEdit?.employmentNomenclatureEntryHelper?.labelEn
                    },
                    startDate: props.selectedEmploymentForEdit?.startDate,
                    endDate: props.selectedEmploymentForEdit?.endDate,
                    workDaysData: props.selectedEmploymentForEdit?.workDaysData
                });
                if(props.selectedEmploymentForEdit?.workDaysData !=null) {
                    setUserWorkDays(props.selectedEmploymentForEdit?.workDaysData);
                }

            }
        }

    }, [props.showModal, props.selectedEmploymentForEdit]);


    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const warningAlert = () => {
        swal({
            title: strings.oops,
            text: strings.somethingWrong,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const warningAlertFieldsRequired = () => {
        swal({
            title: strings.oops,
            text: strings.fieldsRequired,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const resetData = () => {
        setUserWorkDays([]);
        updateFormData({
            engagementNomenclatureEntry: "",
            contractNomenclatureEntry: "",
            employmentNomenclatureEntry: "",
            startDate: "",
            endDate: "",
            workDaysData: ""
        })
    };

    const handleChange = (e, name) => {
        const value = e.target.value;
        if (e.target.hasAttribute("required") && value.trim() === "") {
          e.target.setCustomValidity(strings.fieldsRequired);
        } else {
          e.target.setCustomValidity("");
        }
        updateFormData({
            ...formData,
            [name]: value
        });

    };
    
    const handleEngagementChange = (selectedOption) => {
        setSelectedEngagement(selectedEngagement);
        updateFormData({
            ...formData,
            engagementNomenclatureEntry: selectedOption
        })
    };

    const getUserId = () => {
        if (param.id) {
            UserService.getUserDetailsById(param.id).then((data) => {
                setSelectedUserId(data.data)
                setOpenByAdmin(false);
                setIsActive(data.data.active);

            }).catch(() => {
            })
        } else {
            UserService.getUserDetails().then((data) => {
                setSelectedUserId(data.data)
                setOpenByAdmin(false);
                setIsActive(data.data.active);

            }).catch(() => {
            })
        }
    }

    const addUserEmployment = (selectedUserId, engagementId, contractId, employmentId, startDate, endDate, workDaysData) => {
        if (param.id === undefined) {
            UserEmploymentService.addUserEmploymentData(selectedUserId.id, engagementId, contractId, employmentId, startDate, endDate, workDaysData).then((data) => {
                props.handleClose();
                successfulAlert();
                props.getEmploymentData();
            }).catch(() => {
                warningAlert();
            })
        } else {
            UserEmploymentService.addUserEmploymentDataByAdmin(selectedUserId.id, engagementId, contractId, employmentId, startDate, endDate, workDaysData).then((data) => {
                props.handleClose();
                successfulAlert();
                props.getEmploymentData();
            }).catch(() => {
                warningAlert();
            })
        }
    };

    const editUserEmployment = (selectedUserId, engagementId, contractId, employmentId, startDate, endDate, workDaysData) => {
        if (param.id === undefined) {
            UserEmploymentService.editUserEmploymentData(props.selectedEmploymentForEdit.userEmploymentId, selectedUserId.id, engagementId, contractId, employmentId, startDate, endDate, workDaysData).then((data) => {
                props.handleClose();
                successfulAlert();
                props.getEmploymentData();
            }).catch(() => {
                warningAlert();
            })
        } else {
            UserEmploymentService.editUserEmploymentDataByAdmin(props.selectedEmploymentForEdit.userEmploymentId, selectedUserId.id, engagementId, contractId, employmentId, startDate, endDate, workDaysData).then((data) => {
                props.handleClose();
                successfulAlert();
                props.getEmploymentData();
            }).catch(() => {
                warningAlert();
            })
        }
    };

    const onFormSubmit = (e) => {
        e.preventDefault();

        if (
            !formData.engagementNomenclatureEntry ||
            !formData.contractNomenclatureEntry ||
            !formData.employmentNomenclatureEntry ||
            !formData.startDate ||
             !formData.endDate

        ) {
            warningAlertFieldsRequired();
            return; 
        }

        const workDaysData = userWorkDays;
        setLoading(true);

        if (props.selectedEmploymentForEdit?.userEmploymentId) {
            editUserEmployment(selectedUserId, formData.engagementNomenclatureEntry.value, formData.contractNomenclatureEntry.value, formData.employmentNomenclatureEntry.value, formData.startDate, formData.endDate, workDaysData);
        } else {
            addUserEmployment(selectedUserId, formData.engagementNomenclatureEntry.value, formData.contractNomenclatureEntry.value, formData.employmentNomenclatureEntry.value, formData.startDate, formData.endDate, workDaysData);
        }

            setLoading(false);
       
    };

    const getAllNomenclatureEntriesForEngagement = () => {
        NomenclatureService.getAllNomenclatureEntryByNomenclatureName("Ангажман")
            .then((data) => {
                let nomenclatureConvert = data.data.map((n) => {
                    return {
                        value: n.id,
                        label: ApplicationService.getActiveLanguage() == "mk" ? n.labelMk : n.labelEn
                    }
                })
                setNomenclatureEngagementList(nomenclatureConvert);
            });
    };

    const getAllNomenclatureEntriesForContract = () => {
        NomenclatureService.getAllNomenclatureEntryByNomenclatureName("Договор")
            .then((data) => {
                let nomenclatureConvert = data.data.map((n) => {
                    return {
                        value: n.id,
                        label: ApplicationService.getActiveLanguage() == "mk" ? n.labelMk : n.labelEn
                    }
                })
                setNomenclatureContractList(nomenclatureConvert);
            });
    };

    const getAllNomenclatureEntriesForEmployment = () => {
        NomenclatureService.getAllNomenclatureEntryByNomenclatureName("Вработување")
            .then((data) => {
                let nomenclatureConvert = data.data.map((n) => {
                    return {
                        value: n.id,
                        label: ApplicationService.getActiveLanguage() == "mk" ? n.labelMk : n.labelEn
                    }
                })
                setNomenclatureEmploymentList(nomenclatureConvert);
            });
    };

    const handleWorkHoursUpdate = (dayId, hours) => {
        const dayIndex = userWorkDays.findIndex(
            (day) => day.workDayNomenclatureEntryId === dayId
        );
        if (dayIndex !== -1) {
            setUserWorkDays(userWorkDays.map((day) => {
                    if (day.workDayNomenclatureEntryId === dayId) {
                        return {
                            workDayNomenclatureEntryId: dayId,
                            workHours: hours
                        }
                    } else {
                        return day
                    }
                })
            )

        } else {
            setUserWorkDays([...userWorkDays, {
                workDayNomenclatureEntryId: dayId,
                workHours: hours
            }])
        }
    };

    return (
        <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={"lg"} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.selectedEmploymentForEdit.userEmploymentId  ? strings.editUserEmployment: strings.addUserEmployment}
                </Modal.Title>
            </Modal.Header>
            <Form
                onSubmit={onFormSubmit}>
                <Modal.Body>
                    <Row className={"mb-2"}>
                        <Col xs={12} md={6} className="mb-2">
                            <Form.Label className={"d-flex"}>
                                {strings.engagementType}<span className="text-danger">*</span>
                            </Form.Label>
                            <Select className="mb-3"
                                    onChange={handleEngagementChange}
                                    required
                                    options={nomenclatureEngagementList}
                                    value={formData.engagementNomenclatureEntry}
                                    placeholder={strings.selectOption}
                                    noOptionsMessage={()=>strings.noOptions}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            height: 40,
                                            border: "1px solid #ccc",
                                            borderRadius: 4
                                            
                                        })
                                    }}/>
                        </Col>
                        <Col xs={12} md={6}>
                            <Col xs={12} md={12} className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.contractType}<span className="text-danger">*</span>
                                </Form.Label>
                                <Select
                                    onChange={(e) => updateFormData({
                                        ...formData,
                                        "contractNomenclatureEntry": e
                                    })}
                                    required
                                    options={nomenclatureContractList}
                                    value={formData.contractNomenclatureEntry}
                                    placeholder={strings.selectOption}
                                    noOptionsMessage={()=>strings.noOptions}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            height: 40,
                                            border: "1px solid #ccc",
                                            borderRadius: 4
                                        }) }}/>
                            </Col>
                        </Col>
                        <Col xs={12} md={12}>
                            <Form.Label className={"d-flex"}>
                                {strings.employmentType}<span className="text-danger">*</span>
                            </Form.Label>
                            <Select className="mb-3"
                                    onChange={(e) => updateFormData({
                                        ...formData,
                                        "employmentNomenclatureEntry": e
                                    })}
                                    required
                                    options={nomenclatureEmploymentList}
                                    value={formData.employmentNomenclatureEntry}
                                    placeholder={strings.selectOption}
                                    noOptionsMessage={()=>strings.noOptions}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            height: 40,
                                            border: "1px solid #ccc",
                                            borderRadius: 4
                                        })
                                    }}/>
                        </Col>
                        {(formData.employmentNomenclatureEntry?.label === 'Part time' || formData.employmentNomenclatureEntry?.label === 'Скратено работно време') && (
                            <Row xs={12} md={5} className={"d-flex justify-content-center mx-auto"}>
                                <UserWorkDay
                                    onWorkHoursUpdate={handleWorkHoursUpdate}
                                    userWorkDays={userWorkDays}
                                    setUserWorkDays={setUserWorkDays}/>
                            </Row>
                        )}
                    </Row>
                    <Row className={"mb-2"}>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.startDate}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type={"date"}
                                    value={formData.startDate}
                                    onChange={(e) => handleChange(e, "startDate")}
                                    required
                                    name={"startDate"}
                                    max={formData.endDate}
                                    onInvalid={(e) => {
                                        e.target.setCustomValidity(strings.fieldsRequired);
                                      }}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className={"d-flex"}>
                                    {strings.endDate}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type={"date"}
                                    value={formData.endDate}
                                    required={formData.contractNomenclatureEntry.label === 'Определено' || formData.contractNomenclatureEntry.label === 'Definite'}
                                    onChange={(e) => handleChange(e, "endDate")}
                                    name={"endDate"}
                                    min={formData.startDate}/>
                                    
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className={"cancel btn text-white"} onClick={props.handleClose}>
                        {strings.cancel}
                    </div>
                    <button type={"submit"} className={"save btn text-white"}>
                        {strings.save}
                    </button>
                    {loading && <SpinnerComponent />}
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
export default AddUserEmploymentModal;