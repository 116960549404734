import React, { useEffect, useState } from "react";
import AddDocumentModal from "../../UserDetails/UserProfessionalData/AddDocumentModal/AddDocumentModal";
import AddButton from "../../../components/addButton/AddButton";
import { strings } from "../../../localization/Localization";
import { HiDownload, HiOutlineDocumentText } from "react-icons/hi";
import NomenclatureService from "../../../repository/nomenclatureRepository/NomenclatureRepository";
import NomenclatureEntryModal from "./NomenclatureEntryModal/NomenclatureEntryModal";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";

const NomenclatureEntryList = (props) => {
  const [nomenclatureEntryList, setNomenclatureEntryList] = useState([]);

  const [
    selectedNomenclatureEntryForEdit,
    setSelectedNomenclatureEntryForEdit,
  ] = useState({});

  const [showNomenclatureEntryModal, setShowNomenclatureEntryModal] =
    useState(false);

  const location = useLocation();

  const benefitNomenclatureEntry = location.state ? location.state : null;
  const [expandedDescription, setExpandedDescription] = useState(null);

  const toggleDescriptionExpansion = (id) => {
    setExpandedDescription((prev) => (prev === id ? null : id));
  };

  const openNomenclatureEntryModal = (nomenclatureEntry) => {
    setSelectedNomenclatureEntryForEdit(nomenclatureEntry.nomenclatureEntry);
    setShowNomenclatureEntryModal(true);
  };

  useEffect(() => {
    if (props.selectedNomenclature.id) {
      getNomenclatureEntryList(props.selectedNomenclature.id);
    } else {
      setNomenclatureEntryList([]);
    }

    if (benefitNomenclatureEntry) {
      openNomenclatureEntryModal(benefitNomenclatureEntry);
    }
  }, [props.selectedNomenclature]);

  const handleShowNomenclatureEntryModal = () => {
    setShowNomenclatureEntryModal(true);
  };

  const handleCloseNomenclatureEntryModal = () => {
    setSelectedNomenclatureEntryForEdit({});
    setShowNomenclatureEntryModal(false);
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: "success",
    });
  };

  const errorAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const ConfirmationDelete = (id) => {
    swal(strings.deleteConfirmation, {
      title: strings.areYouSure,
      icon: "warning",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteNomenclatureEntry(id);
      }
    });
  };

  const getNomenclatureEntryList = (id) => {
    NomenclatureService.getAllNomenclatureEntry(id).then((data) => {
      setNomenclatureEntryList(data.data);
    });
  };

  const deleteNomenclatureEntry = (id) => {
    NomenclatureService.deleteNomenclatureEntry(id)
      .then(() => {
        successfulAlert();
        getNomenclatureEntryList(props.selectedNomenclature.id);
      })
      .catch(() => {
        errorAlert();
      });
  };

  return (
    <div>
      <div className={"d-flex mb-2 justify-content-end"}>
        <AddButton onClickHandle={handleShowNomenclatureEntryModal} />
      </div>
      <div className="table-responsive">
        <NomenclatureEntryModal
          showModal={showNomenclatureEntryModal}
          handleClose={handleCloseNomenclatureEntryModal}
          getNomenclatureEntryData={getNomenclatureEntryList}
          selectedNomenclature={props.selectedNomenclature}
          selectedNoemnclatureEntryForEdit={selectedNomenclatureEntryForEdit}
        />
        <table className="table user-table table-responsive table-borderless text-start">
          <thead className={"custom-table-header text-white"}>
            <tr className={"user-table-header-row"}>
              <th scope="col">{strings.name}</th>
              <th scope="col">{strings.labelMk}</th>
              <th scope="col">{strings.labelEn}</th>
              <th scope="col">{strings.description}</th>
              <th scope="col">{strings.value}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {nomenclatureEntryList.length > 0 ? (
              nomenclatureEntryList.map((data) => {
                return (
                  <tr className={"user-table-body-row"} key={data.id}>
                    <td>{data?.name}</td>
                    <td>{data?.labelMk}</td>
                    <td>{data?.labelEn}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div
                          onClick={() => toggleDescriptionExpansion(data.id)}
                          style={{ cursor: "pointer" }}
                        >
                          {expandedDescription === data.id ? (
                            data?.description
                          ) : data?.description.length > 20 ? (
                            <>
                              {`${data?.description.slice(0, 20)}...`}
                              {expandedDescription !== data.id && (
                                <i
                                  className="material-icons"
                                  style={{ marginLeft: "5px" }}
                                >
                                  expand_more
                                </i>
                              )}
                            </>
                          ) : (
                            data?.description
                          )}
                        </div>
                      </div>
                    </td>
                    <td>{data?.value}</td>
                    <td>
                      <div
                        className={
                          "d-flex align-items-center justify-content-end"
                        }
                      >
                        <div className={"icon-div me-2"}>
                          <a
                            onClick={() => {
                              setSelectedNomenclatureEntryForEdit(data);
                              handleShowNomenclatureEntryModal();
                            }}
                            title={"Edit"}
                            className={"text-dark icon"}
                          >
                            <i className={"edit-icon-circle material-icons "}>
                              create
                            </i>
                          </a>
                        </div>
                        <div className={"icon-div cursor-pointer "}>
                          <a title={"Delete"}>
                            <div
                              onClick={() => ConfirmationDelete(data?.id)}
                              title={"Delete"}
                              className={"text-dark "}
                            >
                              <i
                                className={"material-icons delete-icon-circle"}
                                style={{ fontSize: "23px" }}
                              >
                                delete
                              </i>
                            </div>
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className={"justify-content-center text-center "}>
                <td colSpan={5}>
                  <h6 className={"text-secondary"}>
                    <i>{strings.emptyList}</i>
                  </h6>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default NomenclatureEntryList;