import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { strings } from "../../../../localization/Localization";
import ApplicationService from "../../../../ApplicationService";
import React, { useEffect, useRef, useState } from "react";
import NomenclatureService from "../../../../repository/nomenclatureRepository/NomenclatureRepository";
import Select from "react-select";
import "./ProfessionalData.css";
import UserProfessionalDataService from "../../../../repository/userDetailsRepository/userProfessionaldataRepository";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { update } from "../../../../redux/userSlice";
import swal from "sweetalert";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import NotMultipleSelectDropDown from "../../../../components/NotMultipleSelectDropDown";

const ProfessionalDataForm = (props) => {
  const [selectedUserProfessionalDetails, setSelectedUserProfessionalDetails] =
    useState();
  const [openByAdmin, setOpenByAdmin] = useState(false);
  const param = useParams();
  const dispach = useDispatch();
  const target = useRef(null);
  const [showMailInfoTitle, setShowMailInfoTitle] = useState(false);

  const [formData, updateFormData] = useState({
    pd_professional_number: "",
    pd_privateEmail: "",
    pd_office: undefined,
    pd_key: "",
    pd_bank_account: "",
    pd_bank: undefined,
    pd_dateFrom: "",
    pd_dateTo: "",
  });

  const [nomenclatureOfficeList, setNomenclatureOfficeList] = useState([]);
  const [nomenclatureBankList, setNomenclatureBankList] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState({});
  const [selectedBank, setSelectedBank] = useState({});

  // useEffect(() => {
  //     if (props.selectedUserProfessionalDataToEdit?.id) {
  //         console.log("Test")
  //         console.log(props.selectedUserProfessionalDataToEdit)
  //         updateFormData({
  //             pd_professional_number: props.selectedUserProfessionalDataToEdit.pd_professional_number,
  //             pd_privateEmail: props.selectedUserProfessionalDataToEdit.pd_privateEmail,
  //             pd_key: props.selectedUserProfessionalDataToEdit.pd_key,
  //             pd_bank_account: props.selectedUserProfessionalDataToEdit.pd_bank_account,
  //             pd_dateFrom: props.selectedUserProfessionalDataToEdit.pd_dateFrom,
  //         })
  //     }
  // }, [props.selectedUserProfessionalDataToEdit]);

  useEffect(() => {
    if (props.currentTab === "professionalData") {
      getUserProfessionalData();
      if (nomenclatureOfficeList.length === 0) {
        getAllNomenclatureEntriesForOffice();
      }
      if (nomenclatureBankList.length === 0) {
        getAllNomenclatureEntriesForBank();
      }
    }
  }, [props.currentTab]);

  const getUserProfessionalData = () => {
    if (param.id) {
      UserProfessionalDataService.getUserProfessionalDataById(param.id)
        .then((data) => {
          setSelectedUserProfessionalDetails(data.data);
          let pd_officeTemp = null;
          if (data.data.nomenclatureEntryHelper != null) {
            pd_officeTemp = {
              value: data.data?.nomenclatureEntryHelper?.id,
              label:
                ApplicationService.getActiveLanguage() === "mk"
                  ? data.data?.nomenclatureEntryHelper?.labelMk
                  : data.data?.nomenclatureEntryHelper?.labelEn,
            };
          }
          let pd_bankTemp = null;
          if (data.data.bankAccountHelper.nomenclatureEntry != null) {
            pd_bankTemp = {
              value: data.data?.bankAccountHelper.nomenclatureEntry.id,
              label:
                ApplicationService.getActiveLanguage() === "mk"
                  ? data.data?.bankAccountHelper?.nomenclatureEntry?.labelMk
                  : data.data?.bankAccountHelper?.nomenclatureEntry?.labelEn,
            };
          }
          updateFormData({
            ...formData,
            pd_professional_number: data.data.professionalNumber,
            pd_privateEmail: data.data.privateEmail,
            pd_key: data.data.key,
            pd_dateFrom: data.data.bankAccountHelper?.dateFrom
              ?.split("T")
              .at(0),
            pd_dateTo: data.data.bankAccountHelper?.dateTo?.split("T").at(0),
            pd_bank_account: data.data.bankAccountHelper?.accountNumber,
            pd_office: pd_officeTemp,
            pd_bank: pd_bankTemp,
          });
          setOpenByAdmin(false);
          // setIsActive(data.data.active);
        })
        .catch(() => {});
    } else {
      UserProfessionalDataService.getUserProfessionalData()
        .then((data) => {
          setSelectedUserProfessionalDetails(data.data);
          let pd_officeTemp = null;
          if (data.data.nomenclatureEntryHelper != null) {
            pd_officeTemp = {
              value: data.data?.nomenclatureEntryHelper?.id,
              label:
                ApplicationService.getActiveLanguage() === "mk"
                  ? data.data?.nomenclatureEntryHelper?.labelMk
                  : data.data?.nomenclatureEntryHelper?.labelEn,
            };
          }
          let pd_bankTemp = null;
          if (data.data.bankAccountHelper.nomenclatureEntry != null) {
            pd_bankTemp = {
              value: data.data?.bankAccountHelper.nomenclatureEntry.id,
              label:
                ApplicationService.getActiveLanguage() === "mk"
                  ? data.data?.bankAccountHelper?.nomenclatureEntry?.labelMk
                  : data.data?.bankAccountHelper?.nomenclatureEntry?.labelEn,
            };
          }
          updateFormData({
            ...formData,
            pd_professional_number: data.data.professionalNumber,
            pd_privateEmail: data.data.privateEmail,
            pd_key: data.data.key,
            pd_dateFrom: data.data.bankAccountHelper?.dateFrom
              ?.split("T")
              .at(0),
            pd_dateTo: data.data.bankAccountHelper?.dateTo?.split("T").at(0),
            pd_bank_account: data.data.bankAccountHelper?.accountNumber,
            pd_office: pd_officeTemp,
            pd_bank: pd_bankTemp,
          });
          setOpenByAdmin(false);
          // setIsActive(data.data.active);
        })
        .catch(() => {});
    }
  };

  const getAllNomenclatureEntriesForOffice = () => {
    NomenclatureService.getAllNomenclatureEntryByNomenclatureName(
      "Канцеларии"
    ).then((data) => {
      let nomenclatureConvert = data.data.map((n) => {
        return {
          value: n.id,
          label:
            ApplicationService.getActiveLanguage() == "mk"
              ? n.labelMk
              : n.labelEn,
        };
      });
      setNomenclatureOfficeList(nomenclatureConvert);
    });
  };

  const getAllNomenclatureEntriesForBank = () => {
    NomenclatureService.getAllNomenclatureEntryByNomenclatureName("Банки").then(
      (data) => {
        let nomenclatureConvert = data.data.map((n) => {
          return {
            value: n.id,
            label:
              ApplicationService.getActiveLanguage() == "mk"
                ? n.labelMk
                : n.labelEn,
          };
        });
        setNomenclatureBankList(nomenclatureConvert);
      }
    );
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue;
  
    if (type === "checkbox") {
      newValue = checked;
    } else if (name === "pd_bank_account") {
      newValue = value.slice(0, 15).replace(/\D/g, "");
    } else if (name === "pd_professional_number") {
      // Format phone number
      newValue = formatPhoneNumber(value);
    } else if (name === "pd_dateFrom") {
      newValue = value;
      if (formData.pd_dateTo < value) {
        updateFormData((prevData) => ({
          ...prevData,
          pd_dateTo: value,
        }));
      }
    } else {
      newValue = value;
    }
  
    updateFormData({
      ...formData,
      [name]: newValue,
    });
  };
  
  const formatPhoneNumber = (numericValue) => {
    const digitsOnly = numericValue.replace(/[^0-9]/g, "");

    let formattedPhoneNumber = "+";
  
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 5 || i === 8) {
        formattedPhoneNumber += " ";
      }
      formattedPhoneNumber += digitsOnly[i];
    }
  
    return formattedPhoneNumber;
  };

  const handleOfficeChange = (selectedOption) => {
    setSelectedOffice(selectedOption);
    updateFormData({
      ...formData,
      pd_office: selectedOption,
    });
  };

  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption);
    updateFormData({
      ...formData,
      pd_bank: selectedOption,
    });
  };

  const userEditSuccessfulAlert = () => {
    swal(strings.userEditSuccessful, {
      icon: "success",
    });
  };

  const errorAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const errorBankDetailsAlert = () => {
    swal({
      text: strings.bankFieldRequired,
      icon: "error",
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const editUser = (
    id,
    professionalNumber,
    privateEmail,
    officeId,
    key,
    accountNumber,
    bankId,
    dateFrom,
    dateTo
  ) => {
    props.setShowSpinner(false);
    UserProfessionalDataService.editUser(
      id,
      professionalNumber,
      privateEmail,
      officeId,
      key,
      accountNumber,
      bankId,
      dateFrom,
      dateTo
    )
      .then(() => {
        props.setShowSpinner(true);
        userEditSuccessfulAlert();
        getUserProfessionalData();
      })
      .catch(() => {
        props.setShowSpinner(true);
        errorAlert();
      });
  };

  const editUserByAdmin = (
    id,
    professionalNumber,
    privateEmail,
    officeId,
    key,
    accountNumber,
    bankId,
    dateFrom,
    dateTo
  ) => {
    props.setShowSpinner(false);
    UserProfessionalDataService.editUserByAdmin(
      id,
      professionalNumber,
      privateEmail,
      officeId,
      key,
      accountNumber,
      bankId,
      dateFrom,
      dateTo
    )
      .then(() => {
        props.setShowSpinner(true);
        userEditSuccessfulAlert();
        getUserProfessionalData();
      })
      .catch(() => {
        props.setShowSpinner(true);
        errorAlert();
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const professionalNumber =
      formData.pd_professional_number !== ""
        ? formData.pd_professional_number
        : null;
    const privateEmail =
      formData.pd_privateEmail !== "" ? formData.pd_privateEmail : null;
    const officeId = formData.pd_office ? formData.pd_office.value : null;
    const key = formData.pd_key !== "" ? formData.pd_key : null;
    const accountNumber =
      formData.pd_bank_account !== "" ? formData.pd_bank_account : null;
    const bankId = formData.pd_bank ? formData.pd_bank.value : null;
    const dateFrom = formData.pd_dateFrom !== "" ? formData.pd_dateFrom : null;
    const dateTo = formData.pd_dateTo !== "" ? formData.pd_dateTo : null;

    if (accountNumber !== null && formData.pd_bank === null) {
      errorBankDetailsAlert();
    } else {
      if (param.id === undefined) {
        editUser(
          props.selectedUserProfessionalDataToEdit.id,
          professionalNumber,
          privateEmail,
          officeId,
          key,
          accountNumber,
          bankId,
          dateFrom,
          dateTo
        );
      } else {
        editUserByAdmin(
          props.selectedUserProfessionalDataToEdit.id,
          professionalNumber,
          privateEmail,
          officeId,
          key,
          accountNumber,
          bankId,
          dateFrom,
          dateTo
        );
      }
    }
  };

  return (
    <Form className="p-4" onSubmit={onFormSubmit}>
      <Row className="mb-2">
        <Col xs={12} md={6} className="text-center">
          <Form.Group className="mb-2">
            <Form.Label className="d-flex align-items-center text-secondary mb-0">
              {strings.professionalPhoneNumber}
            </Form.Label>
            <Form.Control
              type="text"
              className={"user_data_input"}
              name="pd_professional_number"
              value={formData.pd_professional_number || ''}
              onChange={handleChange}
              pattern="[0-9+ ]*"
              placeholder={strings.onlyNumbers}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6} className="text-center">
          <Form.Group className="mb-2">
            <Form.Label className={"d-flex text-secondary mb-0"}>
              {strings.email}
              <div
                className={"d-flex ms-1 align-items-center cursor-pointer"}
                ref={target}
                onClick={() => setShowMailInfoTitle(!showMailInfoTitle)}
              >
                <i className={"material-icons"}>error_outline</i>
                <Overlay
                  target={target.current}
                  show={showMailInfoTitle}
                  placement="right-start"
                >
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      <div>
                        {strings.theMailFurtherUsedForLoggingIntoTheSystem}
                      </div>
                    </Tooltip>
                  )}
                </Overlay>
              </div>
            </Form.Label>
            <div className={"d-flex text-secondary mb-2"}>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                required
                title={strings.theMailFurtherUsedForLoggingIntoTheSystem}
                disabled={
                  props.selectedUserProfessionalDataToEdit?.email ? true : false
                }
                value={props.selectedUserProfessionalDataToEdit?.email || ''}
                onChange={handleChange}
                className={"user_data_input"}
                name="email"
                onInvalid={(e) => {
                  e.target.setCustomValidity(strings.fieldsRequired);
                }}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3">
            <Form.Label className={"text-secondary mb-0"}>
              {strings.office}
            </Form.Label>
            <Select
              name="pd_office"
              options={nomenclatureOfficeList}
              className={`basic-multi-select user_details_select_input`}
              classNamePrefix="select"
              onChange={handleOfficeChange}
              placeholder={strings.selectOption}
              noOptionsMessage={() => strings.noOptions}
              value={formData.pd_office}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group
            className="mb-xs-3 mt-md-4 d-flex justify-content-center"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Group className={"d-flex my-auto mt-1"}>
              <Form.Label className={"me-3 text-secondary"}>
                {strings.key}
              </Form.Label>
              <div className={"mt-1"}>
                <input
                  type="checkbox"
                  name={"pd_key"}
                  checked={formData.pd_key}
                  className="custom-checkbox"
                  disabled={
                    ApplicationService.hasRole([
                      "ROLE_ADMINISTRATOR",
                      "ROLE_HR",
                    ])
                      ? false
                      : true
                  }
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Form.Group>
        </Col>
      </Row> 
      <div
        className={"p-3 mt-4 shadow-sm"}
        style={{
          border: "1px solid lightgray",
          borderRadius: "10px",
        }}
      >
        <Row>
          <div className="d-flex align-items-center">
            <h5 className="mt-4 mb-5 bank-account-info-section-header">
              <span className="pe-2 text-secondary">
                {strings.bankAccountInfo}
              </span>
            </h5>
          </div>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center text-secondary mb-0">
                {strings.bankAccount}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                className={"user_data_input"}
                name="pd_bank_account"
                value={formData.pd_bank_account}
                onChange={handleChange}
                required={
                  formData.pd_bank !== undefined &&
                  formData.pd_bank !== null &&
                  formData.pd_bank !== ""
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label className={"text-secondary mb-0"}>
                {strings.bank}
                <span className="text-danger">*</span>
              </Form.Label>
              <Select
                name="pd_bank"
                options={nomenclatureBankList}
                className={`basic-multi-select user_details_select_input`}
                classNamePrefix="select"
                onChange={handleBankChange}
                placeholder={strings.selectOption}
                noOptionsMessage={() => strings.noOptions}
                value={formData.pd_bank}
                required={
                  formData.pd_bank_account !== undefined &&
                  formData.pd_bank_account !== null &&
                  formData.pd_bank_account !== ""
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label
                className={"d-flex align-items-center text-secondary mb-0"}
              >
                <div
                  className={
                    "label-icon align-items-end d-flex rotate-key-icon mt-1"
                  }
                ></div>
                {strings.dateFrom}
              </Form.Label>
              <Form.Control
                type="date"
                value={formData.pd_dateFrom?.split("T").at(0)}
                onChange={handleChange}
                className={"user_data_input"}
                name="pd_dateFrom"
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label
                className={"d-flex align-items-center text-secondary mb-0"}
              >
                <div
                  className={
                    "label-icon align-items-end d-flex rotate-key-icon mt-1"
                  }
                ></div>
                {strings.dateTo}
              </Form.Label>
              <Form.Control
                type="date"
                value={formData.pd_dateTo?.split("T").at(0)}
                onChange={handleChange}
                min={formData.pd_dateFrom}
                className={"user_data_input"}
                name="pd_dateTo"
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
      <Modal.Footer>
        <button
          className={"btn text-white mt-4 border-radius add-button"}
          type="submit"
        >
          {strings.saveProfile}
        </button>
      </Modal.Footer>
    </Form>
  );
};

export default ProfessionalDataForm;
