import React, { useEffect, useState } from "react";
import BenefitsService from "../../../repository/benefitsRepository/BenefitsRepository";
import { strings } from "../../../localization/Localization";
import "../../Events/Events.css";
import "../../../pages/UserDetails/UserDetails.css";
import BenefitCardComponent from "../Benefits/BenefitCard/BenefitCard";
import BenefitProposalService from "../../../repository/benefitsRepository/BenefitsProposalRepository";
import AddEditBenefitProposalModal from "../Benefits/BenefitProposal/AddEditBenefitProposal/AddEditBenefitProposalModal";
import { Tabs } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import BenefitProposal from "../Benefits/BenefitProposal/BenefitProposal";
import NomenclatureService from "../../../repository/nomenclatureRepository/NomenclatureRepository";
import "./MyBenefits.css";
import ApplicationService from "../../../ApplicationService";

const MyBenefits = (props) => {
  const [benefitsList, setBenefitsList] = useState([]);
  const [benefitNomenclature, setBenefitNomenclature] = useState({});
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);
  const [showNomenclatureEntryModal, setShowNomenclatureEntryModal] =
    useState(false);
  const [
    selectedNomenclatureEntryForEdit,
    setSelectedNomenclatureEntryForEdit,
  ] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [benefitProposals, setBenefitProposals] = useState([]);
  const [myBenefitProposals, setMyBenefitProposals] = useState([]);
  const [myBenefitsList, setMyBenefitsList] = useState([]);
  const [showAddEditBenefitProposalModal, setAddEditBenefitProposalModal] =
    useState(false);
  const [key, setKey] = useState("myBenefits");

  useEffect(() => {
    if (key === "myBenefits") {
      getMyBenefits();
    } else if (key === "myBenefitProposals") {
      getMyBenefitProposals();
    }
  }, [key]);

  useEffect(() => {
    getNomenclatureByName();
  }, []);

  const getAllBenefits = () => {
    BenefitsService.getAllBenefitNomenclatureEntries()
      .then((data) => {
        setBenefitsList(data.data);
        setExpandedDescriptions(new Array(data.data.length).fill(false));
      })
      .catch((error) => console.error("Error fetching benefits:", error));
  };

  const getNomenclatureByName = () => {
    NomenclatureService.getNomenclatureByName("Бенефиции")
      .then((data) => {
        setBenefitNomenclature(data.data);
      })
      .catch((error) => console.error("Error fetching nomenclature:", error));
  };

  const getMyBenefits = () => {
    BenefitsService.getAllBenefitsForUser()
      .then((data) => {
        setMyBenefitsList(data.data);
        setExpandedDescriptions(new Array(data.data.length).fill(false));
      })
      .catch((error) => console.error("Error fetching user benefits:", error));
  };

  const getAllBenefitProposals = () => {
    BenefitProposalService.getAllBenefitProposals()
      .then((data) => {
        setBenefitProposals(data.data);
      })
      .catch((error) =>
        console.error("Error fetching benefit proposals:", error)
      );
  };

  const getMyBenefitProposals = () => {
    BenefitProposalService.getMyBenefitProposals()
      .then((data) => {
        setMyBenefitProposals(data.data);
      })
      .catch((error) =>
        console.error("Error fetching user benefit proposals:", error)
      );
  };

  const handleAddOrEditBenefitProposal = () => {
    setAddEditBenefitProposalModal(true);
  };

  const handleCloseAddOrEditBenefitModal = () => {
    setAddEditBenefitProposalModal(false);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDate.replace(/\//g, ".");
  };

  const toggleDescription = (index) => {
    const updatedExpandedDescriptions = [...expandedDescriptions];
    updatedExpandedDescriptions[index] = !updatedExpandedDescriptions[index];
    setExpandedDescriptions(updatedExpandedDescriptions);
  };

  const redirectToEditBenefit = (nomenclatureEntry) => {
    setSelectedNomenclatureEntryForEdit(nomenclatureEntry);
    setShowNomenclatureEntryModal(true);
  };

  const handleShowAddUserToBenefitModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddUserToBenefitModal = () => {
    setShowAddModal(false);
  };

  const handleTabSelect = (selectedKey) => {
    setKey(selectedKey);
  };

  return (
    <div>
      <AddEditBenefitProposalModal
        showModal={showAddEditBenefitProposalModal}
        handleClose={handleCloseAddOrEditBenefitModal}
        getBenefitProposals={getAllBenefitProposals}
        getMyBenefitProposals={getMyBenefitProposals}
      />

      <div className="w-100 d-flex justify-content-between">
        <div>
          <h1>{strings.myBenefitList}</h1>
        </div>

        {!ApplicationService.hasRole(["ROLE_ADMINISTRATOR"]) && (
          <div className="align-items-center d-flex ms-2">
            <div
              className="btn text-white text-end d-flex align-items-center border-radius px-xs-0  add-button"
              onClick={handleAddOrEditBenefitProposal}
            >
              <div className="align-items-center d-flex text-start">
                <i className="material-icons add_button_icon">add_box</i>
              </div>
              <div className="mx-1">{strings.addBenefitProposal}</div>
            </div>
          </div>
        )}
      </div>

      <div className="user_details_box w-100 mb-4 border border-radius border-light border-5 px-4 pt-4">
        <Tabs
          id="controlled-tab-example"
          className={`w-100 user_details_tabs events-tabs ${ApplicationService.hasRole(["ROLE_ADMINISTRATOR"]) && key === 'myBenefits' ? 'tabs-left' : ''}`}
          activeKey={key}
          onSelect={handleTabSelect}
          >
          <Tab
            eventKey="myBenefits"
            title={strings.myBenefits}
            className={"h-100"}>
            <div className={"w-100 mt-3 h-100 "}>
              <div className="row equal-height-row">
                {myBenefitsList.length > 0 ? (
                  myBenefitsList.map((benefit, index) => (
                    <div className="col-12 col-lg-6 col-xl-4 mb-4" key={index}>
                      <BenefitCardComponent
                        benefit={benefit}
                        benefitNomenclature={benefitNomenclature}
                        index={index}
                        expandedDescriptions={expandedDescriptions}
                        toggleDescription={toggleDescription}
                        formatDate={formatDate}
                      />
                    </div>
                  ))
                ) : (
                  <h3 className={"text-center my-5"}>
                    <i className={"text-secondary no-notification-message"}>
                      {strings.noBenefits}
                    </i>
                  </h3>
                )}
              </div>
            </div>
          </Tab>
          {!ApplicationService.hasRole(["ROLE_ADMINISTRATOR"]) && (
            <Tab
              eventKey="myBenefitProposals"
              title={strings.myBenefitProposals}
              className={"h-100 w-100"}
            >
              <div className={"w-100 h-100"}>
                <div className={"row table-responsive mt-1"}>
                  <table id="keywords" className={"mb-1"}>
                    <thead>
                      <tr className="userlist">
                        <th scope={"col"}>
                          <b>{strings.name}</b>
                        </th>
                        <th scope={"col w-25"}>{strings.description}</th>
                        <th scope={"col"}>{strings.userEventProposal}</th>
                        <th scope={"col "} className={"pe-4"}>
                          {strings.status}
                        </th>
                        <th scope={"col"} className={"pe-1"}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {myBenefitProposals.length > 0 &&
                        myBenefitProposals.map((benefitProposal, index) => (
                          <BenefitProposal
                            benefitProposal={benefitProposal}
                            key={benefitProposal.id}
                            activeTab={key}
                            getAllBenefits={getAllBenefits}
                            getAllBenefitProposals={getAllBenefitProposals}
                            getAllBenefitProposalsForUser={
                              getMyBenefitProposals
                            }
                          />
                        ))}
                    </tbody>
                  </table>
                  {myBenefitProposals.length === 0 && (
                    <h3 className={"text-center my-5"}>
                      <i className={"text-secondary no-notification-message"}>
                        {strings.noBenefitProposals}
                      </i>
                    </h3>
                  )}
                </div>
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default MyBenefits;
