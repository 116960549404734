import { Route, Switch } from "react-router-dom";
import Login from "./pages/Login/Login";
import Finalized_registration from "./pages/FinalizedRegistration/Finalized_registration";
import changePassword from "./pages/ChangePassword/ChangePassword";
import MainLayout from "./components/Layout/MainLayout";
import EthnicityList from "./pages/Ethnicity/Ethnicity_list/EthnicityList";
import ReligionList from "./pages/ReligionsUnit/ReligionsList/ReligionList";
import LeaveRequestsList from "./pages/LeaveRequests/MyLeaveRequest/LeaveRequestList/LeaveRequestsList";
import UserList from "./pages/User/UserList/UserList";
import MyProfile from "./pages/MyProfile/MyProfile";
import Holiday from "./pages/Calendar/Holiday/Holiday";
import CalendarList from "./pages/Calendar/CalendarList/CalendarList";
import Logout from "./pages/Logout/Logout";
import ProtectedRoute from "./ProtectedRoute";
import AccessDenied from "./pages/AccessDenied/AccessDenied";
import StatusCalendar from "./pages/StatusCalendar/StatusCalendar";
import EmployeeList from "./pages/EmpolyeeEvidention/EmployeeList/EmployeeList";
import Home from "./pages/Home/Home";
import CalendarHistoryList from "./pages/CalendarHistory/CalendarHistoryList";
import OrganizationalHierarchyList from "./pages/OrganizationalHierarchy/OrganizationalHierarchyList/OrganizationalHierarchyList";
import DeliveredRequestList from "./pages/LeaveRequests/DeliveredLeaveRequest/DeliveredRequestList";
import AbsenceStatus from "./pages/AbsenceStatus/AbsenceStatus";
import PositionHierarchyList from "./pages/PositionHierarchy/PositionHierarchyList/PositionHierarchyList";
import Settings from "./pages/Settings/Settings";
import LoginMobilePage from "./pages/LoginMobilePage/LoginMobilePage";
import { useEffect } from "react";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import FinalizedRegistrationMobile from "./pages/FinalizedRegistrationMobile/FinalizedRegistrationMobile";
import ForgotPasswordMobile from "./pages/ForgotPasswordMobile/ForgotPasswordMobile";
import ChangePasswordMobile from "./pages/ChangePasswordMobile/ChangePasswordMobile";
import UserDetails from "./pages/UserDetails/UserDetails";
import LeaveTypeAndReasonsList from "./pages/LeaveTypeAndReasons/LeaveTypeAndReasonsList";
import NomenclatureList from "./pages/Nomencalture/NomenclatureList/NomenclatureList";
import AllProjects from "./pages/AllProjects/AllProjects";
import Events from "./pages/Events/Events";
import AddOrEditEvent from "./pages/Events/Event/AddOrEditEvent/AddOrEditEvent";
import AddOrEditProject from "./pages/AllProjects/AddOrEditProject/AddOrEditProject";
import ProjectGroup from "./pages/ProjectGroup/ProjectGroup";
import MyProjects from "./pages/MyProjects/MyProjects";
import AddGroup from "./pages/ProjectGroup/AddGroup/AddGroup";
import Benefits from "./pages/Benefits/Benefits/Benefits";
import MyBenefits from "./pages/Benefits/MyBenefits/MyBenefits";
import TimeTrackingList from "./pages/TimeTracking/TimeTrackingList/TimeTrackingList";
import MyTimeTrackingList from "./pages/TimeTracking/MyTimeTrackingList/MyTimeTrackingList";
import FeedbackList from "./pages/Feedback/FeedbackList/FeedbackList";
import OrganisationList from "./pages/Organisation/OrganisationList";
import AllEquipment from "./pages/Equipment/AllEquipment/AllEquipment";
import MyEquipment from "./pages/Equipment/MyEquipment/MyEquipment";

const Routes = () => {

  const windowSize = window.innerWidth;

  useEffect(() => {
  }, []);
  return (
    <div className={"h-100"}>
      <Switch>

        <Route exact path='/login' component={windowSize > 567 ? (Login) : (LoginMobilePage)} />
        <Route exact path='/' component={windowSize > 567 ? (Login) : (LoginMobilePage)} />
        <Route exact path='/finalized_registration'
          component={windowSize > 567 ? (Finalized_registration) : (FinalizedRegistrationMobile)} />
        <Route exact path='/forgotPassword' component={windowSize > 567 ? (ForgotPassword) : (ForgotPasswordMobile)} />
        <Route exact path='/accessDenied' component={AccessDenied} />
        <Route exact path='/changePassword' component={windowSize > 567 ? (changePassword) : (ChangePasswordMobile)} />
        <Route exact path='/mainlayout' component={MainLayout} />
        <ProtectedRoute exact path='/myProfile' authorizedRoles={[]} component={MyProfile} />
        <Route path='/logout' component={Logout} />
        <Route exact
          path={['/home', '/employeelist', '/religion', '/leaveRequests', '/arrivedRequest', '/organisation',
            '/user', '/ethnicity', '/holiday', '/holiday/:id', '/holidays', '/status', '/calendarhistory',
            '/position', '/absence', '/settings', '/userDetails/:id', '/userDetails', '/absenceType', '/nomenclature',
            '/events', '/event/:id', '/addEvent',
            '/position', '/absence', '/settings', '/userDetails/:id', '/userDetails', '/absenceType', '/nomenclature',
            '/projects',
            '/benefits', '/myBenefits',
            '/position', '/absence', '/settings', '/userDetails/:id', '/userDetails', '/absenceType', '/nomenclature',
            '/projects', '/addProject', '/projects/:id', '/myProjects', '/group', '/group/:id', '/allTrackings',
            '/myTimeTracking', '/feedback', '/allEquipment', '/myEquipment','/organisationList']}>
          <MainLayout>
            <ProtectedRoute exact path='/home' authorizedRoles={[]} component={Home} />
            <ProtectedRoute exact path='/employeelist'
                            authorizedRoles={[]}
                            component={EmployeeList}/>
            <ProtectedRoute exact path='/religion'
                            authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
                            component={ReligionList}/>
            <ProtectedRoute exact path='/organisationList'
                            authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
                            component={OrganisationList}/>
            <ProtectedRoute exact path='/organisation'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={OrganizationalHierarchyList} />
            <ProtectedRoute exact path='/position'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={PositionHierarchyList} />
            <ProtectedRoute exact path='/user' authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={UserList} />
            <ProtectedRoute exact path='/holidays'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={CalendarList} />
            <ProtectedRoute exact path='/holiday'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={Holiday} />
            <ProtectedRoute exact path='/holiday/:id'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={Holiday} />
            <ProtectedRoute exact path='/status'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={StatusCalendar} />
            <ProtectedRoute exact path='/ethnicity'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={EthnicityList} />
            <ProtectedRoute exact path='/leaveRequests'
              authorizedRoles={[]}
              component={LeaveRequestsList} />
            <ProtectedRoute exact path='/arrivedRequest'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={DeliveredRequestList} />
            <ProtectedRoute exact path='/calendarhistory'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={CalendarHistoryList} />
            <ProtectedRoute exact path='/absence'
              authorizedRoles={[]}
              component={AbsenceStatus} />
            <ProtectedRoute exact path='/settings'
              authorizedRoles={['ROLE_ADMINISTRATOR']}
              component={Settings} />
            <ProtectedRoute exact path='/userDetails'
              authorizedRoles={[]}
              component={UserDetails} />
            <ProtectedRoute exact path='/userDetails/:id'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={UserDetails} />
            <ProtectedRoute exact path='/absenceType'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={LeaveTypeAndReasonsList} />
            <ProtectedRoute exact path='/nomenclature'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={NomenclatureList} />
            <ProtectedRoute exact path='/benefits'
              authorizedRoles={[]}
              component={Benefits} />
            <ProtectedRoute exact path='/myBenefits'
              authorizedRoles={[]}
              component={MyBenefits}/>
            <ProtectedRoute exact path='/projects'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={AllProjects} />
            <ProtectedRoute exact path='/projects/:id'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={AddOrEditProject} />
            <ProtectedRoute exact path='/events'
              authorizedRoles={[]}
              component={Events} />
            <ProtectedRoute exact path='/event/:id'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={AddOrEditEvent} />
            <ProtectedRoute exact path='/addEvent'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={AddOrEditEvent} />
            <ProtectedRoute exact path='/addProject'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={AddOrEditProject} />
            <ProtectedRoute exact path='/myProjects'
              authorizedRoles={[]}
              component={MyProjects} />
            <ProtectedRoute exact path='/group'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={ProjectGroup} />
            <ProtectedRoute exact path='/group/:id'
              authorizedRoles={['ROLE_ADMINISTRATOR', 'ROLE_HR']}
              component={AddGroup} />
            <ProtectedRoute exact path='/allTrackings'
              authorizedRoles={["ROLE_ADMINISTRATOR", "ROLE_HR"]}
              component={TimeTrackingList} />
            <ProtectedRoute exact path='/myTimeTracking'
              authorizedRoles={[]}
              component={MyTimeTrackingList} />
            <ProtectedRoute exact path='/feedback'
                              authorizedRoles={[]}
                              component={FeedbackList}/>
            <ProtectedRoute exact path='/allEquipment'
              authorizedRoles={["ROLE_ADMINISTRATOR", "ROLE_HR"]}
              component={AllEquipment} />
            <ProtectedRoute exact path='/myEquipment'
              authorizedRoles={[]}
              component={MyEquipment} />


          </MainLayout>
        </Route>
      </Switch>
    </div>
  );
};

export default Routes;
