import React from 'react';
import { strings } from '../../../localization/Localization';
import { HiDownload, HiOutlineDocumentText } from 'react-icons/hi';
import ProjectFileService from '../../../repository/projectFileRepository/projectFileRepository';
import swal from 'sweetalert';
import FormatDate from '../../../components/formatDate/FormatDate';

const ProjectFile = (props) => {

  const FileSaver = require('file-saver');

  const ConfirmationDelete = (id) => {
    swal(strings.deleteConfirmation, {
      title: strings.areYouSure,
      icon: 'warning',
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteDocument(id);
      }
    });
  };

  const successfulAlert = () => {
    swal(strings.actionSuccessful, {
      icon: 'success',
    });
  };

  const warningAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: 'error',
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const downloadDocument = (fileId, fileName) => {
    fetch(process.env.REACT_APP_HOST_ENV + `/file/downloadFile/${fileId}`,
      {
        method: 'post',
        origin: 'same-origin',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('auth_token')
        },
      }
    ).then(res => res.blob())
      .then(file => FileSaver.saveAs(file, fileName));
  };

  const deleteDocument = (deleteFileId) => {
    ProjectFileService.deleteFile(deleteFileId)
      .then(() => {
        successfulAlert();
        props.getAllFiles();
      }).catch(() => {
      warningAlert();
    });
  };

  return (
    <div>
      <div className={'mt-1'}>
        <div>
          <div className={'table-responsive scrollWrapper'}>
            {props.files.length > 0 ? (
                <table className='table user-table table-borderless text-start w-100'>
                  <thead className={'custom-table-header text-white'}>
                  <tr className={'user-table-header-row'}>
                    <th scope='col'>{strings.fileName}</th>
                    <th scope='col'>{strings.description}</th>
                    <th scope='col'>{strings.fileType}</th>
                    <th scope='col'>{strings.dateCreated}</th>
                    <th scope='col'>{strings.isPrivate}</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {props.files.map((file) => {
                    return (
                      <tr className={'user-table-body-row'} key={file.id}>
                        <td><HiOutlineDocumentText
                          className={'user_personal_data__document_icon me-1 text-blue'}/> {file?.name}
                        </td>
                        <td>{file?.description}</td>
                        <td>{file?.fileType}</td>
                        <td>{FormatDate.formatDate(file?.dateCreated)}</td>
                        <td>
                          {file?.isPrivate ? (
                            <span className='badge text-bg-danger'>private</span>
                          ) : (
                            <span className='badge text-bg-success'>public</span>
                          )}
                        </td>
                        <td>
                          <div className={'d-flex justify-content-end align-items-center'}>
                            <div className={'icon-div cursor-pointer me-3'}>
                              <a title={'Delete'}>
                                <div
                                  onClick={() => ConfirmationDelete(file?.id)}
                                  title={'Delete'}
                                  className={'text-dark '}>
                                  <i className={'material-icons delete-icon-circle'}
                                     style={{ fontSize: '23px' }}>delete</i>
                                </div>
                              </a>
                            </div>
                            <HiDownload
                              className={'mt-1 text-primary cursor-pointer user_personal_data__document_download__icon'}
                              onClick={() => downloadDocument(file.id, file.name)}/>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                  }
                  </tbody>
                </table>
              ) :
              (
                <div className={'justify-content-center text-center '}>
                  <h5 className={'text-secondary'}><i>{strings.emptyList}</i></h5>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectFile;
