import decode from 'jwt-decode';
import { differenceInYears, differenceInMonths, differenceInDays } from 'date-fns';

const ApplicationService = {

  hasRole: (authorizedRoles) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      return false;
    }
    let userRoles = decode(token).authority;
    const isAuthorized = authorizedRoles.filter((role) => userRoles.includes(role));
    return isAuthorized.length > 0;
  },
  getActiveLanguage: () => {
    const activeLanguage = localStorage.getItem('activeLanguage');
    return activeLanguage;
  },
  getDateFormatForDataPicker: (date) => {
    const years = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return years + '-' + month + '-' + day;
  },
  getYearsMonthsDaysDifference: (startingDate, endingDate) => {
    const startDate = new Date(startingDate);
    const endDate = endingDate !== null ? new Date(endingDate) : new Date();

    const yearsDifference = differenceInYears(endDate, startDate);
    const monthsDifference = differenceInMonths(endDate, startDate) % 12;
    const daysDifference = differenceInDays(endDate, startDate) % 30;

    return {
      years: yearsDifference,
      months: monthsDifference,
      days: daysDifference
    };
  },
  getWorkDayOfWeek: () => {
    return ['Понеделник', 'Вторник', 'Среда', 'Четврток', 'Петок'];
  },
  sortWorkDays: (daysList) => {
    let allWorkDays = ApplicationService.getWorkDayOfWeek();
    let newArray = [];
    for (let i = 0; i < allWorkDays.length; i++) {
      newArray.push(daysList.find((e) => e.name === allWorkDays[i]));
    }
    return newArray;
  },
  clearAllInLocalStorageExceptTokenAndLanguageAndIsOpen: () => {
    const authToken = localStorage.getItem('auth_token');
    const isOpen = localStorage.getItem('isOpen');
    const activeLanguage = localStorage.getItem('activeLanguage');

    localStorage.clear();

    localStorage.setItem('auth_token', authToken);
    localStorage.setItem('isOpen', isOpen);
    localStorage.setItem('activeLanguage', activeLanguage);

  },
  getStatusColor:(status)=>{
   if( status === 'APPROVED') return 'save-text'
    if( status === 'PENDING') return 'save-text'
    if( status === 'REJECTED') return 'cancel-text'
  }
};
export default ApplicationService;