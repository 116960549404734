import axios from "../../axios/axios";

const LeaveReasonService = {
    getAllLeaveReasons:()=>{
        return axios.get("/leavereason/getAll");
    },
    getAllLeaveReasonsByLeaveTypeId:(id) =>{
        return axios.get(`/leavereason/getAllActive/${id}`);
    },
    getAllowDayAndRemainingDayForReasonsForLoggedUser:(id)=>{
        return axios.get(`/userabsence/getAllByUserAndReason/${id}`);
    },
    getAllowDayAndRemainingDayForReasonsByUserId:(reasonsId,userId)=>{
        return axios.get(`/userabsence/getAllByUserAndReason/${reasonsId}/${userId}`);
    },
    addLeaveReasons:(data)=>{
        return axios.post("/leavereason/add",data);
    },
    editLeaveReasons:(data)=>{
        return axios.post("/leavereason/update",data);
    },
    changeActiveLeaveReasons:(id)=>{
        return axios.post(`/leavereason/changeActive/${id}`)
    },
    deleteLeaveReason:(id)=>{
        return axios.delete(`/leavereason/delete/${id}`)
    }
}
export default LeaveReasonService;