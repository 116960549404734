import React, {useEffect, useState} from "react";
import Select from 'react-select';


const MultiSelectDropdown = (props) => {

    const [options, setOptions] = useState([])

    const [selectedOptions, setSelectedOptions] = useState([])

    useEffect(() => {
        setOptions(props.listForDropDown)
        if (props.defaultListForDropdown != null ) {
            if(props.defaultListForDropdown.length > 0)
            {
                let newOptions = props.listForDropDown.filter(obj => selectedOptions.map(s => s.value).includes(obj.value))
                setSelectedOptions(newOptions);
                props.handleSelection(newOptions);
            }else{
                setSelectedOptions([])
            }
        }
    }, [props.listForDropDown]);

    useEffect(() => {
        if (props.resetData) {
            setSelectedOptions([]);
        }
    }, [props.resetData]);

    const getValue = (selectedValues) => {
        props.setResetData(false);
        setSelectedOptions(selectedValues);
        props.handleSelection(selectedValues);
    }

    return (
        <div>
            <Select
                onChange={getValue}
                value={selectedOptions}
                // value={selectedOptions?.length > 0 ? options.find(function (option) {
                //     return selectedOptions.map((s) => s.value === option.value).length > 0;
                // }) : []}
                // value={selectedOptions.length > 0 ? options.filter(obj => selectedOptions.map(s => s.value).includes(obj.value)) : []}
                isMulti
                name="role"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                isDisabled={props.isDisabled ? true : false}
            />
        </div>
    )

}
export default MultiSelectDropdown