import ApplicationService from "../../../ApplicationService";
import React, { useState, useEffect } from "react";
import { strings } from "../../../localization/Localization";
import MyEquipmentCardDetailsModal from "../MyEquipmentCardDetailsModal/MyEquipmentCardDetailsModal";

const MyEquipmentCardComponent = ({
  index,
  expandedDescriptions,
  showDescription,
  equipmentNomenclature,
  equipment,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState({});
  

  const handleOpenCard = (equipment) => {
    setSelectedEquipment(equipment);
    setShowModal(true);
  };

  const handleCloseCard = () => {
    setSelectedEquipment({});
    setShowModal(false);
  };

  return (
    <div className={`col-4 card-width background-card`} key={equipment.id}>
    <div className={`card  ${
    expandedDescriptions
      ? expandedDescriptions[index]
        ? "card-expanded"
        : "card-not-expanded"
      : ""
  }`}>
        <div className="card-body d-flex flex-column" >
          <div className=" mb-2 d-flex justify-content-between">
            <h5 className="card-title">
              {ApplicationService.getActiveLanguage() === "mk"
                ? equipment.equipmentNomenclatureEntry?.labelMk
                : equipment.equipmentNomenclatureEntry?.labelEn}
            </h5>

            <div className="col-3">
              {equipment.isActive ? (
                <span className="badge text-bg-success ms-3">
                  {strings.Active}
                </span>
              ) : (
                <span className="badge text-bg-danger ">
                  {strings.notActive}
                </span>
              )}
            </div>
          </div>
          <div className="row" style={{ position: "relative" }}>
            <img
              src="https://cdn-icons-png.flaticon.com/128/8644/8644457.png"
              alt="Equipment"
              className="col-3 image-width"
            />
            <div className="quantity">
              {strings.equipmentQuantity}: {equipment.quantity}
            </div>
          </div>
          <div className="row mb-3 mt-3">
            <div className={"d-flex"}>
              <p
                className={`description-text me-2 ${
                  expandedDescriptions
                    ? expandedDescriptions[index]
                      ? "description-expanded"
                      : "description-not-expanded"
                    : ""
                }`}
              >
                {equipment.equipmentNomenclatureEntry?.description}
              </p>

              {equipment.equipmentNomenclatureEntry?.description.split(" ")
                .length > 20 && (
                <button
                  className="toggle-description-button justify-content-center"
                  onClick={() => showDescription(index)}
                >
                  {expandedDescriptions[index] ? (
                    <i className={"material-icons"}>expand_less</i>
                  ) : (
                    <i className={"material-icons"}>expand_more</i>
                  )}
                </button>
              )}
            </div>
          </div>

          <div className="d-flex mt-auto">
            <a
              className="btn btn-primary text-white add-button details-button"
              onClick={() => {
                setSelectedEquipment(equipment);
                handleOpenCard(equipment);
              }}
            >
              {strings.details}
            </a>
          </div>

          <MyEquipmentCardDetailsModal
            showModal={showModal}
            equipmentDetails={selectedEquipment}
            handleClose={handleCloseCard}
            equipment={equipmentNomenclature}
            expandedDescriptions={expandedDescriptions}
          />
        </div>
      </div>
    </div>
  );
};

export default MyEquipmentCardComponent;
