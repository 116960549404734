import {useEffect, useState} from "react";
import swal from "sweetalert";
import LeaveRequestRepository from "../../../../repository/leaveRequestRepository/LeaveRequestRepository";
import {Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Row} from "react-bootstrap";
import Select from "react-select";
import LeaveTypeRepository from "../../../../repository/leaveTypeRepository/LeaveTypeRepository";
import {strings} from "../../../../localization/Localization";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import './LeaveRequestsModal.css'
import LeaveReasonService from "../../../../repository/leaveReasonRepository/LeaveReasonRepository";
import 'material-icons/iconfont/material-icons.css';
import SpinnerComponent from "../../../../components/Spinner"
import AbsencesService from "../../../../repository/absencesRepository/absencesRepository";
import ApplicationService from "../../../../ApplicationService";
import DraggableModalDialog from "../../../../components/DraggableModalDialog/DraggableModalDialog";

const LeaveRequestsModal = (props) => {

    const [formData, setFormData] = useState({
        dateFrom: "",
        dateTo: "",
        totalDays: null,
        workDays: null,
        description: "",
        leaveType: "",
        leaveReasons: "",
        deputy: "",
    });

    const [leaveType, setLeaveType] = useState([]);

    const [selectedLeaveType, setSelectedLeaveType] = useState({});

    const [spinnerShow, setShowSpinner] = useState(true)

    const [leaveReasons, setLeaveReasons] = useState([]);

    const [selectedLeaveReasons, setSelectedLeaveReasons] = useState({});

    const [dateValid, setDateValid] = useState(true);

    const [absenceDetails, setAbsenceDetails] = useState([]);

    const [absenceDetailsByReasons, setAbsenceDetailsByReasons] = useState([]);

    const [absenceDetailsByReasonsForCurrentYear, setAbsenceDetailsByReasonsForCurrentYear] = useState({});

    const [showDeputyInput, setShowDeputyInput] = useState(false);

    const [deputiesList, setDeputiesList] = useState([]);

    const [selectedDeputy, setSelectedDeputy] = useState({});

    const [noAllowedDays, setNoAllowedDays] = useState(false);

    const [disableButton, setDisableButton] = useState(true);

    const [noInfoForSelectedLeaveType, setNoInfoForSelectedLeaveType] = useState(false);


    // const [disableButton, setNoAllowedDays] = useState(true);

    useEffect(() => {
        if (props.showModal) {
            if (props.selectedLeaveRequestForEdit.id) {
                if (props.selectedLeaveRequestForEdit.deputy) {
                    setShowDeputyInput(true);
                    getDeputy();
                }
            } else {
                setShowDeputyInput(false);
                setDeputiesList([]);
            }
            setNoInfoForSelectedLeaveType(false)
            getAllLeaveType();
        } else {
            setNoInfoForSelectedLeaveType(false)
            setSelectedLeaveType({});
            setSelectedLeaveReasons({});
            setAbsenceDetailsByReasons([]);
            setSelectedDeputy({});
            setShowDeputyInput(false)
            setAbsenceDetailsByReasonsForCurrentYear({});
            setNoAllowedDays(false);
            setDisableButton(true);
            resetFormDate();
        }
    }, [props.showModal]);

    useEffect(() => {
        if (props.selectedLeaveRequestForEdit.id) {
            setSelectedLeaveReasons({
                value: props.selectedLeaveRequestForEdit.leaveReason.id,
                label: props.selectedLeaveRequestForEdit.leaveReason.name
            });
            setSelectedLeaveType({
                value: props.selectedLeaveRequestForEdit.leaveType.id,
                label: props.selectedLeaveRequestForEdit.leaveType.name
            });
            if (props.selectedLeaveRequestForEdit.deputy) {
                setSelectedDeputy({
                    value: props.selectedLeaveRequestForEdit.deputy.id,
                    label: props.selectedLeaveRequestForEdit.deputy.firstName + " " + props.selectedLeaveRequestForEdit.deputy.firstName
                });
            }
        }
    }, [props.selectedLeaveRequestForEdit]);

    useEffect(() => {
        getAllAbsencesByLoggedUser();
    }, []);

    useEffect(() => {
        checkAllowedDays();
    }, [formData.workDays, formData.leaveReasons]);

    useEffect(() => {
        if (selectedLeaveReasons.value) {
            LeaveReasonService.getAllowDayAndRemainingDayForReasonsForLoggedUser(selectedLeaveReasons.value)
                .then((data) => {
                    setAbsenceDetailsByReasons(data.data);
                })
            AbsencesService.getRemainingAndAllowedDaysForUserAndReasons(selectedLeaveReasons.value)
                .then((data) => {
                    setAbsenceDetailsByReasonsForCurrentYear(data.data)
                    let remainingDays = data.data.remainingDays;
                    if(remainingDays===0){
                        setNoAllowedDays(true)
                        setDisableButton(true)
                        return
                    }
                    remainingDays >= formData.workDays ? setNoAllowedDays(false) : setNoAllowedDays(true);
                    remainingDays >= formData.workDays ? setDisableButton(false) : setDisableButton(true);
                })
        }
    }, [selectedLeaveReasons])

    useEffect(() => {
        if (selectedLeaveType.value || props.selectedLeaveRequestForEdit.id) {
            if (selectedLeaveType.value) {
                if (formData.leaveType !== "") {
                    setAbsenceDetailsByReasonsForCurrentYear({});
                    setDisableButton(true);
                    setSelectedLeaveReasons({});
                }
                getLeaveReasons(selectedLeaveType.value);
            } else if (props.selectedLeaveRequestForEdit.id) {
                setFormData({...formData, "dateFrom": new Date(props.selectedLeaveRequestForEdit.dateFrom)})
                getLeaveReasons(props.selectedLeaveRequestForEdit.leaveType.id);
            }
        }
    }, [selectedLeaveType, props.selectedLeaveRequestForEdit]);

    // useEffect(() => {
    //     if (absenceDetailsByReasons.length > 0) {
    //         var year = new Date().getFullYear();
    //         let remainingDays = absenceDetailsByReasons.filter((r) => r.forYear === year)[0]?.remainingDays;
    //         remainingDays >= formData.workDays ? setNoAllowedDays(false)  : setNoAllowedDays(true);
    //         remainingDays >= formData.workDays ? setDisableButton(false)  : setDisableButton(true);
    //         setAbsenceDetailsByReasonsForCurrentYear(absenceDetailsByReasons.filter((r) => r.forYear === year))
    //     }
    // }, [absenceDetailsByReasons]);

    const resetFormDate = () => {
        setFormData({
            dateFrom: "",
            dateTo: "",
            totalDays: null,
            workDays: null,
            description: "",
            leaveType: "",
            leaveReasons: "",
            deputy: ""
        })
        setDateValid(true);
    };

    const calculateTotalDays = ({dateFrom = null, dateTo = null}) => {
        // if(formData.dateFrom==="" && !props.selectedLeaveRequestForEdit.id){
        //     return
        // }
        let date1 = null;
        let date2 = null;
        if (dateFrom !== null) {
            date1 = dateFrom;
        } else if (formData.dateFrom === "" && !props.selectedLeaveRequestForEdit.id) {
            date1 = new Date();
        } else if (formData.dateFrom !== "" && !props.selectedLeaveRequestForEdit.id) {
            date1 = formData.dateFrom;
        } else if (formData.dateFrom === "" && props.selectedLeaveRequestForEdit.id) {
            date1 = new Date(props.selectedLeaveRequestForEdit.dateFrom);
        } else if (formData.dateFrom !== "" && props.selectedLeaveRequestForEdit.id) {
            date1 = formData.dateFrom;
        }
        if (dateTo === null && formData.dateTo === "" && props.selectedLeaveRequestForEdit.id) {
            date2 = new Date(props.selectedLeaveRequestForEdit.dateTo);
        } else {
            date2 = dateTo ? dateTo : formData.dateTo;
        }
        // const date1 = new Date(formData.dateFrom !="" ? formData : new Date());
        // const date2 = new Date(date);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        setFormData({
            ...formData,
            totalDays: isEqualsDate(date1, date2) ? 1 : diffDays + 1
        })
    }

    const calculateWorkingLeaveDays = ({dateFrom = "", dateTo = ""}) => {
        setShowSpinner(false)
        let date1 = null;
        if (props.selectedLeaveRequestForEdit.id && formData.dateFrom === "") {
            date1 = new Date(props.selectedLeaveRequestForEdit.dateFrom);
        } else if (dateFrom !== "") {
            date1 = new Date(dateFrom);
        } else if (formData.dateFrom !== "") {
            date1 = new Date(formData.dateFrom);
        } else {
            date1 = new Date();
        }
        let date2 = null;
        if ((props.selectedLeaveRequestForEdit.id && formData.dateTo === "") && (props.selectedLeaveRequestForEdit.id && dateTo === "")) {
            date2 = new Date(props.selectedLeaveRequestForEdit.dateTo);
        } else if (dateTo !== "") {
            date2 = new Date(dateTo);
            date2.setHours(date2.getHours() + 3);
        } else if (formData.dateTo !== "") {
            date2 = new Date(formData.dateTo);
            date2.setHours(date2.getHours() + 3);
        }
        let date2Temp = null

        if (date2 != null) {
            if ((parseInt(date2.getDate())) < 10 && (parseInt(date2.getMonth()) < 9)) {
                date2Temp = "" + date2.getFullYear() + "-0" + (date2.getMonth() + 1) + "-0" + date2.getDate();
            } else if (parseInt(date2.getDate()) < 10) {
                date2Temp = "" + date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-0" + date2.getDate();
            } else if (parseInt(date2.getMonth()) < 9) {
                date2Temp = "" + date2.getFullYear() + "-0" + (date2.getMonth() + 1) + "-" + date2.getDate();
            } else {
                date2Temp = "" + date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
            }
        }

        LeaveRequestRepository.getCalculateTotalLeaveDays(date1, date2Temp)
            .then((data) => {
                date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                setFormData({
                    ...formData,
                    workDays: data.data,
                    dateTo: date2,
                    dateFrom: date1,
                    totalDays: isEqualsDate(date1, date2) ? 1 : diffDays + 1
                })
            }).catch(() => {
        })
        setShowSpinner(true);
    };

    const calculateTotalLeaveDate = (e) => {
        if (e.target.value !== "") {
            setShowSpinner(false);
            let date1 = null;
            if (formData.dateFrom === "" && !props.selectedLeaveRequestForEdit.id) {
                date1 = new Date();
            } else if (props.selectedLeaveRequestForEdit.id && formData.dateFrom === "") {
                date1 = new Date(props.selectedLeaveRequestForEdit.dateFrom);
            } else if (formData.dateFrom !== "") {
                date1 = new Date(formData.dateFrom);
            } else {
                date1 = new Date();
            }
            LeaveRequestRepository.getCalculateTotalLeaveDate(date1, e.target.value)
                .then((data) => {
                    let temp = data.data;
                    let tempArray = temp.split("-");
                    let finalDate = new Date(tempArray[0], tempArray[1] - 1, tempArray[2], 0, 0, 0, 0);

                    const diffTime = Math.abs(finalDate - date1);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    setFormData({
                        ...formData,
                        dateTo: finalDate,
                        workDays: e.target.value,
                        totalDays: isEqualsDate(date1, finalDate) ? 1 : diffDays + 1
                    })

                }).catch(() => {
                setFormData({
                    ...formData,
                    dateTo: ""
                })
            })
            setShowSpinner(true);
        }
    };


    const isEqualsDate = (date1, date2) => {
        return (date1.getDate() === date2.getDate() && date1.getMonth() == date2.getMonth() && date1.getFullYear() === date2.getFullYear())
    };


    const handleChange = (e) => {
        const value = e.target.value;
        if (e.target.hasAttribute("required") && value.trim() === "") {
          e.target.setCustomValidity(strings.fieldsRequired);
        } else {
          e.target.setCustomValidity("");
        }
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    };

    const handleDateFromChange = (date) => {
        if (formData.dateTo != "") {
            checkDateValues(date, formData.dateTo);
        }
        setFormData({
            ...formData,
            dateFrom: date
        })
        calculateWorkingLeaveDays({dateFrom: date})
    };

    const handleDateToChange = (date) => {
        if (formData.dateFrom) {
            checkDateValues(formData.dateFrom, date);
        } else if (!props.selectedLeaveRequestForEdit.id) {
            checkDateValues(new Date(), date);
        } else {
            checkDateValues(props.selectedLeaveRequestForEdit.dateFrom, date);
        }

        setFormData({
            ...formData,
            dateTo: date
        })
        calculateWorkingLeaveDays({dateTo: date});
    };

    const checkDateValues = (from, to) => {
        let dateFrom = new Date(from);
        let dateTo = new Date(to);
        if (isEqualsDate(from, to)) {
            setDateValid(true);
        } else if (dateFrom > dateTo) {
            setDateValid(false);
        } else {
            setDateValid(true);
        }
    };

    const checkAllowedDays = () => {
        if (absenceDetailsByReasonsForCurrentYear.remainingDays) {
            var year = new Date().getFullYear();
            let remainingDays = absenceDetailsByReasonsForCurrentYear.remainingDays
            remainingDays >= formData.workDays ? setNoAllowedDays(false) : setNoAllowedDays(true);
            remainingDays >= formData.workDays ? setDisableButton(false) : setDisableButton(true);
            setAbsenceDetailsByReasonsForCurrentYear(absenceDetailsByReasonsForCurrentYear)
        }
    }

    const selectLeaveType = (e) => {
        setFormData({
            ...formData,
            leaveType: leaveType.find(el => el.id === e.value)
        })
        setSelectedLeaveType({value: e.value, label: e.label});
    };

    const selectLeaveReasons = (e) => {
        setFormData({
            ...formData,
            leaveReasons: leaveReasons.find(el => el.id === e.value)
        })
        setSelectedLeaveReasons({value: e.value, label: e.label});
    };

    const selectedDeputyChange = (e) => {
        setFormData({
            ...formData,
            deputy: deputiesList.find(el => el.id === e.value)
        })
        setSelectedDeputy({value: e.value, label: e.label});
    };

    const successfulAlert = () => {
        swal(strings.actionSuccessful, {
            icon: "success",
        });
    };

    const warningAlert = (errorText) => {
        swal({
            title: strings.oops,
            text: errorText,
            icon: "error",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {

        });
    };

    const addLeaveRequest = (dateFrom, dateTo, totalDays, workDays, description, leaveType, leaveReason, deputy) => {
        LeaveRequestRepository.addLeaveRequest(new Date(dateFrom), new Date(dateTo), totalDays, workDays, description, leaveType?.id, leaveReason?.id, deputy?.id)
            .then((date) => {
                setShowSpinner(true);
                successfulAlert();
                resetFormDate();
                props.handleClose();
                props.getAllLeaveRequests();
            }).catch((error) => {
            setShowSpinner(true);
            let errorText = "";
            if (error.response.data == "DuplicateLeaveRequestException") {
                errorText = strings.duplicateLeaveRequestException;
            } else if (error.response.data == "No available days") {
                errorText = strings.noAvailableDays
            } else if (error.response.data == "No available days for this year") {
                errorText = strings.noAvailableDaysForThisYear
            } else if (error.response.data == "Already absent") {
                errorText = strings.alreadyHaveSickLeaveDuring;
            } else if(error.response.data == "No days available, you have pending leave requests"){
                errorText = strings.youHaveNoDaysLeftDueToPendingRequests
            } else {
                errorText = strings.somethingWrong;
            }
            warningAlert(errorText);
        })
    };

    const editLeaveRequest = (id, dateFrom, dateTo, totalDays, workDays, description, selectedLeaveType, selectedLeaveReasons, deputy) => {
        LeaveRequestRepository.editLeaveRequest(id, new Date(dateFrom), dateTo, totalDays, workDays, description, selectedLeaveType, selectedLeaveReasons, deputy)
            .then(() => {
                setShowSpinner(true);
                successfulAlert();
                resetFormDate();
                props.handleClose();
                props.getAllLeaveRequests();
            }).catch((error) => {
            setShowSpinner(true);
            let errorText = "";
         if(error?.response?.data == "No days available, you have pending leave requests"){
                errorText = strings.youHaveNoDaysLeftDueToPendingRequests
            } else {
                errorText = strings.somethingWrong;
            }
            warningAlert(errorText);
        })
    };

    const getAllAbsencesByLoggedUser = () => {
        LeaveRequestRepository.getAllAbsencesByLoggedUser().then((days) => {
            setAbsenceDetails(days.data);
        })
    };

    const getAllLeaveType = () => {
        LeaveTypeRepository.getAllLeaveType().then((data) => {
            setLeaveType(data.data)
            // setSelectedLeaveType({value: data.data[0].id, label: data.data[0].name});
        })
    };

    const getLeaveReasons = (id) => {
        LeaveReasonService.getAllLeaveReasonsByLeaveTypeId(id)
            .then((data) => {
                setLeaveReasons(data.data)
                if (data.data.length == 0){
                    setNoInfoForSelectedLeaveType(true);
                }else{
                    setNoInfoForSelectedLeaveType(false);
                }
                let filteredReasons = data.data.filter((e) => e?.name.toLowerCase() == "Годишен одмор".toLowerCase());
                if (filteredReasons.length > 0) {
                    setSelectedLeaveReasons({
                            label: filteredReasons[0].name,
                            value: filteredReasons[0].id
                        }
                    )
                }
            })
    };

    const getDeputy = () => {
        AbsencesService.getDeputies()
            .then((data) => {
                setDeputiesList(data.data);
            })
    };

    const onFormSubmit = (e) => {
        setShowSpinner(false);
        e.preventDefault();
        let dateFrom = formData.dateFrom !== "" ? formData.dateFrom : props.selectedLeaveRequestForEdit.dateFrom;
        if (dateFrom === undefined || dateFrom === null) {
            dateFrom = new Date();
        }

        const dateTo = formData.dateTo !== "" ? formData.dateTo : props.selectedLeaveRequestForEdit.dateTo;
        const totalDays = formData.totalDays !== "" ? formData.totalDays : props.selectedLeaveRequestForEdit.totalDays;
        const workDays = formData.workDays !== "" ? formData.workDays : props.selectedLeaveRequestForEdit.workDays;
        const description = formData.description !== "" ? formData.description : props.selectedLeaveRequestForEdit.description;
        let leaveType = formData.leaveType !== "" ? formData.leaveType : props.selectedLeaveRequestForEdit.leaveType;
        let leaveReason = formData.leaveReasons !== "" ? formData.leaveReasons : props.selectedLeaveRequestForEdit.leaveReason;
        let deputy = formData.deputy !== "" ? formData.deputy : props.selectedLeaveRequestForEdit.deputy;
        let stringDateTo = getStringFromDate(dateTo);
        if (props.selectedLeaveRequestForEdit.id) {
            leaveType = leaveType.id;
            leaveReason = leaveReason.id;
            deputy = deputy?.id;
            if (props.selectedLeaveRequestForEdit.leaveType.id === leaveType) {
                leaveType = null;
            }
            if (props.selectedLeaveRequestForEdit.leaveReason.id === leaveReason) {
                leaveReason = null;
            }
            if (props.selectedLeaveRequestForEdit.deputy?.id === deputy) {
                deputy = null;
            }

            editLeaveRequest(props.selectedLeaveRequestForEdit.id, dateFrom, stringDateTo, totalDays, workDays, description, leaveType, leaveReason, deputy);
        } else {
            addLeaveRequest(dateFrom, stringDateTo, totalDays, workDays, description, leaveType, leaveReason, deputy);
        }

    };

    const columns = [
        {
            dataField: 'forYear',
            text: strings.forYear,
        },
        {
            dataField: 'allowedDays',
            text: strings.allowedDays,
        },
        {
            dataField: 'remainingDays',
            text: strings.remainingDays
        }
    ];

    const getClassStyleForStatus = (status) => {
        if (status == "NEW") {
            return "new_notification";
        } else if (status == "DECLINED") {
            return " declined_notification";
        } else if (status == "APPROVED") {
            return "approved_notification";
        } else if (status == "RETURNED FOR CORRECTION") {
            return "returned_notification";
        } else if (status == "CORRECTED") {
            return "corrected_notification";
        }
    };

    const getStringFromDate = (date) => {
        if (typeof date === "string") {
            return date;
        }
        let stringDate = null;
        if ((parseInt(date.getDate())) < 10 && (parseInt(date.getMonth()) < 9)) {
            stringDate = "" + date.getFullYear() + "-0" + (date.getMonth() + 1) + "-0" + date.getDate();
        } else if (parseInt(date.getDate()) < 10) {
            stringDate = "" + date.getFullYear() + "-" + (date.getMonth() + 1) + "-0" + date.getDate();
        } else if (parseInt(date.getMonth()) < 9) {
            stringDate = "" + date.getFullYear() + "-0" + (date.getMonth() + 1) + "-" + date.getDate();
        } else {
            stringDate = "" + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        }
        return stringDate;
    };

    const getDateForFormDateCalendar = () => {
        if (props.selectedLeaveRequestForEdit.id && formData.dateFrom === "") {
            return new Date(props.selectedLeaveRequestForEdit.dateFrom);
        } else if (formData.dateFrom !== "") {
            return formData.dateFrom
        } else {
            return new Date();
        }
    };

    const getDateForDateToCalendar = () => {
        if (props.selectedLeaveRequestForEdit.id && formData.dateTo === "") {
            return new Date(props.selectedLeaveRequestForEdit.dateTo);
        } else if (formData.dateTo !== "") {
            return formData.dateTo
        } else {
            return "";
        }
    };

    const checkDisabled = () => {
        if (props.selectedLeaveRequestForEdit.id) {
            return props.selectedLeaveRequestForEdit.leaveRequestStatus.name === "DECLINED"
        }
    };

    const disabledPastDays = (date) => {
        let currentMonth = new Date().getMonth();
        let currentYear = new Date().getFullYear();
        if (date.getFullYear() < currentYear) {
            return false;
        } else if (date.getFullYear() == currentYear) {
            return date.getMonth() >= currentMonth;
        } else {
            return true;
        }
    };

    const handleDeputySelect = (e) => {
        if (e.target.value === "yes") {
            setShowDeputyInput(true);
            getDeputy();
        } else {
            setShowDeputyInput(false);
        }
    };

    return (
        <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={"lg"} centered>
            <ModalHeader closeButton>
                {props.selectedLeaveRequestForEdit.id ? (
                    <ModalTitle>
                        <div className={"d-flex "}>
                            <div>{strings.editLeaveRequest}</div>
                            <div
                                className={"status-position-end d-flex text-center align-items-center " + getClassStyleForStatus(props.selectedLeaveRequestForEdit.leaveRequestStatus.name)}>{ApplicationService.getActiveLanguage() === "mk" ? (props.selectedLeaveRequestForEdit.leaveRequestStatus.label_mk) : (props.selectedLeaveRequestForEdit.leaveRequestStatus.label_en)}</div>
                        </div>
                    </ModalTitle>
                ) : (
                    <ModalTitle><b>{strings.requestALeave}</b></ModalTitle>
                )
                }
            </ModalHeader>
            <Form onSubmit={onFormSubmit}>
                <ModalBody>
                    {props.selectedLeaveRequestForEdit.id && (props.selectedLeaveRequestForEdit.leaveRequestStatus.name === "DECLINED" || props.selectedLeaveRequestForEdit.leaveRequestStatus.name == "RETURNED FOR CORRECTION") &&
                        <Form.Group className="row mb-3" controlId="date">
                            <h4 className={"text-center"}>{strings.responseDescription}</h4>
                            <div className={"w-100 text-center mb-4 align-items-center d-flex justify-content-center"}>
                                {/*<textarea className={"w-75 form-control text-center free-text"} disabled={true}*/}
                                {/*          value={props.selectedLeaveRequestForEdit.statusDescription}></textarea>*/}
                                <div className="response-card b-1 hover-shadow mb-20 w-100 mt-3">
                                    <div
                                        className={"text-secondary free-area-text"}> {props.selectedLeaveRequestForEdit.statusDescription}</div>
                                </div>

                            </div>
                        </Form.Group>
                    }
                    <Form.Group className="row mb-3" controlId="date">
                        <Form.Group className="col-6">
                            <Form.Label>{strings.dateFrom}<span className="text-danger">*</span></Form.Label> <br/>
                            <DatePicker
                                className="react-datepicker w-100"
                                selected={
                                    getDateForFormDateCalendar()
                                }
                                filterDate={disabledPastDays}
                                disabled={checkDisabled()}
                                required
                                onChange={(date) => {
                                    handleDateFromChange(date);
                                    calculateTotalDays({dateFrom: date});
                                    checkAllowedDays(date);
                                }}
                                dateFormat="dd.MM.yyyy"
                                onInvalid={(e) => {
                                    e.target.setCustomValidity(strings.fieldsRequired);
                                  }}
                            />
                        </Form.Group>
                        <Form.Group className="col-6">
                            <Form.Label>{strings.dateTo}<span className="text-danger">*</span></Form.Label> <br/>
                            <DatePicker
                                className="react-datepicker w-100"
                                selected={getDateForDateToCalendar()}
                                required
                                disabled={checkDisabled()}
                                filterDate={disabledPastDays}
                                onChange={(date) => {
                                    handleDateToChange(date);
                                    calculateTotalDays({dateTo: date});
                                    checkAllowedDays(date);
                                }}
                                dateFormat="dd.MM.yyyy"
                                onInvalid={(e) => {
                                    e.target.setCustomValidity(strings.fieldsRequired);
                                  }}
                            />

                        </Form.Group>
                    </Form.Group>
                    {dateValid ? null :
                        <div className="alert alert-danger row-cols-6 mt-2"
                             role="alert">{strings.enterValidateDate}</div>}
                    <Form.Group className="row" controlId="days">
                        <Form.Group className="col-6 mb-2" controlId="workDays">
                            <Form.Label>{strings.workingDays}<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="number"
                                placeholder={strings.day}
                                autoFocus
                                required
                                disabled={checkDisabled()}
                                defaultValue={props.selectedLeaveRequestForEdit.calculatedWorkDays}
                                onChange={(e) => {
                                    if (e.target.value === "") {
                                        e.target.value = null;
                                    }

                                    handleChange(e);
                                    calculateTotalLeaveDate(e);
                                    checkAllowedDays(e);
                                }}
                                name="workDays"
                                value={formData.workDays}
                                className="mb-3"
                                onInvalid={(e) => {
                                    e.target.setCustomValidity(strings.fieldsRequired);
                                  }}
                                />
                        </Form.Group>
                        <Form.Group className="col-6 mb-2" controlId="totalDays">
                            <Form.Label>{strings.totalDays}</Form.Label>
                            <Form.Control
                                type="number"
                                autoFocus
                                disabled={true}
                                defaultValue={props.selectedLeaveRequestForEdit.totalDays}
                                readOnly={true}
                                onChange={(e) => {
                                    if (e.target.value === "") {
                                        e.target.value = null;
                                    }

                                    handleChange(e);
                                }}
                                name="totalDays"
                                value={formData.totalDays}
                                className="mb-3"/>
                        </Form.Group>
                    </Form.Group>
                    <Form.Group className="mb-3 justifyContent" controlId="description">
                        <Form.Label>{strings.description}</Form.Label>
                        <textarea
                            className={"w-100 free-area-text form-control text-start"}
                            placeholder={strings.enterDescription}
                            defaultValue={props.selectedLeaveRequestForEdit.description}
                            disabled={checkDisabled()}
                            onChange={handleChange}
                            name="description">
                        </textarea>
                    </Form.Group>
                    <Row>
                        <Form.Group className="col-5 mb-3">
                            <Form.Label>{strings.doYouWantToSelectADeputy}</Form.Label>
                            <div className={"d-flex ms-2"}>
                                <div className={"me-2"}>
                                    <input type="radio" value="yes" name="deputy"
                                           defaultChecked={props.selectedLeaveRequestForEdit.deputy != null ? true : false}
                                           disabled={checkDisabled()}
                                           onChange={handleDeputySelect}/> {strings.yes}
                                </div>
                                <div>
                                    <input type="radio" onChange={handleDeputySelect}
                                           defaultChecked={props.selectedLeaveRequestForEdit.deputy != null ? false : true}
                                           value="no"
                                           disabled={checkDisabled()}
                                           name="deputy"/> {strings.no}
                                </div>
                            </div>
                        </Form.Group>
                        {showDeputyInput &&
                            <Form.Group className="col-7 mb-3">
                                <Form.Label>{strings.selectDeputy}<span className="text-danger">*</span></Form.Label>
                                <Select required
                                        name="deputies"
                                        options={
                                            deputiesList?.map((user) => (
                                                {value: user.id, label: user.firstName + " " + user.lastName}
                                            ))}
                                        isDisabled={checkDisabled()}
                                        onChange={selectedDeputyChange}
                                        value={selectedDeputy}
                                        onInvalid={(e) => {
                                            e.target.setCustomValidity(strings.fieldsRequired);
                                          }}
                                />
                            </Form.Group>
                        }
                    </Row>
                    <Form.Label>{strings.leaveType}<span className="text-danger">*</span></Form.Label>
                    {
                        selectedLeaveType && leaveType.length > 0 &&
                        <Select required
                                name="leaveType"
                                options={
                                    leaveType?.map((item) => (
                                        {value: item.id, label: item.labelMk}
                                    ))}
                                isDisabled={checkDisabled()}
                                onChange={selectLeaveType}
                                value={selectedLeaveType}
                                onInvalid={(e) => {
                                    e.target.setCustomValidity(strings.fieldsRequired);
                                  }}

                        />
                    }
                    {noInfoForSelectedLeaveType ? (
                        <div className="alert alert-danger row-cols-6 mt-3"
                             role="alert">{strings.noInfoForSelectedLeaveType}</div>):null}
                    {(props.selectedLeaveRequestForEdit.id || selectedLeaveType.value && leaveReasons.length > 0) &&
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="mt-3 justifyContent" controlId="reasons">
                                    <Form.Label>{strings.reasonsForLeaveRequest}<span className="text-danger">*</span></Form.Label>
                                    <Select required
                                            name="leaveReasons"
                                            options={
                                                leaveReasons?.map((item) => (
                                                    {value: item.id, label: item.name}
                                                ))}
                                            onChange={selectLeaveReasons}
                                            isDisabled={checkDisabled()}
                                            value={selectedLeaveReasons}
                                            defaultValue={selectLeaveReasons}
                                            onInvalid={(e) => {
                                                e.target.setCustomValidity(strings.fieldsRequired);
                                              }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mt-4 d-flex text-align-end justify-content-end"
                                            controlId="dayLeft">
                                    <Form.Group className={"d-flex justify-content-end align-items-end"}>
                                        <Form.Group className={"text-center"}>
                                            <Form.Label
                                                className={"justify-content-center d-flex"}>{strings.allowedDays}:<span className="text-danger">*</span></Form.Label>
                                            <Form.Control className={"w-50 h-100 mx-auto"}
                                                          type="text"
                                                          autoFocus
                                                          required
                                                          disabled={checkDisabled()}
                                                          value={absenceDetailsByReasonsForCurrentYear.allowedDays ? absenceDetailsByReasonsForCurrentYear?.allowedDays : ""}
                                                          readOnly={true}
                                                          onChange={handleChange}
                                                          name="description"
                                                          onInvalid={(e) => {
                                                            e.target.setCustomValidity(strings.fieldsRequired);
                                                          }}
                                            />
                                        </Form.Group>
                                        <Form.Group className={"text-center "}>
                                            <Form.Label
                                                className={"justify-content-center d-flex"}>{strings.remainingDays}:<span className="text-danger">*</span></Form.Label>
                                            <Form.Group className={"d-inline-flex justify-content-center"}>
                                                <Form.Control className={"w-50 h-100"}
                                                              type="text"
                                                              autoFocus
                                                              required
                                                              disabled={checkDisabled()}
                                                              value={absenceDetailsByReasonsForCurrentYear.remainingDays !=="" ? absenceDetailsByReasonsForCurrentYear?.remainingDays : ""}
                                                              readOnly={true}
                                                              onChange={handleChange}
                                                              name="description"
                                                              onInvalid={(e) => {
                                                                e.target.setCustomValidity(strings.fieldsRequired);
                                                              }}
                                                />
                                                {(absenceDetailsByReasonsForCurrentYear?.remainingDays >= formData.workDays &&  absenceDetailsByReasonsForCurrentYear?.remainingDays!==0) ?
                                                    (
                                                        <i className="material-icons mx-1 align-items-center d-flex text-success">check_circle</i>
                                                    ) : (
                                                        <i className="material-icons mx-1 align-items-center d-flex text-danger ">cancel</i>
                                                    )
                                                }
                                            </Form.Group>
                                        </Form.Group>
                                    </Form.Group>
                                </Form.Group>
                            </Col>
                        </Row>
                    }
                    {noAllowedDays && (props.selectedLeaveRequestForEdit.id || selectedLeaveReasons.value) &&
                        <div className={"alert alert-danger my-4"}>{strings.noEnoughAvailableDays}</div>

                    }
                    {(props.selectedLeaveRequestForEdit.id || selectedLeaveReasons.value) &&
                        <>
                            <h2 className="mt-5 mb-5 remaining-days-header"><span>{strings.remainingDays}</span></h2>
                            <BootstrapTable
                                keyField='id'
                                data={absenceDetailsByReasons}
                                columns={columns}
                                bordered={true}
                                hover={false}
                                headerClasses={"headerStyleModal remainingDaysTable"}
                                wrapperClasses={"text-center "}
                                bodyClasses={"bodyStyleModal"}/>
                        </>
                    }
                </ModalBody>

                {props.selectedLeaveRequestForEdit.id ?
                    (props.selectedLeaveRequestForEdit.leaveRequestStatus.name !== "DECLINED" &&
                        <ModalFooter>
                            <button type={"submit"}
                                    className={(disableButton || !dateValid) ? "disabled cancel btn text-white " : "asd save btn text-white"}>
                                {strings.sendRequest}
                            </button>
                        </ModalFooter>
                    )
                    :
                    <ModalFooter>
                        <button type={"submit"}
                                className={(disableButton || !dateValid) ? "disabled cancel btn text-white " : "asd save btn text-white"}>
                            {strings.sendRequest}
                        </button>
                    </ModalFooter>
                }

            </Form>
            <SpinnerComponent spinnerShow={spinnerShow}/>
        </Modal>
    )
}
export default LeaveRequestsModal;