import React, {useEffect, useRef, useState} from "react";
import UserService from "../../../../repository/userRepository/UserRepository";
import {useParams} from "react-router-dom";
import "./UserListForHoliday.css"
import ReactPaginate from "react-paginate";
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";
import HolidayService from "../../../../repository/holidayRepository/holidayRepository";
import UserFilter from "../../../../components/UserFilter/UserFilter";
import {strings} from "../../../../localization/Localization";
import swal from "sweetalert";
import AddRemoveUserFromHoliday from "./AddRemoveUserFromHolidayList/AddRemoveUserFromHoliday";

const UserListForHoliday = (props) => {

    const [pageListOfHolidayUser, setPageListOfHolidayUser] = useState(0);

    const [sizeListOfHolidayUser, setSizeListOfHolidayUser] = useState(5);

    const [pageCountListOfHolidayUser, setPageCountListOfHolidayUser] = useState();

    const [pageListOfOtherUserList, setPageListOfOtherUserList] = useState(0);

    const [sizeListOfOtherUserList, setSizeListOfOtherUserList] = useState(5);

    const [pageCountListOfOtherUserList, setPageCountListOfOtherUserList] = useState();

    const [allUserList, setAllUserList] = useState([]);

    const [usersForSelectedHoliday, setUsersForSelectedHoliday] = useState([]);

    const [selectedUserFromAllUserList, setSelectedUserFromAllUserList] = useState([]);

    const [selectedUserFromHolidayList, setSelectedUserFromHolidayList] = useState([]);

    const [showFilterForHolidayList, setShowFilterForHolidayList] = useState(false)

    const [showFilterForOtherList, setShowFilterForOtherList] = useState(false)

    const [filterDateForHolidayUserList, updateFilterDateForHolidayUserList] = React.useState({
        firstName: null,
        lastName: null,
        parentName: null,
        email: null,
        hrmsIdNumber:null,
        roles: [],
        religionIds: [],
        ethnicityId: [],
        organisationsIds: [],
        positionsIds: []
    });

    const [filterDateForOtherUserList, updateFilterDateForOtherUserList] = React.useState({
        firstName: null,
        lastName: null,
        parentName: null,
        email: null,
        hrmsIdNumber:null,
        roles: [],
        religionIds: [],
        ethnicityId: [],
        organisationsIds: [],
        positionsIds: []
    });

    const [checkAllUserForHoliday, setCheckAllUserForHoliday] = useState(false);

    const [checkAllOtherUser, setCheckAllOtherUser] = useState(false);

    const [resetIsLoadingUserForHoliday, setResetIsLoadingUserForHoliday] = useState(false);

    const [searchIsLoadingUserForHoliday, setSearchIsLoadingUserForHoliday] = useState(false);

    const [resetIsLoadingUserWithoutHoliday, setResetIsLoadingUserWithoutHoliday] = useState(false);

    const [searchIsLoadingUserWithoutHoliday, setSearchIsLoadingUserWithoutHoliday] = useState(false);

    const params = useParams();

    const divRef = useRef(null);

    useEffect(() => {
        loadAllUser(null, null, null, null,null, [], [], [], [], [], 0, 5);
        loadUserForHoliday(null, null, null, null,null, [], [], [], [], [], 0, 5);
        if (props.scroll) {
            scrollToBottom();
        }
    }, []);

    useEffect(() => {
        loadUserForHoliday(filterDateForHolidayUserList.firstName, filterDateForHolidayUserList.lastName, filterDateForHolidayUserList.parentName, filterDateForHolidayUserList.email,filterDateForHolidayUserList.hrmsIdNumber, filterDateForHolidayUserList.roles, filterDateForHolidayUserList.religionIds, filterDateForHolidayUserList.ethnicityId, filterDateForHolidayUserList.organisationsIds, filterDateForHolidayUserList.positionsIds, pageListOfHolidayUser, sizeListOfHolidayUser);
    }, [pageListOfHolidayUser]);

    useEffect(() => {
        loadAllUser(filterDateForOtherUserList.firstName, filterDateForOtherUserList.lastName, filterDateForOtherUserList.parentName, filterDateForOtherUserList.email,filterDateForOtherUserList.hrmsIdNumber, filterDateForOtherUserList.roles, filterDateForOtherUserList.religionIds, filterDateForOtherUserList.ethnicityId, filterDateForOtherUserList.organisationsIds, filterDateForOtherUserList.positionsIds, pageListOfOtherUserList, sizeListOfOtherUserList);
    }, [pageListOfOtherUserList])

    const loadAllUser = (firstName, lastName, parentName, email,hrmsIdNumber, roles, religionIds, ethnicityId, organisationsIds, positionsIds, pageListOfOtherUserList, sizeListOfOtherUserList, resetDate = false, searchData = false) => {
        if (resetDate) {
            setResetIsLoadingUserWithoutHoliday(true);
        } else if (searchData) {
            setSearchIsLoadingUserWithoutHoliday(true);
        }
        UserService.getAllUserWithoutHoliday(firstName, lastName, parentName, email,hrmsIdNumber, roles, religionIds, ethnicityId, params.id, organisationsIds, positionsIds, pageListOfOtherUserList, sizeListOfOtherUserList)
            .then((data) => {
                setAllUserList(data.data.content);
                setPageListOfOtherUserList(pageListOfOtherUserList)
                setPageCountListOfOtherUserList(data.data.totalPages);
                if (resetDate) {
                    setResetIsLoadingUserWithoutHoliday(false);
                } else if (searchData) {
                    setSearchIsLoadingUserWithoutHoliday(false);
                }
            }).catch(()=>{
                setResetIsLoadingUserWithoutHoliday(false);
                setSearchIsLoadingUserWithoutHoliday(false);
        });
    };

    const loadUserForHoliday = (firstName, lastName, parentName, email,hrmsIdNumber, roles, religionIds, ethnicityId, organisationsIds, positionsIds, pageListOfHolidayUser, sizeListOfHolidayUser, resetDate = false, searchData = false) => {
        if (resetDate) {
            setResetIsLoadingUserForHoliday(true);
        } else if (searchData) {
            setSearchIsLoadingUserForHoliday(true);
        }
        UserService.getAllUserForHoliday(firstName, lastName, parentName, email,hrmsIdNumber, roles, religionIds, ethnicityId, params.id, organisationsIds, positionsIds, pageListOfHolidayUser, sizeListOfHolidayUser)
            .then((data) => {
                setUsersForSelectedHoliday(data.data.content);
                setPageCountListOfHolidayUser(data.data.totalPages);
                setPageListOfHolidayUser(pageListOfHolidayUser)
                if (resetDate) {
                    setResetIsLoadingUserForHoliday(false);
                } else if (searchData) {
                    setSearchIsLoadingUserForHoliday(false);
                }
            }).catch(()=>{
                setResetIsLoadingUserForHoliday(false);
                setSearchIsLoadingUserForHoliday(false);
        });
    };

    const loadAllCheckUserWithHoliday = (filterDateForHolidayUserList) => {
        props.setSpinnerShow(false);
        UserService.getAllUserForHolidayFiltered(filterDateForHolidayUserList, params.id)
            .then((data) => {
                setSelectedUserFromHolidayList(data.data, ...selectedUserFromHolidayList);
                props.setSpinnerShow(true);
            }).catch(()=>{
            props.setSpinnerShow(true);
        })
    }

    const loadAllCheckUserWithoutHoliday = (filterDateForOtherUserList) => {
        props.setSpinnerShow(false);
        UserService.getAllUserWithoutHolidayFiltered(filterDateForOtherUserList, params.id)
            .then((data) => {
                setSelectedUserFromAllUserList(data.data, ...selectedUserFromHolidayList);
                props.setSpinnerShow(true);
            }).catch(()=>{
            props.setSpinnerShow(true);
        })
    }

    const resetFilterDataForOtherUser = () => {
        updateFilterDateForOtherUserList({
            firstName: null,
            lastName: null,
            parentName: null,
            email: null,
            hrmsIdNumber:null,
            roles: [],
            religion: [],
            ethnicity: [],
            organisation: [],
            position: []
        });

    };

    const resetFilterDataForHolidayFilter = () => {
        updateFilterDateForHolidayUserList({
            firstName: null,
            lastName: null,
            parentName: null,
            email: null,
            hrmsIdNumber:null,
            roles: [],
            religion: [],
            ethnicity: [],
            organisation: [],
            position: []
        });
    };

    const scrollToBottom = () => {
        divRef.current.scrollIntoView({behavior: 'smooth'});
    };


    const removeUserFormList = (list, e) => {
        return list.filter(u => u.id != e);
    };

    const confirmRemoveAlert = () => {
        swal({
            title: strings.areYouSure,
            text: strings.confirmRemoveUserFromHoliday + selectedUserFromHolidayList.length + " " + strings.confirmRemoveUserFromHolidayMemberNumber,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                removeMembers();
            } else {

            }
        });
    };

    const confirmAddAlert = () => {
        swal({
            title: strings.areYouSure,
            text: strings.confirmAddUserToHoliday + selectedUserFromAllUserList.length + " " + strings.confirmAddUserToHolidayMemberNumber,
            icon: "warning",
            buttons: [strings.cancel, strings.ok],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                addMembers();
            } else {

            }
        });
    };


    const addMembers = () => {
        props.setSpinnerShow(false);
        if (selectedUserFromAllUserList.length > 0) {
            let tempList = selectedUserFromAllUserList.map((u) => {
                return u.id
            })
            HolidayService.addUsersHolidays(params.id, tempList)
                .then(() => {
                    loadAllUser(null, null, null, null, null,[], [], [], [], [], 0, 5);
                    loadUserForHoliday(null, null, null, null,null, [], [], [], [], [], 0, 5);
                    setSelectedUserFromHolidayList([]);
                    setPageListOfOtherUserList(0);
                    setPageListOfHolidayUser(0);
                    setSelectedUserFromAllUserList([]);
                    props.setSpinnerShow(true);
                }).catch(()=>{
                props.setSpinnerShow(true);
            })
        }
    };

    const removeMembers = () => {
        props.setSpinnerShow(false);
        let tempList = selectedUserFromHolidayList.map((u) => {
            return u.id
        })
        if (selectedUserFromHolidayList.length > 0) {
            HolidayService.deleteUserForHoliday(params.id, tempList)
                .then(() => {
                    loadAllUser(null, null, null, null, null,[], [], [], [], [], 0, 5);
                    loadUserForHoliday(null, null, null, null,null, [], [], [], [], [], 0, 5);
                    setPageListOfOtherUserList(0);
                    setPageListOfHolidayUser(0);
                    setSelectedUserFromHolidayList([]);
                    setSelectedUserFromAllUserList([]);
                    props.setSpinnerShow(true)
                }).catch(()=>{
                props.setSpinnerShow(true)
            })
        }
    };

    const handleCheckAllUserInOtherList = (event) => {
        if (event.target.checked) {
            loadAllCheckUserWithoutHoliday(filterDateForOtherUserList);
            setCheckAllOtherUser(true);
            setSelectedUserFromAllUserList(allUserList);
        } else {
            setCheckAllOtherUser(false);
            setSelectedUserFromAllUserList([]);
        }
    };

    const handleCheckAllInHolidayUsersList = (event) => {
        if (event.target.checked) {

            loadAllCheckUserWithHoliday(filterDateForHolidayUserList)
            setCheckAllUserForHoliday(true);
            setSelectedUserFromHolidayList(usersForSelectedHoliday);
        } else {
            setCheckAllUserForHoliday(false);
            setSelectedUserFromHolidayList([]);
        }
    };

    const handleChangeInOtherUserList = (event) => {
        if (event.target.checked) {
            setSelectedUserFromAllUserList([...selectedUserFromAllUserList, allUserList.filter((u) => u.id == event.target.value).at(0)])
        } else {
            setSelectedUserFromAllUserList(removeUserFormList(selectedUserFromAllUserList, event.target.value))
        }
    };

    const handleChangeInHolidayUserList = (event) => {
        if (event.target.checked) {
            setSelectedUserFromHolidayList([...selectedUserFromHolidayList, usersForSelectedHoliday.filter((u) => u.id == event.target.value).at(0)])
        } else {
            setSelectedUserFromHolidayList(removeUserFormList(selectedUserFromHolidayList, event.target.value))
        }
    };

    const isChecked = (list, userId) => {
        return list.filter((u) => u.id == userId).length > 0;
    };

    const handleFilterForHolidayList = () => {
        setShowFilterForHolidayList(!showFilterForHolidayList)
    }

    const handleFilterForOtherList = () => {
        setShowFilterForOtherList(!showFilterForOtherList)
    }

    const filterUserInOtherList = (firstName, lastName, parentName, email, roles, religionIds, ethnicityIds, organisationsIds, positionsIds, resetDate = false, searchData = false,hrmsIdNumber) => {
        updateFilterDateForOtherUserList({
            firstName: firstName,
            lastName: lastName,
            parentName: parentName,
            email: email,
            hrmsIdNumber:hrmsIdNumber,
            roles: roles,
            religionIds: religionIds,
            ethnicityId: ethnicityIds,
            organisationsIds: organisationsIds,
            positionsIds: positionsIds
        })
        loadAllUser(firstName, lastName, parentName, email,hrmsIdNumber, roles, religionIds, ethnicityIds, organisationsIds, positionsIds, 0, sizeListOfOtherUserList, resetDate, searchData);
    }

    const filterUserInHolidayUserList = (firstName, lastName, parentName, email, roles, religionIds, ethnicityIds, organisationsIds, positionsIds, resetDate = false, searchData = false,hrmsIdNumber) => {
        updateFilterDateForHolidayUserList({
            firstName: firstName,
            lastName: lastName,
            parentName: parentName,
            email: email,
            hrmsIdNumber:hrmsIdNumber,
            roles: roles,
            religionIds: religionIds,
            ethnicityId: ethnicityIds,
            organisationsIds: organisationsIds,
            positionsIds: positionsIds
        })
        loadUserForHoliday(firstName, lastName, parentName, email, hrmsIdNumber,roles, religionIds, ethnicityIds, organisationsIds, positionsIds, 0, sizeListOfHolidayUser, resetDate, searchData);
    }

    const handlePageClickListOfHolidayUser = (data) => {
        setPageListOfHolidayUser(data.selected)
    }

    const handlePageClickListOfOtherUser = (data) => {
        setPageListOfOtherUserList(data.selected)
    }

    return (
        <div className={"container w-100 text-center align-items-center justify-content-center"}>
            <div className={" my-5 text-center"}>
                <div className="container mt-5 px-2">
                    <div className="d-flex">
                        <div className={"w-95"}>

                            <div className={"w-95"}>
                                <div className={"w-95"}>
                                    <div className="d-inline-flex w-100 text-center d-flex align-items-center">
                                        <h2 className=" h-100 align-middle mx-auto ">{strings.userForHoliday}</h2>
                                        <div
                                            className="btn  btn-secondary d-flex justify-content-center text-end  align-items-center users_filter_button"
                                            onClick={handleFilterForHolidayList}><i
                                            className={"material-icons me-1"}>filter_list</i> {strings.filter}
                                        </div>
                                    </div>
                                    <div className={"m-2"}>
                                        {showFilterForHolidayList &&
                                            <UserFilter loadUserFiltred={filterUserInHolidayUserList}
                                                        resetFilterData={resetFilterDataForHolidayFilter}
                                                        resetIsLoading={resetIsLoadingUserForHoliday}
                                                        searchIsLoading={searchIsLoadingUserForHoliday}/>}
                                    </div>
                                </div>
                            </div>
                            <div className={"w-95 list_border_end"}>
                                <div className={"w-95 table-responsive"}>
                                    <table className="table text-start">
                                        <thead className={"user-table-header custom-table-header"}>
                                        <tr>
                                            <th scope="col" width="10%">
                                                <input className="form-check-input"
                                                       type="checkbox"
                                                       onChange={e => handleCheckAllInHolidayUsersList(e)}
                                                />
                                            </th>
                                            <th scope="col" width="20%">{strings.hrmsIdNumber}</th>
                                            <th scope="col" width="20%">{strings.name}</th>
                                            <th scope="col" width="20%">{strings.lastName}</th>
                                            <th scope="col" width="30%">{strings.email}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {usersForSelectedHoliday.length > 0 ?
                                            (usersForSelectedHoliday.map((user) => {
                                                    return (
                                                        <tr key={user.id}>
                                                            <td scope="row">
                                                                <input className="form-check-input selected"
                                                                       value={user.id}
                                                                       id={user.id}
                                                                       checked={isChecked(selectedUserFromHolidayList, user.id)}
                                                                       onChange={e => handleChangeInHolidayUserList(e)}
                                                                       type="checkbox"/>
                                                            </td>
                                                            <td>{user.hrmsIdNumber}</td>
                                                            <td>{user.firstName}</td>
                                                            <td>{user.lastName}</td>
                                                            <td>{user.email}</td>
                                                        </tr>
                                                    )
                                                })

                                            ) :
                                            (
                                                <tr className={"justify-content-center text-center"}>
                                                    <td colSpan={5}>
                                                        <h6 className={"text-secondary"}>
                                                            <i>{strings.noUserForSelectedHoliday}</i>
                                                        </h6>
                                                    </td>
                                                </tr>
                                            )
                                        }

                                        </tbody>
                                    </table>
                                    {usersForSelectedHoliday.length > 0 &&
                                        <div className={"text-start justify-content-between mt-3 d-flex"}>
                                            <div className={" mx-auto justify-content-center d-flex"}>
                                                <ReactPaginate previousLabel={<AiOutlineArrowLeft/>}
                                                               nextLabel={<AiOutlineArrowRight/>}
                                                               breakLabel={"..."}
                                                               pageCount={pageCountListOfHolidayUser}
                                                               forcePage={pageListOfHolidayUser}
                                                               marginPagesDisplayed={2}
                                                               pageRangeDisplayed={2}
                                                               onPageChange={handlePageClickListOfHolidayUser}
                                                               breakClassName={"page-item"}
                                                               breakLinkClassName={"page-link"}
                                                               containerClassName={"pagination justify-content-center"}
                                                               pageClassName={"page-item"}
                                                               pageLinkClassName={"page-link"}
                                                               previousClassName={"page-item"}
                                                               previousLinkClassName={"page-link"}
                                                               nextClassName={"page-item"}
                                                               nextLinkClassName={"page-link"}
                                                               activeClassName={"active"}/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div
                            className={`list_border_start  d-flex  ${showFilterForHolidayList ? "align-items-end" : "align-items-start"}`}>
                            <AddRemoveUserFromHoliday
                                users={selectedUserFromHolidayList}
                                setSelectedList={setSelectedUserFromHolidayList}
                                removeUserFormList={removeUserFormList}
                                buttonTitle={strings.removeMembers}
                                listTitle={strings.usersToRemoveFromHoliday}
                                confirmAlert={confirmRemoveAlert}/>
                        </div>
                    </div>
                </div>
            </div>
            {/*--------------------------------------------------------------------------------------------------------------*/}
            <div className={" my-5 text-center"}>
                <div className="container mt-5 px-2">
                    <div className="d-flex">
                        <div className={"w-95"}>

                            <div className={"w-95"}>
                                <div className={"w-95"}>
                                    <div
                                        className="d-inline-flex w-100 text-center d-flex align-items-center">
                                        <h2 className=" h-100 align-middle mx-auto ">{strings.otherUser}</h2>
                                        <div
                                            className="btn  btn-secondary d-flex justify-content-center text-end  align-items-center users_filter_button"
                                            onClick={handleFilterForOtherList}><i
                                            className={"material-icons me-1"}>filter_list</i> {strings.filter}
                                        </div>
                                    </div>
                                    <div className={"m-2"}>
                                        {showFilterForOtherList &&
                                            <UserFilter loadUserFiltred={filterUserInOtherList}
                                                        resetFilterData={resetFilterDataForOtherUser}
                                                        resetIsLoading={resetIsLoadingUserWithoutHoliday}
                                                        searchIsLoading={searchIsLoadingUserWithoutHoliday}/>}
                                    </div>
                                </div>
                            </div>
                            <div className={"w-95 list_border_end"}>
                                <div className={"w-95 table-responsive"}>
                                    <table className="table text-start">
                                        <thead className={"user-table-header custom-table-header"}>
                                        <tr>
                                            <th scope="col" width="10%">
                                                <input className="form-check-input"
                                                       type="checkbox"
                                                       onChange={e => handleCheckAllUserInOtherList(e)}/>
                                            </th>
                                            <th scope="col" width="20%">{strings.hrmsIdNumber}</th>
                                            <th scope="col" width="20%">{strings.name}</th>
                                            <th scope="col" width="20%">{strings.lastName}</th>
                                            <th scope="col" width="30%">{strings.email}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {allUserList.length > 0 ?
                                            (allUserList.map((user) => {
                                                    return (
                                                        <tr key={user.id}>
                                                            <td scope="row">
                                                                <input className="form-check-input"
                                                                       onChange={e => handleChangeInOtherUserList(e)}
                                                                       value={user.id}
                                                                       id={user.id}
                                                                       checked={isChecked(selectedUserFromAllUserList, user.id)}
                                                                       type="checkbox"/>
                                                            </td>
                                                            <td>{user.hrmsIdNumber}</td>
                                                            <td>{user.firstName}</td>
                                                            <td>{user.lastName}</td>
                                                            <td>{user.email}</td>

                                                        </tr>
                                                    )
                                                })

                                            ) :
                                            (
                                                <tr className={"justify-content-center text-center"}>
                                                    <td colSpan={5}>
                                                        <h6 className={"text-secondary"}>
                                                            <i>{strings.noOtherUser}</i>
                                                        </h6>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                    {allUserList.length > 0 &&
                                        <div className={"text-end justify-content-between d-flex mt-3"}>
                                            <div className={"mx-auto"}>
                                                <ReactPaginate previousLabel={<AiOutlineArrowLeft/>}
                                                               nextLabel={<AiOutlineArrowRight/>}
                                                               breakLabel={"..."}
                                                               pageCount={pageCountListOfOtherUserList}
                                                               marginPagesDisplayed={2}
                                                               forcePage={pageListOfOtherUserList}
                                                               pageRangeDisplayed={2}
                                                               onPageChange={handlePageClickListOfOtherUser}
                                                               breakClassName={"page-item"}
                                                               breakLinkClassName={"page-link"}
                                                               containerClassName={"pagination justify-content-center"}
                                                               pageClassName={"page-item"}
                                                               pageLinkClassName={"page-link"}
                                                               previousClassName={"page-item"}
                                                               previousLinkClassName={"page-link"}
                                                               nextClassName={"page-item"}
                                                               nextLinkClassName={"page-link"}
                                                               activeClassName={"active"}/>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div ref={divRef}></div>
                            </div>

                        </div>
                        <div
                            className={`list_border_start d-flex ${showFilterForOtherList ? "align-items-end" : "align-items-start"}`}>
                            <AddRemoveUserFromHoliday
                                users={selectedUserFromAllUserList}
                                setSelectedList={setSelectedUserFromAllUserList}
                                removeUserFormList={removeUserFormList}
                                listTitle={strings.usersToAddToHoliday}
                                buttonTitle={strings.addMembers}
                                confirmAlert={confirmAddAlert}/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default UserListForHoliday;