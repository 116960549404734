import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown";
import UserRepository from "../../../repository/userRepository/UserRepository";
import ProjectUserRepository from "../../../repository/projectsRepository/projectUserRepository";
import projectUserRepository from "../../../repository/projectsRepository/projectUserRepository";
import { strings } from "../../../localization/Localization";
import NomenclatureService from "../../../repository/nomenclatureRepository/NomenclatureRepository";
import ApplicationService from "../../../ApplicationService";
import Select from "react-select";
import DraggableModalDialog from "../../../components/DraggableModalDialog/DraggableModalDialog";

const AddUserToProjectModal = (props) => {
  const [technologies, setTechnologies] = useState([]);

  const [selectedTechnology, setSelectedTechnology] = useState([]);

  const [listTechnologiesForDropDown, setListTechnologiesForDropDown] =
    useState([]);

  const [resetAllSelectInput, setResetAllSelectInput] = useState(true);

  const [userRoles, setUserRoles] = useState([]);

  const [selectedUserRoles, setSelectedUserRoles] = useState([]);

  const [listUserRolesForDropDown, setUserRolesForDropDown] = useState([]);

  const [resetAllUserRolesInput, setResetAllUserRolesInput] = useState(true);

  const [users, setUsers] = useState([]);

  const [projectUserList, setProjectUserList] = useState([]);

  const [formData, updateFormData] = React.useState({
    userId: "",
    projectId: props.slectedProjectId.id,
    dateBeginning: "",
    dateEnding: "",
    roles: [],
    technologies: [],
  });

  /*
      Handle submit works different if we are adding
      the project for the first time or if we are
      editing the project. If we edit the project
      we make a call that saves the project_id and user_id
      in the pr_project_user table. Else we create a list
      of users to be added while creating the project.
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    if (props.slectedProjectId.id == null) {
      const user = {
        userId: formData.userId,
        dateBeginning: formData.dateBeginning,
        dateEnding: formData.dateEnding,
        roles: formData.roles,
        technologies: formData.technologies,
      };
      props.addUserToUserList(user);
      updateFormData({
        userId: "",
        projectId: props.slectedProjectId.id,
        dateBeginning: "",
        dateEnding: "",
        roles: [],
        technologies: [],
      });
      props.handleClose();
    } else {
      ProjectUserRepository.addUserToProject(formData)
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.error("Error adding user to the project:", error);
        });
    }
  };

  useEffect(() => {
    getAllProjectTechnologies();
    getAllUserRoles();
    getAllUsers();
    getAllProjectUsers();
  }, [props.showModal]);

  useEffect(() => {
    if (technologies.length > 0) {
      setListTechnologiesForDropDown(
        convertTechnologyForDropDown(technologies)
      );
    }
  }, [technologies]);

  useEffect(() => {
    if (userRoles.length > 0) {
      setUserRolesForDropDown(convertUserRolesForDropDown(userRoles));
    }
  }, [userRoles]);

  const getSelectedUserRolesFromDropDown = (selectedOptions) => {
    const selectedRoles = selectedOptions.map((el) => el.value);
    updateFormData({
      ...formData,
      roles: selectedRoles,
    });
  };

  const getSelectedTechnologiesFromDropDown = (selectedOptions) => {
    const selectedTech = selectedOptions.map((el) => el.value);
    updateFormData({
      ...formData,
      technologies: selectedTech,
    });
  };

  const convertTechnologyForDropDown = (data) => {
    return data.map((item) => ({
      value: item.id.toString(),
      label: item.name,
    }));
  };

  const convertUserRolesForDropDown = (data) => {
    return data.map((item) => ({
      value: item.id.toString(),
      label: item.name,
    }));
  };

  const getAllProjectTechnologies = () => {
    NomenclatureService.getAllNomenclatureEntryByNomenclatureName("Технологии")
      .then((response) => {
        let nomenclatureConvert = response.data.map((n) => {
          return {
            value: n.id,
            label:
              ApplicationService.getActiveLanguage() === "mk"
                ? n.labelMk
                : n.labelEn,
          };
        });
        setListTechnologiesForDropDown(nomenclatureConvert);
      })
      .catch((error) => {
        console.error("Error fetching project user technologies:", error);
      });
  };

  const getAllProjectUsers = () => {
    projectUserRepository
      .getProjectUserByProjectId(props.slectedProjectId.id)
      .then((response) => {
        setProjectUserList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  };

  const getAllUsers = () => {
    UserRepository.findAllUsers()
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  const getAllUserRoles = () => {
    NomenclatureService.getAllNomenclatureEntryByNomenclatureName(
      "Улога на проект"
    )
      .then((response) => {
        let nomenclatureConvert = response.data.map((n) => {
          return {
            value: n.id,
            label:
              ApplicationService.getActiveLanguage() === "mk"
                ? n.labelMk
                : n.labelEn,
          };
        });
        setUserRolesForDropDown(nomenclatureConvert);
      })
      .catch((error) => {
        console.error("Error fetching project user roles:", error);
      });
  };

  const filteredUsers = users.filter((user) => {
    return !projectUserList.some((projectUser) => projectUser.id === user.id);
  });

  return (
    <Modal
      show={props.showModal}
      dialogAs={DraggableModalDialog}
      onHide={props.handleClose}
      size={"lg"}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <b>{strings.addEmployeeToProject}</b>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group
                className="mb-4 mt-2 w-75 mx-auto"
                controlId="employeeSelect"
              >
                <Form.Label className={"d-flex me-1 text-secondary"}>
                  {strings.employee}
                </Form.Label>
                <Select
                  value={formData.userId}
                  onChange={(selectedOption) => {
                    updateFormData({
                      ...formData,
                      userId: selectedOption ? selectedOption.value : "",
                    });
                  }}
                  options={filteredUsers.map((user) => ({
                    value: user.id,
                    label: `${user.firstName} ${user.lastName}`,
                  }))}
                  placeholder={strings.selectEmployee}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-4 mt-2 w-75 mx-auto" controlId="roles">
                <Form.Label className={"d-flex me-1 text-secondary"}>
                  {strings.roles}
                </Form.Label>
                <MultiSelectDropdown
                  handleSelection={getSelectedUserRolesFromDropDown}
                  listForDropDown={listUserRolesForDropDown}
                  defaultListForDropdown={selectedUserRoles}
                  resetData={resetAllUserRolesInput}
                  setResetData={setResetAllUserRolesInput}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
                className="mb-4 mt-2 w-75 mx-auto"
                controlId="technologies"
              >
                <Form.Label className={"d-flex me-1 text-secondary"}>
                  {strings.technologies}
                </Form.Label>
                <MultiSelectDropdown
                  handleSelection={getSelectedTechnologiesFromDropDown}
                  listForDropDown={listTechnologiesForDropDown}
                  defaultListForDropdown={selectedTechnology}
                  resetData={resetAllSelectInput}
                  setResetData={setResetAllSelectInput}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group
                className="mb-4 mt-2 w-75 mx-auto"
                controlId="startDate"
              >
                <Form.Label className={"d-flex me-1 text-secondary"}>
                  {strings.dateBeginning}
                </Form.Label>
                <Form.Control
                  type="date"
                  value={formData.dateBeginning}
                  onChange={(e) =>
                    updateFormData({
                      ...formData,
                      dateBeginning: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
                className="mb-4 mt-2 w-75 mx-auto"
                controlId="endDate"
              >
                <Form.Label className={"d-flex me-1 text-secondary"}>
                  {strings.dateEnding}
                </Form.Label>
                <Form.Control
                  type="date"
                  value={formData.dateEnding}
                  onChange={(e) =>
                    updateFormData({
                      ...formData,
                      dateEnding: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className={"cancel btn text-white"} onClick={props.handleClose}>
            {strings.cancel}
          </div>
          <button type={"submit"} className={"save btn text-white"}>
            {strings.save}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default AddUserToProjectModal;
