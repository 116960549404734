import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { strings } from "../../localization/Localization";
import { Buffer } from "buffer";
import swal from "sweetalert";
import "./Login.css";
import Logo from "../../assets/images/logo-inteligenta.png";
import { AUTH_TOKEN } from "../../shared/utility";
import AuthenticationRepository from "../../repository/authentication/AuthenticationRepository";
import { InputGroup, FormControl } from "react-bootstrap";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

const Login = () => {
  const [email, setEmail] = useState();

  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("auth_token")) {
      history.push("/home");
    }
  }, []);

  const handleLoginRequest = (e) => {
    e.preventDefault();
    let data2 = email + ":" + password;
    const request = Buffer.from(data2).toString("base64");
    AuthenticationRepository.loginUser(request)
      .then((data) => {
        localStorage.setItem(AUTH_TOKEN, data.data);
        history.push("/home");
      })
      .catch((err) => {
        wrongCredentialsAlert();
      });
  };

  const wrongCredentialsAlert = () => {
    swal(strings.wrongCredentials, {
      icon: "error",
    });
  };

  const setLanguageToMacedonian = () => {
    strings.setLanguage("mk");
    localStorage.setItem("activeLanguage", "mk");
    window.location.reload();
  };

  const setLanguageToEnglish = () => {
    strings.setLanguage("en");
    localStorage.setItem("activeLanguage", "en");
    window.location.reload();
  };

  return (
    <div className="login-back-container">
      <div className="login-div-center">
        <div className="content">
          <div className={"text-end"}>
            <button
              onClick={setLanguageToMacedonian}
              className="btn button1 btn-sm language-btn text-white m-sm-1 language-button"
            >
              MK
            </button>
            <button
              onClick={setLanguageToEnglish}
              className="btn btn-sm language-btn m-sm-1 text-white language-button"
            >
              EN
            </button>
          </div>
          <div className="d-flex justify-content-center">
            <img src={Logo} className={"logo"} />
          </div>
          <hr />
          <form onSubmit={handleLoginRequest} className="login-form">
            <div className="form-group mb-3">
              <label htmlFor={"exampleInputEmail1"} className={"my-2"}>
                {strings.email}
                <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                required={true}
                className="form-control"
                id="exampleInputEmail1"
                placeholder={strings.email}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="exampleInputPassword1" className="my-2">
                {strings.password}
                <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <InputGroup className="mb-3">
                  <FormControl
                    type={showPassword ? "text" : "password"}
                    required={true}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder={strings.password}
                  />
                  <InputGroup.Text
                    id="basic-addon2"
                    onClick={handleTogglePassword}
                  >
                    {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <span
              id={"error_text"}
              className={"text-danger d-inline-block"}
            ></span>
            <button
              type="submit"
              className="btn border-radius signIn-button text-white w-100 my-4"
            >
              {strings.signIn}
            </button>
            <br />
            <div
              className={"forget-password-label d-flex justify-content-between"}
            >
              <div>
                <Link to={"/forgotPassword"} className="btn btn-link mr-auto">
                  {strings.forgotPassword}
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
