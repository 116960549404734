import React from 'react';
import {strings} from "../../../../../localization/Localization";

const TotalExperienceUntilSelectedDate = ({ label, years, months, days, selectedDate }) => (
    <div className={"border border-1 border-radius p-2 pt-3 pb-3 rounded-4 mb-4 total-experience-total-and-company-container me-3 ms-auto"}>
        <div className={"d-flex inline-block align-items-center total-experience-text mb-3 mt-2 fs-6"}>
            <p className="mb-0 mr-2 mt-1 ml-5">{label}: &nbsp; {selectedDate}</p>
        </div>
        <hr className={"experience-hr d-flex ms-auto me-auto mb-4"}/>
        <div className={"row d-flex inline-block align-items-center total-years-months-days mb-3 mt-2 fs-6"}>
            <div className={"row"}>
                <div className={"col-4"}>{years}</div>
                <div className={"col-4"}>{months}</div>
                <div className={"col-4"}>{days}</div>
            </div>
        </div>
        <hr className={"experience-hr d-flex ms-auto me-auto mb-0"}/>
        <div className={"row d-flex inline-block align-items-center total-years-months-days mb-3 fs-6"}>
            <div className={"row"}>
                <i className={"col-4 text-secondary"}>{strings.years}</i>
                <i className={"col-4 text-secondary"}>{strings.months}</i>
                <i className={"col-4 text-secondary"}>{strings.days}</i>
            </div>
        </div>
    </div>
);

export default TotalExperienceUntilSelectedDate;
