import ApplicationService from "../../../ApplicationService";
import React, { useState, useEffect } from "react";
import { strings } from "../../../localization/Localization";
import "./EquipmentCardComponent.css";
import EquipmentCardDetailsModal from "../EquipmentCardDetailsModal/EquipmentCardDetailsModal";

const EquipmentCardComponent = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState({});
   
  const handleOpenCard = (equipment) => {
    setSelectedEquipment(equipment);
    setShowModal(true);
  };

  const handleCloseCard = () => {
    setSelectedEquipment({});
    setShowModal(false);
  };

  return (
    <div className={`col-4 card-width background-card`} key={props.equipment.id}>
      <div className={`card  ${
      props.expandedDescriptions
        ? props.expandedDescriptions[props.index]
          ? "card-expanded"
          : "card-not-expanded"
        : ""
    }`}>
        <div className="card-body d-flex flex-column">
          <div className="mb-2 d-flex justify-content-between">
            <h5 className="card-title">
              {ApplicationService.getActiveLanguage() === "mk"
                ? props.equipment?.labelMk
                : props.equipment?.labelEn}
            </h5>

            <div className="col-3">
              {props.equipment.isActive ? (
                <span className="badge text-bg-success ms-3">
                  {strings.Active}
                </span>
              ) : (
                <span className="badge text-bg-danger ">
                  {strings.notActive}
                </span>
              )}
            </div>
          </div>
          <div className="row">
            <img
              src="https://cdn-icons-png.flaticon.com/128/8644/8644457.png"
              alt="Equipment"
              className="col-3 image-width"
            />
          </div>
          <div className="row mb-3 mt-3">
            <div className={"d-flex"}>
              <p
                className={`description-text me-2 ${
                  props.expandedDescriptions
                    ? props.expandedDescriptions[props.index]
                      ? "description-expanded"
                      : "description-not-expanded"
                    : ""
                }`}
              >
                {props.equipment?.description}
              </p>
              {props.equipment?.description.split(" ").length > 20 && (
                <button
                  className="toggle-description-button justify-content-center"
                  onClick={() => props.showDescription(props.index)}
                >
                  {props.expandedDescriptions[props.index] ? (
                    <i className={"material-icons"}>expand_less</i>
                  ) : (
                    <i className={"material-icons"}>expand_more</i>
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="d-flex mt-auto">
            <a
              className="btn btn-primary text-white add-button details-button"
              onClick={() => {
                setSelectedEquipment(props.equipment);
                handleOpenCard(props.equipment);
              }}
            >
              {strings.details}
            </a>
          </div>
          <EquipmentCardDetailsModal
            showModal={showModal}
            equipmentDetails={selectedEquipment}
            handleClose={handleCloseCard}
            equipment={props.equipmentNomenclature}
            expandedDescriptions={props.expandedDescriptions}
          />
        </div>
      </div>
    </div>
  );
};

export default EquipmentCardComponent;
