import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { strings } from "../../localization/Localization";
import Select from "react-select";
import ProjectService from "../../repository/projectRepository/projectRepository";
import ProjectGroupService from "../../repository/projectGroupRepository/projectGroupRepository";
import ApplicationService from "../../ApplicationService";

const TimeTrackingFilter = (props) => {
  const [projectNames, setProjectNames] = useState([]);
  const [projectGroups, setProjectGroups] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [resetAllSelectInput, setResetAllSelectInput] = useState(true);

  const [formData, updateFormData] = React.useState({
    firstName: "",
    lastName: "",
    projectGroup: undefined,
    projectName: undefined,
    timeRecordDate: "",
  });
  const [permissionForUpsert, setPermissionForUpsert] = useState(
    ApplicationService.hasRole(["ROLE_HR", "ADMINISTRATOR"])
  );

  useEffect(() => {
    if (ApplicationService.hasRole(["ROLE_HR", "ROLE_ADMINISTRATOR"])) {
      setPermissionForUpsert(true);
    }
  }, []);

  useEffect(() => {
    getAllProjectNames();
    getAllProjectGroups();
  }, []);

  const getAllProjectNames = () => {
    ProjectService.getAllProjects().then((data) => {
      let namesAndIdsConverter = data.data.map((n) => {
        return {
          value: n.id,
          label: n.name,
        };
      });
      setProjectNames(namesAndIdsConverter);
    });
  };

  const getAllProjectGroups = () => {
    ProjectGroupService.getAllGroups().then((data) => {
      let groupsConverter = data.data.map((gp) => {
        return {
          value: gp.id,
          label: gp.name,
        };
      });
      setProjectGroups(groupsConverter);
    });
  };

  const handleChange = (e) => {
    setResetAllSelectInput(false);
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleProjectNameChange = (selectedOption) => {
    updateFormData({
      ...formData,
      projectName: selectedOption,
    });
  };

  const handleProjectGroupChange = (selectedOption) => {
    updateFormData({
      ...formData,
      projectGroup: selectedOption,
    });
  };

  const filterTimeRecords = (
    firstName,
    lastName,
    projectGroup,
    projectName,
    timeRecordDate
  ) => {
    props.loadTimeRecord(
      firstName,
      lastName,
      projectGroup,
      projectName,
      timeRecordDate
    );
  };

  const onFormSubmit = (e, reset = false, search = false) => {
    e.preventDefault();
    search = !reset;
    let firstName = formData.firstName !== "" ? formData.firstName : null;
    let lastName = formData.lastName !== "" ? formData.lastName : null;
    let projectGroup = formData.projectGroup !== "" ? formData.projectGroup : null;
    let projectName = formData.projectName !== "" ? formData.projectName : null;
    let timeRecordDate = formData.date !== "" ? formData.date : null;

    if (reset) {
      firstName = null;
      lastName = null;
      projectGroup = null;
      projectName = null;
      timeRecordDate = null;
    }
    filterTimeRecords(
      firstName,
      lastName,
      projectGroup,
      projectName,
      timeRecordDate
    );
  };

  const resetFilterData = (event) => {
    updateFormData({
      firstName: "",
      lastName: "",
      projectGroup: [],
      projectName: [],
      date: "",
    });
    setSelectedProject([]);
    setResetAllSelectInput(true);
    props.resetFilterData();
    onFormSubmit(event, true);
  };

  return (
    <>
      <div className="card border-secondary mb-3">
        <div className="card-header justify-content-center">
          <div className="m-2 p-2 text-center justify-content-center">
            <Form onSubmit={onFormSubmit}>
              {props.permissionForUpsert && permissionForUpsert && (
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-2" controlId="name">
                      <Form.Label className="d-flex justify-content-start">
                        {strings.firstName}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        placeholder={strings.filterName}
                        onChange={handleChange}
                        value={formData.firstName}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-2" controlId="lastName">
                      <Form.Label className="d-flex justify-content-start">
                        {strings.lastName}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        placeholder={strings.filterSurname}
                        onChange={handleChange}
                        value={formData.lastName}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row className="d-flex justify-content-center">
                <Col xs={12} md={4} className="d-flex justify-content-center">
                  <Form.Group
                    className="mb-4 mt-3 w-95 mx-auto"
                    controlId="projectGroup"
                  >
                    <Form.Label
                      className={
                        "justify-content-start text-start align-items-start d-flex"
                      }
                    >
                      {strings.projectGroup}
                    </Form.Label>
                    <Select
                      options={projectGroups}
                      resetData={resetAllSelectInput}
                      setResetData={setResetAllSelectInput}
                      value={formData.projectGroup}
                      onChange={handleProjectGroupChange}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4} className="d-flex justify-content-center">
                  <Form.Group
                    className="mb-4 mt-3 w-95 mx-auto"
                    controlId="projectName"
                  >
                    <Form.Label
                      className={
                        "justify-content-start text-start align-items-start d-flex"
                      }
                    >
                      {strings.projectName}
                    </Form.Label>
                    <Select
                      options={projectNames}
                      defaultListForDropdown={selectedProject}
                      resetData={resetAllSelectInput}
                      setResetData={setResetAllSelectInput}
                      value={formData.projectName}
                      onChange={handleProjectNameChange}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4} className="d-flex justify-content-center">
                  <Form.Group className="mb-2 mt-3 w-95" controlId="date">
                    <Form.Label className="d-flex justify-content-start">
                      {strings.date}
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="date"
                      placeholder="Filter beginning date..."
                      onChange={handleChange}
                      resetData={resetAllSelectInput}
                      setResetData={setResetAllSelectInput}
                      value={formData.date}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className={"mt-4"}>
                {!props.resetIsLoading ? (
                  <div
                    className="btn text-white align-bottom align-items-end user-filter__reset_button me-5 user-filter__button_size"
                    onClick={(event) => resetFilterData(event)}
                  >
                    {strings.reset}
                  </div>
                ) : (
                  <div
                    className="btn text-white align-bottom align-items-end user-filter__reset_button user-filter__reset_button__disabled me-5 user-filter__button_size"
                    type="button"
                    disabled={true}
                  >
                    <span
                      className="spinner-grow spinner-grow-sm me-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {strings.reset}...
                  </div>
                )}
                {!props.searchIsLoading ? (
                  <div
                    className="btn text-white align-bottom align-items-end add-button user-filter__button_size"
                    onClick={onFormSubmit}
                  >
                    {strings.search}
                  </div>
                ) : (
                  <div
                    className="btn text-white align-bottom align-items-end add-button user-filter_search_button__disabled user-filter__button_size"
                    type="button"
                    disabled={true}
                  >
                    <span
                      className="spinner-grow spinner-grow-sm me-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {strings.search}...
                  </div>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeTrackingFilter;
