import React, { useEffect } from "react";
import { strings } from "../localization/Localization";
import { FaChevronDown } from "react-icons/fa";
import 'material-icons/iconfont/material-icons.css';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { remove } from "../redux/userSlice";
import FeedbackModal from "../pages/Feedback/FeedbackModal/FeedbackModal";


const Dropdown = () => {

    const dispach = useDispatch();

    const userDetailsRedux = useSelector((state) => state.user);
    const [showModal, setShowModal] = React.useState(false);

    const handleLogout = () => {
        dispach(remove());
    };
    const handleModal = () => {
        setShowModal(!showModal)
    }
    const handleClose = () => {
        setShowModal(false);
    };



    return (
        <div style={{ width: "100%", zIndex: "1010" }}>
            <a className="dropdown d-flex align-items-center btn btn-dark icon-button" id="dropdownMenu"
                data-bs-toggle="dropdown" aria-expanded="false">
                <FaChevronDown />
            </a>

            <div className="dropdown-menu" aria-labelledby="dropdownMenu">
                <button className="dropdown-item" type="button">
                    <Link className="item w-100 d-inline-flex text-dark"
                        to="/userDetails">
                        <div className={"icon-wrapper "}>
                            <i className="material-icons mx-1">person_pin</i>
                        </div>
                        <div className={"mx-1"}>
                            {strings.profile}
                        </div>
                    </Link>
                </button>

                <button className="dropdown-item" type="button">
                    <Link className="item w-100 d-inline-flex text-dark"
                        to="/myProjects" >
                        <div className={"icon-wrapper"}>
                            <i className="material-icons mx-1">library_books</i>
                        </div>
                        <div className={"mx-1"}>
                            {strings.myProjects}
                        </div>
                    </Link>
                </button>
                <button className="dropdown-item" type="button" onClick={handleModal}>
                    <div className="item w-100 d-inline-flex text-dark">


                        <div className={"icon-wrapper text"}>
                            <i className="material-icons mx-1" >feedback</i>

                        </div>
                        <div className={"mx-1"}>
                            {strings.feedback}
                        </div>
                    </div>
                </button>
                <button className="dropdown-item" type="button" onClick={handleLogout}>
                    <Link
                        className="item w-100 d-inline-flex text-dark"
                        to="/logout">
                        <div className={"icon-wrapper text"}>
                            <i className="material-icons mx-1">logout</i>
                        </div>
                        <div className={"mx-1"}>
                            {strings.logOut}
                        </div>
                    </Link>
                </button>
            </div>
            <FeedbackModal showModal={showModal}
                handleClose={handleClose} />
        </div>
    );
}
export default Dropdown;
