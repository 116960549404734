import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { strings } from '../../../localization/Localization';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ProjectGroupService from '../../../repository/projectGroupRepository/projectGroupRepository';
import swal from 'sweetalert';
import DraggableModalDialog from '../../../components/DraggableModalDialog/DraggableModalDialog';

const AddGroup = (props) => {

  const [formData, updateFormData] = useState({
    name: '',
    description: ''
  });

  const resetData = () => {
    updateFormData({
      name: '',
      description: ''
    });
  };

  useEffect(() => {
    if (!props.selectedGroupForEdit) {
      resetData();
    } else {
      if (props.selectedGroupForEdit?.id) {
        updateFormData({
          name: props.selectedGroupForEdit?.name,
          description: props.selectedGroupForEdit?.description,
        });
      }
    }
  }, [props.showModal]);

  const handleCancel = () => {
    props.handleClose();
    resetData();
  };

  const handleChange = (e) => {
    const {
      name,
      value
    } = e.target;

    if (e.target.hasAttribute("required") && value.trim() === "") {
      e.target.setCustomValidity(strings.fieldsRequired);
    } else {
      e.target.setCustomValidity("");
    }
    updateFormData({
      ...formData,
      [name]: value,
    });
  };

  const groupAddSuccessfulAlert = () => {
    swal(strings.userEditSuccessful, {
      icon: 'success',
    });
  };

  const errorAlert = () => {
    swal({
      title: strings.oops,
      text: strings.somethingWrong,
      icon: 'error',
      buttons: [strings.cancel, strings.ok],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
  };

  const addGroup = (name, description) => {
    ProjectGroupService.addGroup(name, description)
      .then(() => {
        groupAddSuccessfulAlert();
        props.getAllGroups();
        props.handleClose();
      }).catch(() => {
      errorAlert();
    });
  };

  const editGroup = (name, description) => {
    ProjectGroupService.editGroup(props.selectedGroupForEdit?.id, name, description)
      .then(() => {
        groupAddSuccessfulAlert();
        props.getAllGroups();
        props.handleClose();
      }).catch(() => {
      errorAlert();
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const name = formData.name !== '' ? formData.name : null;
    const description = formData.description !== '' ? formData.description : null;
    if (props.selectedGroupForEdit?.id) {
      editGroup(name, description);
    } else {
      addGroup(name, description);
    }
  };

  return (
    <Modal show={props.showModal} dialogAs={DraggableModalDialog} onHide={props.handleClose} size={'lg'} centered>
      <Modal.Header>
        {props.selectedGroupForEdit?.id ? (
          <h4 className={'text-center'}>{strings.editGroup}: <b>{formData.name}</b></h4>
        ) : (
          <h4 className={'text-center'}>{strings.addGroup}</h4>
        )}            </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <Modal.Body>
          <Row className={'mb-2'}>
            <Form.Group className='mb-3'>
              <Form.Label className={'d-flex text-secondary'}>
                {strings.name}<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                required
                type={'text'}
                value={formData.name}
                onChange={handleChange}
                name={'name'}
                onInvalid={(e) => {
                  e.target.setCustomValidity(strings.fieldsRequired);
                }}/>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className='mb-2'>
              <Form.Label className={'d-flex text-secondary'}>
                {strings.description}<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                required
                as='textarea'
                rows={4}
                value={formData.description}
                onChange={handleChange}
                name={'description'}
                onInvalid={(e) => {
                  e.target.setCustomValidity(strings.fieldsRequired);
                }}/>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={12} md={2} className={'ms-auto'}>
              <div className={'d-flex justify-content-end mb-3'}>
                <div className={'cancel btn text-white m-1'} onClick={handleCancel}>
                  {strings.cancel}
                </div>
                <div>
                  <button type={'submit'} className={'save btn text-white m-1'}>
                    {strings.save}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default AddGroup;
