import axios from "../../axios/axios";

const WorkExperienceService = {
    getAll: () => {
        return axios.get("")
    },
    getAllWorkExperienceDataForUser: (userId) => {
        return axios.get(`/work-experience/getAllWorkExperienceDataForUser/${userId}`)
    },
    getAllWorkExperienceDataForLoggedUser: () => {
        return axios.get('/work-experience/getAllWorkExperienceDataForLoggedUser')
    },
    getWorkExperienceById: (workExperienceId) => {
        return axios.get(`/work-experience/getWorkExperienceById/${workExperienceId}`)
    },
    addWorkExperienceData: (userId, companyName, dateRegistered, workPosition, startDate, endDate, workingYears, workingMonths, workingDays, fromOurCompany) => {
        return axios.post("/work-experience/addWorkExperienceData", {
            userId,
            companyName,
            dateRegistered,
            workPosition,
            startDate,
            endDate,
            workingYears,
            workingMonths,
            workingDays,
            fromOurCompany,
        })
    },
    addWorkExperienceDataByAdmin: (userId, companyName, dateRegistered, workPosition, startDate, endDate, workingYears, workingMonths, workingDays, fromOurCompany) => {
        return axios.post("/work-experience/addWorkExperienceDataByAdmin", {
            userId,
            companyName,
            dateRegistered,
            workPosition,
            startDate,
            endDate,
            workingYears,
            workingMonths,
            workingDays,
            fromOurCompany,
        })
    },
    editWorkExperienceData: (workExperienceId, userId, companyName, dateRegistered, workPosition, startDate, endDate, workingYears, workingMonths, workingDays, fromOurCompany) => {
        return axios.put("/work-experience/updateWorkExperienceData", {
            workExperienceId,
            userId,
            companyName,
            dateRegistered,
            workPosition,
            startDate,
            endDate,
            workingYears,
            workingMonths,
            workingDays,
            fromOurCompany
        })
    },
    editWorkExperienceDataByAdmin: (workExperienceId, userId, companyName, dateRegistered, workPosition, startDate, endDate, workingYears, workingMonths, workingDays, fromOurCompany,) => {
        return axios.put("/work-experience/updateWorkExperienceDataByAdmin", {
            workExperienceId,
            userId,
            companyName,
            dateRegistered,
            workPosition,
            startDate,
            endDate,
            workingYears,
            workingMonths,
            workingDays,
            fromOurCompany,
        })
    },
    deleteWorkExperienceData: (id) => {
        return axios.delete(`work-experience/deleteWorkExperienceData/${id}`)
    }
}

export default WorkExperienceService;