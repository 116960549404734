import {strings} from "../../../localization/Localization";
import React, {useState, useEffect} from "react";
import "./EventCard.css"
import swal from "sweetalert";
import EventsService from "../../../repository/eventsRepository/EventsRepository";
import EventDetails from "../Event/EventDetails";
import FormatDate from "../../../components/formatDate/FormatDate";

const EventCard = (props) => {

    const [selectedEventForEdit, setSelectedEventForEdit] = useState({});

    const [showAddModal, setShowAddModal] = useState(false);

    const [formData, updateFormData] = useState({
        ed_startDate: "",
        ed_endDate: "",
        ed_capacity: "",
    });

    useEffect(() => {
        const eventGroups = props.event.eventGroups || [];
        const activeGroup = eventGroups.find(group => group.status === "active" && group.capacity > 0);

        if (activeGroup) {
            updateFormData({
                ...formData,
                ed_startDate: activeGroup.startDate,
                ed_endDate: activeGroup.endDate,
                ed_capacity: activeGroup.capacity,
            });
        }
    }, [props.event]);

    const handleShowAddEventModal = () => {
        setShowAddModal(true);
    };

    const handleCloseAddEventModal = () => {
        setSelectedEventForEdit({});
        setShowAddModal(false);
    };

    const handleCardClick = () => {
        setSelectedEventForEdit(props.event);
        setShowAddModal(true);
    }

    const isActiveEvent = props.event.eventGroups.some(group => group.status === "active" && group.capacity > 0);

    return (
        <div className={`card-col col-12 col-lg-6 col-xl-4 mb-4 px-2 cursor-pointer`}
             key={props.event.id}>
            <EventDetails showModal={showAddModal}
                          handleClose={handleCloseAddEventModal}
                          selectedEventForEdit={selectedEventForEdit}
                          getAllEvents={props.getAllEvents}
                          event={props.event}/>
            {/*${(!isActiveEvent && props.activeTab === "allEvents") || (isActiveEvent && props.activeTab === "inactiveEvents")  ? "inactive-card" : ""}*/}
            <div className={`mt-4 shadow-sm event-card ${isActiveEvent ? "" : "inactive-card"}`}
                 onClick={handleCardClick}>
                <div className="card-image">
                    <img
                        src="https://desktime.com/blog/wp-content/uploads/2021/08/meeting.png"
                        alt="Event"
                    />
                </div>
                <div className="p-3">
                    <h4 className="bold-text ">{props.event.name}</h4>
                    <p>{props.event.description}</p>
                </div>
                <div className={"d-flex align-items-end bottom-content"}>
                    {isActiveEvent ? (
                        <div className="bottom-content p-3">
                            <div className="d-flex flex-column">
                            <span className={"text-secondary"}>
                                {strings.startDate}: {FormatDate.formatDate(formData.ed_startDate)}
                            </span>
                                <span className={"text-secondary"}>
                                {strings.endDate}: {FormatDate.formatDate(formData.ed_endDate)}
                            </span>
                                <span className={"mt-1 justify-content-center badge text-white rounded-3 capacity"}>
                                {strings.capacity}: {formData.ed_capacity}
                            </span>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="p-3">
                                <h5>
                            <span className={"border-on-text"}>
                                {strings.inactiveEventMessage}
                            </span>
                                </h5>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EventCard;
