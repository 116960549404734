import React from 'react';

function CustomTable({data, columns}){
    return (
        <table className="table user-table table-responsive table-responsive-sm table-borderless text-start w-100">
            <thead className={"custom-table-header text-white"}>
            <tr className={"user-table-header-row"}>
                {columns.map(column => (
                    <th scope="col" key={column.key} className='col-6' style={{ minWidth: column.minWidth ?? 0 }}>{column.header}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {data.map(item => (
                <tr className={"user-table-body-row"} key={item.id}>
                    {columns.map(column => (
                        <td key={column.key}>
                            {column.render ? column.render(item[column.key]) : item[column.key]}
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default CustomTable;
