import React from 'react';
import { strings } from '../../../localization/Localization';

const AbsenceStatusList = (props) => {

  const windowSize = window.innerWidth;

  return (
    <div className='table-responsive'>
      <table className='table user-table table-responsive table-borderless text-start'>
        <thead className={'custom-table-header text-white'}>
        <tr className={'user-table-header-row'}>
          <th scope='col'>{strings.year}</th>
          {((windowSize <= 567 && props.showReasons) || windowSize > 576) &&
            <th scope='col'>{strings.reasonsForLeaveRequest}</th>
          }
          {props.showReasons &&
            <th scope='col'>{strings.leaveType}</th>
          }
          <th scope='col'>{strings.allowedDays}</th>
          <th scope='col'>{strings.usedDays}</th>
          <th scope='col'>{strings.remainingDays}</th>
        </tr>
        </thead>
        <tbody>

        {props.data.length > 0 ?
          (props.data.map((data) => {
              return (
                <tr className={'user-table-body-row'} key={data.id}>
                  <td>{data.forYear}</td>
                  {((windowSize <= 567 && props.showReasons) || windowSize > 576) &&
                    <td>{data?.leaveReason?.labelMk}</td>
                  }
                  {props.showReasons &&
                    <td>{data.leaveReason?.leaveType?.label_mk}</td>
                  }
                  <td>{data?.allowedDays}</td>
                  <td>{data?.usedDays}</td>
                  <td>{data?.remainingDays}</td>
                </tr>
              );
            })
          ) :
          (
            <tr className={'justify-content-center text-center '}>
              <td colSpan={6}>
                <h6 className={'text-secondary'}><i>{strings.emptyList}</i></h6>
              </td>
            </tr>
          )
        }
        </tbody>
      </table>
    </div>
  );
};
export default AbsenceStatusList;