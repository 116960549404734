import React, {useState} from "react"
import {strings} from "../../localization/Localization";
import PositionCard from "./PositionCard";
import PositionHierarchyEditModal from '../../pages/PositionHierarchy/PositionHierarchyEdtiModal/PositionHierarchyEditModal'

const PositionListCard = (props) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState(null);

    const isActivePosition = (position) => {
        const currentDate = new Date();

        if (position.activeFrom && new Date(position.activeFrom) <= currentDate) {
            if (position.activeTo && new Date(position.activeTo) >= currentDate) {
                return true;
            }
            if (position.activeTo === null) {
                return true;
            }
        }
        return false;
    }
    const handlePositionCardClick = (position) => {
        setSelectedPosition(position);
        setIsModalOpen(true);
        // props.onPositionCardClick(position);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
      };

    return (
        <>
            {props.positionList.length > 0 ? (
                <div className={"py-3 ps-4  border-radius border-4 mb-3"}>
                    {props.positionList.map((position)=>{
                        const isActive = isActivePosition(position);
                        return(
                            <div onClick={() => handlePositionCardClick(position)}>
                                <PositionCard key={position.id} position={position} isActive={isActive} />
                            </div>
                        )

                    })}

                </div>
            ) : (

                <h5 className={'text-center text-secondary'}>
                    <i>  {strings.noPositionForSelectedOrganisation} </i>
                </h5>

            )}
            {isModalOpen && (
        <PositionHierarchyEditModal
            showModal={isModalOpen}
            handleClose={closeModal}
            selectedNode={selectedPosition}
        />
      )}
        </>
    );
}
export default PositionListCard;